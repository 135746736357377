import * as React from 'react'
import { FlexBox } from 'components/atoms/BoxComponents'
import { theme } from 'components/theme'

// 項目グルーピング形式の詳細画面用FlexBox
export const DetailFlexBox: React.FC<{
  children?: React.ReactNode | string
}> = (props) => {
  return (
    <FlexBox
      alignItems="center"
      sx={{
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        },
      }}
    >
      {props.children}
    </FlexBox>
  )
}
