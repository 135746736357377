/**
 * - 「EnterKeyでsubmit」を避けたいElementがあれば配列にnodeNameを入れる
 */
const ignoreElementNames = ['TEXTAREA']

const isEnterKey = (e: React.KeyboardEvent) => {
  return e.code === 'Enter'
}

/**
 * - 押されたKeyが'Enter'であり、かつfocusしているElementがignoreElementNamesに含まれていなければtrueを返す
 * - ex) textareaでは改行したい為、ignoreElementNamesに登録する
 */
export const ShouldSubmitOnKeyPress = (e: React.KeyboardEvent) => {
  const focusElementNodeName = document.activeElement.nodeName
  if (e.nativeEvent.isComposing) return false
  return isEnterKey(e) && !ignoreElementNames.includes(focusElementNodeName)
}
