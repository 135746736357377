import * as React from 'react'
import { ADMIN_MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import AlcoholChecksForm from 'components/organisms/alcohol_checks/Form'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'

function AdminAlcoholChecksNew() {
  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.ALCOHOL_CHECKS.NEW ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>{ADMIN_MENUS.alcoholChecks.title}</h1>
      <AlcoholChecksForm />
    </PageWrapper>
  )
}

export default AdminAlcoholChecksNew
