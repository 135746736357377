import * as React from 'react'
import { styled } from '@mui/styles'
import { Box } from '@mui/material'
import { palette, theme, spacing } from 'components/theme'
import { FlexBox } from 'components/atoms/BoxComponents'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'

const ExecutiveSidebarContainer = styled(Box)({
  height: '100%',
  minWidth: spacing.sidebarWidth,
  padding: '12px 8px 8px',
  borderRight: 'solid',
  borderRightColor: palette.border.pale,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'block',
  },
})

function ExecutiveSidebar() {
  const BlankSpace = styled('div')({
    height: '70px',
    flexShrink: 0,
  })

  return (
    <ExecutiveSidebarContainer>
      <FlexBox flexDirection={'column'} style={{ height: '100%' }}>
        {/* position:fixedのヘッダーと重なる箇所を余白で調整 */}
        <BlankSpace />
        <FlexBox
          flexDirection={'column'}
          style={{ height: '100%', overflowY: 'auto' }}
        >
          <ApplyButton pattern="sidebar" href={'/'}>
            モビポケを表示する
          </ApplyButton>
        </FlexBox>
      </FlexBox>
    </ExecutiveSidebarContainer>
  )
}

export default ExecutiveSidebar
