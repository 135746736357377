import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const BillIcon = () => {
  return (
    <SvgIcon>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 4V20.875L6.375 19.4688L8.25 20.875L10.125 19.4688L12 20.875L13.875 19.4688L15.75 20.875L17.625 19.4688L19.5 20.875V4H4.5ZM14.2814 12.8901V13.7039H12.5958V15.0989H11.472V13.7039H9.81549V12.8901H11.472V12.1733H9.81549V11.3595H10.8908L9.32143 8.82143H10.6292L12.0436 11.253L13.4192 8.82143H14.6786L13.148 11.3595H14.2814V12.1733H12.5958V12.8901H14.2814Z"
        fill="#003333"
      />
    </SvgIcon>
  )
}
