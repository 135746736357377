import * as React from 'react'
import { EDUCATION_ANNUAL_PLANS } from 'commons/constants'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { styled } from '@mui/material'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { deleteEducationAnnualPlan } from 'components/apis/educationAnnualPlans'
import { useNavigate } from 'react-router-dom'
import { toastOnError } from 'commons/toaster'
import { range } from 'commons/functions'
import { useMutation } from '@tanstack/react-query'
import { UserContext } from 'providers/UserProvider'
import { slackNotification } from 'commons/slackNotification'
import { theme } from 'components/theme'
import { CustomLabel } from 'components/atoms/FormComponents'
import { ValueText } from 'components/atoms/TextComponents'
import VideoContent from 'components/organisms/educations/VideoContent'
import { Grid } from '@mui/material'

export default function EducationAnnualPlanDetail(props: {
  data: EducationAnnualPlan
}) {
  const { data } = props
  const navigate = useNavigate()
  const mutation = useMutation({
    mutationFn: deleteEducationAnnualPlan,
    onSuccess: () => navigate(`/admin/educations/annual_plans/`),
    onError: (e: { response: { errors: string } }) => {
      toastOnError(e.response.errors)
      // 422エラー時にslack通知する
      slackNotification(e, user)
    },
  })
  const user = React.useContext(UserContext)

  const Section = styled('div')({
    marginBottom: '1rem',
  })

  const HeadingText = styled('p')({
    fontWeight: 'bold',
    marginTop: 0,
  })

  const DetailCustomLabel: React.FC<{ children?: React.ReactNode | string }> = (
    props
  ) => {
    return (
      <CustomLabel
        sx={{
          marginRight: '32px',
          [theme.breakpoints.down('md')]: {
            marginRight: '0',
          },
        }}
      >
        {props.children}
      </CustomLabel>
    )
  }

  const onClickDelete = () => {
    mutation.mutate(data.id)
  }

  return (
    <>
      <Section>
        <BackGroundWhite>
          <FlexBox flexDirection={'column'}>
            <HeadingText>
              {EDUCATION_ANNUAL_PLANS.LABELS.BASIC_INFO}
            </HeadingText>
            <DetailCustomLabel>
              {EDUCATION_ANNUAL_PLANS.LABELS.ANNUAL_PLAN_TITLE}
              <ValueText value={data.annualPlanTitle} />
            </DetailCustomLabel>
            <DetailCustomLabel>
              {EDUCATION_ANNUAL_PLANS.LABELS.FISCAL_YEAR}
              <ValueText value={`${data.year}年度`} />
            </DetailCustomLabel>
            {user.isXmileAdmin ||
              (user.isGroupParentCompanyAdmin && (
                <DetailCustomLabel>
                  {EDUCATION_ANNUAL_PLANS.LABELS.COMPANY}
                  <ValueText value={data.companyName} />
                </DetailCustomLabel>
              ))}
            <DetailCustomLabel>
              {EDUCATION_ANNUAL_PLANS.LABELS.OFFICE}
              <ValueText value={data.officeName} />
            </DetailCustomLabel>
          </FlexBox>
        </BackGroundWhite>
      </Section>
      {data.subjects.map((subject, index) => (
        <Section key={index}>
          <BackGroundWhite>
            <HeadingText>
              {index + 1}. {subject.education.title}
            </HeadingText>
            <DetailCustomLabel>
              {EDUCATION_ANNUAL_PLANS.LABELS.SCHEDULE}
              <ValueText value={subject.scheduleDate} />
            </DetailCustomLabel>
            <DetailCustomLabel>
              {EDUCATION_ANNUAL_PLANS.LABELS.ORGANIZER}
              <ValueText value={subject.organizer} />
            </DetailCustomLabel>
            <DetailCustomLabel>
              {EDUCATION_ANNUAL_PLANS.LABELS.LOCATION}
              <ValueText value={subject.location} />
            </DetailCustomLabel>
            <DetailCustomLabel>
              {EDUCATION_ANNUAL_PLANS.LABELS.CONTENT}
              <ValueText value={subject.content} />
            </DetailCustomLabel>
            <DetailCustomLabel>
              {EDUCATION_ANNUAL_PLANS.LABELS.VIDEO}
              <Grid container spacing={1}>
                {subject.videos?.map((education: Education, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <VideoContent
                        education={education}
                        isShowComment={false}
                      />
                    </React.Fragment>
                  )
                })}
              </Grid>
            </DetailCustomLabel>
          </BackGroundWhite>
        </Section>
      ))}
      <FlexBox
        flexDirection={{ xs: 'column-reverse', sm: 'row' }}
        justifyContent={{ xs: 'space-between' }}
        alignItems={{ xs: 'start', sm: 'center' }}
      >
        <DeleteButton sx={{ margin: 'none' }} onClick={onClickDelete}>
          このデータを削除する
        </DeleteButton>
      </FlexBox>
    </>
  )
}
