import * as React from 'react'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { Helmet } from 'react-helmet-async'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { BoldLabel, CautionLi } from '../driver_import'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { FlexBox } from 'components/atoms/BoxComponents'
import { ADMIN_ROUTES } from 'commons/constants'

function AdminCsvSettingsCautionsVehicleImportIndex() {
  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.CSV_SETTINGS.INDEX ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>車両CSVインポート 注意事項</h1>
      <BackGroundWhite>
        <div style={{ marginBottom: '50px' }}>
          <h2>1. CSV作成時の注意事項</h2>
          <ul>
            <CautionLi>
              <BoldLabel>車両ナンバー</BoldLabel>
              <ul>
                <li>入力必須です。</li>
              </ul>
            </CautionLi>
            <CautionLi>
              <BoldLabel>事業所</BoldLabel>
              <ul>
                <li>
                  モビポケの会社管理で登録済みの事業所名を入力してください。
                </li>
                <li>
                  未登録の事業所が入力された場合、その車両の所属事業所にはインポート実行ユーザーの所属事業所を設定します。
                </li>
              </ul>
            </CautionLi>
            <CautionLi>
              <BoldLabel>最大積載量、車両総重量</BoldLabel>
              <ul>
                <li>入力値の単位はkgとして扱います。</li>
              </ul>
            </CautionLi>
            <CautionLi>
              <BoldLabel>車両外寸</BoldLabel>
              <ul>
                <li>入力値の単位はcmとして扱います。</li>
              </ul>
            </CautionLi>
            <CautionLi>
              <BoldLabel>総排気量</BoldLabel>
              <ul>
                <li>入力値の単位はkw/lとして扱います。</li>
              </ul>
            </CautionLi>
            <CautionLi>
              <BoldLabel>年月日項目(登録年月日等)</BoldLabel>
              <ul>
                <li>
                  西暦表記で入力してください。
                  <br />
                  入力例: 2023年1月1日、2023/1/1、2023.1.1、2023-1-1、20230101
                </li>
              </ul>
            </CautionLi>
          </ul>
        </div>
        <div>
          <h2>2. その他注意事項</h2>
          <ul>
            <CautionLi>
              <BoldLabel>
                以下の項目は小数点以下は切り捨て、全角の場合は0として登録されます。
              </BoldLabel>
            </CautionLi>
            <ul>
              <li>乗車定員</li>
              <li>最大積載量1~3</li>
              <li>車両総重量1~3</li>
              <li>トン数（最大積載量）</li>
              <li>車両外寸(長さ)</li>
              <li>車両外寸(幅)</li>
              <li>車両外寸(高さ)</li>
              <li>燃料タンクの容量1~4</li>
              <li>購入価格（円）</li>
            </ul>
          </ul>
        </div>
      </BackGroundWhite>
      <FlexBox
        justifyContent={'flex-end'}
        flexDirection={'row-reverse'}
        sx={{ margin: '8px 0 20px 0' }}
      >
        <ApplyButton href={ADMIN_ROUTES.CSV_SETTING_INDEX}>
          CSV設定画面へ
        </ApplyButton>
      </FlexBox>
    </PageWrapper>
  )
}

export default AdminCsvSettingsCautionsVehicleImportIndex
