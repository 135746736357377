import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const MasterIcon = () => {
  return (
    <SvgIcon>
      <rect x="3" y="6" width="3" height="3" fill="#003333" />
      <rect x="3" y="11" width="3" height="3" fill="#003333" />
      <rect x="3" y="16" width="3" height="3" fill="#003333" />
      <rect x="8" y="6" width="12" height="3" fill="#003333" />
      <rect x="8" y="11" width="12" height="3" fill="#003333" />
      <rect x="8" y="16" width="12" height="3" fill="#003333" />
    </SvgIcon>
  )
}
