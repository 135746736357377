import * as React from 'react'
import { styled } from '@mui/styles'
import { Box, Button, Grid } from '@mui/material'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  APPLICATION_NAME,
  HEAD_TEXTS,
  PAGE_TITLES,
  ALERT_TEXT,
} from 'commons/constants'
import { MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { fetchEducations } from 'components/apis/educations'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { useQuery } from '@tanstack/react-query'
import EducationContent from 'components/organisms/educations/EducationContent'
import EmptyCurrentContent from 'components/organisms/educations/EmptyCurrentContent'
import { Helmet } from 'react-helmet-async'
import VideoContent from 'components/organisms/educations/VideoContent'
import { getEducationValidStatuses } from 'components/apis/educationAnnualPlans'
import { useNavigate } from 'react-router-dom'
import CustomEducationContent from 'components/organisms/educations/CustomEducationContent'
import { UserContext } from 'providers/UserProvider'

const HeadingText = styled('p')({
  fontWeight: 'bold',
  marginTop: 0,
})

const AlertText = styled('p')({
  fontWeight: 'bold',
  marginTop: 0,
  color: '#014361',
  display: 'inline',
})

function AdminEducationsIndex() {
  const navigate = useNavigate()
  const user = React.useContext(UserContext)

  const [educationValidStatuses, setEducationValidStatuses] = React.useState(
    Array(11).fill(true)
  )

  React.useEffect(() => {
    ;(async () => {
      const res = await getEducationValidStatuses()
      setEducationValidStatuses(res.data)
    })()
  }, [])

  const { data, isLoading } = useQuery([`admin/educations`, 'educations'], () =>
    fetchEducations().then((res) => res.data)
  )

  if (isLoading) {
    return <CircularProgressBox />
  }

  // 各条件でエラーメッセージの内容を変える
  const renderAlertText = () => {
    // 年度計画のコンテンツにscheduleに1つでも無効（nilか未来）な日程が設定されているかどうか
    if (educationValidStatuses.some((value) => value === false)) {
      return ALERT_TEXT.RESTRICTION_BY_EDUCATION_ANNUAL_PLAN
    }

    // 問題がない場合、アラートを表示しない
    return null
  }
  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.EDUCATIONS.INDEX ?? APPLICATION_NAME}</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1>{MENUS.educations.children.education_documents.title}</h1>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/admin/educations/new')}
          disabled={user.officeId === null} // 事業所に紐づけて管理するため、事業所IDを持たないユーザは作成できないようにしています。各事業所管理者が作成することを想定しており、本社管理者は作れないです。
        >
          新規作成
        </Button>
      </Box>
      {user.officeId === null && (
        <Box sx={{ textAlign: 'right' }}>
          <AlertText>
            カスタム項目を新規作成するには、事業所を設定してください。
          </AlertText>
        </Box>
      )}
      <Box sx={{ marginTop: '30px' }}>
        <BackGroundWhite smallPadding>
          <HeadingText>今月の項目</HeadingText>
          {data.currentMonthEducations.length === 0 ? (
            <EmptyCurrentContent />
          ) : (
            <>
              <Grid container spacing={2} justifyContent="flex-start">
                {data.currentMonthEducations.map(
                  (currentMonthEducation, index) => (
                    <React.Fragment key={index}>
                      {currentMonthEducation.category === 'custom' && (
                        <CustomEducationContent
                          education={currentMonthEducation}
                        />
                      )}
                      {currentMonthEducation.category === 'exam' && (
                        <EducationContent
                          education={currentMonthEducation}
                          isValid={
                            educationValidStatuses[currentMonthEducation.id - 1]
                          }
                        />
                      )}
                    </React.Fragment>
                  )
                )}
              </Grid>
              <Grid container spacing={2} justifyContent="flex-start">
                {data.currentMonthVideos.map(
                  (education: Education, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <VideoContent education={education} />
                      </React.Fragment>
                    )
                  }
                )}
              </Grid>
            </>
          )}
        </BackGroundWhite>
        <BackGroundWhite smallPadding>
          <HeadingText>
            {HEAD_TEXTS.DOWNLOAD_EDUCATION_DOCUMENT}
            {'　'}
            {renderAlertText() && <AlertText>{renderAlertText()}</AlertText>}
          </HeadingText>
          <Grid container spacing={2} justifyContent="flex-start">
            {data.educations.map((education: Education, i: number) => {
              return (
                <React.Fragment key={i}>
                  <EducationContent
                    education={education}
                    isValid={educationValidStatuses[i]}
                  />
                </React.Fragment>
              )
            })}
          </Grid>
        </BackGroundWhite>
        <BackGroundWhite smallPadding>
          <HeadingText>カスタム項目</HeadingText>
          <Grid container spacing={2} justifyContent="flex-start">
            {data.customEducations.map((education: Education, i: number) => {
              return (
                <React.Fragment key={i}>
                  <CustomEducationContent education={education} />
                </React.Fragment>
              )
            })}
          </Grid>
        </BackGroundWhite>
      </Box>
    </PageWrapper>
  )
}

export default AdminEducationsIndex
