import * as React from 'react'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { Box, styled } from '@mui/material'
import { palette, theme } from 'components/theme'
import { VEHICLES } from 'commons/constants'
import { FlexBox } from 'components/atoms/BoxComponents'

type PurchaseProps = {
  data: Vehicle
}

export default function Purchase(props: PurchaseProps) {
  const Title = styled('h2')({
    fontSize: '20px',
    margin: 0,
    marginBottom: '16px',
  })
  const Text = styled('p')({
    fontSize: '16px',
    margin: 0,
  })
  const Label = styled('p')({
    fontSize: '14px',
    margin: 0,
    color: palette.text.gray.pale,
    marginTop: '32px',
    marginBottom: '8px',
  })
  const TextWrapper = styled('div')({
    width: '300px',
    paddingBottom: '8px',
    paddingLeft: '4px',
    borderBottom: `1px solid ${palette.border.pale}`,
    overflowWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  })
  const TextWrapperShort = styled(TextWrapper)({
    width: '140px',
  })
  const TextWrapperFull = styled(TextWrapper)({
    width: '628px',
  })

  const purchaseType = {
    '0': 'なし',
    '1': 'あり',
  }

  const purchasedPrice = new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
  }).format(props.data.purchasedPrice)

  return (
    <BackGroundWhite>
      <Title>{VEHICLES.LABELS.PURCHASE}</Title>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.PURCHASE_TYPE}</Label>
          <TextWrapperShort>
            <Text>{purchaseType[props.data.isPurchased] || '未入力'}</Text>
          </TextWrapperShort>
        </Box>
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.PURCHASE_DATE}</Label>
          <TextWrapper>
            <Text>{props.data.purchasedDate || '未入力'}</Text>
          </TextWrapper>
        </Box>
        <Box>
          <Label>{VEHICLES.LABELS.PURCHASE_PRICE}</Label>
          <TextWrapperShort>
            <Text>{purchasedPrice || '未入力'}</Text>
          </TextWrapperShort>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.DEPRECATION_PERIOD}</Label>
          <TextWrapperShort>
            <Text>
              {props.data.depreciationPeriod
                ? `${props.data.depreciationPeriod}年`
                : '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.RETAILER}</Label>
          <TextWrapper>
            <Text>{props.data.retailer || '未入力'}</Text>
          </TextWrapper>
        </Box>
        <Box>
          <Label>{VEHICLES.LABELS.RETAILER_CHARGE_PERSON}</Label>
          <TextWrapperShort>
            <Text>{props.data.retailerChargePerson || '未入力'}</Text>
          </TextWrapperShort>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box>
          <Label>{VEHICLES.LABELS.RETAILER_ADDRESS}</Label>
          <TextWrapperFull>
            <Text>{props.data.retailerAddress || '未入力'}</Text>
          </TextWrapperFull>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box>
          <Label>{VEHICLES.LABELS.RETAILER_PHONE_NUMBER}</Label>
          <TextWrapperShort>
            <Text>{props.data.retailerPhoneNumber || '未入力'}</Text>
          </TextWrapperShort>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box>
          <Label>{VEHICLES.LABELS.PURCHASE_MEMO}</Label>
          <TextWrapperFull>
            <Text>{props.data.purchaseMemo || '未入力'}</Text>
          </TextWrapperFull>
        </Box>
      </FlexBox>
    </BackGroundWhite>
  )
}
