import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'components/organisms/NumberFormat'
import {
  ACCIDENTS,
  LOCAL_STORAGE_PAGE_KEY,
  VALIDATION_MESSAGES,
  ERRORS_TEXTS,
  ALERT_TEXT,
} from 'commons/constants'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomInput,
  ErrorMessage,
  CustomTextArea,
  CustomRadioGroup,
  CustomForm,
  DatePicker,
  CustomRadioButton,
  CustomSelect,
  CustomDropZone,
  CustomVideoDropZone,
  WithRequiredBadge,
  WithDescriptionTooltipIcon,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import {
  retrieveNumbers,
  toISOStringWithTimezone,
  isUnPlayableFile,
} from 'commons/functions'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { ENUMS } from 'commons/enums'
import {
  createAccident,
  fetchAccidents,
  updateAccident,
} from 'components/apis/accidents'
import { fetchDriverList } from 'components/apis/drivers'
import { fetchVehicleList } from 'components/apis/vehicles'
import CarouselComponent from 'components/atoms/ResponsiveCarousel'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { fetchS3ImagesV2 } from 'commons/fetchS3Images'
import { toastOnError, toastOnSuccess } from 'commons/toaster'
import { UserContext } from 'providers/UserProvider'
import { useErrorHandler } from 'react-error-boundary'
import { useQuery, useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import FormSubmitSelectPageModal from 'components/atoms/FormSubmitSelectPageModal'
import ProcessingModal from 'components/atoms/ProcessingModal'
import { ShouldSubmitOnKeyPress } from 'commons/formUtils'
import {
  getLocalStorageDisplayLimit,
  getLocalStorageSearchParams,
  getNextResourceId,
} from 'commons/table'
import { useNavigate } from 'react-router-dom'
import { slackNotification } from 'commons/slackNotification'
import VideoPlayerModal from 'components/atoms/videos/VideoPlayerModal'
import { VideoViewButton } from 'components/atoms/videos/VideoViewButton'
import { videoUrlStyle, videoFlexBoxStyle } from 'components/theme'
interface Props {
  type: 'create' | 'update'
  data?: Accident
}

export default function AccidentsForm(props: Props) {
  const user = React.useContext(UserContext)
  const handleError = useErrorHandler()
  const navigate = useNavigate()
  const [isProcessingModalShow, setIsProcessingModalShow] =
    React.useState(false)

  // 更新時の初期値
  const defaultValues = {
    driverId: props.type == 'create' ? user.id : null,
    ...props.data,
    date: props.data?.date ? new Date(props.data.date) : new Date(),
    isQuickReport: props.data?.isQuickReport ? props.data.isQuickReport : '0',
  }

  // ドライバー・車両のセレクトボックスの状態管理
  const [images, setImages] = React.useState<File[]>([])
  const [videos, setVideos] = React.useState<File[]>([])

  const [isModalShow, setModalShow] = React.useState(false)
  const [isMutationCalled, setIsMutationCalled] = React.useState(false)

  // 添付動画のModalの状態管理
  const [isVideoModalShow, setIsVideoModalShow] = React.useState(false)
  // 動画ファイルのURLの状態管理
  const [videoUrl, setVideoUrl] = React.useState('')

  const doMethod =
    props.type === 'create' && !isMutationCalled
      ? createAccident
      : updateAccident

  const mutation = useMutation({
    mutationFn: doMethod,
    onError: (e: AxiosError<{ errors: string[] }>) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors.join(','))
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
  })

  const mutationWithoutNavigation = useMutation({
    mutationFn: doMethod,
    onSuccess: (res) => {
      setIsMutationCalled(true)
      setValue('id', res.data.id)
      toastOnSuccess('保存しました')
    },
    onError: (e: AxiosError<{ errors: string[] }>) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors.join(','))
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
    onSettled: () => {
      setIsProcessingModalShow(false)
    },
  })

  // フォームオブジェクト
  const {
    register,
    getValues,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm<Accident>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: defaultValues,
  })

  // S3から画像URLを取得してFileオブジェクトに変換
  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3ImagesV2(defaultValues.attachedFiles)

      setImages(files)
    })()
  }, [defaultValues?.attachedFiles])

  // S3から動画URLを取得してFileオブジェクトに変換
  React.useEffect(() => {
    ;(async () => {
      const video_files = await fetchS3ImagesV2(
        defaultValues.attachedVideoFiles
      )

      setVideos(video_files)
    })()
  }, [defaultValues?.attachedVideoFiles])

  // ドライバー・車両のセレクトボックスのoptionsをAPIから取得
  const { data: drivers } = useQuery([`accidents/Form`, 'driverList'], () =>
    fetchDriverList().then((res) => {
      // "ドライバー 共通アカウント"ユーザー向けの処理
      // "ドライバー 共通アカウント"はセレクトの選択肢には表示しないため、driverIdの初期値もundefinedにする
      const currentDriver = res.data.drivers?.find(
        (value: DriverSelectOption) => String(value.id) === String(user.id)
      )
      if (!currentDriver) {
        setValue('driverId', undefined)
      }
      return Object.values(res.data.drivers).map(
        (value: DriverSelectOption) => {
          return { value: Number(value.id), label: value.fullName }
        }
      )
    })
  )

  const { data: vehicles } = useQuery([`accidents/Form`, 'vehicleList'], () =>
    fetchVehicleList().then((res) =>
      Object.values(res.data.vehicles).map((vehicle: Vehicle) => {
        return { value: String(vehicle.id), label: vehicle.number }
      })
    )
  )

  // 画像設定時のコールバック
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setImages([...images, ...acceptedFiles])
    },
    [images]
  )

  // 動画設定時のコールバック（合計500MB以内）
  const onDropVideo = React.useCallback(
    (acceptedVideoFiles) => {
      let totalSize = videos.reduce((total, video) => total + video.size, 0)
      totalSize += acceptedVideoFiles.reduce(
        (total, video) => total + video.size,
        0
      )

      if (totalSize >= ACCIDENTS.NUMBERS.MAX_SIZE_VIDEO_FILE) {
        toastOnError(ERRORS_TEXTS.EXCEEDED_MAX_VIDEO_FILE_SIZE)
      } else {
        setVideos([...videos, ...acceptedVideoFiles])
      }
    },
    [videos]
  )

  const formatParamsData = (data) => {
    const formData = new FormData()
    images.map((image) => {
      formData.append('files[]', image)
    })
    videos.map((video) => {
      formData.append('video_files[]', video)
    })

    const params = {
      ...data,
      date: toISOStringWithTimezone(data.date).split('T')[0],
      damageAmount: data?.damageAmount
        ? retrieveNumbers(data?.damageAmount)
        : undefined,
    }
    return { data: params, formData }
  }

  const submit = async (callback: () => void) => {
    setModalShow(false)
    const hasNotError = await trigger(undefined, { shouldFocus: true })
    if (hasNotError) {
      callback()
    } else {
      toastOnError('入力内容に不備があります')
    }
  }

  const submitCallback = async (to: 'show' | 'new' | 'next' | 'index') => {
    setIsProcessingModalShow(true)
    const copy = structuredClone(getValues())
    const { data, formData } = formatParamsData(copy)
    mutation.mutate(
      { data, formData },
      {
        onSuccess: (res) => {
          scrollTo({ top: 0 })
          if (to === 'new') {
            if (props.type === 'create') {
              return navigate(0)
            }
            navigate('/accidents/new/')
          } else if (to === 'show') {
            navigate(`/accidents/${res.data.id}/`)
          } else if (to === 'next') {
            navigateToNextResource(res.data.id)
          } else if (to === 'index') {
            navigate(`/accidents?focus_id=${res.data.id}`)
          }
        },
        onSettled: () => {
          setIsProcessingModalShow(false)
        },
      }
    )
  }

  /**
   *
   * @param id
   * - 「次のレコード」を取得して遷移するメソッド
   * - TODO: 全件取得してフロント側で「次のレコード」を見つける実装なのでdisplayLimitを越えた場合に機能しない。バックエンド側で「次のレコード」を見つけて返すようにしたい。
   */
  const navigateToNextResource = async (id: number) => {
    // localStorageから検索条件を取得
    const searchParams = getLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.ACCIDENTS
    )
    const countPerPage = getLocalStorageDisplayLimit()
    const accidentsPerDisplayLimit = await fetchAccidents(searchParams, {
      displayLimit: 1000,
    }).then((res) => {
      // 検索条件を適用してfetch, 結果をper毎に分割
      const arr = []
      while (res.data.accidents.length > 1) {
        const per = res.data.accidents.splice(0, countPerPage)
        arr.push(per)
      }
      return arr
    })
    // 「次のデータ」のidを取得
    const nextId = getNextResourceId<Accident>(
      LOCAL_STORAGE_PAGE_KEY.ACCIDENTS,
      id,
      accidentsPerDisplayLimit
    )
    if (nextId === null) {
      navigate(`/accidents/`)
      return
    }
    navigate(`/accidents/${nextId}/`)
  }

  /**
   * keyboardInputを監視し、Enterが押された場合は更新リクエストを叩く(成功時に遷移しない)
   */
  const handleKeyDown = async (e) => {
    if (ShouldSubmitOnKeyPress(e)) {
      const hasNotError = await trigger(undefined, { shouldFocus: true })
      if (hasNotError) {
        setIsProcessingModalShow(true)
        e.preventDefault()
        const copy = structuredClone(getValues())
        const { data, formData } = formatParamsData(copy)
        mutationWithoutNavigation.mutate({ data, formData })
        ;(document.activeElement as HTMLElement)?.blur()
      } else {
        toastOnError('入力内容に不備があります')
      }
    }
  }

  const handleModalCancelSelected = () => {
    setModalShow(false)
  }

  const showVideoModal = (video: File) => {
    setIsVideoModalShow(true)
    setVideoUrl(URL.createObjectURL(video))
  }

  return (
    <>
      <BackGroundWhite>
        <CustomForm onKeyDown={handleKeyDown}>
          <CustomLabel>
            <WithRequiredBadge>{ACCIDENTS.LABELS.CONTENT}</WithRequiredBadge>
            <CustomInput {...register('content', { required: true })} />
            {errors.content && (
              <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
            )}
          </CustomLabel>
          <CustomLabel>
            <WithRequiredBadge>{ACCIDENTS.LABELS.DATE}</WithRequiredBadge>
            <Controller
              control={control}
              name="date"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DatePicker onChange={onChange} selected={value} />
              )}
            />
            {errors.date && (
              <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
            )}
          </CustomLabel>
          <CustomLabel>
            <WithRequiredBadge>{ACCIDENTS.LABELS.DRIVER}</WithRequiredBadge>
            <Controller
              name="driverId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  options={drivers}
                  value={drivers?.find((c) => c.value === value)}
                  onChange={(option: ReactSelectOptionProps) =>
                    onChange(option.value)
                  }
                  placeholder="選択してください"
                />
              )}
            />
            {errors.driverId && (
              <ErrorMessage sx={{ marginTop: '0.5rem' }}>
                {VALIDATION_MESSAGES.required}
              </ErrorMessage>
            )}
          </CustomLabel>
          <CustomLabel>
            <WithRequiredBadge>
              {ACCIDENTS.LABELS.VEHICLE_NUMBER}
            </WithRequiredBadge>
            <Controller
              name="vehicleId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  options={vehicles}
                  value={vehicles?.find((c) => c.value === String(value))}
                  onChange={(option: ReactSelectOptionProps) =>
                    onChange(option.value)
                  }
                  placeholder="選択してください"
                />
              )}
            />
            {errors.vehicleId && (
              <ErrorMessage sx={{ marginTop: '0.5rem' }}>
                {VALIDATION_MESSAGES.required}
              </ErrorMessage>
            )}
          </CustomLabel>
          <CustomLabel>
            {ACCIDENTS.LABELS.DAMAGE_AMOUNT}
            <Controller
              render={({ field }) => (
                <NumberFormat
                  {...field}
                  customInput={CustomInput}
                  thousandSeparator={true}
                  prefix={'¥ '}
                />
              )}
              name="damageAmount"
              control={control}
            />
          </CustomLabel>

          <CustomLabel>
            {ACCIDENTS.LABELS.ACCIDENT_TYPE}
            <CustomRadioGroup>
              {Object.entries(ENUMS.ACCIDENTS.ACCIDENT_TYPE).map(
                ([key, obj], index) => (
                  <React.Fragment key={index}>
                    <CustomRadioButton
                      {...register('accidentType')}
                      id={key}
                      value={obj.value}
                      key={index}
                      type="radio"
                    />
                    <label htmlFor={key}>{obj.label}</label>
                  </React.Fragment>
                )
              )}
            </CustomRadioGroup>
          </CustomLabel>
          <FlexBox
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <CustomLabel>
              <WithRequiredBadge>
                {ACCIDENTS.LABELS.DAMAGED_OBJECT}
              </WithRequiredBadge>
              <CustomRadioGroup>
                {Object.entries(ENUMS.ACCIDENTS.DAMAGED_OBJECT).map(
                  ([key, obj], index) => (
                    <React.Fragment key={index}>
                      <CustomRadioButton
                        {...register('damagedObject', { required: true })}
                        id={key}
                        value={obj.value}
                        key={index}
                        type="radio"
                      />
                      <label htmlFor={key}>{obj.label}</label>
                    </React.Fragment>
                  )
                )}
              </CustomRadioGroup>
              {errors.damagedObject && (
                <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
              )}
            </CustomLabel>
            <CustomLabel>
              <WithRequiredBadge>
                {ACCIDENTS.LABELS.RESPONSIBILITY}
              </WithRequiredBadge>
              <CustomRadioGroup>
                {Object.entries(ENUMS.ACCIDENTS.RESPONSIBILITY).map(
                  ([key, obj], index) => (
                    <React.Fragment key={index}>
                      <CustomRadioButton
                        {...register('responsibility', { required: true })}
                        id={key}
                        value={obj.value}
                        type="radio"
                      />
                      <label htmlFor={key}>{obj.label}</label>
                    </React.Fragment>
                  )
                )}
              </CustomRadioGroup>
              {errors.responsibility && (
                <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
              )}
            </CustomLabel>
            <CustomLabel>
              {ACCIDENTS.LABELS.PRODUCT_ACCIDENT}
              <CustomRadioGroup>
                {Object.entries(ENUMS.ACCIDENTS.PRODUCT_ACCIDENT).map(
                  ([key, obj], index) => (
                    <React.Fragment key={index}>
                      <CustomRadioButton
                        {...register('isProductAccident')}
                        id={`isProductAccident_${key}`}
                        value={obj.value}
                        type="radio"
                      />
                      <label htmlFor={`isProductAccident_${key}`}>
                        {obj.label}
                      </label>
                    </React.Fragment>
                  )
                )}
              </CustomRadioGroup>
            </CustomLabel>
            <CustomLabel>
              <WithRequiredBadge>{ACCIDENTS.LABELS.STATUS}</WithRequiredBadge>
              <CustomRadioGroup sx={{ width: '300px' }}>
                {Object.entries(ENUMS.ACCIDENTS.STATUS).map(
                  ([key, obj], index) => (
                    <React.Fragment key={index}>
                      <CustomRadioButton
                        {...register('status', { required: true })}
                        id={key}
                        value={obj.value}
                        type="radio"
                      />
                      <label htmlFor={key}>{obj.label}</label>
                    </React.Fragment>
                  )
                )}
              </CustomRadioGroup>
              {errors.status && (
                <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
              )}
            </CustomLabel>
          </FlexBox>

          <CustomLabel>
            {ACCIDENTS.LABELS.QUICK_REPORT}
            <CustomRadioGroup>
              {Object.entries(ENUMS.ACCIDENTS.QUICK_REPORT).map(
                ([key, obj], index) => (
                  <React.Fragment key={index}>
                    <CustomRadioButton
                      {...register('isQuickReport')}
                      id={`isQuickReport_${key}`}
                      value={obj.value}
                      type="radio"
                    />
                    <label htmlFor={`isQuickReport_${key}`}>{obj.label}</label>
                  </React.Fragment>
                )
              )}
            </CustomRadioGroup>
          </CustomLabel>

          <CustomLabel>
            {ACCIDENTS.LABELS.CAUSE}
            <CustomTextArea rows={5} {...register('cause')} />
          </CustomLabel>

          <CustomLabel>
            {ACCIDENTS.LABELS.MEASURES}
            <CustomTextArea rows={5} {...register('measures')} />
          </CustomLabel>

          <CustomLabel>
            {ACCIDENTS.LABELS.IMAGE}
            <Controller
              render={() => (
                <CustomDropZone
                  multiple={true}
                  onDrop={onDrop}
                  accept={'image/*, application/pdf'}
                />
              )}
              name="files"
              control={control}
            />
            {Object.values(images).map((image, index) => (
              <FlexBox flexDirection={'row'} key={index}>
                <p>
                  <a
                    href={URL.createObjectURL(image)}
                    download={decodeURI(image.name)}
                  >
                    {decodeURI(image.name)}
                  </a>
                </p>
                <IconButton
                  component="span"
                  onClick={() =>
                    setImages(images.filter((_, i) => i !== index))
                  }
                >
                  <CancelIcon />
                </IconButton>
              </FlexBox>
            ))}
            <CarouselComponent images={images} />
          </CustomLabel>

          <CustomLabel>
            {ACCIDENTS.LABELS.VIDEO}
            <Controller
              render={() => (
                <CustomVideoDropZone
                  multiple={true}
                  onDrop={onDropVideo}
                  maxSize={ACCIDENTS.NUMBERS.MAX_SIZE_VIDEO_FILE}
                  onDropRejected={() => {
                    toastOnError(ERRORS_TEXTS.EXCEEDED_MAX_VIDEO_FILE_SIZE)
                  }}
                />
              )}
              name="attachedVideoFiles"
              control={control}
            />
            {Object.values(videos).map((video, index) => (
              <FlexBox
                flexDirection={'row'}
                key={index}
                style={videoFlexBoxStyle}
              >
                <VideoPlayerModal
                  isModalOpen={isVideoModalShow}
                  setIsModalOpen={setIsVideoModalShow}
                  videoUrl={videoUrl}
                />
                <VideoViewButton
                  onClick={() => showVideoModal(video)}
                  disabled={isUnPlayableFile(video)}
                />
                <p style={videoUrlStyle}>
                  <a
                    href={URL.createObjectURL(video)}
                    download={decodeURI(video.name)}
                  >
                    {decodeURI(video.name)}
                  </a>
                </p>
                <IconButton
                  component="span"
                  onClick={() =>
                    setVideos(videos.filter((_, i) => i !== index))
                  }
                >
                  <CancelIcon />
                </IconButton>
                {isUnPlayableFile(video) && (
                  <div style={{ marginTop: '19px' }}>
                    <WithDescriptionTooltipIcon
                      text={`${ALERT_TEXT.AVI_VIDEO_ALERT_CREATE_OR_UPDATE}`}
                    ></WithDescriptionTooltipIcon>
                  </div>
                )}
              </FlexBox>
            ))}
          </CustomLabel>
          <CustomLabel>
            {ACCIDENTS.LABELS.MEMO}
            <CustomTextArea rows={5} {...register('memo')} />
            {errors.memo && (
              <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
            )}
          </CustomLabel>
        </CustomForm>
      </BackGroundWhite>
      <FlexBox justifyContent={'flex-end'} sx={{ marginTop: '8px' }}>
        <ApplyButton
          pattern="block"
          onClick={(e) => {
            e.preventDefault()
            setModalShow(true)
          }}
        >
          保存する
        </ApplyButton>
      </FlexBox>
      <FormSubmitSelectPageModal
        isOpen={isModalShow}
        resourceName="事故報告書"
        handlers={{
          cancel: handleModalCancelSelected,
          toShow: () => submit(() => submitCallback('show')),
          toIndex: () => submit(() => submitCallback('index')),
        }}
      ></FormSubmitSelectPageModal>
      <ProcessingModal isOpen={isProcessingModalShow} />
    </>
  )
}
