import { AxiosResponse } from 'axios'
import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { camelCaseKeysToUnderscore } from 'commons/functions'

export function getCsvHeaderAssociation(
  category: string
): Promise<AxiosResponse<DriverImport | VehicleImport>> {
  return axios.get(
    `${API_ENDPOINTS.CSV_HEADER_ASSOCIATIONS}?category=${category}`
  )
}

export function updateCsvHeaderAssociation(params) {
  return axios.patch(
    API_ENDPOINTS.CSV_HEADER_ASSOCIATIONS,
    camelCaseKeysToUnderscore(params)
  )
}

export function checkCreatedAssociationCategories() {
  return axios.get(
    `${API_ENDPOINTS.CSV_TEMPLATE}check_created_association_categories`
  )
}
