import * as React from 'react'
import { UserContext } from 'providers/UserProvider'
import { FlexBox } from 'components/atoms/BoxComponents'

function NotShowVideoModal() {
  const user = React.useContext(UserContext)

  const handleBack = () => {
    if (window.history.length > 1) {
      window.history.back()
    } else {
      window.close()
    }
  }

  return (
    <>
      <h3>
        動画の視聴が終わっていないか、最後に視聴してから30日が経過しています。
        <br />
        動画の視聴完了後、再度お試しください。
      </h3>
      {user.role === 'system_admin' && (
        <FlexBox sx={{ marginBottom: '20px' }}>
          テストの受講制限は
          <a href="/admin/educations/settings/">各種設定</a>
          から変更できます。
        </FlexBox>
      )}
      <button onClick={handleBack}>前のページに戻る</button>
    </>
  )
}

export default NotShowVideoModal
