import * as React from 'react'
import {
  CustomLabel,
  CustomInputShort,
  CustomDateSelect,
  CustomTextArea,
  CustomInput,
  CustomRadioButton,
  CustomRadioGroup,
  WithDescriptionTooltipIcon,
  CustomDropZone,
} from 'components/atoms/FormComponents'
import {
  Control,
  FieldErrors,
  UseFormRegister,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { BackGroundPalePink } from 'components/atoms/BackGroundComponents'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { styled } from '@mui/styles'
import { FlexBox } from 'components/atoms/BoxComponents'
import { ENUMS } from 'commons/enums'
import { VEHICLES, TOOLTIP_TEXTS } from 'commons/constants'
import { theme } from 'components/theme'
import NumberFormat from 'components/organisms/NumberFormat'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

type InspectionThreeMonthsCardProps = {
  register: UseFormRegister<Vehicle>
  control: Control<Vehicle>
  setValue: UseFormSetValue<Vehicle>
  watch: UseFormWatch<Vehicle>
  errors?: FieldErrors<Vehicle>
  appendEmptyItem: () => void
  deleteItem: (index: number) => void
  index: number
}

const InspectionThreeMonthsCard: React.FC<InspectionThreeMonthsCardProps> = (
  props
) => {
  const { register, control, setValue, watch, index } = props
  const CardTitle = styled('p')({
    fontWeight: 'bold',
  })
  const MidiumCustomRadioGroup = styled(CustomRadioGroup)({
    width: '270px',
  })
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const old_files = watch(`inspectionsOthers.${index}.files`)
        ? watch(`inspectionsOthers.${index}.files`)
        : []
      setValue(`inspectionsOthers.${index}.files`, [
        ...old_files,
        ...acceptedFiles,
      ])
    },
    [watch(`inspectionsOthers.${index}.files`)]
  )

  return (
    <BackGroundPalePink sx={{ marginBottom: '30px' }}>
      <CardTitle>
        {VEHICLES.LABELS.INSPECTIONS_OTHERS}
        {index + 1}
      </CardTitle>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.INSPECTIONS_INSPECT_DATE}
        <CustomDateSelect
          formKeys={{
            year: `inspectionsOthers.${index}.inspectYear`,
            month: `inspectionsOthers.${index}.inspectMonth`,
            day: `inspectionsOthers.${index}.inspectDay`,
          }}
          control={control}
        />
      </CustomLabel>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.INSPECTIONS_TOTAL_FEE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`inspectionsOthers.${index}.totalFee`}
            control={control}
          />
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.INSPECTIONS_PARTS_FEE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`inspectionsOthers.${index}.partsFee`}
            control={control}
          />
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.INSPECTIONS_TECHNICAL_FEE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`inspectionsOthers.${index}.technicalFee`}
            control={control}
          />
        </CustomLabel>
      </FlexBox>
      <FlexBox>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.INSPECTIONS_COMPANY_NAME}
          <CustomInput
            {...register(`inspectionsOthers.${index}.companyName`)}
          />
        </CustomLabel>
      </FlexBox>
      <FlexBox>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.INSPECTIONS_MILEAGE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`inspectionsOthers.${index}.mileage`}
            control={control}
          />
        </CustomLabel>
      </FlexBox>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.INSPECTIONS_NEXT_DATE}
        <FlexBox alignItems="center">
          <CustomDateSelect
            formKeys={{
              year: `inspectionsOthers.${index}.nextYear`,
              month: `inspectionsOthers.${index}.nextMonth`,
              day: `inspectionsOthers.${index}.nextDay`,
            }}
            control={control}
          />
          <WithDescriptionTooltipIcon
            text={`${TOOLTIP_TEXTS.NOTIFICATION_AUTO_CREATE}${TOOLTIP_TEXTS.VEHICLE_NOTIFICATION_TARGET_USER}`}
          ></WithDescriptionTooltipIcon>
        </FlexBox>
      </CustomLabel>
      <CustomLabel>
        {VEHICLES.LABELS.INSPECTIONS_IS_ACCEPTABLE}
        <MidiumCustomRadioGroup>
          {Object.entries(ENUMS.VEHICLES.INSPETION_RESULTS).map(
            ([key, obj]) => (
              <React.Fragment key={key}>
                <CustomRadioButton
                  {...props.register(`inspectionsOthers.${index}.isAcceptable`)}
                  id={`isAcceptable_${obj.value}_${index}`}
                  value={obj.value}
                  type="radio"
                />
                <label htmlFor={`isAcceptable_${obj.value}_${index}`}>
                  {obj.label}
                </label>
              </React.Fragment>
            )
          )}
        </MidiumCustomRadioGroup>
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.INSPECTIONS_MEMO}
        <CustomTextArea {...register(`inspectionsOthers.${index}.memo`)} />
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.FILE_UPLOAD}(
        {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
        <Controller
          render={() => (
            <CustomDropZone
              multiple={true}
              onDrop={onDrop}
              accept={'image/*, application/pdf'}
            />
          )}
          name={`inspectionsOthers.${index}.fileUrls`}
          control={control}
        />
        {watch(`inspectionsOthers.${index}.files`) &&
          Object.values(watch(`inspectionsOthers.${index}.files`))?.map(
            (image: File, fileIndex: number) => (
              <FlexBox flexDirection={'row'} key={fileIndex}>
                <p>
                  <a
                    href={URL.createObjectURL(image)}
                    download={decodeURI(image.name)}
                  >
                    {decodeURI(image.name)}
                  </a>
                </p>
                <IconButton
                  component="span"
                  onClick={() =>
                    setValue(
                      `inspectionsOthers.${index}.files`,
                      watch(`inspectionsOthers.${index}.files`).filter(
                        (_, i) => i !== fileIndex
                      )
                    )
                  }
                >
                  <CancelIcon />
                </IconButton>
              </FlexBox>
            )
          )}
      </CustomLabel>
      <DeleteButton onClick={() => props.deleteItem(index)}>削除</DeleteButton>
    </BackGroundPalePink>
  )
}

export default InspectionThreeMonthsCard
