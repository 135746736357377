import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BackGroundGray } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomForm,
  DatePicker,
  ResetButton,
  WithEmptyOption,
  CustomSelect,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Box, Typography } from '@mui/material'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { styles } from 'components/theme'
import { fetchDriverList } from 'components/apis/drivers'
import { toISOStringWithTimezone } from 'commons/functions'
import { useQuery } from '@tanstack/react-query'
import SearchCompanyAndOffice from 'components/atoms/ReactHookFormPartials/SearchForm/SearchCompanyAndOffice'
import {
  getLocalStorageSearchParams,
  setLocalStorageSearchParams,
  getLocalStorageDisplayLimit,
  removeLocalStoragePageNumber,
} from 'commons/table'
import { LOCAL_STORAGE_PAGE_KEY } from 'commons/constants'

interface Props {
  setSearchParams: React.Dispatch<React.SetStateAction<SearchAlcoholCheck>>
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  defaultSearchParams: SearchAlcoholCheck
  isLoading?: boolean
}

function SearchForm(props: Props) {
  const {
    setSearchParams,
    setPaginateParams,
    setPage,
    defaultSearchParams,
    isLoading = false,
  } = props
  React.useEffect(() => {
    const searchParams = getLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.ALCOHOL_CHECKS
    )
    if (searchParams) {
      // リクエストで送るparamsのstate
      setSearchParams(searchParams)
      // formのvalue
      Object.keys(searchParams).forEach((key: keyof SearchAlcoholCheck) => {
        setValue(key, searchParams[key])
      })
    }
  }, [])

  // フォームオブジェクト
  const { handleSubmit, control, reset, getValues, setValue, watch } =
    useForm<SearchAlcoholCheck>({
      mode: 'onSubmit',
    })

  // ドライバー・車両のセレクトボックスのoptionsをAPIから取得
  const { data: drivers } = useQuery(
    [`admin/alcohol_checks/SearchForm`, 'driver_list'],
    () =>
      fetchDriverList().then((res) =>
        Object.values(res.data.drivers).map((value: DriverSelectOption) => {
          return { value: value.id, label: value.fullName }
        })
      )
  )

  // 提出状況の選択肢
  const submitSelectOptions: SelectOption[] = [
    { value: 'not_submitted', label: '未提出のみ' },
  ]
  // フォームsubmit時のコールバック
  const onSubmit = (data: SearchAlcoholCheck) => {
    const params = {
      ...data,
      createdAt: data.createdAt
        ? toISOStringWithTimezone(data.createdAt as Date).split('T')[0]
        : undefined,
    }
    setSearchParams(params)
    setLocalStorageSearchParams(LOCAL_STORAGE_PAGE_KEY.ALCOHOL_CHECKS, data)
  }

  const resetParams = () => {
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = 1

    setPaginateParams({ pageNumber, displayLimit: displayLimit })
    setPage(pageNumber)
    removeLocalStoragePageNumber(LOCAL_STORAGE_PAGE_KEY.ALCOHOL_CHECKS)
    setSearchParams(defaultSearchParams)
    setLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.ALCOHOL_CHECKS,
      defaultSearchParams
    )
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '90%' }}>
        <BackGroundGray>
          <Typography
            sx={{ marginBottom: '20px', fontWeight: 'bold' }}
            variant={'body1'}
          >
            条件で絞り込み
          </Typography>
          <CustomForm onSubmit={handleSubmit(onSubmit)}>
            <FlexBox>
              <SearchCompanyAndOffice
                companyKeyName={'companyId'}
                officeKeyName={'officeId'}
                control={control}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
              />
            </FlexBox>
            <FlexBox>
              <CustomLabel>
                作成日
                <FlexBox>
                  <Controller
                    control={control}
                    name="createdAt"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <DatePicker
                          onChange={onChange}
                          selected={value && new Date(value)}
                          sx={{ width: `${styles.shortFormWidth}px` }}
                        />
                      )
                    }}
                  />
                </FlexBox>
              </CustomLabel>
            </FlexBox>
            <CustomLabel>
              ドライバー
              <FlexBox>
                <Controller
                  name="driverId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      options={WithEmptyOption(drivers)}
                      value={drivers?.find((c) => c.value === value)}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option.value)
                      }
                      placeholder="全て"
                    />
                  )}
                />
              </FlexBox>
            </CustomLabel>
            <CustomLabel>
              提出状況
              <FlexBox>
                <Controller
                  name="submitStatus"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      options={WithEmptyOption(submitSelectOptions)}
                      value={submitSelectOptions?.find(
                        (c) => c.value === value
                      )}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option.value)
                      }
                      placeholder="全て"
                    />
                  )}
                />
              </FlexBox>
            </CustomLabel>

            <ApplyButton pattern="inRow" type="submit" disabled={isLoading}>
              絞り込む
            </ApplyButton>
            <ResetButton
              onClick={() => {
                reset()
                resetParams()
              }}
              disabled={isLoading}
            >
              <Typography>リセット</Typography>
            </ResetButton>
          </CustomForm>
        </BackGroundGray>
      </Box>
    </Box>
  )
}

export default SearchForm
