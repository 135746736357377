import * as React from 'react'
import { ADMIN_MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import ArticleMaintenancesForm from 'components/organisms/articles/maintenances/Form'

function ArticleMaintenancesNew() {
  return (
    <PageWrapper>
      <h1>{ADMIN_MENUS.articles.children.article_maintenances.title}</h1>
      <ArticleMaintenancesForm type="create" />
    </PageWrapper>
  )
}

export default ArticleMaintenancesNew
