import * as React from 'react'
import { styled } from '@mui/styles'
import { Box } from '@mui/material'
import { palette } from 'components/theme'
import { FooterMenus } from 'commons/constants'
import { FlexBox } from 'components/atoms/BoxComponents'

const FooterContainer = styled(Box)({
  position: 'relative',
  bottom: 0,
  height: '30px',
  color: palette.text.gray.pale,
  marginLeft: '10px',
  width: '100%',
  paddingLeft: '10px',
})

const FooterLinkElement = styled('a')({
  color: 'inherit',
  textDecoration: 'none',
})

function Footer() {
  return (
    <FooterContainer>
      <FlexBox flexDirection={{ xs: 'column', sm: 'row' }}>
        {Object.values(FooterMenus).map((menu: Menu, index: number) => {
          return (
            <Box sx={{ marginTop: '1rem', marginRight: '1rem' }} key={index}>
              <FooterLinkElement href={menu.url} key={index}>
                {menu.title}
              </FooterLinkElement>
            </Box>
          )
        })}
      </FlexBox>
      <p>© X Mile, Inc.</p>
    </FooterContainer>
  )
}

export default Footer
