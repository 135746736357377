import * as React from 'react'
import { FlexBox } from 'components/atoms/BoxComponents'
import { useForm, Controller } from 'react-hook-form'
import { Box } from '@mui/material'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import Modal from 'react-modal'
import { palette } from 'components/theme'
import {
  CustomLabel,
  CustomSelectShort,
  CustomForm,
} from 'components/atoms/FormComponents'
import { yearRangeFrom2000 } from 'commons/date'
import CompanyAndOfficeSelect from 'components/atoms/ReactHookFormPartials/InputForm/CompanyAndOfficeSelect'
import { UserContext } from 'providers/UserProvider'

export default function EducationNonattendanceListModal(props: {
  educationId: string
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const user = React.useContext(UserContext)
  const { getValues, setValue, control, watch, handleSubmit } =
    useForm<ExportNonattendanceList>({
      mode: 'onSubmit',
      defaultValues: {
        year: user.company.thisYear || new Date().getFullYear(),
      },
    })

  const onSubmit = (data) => {
    let nonattendanceListUrl = `/nonattendance_lists/show.pdf?id=${props.educationId}&year=${data.year}`
    getValues('companyId') &&
      (nonattendanceListUrl += `&company_id=${getValues('companyId')}`)
    getValues('officeId') &&
      (nonattendanceListUrl += `&office_id=${getValues('officeId')}`)
    window.open(nonattendanceListUrl, '_blank')
    props.setShowModal(false)
  }

  return (
    <Modal
      isOpen={props.showModal}
      style={{
        overlay: {
          minHeight: '100%',
          zIndex: 1000,
        },
        content: {
          backgroundColor: palette.background.main,
          maxHeight: '600px',
          maxWidth: '440px',
          marginLeft: 0,
          margin: 'auto',
          display: 'flex',
          flexFlow: 'wrap',
          overFlow: 'hidden',
          flexDirection: 'column',
          justifyContents: 'center',
          alignItems: 'center',
        },
      }}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        props.setShowModal(false)
      }}
    >
      <h2>未受講者一覧PDF出力</h2>
      <Box sx={{ justifyContents: 'left' }}>
        <CustomLabel sx={{ maxWidth: '420px' }}>
          選択した年度、事業所の未受講者を出力します。事業所が未選択の場合は全社の未受講者を出力します。
        </CustomLabel>
        <FlexBox>
          <CustomLabel>
            年度
            <Controller
              name="year"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelectShort
                  options={yearRangeFrom2000}
                  value={yearRangeFrom2000?.find((c) => c.value === value)}
                  onChange={(option: ReactSelectOptionProps) =>
                    onChange(option.value)
                  }
                />
              )}
            />
          </CustomLabel>
        </FlexBox>
        <CompanyAndOfficeSelect
          companyKeyName={'companyId'}
          officeKeyName={'officeId'}
          control={control}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
        />
      </Box>
      <FlexBox>
        <CustomForm onSubmit={handleSubmit(onSubmit)}>
          <ApplyButton pattern="block" type="submit">
            出力
          </ApplyButton>
        </CustomForm>
      </FlexBox>
      <div style={{ position: 'absolute', bottom: 20 }}>
        <CancelButtonOutlined onClick={() => props.setShowModal(false)}>
          キャンセル
        </CancelButtonOutlined>
      </div>
    </Modal>
  )
}
