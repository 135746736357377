import * as React from 'react'
import { useForm } from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { FlexBox } from 'components/atoms/BoxComponents'
import {
  CustomLabel,
  CustomInput,
  WithRequiredBadge,
  CustomForm,
} from 'components/atoms/FormComponents'
import ProcessingModal from 'components/atoms/ProcessingModal'
import { toastOnError, toastOnSuccess } from 'commons/toaster'
import { ShouldSubmitOnKeyPress } from 'commons/formUtils'
import { updateCsvHeaderAssociation } from 'components/apis/csvHeaderAssociations'
import { UserContext } from 'providers/UserProvider'
import { slackNotification } from 'commons/slackNotification'
import { AxiosError } from 'axios'
import { useErrorHandler } from 'react-error-boundary'
import { useMutation } from '@tanstack/react-query'
import { CSV_HEADER_ASSOCIATIONS } from 'commons/constants'

interface Props {
  data?: VehicleImport
}

function IsDuplication(data: VehicleImport) {
  delete data['category']
  const values = Object.values(data).filter(Boolean)
  const uniqueValues = new Set(values)
  return values.length !== uniqueValues.size
}

function defaultHeader(str: string, template: string) {
  if (str === undefined) {
    return template
  }

  return str
}

function VehicleImportForm(props: Props) {
  const user = React.useContext(UserContext)
  const handleError = useErrorHandler()
  const [isProcessingModalShow, setIsProcessingModalShow] =
    React.useState(false)
  const defaultValues = {
    ...props.data,
    category: 'vehicle',
    number: defaultHeader(
      props.data?.number,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.NUMBER
    ),
    uniqueNumber: defaultHeader(
      props.data?.uniqueNumber,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.UNIQUE_NUMBER
    ),
    officeName: defaultHeader(
      props.data?.officeName,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.OFFICE_NAME
    ),
    name: defaultHeader(
      props.data?.name,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.NAME
    ),
    registeredDate: defaultHeader(
      props.data?.registeredDate,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.REGISTERED_DATE
    ),
    firstRegisteredDate: defaultHeader(
      props.data?.firstRegisteredDate,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.FIRST_REGISTERED_DATE
    ),
    manufacture: defaultHeader(
      props.data?.manufacture,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.MANUFACTURE
    ),
    personCapacity: defaultHeader(
      props.data?.personCapacity,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.PERSON_CAPACITY
    ),
    weightCapacity1: defaultHeader(
      props.data?.weightCapacity1,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.WEIGHT_CAPACITY1
    ),
    weightCapacity2: defaultHeader(
      props.data?.weightCapacity2,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.WEIGHT_CAPACITY2
    ),
    weightCapacity3: defaultHeader(
      props.data?.weightCapacity3,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.WEIGHT_CAPACITY3
    ),
    totalWeight1: defaultHeader(
      props.data?.totalWeight1,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.TOTAL_WEIGHT1
    ),
    totalWeight2: defaultHeader(
      props.data?.totalWeight2,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.TOTAL_WEIGHT2
    ),
    totalWeight3: defaultHeader(
      props.data?.totalWeight3,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.TOTAL_WEIGHT3
    ),
    tonnage: defaultHeader(
      props.data?.tonnage,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.TONNAGE
    ),
    outerLength: defaultHeader(
      props.data?.outerLength,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.OUTER_LENGTH
    ),
    outerWidth: defaultHeader(
      props.data?.outerWidth,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.OUTER_WIDTH
    ),
    outerHeight: defaultHeader(
      props.data?.outerHeight,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.OUTER_HEIGHT
    ),
    engineDisplacement: defaultHeader(
      props.data?.engineDisplacement,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.ENGINE_DISPLACEMENT
    ),
    fuelType: defaultHeader(
      props.data?.fuelType,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.FUEL_TYPE
    ),
    fuelTankCapacity1: defaultHeader(
      props.data?.fuelTankCapacity1,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.FUEL_TANK_CAPACITY1
    ),
    fuelTankCapacity2: defaultHeader(
      props.data?.fuelTankCapacity2,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.FUEL_TANK_CAPACITY2
    ),
    fuelTankCapacity3: defaultHeader(
      props.data?.fuelTankCapacity3,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.FUEL_TANK_CAPACITY3
    ),
    fuelTankCapacity4: defaultHeader(
      props.data?.fuelTankCapacity4,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.FUEL_TANK_CAPACITY4
    ),
    etcDevice: defaultHeader(
      props.data?.etcDevice,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.ETC_DEVICE
    ),
    etcCard1: defaultHeader(
      props.data?.etcCard1,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.ETC_CARD1
    ),
    etcCard2: defaultHeader(
      props.data?.etcCard2,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.ETC_CARD2
    ),
    etcCard3: defaultHeader(
      props.data?.etcCard3,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.ETC_CARD3
    ),
    etcCard4: defaultHeader(
      props.data?.etcCard4,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.ETC_CARD4
    ),
    etcCard5: defaultHeader(
      props.data?.etcCard5,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.ETC_CARD5
    ),
    purchasedPrice: defaultHeader(
      props.data?.purchasedPrice,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.PURCHASED_PRICE
    ),
    memo: defaultHeader(
      props.data?.memo,
      CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.MEMO
    ),
  }
  const { register, getValues, trigger } = useForm<VehicleImport>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: defaultValues,
  })

  const mutation = useMutation({
    mutationFn: updateCsvHeaderAssociation,
    onError: (e: AxiosError<{ errors: string[] }>) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors.join(','))
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
  })

  const mutationWithoutNavigation = useMutation({
    mutationFn: updateCsvHeaderAssociation,
    onSuccess: () => {
      toastOnSuccess('保存しました')
    },
    onError: (e: AxiosError<{ errors: string[] }>) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors.join(','))
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
    onSettled: () => {
      setIsProcessingModalShow(false)
    },
  })

  const submit = async (callback: () => void) => {
    const hasNotError = await trigger(undefined, { shouldFocus: true })
    const isDuplication = IsDuplication(getValues())
    if (isDuplication) {
      toastOnError(
        'ヘッダーの項目名に重複があります。重複がなくなるように修正してください。'
      )
    } else if (hasNotError) {
      callback()
    } else {
      toastOnError('入力内容に不備があります')
    }
  }

  const submitCallback = async () => {
    setIsProcessingModalShow(true)
    const data = structuredClone(getValues())
    mutation.mutate(data, {
      onSuccess: () => {
        scrollTo({ top: 0 })
        toastOnSuccess('保存しました')
      },
      onSettled: () => {
        setIsProcessingModalShow(false)
      },
    })
  }

  const handleKeyDown = async (e) => {
    if (ShouldSubmitOnKeyPress(e)) {
      const hasNotError = await trigger(undefined, { shouldFocus: true })
      const isDuplication = IsDuplication(getValues())
      if (isDuplication) {
        toastOnError(
          'ヘッダーの項目名に重複があります。重複がなくなるように修正してください。'
        )
      } else if (hasNotError) {
        setIsProcessingModalShow(true)
        e.preventDefault()
        const params = structuredClone(getValues())
        mutationWithoutNavigation.mutate(params)
        ;(document.activeElement as HTMLElement)?.blur()
      } else {
        toastOnError('入力内容に不備があります')
      }
    }
  }

  return (
    <>
      <BackGroundWhite>
        <CustomForm onKeyDown={handleKeyDown}>
          <CustomLabel>
            車両管理の画面項目とインポートするCSVのヘッダー項目を紐づけるための画面です。
            <br />
            各画面項目に紐づけたいCSVヘッダーの項目名を入力してください。
          </CustomLabel>
          <CustomLabel>
            <WithRequiredBadge>
              {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.NUMBER}
            </WithRequiredBadge>
            <CustomInput {...register('number', { required: true })} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.UNIQUE_NUMBER}
            <CustomInput {...register('uniqueNumber')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.OFFICE_NAME}
            <CustomInput {...register('officeName')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.NAME}
            <CustomInput {...register('name')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.REGISTERED_DATE}
            <CustomInput {...register('registeredDate')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT
                .FIRST_REGISTERED_DATE
            }
            <CustomInput {...register('firstRegisteredDate')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.MANUFACTURE}
            <CustomInput {...register('manufacture')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.PERSON_CAPACITY}
            <CustomInput {...register('personCapacity')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.WEIGHT_CAPACITY1}
            <CustomInput {...register('weightCapacity1')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.WEIGHT_CAPACITY2}
            <CustomInput {...register('weightCapacity2')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.WEIGHT_CAPACITY3}
            <CustomInput {...register('weightCapacity3')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.TOTAL_WEIGHT1}
            <CustomInput {...register('totalWeight1')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.TOTAL_WEIGHT2}
            <CustomInput {...register('totalWeight2')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.TOTAL_WEIGHT3}
            <CustomInput {...register('totalWeight3')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.TONNAGE}
            <CustomInput {...register('tonnage')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.OUTER_LENGTH}
            <CustomInput {...register('outerLength')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.OUTER_WIDTH}
            <CustomInput {...register('outerWidth')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.OUTER_HEIGHT}
            <CustomInput {...register('outerHeight')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.ENGINE_DISPLACEMENT}
            <CustomInput {...register('engineDisplacement')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.FUEL_TYPE}
            <CustomInput {...register('fuelType')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.FUEL_TANK_CAPACITY1}
            <CustomInput {...register('fuelTankCapacity1')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.FUEL_TANK_CAPACITY2}
            <CustomInput {...register('fuelTankCapacity2')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.FUEL_TANK_CAPACITY3}
            <CustomInput {...register('fuelTankCapacity3')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.FUEL_TANK_CAPACITY4}
            <CustomInput {...register('fuelTankCapacity4')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.ETC_DEVICE}
            <CustomInput {...register('etcDevice')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.ETC_CARD1}
            <CustomInput {...register('etcCard1')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.ETC_CARD2}
            <CustomInput {...register('etcCard2')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.ETC_CARD3}
            <CustomInput {...register('etcCard3')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.ETC_CARD4}
            <CustomInput {...register('etcCard4')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.ETC_CARD5}
            <CustomInput {...register('etcCard5')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.PURCHASED_PRICE}
            <CustomInput {...register('purchasedPrice')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.VEHICLE_IMPORT.MEMO}
            <CustomInput {...register('memo')} />
          </CustomLabel>
        </CustomForm>
      </BackGroundWhite>
      <FlexBox justifyContent={'flex-end'} sx={{ marginTop: '8px' }}>
        <ApplyButton
          pattern="block"
          onClick={() => submit(() => submitCallback())}
        >
          保存する
        </ApplyButton>
      </FlexBox>
      <ProcessingModal isOpen={isProcessingModalShow} />
    </>
  )
}

export default VehicleImportForm
