import { AxiosResponse } from 'axios'
import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'

export function getEducationTests(
  id: number,
  driverId: number,
  releaseVersion?: number
): Promise<AxiosResponse<{ questionAnswers: QuestionAnswer[] }>> {
  return axios.get([API_ENDPOINTS.EDUCATION_TESTS, id].join(''), {
    params: { driver_id: driverId, release_version: releaseVersion },
  })
}

export function getEducationTestHistories(
  userId: string,
  educationId: string,
  year: string
): Promise<AxiosResponse<{ score: number | null; selectedAnswers: number[] }>> {
  return axios.get(
    [API_ENDPOINTS.DRIVERS_EDUCATION_TEST_HISTORY, educationId].join(''),
    {
      params: {
        user_id: userId,
        education_id: educationId,
        year: year,
      },
    }
  )
}
