import * as React from 'react'
import { ADMIN_MENUS } from 'commons/menus'
import VehiclesForm from 'components/organisms/vehicles/Form'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { FlexBox } from 'components/atoms/BoxComponents'
import SidebarAnchorList from 'components/organisms/layouts/SidebarAnchorList'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'

function AdminVehiclesNew() {
  return (
    <FlexBox>
      <SidebarAnchorList page="vehicles" />
      <PageWrapper>
        <Helmet>
          <title>{PAGE_TITLES.VEHICLES.NEW ?? APPLICATION_NAME}</title>
        </Helmet>
        <h1>{ADMIN_MENUS.masters.children.vehicles.title}</h1>
        <VehiclesForm type="create" />
      </PageWrapper>
    </FlexBox>
  )
}

export default AdminVehiclesNew
