import { AxiosResponse } from 'axios'
import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
export function getStaticFileUrl(
  key: string,
  common?: boolean
): Promise<AxiosResponse<{ url: string }>> {
  const params = {
    key: key,
    common: common,
  }
  return axios.get(API_ENDPOINTS.STATIC_FILES, { params })
}
