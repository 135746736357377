import { styled } from '@mui/styles'
import { palette, theme } from 'components/theme'
import * as React from 'react'
import Modal from 'react-modal'

type FormSubmitModalProps = {
  isOpen: boolean
  resourceName: string
  handlers: {
    cancel: () => void | Promise<void>
    toShow: () => void | Promise<void>
    toNew?: () => void | Promise<void>
    toNext?: () => void | Promise<void>
  }
}

const StyledModal = styled(Modal)({
  marginLeft: 0,
  display: 'flex',
  flexFlow: 'wrap',
  flexDirection: 'column',
  justifyContents: 'center',
  alignItems: 'center',
  width: '500px',
  height: '200px',
  background: 'white',
  border: '1px solid rgb(204, 204, 204)',
  [theme.breakpoints.down('sm')]: { width: '300px', padding: '0 16px' },
})
const Inner = styled('div')({
  height: '100%',
  maxWidth: '300px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})
const Text = styled('p')({
  margin: '0 0 40px',
})
const Button = styled('button')({
  padding: '8px',
  width: '100px',
  cursor: 'pointer',
  borderRadius: '4px',
  marginBottom: '16px',
})
const ButtonWrapperRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})
const CancelButton = styled(Button)({
  background: 'white',
  color: `${palette.primary.deep}`,
  border: `1px solid ${palette.primary.deep}`,
  marginRight: '16px',
})
const ConfirmButton = styled(Button)({
  background: `${palette.primary.deep}`,
  color: 'white',
  border: `1px solid ${palette.primary.deep}`,
})

const FormSubmitModal: React.FC<FormSubmitModalProps> = (props) => {
  return (
    <StyledModal
      isOpen={props.isOpen}
      style={{
        overlay: {
          minHeight: '100%',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {},
      }}
    >
      <Inner>
        <Text>保存しますか?</Text>
        <ButtonWrapperRow>
          <CancelButton onClick={props.handlers.cancel}>
            キャンセル
          </CancelButton>
          <ConfirmButton onClick={props.handlers.toShow}>
            保存する
          </ConfirmButton>
        </ButtonWrapperRow>
      </Inner>
    </StyledModal>
  )
}

export default FormSubmitModal
