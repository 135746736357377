import { AxiosResponse } from 'axios'
import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { camelCaseKeysToUnderscore } from 'commons/functions'

export function fetchSales(searchParams?: SearchSales): Promise<
  AxiosResponse<{
    labels: string[]
    datasets: []
  }>
> {
  const copy = structuredClone(searchParams)
  const params = { search: camelCaseKeysToUnderscore(copy) }
  return axios.post(API_ENDPOINTS.SALES, params)
}
