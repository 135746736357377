import { useQuery } from '@tanstack/react-query'
import { createQueryKey } from 'commons/functions'
import { fetchVehicles } from 'components/apis/vehicles'
import { useCallback } from 'react'
import { useErrorHandler } from 'react-error-boundary'

export default function useVehicles(
  searchParams?: SearchVehicle,
  paginateParams?: PaginateParams
) {
  const handleError = useErrorHandler()
  const queryKey = createQueryKey('vehicles', {
    ...searchParams,
    ...paginateParams,
  })

  const outerText = useCallback(
    (outerLength: number, outerWidth: number, outerHeight: number) => {
      let text = ''

      if (outerLength) text += `長さ: ${outerLength}cm`
      else text += `長さ: 未入力`
      if (outerWidth) text += ` 幅: ${outerWidth}cm`
      else text += ` 幅: 未入力`
      if (outerHeight) text += ` 高さ: ${outerHeight}cm`
      else text += ` 高さ: 未入力`

      return text
    },
    []
  )

  const { data: vehiclesData, isLoading: isLoadingVehiclesData } = useQuery({
    queryKey,
    queryFn: async () => {
      const res = await fetchVehicles(searchParams, paginateParams)
      res.data.vehicles.map((v) => {
        v.outer = outerText(v.outerLength, v.outerWidth, v.outerHeight)
        return v
      })

      return res.data
    },
    onError: (error) => {
      handleError(error)
    },
  })

  return { vehiclesData, isLoadingVehiclesData }
}
