import * as React from 'react'
import {
  BoxBorderBottom,
  FlexBox,
  MarginBox,
} from 'components/atoms/BoxComponents'
import { Typography } from '@mui/material'
import { TextBold } from 'components/atoms/TextComponents'
import { JapaneseDateFormat } from 'commons/dateFormat'
import { ALCOHOL_CHECKS } from 'commons/constants'
import { TabName } from './Form'

export default function TabView(props: {
  data: AlcoholCheck
  tabName: TabName
}) {
  const { data, tabName } = props

  return (
    <>
      <FlexBox flexDirection={'column'}>
        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ALCOHOL_CHECKS.LABELS.RESULT}</TextBold>
          <BoxBorderBottom>
            <Typography>
              {tabName === TabName.BEFORE && data.resultBeforeWork}
              {tabName === TabName.AFTER && data.resultAfterWork}
            </Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ALCOHOL_CHECKS.LABELS.RESULT_PHOTO}</TextBold>
          <p>
            撮影日時：
            {tabName === TabName.BEFORE &&
              JapaneseDateFormat(new Date(data.resultPhotoBeforeWorkCreatedAt))}
            {tabName === TabName.AFTER &&
              JapaneseDateFormat(new Date(data.resultPhotoAfterWorkCreatedAt))}
          </p>
          {tabName === TabName.BEFORE && (
            <img
              src={data.attachedResultPhotoBeforeWork.url}
              style={{ width: '50%' }}
            />
          )}
          {tabName === TabName.AFTER && (
            <img
              src={data.attachedResultPhotoAfterWork.url}
              style={{ width: '50%' }}
            />
          )}
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ALCOHOL_CHECKS.LABELS.MEMO}</TextBold>
          <BoxBorderBottom>
            <Typography>
              {tabName === TabName.BEFORE && data.memoBeforeWork}
              {tabName === TabName.AFTER && data.memoAfterWork}
            </Typography>
          </BoxBorderBottom>
        </MarginBox>
      </FlexBox>
    </>
  )
}
