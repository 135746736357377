import * as React from 'react'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import EducationCommentForm from 'components/organisms/education_comments/Form'
import { getEducations } from 'components/apis/educations'
import { useQuery } from '@tanstack/react-query'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'

function AdminEducationCommentsNew() {
  const { id } = useParams()
  const { data: education, isLoading } = useQuery(
    ['admin/educations', 'educations'],
    () => getEducations(parseInt(id)).then((res) => res.data)
  )

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.EDUCATIONS.COMMENTS.NEW ?? APPLICATION_NAME}</title>
      </Helmet>
      {isLoading ? (
        <CircularProgressBox />
      ) : (
        <EducationCommentForm education={education} />
      )}
    </PageWrapper>
  )
}

export default AdminEducationCommentsNew
