import * as React from 'react'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { AvatarSquare } from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Box, styled } from '@mui/material'
import { palette, theme } from 'components/theme'
import { VEHICLES } from 'commons/constants'

type VehicleImageProps = {
  imageFiles: File[]
  data: {
    name: string
    number: string // 管理番号
    manufacture: string // メーカー
    model1: string // 車両型式
    model2: string
    modelModified: string
    uniqueNumber: string // 車台番号
    firstRegisteredDate: string // 初年度登録
    inspectionExpireDate: string // 車検満了日
    insurancesCompulsory: InsuranceCompulsory[] // 自賠責
  }
}

export default function VehicleImage(props: VehicleImageProps) {
  const [imageURLs, setImageURLs] = React.useState([])

  // imageFilesに変更が加わった場合に画像表示用のURLを生成する
  React.useEffect(() => {
    if (!props.imageFiles) return
    const urls = props.imageFiles.map((file) => {
      return URL.createObjectURL(file)
    })
    setImageURLs(urls)
  }, [props.imageFiles])

  const modelName =
    props.data.model1 && props.data.model2
      ? `${props.data.model1}-${props.data.model2}`
      : '未入力'

  const latesetInsuranceCompulsory =
    props.data.insurancesCompulsory[props.data.insurancesCompulsory.length - 1]

  const VehicleNameText = styled('p')({
    fontSize: '23px',
    fontWeight: 'bold',
    margin: '8px 0 16px',
  })

  const Text = styled('p')({
    fontSize: '17px',
    margin: 0,
    marginRight: '16px',
  })

  const TextLabel = styled('span')({
    color: palette.text.gray.pale,
    fontWeight: 'normal',
  })

  return (
    <BackGroundWhite>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <AvatarSquare src={imageURLs[0]} />
        <Box
          ml={2}
          sx={{
            [theme.breakpoints.down('md')]: {
              marginLeft: 0,
            },
          }}
        >
          <VehicleNameText>{props.data.name ?? '未入力'}</VehicleNameText>
          <FlexBox
            mb={0.5}
            sx={{
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
            }}
          >
            <Text>
              <TextLabel>{VEHICLES.LABELS.CONTROL_NUMBER}:</TextLabel>
              {props.data.number ?? '未入力'}
            </Text>
            <Text>
              <TextLabel>{VEHICLES.LABELS.MANUFACTURE}:</TextLabel>
              {props.data.manufacture ?? '未入力'}
            </Text>
          </FlexBox>
          <FlexBox
            mb={0.5}
            sx={{
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
            }}
          >
            <Text>
              <TextLabel>{VEHICLES.LABELS.MODEL}:</TextLabel>
              {modelName ?? '未入力'}
              {props.data.modelModified ? '(改)' : ''}
            </Text>
            <Text>
              <TextLabel>{VEHICLES.LABELS.UNIQUE_NUMBER}:</TextLabel>
              {props.data.uniqueNumber ?? '未入力'}
            </Text>
          </FlexBox>
          <FlexBox
            mb={0.5}
            sx={{
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
            }}
          >
            <Text>
              <TextLabel>{VEHICLES.LABELS.FIRST_REGISTERED_DATE}:</TextLabel>
              {props.data.firstRegisteredDate ?? '未入力'}
            </Text>
            <Text>
              <TextLabel>{VEHICLES.LABELS.INSPECTIONS_DATE}:</TextLabel>
              {props.data.inspectionExpireDate ?? '未入力'}
            </Text>
          </FlexBox>
          <FlexBox
            mb={0.5}
            sx={{
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
            }}
          >
            <Text>
              <TextLabel>
                {VEHICLES.LABELS.INSURANCE_COMPULSORY_COMPANY_NAME_DETAILED}:
              </TextLabel>
              {latesetInsuranceCompulsory?.companyName ?? '未入力'}
            </Text>
            <Text>
              <TextLabel>
                {VEHICLES.LABELS.INSURANCE_COMPULSORY_CONTRACT_BRANCH_NAME}:
              </TextLabel>
              {latesetInsuranceCompulsory?.contractBranchNumber ?? '未入力'}
            </Text>
          </FlexBox>
        </Box>
      </FlexBox>
      <FlexBox mt={1} flexWrap="wrap">
        {imageURLs?.map((url, i) => {
          if (i == 0) return
          return (
            <Box mr={1.5} mb={1.5} key={`vehicle-img-${i}`}>
              <AvatarSquare src={url} />
            </Box>
          )
        })}
      </FlexBox>
    </BackGroundWhite>
  )
}
