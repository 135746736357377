import * as React from 'react'
import { styled } from '@mui/styles'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { textLengthWithoutNewline } from 'commons/functions'
import { useForm } from 'react-hook-form'
import { Box } from '@mui/material'
import { FlexBox } from 'components/atoms/BoxComponents'
import {
  VALIDATION_MESSAGES,
  EDUCATION_COMMENTS,
  DRIVER_COMMENT_VALIDATE_LENGTH_TEXT,
  DRIVER_COMMENT_PLACEHOLDER,
} from 'commons/constants'
import {
  CustomForm,
  ErrorMessage,
  CustomLabel,
} from 'components/atoms/FormComponents'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { slackNotification } from 'commons/slackNotification'
import { updateEducationComment } from 'components/apis/educationComments'
import { useMutation } from '@tanstack/react-query'
import { toastOnError, toastOnSuccess } from 'commons/toaster'
import { useErrorHandler } from 'react-error-boundary'
import { UserContext } from 'providers/UserProvider'
import { AxiosError } from 'axios'
import { TextLength } from 'components/atoms/TextLength'
import EducationCommentTextArea from 'components/atoms/EducationCommentTextArea'

const Title = styled('p')({
  fontSize: '25px',
  color: '#0050B2',
  fontWeight: 'bold',
  margin: '0 auto',
})

type EducationCommentFormProps = {
  educationId: number
  driverId: number
}

export default function EducationCommentForm(props: EducationCommentFormProps) {
  const { educationId, driverId } = props
  const user = React.useContext(UserContext)
  const handleError = useErrorHandler()

  const {
    getValues,
    setValue,
    control,
    register,
    trigger,
    formState: { errors },
  } = useForm<EducationComment>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      educationId: educationId,
      driverId: driverId,
    },
  })

  const mutation = useMutation({
    mutationFn: updateEducationComment,
    onSuccess: () => {
      toastOnSuccess('保存しました')
    },
    onError: (e: AxiosError<{ errors: string }>) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors)
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
  })

  const submit = async () => {
    const hasNotError = await trigger('driverComment', { shouldFocus: true })
    if (hasNotError) {
      const driverComment = structuredClone(getValues('driverComment'))
      mutation.mutate(
        {
          educationId: educationId,
          driverId: driverId,
          driverComment: driverComment,
        },
        {
          onError: (error) => {
            handleError(error)
          },
        }
      )
    } else {
      toastOnError('入力内容に不備があります')
    }
  }

  return (
    <>
      <BackGroundWhite smallPadding={true}>
        <Box style={{ textAlign: 'center' }}>
          <Title>教育内容に関するコメントを登録してください</Title>
          <Title style={{ fontSize: '20px' }}>
            {`(${DRIVER_COMMENT_VALIDATE_LENGTH_TEXT})`}
          </Title>
        </Box>
        <FlexBox justifyContent={'center'}>
          <CustomForm sx={{ maxWidth: '370px' }}>
            <CustomLabel>
              <EducationCommentTextArea
                register={register}
                setValue={setValue}
                name="driverComment"
                required={true}
                validate={(value: string) =>
                  textLengthWithoutNewline(value) >=
                    EDUCATION_COMMENTS.DRIVER_COMMENT_LENGTH_MIN &&
                  textLengthWithoutNewline(value) <=
                    EDUCATION_COMMENTS.DRIVER_COMMENT_LENGTH_MAX
                }
                placeholder={DRIVER_COMMENT_PLACEHOLDER}
              />
              <FlexBox justifyContent="flex-end">
                <TextLength control={control} name="driverComment" />
              </FlexBox>
              <Box>
                {errors.driverComment?.type === 'required' && (
                  <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
                )}
                {errors.driverComment?.type === 'validate' && (
                  <ErrorMessage>
                    {`${DRIVER_COMMENT_VALIDATE_LENGTH_TEXT}で入力してください。`}
                  </ErrorMessage>
                )}
              </Box>
            </CustomLabel>
            <FlexBox sx={{ justifyContent: 'center' }}>
              <ApplyButton onClick={submit}>保存</ApplyButton>
            </FlexBox>
          </CustomForm>
        </FlexBox>
      </BackGroundWhite>
    </>
  )
}
