import { range } from 'commons/functions'

export const yearRange = range(1920, 2050).map((value) => ({
  label: value,
  value: value,
}))

export const yearRangeFrom2000 = range(2000, 2050).map((value) => ({
  label: value,
  value: value,
}))

export const monthRange = range(1, 13).map((value) => ({
  label: value,
  value: value,
}))

export const dayRange = range(1, 32).map((value) => ({
  label: value,
  value: value,
}))
