import * as React from 'react'
import { styled } from '@mui/styles'
import { Box } from '@mui/material'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  APPLICATION_NAME,
  HEAD_TEXTS,
  PAGE_TITLES,
  ALERT_TEXT,
  EDUCATIONS,
} from 'commons/constants'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { fetchEducations } from 'components/apis/educations'
import { getEducationValidStatuses } from 'components/apis/educationAnnualPlans'
import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import { UserContext } from 'providers/UserProvider'
import EducationItemLink from 'components/organisms/education_tests/EducationItemLink'

const AlertText = styled('p')({
  fontWeight: 'bold',
  marginTop: 0,
  color: '#014361',
  display: 'inline',
})

function AdminEducationTestsIndex() {
  const [educationValidStatuses, setEducationValidStatuses] = React.useState(
    Array(EDUCATIONS.LEGAL_COUNT).fill(true)
  )
  React.useEffect(() => {
    ;(async () => {
      const res = await getEducationValidStatuses()
      setEducationValidStatuses(res.data)
    })()
  }, [])

  const user = React.useContext(UserContext)
  const { data: educations, isLoading } = useQuery(
    ['admin/education_tests/index', 'educations'],
    () => fetchEducations().then((res) => res.data.educations)
  )

  // 各条件でエラーメッセージの内容を変える
  const renderAlertText = () => {
    // 年度計画のコンテンツにscheduleに1つでも無効（nilか未来）な日程が設定されているかどうか
    if (educationValidStatuses.some((value) => value === false)) {
      return ALERT_TEXT.RESTRICTION_BY_EDUCATION_ANNUAL_PLAN
    }

    // 問題がない場合、アラートを表示しない
    return null
  }

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.EDUCATIONS.TESTS.INDEX ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>{HEAD_TEXTS.WEB_TEST}</h1>
      <div>
        {renderAlertText() && <AlertText>{renderAlertText()}</AlertText>}
      </div>
      <Box sx={{ marginTop: '30px' }}>
        <BackGroundWhite>
          <ol>
            {isLoading ? (
              <CircularProgressBox />
            ) : (
              educations.map((item: Education, i: number) => (
                <li key={item.id}>
                  {item.documentPath ? (
                    <EducationItemLink
                      user={user}
                      education={item}
                      isValid={educationValidStatuses[i]}
                      key={item.id}
                    />
                  ) : (
                    <>{item.title}(準備中)</>
                  )}
                </li>
              ))
            )}
          </ol>
        </BackGroundWhite>
      </Box>
    </PageWrapper>
  )
}

export default AdminEducationTestsIndex
