import * as React from 'react'
import { styled, StyledEngineProvider } from '@mui/material/styles'
import { Box, CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from 'components/theme'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { HelmetProvider } from 'react-helmet-async'

type Props = {
  children?: React.ReactNode | string
}

const Root = styled(Box)({
  margin: '0 12px',
  width: '90%',
  [theme.breakpoints.down('md')]: {
    margin: 0,
    width: '100%',
  },
})

function PageWrapper(props: Props) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <CssBaseline />
          <Root>
            <ToastContainer />
            {props.children}
          </Root>
        </HelmetProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default PageWrapper
