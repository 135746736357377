import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const PaperClipIcon = () => {
  return (
    <SvgIcon>
      <path
        d="M9.99999 4.66681L5.66665 9.00014C5.11437 9.55242 5.11437 10.4479 5.66665 11.0001C6.21894 11.5524 7.11437 11.5524 7.66665 11.0001L12 6.66681C13.1046 5.56224 13.1046 3.77138 12 2.66681C10.8954 1.56224 9.10456 1.56224 7.99999 2.66681L3.66665 7.00014C2.0098 8.65699 2.0098 11.3433 3.66665 13.0001C5.32351 14.657 8.0098 14.657 9.66665 13.0001L14 8.66681"
        stroke="#202830"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
