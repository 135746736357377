import * as React from 'react'
import { Controller } from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  CustomCheckBoxForm,
  CustomDropZone,
  CustomTextArea,
  CustomInput,
  YearMounthDayDatePicker,
} from 'components/atoms/FormComponents'
import { styled } from '@mui/styles'
import { FlexBox, FlexBoxRow } from 'components/atoms/BoxComponents'
import { ENUMS } from 'commons/enums'
import { Box } from '@mui/system'
import { IconButton } from '@mui/material'
import CarouselComponent from 'components/atoms/ResponsiveCarousel'
import CancelIcon from '@mui/icons-material/Cancel'
import { range } from 'commons/functions'
import { VEHICLES } from 'commons/constants'
import { theme } from 'components/theme'

export default function Tools(props: {
  register
  control
  equipmentFiles
  setEquipmentFiles
}) {
  const { register, control, equipmentFiles, setEquipmentFiles } = props

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setEquipmentFiles([...equipmentFiles, ...acceptedFiles])
    },
    [equipmentFiles]
  )

  const HeadDescriptionText = styled('p')({
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#52585E',
  })

  const CustomBorderkBox = styled(Box)({
    marginTop: '15px',
    marginBottom: '15px',
    borderTop: '1px solid #E6E7E8',
  })

  const CustomCheckBoxFormContain = styled('div')({
    marginTop: '10px',
  })

  return (
    <BackGroundWhite>
      <HeadingText>{VEHICLES.LABELS.TOOLS}</HeadingText>

      <CustomBorderkBox>
        <HeadDescriptionText>{VEHICLES.LABELS.DIGI_TACHO}</HeadDescriptionText>
        <FlexBox flexWrap="wrap">
          <CustomLabel>
            {VEHICLES.LABELS.DIGI_TACHO_MANUFACTURE}
            <CustomInput {...register('digiTachoManufacturer', {})} />
          </CustomLabel>
          <CustomLabel>
            {VEHICLES.LABELS.DIGI_TACHO_SERIAL_NUMBER}
            <CustomInput {...register('digiTachoSerialNumber', {})} />
          </CustomLabel>
        </FlexBox>
      </CustomBorderkBox>

      <CustomBorderkBox>
        <HeadDescriptionText>
          {VEHICLES.LABELS.DRIVER_RECORDER}
        </HeadDescriptionText>
        <FlexBox flexWrap="wrap">
          <CustomLabel>
            {VEHICLES.LABELS.DRIVER_RECORDER_MANUFACTURE}
            <CustomInput {...register('driveRecorderManufacturer', {})} />
          </CustomLabel>
          <CustomLabel>
            {VEHICLES.LABELS.DRIVER_RECORDER_SERIAL_NUMBER}
            <CustomInput {...register('driveRecorderSerialNumber', {})} />
          </CustomLabel>
        </FlexBox>
      </CustomBorderkBox>

      <CustomBorderkBox>
        <HeadDescriptionText>{VEHICLES.LABELS.ETC}</HeadDescriptionText>
        <FlexBox flexWrap="wrap">
          <CustomLabel>
            {VEHICLES.LABELS.ETC_DEVICE}
            <CustomInput {...register('etcDevice', {})} />
          </CustomLabel>
          <CustomLabel>
            {VEHICLES.LABELS.ETC_ASL_ID}
            <CustomInput {...register('etcAslId', {})} />
          </CustomLabel>
        </FlexBox>
      </CustomBorderkBox>

      <CustomBorderkBox>
        <HeadDescriptionText> {VEHICLES.LABELS.ETC_CARD}</HeadDescriptionText>
        <FlexBox flexWrap="wrap">
          {Object.values(range(1, 6)).map((value) => (
            <FlexBox
              key={value}
              sx={{
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                },
              }}
            >
              <CustomLabel>
                {VEHICLES.LABELS.ETC_CARD_NUMBER}
                {value}
                <CustomInput {...register(`etcCard${value}`, {})} />
              </CustomLabel>
              <CustomLabel>
                {VEHICLES.LABELS.ETC_CARD_EXPIRE_DATE}
                {value}
                <Controller
                  name={`etcCard${value}ExpireDate`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <YearMounthDayDatePicker
                      onChange={onChange}
                      selected={value && new Date(value)}
                    />
                  )}
                />
              </CustomLabel>
            </FlexBox>
          ))}
        </FlexBox>
      </CustomBorderkBox>

      <CustomBorderkBox>
        <HeadDescriptionText>{VEHICLES.LABELS.FUEL_CARD}</HeadDescriptionText>
        <FlexBox flexWrap="wrap">
          {Object.values(range(1, 6)).map((value) => (
            <CustomLabel key={value}>
              {VEHICLES.LABELS.FUEL_CARD_NUMBER}
              {value}
              <CustomInput {...register(`fuelCard${value}`, {})} />
            </CustomLabel>
          ))}
        </FlexBox>
      </CustomBorderkBox>

      <CustomBorderkBox>
        <HeadDescriptionText>{VEHICLES.LABELS.OTHERS}</HeadDescriptionText>
        <CustomLabel>
          <FlexBoxRow flexWrap="wrap">
            {Object.values(ENUMS.VEHICLES.TOOLS_OTHER_TYPE || []).map(
              (obj, index) => (
                <CustomCheckBoxFormContain key={index}>
                  <Controller
                    name={`has${
                      obj.value.charAt(0).toUpperCase() + obj.value.slice(1)
                    }`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CustomCheckBoxForm>
                        <label>
                          <input
                            type="checkbox"
                            checked={value}
                            onChange={() => onChange(!value)}
                          />
                          {obj.label}
                        </label>
                      </CustomCheckBoxForm>
                    )}
                  />
                </CustomCheckBoxFormContain>
              )
            )}
          </FlexBoxRow>
        </CustomLabel>
      </CustomBorderkBox>

      <CustomBorderkBox>
        <CustomLabel>
          添付ファイルのアップロード
          <Controller
            render={() => (
              <CustomDropZone
                multiple={true}
                onDrop={onDrop}
                accept={'image/*, application/pdf'}
              />
            )}
            name="equipmentFiles"
            control={control}
          />
          {Object.values(equipmentFiles).map((file: File, index) => (
            <FlexBox flexDirection={'row'} key={index}>
              <p>
                <a
                  href={URL.createObjectURL(file)}
                  download={decodeURI(file.name)}
                >
                  {decodeURI(file.name)}
                </a>
              </p>
              <IconButton
                component="span"
                onClick={() =>
                  setEquipmentFiles(
                    equipmentFiles.filter((_, i) => i !== index)
                  )
                }
              >
                <CancelIcon />
              </IconButton>
            </FlexBox>
          ))}
          <CarouselComponent images={equipmentFiles} />
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.MEMO}
          <CustomTextArea rows={5} {...register('equipmentMemo')} />
        </CustomLabel>
      </CustomBorderkBox>
    </BackGroundWhite>
  )
}
