export const validatePhoneNumberInput = (v: string) => {
  const reg = /^[0-9]+$/
  if (v.match(reg) == null) return false
  return true
}

export const validateEmailInput = (v: string) => {
  const reg =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  if (v.match(reg) == null) return false
  return true
}

export const validateNullableEmailInput = (value: string) => {
  if (value === '' || value === null || value === undefined) {
    return true
  }

  return validateEmailInput(value)
}

export const validateFaxNumber = (v: string) => {
  const faxRegex = /^0\d{1,3}[-]?\d{1,4}[-]?\d{4}$/ // FAX番号のフォーマット
  return faxRegex.test(v)
}
