import * as React from 'react'
import {
  ADMIN_ROUTES,
  LOCAL_STORAGE_PAGE_KEY,
  ROUTES,
  VEHICLES,
} from 'commons/constants'
import EnhancedTable, { HeadCell } from 'components/atoms/TableComponents'
import SearchForm from '../vehicles/SearchForm'
import { UserContext } from 'providers/UserProvider'
import {
  getLocalStorageDisplayLimit,
  getLocalStoragePageNumber,
  getLocalStorageOrder,
  isOrder,
} from 'commons/table'
import type { Order } from 'commons/array'

const headCells: readonly HeadCell<Vehicle>[] = [
  {
    id: 'name',
    label: VEHICLES.LABELS.NAME,
    type: 'string',
  },
  {
    id: 'number',
    label: VEHICLES.LABELS.NUMBER,
    type: 'string',
  },
  {
    id: 'officeName',
    label: VEHICLES.LABELS.OFFICE,
    type: 'string',
  },
  {
    id: 'temperatureZoneText',
    label: VEHICLES.LABELS.TEMPERATURE_ZONE,
    type: 'string',
  },
  {
    id: 'weight',
    label: `${VEHICLES.LABELS.WEIGHT}(t)`,
    type: 'string',
  },
  {
    id: 'sizeText',
    label: VEHICLES.LABELS.SIZE,
    type: 'string',
  },
  {
    id: 'shapeText',
    label: VEHICLES.LABELS.SHAPE,
    type: 'string',
  },
  {
    id: 'operationStatus',
    label: VEHICLES.LABELS.OPERATION_STATUS,
    type: 'string',
  },
  {
    id: 'tonnage',
    label: `${VEHICLES.LABELS.TONNAGE}(t)`,
    type: 'string',
  },
  {
    id: 'license',
    label: VEHICLES.LABELS.LICENSE,
    type: 'string',
  },
  {
    id: 'outer',
    label: VEHICLES.LABELS.OUTER,
    type: 'string',
  },
  {
    id: 'memo',
    label: VEHICLES.LABELS.MEMO,
    type: 'string',
  },
]

const headCellsWithCompany: readonly HeadCell<Vehicle>[] = [
  {
    id: 'name',
    label: VEHICLES.LABELS.NAME,
    type: 'string',
  },
  {
    id: 'number',
    label: VEHICLES.LABELS.NUMBER,
    type: 'string',
  },
  {
    id: 'companyName',
    label: VEHICLES.LABELS.COMPANY,
    type: 'string',
  },
  {
    id: 'officeName',
    label: VEHICLES.LABELS.OFFICE,
    type: 'string',
  },
  {
    id: 'temperatureZoneText',
    label: VEHICLES.LABELS.TEMPERATURE_ZONE,
    type: 'string',
  },
  {
    id: 'weight',
    label: `${VEHICLES.LABELS.WEIGHT}(t)`,
    type: 'string',
  },
  {
    id: 'sizeText',
    label: VEHICLES.LABELS.SIZE,
    type: 'string',
  },
  {
    id: 'shapeText',
    label: VEHICLES.LABELS.SHAPE,
    type: 'string',
  },
  {
    id: 'operationStatus',
    label: VEHICLES.LABELS.OPERATION_STATUS,
    type: 'string',
  },
  {
    id: 'tonnage',
    label: `${VEHICLES.LABELS.TONNAGE}(t)`,
    type: 'string',
  },
  {
    id: 'license',
    label: VEHICLES.LABELS.LICENSE,
    type: 'string',
  },
  {
    id: 'outer',
    label: VEHICLES.LABELS.OUTER,
    type: 'string',
  },
  {
    id: 'memo',
    label: VEHICLES.LABELS.MEMO,
    type: 'string',
  },
]

export default function VehiclesTable(props: {
  data: Vehicle[]
  totalCount: number
  paginateParams: PaginateParams
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setSearchParams: React.Dispatch<React.SetStateAction<SearchVehicle>>
  searchParams: SearchVehicle
  defaultSearchParams: SearchVehicle
  focusId?: number
  isLoading?: boolean
}) {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Vehicle>('id')
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(
    props.paginateParams.displayLimit
  )
  const rows = props.data
  const totalCount = props.totalCount
  const user = React.useContext(UserContext)
  const isOrderBy = (arg): arg is keyof Vehicle => {
    const headCellsItems =
      user.isXmileAdmin || user.isGroupParentCompanyAdmin
        ? headCellsWithCompany
        : headCells
    const arr = headCellsItems.map((h) => {
      return h.id
    })
    return arr.includes(arg)
  }
  React.useEffect(() => {
    // ソート条件をlocalStorageから取得してset
    const { order, orderBy } = getLocalStorageOrder(
      LOCAL_STORAGE_PAGE_KEY.VEHICLES
    )
    if (isOrder(order) && isOrderBy(orderBy)) {
      setOrder(order)
      setOrderBy(orderBy)
    }
    //「ページあたりの行数」をloalStorageから取得してセット
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = getLocalStoragePageNumber(
      LOCAL_STORAGE_PAGE_KEY.VEHICLES,
      true
    )
    const newPaginateParams = {
      pageNumber: pageNumber,
      displayLimit: displayLimit,
    }
    props.setPaginateParams(newPaginateParams)
    setRowsPerPage(displayLimit)
    setPage(pageNumber)
  }, [])
  return (
    <EnhancedTable<Vehicle>
      {...props}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      page={page}
      setPage={setPage}
      rows={rows}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      headCells={
        user.isXmileAdmin || user.isGroupParentCompanyAdmin
          ? headCellsWithCompany
          : headCells
      }
      totalCount={totalCount}
      localStoragePageKey={LOCAL_STORAGE_PAGE_KEY.VEHICLES}
      basePath={
        user.admin ? ADMIN_ROUTES.VEHICLES_INDEX : ROUTES.VEHICLES_INDEX
      }
      isNewVersion
      searchForm={
        <SearchForm
          setSearchParams={props.setSearchParams}
          setPaginateParams={props.setPaginateParams}
          setPage={setPage}
          searchParams={props.searchParams}
          defaultSearchParams={props.defaultSearchParams}
          isLoading={props.isLoading}
        />
      }
    />
  )
}
