import * as React from 'react'
import { ADMIN_MENUS } from 'commons/menus'
import ArticleMaintenancesForm from 'components/organisms/articles/maintenances/Form'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { getArticle } from 'components/apis/articles'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

function ArticleMaintenancesEdit() {
  const params = useParams()
  const id = parseInt(params.id)
  const { data: article, isLoading } = useQuery(
    [`articles/edit`, 'article'],
    () => getArticle(id).then((res) => res.data)
  )

  return (
    <PageWrapper>
      <h1>{ADMIN_MENUS.articles.children.article_maintenances.title}</h1>
      {isLoading ? (
        <CircularProgressBox />
      ) : (
        <ArticleMaintenancesForm type="update" data={article} />
      )}
    </PageWrapper>
  )
}

export default ArticleMaintenancesEdit
