import { AxiosResponse } from 'axios'
import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { addParamPrefix, camelCaseKeysToUnderscore } from 'commons/functions'

export function fetchResources(
  searchParams: SearchResource,
  paginateParams: PaginateParams
): Promise<AxiosResponse<{ resources: Resource[]; totalCount: number }>> {
  const customSearchParams = addParamPrefix(searchParams, 'search')
  const customPaginateParams = addParamPrefix(paginateParams, 'pagination')
  const params = camelCaseKeysToUnderscore({
    ...customSearchParams,
    ...customPaginateParams,
  })

  return axios.get(API_ENDPOINTS.RESOURCES, {
    params: params,
  })
}

export function createResource(params: { formData: FormData }) {
  const headers = { 'Content-Type': 'multipart/form-data' }
  return axios.post(API_ENDPOINTS.RESOURCES, params.formData, {
    headers: headers,
  })
}

export function updateResource(params: Resource) {
  return axios.patch(
    [API_ENDPOINTS.RESOURCES, params.id].join(''),
    camelCaseKeysToUnderscore(params)
  )
}

export function deleteResource(id: number) {
  return axios.delete([API_ENDPOINTS.RESOURCES, id].join(''))
}
