import * as React from 'react'
import { WithRequiredBadge } from 'components/atoms/FormComponents'
import { DRIVERS } from 'commons/constants'
type PropsType = {
  type: ProfileEvent
}
export default function PasswordLabel(props: PropsType) {
  const { type } = props
  return (
    <>
      {type === 'create' ? (
        <WithRequiredBadge>{DRIVERS.LABELS.PASSWORD}</WithRequiredBadge>
      ) : (
        <>{DRIVERS.LABELS.PASSWORD}</>
      )}
    </>
  )
}
