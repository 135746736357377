import { useQuery } from '@tanstack/react-query'
import { createQueryKey } from 'commons/functions'
import { fetchEducationAnnualPlans } from 'components/apis/educationAnnualPlans'
import { useErrorHandler } from 'react-error-boundary'

export default function useEducationAnnualPlans(
  searchParams?: SearchEducationAnnualPlan,
  paginateParams?: PaginateParams
) {
  const handleError = useErrorHandler()
  const queryKey = createQueryKey('education_annual_plans', {
    ...searchParams,
    ...paginateParams,
  })

  const {
    data: educationAnnualPlansData,
    isLoading: isLoadingEducationAnnualPlansData,
  } = useQuery({
    queryKey,
    queryFn: async () => {
      const result = await fetchEducationAnnualPlans(
        searchParams,
        paginateParams
      )

      return result.data
    },
    onError: (error) => {
      handleError(error)
    },
  })

  return {
    educationAnnualPlansData,
    isLoadingEducationAnnualPlansData,
  }
}
