import * as React from 'react'
import {
  CustomInput,
  CustomLabel,
  CustomPhoneNumberInput,
  CustomSelect,
  WithRequiredBadge,
  ErrorMessage,
} from 'components/atoms/FormComponents'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form'
import {
  DRIVERS,
  EMERGENCY_CONTACTS,
  VALIDATION_MESSAGES,
} from 'commons/constants'
import { FlexBox } from 'components/atoms/BoxComponents'
import { BackGroundPalePink } from 'components/atoms/BackGroundComponents'
import { PREFECTURES } from 'commons/prefectures'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { styled } from '@mui/styles'
import { formatPhoneNumberErrorMessage } from 'commons/errorMessage'
import { useTheme } from '@mui/material'

type EmergencyContactCardProps = {
  register: UseFormRegister<Driver>
  setValue: UseFormSetValue<Driver>
  control: Control<Driver>
  errors: FieldErrors<Driver>
  appendEmptyItem: () => void
  deleteItem: (index: number) => void
  index: number
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>
}

const EmergencyContactCard: React.FC<EmergencyContactCardProps> = (props) => {
  const CardTitle = styled('p')({
    fontWeight: 'bold',
  })
  const customPhoneNumberErrorMessage = React.useCallback(() => {
    return formatPhoneNumberErrorMessage({
      first: props.errors?.emergencyContacts?.[props.index]?.phoneNumberFirst,
      second: props.errors?.emergencyContacts?.[props.index]?.phoneNumberSecond,
      third: props.errors?.emergencyContacts?.[props.index]?.phoneNumberThird,
    })
  }, [props.errors?.emergencyContacts?.[props.index]])

  const theme = useTheme()

  return (
    <BackGroundPalePink sx={{ marginBottom: '30px' }}>
      <CardTitle>
        {DRIVERS.LABELS.EMERGENCY_CONTACT}
        {props.index + 1}
      </CardTitle>
      <FlexBox
        flexWrap="wrap"
        alignItems="center"
        width="100%"
        sx={{
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel
          sx={{
            marginBottom: '30px',
            maxWidth: '140px',
            [theme.breakpoints.down('sm')]: {
              maxWidth: '100%',
            },
          }}
        >
          <WithRequiredBadge>
            {EMERGENCY_CONTACTS.LABELS.LAST_NAME}
          </WithRequiredBadge>
          <CustomInput
            sx={{ marginTop: '4px', height: '38px', width: '100%' }}
            {...props.register(`emergencyContacts.${props.index}.lastName`, {
              required: true,
            })}
            onKeyDown={props.onInputKeyDown}
          />
          {props.errors?.emergencyContacts?.[props.index]?.lastName && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
        <CustomLabel
          sx={{
            marginBottom: '30px',
            maxWidth: '140px',
            [theme.breakpoints.down('sm')]: {
              maxWidth: '100%',
            },
          }}
        >
          <WithRequiredBadge>
            {EMERGENCY_CONTACTS.LABELS.FIRST_NAME}
          </WithRequiredBadge>
          <CustomInput
            sx={{ marginTop: '4px', height: '38px', width: '100%' }}
            {...props.register(`emergencyContacts.${props.index}.firstName`, {
              required: true,
            })}
            onKeyDown={props.onInputKeyDown}
          />
          {props.errors?.emergencyContacts?.[props.index]?.firstName && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
        <CustomLabel
          sx={{
            marginBottom: '30px',
            maxWidth: '140px',
            [theme.breakpoints.down('sm')]: {
              maxWidth: '100%',
            },
          }}
        >
          {EMERGENCY_CONTACTS.LABELS.LAST_NAME_KANA}
          <CustomInput
            sx={{ marginTop: '4px', height: '38px', width: '100%' }}
            onKeyDown={props.onInputKeyDown}
            {...props.register(`emergencyContacts.${props.index}.lastNameKana`)}
          />
        </CustomLabel>
        <CustomLabel
          sx={{
            marginBottom: '30px',
            maxWidth: '140px',
            [theme.breakpoints.down('sm')]: {
              maxWidth: '100%',
            },
          }}
        >
          {EMERGENCY_CONTACTS.LABELS.FIRST_NAME_KANA}
          <CustomInput
            sx={{ marginTop: '4px', height: '38px', width: '100%' }}
            onKeyDown={props.onInputKeyDown}
            {...props.register(
              `emergencyContacts.${props.index}.firstNameKana`
            )}
          />
        </CustomLabel>
      </FlexBox>
      <CustomLabel
        sx={{
          marginBottom: '30px',
          [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
          },
        }}
      >
        <WithRequiredBadge>
          {EMERGENCY_CONTACTS.LABELS.PHONE_NUMBER}
        </WithRequiredBadge>
        <CustomPhoneNumberInput
          formKeys={{
            first: `emergencyContacts.${props.index}.phoneNumberFirst`,
            second: `emergencyContacts.${props.index}.phoneNumberSecond`,
            third: `emergencyContacts.${props.index}.phoneNumberThird`,
          }}
          register={props.register}
          required
        />
        <ErrorMessage>{customPhoneNumberErrorMessage()}</ErrorMessage>
      </CustomLabel>
      <CustomLabel
        sx={{
          marginBottom: '30px',
          maxWidth: '180px',
          [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
          },
        }}
      >
        {EMERGENCY_CONTACTS.LABELS.RELATIONSHIP}
        <CustomInput
          sx={{ marginTop: '4px', height: '38px', width: '100%' }}
          onKeyDown={props.onInputKeyDown}
          {...props.register(`emergencyContacts.${props.index}.relationship`)}
        />
      </CustomLabel>
      <FlexBox flexWrap="wrap" alignItems="center">
        <CustomLabel
          sx={{
            marginBottom: '30px',
            maxWidth: '130px',
            [theme.breakpoints.down('sm')]: {
              maxWidth: '100%',
            },
          }}
        >
          {EMERGENCY_CONTACTS.LABELS.ZIP_CODE}
          <CustomInput
            sx={{ marginTop: '4px', height: '38px', width: '100%' }}
            onKeyDown={props.onInputKeyDown}
            {...props.register(`emergencyContacts.${props.index}.zipCode`)}
          />
        </CustomLabel>
        <CustomLabel
          sx={{
            marginBottom: '30px',
            maxWidth: '130px',
            [theme.breakpoints.down('sm')]: {
              maxWidth: '100%',
            },
          }}
        >
          {EMERGENCY_CONTACTS.LABELS.PREFECTURE}
          <Controller
            name={`emergencyContacts.${props.index}.prefecture`}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                options={PREFECTURES}
                value={PREFECTURES.find((c) => c.value === value)}
                onChange={(option: ReactSelectOptionProps) =>
                  onChange(option.value)
                }
                placeholder="未選択"
              />
            )}
          />
        </CustomLabel>
        <CustomLabel
          sx={{
            marginBottom: '30px',
            maxWidth: '320px',
            [theme.breakpoints.down('sm')]: {
              maxWidth: '100%',
            },
          }}
        >
          {EMERGENCY_CONTACTS.LABELS.ADDRESS}
          <CustomInput
            sx={{
              marginTop: '4px',
              height: '38px',
              [theme.breakpoints.down('sm')]: {
                width: '100%',
              },
            }}
            onKeyDown={props.onInputKeyDown}
            {...props.register(`emergencyContacts.${props.index}.address`)}
          />
        </CustomLabel>
      </FlexBox>
      <DeleteButton onClick={() => props.deleteItem(props.index)}>
        削除
      </DeleteButton>
    </BackGroundPalePink>
  )
}

export default EmergencyContactCard
