import { AxiosResponse } from 'axios'
import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { addParamPrefix, camelCaseKeysToUnderscore } from 'commons/functions'

export function fetchHistories(
  searchParams: SearchHistory
): Promise<AxiosResponse<{ histories: History[]; totalCount: number }>> {
  const customSearchParams = searchParams
    ? addParamPrefix(searchParams, 'search')
    : {}
  const params = camelCaseKeysToUnderscore({
    ...customSearchParams,
  })

  return axios.get(API_ENDPOINTS.HISTORIES, { params })
}
