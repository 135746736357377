import * as React from 'react'
import { XMILE_ADMIN_MENUS } from 'commons/menus'
import CompaniesForm from 'components/organisms/companies/Form'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import {
  fetchAllCompanies,
  fetchGroupParentCompanies,
} from 'components/apis/companies'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'

function AdminCompaniesNew() {
  const { data: companies, isLoading } = useQuery(
    ['admin/companies/new', 'companies'],
    () => fetchAllCompanies().then((res) => res.data.companies)
  )

  const {
    data: groupParentCompanies,
    isLoading: isGoupParentCompanyListLoading,
  } = useQuery([`admin/companies/new`, 'groupParentCompanies'], () =>
    fetchGroupParentCompanies().then((res) => res.data.groupParentCompanies)
  )

  if (isLoading || isGoupParentCompanyListLoading) {
    return <CircularProgressBox />
  }

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.COMPANIES.NEW ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>{XMILE_ADMIN_MENUS.masters.children.companies.title}</h1>
      <CompaniesForm
        type="create"
        data={{ name: '' }}
        companies={companies}
        groupParentCompanies={groupParentCompanies}
      />
    </PageWrapper>
  )
}

export default AdminCompaniesNew
