import * as React from 'react'
import { Controller } from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomInput,
  CustomLabel,
  CustomRadioButton,
  CustomRadioGroup,
  HeadingText,
  CustomCheckBoxForm,
  YearMounthDatePicker,
  CustomInputShort,
} from 'components/atoms/FormComponents'
import { styled } from '@mui/styles'
import { FlexBox, FlexBoxRow } from 'components/atoms/BoxComponents'
import { ENUMS } from 'commons/enums'
import { Box } from '@mui/system'
import { VEHICLES } from 'commons/constants'
import { theme } from 'components/theme'
import NumberFormat from 'components/organisms/NumberFormat'

export default function Body(props: { register; control; errors; type }) {
  const { register, control } = props

  const FormSubText = styled('p')({
    fontSize: '14px',
    marginLeft: '8px',
  })

  const MidiumInput = styled(CustomInput)({
    width: '160px',
  })

  const MidiumCustomRadioGroup = styled(CustomRadioGroup)({
    width: '270px',
  })

  const CustomBorderkBox = styled(Box)({
    marginTop: '15px',
    marginBottom: '15px',
    borderTop: '1px solid #E6E7E8',
  })

  const CustomCheckBoxFormContain = styled('div')({
    marginTop: '10px',
  })

  return (
    <BackGroundWhite>
      <HeadingText>{VEHICLES.LABELS.BODY}</HeadingText>

      <CustomLabel>
        {VEHICLES.LABELS.MANUFACTURE}
        <CustomInput {...register('bodyManufacturer', {})} />
      </CustomLabel>

      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel>
          {VEHICLES.LABELS.BODY_SERIAL_NUMBER}
          <MidiumInput {...register('bodySerialNumber', {})} />
        </CustomLabel>

        <CustomLabel>
          {VEHICLES.LABELS.BODY_MANUFACTURED_DATE}
          <Controller
            name="bodyManufacturedDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <YearMounthDatePicker
                onChange={onChange}
                selected={value && new Date(value)}
              />
            )}
          />
        </CustomLabel>
      </FlexBox>

      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {VEHICLES.LABELS.BODY_INNER}(長さ)
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={MidiumInput} />
              )}
              name="bodyInnerLength"
              control={control}
            />
            <FormSubText>cm</FormSubText>
          </FlexBox>
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.BODY_INNER}(幅)
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={MidiumInput} />
              )}
              name="bodyInnerWidth"
              control={control}
            />
            <FormSubText>cm</FormSubText>
          </FlexBox>
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.BODY_INNER}(高さ)
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={MidiumInput} />
              )}
              name="bodyInnerHeight"
              control={control}
            />
            <FormSubText>cm</FormSubText>
          </FlexBox>
        </CustomLabel>
      </FlexBox>

      <CustomLabel>
        {VEHICLES.LABELS.BODY_CAPACITY}
        <FlexBox alignItems="center">
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={MidiumInput} />
            )}
            name="bodyCapacity"
            control={control}
          />
          <FormSubText>立米</FormSubText>
        </FlexBox>
      </CustomLabel>

      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {VEHICLES.LABELS.BODY_WALL_THICKNESS}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={MidiumInput} />
              )}
              name="bodyWallThickness"
              control={control}
            />
            <FormSubText>cm</FormSubText>
          </FlexBox>
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.BODY_FLOOR_HEIGHT}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={MidiumInput} />
              )}
              name="bodyFloorHeight"
              control={control}
            />
            <FormSubText>cm</FormSubText>
          </FlexBox>
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.BODY_GATE_HEIGHT}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={MidiumInput} />
              )}
              name="bodyGateHeight"
              control={control}
            />
            <FormSubText>cm</FormSubText>
          </FlexBox>
        </CustomLabel>
      </FlexBox>

      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {VEHICLES.LABELS.BODY_GATE_FRAME}(幅)
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={MidiumInput} />
              )}
              name="bodyGateFrameWidth"
              control={control}
            />
            <FormSubText>cm</FormSubText>
          </FlexBox>
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.BODY_GATE_FRAME}(高さ)
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={MidiumInput} />
              )}
              name="bodyGateFrameHeight"
              control={control}
            />
            <FormSubText>cm</FormSubText>
          </FlexBox>
        </CustomLabel>
      </FlexBox>

      <CustomLabel>
        {VEHICLES.LABELS.BODY_GATE_MATERIAL}
        <CustomInput {...register('bodyGateMaterial', {})} />
      </CustomLabel>

      <CustomLabel>
        {VEHICLES.LABELS.SIDE_DOOR}
        <MidiumCustomRadioGroup>
          {Object.entries(ENUMS.VEHICLES.SIDE_DOOR_TYPE).map(([key, obj]) => (
            <React.Fragment key={key}>
              <CustomRadioButton
                {...register('bodyHasSidedoor')}
                id={`bodyHasSidedoor_${obj.value}`}
                value={obj.value}
                type="radio"
              />
              <label htmlFor={`bodyHasSidedoor_${obj.value}`}>
                {obj.label}
              </label>
            </React.Fragment>
          ))}
        </MidiumCustomRadioGroup>
      </CustomLabel>

      <CustomBorderkBox />

      <HeadingText> {VEHICLES.LABELS.COOLER}</HeadingText>

      <CustomLabel>
        {VEHICLES.LABELS.COOLER_MANUFACTURE}
        <CustomInput {...register('coolerManufacturer', {})} />
      </CustomLabel>

      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {VEHICLES.LABELS.COOLER_MODEL}
          <MidiumInput {...register('coolerModel', {})} />
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.COOLER_SERIAL_NUMBER}
          <MidiumInput {...register('coolerSerialNumber', {})} />
        </CustomLabel>
      </FlexBox>

      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {VEHICLES.LABELS.COOLER_MANUFACTURED_DATE}
          <Controller
            name="coolerManufacturedDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <YearMounthDatePicker
                onChange={onChange}
                selected={value && new Date(value)}
              />
            )}
          />
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.COOLER_FREON_TYPE}
          <MidiumInput {...register('coolerFreonType', {})} />
        </CustomLabel>
      </FlexBox>

      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {VEHICLES.LABELS.COOLER_TEMPERATURE}
          <FlexBoxRow flexWrap="wrap">
            {Object.values(ENUMS.VEHICLES.TEMPERATURE_ZONE).map(
              (temperatureZone, index) => (
                <CustomCheckBoxFormContain key={index}>
                  <Controller
                    name={`coolerTemperature${
                      temperatureZone.value.charAt(0).toUpperCase() +
                      temperatureZone.value.slice(1)
                    }`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CustomCheckBoxForm>
                        <label>
                          <input
                            type="checkbox"
                            checked={value}
                            onChange={() => onChange(!value)}
                          />
                          {temperatureZone.label}
                        </label>
                      </CustomCheckBoxForm>
                    )}
                  />
                </CustomCheckBoxFormContain>
              )
            )}
          </FlexBoxRow>
        </CustomLabel>
      </FlexBox>

      <CustomLabel>
        {VEHICLES.LABELS.COOLER_HAS_STANDBY_DEVICE}
        <MidiumCustomRadioGroup>
          {Object.entries(ENUMS.VEHICLES.STANDBY_EQUIPMENT_TYPE).map(
            ([key, obj]) => (
              <React.Fragment key={key}>
                <CustomRadioButton
                  {...register('coolerHasStandbyDevice')}
                  id={`coolerHasStandbyDevice_${obj.value}`}
                  value={obj.value}
                  type="radio"
                />
                <label htmlFor={`coolerHasStandbyDevice_${obj.value}`}>
                  {obj.label}
                </label>
              </React.Fragment>
            )
          )}
        </MidiumCustomRadioGroup>
      </CustomLabel>

      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {VEHICLES.LABELS.COOLER_FLOOER_COUNT}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name="coolerFloorCount"
            control={control}
          />
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.COOLER_EVAPORATOR_COUNT}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name="coolerEvaporatorCount"
            control={control}
          />
        </CustomLabel>
      </FlexBox>
    </BackGroundWhite>
  )
}
