import * as React from 'react'
import { CustomPagination, HeadCell } from 'components/atoms/TableComponents'
import { useForm, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { LOCAL_STORAGE_PAGE_KEY, EDUCATION_COMMENTS } from 'commons/constants'
import SearchForm from '../education_comments/SearchForm'
import { UserContext } from 'providers/UserProvider'
import {
  setLocalStorageDisplayLimit,
  setLocalStoragePageNumber,
  removeLocalStoragePageNumber,
  getLocalStorageOrder,
  setLocalStorageOrder,
  isOrder,
} from 'commons/table'
import { Order, sortBy } from 'commons/array'
import {
  styled,
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  CircularProgress,
} from '@mui/material'
import { palette } from 'components/theme'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { slackNotification } from 'commons/slackNotification'
import { AxiosError } from 'axios'
import { useErrorHandler } from 'react-error-boundary'
import { toastOnSuccess, toastOnError } from 'commons/toaster'
import { visuallyHidden } from '@mui/utils'
import { useMutation } from '@tanstack/react-query'
import {
  RecordNotFound,
  EnhancedTableToolbar,
} from 'components/atoms/TableComponents'
import { updateAdminEducationComment } from 'components/apis/educationComments'
import EducationCommentTextArea from 'components/atoms/EducationCommentTextArea'

const headCells: HeadCell<EducationComment>[] = [
  {
    id: 'officeName',
    label: EDUCATION_COMMENTS.LABELS.OFFICE,
    type: 'string',
  },
  {
    id: 'driverName',
    label: EDUCATION_COMMENTS.LABELS.DRIVER_NAME,
    type: 'string',
  },
  {
    id: 'driverComment',
    label: EDUCATION_COMMENTS.LABELS.DRIVER_COMMENT,
    type: 'string',
  },
  {
    id: 'adminUserName',
    label: EDUCATION_COMMENTS.LABELS.ADMIN_USER_NAME,
    type: 'string',
  },
  {
    id: 'adminComment',
    label: EDUCATION_COMMENTS.LABELS.ADMIN_COMMENT,
    type: 'string',
  },
]

const headCellsWithCompany: HeadCell<EducationComment>[] = [
  {
    id: 'companyName',
    label: EDUCATION_COMMENTS.LABELS.COMPANY,
    type: 'string',
  },
  ...headCells,
]

const CustomStickyBox = styled(Box)({
  position: 'sticky',
  left: 0,
  width: '172px',
  zIndex: 1,
})

function EducationCommentsTableHead(props: {
  headCells: { id: string; label: string; type: string }[]
  order: Order
  orderBy: keyof EducationComment
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof EducationComment,
    order: Order
  ) => void
}) {
  const { headCells, order, orderBy, onRequestSort } = props
  const headerColor = palette.background.tableHeaderCell.pale
  const createSortHandler =
    (property, order) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property, order)
    }

  return (
    <TableHead>
      <TableRow>
        <CustomStickyBox style={{ backgroundColor: headerColor }}>
          <TableCell style={{ height: '80px', verticalAlign: 'middle' }}>
            No
          </TableCell>
          <TableCell style={{ width: '128px', height: '80px' }}></TableCell>
        </CustomStickyBox>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={'left'}
            padding={'normal'}
            style={{ backgroundColor: headerColor }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id, order)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const commentFields = ['driverComment', 'adminComment']

function CustomCells(props: {
  headCells: { id: string; label: string; type: string }[]
  educationComment: EducationComment
  register: UseFormRegister<EducationComment>
  setValue: UseFormSetValue<EducationComment>
}) {
  const { headCells, educationComment, register, setValue } = props

  return (
    <>
      {headCells.map((cell, cellIndex) => {
        if (commentFields.includes(cell.id)) {
          return (
            <TableCell key={cellIndex}>
              <EducationCommentTextArea
                register={register}
                setValue={setValue}
                rows={3}
                name={cell.id as 'driverComment' | 'adminComment'}
                required={cell.id === 'adminComment'}
                disabled={
                  !educationComment.isEdit || cell.id === 'driverComment'
                }
                sx={{ width: '368px' }}
              />
            </TableCell>
          )
        } else {
          return (
            <TableCell key={cellIndex}>{educationComment[cell.id]}</TableCell>
          )
        }
      })}
    </>
  )
}

function CustomTableRow(props: {
  educationComment: EducationComment
  educationComments: EducationComment[]
  setEducationComments: React.Dispatch<React.SetStateAction<EducationComment[]>>
  headCells: { id: string; label: string; type: string }[]
  rowNumber: number
  handleCellClick: (id: number) => void
}) {
  const {
    educationComment,
    educationComments,
    setEducationComments,
    headCells,
    rowNumber,
    handleCellClick,
  } = props
  const user = React.useContext(UserContext)
  const handleError = useErrorHandler()
  const { getValues, setValue, register, trigger } = useForm<EducationComment>({
    mode: 'onSubmit',
    defaultValues: educationComment,
  })
  const mutation = useMutation({
    mutationFn: updateAdminEducationComment,
    onSuccess: (res) => {
      const updatedEducationComment = res.data
      const updatedEducationComments = educationComments.map(
        (educationComment) =>
          educationComment.id === updatedEducationComment.id
            ? {
                ...educationComment,
                adminUserName: updatedEducationComment.adminUserName,
                adminComment: updatedEducationComment.adminComment,
                isEdit: false,
              }
            : educationComment
      )
      setEducationComments(updatedEducationComments)
      toastOnSuccess('保存しました')
    },
    onError: (e: AxiosError<{ errors: string[] }>) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors.join(','))
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
  })
  const submit = async () => {
    const hasNotError = await trigger('adminComment', { shouldFocus: true })
    if (hasNotError) {
      const data = structuredClone(getValues())
      const params = {
        id: data.id,
        driverId: data.driverId,
        adminComment: data.adminComment,
      }
      mutation.mutate(params)
    } else {
      toastOnError('管理者コメントを入力してください')
    }
  }

  return (
    <TableRow>
      <CustomStickyBox style={{ backgroundColor: 'white' }}>
        <TableCell style={{ width: '48px', verticalAlign: 'middle' }}>
          {rowNumber}
        </TableCell>
        <TableCell style={{ width: '128px' }}>
          <Box style={{ height: '86px', paddingTop: '20px' }}>
            {educationComment.isEdit ? (
              <ApplyButton onClick={() => submit()}>保存</ApplyButton>
            ) : (
              <ApplyButton
                pattern="width80"
                onClick={() => handleCellClick(educationComment?.id)}
                disabled={
                  !educationComment.id || educationComment.driverId === user.id
                }
              >
                編集
              </ApplyButton>
            )}
          </Box>
        </TableCell>
      </CustomStickyBox>
      <CustomCells
        headCells={headCells}
        educationComment={educationComment}
        register={register}
        setValue={setValue}
      />
    </TableRow>
  )
}

function EducationCommentsTableBody(props: {
  educationComments: EducationComment[]
  setEducationComments: React.Dispatch<React.SetStateAction<EducationComment[]>>
  rowsPerPage: number
  page: number
  headCells: { id: string; label: string; type: string }[]
  order: Order
  orderBy: keyof EducationComment
}) {
  const {
    educationComments,
    setEducationComments,
    rowsPerPage,
    page,
    headCells,
    order,
    orderBy,
  } = props
  const handleCellClick = (id: number) => {
    const updatedEducationComments = educationComments.map((educationComment) =>
      educationComment.id === id
        ? { ...educationComment, isEdit: !educationComment.isEdit }
        : educationComment
    )
    setEducationComments(updatedEducationComments)
  }

  return (
    <TableBody>
      {sortBy(educationComments, order, orderBy).map(
        (educationComment, index) => (
          <React.Fragment key={educationComment.id}>
            <CustomTableRow
              educationComment={educationComment}
              educationComments={educationComments}
              setEducationComments={setEducationComments}
              headCells={headCells}
              rowNumber={(page - 1) * rowsPerPage + index + 1}
              handleCellClick={handleCellClick}
            />
          </React.Fragment>
        )
      )}
    </TableBody>
  )
}

export default function EducationCommentsTable(props: {
  data: EducationComment[]
  setEducationComments: React.Dispatch<React.SetStateAction<EducationComment[]>>
  totalCount: number
  paginateParams: PaginateParams
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setSearchParams: React.Dispatch<React.SetStateAction<SearchEducationComment>>
  defaultSearchParams: SearchEducationComment
  isLoading?: boolean
}) {
  const {
    data,
    setEducationComments,
    totalCount,
    paginateParams,
    setPaginateParams,
  } = props
  const [isOpen, setIsOpen] = React.useState(true)
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof EducationComment>('id')
  const [page, setPage] = React.useState(paginateParams.pageNumber)
  const [rowsPerPage, setRowsPerPage] = React.useState(
    paginateParams.displayLimit
  )
  const user = React.useContext(UserContext)
  const headCellsItems =
    user.isXmileAdmin || user.isGroupParentCompanyAdmin
      ? headCellsWithCompany
      : headCells
  const isOrderBy = (arg): arg is keyof EducationComment => {
    const arr = headCellsItems.map((h) => {
      return h.id
    })
    return arr.includes(arg)
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
    setPaginateParams({
      ...paginateParams,
      pageNumber: newPage,
    })
    setLocalStoragePageNumber(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_COMMENTS,
      newPage
    )
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const count = parseInt(event.target.value, 10)
    const firstPage = 1
    setRowsPerPage(count)
    setPaginateParams({
      pageNumber: firstPage,
      displayLimit: count,
    })
    setPage(firstPage)
    setLocalStorageDisplayLimit(count)
    Object.values(LOCAL_STORAGE_PAGE_KEY).forEach((key) => {
      removeLocalStoragePageNumber(key)
    })
  }

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: keyof EducationComment,
    order: Order
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    const selectedOrder = isAsc ? 'desc' : 'asc'
    setOrder(selectedOrder)
    setOrderBy(property)

    setLocalStorageOrder(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_COMMENTS,
      selectedOrder,
      String(property)
    )
  }
  React.useEffect(() => {
    // ソート条件をlocalStorageから取得してset
    const { order, orderBy } = getLocalStorageOrder(
      LOCAL_STORAGE_PAGE_KEY.DELIVERY_REQUESTS
    )
    if (isOrder(order) && isOrderBy(orderBy)) {
      setOrder(order)
      setOrderBy(orderBy)
    }
  }, [])

  const customPagination = {
    rowsPerPage: rowsPerPage,
    handleChangeRowsPerPage: handleChangeRowsPerPage,
    totalCount: totalCount,
    page: page,
    handleChangePage: handleChangePage,
  }

  const Pagination = () => <CustomPagination {...customPagination} />

  return (
    <Box>
      <Paper>
        <EnhancedTableToolbar isOpen={isOpen} setIsOpen={setIsOpen} />
        {isOpen && (
          <SearchForm
            setSearchParams={props.setSearchParams}
            setPaginateParams={props.setPaginateParams}
            setPage={setPage}
            defaultSearchParams={props.defaultSearchParams}
            isLoading={props.isLoading}
          />
        )}
        <div style={{ fontSize: '15px', margin: '5px 0 0 60px' }}>
          編集ボタンを選択すると管理者コメントが入力可能になります。
          <br />
          編集ボタンはドライバーコメントが登録済の場合のみ選択可能です。(ドライバー氏名がご自身の名前の行は編集できません。)
        </div>
        {props.isLoading ? (
          <div style={{ padding: 16, textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : data.length == 0 ? (
          <RecordNotFound />
        ) : (
          <>
            <Pagination />
            <TableContainer sx={{ overflowX: 'scroll' }}>
              <Table sx={{ whiteSpace: 'nowrap' }}>
                <EducationCommentsTableHead
                  headCells={headCellsItems}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <EducationCommentsTableBody
                  educationComments={data}
                  setEducationComments={setEducationComments}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  headCells={headCellsItems}
                  order={order}
                  orderBy={orderBy}
                />
              </Table>
            </TableContainer>
            <Pagination />
          </>
        )}
      </Paper>
    </Box>
  )
}
