import * as React from 'react'
import {
  ADMIN_ROUTES,
  EDUCATION_ANNUAL_PLANS,
  LOCAL_STORAGE_PAGE_KEY,
} from 'commons/constants'
import EnhancedTable, { HeadCell } from 'components/atoms/TableComponents'
import SearchForm from './SearchForm'
import { UserContext } from 'providers/UserProvider'
import {
  getLocalStorageDisplayLimit,
  getLocalStoragePageNumber,
  getLocalStorageOrder,
  isOrder,
} from 'commons/table'
import { Order } from 'commons/array'

export default function EducationAnnualPlanTable(props: {
  data: EducationAnnualPlan[]
  totalCount: number
  paginateParams: PaginateParams
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setSearchParams: React.Dispatch<
    React.SetStateAction<SearchEducationAnnualPlan>
  >
  defaultSearchParams: SearchEducationAnnualPlan
  focusId?: number
  isLoading?: boolean
}) {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof EducationAnnualPlan>('id')
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(
    props.paginateParams.displayLimit
  )
  const rows = props.data
  const totalCount = props.totalCount
  const user = React.useContext(UserContext)

  const headCells: readonly HeadCell<EducationAnnualPlan>[] = [
    {
      id: 'annualPlanTitle',
      label: EDUCATION_ANNUAL_PLANS.LABELS.ANNUAL_PLAN_TITLE,
      type: 'string',
    },
    {
      id: 'year',
      label: EDUCATION_ANNUAL_PLANS.LABELS.FISCAL_YEAR,
      type: 'string',
    },
    {
      id: 'officeName',
      label: EDUCATION_ANNUAL_PLANS.LABELS.OFFICE,
      type: 'string',
    },
  ]

  const headCellsWithCompany: readonly HeadCell<EducationAnnualPlan>[] = [
    {
      id: 'annualPlanTitle',
      label: EDUCATION_ANNUAL_PLANS.LABELS.ANNUAL_PLAN_TITLE,
      type: 'string',
    },
    {
      id: 'year',
      label: EDUCATION_ANNUAL_PLANS.LABELS.FISCAL_YEAR,
      type: 'string',
    },
    {
      id: 'companyName',
      label: EDUCATION_ANNUAL_PLANS.LABELS.COMPANY,
      type: 'string',
    },
    {
      id: 'officeName',
      label: EDUCATION_ANNUAL_PLANS.LABELS.OFFICE,
      type: 'string',
    },
  ]
  const isOrderBy = (arg): arg is keyof EducationAnnualPlan => {
    const headCellItems =
      user.isXmileAdmin || user.isGroupParentCompanyAdmin
        ? headCellsWithCompany
        : headCells
    const arr = headCellItems.map((h) => {
      return h.id
    })
    return arr.includes(arg)
  }
  // ソート条件をlocalStorageから取得してset
  React.useEffect(() => {
    const { order, orderBy } = getLocalStorageOrder(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_ANNUAL_PLAN
    )
    if (isOrder(order) && isOrderBy(orderBy)) {
      setOrder(order)
      setOrderBy(orderBy)
    }
    //「ページあたりの行数」をloalStorageから取得してセット
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = getLocalStoragePageNumber(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_ANNUAL_PLAN,
      true
    )
    const newPaginateParams = {
      pageNumber: pageNumber,
      displayLimit: displayLimit,
    }
    props.setPaginateParams(newPaginateParams)
    setRowsPerPage(displayLimit)
    setPage(pageNumber)
  }, [])
  return (
    <EnhancedTable<EducationAnnualPlan>
      {...props}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      page={page}
      setPage={setPage}
      rows={rows}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      headCells={
        user.isXmileAdmin || user.isGroupParentCompanyAdmin
          ? headCellsWithCompany
          : headCells
      }
      totalCount={totalCount}
      localStoragePageKey={LOCAL_STORAGE_PAGE_KEY.EDUCATION_ANNUAL_PLAN}
      basePath={ADMIN_ROUTES.EDUCATION_ANNUAL_PLANS_INDEX}
      isNewVersion
      searchForm={
        <SearchForm
          setSearchParams={props.setSearchParams}
          setPaginateParams={props.setPaginateParams}
          setPage={setPage}
          defaultSearchParams={props.defaultSearchParams}
          isLoading={props.isLoading}
        />
      }
    />
  )
}
