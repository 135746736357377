import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BackGroundGray } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomInputShort,
  CustomForm,
  ResetButton,
  CustomSelect,
  WithEmptyOption,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Box, Typography } from '@mui/material'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { ENUMS } from 'commons/enums'
import SearchCompanyAndOffice from 'components/atoms/ReactHookFormPartials/SearchForm/SearchCompanyAndOffice'
import { LOCAL_STORAGE_PAGE_KEY } from 'commons/constants'
import {
  setLocalStorageSearchParams,
  getLocalStorageDisplayLimit,
  removeLocalStoragePageNumber,
} from 'commons/table'

interface Props {
  setSearchParams: React.Dispatch<React.SetStateAction<SearchDriver>>
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  searchParams: SearchDriver
  defaultSearchParams: SearchDriver
  isLoading?: boolean
}

function SearchForm(props: Props) {
  const {
    setSearchParams,
    setPaginateParams,
    setPage,
    searchParams,
    defaultSearchParams,
    isLoading = false,
  } = props

  // フォームオブジェクト
  const { register, handleSubmit, control, reset, getValues, setValue, watch } =
    useForm<SearchDriver>({
      mode: 'onSubmit',
      defaultValues: searchParams,
    })

  // フォームsubmit時のコールバック
  const onSubmit = (data: SearchDriver) => {
    setSearchParams(data)
    setLocalStorageSearchParams(LOCAL_STORAGE_PAGE_KEY.DRIVERS, data)
  }

  const resetParams = () => {
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = 1
    setPaginateParams({ pageNumber, displayLimit: displayLimit })
    setPage(pageNumber)
    removeLocalStoragePageNumber(LOCAL_STORAGE_PAGE_KEY.DRIVERS)
    setSearchParams(defaultSearchParams)
    setLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.DRIVERS,
      defaultSearchParams
    )
  }

  const currentCareerStatusOptions = WithEmptyOption([
    ...Object.values(ENUMS.DRIVERS.CURRENT_CAREER_STATUS),
  ])

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '90%' }}>
        <BackGroundGray>
          <Typography
            sx={{ marginBottom: '20px', fontWeight: 'bold' }}
            variant={'body1'}
          >
            条件で絞り込み
          </Typography>
          <CustomForm onSubmit={handleSubmit(onSubmit)}>
            <FlexBox>
              <CustomLabel>
                苗字（カナ）
                <CustomInputShort {...register('lastNameKana')} />
              </CustomLabel>
              <CustomLabel>
                名前（カナ）
                <CustomInputShort {...register('firstNameKana')} />
              </CustomLabel>
            </FlexBox>
            <SearchCompanyAndOffice
              companyKeyName={'companyId'}
              officeKeyName={'officeId'}
              control={control}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
            />
            <FlexBox>
              <CustomLabel>
                在籍状況
                <Controller
                  name="currentCareer"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      options={currentCareerStatusOptions}
                      value={currentCareerStatusOptions.find(
                        (c) => c.value === value
                      )}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option.value)
                      }
                      placeholder="全て"
                    />
                  )}
                />
              </CustomLabel>
            </FlexBox>

            <ApplyButton pattern="inRow" type="submit" disabled={isLoading}>
              絞り込む
            </ApplyButton>
            <ResetButton
              onClick={() => {
                reset(defaultSearchParams)
                resetParams()
              }}
              disabled={isLoading}
            >
              <Typography>リセット</Typography>
            </ResetButton>
          </CustomForm>
        </BackGroundGray>
      </Box>
    </Box>
  )
}

export default SearchForm
