import * as React from 'react'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import AlcoholCheckDetail from 'components/organisms/alcohol_checks/Detail'
import { EditButton, TelButton } from 'components/atoms/ButtonComponenets'
import { getAlcoholCheck } from 'components/apis/alcohol_checks'
import { useParams, useNavigate } from 'react-router-dom'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { ADMIN_MENUS } from 'commons/menus'
import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import { FlexBox } from 'components/atoms/BoxComponents'
import { useTheme } from '@mui/material'

function AdminAlcoholChecksShow() {
  const { id } = useParams()
  const theme = useTheme()
  const navigate = useNavigate()
  const { data: alcoholCheck, isLoading } = useQuery(
    [`alcohol_checks/edit`, 'alcohol_check'],
    () => getAlcoholCheck(parseInt(id)).then((res) => res.data)
  )

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.ALCOHOL_CHECKS.SHOW ?? APPLICATION_NAME}</title>
      </Helmet>
      <FlexBox
        justifyContent={'space-between'}
        sx={{
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
          alignItems: 'center',
        }}
      >
        <h1>{ADMIN_MENUS.alcoholChecks.title}</h1>
        <FlexBox
          sx={{
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
            alignItems: 'center',
          }}
        >
          {!isLoading && alcoholCheck?.phoneNumber && (
            <TelButton
              sx={{
                [theme.breakpoints.down('sm')]: {
                  marginBottom: '8px',
                },
                [theme.breakpoints.up('sm')]: {
                  marginRight: '8px',
                },
              }}
              onClick={() =>
                (location.href = `tel:${alcoholCheck.phoneNumber}`)
              }
            >
              管理者に連絡
            </TelButton>
          )}
          <EditButton
            onClick={() => {
              navigate('/admin/alcohol_checks/new')
            }}
          >
            編集する
          </EditButton>
        </FlexBox>
      </FlexBox>
      {isLoading ? (
        <CircularProgressBox />
      ) : (
        <AlcoholCheckDetail data={alcoholCheck} />
      )}
    </PageWrapper>
  )
}

export default AdminAlcoholChecksShow
