import * as React from 'react'
import { ADMIN_MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { getArticle } from 'components/apis/articles'
import { useParams, useNavigate } from 'react-router-dom'
import { useErrorHandler } from 'react-error-boundary'
import ArticleMaintenancesDetail from 'components/organisms/articles/maintenances/Detail'
import { FlexBox } from 'components/atoms/BoxComponents'
import { EditButton } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { useTheme } from '@mui/material'

function ArticleMaintenancesShow() {
  const [article, setArticle] = React.useState<Article>(null)
  const [loading, setLoading] = React.useState(true)
  const { id } = useParams()
  const theme = useTheme()
  const navigate = useNavigate()
  const handleError = useErrorHandler()

  React.useEffect(() => {
    getArticle(parseInt(id))
      .then((response) => {
        setArticle(response.data)
      })
      .catch((e) => {
        handleError(e.response.status)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <PageWrapper>
      <FlexBox
        justifyContent={'space-between'}
        sx={{
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
          [theme.breakpoints.up('sm')]: {
            marginRight: '16px',
          },
          alignItems: 'center',
        }}
      >
        <h1>{ADMIN_MENUS.articles.children.article_maintenances.title}</h1>
        {!loading && (
          <FlexBox
            gap={2}
            sx={{
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
              },
            }}
          >
            <ApplyButton onClick={() => navigate('user_articles/')}>
              確認状況
            </ApplyButton>
            <EditButton
              onClick={() => {
                navigate('edit/')
              }}
            >
              編集する
            </EditButton>
          </FlexBox>
        )}
      </FlexBox>
      {loading ? (
        <CircularProgressBox />
      ) : (
        <ArticleMaintenancesDetail data={article} />
      )}
    </PageWrapper>
  )
}

export default ArticleMaintenancesShow
