import * as React from 'react'
import {
  BackGroundPalePink,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import { Box, styled } from '@mui/material'
import { palette, theme } from 'components/theme'
import { VEHICLES } from 'commons/constants'
import { FlexBox } from 'components/atoms/BoxComponents'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

type InspectionsOthersProps = {
  data: Vehicle
  inspectionsOthersImages: File[]
  inspectionOthersChildrenFiles: File[][]
}

export default function InspectionsOthers(props: InspectionsOthersProps) {
  const Title = styled('h2')({
    fontSize: '20px',
    margin: 0,
    marginBottom: '16px',
  })
  const SubTitle = styled('p')({
    fontSize: '16px',
    margin: 0,
    color: palette.text.gray.deep,
    fontWeight: 'bold',
  })

  const Text = styled('p')({
    fontSize: '16px',
    margin: 0,
  })
  const Label = styled('p')({
    fontSize: '14px',
    margin: 0,
    color: palette.text.gray.pale,
    marginTop: '32px',
    marginBottom: '8px',
  })
  const TextWrapper = styled('div')({
    width: '300px',
    paddingBottom: '8px',
    paddingLeft: '4px',
    borderBottom: `1px solid ${palette.border.pale}`,
    overflowWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  })
  const TextWrapperFull = styled(TextWrapper)({
    width: '628px',
  })
  const FileTextWrapper = styled(TextWrapper)({
    width: '100%',
    marginTop: '16px',
  })
  const TextWrapperLong = styled(TextWrapper)({
    width: '420px',
  })
  const TextWrapperShort = styled(TextWrapper)({
    width: '140px',
  })

  const formatDate = (year: string, month: string, day: string) => {
    if (year && month && day) return `${year}年${month}月${day}日`
    else return '未入力'
  }
  const formatMoneyText = (money: string | number) => {
    if (!money) return ''
    const parsed = parseInt(money as string)
    if (isNaN(parsed)) {
      return money
    } else {
      return new Intl.NumberFormat('ja-JP', {
        style: 'currency',
        currency: 'JPY',
      }).format(parsed)
    }
  }

  const inspectionResults = { '0': '否', '1': '良' }

  return (
    <BackGroundWhite>
      <Title>{VEHICLES.LABELS.INSPECTIONS_OTHERS}</Title>
      {props.data.inspectionsOthers.length == 0 ? (
        <Text>データがありません</Text>
      ) : (
        props.data.inspectionsOthers.map((inspectionOther, i) => {
          return (
            <Box mb={3} key={`inspection-other-${i}`}>
              <BackGroundPalePink>
                <SubTitle>
                  {VEHICLES.LABELS.INSPECTIONS_OTHERS}
                  {i + 1}
                </SubTitle>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.INSPECTIONS_INSPECT_DATE}</Label>
                    <TextWrapper>
                      <Text>
                        {formatDate(
                          inspectionOther.inspectYear,
                          inspectionOther.inspectMonth,
                          inspectionOther.inspectDay
                        ) || '未入力'}
                      </Text>
                    </TextWrapper>
                  </Box>
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.INSPECTIONS_COMPANY_NAME}</Label>
                    <TextWrapperLong>
                      <Text>{inspectionOther.companyName || '未入力'}</Text>
                    </TextWrapperLong>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.INSPECTIONS_TOTAL_FEE}</Label>
                    <TextWrapperShort>
                      <Text>
                        {formatMoneyText(inspectionOther.totalFee) || '未入力'}
                      </Text>
                    </TextWrapperShort>
                  </Box>
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.INSPECTIONS_PARTS_FEE}</Label>
                    <TextWrapperShort>
                      <Text>
                        {formatMoneyText(inspectionOther.partsFee) || '未入力'}
                      </Text>
                    </TextWrapperShort>
                  </Box>
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.INSPECTIONS_TECHNICAL_FEE}</Label>
                    <TextWrapperShort>
                      <Text>
                        {formatMoneyText(inspectionOther.technicalFee) ||
                          '未入力'}
                      </Text>
                    </TextWrapperShort>
                  </Box>
                  <Box>
                    <Label>{VEHICLES.LABELS.INSPECTIONS_MILEAGE}</Label>
                    <TextWrapperShort>
                      <Text>
                        {inspectionOther.mileage
                          ? `${inspectionOther.mileage}km`
                          : '未入力'}
                      </Text>
                    </TextWrapperShort>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.INSPECTIONS_NEXT_DATE}</Label>
                    <TextWrapper>
                      <Text>
                        {formatDate(
                          inspectionOther.nextYear,
                          inspectionOther.nextMonth,
                          inspectionOther.nextDay
                        ) || '未入力'}
                      </Text>
                    </TextWrapper>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.INSPECTIONS_IS_ACCEPTABLE}</Label>
                    <TextWrapper>
                      <Text>
                        {inspectionResults[inspectionOther.isAcceptable] ||
                          '未入力'}
                      </Text>
                    </TextWrapper>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box>
                    <Label>{VEHICLES.LABELS.INSPECTIONS_MEMO}</Label>
                    <TextWrapperFull>
                      <Text>{inspectionOther.memo || '未入力'}</Text>
                    </TextWrapperFull>
                  </Box>
                </FlexBox>
                <Label>
                  {VEHICLES.LABELS.FILE}(
                  {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
                </Label>
                <FileTextWrapper>
                  {props.inspectionOthersChildrenFiles[i]?.length > 0 ? (
                    props.inspectionOthersChildrenFiles[i].map((file, i) => {
                      return (
                        <FlexBox key={`${file.name}-${i}`} mb={1}>
                          <InsertDriveFileOutlinedIcon fontSize="small" />
                          <Text sx={{ marginLeft: '8px' }}>
                            <a
                              href={URL.createObjectURL(file)}
                              download={decodeURI(file.name)}
                            >
                              {decodeURI(file.name)}
                            </a>
                          </Text>
                        </FlexBox>
                      )
                    })
                  ) : (
                    <Text>ファイルなし</Text>
                  )}
                </FileTextWrapper>
              </BackGroundPalePink>
            </Box>
          )
        })
      )}
      <Label>
        {VEHICLES.LABELS.FILE}({VEHICLES.LABELS.INSPECTIONS_OTHERS}の
        {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_PARENT})
      </Label>
      <FileTextWrapper>
        {props.inspectionsOthersImages.length > 0 ? (
          props.inspectionsOthersImages.map((file, i) => {
            return (
              <FlexBox key={`${file.name}-${i}`} mb={1}>
                <InsertDriveFileOutlinedIcon fontSize="small" />
                <Text sx={{ marginLeft: '8px' }}>
                  <a
                    href={URL.createObjectURL(file)}
                    download={decodeURI(file.name)}
                  >
                    {decodeURI(file.name)}
                  </a>
                </Text>
              </FlexBox>
            )
          })
        ) : (
          <Text>ファイルなし</Text>
        )}
      </FileTextWrapper>
    </BackGroundWhite>
  )
}
