import * as React from 'react'
import { Button } from '@mui/material'

type ModalProps = {
  education: Education
  onClose: () => void
}

function MoveEducationTestDialog({ education, onClose }: ModalProps) {
  return (
    <div>
      こちらで動画の視聴は完了です。WEBテストに進んでください。
      <br />
      <Button onClick={() => window.open(`tests/${education.id}`)}>
        WEBテストを受験
      </Button>
      <Button onClick={onClose}>閉じる</Button>
    </div>
  )
}

export default MoveEducationTestDialog
