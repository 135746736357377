import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { addParamPrefix, camelCaseKeysToUnderscore } from 'commons/functions'

export function fetchEducationComments(
  baseParams: EducationComment,
  searchParams: SearchEducationComment,
  paginateParams: PaginateParams
) {
  const customSearchParams = addParamPrefix(searchParams, 'search')
  const customPaginateParams = addParamPrefix(paginateParams, 'pagination')
  const params = camelCaseKeysToUnderscore({
    ...baseParams,
    ...customSearchParams,
    ...customPaginateParams,
  })

  return axios.get(API_ENDPOINTS.EDUCATION_COMMENTS, {
    params: params,
  })
}

export function updateEducationComment(params: EducationComment) {
  return axios.put(
    [API_ENDPOINTS.EDUCATION_COMMENTS, 'update_attributes'].join(''),
    camelCaseKeysToUnderscore(params)
  )
}

export function updateAdminEducationComment(params: EducationComment) {
  return axios.patch(
    [API_ENDPOINTS.EDUCATION_COMMENTS, params.id].join(''),
    camelCaseKeysToUnderscore(params)
  )
}

export function fetchEducationComment(params: EducationComment) {
  return axios.get(
    [API_ENDPOINTS.EDUCATION_COMMENTS, 'find_by_education'].join(''),
    {
      params: camelCaseKeysToUnderscore(params),
    }
  )
}

export function fetchEducationResult(params: EducationComment) {
  return axios.get(
    [API_ENDPOINTS.EDUCATION_COMMENTS, 'education_result'].join(''),
    {
      params: camelCaseKeysToUnderscore(params),
    }
  )
}
