import * as React from 'react'
import {
  CustomDateSelect,
  CustomInput,
  CustomLabel,
  CustomTextArea,
  ErrorMessage,
  CustomDropZone,
  WithRequiredBadge,
} from 'components/atoms/FormComponents'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { DRIVERS, MEDICAL_RECORDS } from 'commons/constants'
import { BackGroundPalePink } from 'components/atoms/BackGroundComponents'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { styled } from '@mui/styles'
import { formatCustomDateErrorMessage } from 'commons/errorMessage'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { FlexBox } from 'components/atoms/BoxComponents'

type MedicalRecordCardProps = {
  register: UseFormRegister<Driver>
  setValue: UseFormSetValue<Driver>
  watch: UseFormWatch<Driver>
  control: Control<Driver>
  errors: FieldErrors<Driver>
  appendEmptyItem: () => void
  deleteItem: (index: number) => void
  index: number
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>
}

const MedicalRecordCard: React.FC<MedicalRecordCardProps> = (props) => {
  const { register, control, setValue, watch, index, errors } = props
  const customDateErrorMessage = React.useCallback(() => {
    return formatCustomDateErrorMessage({
      year: errors?.medicalRecords?.[index]?.consultationYear,
      month: errors?.medicalRecords?.[index]?.consultationMonth,
      day: errors?.medicalRecords?.[index]?.consultationDay,
    })
  }, [errors?.medicalRecords?.[index]])

  const CardTitle = styled('p')({
    fontWeight: 'bold',
  })

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const oldFiles = watch(`medicalRecords.${index}.files`)
        ? watch(`medicalRecords.${index}.files`)
        : []
      setValue(`medicalRecords.${index}.files`, [...oldFiles, ...acceptedFiles])
    },
    [watch(`medicalRecords.${index}.files`)]
  )

  return (
    <BackGroundPalePink sx={{ marginBottom: '30px' }}>
      <CardTitle>
        {DRIVERS.LABELS.MEDICAL_RECORDS}
        {index + 1}
      </CardTitle>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        <WithRequiredBadge>
          {MEDICAL_RECORDS.LABELS.CONSULTATION_DATE}
        </WithRequiredBadge>
        <CustomDateSelect
          formKeys={{
            year: `medicalRecords.${index}.consultationYear`,
            month: `medicalRecords.${index}.consultationMonth`,
            day: `medicalRecords.${index}.consultationDay`,
          }}
          required
          control={control}
        />
        <ErrorMessage>{customDateErrorMessage()}</ErrorMessage>
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {MEDICAL_RECORDS.LABELS.RESULT}
        <CustomInput
          sx={{ marginTop: '4px', height: '38px' }}
          onKeyDown={props.onInputKeyDown}
          {...register(`medicalRecords.${index}.result`)}
        />
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {MEDICAL_RECORDS.LABELS.MEMO}
        <CustomTextArea
          sx={{ marginTop: '4px', height: '38px' }}
          {...register(`medicalRecords.${index}.memo`, {})}
        />
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {DRIVERS.LABELS.FILE_UPLOAD}(健康診断の
        {DRIVERS.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
        <Controller
          render={() => (
            <CustomDropZone
              multiple={true}
              onDrop={onDrop}
              accept={'image/*, application/pdf'}
            />
          )}
          name={`medicalRecords.${index}.fileUrls`}
          control={control}
        />
        {watch(`medicalRecords.${index}.files`) &&
          Object.values(watch(`medicalRecords.${index}.files`))?.map(
            (image: File, fileIndex: number) => (
              <FlexBox flexDirection={'row'} key={fileIndex}>
                <p>
                  <a
                    href={URL.createObjectURL(image)}
                    download={decodeURI(image.name)}
                  >
                    {decodeURI(image.name)}
                  </a>
                </p>
                <IconButton
                  component="span"
                  onClick={() =>
                    setValue(
                      `medicalRecords.${index}.files`,
                      watch(`medicalRecords.${index}.files`).filter(
                        (_, i) => i !== fileIndex
                      )
                    )
                  }
                >
                  <CancelIcon />
                </IconButton>
              </FlexBox>
            )
          )}
      </CustomLabel>
      <DeleteButton onClick={() => props.deleteItem(index)}>削除</DeleteButton>
    </BackGroundPalePink>
  )
}

export default MedicalRecordCard
