export const JapaneseDateFormat = (jst: Date | string) => {
  if (jst == null || jst == undefined) {
    return
  }

  if (typeof jst == 'string') {
    try {
      jst = new Date(jst)
    } catch {
      return
    }
  }

  function pad(number) {
    if (number < 10) {
      return '0' + number
    }
    return number
  }

  return (
    jst.getFullYear() +
    '年' +
    pad(jst.getMonth() + 1) +
    '月' +
    pad(jst.getDate()) +
    '日' +
    pad(jst.getHours()) +
    ':' +
    pad(jst.getMinutes()) +
    ':' +
    pad(jst.getSeconds())
  )
}

export const paymentDayFormat = (paymentDay: number) => {
  switch (paymentDay) {
    case 31:
      return '末日'
    case null:
      return ''
    default:
      return `${paymentDay}日`
  }
}

//指定日時をYYYYMMDDHHMMSS形式に変換する関数
export const toFormattedDateTime = (date: Date) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const min = date.getMinutes()
  const sec = date.getSeconds()

  const formattedDateTime =
    year +
    String(month).padStart(2, '0') +
    String(day).padStart(2, '0') +
    String(hour).padStart(2, '0') +
    String(min).padStart(2, '0') +
    String(sec).padStart(2, '0')

  return formattedDateTime
}
