import * as React from 'react'
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormGetValues,
} from 'react-hook-form'
import {
  BackGroundPalePink,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import {
  ConvertibleCustomDateSelect,
  ConvertibleCustomTextArea,
  CustomLabel,
  HeadingText,
} from 'components/atoms/FormComponents'
import { Box, styled } from '@mui/material'
import { palette } from 'components/theme'
import { FlexBox } from 'components/atoms/BoxComponents'
import { DRIVERS, INSURANCES } from 'commons/constants'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { useNavigate } from 'react-router-dom'

type InsurancesProps = {
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
  handleModeChange: (mapKey: DriverFormModeMapKey, mode: FormMode) => void
}

export default function Insurances(props: InsurancesProps) {
  const navigate = useNavigate()

  const reload = () => {
    if (
      window.confirm('保存していないデータは削除されます。よろしいですか？')
    ) {
      navigate(0)
    }
  }

  return (
    <BackGroundWhite>
      <FlexBox justifyContent="space-between" marginBottom="16px">
        <HeadingText>{DRIVERS.LABELS.INSURANCE}</HeadingText>
        <Box>
          {/* 一時的にコメントアウト */}
          {/* {props.mode == 'show' && (
            <EditButtonOutlined
              onClick={() => {
                props.handleModeChange('profile', 'edit')
              }}
            >
              編集
            </EditButtonOutlined>
          )} */}
          {props.mode == 'edit' && (
            <FlexBox>
              <ApplyButton pattern="marginRight8" type="submit">
                保存する
              </ApplyButton>
              <CancelButtonOutlined onClick={reload}>
                キャンセル
              </CancelButtonOutlined>
            </FlexBox>
          )}
        </Box>
      </FlexBox>
      {Object.keys(INSURANCES.LABELS.TYPES).map((label, index) => {
        return (
          <InsuranceInner
            key={index}
            mode={props.mode}
            control={props.control}
            register={props.register}
            errors={props.errors}
            getValues={props.getValues}
            insuranceLabel={label}
          ></InsuranceInner>
        )
      })}
    </BackGroundWhite>
  )
}

type InsuranceInnerProps = {
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
  insuranceLabel: string
}

const InsuranceInner: React.FC<InsuranceInnerProps> = (props) => {
  const Title = styled('p')({
    fontWeight: 'bold',
    color: palette.text.gray.deep,
  })
  const Label = styled('p')({
    fontSize: '14px',
    margin: 0,
    color: palette.text.gray.pale,
  })

  const { insuranceLabel } = props
  const insuranceType = insuranceLabel.toLowerCase()
  return (
    <BackGroundPalePink sx={{ marginBottom: '16px' }}>
      <Title>{INSURANCES.LABELS.TYPES[insuranceLabel]}</Title>
      <CustomLabel>
        <Label sx={{ marginTop: '8px' }}>{INSURANCES.LABELS.DATE}</Label>
        <ConvertibleCustomDateSelect
          mode={props.mode}
          formKeys={{
            year: `insurance.${insuranceType}.subscribedYear`,
            month: `insurance.${insuranceType}.subscribedMonth`,
            day: `insurance.${insuranceType}.subscribedDay`,
          }}
          control={props.control}
          getValues={props.getValues}
        />
      </CustomLabel>
      <CustomLabel>
        <Label sx={{ marginTop: '8px' }}>{INSURANCES.LABELS.NUMBER}</Label>
        <ConvertibleCustomTextArea
          mode={props.mode}
          formKey={`insurance.${insuranceType}.number`}
          register={props.register}
          getValues={props.getValues}
        />
      </CustomLabel>
    </BackGroundPalePink>
  )
}
