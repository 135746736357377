import * as React from 'react'
import { Box } from '@mui/material'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { ADMIN_MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import AdminEducationList from 'components/pages/admin/education_comments/educationList'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'

function AdminEducationCommentsIndex() {
  return (
    <PageWrapper>
      <Helmet>
        <title>
          {PAGE_TITLES.EDUCATIONS.COMMENTS.INDEX ?? APPLICATION_NAME}
        </title>
      </Helmet>
      <h1>{ADMIN_MENUS.educations.children.education_comments.title}</h1>
      <Box sx={{ marginTop: '30px' }}>
        <BackGroundWhite>
          <AdminEducationList />
        </BackGroundWhite>
      </Box>
    </PageWrapper>
  )
}

export default AdminEducationCommentsIndex
