import * as React from 'react'
import { FormControlLabel, Switch } from '@mui/material'
import { palette } from 'components/theme'

type ToggleProps = {
  value: string
  label: string
  onChangeSwitch: () => void
  formControlLabelStyle?: React.CSSProperties
  switchStyle?: React.CSSProperties
}

export function ToggleButton({
  value,
  label,
  onChangeSwitch,
  formControlLabelStyle,
  switchStyle,
}: ToggleProps) {
  return (
    <FormControlLabel
      value={value}
      control={
        <Switch
          color="primary"
          onChange={onChangeSwitch}
          sx={{
            marginBottom: '2px',
            '& .MuiSwitch-switchBase.Mui-checked': {
              color: palette.primary.main,
            },
            '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
              backgroundColor: palette.primary.main,
            },
            ...switchStyle,
          }}
        />
      }
      label={label}
      labelPlacement="start"
      sx={{ ...formControlLabelStyle }}
    />
  )
}
