import * as React from 'react'
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormGetValues,
  useFieldArray,
} from 'react-hook-form'
import {
  BackGroundPalePink,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  ErrorMessage,
  ConvertibleCustomInput,
  ConvertibleCustomSelect,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import {
  DRIVERS,
  DRIVING_EXPERIENCES,
  VALIDATION_MESSAGES,
} from 'commons/constants'
import { Box, styled } from '@mui/material'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { useNavigate } from 'react-router-dom'
import { palette } from 'components/theme'

type DrivingExperiencesProps = {
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
  handleModeChange: (mapKey: DriverFormModeMapKey, mode: FormMode) => void
}

export default function DrivingExperiences(props: DrivingExperiencesProps) {
  const navigate = useNavigate()
  const { fields } = useFieldArray({
    control: props.control,
    name: 'drivingExperiences',
  })

  const reload = () => {
    if (
      window.confirm('保存していないデータは削除されます。よろしいですか？')
    ) {
      navigate(0)
    }
  }

  return (
    <BackGroundWhite>
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        marginBottom="16px"
      >
        <HeadingText>{DRIVERS.LABELS.DRIVING_EXPERIENCE}</HeadingText>
        <Box>
          {/* 一時的にコメントアウト */}
          {/* {props.mode == 'show' && (
            <EditButtonOutlined
              onClick={() => {
                props.handleModeChange('drivingExperiences', 'edit')
              }}
            >
              編集
            </EditButtonOutlined>
          )} */}
          {props.mode == 'edit' && (
            <FlexBox>
              <ApplyButton pattern="marginRight8" type="submit">
                保存する
              </ApplyButton>
              <CancelButtonOutlined onClick={reload}>
                キャンセル
              </CancelButtonOutlined>
            </FlexBox>
          )}
        </Box>
      </FlexBox>
      {fields.length == 0 && <p>データがありません</p>}
      {fields.map((_, i) => {
        return (
          <Box key={`personal-history-card-${i}`} mb={2}>
            <PersonalHistoryInner
              index={i}
              mode={props.mode}
              register={props.register}
              errors={props.errors}
              getValues={props.getValues}
              control={props.control}
            ></PersonalHistoryInner>
          </Box>
        )
      })}
    </BackGroundWhite>
  )
}

type PersonalHistoryInnerProps = {
  index: number
  register: UseFormRegister<Driver>
  control: Control<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
}

const Title = styled('p')({
  fontWeight: 'bold',
  color: palette.text.gray.deep,
})

const vehicleTypeOptions = [
  { value: 'cargo', label: '貨物' },
  { value: 'passenger', label: '乗用車' },
  { value: 'bus', label: 'バス' },
]

const PersonalHistoryInner: React.FC<PersonalHistoryInnerProps> = (props) => {
  return (
    <BackGroundPalePink>
      <Title>
        {DRIVERS.LABELS.DRIVING_EXPERIENCE}
        {props.index + 1}
      </Title>
      <FlexBox alignItems="center" flexWrap="wrap">
        <CustomLabel sx={{ marginRight: '32px' }}>
          {DRIVING_EXPERIENCES.LABELS.VEHICLE_TYPE}
          <ConvertibleCustomSelect
            mode={props.mode}
            formKey={`drivingExperiences.${props.index}.vehicleType`}
            control={props.control}
            getValues={props.getValues}
            options={vehicleTypeOptions}
          ></ConvertibleCustomSelect>
        </CustomLabel>
        <CustomLabel sx={{ marginRight: '32px' }}>
          {DRIVING_EXPERIENCES.LABELS.CAPACITY}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey={`drivingExperiences.${props.index}.capacity`}
            register={props.register}
            getValues={props.getValues}
            suffix="人"
          ></ConvertibleCustomInput>
          {props.errors.drivingExperiences?.[props.index]?.capacity && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
        <CustomLabel>
          {DRIVING_EXPERIENCES.LABELS.LOAD_CAPACITY}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey={`drivingExperiences.${props.index}.loadCapacity`}
            register={props.register}
            getValues={props.getValues}
            suffix="t"
          ></ConvertibleCustomInput>
          {props.errors.drivingExperiences?.[props.index]?.loadCapacity && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
      </FlexBox>
      <CustomLabel>
        {DRIVING_EXPERIENCES.LABELS.EXPERIENCE_DATE}
        <FlexBox>
          <Box marginRight="32px">
            <ConvertibleCustomInput
              mode={props.mode}
              formKey={`drivingExperiences.${props.index}.experienceYear`}
              register={props.register}
              getValues={props.getValues}
              suffix="年"
            ></ConvertibleCustomInput>
          </Box>
          <Box>
            <ConvertibleCustomInput
              mode={props.mode}
              formKey={`drivingExperiences.${props.index}.experienceMonth`}
              register={props.register}
              getValues={props.getValues}
              suffix="ヶ月"
            ></ConvertibleCustomInput>
          </Box>
        </FlexBox>
        {props.errors.drivingExperiences?.[props.index]?.experienceYear ||
          (props.errors.drivingExperiences?.[props.index]?.experienceMonth && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          ))}
      </CustomLabel>
      <CustomLabel>
        {DRIVING_EXPERIENCES.LABELS.OFFICE_NAME}
        <ConvertibleCustomInput
          mode={props.mode}
          formKey={`drivingExperiences.${props.index}.officeName`}
          register={props.register}
          getValues={props.getValues}
        ></ConvertibleCustomInput>
        {props.errors.drivingExperiences?.[props.index]?.officeName && (
          <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
        )}
      </CustomLabel>
    </BackGroundPalePink>
  )
}
