// app/javascript/axiosClient.ts

import axios from 'axios'
import * as Sentry from '@sentry/react'

const client = axios.create()

client.interceptors.request.use(
  (config) => {
    const token = document.querySelector<HTMLMetaElement>(
      'meta[name="csrf-token"]'
    )?.content

    config.headers['X-CSRF-Token'] = token
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    Sentry.captureException(error)
  }
)

export default client
