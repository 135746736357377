import * as React from 'react'
import Forbidden from './Forbidden'
import NotFound from './NotFound'
import PaymentRequired from './PaymentRequired'
import TooManyRequests from './TooManyRequests'
import Unauthorized from './Unauthorized'
import Unknown from './Unknown'

type ErrorProps = {
  errorCode?: number
}

const Error: React.FC<ErrorProps> = (props) => {
  const search = window.location.search
  const queryParams = new URLSearchParams(search)
  const code = props.errorCode
    ? props.errorCode
    : parseInt(queryParams.get('status'))

  switch (code) {
    case 401:
      return <Unauthorized />
    case 402:
      return <PaymentRequired />
    case 403:
      return <Forbidden />
    case 404:
      return <NotFound />
    case 429:
      return <TooManyRequests />
    default:
      return <Unknown />
  }
}

export default Error
