import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { UserContext } from 'providers/UserProvider'
import {
  CustomLabel,
  CustomInput,
  CustomSelect,
  CustomTextArea,
  ErrorMessage,
  WithRequiredBadge,
  CustomDropZone,
} from 'components/atoms/FormComponents'
import {
  VALIDATION_MESSAGES,
  FUND_CONTACTS,
  EXECUTIVES_ROUTES,
} from 'commons/constants'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { FlexBox } from 'components/atoms/BoxComponents'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useErrorHandler } from 'react-error-boundary'
import {
  createFundContact,
  fetchFundContactTypeList,
} from 'components/apis/fund_contacts'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import CustomModal from 'components/atoms/CustomModal'
import { toastOnError } from 'commons/toaster'
import { useMutation } from '@tanstack/react-query'
import { validateEmailInput } from 'commons/validation'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { slackNotification } from 'commons/slackNotification'

export default function FundContactsForm() {
  const user = React.useContext(UserContext)
  const handleError = useErrorHandler()
  const [isModalShow, setModalShow] = React.useState(false)
  const [files, setFiles] = React.useState<File[]>([])

  const defaultValues = {
    companyName: user.company.name,
    officeName: user.office?.name,
    userName: user.fullName,
    contactAddress: user.email,
    contactType: 'funding',
  }

  // フォームオブジェクト
  const {
    register,
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<FundContact>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: defaultValues,
  })

  const { data: contactTypeList, isLoading: isLoading } = useQuery(
    [`executives/fund_contacts/new`, 'contact_type_list'],
    () => fetchFundContactTypeList().then((res) => res.data),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      refetchOnWindowFocus: false,
    }
  )

  const mutation = useMutation({
    mutationFn: createFundContact,
    onSuccess: () => {
      setModalShow(false)
      window.location.href = EXECUTIVES_ROUTES.FUND_CONTACTS_COMPLETE
    },
    onError: (e: AxiosError<{ errors: string[] }>) => {
      setModalShow(false)
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors.join(','))
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
  })

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles])
    },
    [files]
  )

  const formatParamsData = (data) => {
    const formData = new FormData()
    files.map((image) => {
      formData.append('files[]', image)
    })

    return { data: data, formData }
  }

  const submit = async () => {
    const hasNotError = await trigger()
    if (hasNotError) {
      const copy = structuredClone(getValues())
      const { data, formData } = formatParamsData(copy)
      mutation.mutate({ data, formData })
    } else {
      toastOnError('入力内容に不備があります')
      setModalShow(false)
    }
  }

  const handleModalCancelSelected = () => {
    setModalShow(false)
  }

  return (
    <>
      {isLoading ? (
        <CircularProgressBox />
      ) : (
        <>
          <BackGroundWhite>
            <CustomLabel>
              <WithRequiredBadge>
                {FUND_CONTACTS.LABELS.COMPANY_NAME}
              </WithRequiredBadge>
              <CustomInput {...register('companyName', { required: true })} />
              {errors.companyName && (
                <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
              )}
            </CustomLabel>
            <CustomLabel>
              {FUND_CONTACTS.LABELS.OFFICE_NAME}
              <CustomInput {...register('officeName')} />
            </CustomLabel>
            <CustomLabel>
              <WithRequiredBadge>
                {FUND_CONTACTS.LABELS.USER_NAME}
              </WithRequiredBadge>
              <CustomInput {...register('userName', { required: true })} />
              {errors.userName && (
                <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
              )}
            </CustomLabel>
            <CustomLabel>
              <WithRequiredBadge>
                {FUND_CONTACTS.LABELS.CONTACT_ADDRESS}
              </WithRequiredBadge>
              <CustomInput
                {...register('contactAddress', {
                  required: true,
                  validate: { format: validateEmailInput },
                })}
              />
              {errors.contactAddress && (
                <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
              )}
            </CustomLabel>
            <CustomLabel>
              {FUND_CONTACTS.LABELS.PHONE_NUMBER}
              <CustomInput {...register('phoneNumber')} />
            </CustomLabel>
            <CustomLabel>
              <WithRequiredBadge>
                {FUND_CONTACTS.LABELS.CONTACT_TYPE}
              </WithRequiredBadge>
              <Controller
                name="contactType"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <CustomSelect
                    options={contactTypeList}
                    value={contactTypeList?.find((c) => c.value === value)}
                    onChange={(option: ReactSelectOptionProps) =>
                      onChange(option.value)
                    }
                    placeholder="全て"
                  />
                )}
              />
              {errors.contactType && (
                <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
              )}
            </CustomLabel>
            <CustomLabel>
              {FUND_CONTACTS.LABELS.MEMO}
              <CustomTextArea rows={5} {...register('memo')} />
            </CustomLabel>
            <CustomLabel>
              添付ファイル
              <Controller
                render={() => (
                  <CustomDropZone multiple={true} onDrop={onDrop} />
                )}
                name="files"
                control={control}
              />
              {Object.values(files).map((file, index) => (
                <FlexBox flexDirection={'row'} key={index}>
                  <p>
                    <a
                      href={URL.createObjectURL(file)}
                      download={decodeURI(file.name)}
                    >
                      {decodeURI(file.name)}
                    </a>
                  </p>
                  <IconButton
                    component="span"
                    onClick={() =>
                      setFiles(files.filter((_, i) => i !== index))
                    }
                  >
                    <CancelIcon />
                  </IconButton>
                </FlexBox>
              ))}
            </CustomLabel>
          </BackGroundWhite>
          <FlexBox justifyContent={'flex-end'} sx={{ marginTop: '8px' }}>
            <ApplyButton
              pattern="block"
              onClick={(e) => {
                e.preventDefault()
                setModalShow(true)
              }}
            >
              送信する
            </ApplyButton>
          </FlexBox>
          <CustomModal
            isOpen={isModalShow}
            text={{
              main: '送信しますか？',
              confirm: '送信する',
              cancel: 'キャンセル',
            }}
            onConfirmSelected={submit}
            onCancelSelected={handleModalCancelSelected}
          />
        </>
      )}
    </>
  )
}
