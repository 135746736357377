import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { addParamPrefix, camelCaseKeysToUnderscore } from 'commons/functions'
import { AxiosResponse } from 'axios'

export function fetchEducations(): Promise<
  AxiosResponse<{
    educations: Education[]
    currentMonthEducations?: Education[]
    currentMonthVideos?: Education[]
    customEducations?: Education[]
  }>
> {
  return axios.get(API_ENDPOINTS.EDUCATIONS, {
    params: addParamPrefix({ category: 'exam' }, 'search'),
  })
}

export function fetchEducationVideos() {
  return axios.get(API_ENDPOINTS.EDUCATIONS, {
    params: addParamPrefix({ category: 'video' }, 'search'),
  })
}

export function fetchAllEducationVideos() {
  return axios.get(API_ENDPOINTS.EDUCATIONS_VIDEO_INDEX)
}

export function getEducations(id: number) {
  return axios.get([API_ENDPOINTS.EDUCATIONS, id].join(''))
}

export function createEducationLogs(id: number, params: EducationLogType) {
  return axios.post(
    [API_ENDPOINTS.EDUCATIONS, id, '/logs'].join(''),
    camelCaseKeysToUnderscore(params)
  )
}

export function getEducationPdfLog(id: number) {
  return axios.get([API_ENDPOINTS.EDUCATIONS, id, '/pdf_log'].join(''))
}

export function getEducationVideoLog(id: number) {
  return axios.get([API_ENDPOINTS.EDUCATIONS, id, '/video_log'].join(''))
}

export function fetchEducationVideoList(): Promise<
  AxiosResponse<SelectOption[]>
> {
  return axios.get(API_ENDPOINTS.EDUCATIONS_VIDEO_LIST)
}

export function fetchEducationMonthlyVideos(): Promise<
  AxiosResponse<{ educationVideos: Education[] }>
> {
  return axios.get(API_ENDPOINTS.EDUCATIONS_MONTHLY_VIDEOS)
}
