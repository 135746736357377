export const PARTS_REPLACED: {
  label: string
  value: 'isTire' | 'isEngineOil' | 'isOilElement' | 'isAirFilter' | 'isBattery'
}[] = [
  { label: 'タイヤ', value: 'isTire' },
  { label: 'エンジンオイル', value: 'isEngineOil' },
  { label: 'オイルエレメント', value: 'isOilElement' },
  { label: 'エアフィルター', value: 'isAirFilter' },
  { label: 'バッテリー', value: 'isBattery' },
]
