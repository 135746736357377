import * as React from 'react'
import { XMILE_ADMIN_MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { getCompany } from 'components/apis/companies'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import CompanyDetail from 'components/organisms/companies/Detail'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import { EditButton } from 'components/atoms/ButtonComponenets'
import { useTheme } from '@mui/material'
import { FlexBox } from 'components/atoms/BoxComponents'

function AdminCompaniesShow() {
  const { id } = useParams()
  const theme = useTheme()
  const navigate = useNavigate()

  const { data: company, isLoading } = useQuery(
    [`admin/companies/${id}`, 'company'],
    () => getCompany(parseInt(id)).then((res) => res.data)
  )

  if (isLoading) {
    return <CircularProgressBox />
  }

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.COMPANIES.SHOW ?? APPLICATION_NAME}</title>
      </Helmet>
      <FlexBox
        justifyContent={'space-between'}
        sx={{
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
          alignItems: 'center',
        }}
      >
        <h1>{XMILE_ADMIN_MENUS.masters.children.companies.title}</h1>
        <EditButton
          onClick={() => {
            navigate('edit/')
          }}
        >
          編集する
        </EditButton>
      </FlexBox>
      <CompanyDetail data={company} />
    </PageWrapper>
  )
}

export default AdminCompaniesShow
