import * as React from 'react'
import { Box, CircularProgress } from '@mui/material'
import {
  APPLICATION_NAME,
  LOCAL_STORAGE_PAGE_KEY,
  PAGE_TITLES,
  TABLE_DEFAULT_ROWS,
} from 'commons/constants'
import { ADMIN_MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { UserContext } from 'providers/UserProvider'
import { useErrorHandler } from 'react-error-boundary'
import { fetchEducationComments } from 'components/apis/educationComments'
import EducationCommentsTable from 'components/organisms/education_comments/Table'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { HeadingText } from 'components/atoms/FormComponents'
import EducationContent from 'components/organisms/educations/EducationContent'
import VideoContent from 'components/organisms/educations/VideoContent'
import { styled } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getEducations } from 'components/apis/educations'
import { toastOnError } from 'commons/toaster'
import {
  getLocalStorageDisplayLimit,
  getLocalStoragePageNumber,
} from 'commons/table'

function AdminEducationCommentsChapterIndex() {
  const user = React.useContext(UserContext)
  const { id } = useParams()
  const [educationComments, setEducationComments] = React.useState([])
  const [title, setTitle] = React.useState(null)
  const params = {
    educationId: id,
  }
  const [totalCount, setTotalCount] = React.useState(0)
  const [loading, setLoading] = React.useState(true)
  const defaultSearchParams = {
    year: user.company.thisYear || new Date().getFullYear(),
    officeId: user.officeId,
  }
  const [searchParams, setSearchParams] =
    React.useState<SearchEducationComment>(defaultSearchParams)

  const displayLimit = getLocalStorageDisplayLimit()
  const pageNumber = getLocalStoragePageNumber(
    LOCAL_STORAGE_PAGE_KEY.DELIVERY_REQUESTS,
    true
  )

  const [paginateParams, setPaginateParams] = React.useState<PaginateParams>({
    pageNumber: pageNumber,
    displayLimit: displayLimit,
  })
  const handleError = useErrorHandler()

  const Section = styled('div')({
    marginTop: '1rem',
  })

  React.useEffect(() => {
    setLoading(true)
    fetchEducationComments(params, searchParams, paginateParams)
      .then((response) => {
        const data = response.data.educationComments.map(
          (educationComment) => ({
            ...educationComment,
            isEdit: false,
          })
        )
        setEducationComments(data)
        setTitle(response.data.title)
        setTotalCount(response.data.totalCount)
        if (response.data.message) {
          toastOnError(response.data.message)
        }
      })
      .catch((e) => {
        handleError(e.response.status)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [searchParams, paginateParams])

  const { data: education, isLoading: isEducationLoading } = useQuery(
    ['educations'],
    () => getEducations(parseInt(id)).then((res) => res.data)
  )

  return (
    <PageWrapper>
      <Helmet>
        <title>
          {PAGE_TITLES.EDUCATIONS.COMMENTS.INDEX ?? APPLICATION_NAME}
        </title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
        }}
      >
        <h1>{ADMIN_MENUS.educations.children.education_comments.title}</h1>
      </Box>
      <h3>{title}</h3>
      <EducationCommentsTable
        data={educationComments}
        setEducationComments={setEducationComments}
        totalCount={totalCount}
        setSearchParams={setSearchParams}
        paginateParams={paginateParams}
        setPaginateParams={setPaginateParams}
        defaultSearchParams={defaultSearchParams}
        isLoading={loading}
      />
      <Section>
        <BackGroundWhite>
          <HeadingText>関連教材</HeadingText>
          {isEducationLoading ? (
            <div style={{ padding: 16, textAlign: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {education.category === 'exam' && (
                <EducationContent
                  education={education}
                  isValid={true}
                  isShowComment={false}
                />
              )}
              {education.category === 'video' && (
                <VideoContent education={education} isShowComment={false} />
              )}
            </>
          )}
        </BackGroundWhite>
      </Section>
    </PageWrapper>
  )
}

export default AdminEducationCommentsChapterIndex
