import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const EducationIcon = () => {
  return (
    <SvgIcon>
      <path
        d="M19.7727 5.43182C18.8141 5.12955 17.7605 5 16.75 5C15.0659 5 13.2523 5.34545 12 6.29545C10.7477 5.34545 8.93409 5 7.25 5C5.56591 5 3.75227 5.34545 2.5 6.29545V19.6818C3.75227 18.7318 5.56591 18.3864 7.25 18.3864C8.93409 18.3864 10.7477 18.7318 12 19.6818C13.2523 18.7318 15.0659 18.3864 16.75 18.3864C17.7605 18.3864 18.8141 18.5159 19.7727 18.8182C20.4205 19.0341 20.9818 19.2932 21.5 19.6818V6.29545C20.9818 5.90682 20.4205 5.64773 19.7727 5.43182ZM19.7727 17.0909C18.8227 16.7886 17.7864 16.6591 16.75 16.6591C15.2818 16.6591 13.1659 17.2205 12 17.9545V8.02273C13.1659 7.28864 15.2818 6.72727 16.75 6.72727C17.7864 6.72727 18.8227 6.85682 19.7727 7.15909V17.0909Z"
        fill="#003333"
      />
      <path
        d="M16.7501 10.1817C17.5101 10.1817 18.2442 10.2594 18.9092 10.4062V9.0935C18.2269 8.96396 17.4928 8.88623 16.7501 8.88623C15.2819 8.88623 13.9519 9.13668 12.8637 9.60305V11.0367C13.8396 10.484 15.1955 10.1817 16.7501 10.1817Z"
        fill="#003333"
      />
      <path
        d="M12.8636 11.9004V13.334C13.8396 12.7813 15.1955 12.479 16.75 12.479C17.51 12.479 18.2441 12.5568 18.9091 12.7036V11.3909C18.2268 11.2613 17.4927 11.1836 16.75 11.1836C15.2818 11.1836 13.9518 11.4427 12.8636 11.9004Z"
        fill="#003333"
      />
      <path
        d="M16.75 13.4893C15.2818 13.4893 13.9518 13.7397 12.8636 14.2061V15.6397C13.8396 15.087 15.1955 14.7847 16.75 14.7847C17.51 14.7847 18.2441 14.8624 18.9091 15.0093V13.6965C18.2268 13.5583 17.4927 13.4893 16.75 13.4893Z"
        fill="#003333"
      />
    </SvgIcon>
  )
}
