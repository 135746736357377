import * as React from 'react'
import { MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import EducationAttendanceForm from 'components/organisms/education_attendances/Form'
import { getEducationAttendance } from 'components/apis/educationAttendances'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'

function AdminEducationAttendancesEdit() {
  const { user_education_id } = useParams()
  const { data: userEducation, isLoading } = useQuery(
    [
      `admin/education_attendances/${user_education_id}`,
      'education_attendances',
    ],
    () =>
      getEducationAttendance(parseInt(user_education_id)).then(
        (res) => res.data
      )
  )

  return (
    <PageWrapper>
      <Helmet>
        <title>
          {PAGE_TITLES.EDUCATIONS.ATTENDANCES.EDIT ?? APPLICATION_NAME}
        </title>
      </Helmet>
      <h1>{MENUS.educations.children.education_attendances.title}</h1>
      {isLoading ? (
        <CircularProgressBox />
      ) : (
        <EducationAttendanceForm data={userEducation} type="update" />
      )}
    </PageWrapper>
  )
}

export default AdminEducationAttendancesEdit
