import * as React from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import {
  BackGroundWhite,
  BackGroundPalePink,
} from 'components/atoms/BackGroundComponents'
import {
  HeadingText,
  CustomForm,
  CustomInput,
  ErrorMessage,
} from 'components/atoms/FormComponents'
import {
  AddButtonOutlined,
  DeleteButton,
} from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { NOTIFICATION_MODELS } from 'commons/notificationModels'
import { Box } from '@mui/material'
import { VALIDATION_MESSAGES } from 'commons/constants'
import { validateEmailInput } from 'commons/validation'
import { toastOnError, toastOnSuccess } from 'commons/toaster'
import { bulkUpdateContactAddresses } from 'components/apis/contactAddresses'
import { useErrorHandler } from 'react-error-boundary'
import { FlexBox } from 'components/atoms/BoxComponents'
import ProcessingModal from 'components/atoms/ProcessingModal'

function ContactAddressItem(props: { register; control; errors; model }) {
  const { register, control, errors, model } = props
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: `${model.value}`,
  })
  const snakeCase = (string) => {
    return string.replace(/([A-Z])/g, function ($1) {
      return '_' + $1.toLowerCase()
    })
  }
  const initContactAddress = {
    id: null,
    email: null,
    model: snakeCase(model.value),
  }

  const deleteContactAddress = (index) => {
    remove(index)
  }

  const addContactAddress = () => {
    append(initContactAddress, { shouldFocus: false })
  }

  return (
    <BackGroundWhite>
      <HeadingText>{model.label}</HeadingText>
      {fields.length > 0 && (
        <Box mb={2}>
          <BackGroundPalePink>
            メールアドレス
            {fields.map((_, index) => (
              <Box mb={2} key={index}>
                <Box>
                  <CustomInput
                    {...register(`${model.value}.${index}.email`, {
                      required: true,
                      validate: { format: validateEmailInput },
                    })}
                  />
                  <DeleteButton onClick={() => deleteContactAddress(index)}>
                    削除
                  </DeleteButton>
                </Box>
                {eval(`errors?.${model.value}?.[${index}]?.email.type`) ==
                  'format' && (
                  <ErrorMessage>{VALIDATION_MESSAGES.emailFormat}</ErrorMessage>
                )}
                {eval(`errors?.${model.value}?.[${index}]?.email.type`) ===
                  'required' && (
                  <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
                )}
              </Box>
            ))}
          </BackGroundPalePink>
        </Box>
      )}
      <AddButtonOutlined onClick={() => addContactAddress()}>
        通知先を追加
      </AddButtonOutlined>
    </BackGroundWhite>
  )
}

interface Props {
  data?: ContactAddresses
}

export default function ContactAddressesForm(props: Props) {
  const defaultValues = props.data
  const handleError = useErrorHandler()
  const [isProcessingModalShow, setIsProcessingModalShow] =
    React.useState(false)

  // フォームオブジェクト
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<ContactAddresses>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: defaultValues,
  })

  // フォームsubmit時のコールバック
  const onSubmit = (data) => {
    setIsProcessingModalShow(true)
    const params = { contact_addresses: [] }
    Object.keys(data).forEach((key) => {
      params.contact_addresses.push(...data[key])
    })

    bulkUpdateContactAddresses(params)
      .then(() => {
        toastOnSuccess('更新完了')
        location.reload()
      })
      .catch((e) => {
        switch (e.response.status) {
          case 422:
            toastOnError(e.response.data.errors)
            break
          default:
            handleError(e.response.status)
        }
      })
      .finally(() => setIsProcessingModalShow(false))
  }

  return (
    <>
      <CustomForm onSubmit={handleSubmit(onSubmit)}>
        {NOTIFICATION_MODELS.map((model, index) => (
          <Box mb={2} key={index}>
            <ContactAddressItem
              register={register}
              control={control}
              errors={errors}
              model={model}
            />
          </Box>
        ))}
        <FlexBox justifyContent={'flex-end'} sx={{ marginTop: '8px' }}>
          <ApplyButton pattern="block" type="submit">
            保存する
          </ApplyButton>
        </FlexBox>
      </CustomForm>
      <ProcessingModal isOpen={isProcessingModalShow} />
    </>
  )
}
