import * as React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomForm,
  HeadingText,
  CustomLabel,
  CustomSelect,
  ErrorMessage,
  WithRequiredBadge,
  CustomDateSelectOnlyYear,
} from 'components/atoms/FormComponents'
import {
  updateEducationComment,
  fetchEducationComment,
  fetchEducationResult,
} from 'components/apis/educationComments'
import { slackNotification } from 'commons/slackNotification'
import { UserContext } from 'providers/UserProvider'
import { toastOnError, toastOnSuccess } from 'commons/toaster'
import ProcessingModal from 'components/atoms/ProcessingModal'
import { useErrorHandler } from 'react-error-boundary'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { FlexBox } from 'components/atoms/BoxComponents'
import FormSubmitModal from 'components/atoms/FormSubmitModal'
import { styled } from '@mui/material'
import { fetchDriverList } from 'components/apis/drivers'
import { useNavigate } from 'react-router-dom'
import {
  EDUCATION_COMMENTS,
  VALIDATION_MESSAGES,
  DRIVER_COMMENT_VALIDATE_LENGTH_TEXT,
  DRIVER_COMMENT_PLACEHOLDER,
} from 'commons/constants'
import { Box } from '@mui/material'
import EducationContent from 'components/organisms/educations/EducationContent'
import VideoContent from 'components/organisms/educations/VideoContent'
import { textLengthWithoutNewline } from 'commons/functions'
import { TextLength } from 'components/atoms/TextLength'
import EducationCommentTextArea from 'components/atoms/EducationCommentTextArea'

interface Props {
  education: Education
}

function EducationCommentForm(props: Props) {
  const { education } = props
  const user = React.useContext(UserContext)
  const [isModalShow, setModalShow] = React.useState(false)
  const [isProcessingModalShow, setIsProcessingModalShow] =
    React.useState(false)
  const [isSelectedDriver, setIsSelectedDriver] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const handleError = useErrorHandler()
  const navigate = useNavigate()

  const Section = styled('div')({
    marginBottom: '1rem',
  })

  const {
    getValues,
    setValue,
    control,
    register,
    watch,
    trigger,
    formState: { errors },
  } = useForm<EducationComment>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      educationId: education.id,
      driverId: null,
      year: user.company.thisYear || new Date().getFullYear(),
    },
  })

  const mutation = useMutation({
    mutationFn: updateEducationComment,
    onError: (e: AxiosError<{ errors: string }>) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors)
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
  })

  const submit = async (callback: () => void) => {
    setModalShow(false)
    const hasNotError = await trigger(undefined, { shouldFocus: true })
    if (hasNotError) {
      callback()
    } else {
      toastOnError('入力内容に不備があります')
    }
  }

  const submitCallback = async () => {
    setIsProcessingModalShow(true)
    const data = structuredClone(getValues())
    mutation.mutate(data, {
      onSuccess: () => {
        scrollTo({ top: 0 })
        toastOnSuccess('保存しました')
      },
      onSettled: () => {
        setIsProcessingModalShow(false)
      },
    })
  }

  const checkDriverEducationAttendance = () => {
    fetchEducationResult({
      educationId: education.id,
      driverId: watch('driverId'),
      year: watch('year'),
    })
      .then((res) => {
        if (res.data.educationResult) {
          setIsSelectedDriver(true)
          setErrorMessage('')
          setEducationComment()
        } else {
          switch (education.category) {
            case 'exam':
              setErrorMessage(
                'WEBテストに合格するまでコメントの登録はできません。'
              )
              break
            case 'video':
              setErrorMessage(
                '動画を視聴完了するまでコメントの登録はできません。'
              )
              break
            case 'custom':
              setIsSelectedDriver(true)
          }
        }
      })
      .catch((e) => {
        switch (e.response.status) {
          case 422:
            toastOnError(e.response.data.errors.join(','))
            break
          default:
            handleError(e.response.status)
        }
      })
  }

  const resetDriver = () => {
    setValue('driverId', null)
    setIsSelectedDriver(false)
    setValue('driverComment', null)
    setValue('adminComment', null)
  }

  const setEducationComment = () => {
    fetchEducationComment({
      educationId: education.id,
      driverId: watch('driverId'),
      year: watch('year'),
    })
      .then((res) => {
        setValue('id', res.data.id)
        setValue('driverComment', res.data.driverComment)
        setValue('adminComment', res.data.adminComment)
      })
      .catch((e) => {
        handleError(e.response.status)
      })
  }

  const { data: drivers } = useQuery(
    [`education_comments/Form`, 'driver_list'],
    () =>
      fetchDriverList(true).then((res) => {
        // "ドライバー 共通アカウント"はcurrentDriverに値が取得されない
        // admin権限のユーザー場合も除外する
        const currentDriver = res.data.drivers?.find(
          (value: DriverSelectOption) => String(value.id) === String(user.id)
        )
        if (currentDriver) {
          setValue('driverId', user.id)
        }
        return Object.values(res.data.drivers).map(
          (value: DriverSelectOption) => {
            return { value: Number(value.id), label: value.fullName }
          }
        )
      }),
    {
      onError: (error: AxiosError) =>
        navigate(`/errors?status=${error.response.status}`),
      refetchOnWindowFocus: false,
    }
  )

  return (
    <>
      <h1>{education.title}</h1>
      <CustomForm>
        <Section>
          <BackGroundWhite>
            コメントを登録(または確認)したい年度と氏名を選択してください。
            <FlexBox flexWrap="wrap">
              <Box sx={{ marginRight: '8px' }}>
                <CustomDateSelectOnlyYear
                  formKey="year"
                  fiscalYearSelect
                  control={control}
                  required
                  defaultThisYear={false}
                  yearsFrom2000
                  isDisabled={isSelectedDriver}
                />
              </Box>
              <Controller
                name="driverId"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <CustomSelect
                    sx={{ marginRight: '16px' }}
                    options={drivers}
                    value={drivers?.find(
                      (c) => String(c.value) === String(value)
                    )}
                    onChange={(option: ReactSelectOptionProps) =>
                      onChange(option.value)
                    }
                    placeholder="選択してください"
                    isDisabled={isSelectedDriver}
                  />
                )}
              />
              {isSelectedDriver ? (
                <ApplyButton sx={{ display: 'block' }} onClick={resetDriver}>
                  取消
                </ApplyButton>
              ) : (
                <ApplyButton
                  sx={{ display: 'block' }}
                  disabled={!watch('driverId')}
                  onClick={checkDriverEducationAttendance}
                >
                  決定
                </ApplyButton>
              )}
            </FlexBox>
            {errorMessage.length > 0 && (
              <ErrorMessage>{errorMessage}</ErrorMessage>
            )}
          </BackGroundWhite>
        </Section>

        <Section>
          <BackGroundWhite>
            <CustomLabel>
              <WithRequiredBadge>
                {`${EDUCATION_COMMENTS.LABELS.DRIVER_COMMENT}(${DRIVER_COMMENT_VALIDATE_LENGTH_TEXT})`}
              </WithRequiredBadge>
              <Box sx={{ maxWidth: '370px' }}>
                <EducationCommentTextArea
                  register={register}
                  setValue={setValue}
                  name="driverComment"
                  required={true}
                  validate={(value: string) =>
                    textLengthWithoutNewline(value) >=
                      EDUCATION_COMMENTS.DRIVER_COMMENT_LENGTH_MIN &&
                    textLengthWithoutNewline(value) <=
                      EDUCATION_COMMENTS.DRIVER_COMMENT_LENGTH_MAX
                  }
                  placeholder={DRIVER_COMMENT_PLACEHOLDER}
                  disabled={!isSelectedDriver}
                />
                <FlexBox justifyContent="flex-end">
                  <TextLength control={control} name="driverComment" />
                </FlexBox>
                <Box>
                  {errors.driverComment?.type === 'required' && (
                    <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
                  )}
                  {errors.driverComment?.type === 'validate' && (
                    <ErrorMessage>
                      {`${DRIVER_COMMENT_VALIDATE_LENGTH_TEXT}で入力してください。`}
                    </ErrorMessage>
                  )}
                </Box>
              </Box>
            </CustomLabel>
            <CustomLabel>
              {EDUCATION_COMMENTS.LABELS.ADMIN_COMMENT}
              <EducationCommentTextArea
                register={register}
                setValue={setValue}
                name="adminComment"
                disabled={
                  !isSelectedDriver ||
                  user.id == watch('driverId') ||
                  !user.admin
                }
              />
              {isSelectedDriver &&
                user.admin &&
                user.id == watch('driverId') && (
                  <>
                    ご自身のコメントに対して管理者コメントを登録することはできません。
                  </>
                )}
            </CustomLabel>
            <ApplyButton
              sx={{ display: 'block', marginTop: '8px' }}
              disabled={!isSelectedDriver}
              onClick={(e) => {
                e.preventDefault()
                setModalShow(true)
              }}
            >
              保存する
            </ApplyButton>
          </BackGroundWhite>
        </Section>

        <Section>
          <BackGroundWhite>
            <HeadingText>関連教材</HeadingText>
            {education.category === 'exam' && (
              <EducationContent
                education={education}
                isValid={true}
                isShowComment={false}
              />
            )}
            {education.category === 'video' && (
              <VideoContent education={education} isShowComment={false} />
            )}
          </BackGroundWhite>
        </Section>
      </CustomForm>
      <FormSubmitModal
        isOpen={isModalShow}
        resourceName="受講コメント"
        handlers={{
          cancel: () => setModalShow(false),
          toShow: () => submit(() => submitCallback()),
        }}
      />
      <ProcessingModal isOpen={isProcessingModalShow} />
    </>
  )
}

export default EducationCommentForm
