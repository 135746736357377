// Educationを新規作成するページを実装してください。Formは別コンポーネントで定義するので、このページはラッパーとしての粒度で大丈夫です

import PageWrapper from 'components/organisms/layouts/PageWrapper'
import React from 'react'
import { EducationForm } from './components'
import { useEducation } from './hooks'
import { useParams } from 'react-router-dom'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'

const AdminEducationsEdit = () => {
  const { id } = useParams()
  const { education, isLoading } = useEducation(id)

  if (isLoading) {
    return <CircularProgressBox />
  } else {
    return (
      <PageWrapper>
        <EducationForm education={education} />
      </PageWrapper>
    )
  }
}

export default AdminEducationsEdit
