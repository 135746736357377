import * as React from 'react'
import {
  BackGroundPalePink,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import { styled } from '@mui/material'
import { palette, theme } from 'components/theme'
import { VEHICLES } from 'commons/constants'

type MemoProps = {
  data: Vehicle
}

export default function Memo(props: MemoProps) {
  const Title = styled('h2')({
    fontSize: '20px',
    margin: 0,
    marginBottom: '16px',
  })
  const Text = styled('p')({
    fontSize: '16px',
    margin: 0,
  })
  const TextDisabled = styled(Text)({
    color: palette.text.gray.pale,
  })
  const TextWrapper = styled('div')({
    width: '300px',
    overflowWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  })
  const TextWrapperFull = styled(TextWrapper)({
    width: '628px',
  })

  return (
    <BackGroundWhite>
      <Title>{VEHICLES.LABELS.MEMO}</Title>
      <BackGroundPalePink>
        <TextWrapperFull>
          {props.data.memo ? (
            <Text>{props.data.memo}</Text>
          ) : (
            <TextDisabled>記載がありません</TextDisabled>
          )}
        </TextWrapperFull>
      </BackGroundPalePink>
    </BackGroundWhite>
  )
}
