import * as React from 'react'
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormGetValues,
  useFieldArray,
} from 'react-hook-form'
import {
  BackGroundPalePink,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  ErrorMessage,
  ConvertibleCustomInput,
  ConvertibleCustomPhoneNumberSelect,
  ConvertibleCustomSelect,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import {
  DRIVERS,
  EMERGENCY_CONTACTS,
  VALIDATION_MESSAGES,
} from 'commons/constants'
import { Box, styled, useTheme } from '@mui/material'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { useNavigate } from 'react-router-dom'
import { palette } from 'components/theme'
import { PREFECTURES } from 'commons/prefectures'

type EmergencyContactsProps = {
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
  handleModeChange: (mapKey: DriverFormModeMapKey, mode: FormMode) => void
}

export default function EmergencyContacts(props: EmergencyContactsProps) {
  const navigate = useNavigate()
  const { fields } = useFieldArray({
    control: props.control,
    name: 'emergencyContacts',
  })

  const reload = () => {
    if (
      window.confirm('保存していないデータは削除されます。よろしいですか？')
    ) {
      navigate(0)
    }
  }

  return (
    <BackGroundWhite>
      <FlexBox justifyContent="space-between" marginBottom="16px">
        <HeadingText>{DRIVERS.LABELS.EMERGENCY_CONTACT}</HeadingText>
        <Box>
          {/* 一時的にコメントアウト */}
          {/* {props.mode == 'show' && (
            <EditButtonOutlined
              onClick={() => {
                props.handleModeChange('emergencyContacts', 'edit')
              }}
            >
              編集
            </EditButtonOutlined>
          )} */}
          {props.mode == 'edit' && (
            <FlexBox>
              <ApplyButton pattern="marginRight8" type="submit">
                保存する
              </ApplyButton>
              <CancelButtonOutlined onClick={reload}>
                キャンセル
              </CancelButtonOutlined>
            </FlexBox>
          )}
        </Box>
      </FlexBox>
      {fields.length == 0 && <p>データがありません</p>}
      {fields.map((_, i) => {
        return (
          <Box key={`emergency-contact-card-${i}`} mb={2}>
            <EmergenctContactInner
              index={i}
              mode={props.mode}
              control={props.control}
              register={props.register}
              errors={props.errors}
              getValues={props.getValues}
            ></EmergenctContactInner>
          </Box>
        )
      })}
    </BackGroundWhite>
  )
}

type EmergenctContactInnerProps = {
  index: number
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
}

const Title = styled('p')({
  fontWeight: 'bold',
  color: palette.text.gray.deep,
})

const EmergenctContactInner: React.FC<EmergenctContactInnerProps> = (props) => {
  const theme = useTheme()
  return (
    <BackGroundPalePink>
      <Title>
        {DRIVERS.LABELS.EMERGENCY_CONTACT}
        {props.index + 1}
      </Title>
      <FlexBox
        alignItems="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
          },
        }}
      >
        <CustomLabel sx={{ marginRight: '32px' }}>
          {EMERGENCY_CONTACTS.LABELS.LAST_NAME}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey={`emergencyContacts.${props.index}.lastName`}
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.emergencyContacts?.[props.index]?.lastName && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
        <CustomLabel sx={{ marginRight: '32px' }}>
          {EMERGENCY_CONTACTS.LABELS.FIRST_NAME}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey={`emergencyContacts.${props.index}.firstName`}
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.emergencyContacts?.[props.index]?.firstName && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
        <CustomLabel sx={{ marginRight: '32px' }}>
          {EMERGENCY_CONTACTS.LABELS.LAST_NAME_KANA}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey={`emergencyContacts.${props.index}.lastNameKana`}
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.emergencyContacts?.[props.index]?.lastNameKana && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
        <CustomLabel>
          {EMERGENCY_CONTACTS.LABELS.FIRST_NAME_KANA}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey={`emergencyContacts.${props.index}.firstNameKana`}
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.emergencyContacts?.[props.index]?.firstNameKana && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
      </FlexBox>
      <FlexBox
        alignItems="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
          },
        }}
      >
        <CustomLabel sx={{ marginRight: '32px' }}>
          {EMERGENCY_CONTACTS.LABELS.PHONE_NUMBER}
          <ConvertibleCustomPhoneNumberSelect
            mode={props.mode}
            formKeys={{
              first: `emergencyContacts.${props.index}.phoneNumberFirst`,
              second: `emergencyContacts.${props.index}.phoneNumberSecond`,
              third: `emergencyContacts.${props.index}.phoneNumberThird`,
            }}
            register={props.register}
            getValues={props.getValues}
          />
        </CustomLabel>
        <CustomLabel>
          {EMERGENCY_CONTACTS.LABELS.RELATIONSHIP}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey={`emergencyContacts.${props.index}.relationship`}
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.emergencyContacts?.[props.index]?.relationship && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
      </FlexBox>
      <FlexBox
        alignItems="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
          },
        }}
      >
        <CustomLabel sx={{ marginRight: '32px' }}>
          {EMERGENCY_CONTACTS.LABELS.ZIP_CODE}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey={`emergencyContacts.${props.index}.zipCode`}
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.emergencyContacts?.[props.index]?.zipCode && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
        <CustomLabel>
          {EMERGENCY_CONTACTS.LABELS.PREFECTURE}
          <ConvertibleCustomSelect
            mode={props.mode}
            formKey={`emergencyContacts.${props.index}.prefecture`}
            control={props.control}
            getValues={props.getValues}
            options={PREFECTURES}
          />
        </CustomLabel>
        <CustomLabel>
          {EMERGENCY_CONTACTS.LABELS.ADDRESS}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey={`emergencyContacts.${props.index}.address`}
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.emergencyContacts?.[props.index]?.address && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
      </FlexBox>
    </BackGroundPalePink>
  )
}
