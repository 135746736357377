import * as React from 'react'
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormRegister,
  UseFormWatch,
  UseFormSetValue,
  Controller,
} from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { AddButtonOutlined } from 'components/atoms/ButtonComponenets'
import { Box } from '@mui/material'
import {
  CustomDropZone,
  CustomLabel,
  HeadingText,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import MaintenanceCard from './MaintenanceCard'
import { VEHICLES } from 'commons/constants'

type MaintenanceProps = {
  register: UseFormRegister<Vehicle>
  control: Control<Vehicle>
  watch: UseFormWatch<Vehicle>
  setValue: UseFormSetValue<Vehicle>
  errors: FieldErrors<Vehicle>
  files: File[]
  setFiles: React.Dispatch<React.SetStateAction<File[]>>
}

const Maintenance: React.FC<MaintenanceProps> = (props) => {
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: 'maintenances',
  })

  // 画像設定時のコールバック
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      props.setFiles([...props.files, ...acceptedFiles])
    },
    [props.files]
  )

  const appendEmptyItem = () => {
    const emptyItem = {
      maintenanceYear: undefined,
      maintenanceMonth: undefined,
      maintenanceDay: undefined,
      title: '',
      number: '',
      totalFee: '',
      partsFee: '',
      technicalFee: '',
      maintenanceArea: '',
      companyName: '',
      mileage: '',
      memo: '',
      files: [],
    }
    append(emptyItem, { shouldFocus: false })
  }

  const deleteItem = (targetIndex: number) => {
    remove(targetIndex)
  }

  return (
    <BackGroundWhite>
      <HeadingText>{VEHICLES.LABELS.MAINTENANCE}</HeadingText>
      {fields.map((_, i) => {
        return (
          <Box mb={2} key={`maintenances-card-${i}`}>
            <MaintenanceCard
              register={props.register}
              errors={props.errors}
              control={props.control}
              watch={props.watch}
              setValue={props.setValue}
              appendEmptyItem={appendEmptyItem}
              deleteItem={deleteItem}
              index={i}
            />
          </Box>
        )
      })}
      <AddButtonOutlined onClick={appendEmptyItem}>追加</AddButtonOutlined>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.FILE_UPLOAD}({VEHICLES.LABELS.MAINTENANCE}の
        {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_PARENT})
        <Controller
          render={() => (
            <CustomDropZone
              multiple={true}
              onDrop={onDrop}
              accept={'image/*, application/pdf'}
            />
          )}
          name={`maintenancesFileUrls`}
          control={props.control}
        />
        {Object.values(props?.files)?.map((image: File, index: number) => (
          <FlexBox flexDirection={'row'} key={index}>
            <p>
              <a
                href={URL.createObjectURL(image)}
                download={decodeURI(image.name)}
              >
                {decodeURI(image.name)}
              </a>
            </p>
            <IconButton
              component="span"
              onClick={() =>
                props.setFiles(props.files.filter((_, i) => i !== index))
              }
            >
              <CancelIcon />
            </IconButton>
          </FlexBox>
        ))}
      </CustomLabel>
    </BackGroundWhite>
  )
}

export default Maintenance
