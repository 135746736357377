import * as React from 'react'
import { styled } from '@mui/styles'
import { Box, BoxProps } from '@mui/material'

export function FlexBox(props: BoxProps) {
  const FlexBoxElement = styled(Box)({
    display: 'flex',
  })
  return <FlexBoxElement {...props}>{props.children}</FlexBoxElement>
}

export function FlexBoxRow(props: BoxProps) {
  const FlexBoxRowElement = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'start',
  })
  return <FlexBoxRowElement {...props}>{props.children}</FlexBoxRowElement>
}

export function FlexBoxColumn(props: BoxProps) {
  const FlexBoxColumnElement = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
  })
  return (
    <FlexBoxColumnElement {...props}>{props.children}</FlexBoxColumnElement>
  )
}

export function BoxBorderBottom(props: BoxProps) {
  const BoxBorderBottomElement = styled(Box)({
    borderBottom: 'solid',
    width: 'fit-content',
  })
  return (
    <BoxBorderBottomElement {...props}>{props.children}</BoxBorderBottomElement>
  )
}

export function MarginBox(props: BoxProps) {
  const MarginBoxElement = styled(Box)({
    margin: '1rem',
  })
  return <MarginBoxElement {...props}>{props.children}</MarginBoxElement>
}
