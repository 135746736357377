import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const DriverIcon = () => {
  return (
    <SvgIcon>
      <path
        d="M15.6364 11.4545C17.1455 11.4545 18.3545 10.2364 18.3545 8.72727C18.3545 7.21818 17.1455 6 15.6364 6C14.1273 6 12.9091 7.21818 12.9091 8.72727C12.9091 10.2364 14.1273 11.4545 15.6364 11.4545ZM8.36364 11.4545C9.87273 11.4545 11.0818 10.2364 11.0818 8.72727C11.0818 7.21818 9.87273 6 8.36364 6C6.85455 6 5.63636 7.21818 5.63636 8.72727C5.63636 10.2364 6.85455 11.4545 8.36364 11.4545ZM8.36364 13.2727C6.24545 13.2727 2 14.3364 2 16.4545V18.7273H14.7273V16.4545C14.7273 14.3364 10.4818 13.2727 8.36364 13.2727ZM15.6364 13.2727C15.3727 13.2727 15.0727 13.2909 14.7545 13.3182C15.8091 14.0818 16.5455 15.1091 16.5455 16.4545V18.7273H22V16.4545C22 14.3364 17.7545 13.2727 15.6364 13.2727Z"
        fill="#003333"
      />
    </SvgIcon>
  )
}
