import * as React from 'react'
import {
  styled,
  Box,
  Table,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Toolbar,
  Tooltip,
  IconButton,
  Paper,
  TableBody,
  Typography,
  MenuItem,
  Pagination,
  Select,
  CircularProgress,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import { visuallyHidden } from '@mui/utils'
import { TABLE_ROWS_PER_PAGE, LOCAL_STORAGE_PAGE_KEY } from 'commons/constants'
import { convertToStringYen } from 'commons/functions'
import { palette } from 'components/theme'
import { JapaneseDateFormat } from 'commons/dateFormat'
import { setLocalStorageOrder } from 'commons/table'
import {
  setLocalStorageDisplayLimit,
  setLocalStoragePageNumber,
  removeLocalStoragePageNumber,
} from 'commons/table'
import { CellType, Order, StatefulText, sortBy } from 'commons/array'
import { useNavigate } from 'react-router-dom'
const StatefulTextStyle = {
  error: {
    color: 'rgba(234, 51, 35, 1.0)',
  },
}
export interface HeadCell<T> {
  id: keyof T
  label: string
  type: CellType
}

type Props<T> = {
  order: Order
  setOrder: React.Dispatch<React.SetStateAction<Order>>
  orderBy: keyof T
  setOrderBy: React.Dispatch<React.SetStateAction<keyof T>>
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  rows: T[]
  rowsPerPage: number
  totalCount: number
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>
  headCells: readonly HeadCell<T>[]
  children?: React.ReactNode | string
  searchForm?: React.ReactNode
  additionalContent?: React.ReactNode
  paginateParams: PaginateParams
  setPaginateParams: React.Dispatch<
    React.SetStateAction<Record<string, unknown>>
  >
  disabledClick?: boolean
  localStoragePageKey?: string
  basePath: string
  focusId?: number
  isLoading?: boolean
  isNewVersion?: boolean
}

type HeaderProps<T> = {
  order: Order
  orderBy: keyof T
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void
  headCells: readonly HeadCell<T>[]
}

export function RecordNotFound() {
  const RecordNotFoundElement = styled('p')({
    margin: 0,
    padding: '16px',
    textAlign: 'center',
  })
  return (
    <RecordNotFoundElement>
      検索条件に一致するデータは見つかりませんでした。
    </RecordNotFoundElement>
  )
}

export const EnhancedTableToolbar = (props: {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  title?: string
}) => {
  const { isOpen, setIsOpen, title } = props
  const onClickHandler = () => {
    setIsOpen(!isOpen)
  }
  const defaultTitle = '検索フィルタ'

  return (
    <Toolbar>
      <Tooltip title={title || defaultTitle}>
        <IconButton onClick={onClickHandler}>
          <FilterListIcon />{' '}
          <Typography
            sx={{
              color: palette.text.black.main,
              fontWeight: 'bold',
              marginLeft: '1rem',
            }}
          >
            {title || defaultTitle}
          </Typography>
        </IconButton>
      </Tooltip>
    </Toolbar>
  )
}

function StatusCell(props: { children?: React.ReactNode | number | string }) {
  const StatusCellElement = styled(TableCell)({
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    '& p': {
      color: palette.text.gray.pale,
      border: `1px solid ${palette.text.gray.pale}`,
      width: '100%',
    },
  })
  return (
    <StatusCellElement>
      <p>{props.children}</p>
    </StatusCellElement>
  )
}

export function IdHeader(props: {
  children?: React.ReactNode
  color?: string
}) {
  const IdHeaderElement = styled(TableCell)({
    position: 'sticky',
    left: 0,
    backgroundColor: props.color || 'white',
    zIndex: '10',
    width: '20px',
  })
  return <IdHeaderElement>{props.children}</IdHeaderElement>
}

function StatefulCell({ statefulText }: { statefulText: StatefulText }) {
  return (
    <>
      {statefulText.state === 'none' && (
        <TableCell>{statefulText.text}</TableCell>
      )}
      {statefulText.state === 'error' && (
        <TableCell style={StatefulTextStyle.error}>
          {statefulText.text}
        </TableCell>
      )}
    </>
  )
}

function CustomCell(props: {
  type: CellType
  children?: React.ReactNode | string | StatefulText
}) {
  const { type } = props
  switch (type) {
    case 'string': {
      if (typeof props.children === 'object') {
        if ((props.children as StatefulText)?.state) {
          return <StatefulCell statefulText={props.children as StatefulText} />
        }
      }
      return <TableCell>{props.children}</TableCell>
    }
    case 'money': {
      const value = isNaN(parseInt(props.children as string))
        ? ''
        : convertToStringYen(props.children as number)
      return <TableCell>{value}</TableCell>
    }
    case 'status': {
      return <StatusCell>{props.children}</StatusCell>
    }
    case 'date': {
      return (
        <TableCell>{JapaneseDateFormat(props.children as string)}</TableCell>
      )
    }
    default:
      return <TableCell>{props.children}</TableCell>
  }
}

export function EnhancedTableHead<T>(props: HeaderProps<T>) {
  const { order, orderBy, onRequestSort } = props
  const headerColor = palette.background.tableHeaderCell.pale
  const createSortHandler =
    (property) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead sx={{ backgroundColor: headerColor }}>
      <TableRow>
        <IdHeader color={headerColor}>No</IdHeader>
        {props.headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export function SortableTableBody<T>(props: Props<T>) {
  const {
    order,
    orderBy,
    page,
    rows,
    rowsPerPage,
    headCells,
    disabledClick,
    basePath,
    focusId,
    isNewVersion,
  } = props
  const navigate = useNavigate()

  const navigateTo = (id: string) => {
    let path = basePath
    if (!path.startsWith('/')) {
      path = `/${path}`
    }
    if (!path.endsWith('/')) {
      path = `${path}/`
    }
    if (id) {
      path = `${path}${id}/`
    }
    navigate(path)
  }

  // muiのTablePaginationとPaginationのスタートページ番号が異なるため、新バージョンの場合はページ番号を1から始める
  const pageNum = isNewVersion ? page - 1 : page

  return (
    <TableBody sx={disabledClick && { pointerEvents: 'none' }}>
      {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
      {sortBy(rows, order, orderBy).map((row, index) => {
        const number = pageNum * rowsPerPage + index + 1
        const color =
          row['id'] && row['id'] === focusId ? palette.background.grey : 'white'
        return (
          <TableRow
            hover={row['id'] && true}
            tabIndex={-1}
            key={index}
            sx={{ cursor: 'pointer', backgroundColor: color }}
            onClick={() => {
              if (row['id']) {
                navigateTo(row['id'])
              }
            }}
          >
            <IdHeader>{number}</IdHeader>
            {headCells.map((cell, index) => {
              return (
                <CustomCell key={index} type={cell.type}>
                  {row[cell.id]}
                </CustomCell>
              )
            })}
          </TableRow>
        )
      })}
    </TableBody>
  )
}

export default function EnhancedTable<T>(props: Props<T>) {
  const {
    setOrder,
    orderBy,
    setOrderBy,
    page,
    setPage,
    rows,
    rowsPerPage,
    setRowsPerPage,
    headCells,
    totalCount,
    paginateParams,
    setPaginateParams,
    localStoragePageKey,
    isLoading,
    isNewVersion,
  } = props
  const [isOpen, setIsOpen] = React.useState(true)

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    const isAsc = orderBy === property && props.order === 'asc'
    const order = isAsc ? 'desc' : 'asc'
    setOrder(order)
    setOrderBy(property)

    // localStorageの値を更新
    setLocalStorageOrder(localStoragePageKey, order, String(property))
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
    setPaginateParams({
      ...paginateParams,
      pageNumber: isNewVersion ? newPage : newPage + 1,
    })
    setLocalStoragePageNumber(localStoragePageKey, newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const count = parseInt(event.target.value, 10)
    const firstPage = isNewVersion ? 1 : 0

    setRowsPerPage(count)
    setPaginateParams({
      pageNumber: isNewVersion ? firstPage : firstPage + 1,
      displayLimit: count,
    })
    setPage(firstPage)
    setLocalStorageDisplayLimit(count)
    Object.values(LOCAL_STORAGE_PAGE_KEY).forEach((key) => {
      removeLocalStoragePageNumber(key)
    })
  }

  const Pagination = () => (
    <TablePagination
      rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
      component="div"
      count={totalCount}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )

  const customPagination = {
    rowsPerPage: rowsPerPage,
    handleChangeRowsPerPage: handleChangeRowsPerPage,
    totalCount: totalCount,
    page: page,
    handleChangePage: handleChangePage,
  }

  return (
    <Box>
      {isNewVersion ? (
        <StyledPaper>
          <EnhancedTableToolbar isOpen={isOpen} setIsOpen={setIsOpen} />
          {isOpen && props.searchForm}
          {props.additionalContent}
          {isLoading ? (
            <div style={{ padding: 16, textAlign: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <CustomPagination {...customPagination} />
              {rows.length == 0 ? (
                <RecordNotFound />
              ) : (
                <TableContainer sx={{ overflowX: 'scroll' }}>
                  <Table sx={{ whiteSpace: 'nowrap' }}>
                    <EnhancedTableHead
                      order={props.order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      headCells={headCells}
                    />
                    <SortableTableBody<T> {...props}>
                      {props.children}
                    </SortableTableBody>
                  </Table>
                </TableContainer>
              )}
              <CustomPagination {...customPagination} />
            </>
          )}
        </StyledPaper>
      ) : (
        // TODO: 全て切り替えできた時点で、古いバージョンを削除する
        <Paper>
          <EnhancedTableToolbar isOpen={isOpen} setIsOpen={setIsOpen} />
          {isOpen && props.searchForm}
          {props.additionalContent}
          <Pagination />
          {rows.length == 0 ? (
            <RecordNotFound />
          ) : (
            <TableContainer sx={{ overflowX: 'scroll' }}>
              <Table sx={{ whiteSpace: 'nowrap' }}>
                <EnhancedTableHead
                  order={props.order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  headCells={headCells}
                />
                <SortableTableBody<T> {...props}>
                  {props.children}
                </SortableTableBody>
              </Table>
            </TableContainer>
          )}
          <Pagination />
        </Paper>
      )}
    </Box>
  )
}

const StyledPaper = styled(Paper)({
  marginBottom: '12px',
})

const StyledSelect = styled(Select)({
  marginRight: '32px',
  fontSize: '0.875rem',
  width: '80px',
  height: '2em',
  fieldset: {
    border: 'none',
  },
})

type CustomPaginationProps = {
  rowsPerPage: number
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
  totalCount: number
  page: number
  handleChangePage: (event: React.ChangeEvent<unknown>, value: number) => void
}

export const CustomPagination = (props: CustomPaginationProps) => {
  const {
    rowsPerPage,
    handleChangeRowsPerPage,
    totalCount,
    page,
    handleChangePage,
  } = props

  if (totalCount === 0) {
    return <></>
  }

  const pageCount = Math.ceil(totalCount / rowsPerPage)
  const startCount = page * rowsPerPage - rowsPerPage + 1
  const endCount =
    page * rowsPerPage > totalCount ? totalCount : page * rowsPerPage

  return (
    <CustomPaginationWrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: { sm: 'flex-end', xs: 'space-between' },
          alignItems: 'center',
          fontSize: '0.875rem',
          minHeight: '52px',
          paddingLeft: '12px',
          paddingRight: '12px',
        }}
      >
        <div
          style={{
            flexShrink: 0,
          }}
        >
          <span>ページあたりの行数:</span>
          <StyledSelect value={rowsPerPage} onChange={handleChangeRowsPerPage}>
            {TABLE_ROWS_PER_PAGE.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </StyledSelect>
        </div>
        <div
          style={{
            flexShrink: 0,
          }}
        >
          <span>
            {startCount}〜{endCount} / {totalCount}
          </span>
        </div>
        <Pagination
          sx={{
            flexShrink: 0,
          }}
          count={pageCount}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
    </CustomPaginationWrapper>
  )
}

const CustomPaginationWrapper = styled(Box)({
  overflow: 'auto',
})
