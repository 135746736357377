import * as React from 'react'
import { fetchS3Images } from 'commons/fetchS3Images'
import Modal from '@mui/material/Modal'
import { Box } from '@mui/material'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

type Props = {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  education: Education
}

export default function CustomEducationDocumentModal({
  isModalOpen,
  setIsModalOpen,
  education,
}: Props) {
  const [fileObjects, setFileObjects] = React.useState<File[]>([])

  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3Images(education.fileUrls)
      setFileObjects(files)
    })()
  }, [education.fileUrls])

  return (
    <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <Box sx={style}>
        {Object.values(fileObjects)?.map((image: File, index: number) => (
          <p key={index}>
            <a
              href={URL.createObjectURL(image)}
              download={decodeURI(image.name)}
            >
              {decodeURI(image.name)}
            </a>
          </p>
        ))}
      </Box>
    </Modal>
  )
}
