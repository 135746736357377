import * as React from 'react'
import CustomCenteringModal from 'components/atoms/CustomCenteringModal'
import Webcam from 'react-webcam'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { CameraButton } from 'components/atoms/buttons/CameraButton'
import { UseFormSetValue } from 'react-hook-form'
import { base64ToFile } from 'commons/functions'
import { toFormattedDateTime } from 'commons/dateFormat'

type Props = {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  title: string
  setPhoto: React.Dispatch<React.SetStateAction<File>>
  setValue: UseFormSetValue<AlcoholCheck>
  createdAtKey: keyof AlcoholCheck
}

function CameraModal({
  isModalOpen,
  setIsModalOpen,
  title,
  setPhoto,
  setValue,
  createdAtKey,
}: Props) {
  const webcamRef = React.useRef(null)

  const capture = React.useCallback(() => {
    const imageSrc = base64ToFile(
      webcamRef.current.getScreenshot(),
      toFormattedDateTime(new Date()),
      'image/jpeg'
    )
    setPhoto(imageSrc)
    setValue(createdAtKey, new Date(imageSrc.lastModified))
  }, [webcamRef])

  return (
    <CustomCenteringModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      customClose={() => false}
    >
      <h2>{title}</h2>
      <Webcam
        audio={false}
        ref={webcamRef}
        width={'100%'}
        height={450}
        screenshotFormat="image/jpeg"
        videoConstraints={{ facingMode: 'user' }} // フロントカメラを使用
      />
      <div style={{ margin: '10px 0' }}>
        <CameraButton sx={{ width: '130px' }} onClick={capture}>
          撮影
        </CameraButton>
      </div>
      <CancelButtonOutlined onClick={() => setIsModalOpen(false)}>
        閉じる
      </CancelButtonOutlined>
    </CustomCenteringModal>
  )
}

export { CameraModal }
