import * as React from 'react'
import { Checkbox, FormControlLabel, IconButton, Grid } from '@mui/material'
import GradingIcon from '@mui/icons-material/Grading'
import BootstrapDialog from 'components/atoms/BootstrapDialog'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'

type Props = {
  theadFilter?: TheadItem[]
  handleChangeHeaderColumns?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void
}

export function SelectApprovalsThead(props: Props) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <IconButton
        color="primary"
        aria-label="列表示設定"
        onClick={() => setOpen(true)}
        size="small"
      >
        <GradingIcon fontSize="small" />
        列表示設定
      </IconButton>
      {open && (
        <BootstrapDialog
          title="表示する列を選択してください。"
          changeDialogStatus={() => setOpen(false)}
        >
          <Grid container spacing={2}>
            {props.theadFilter.map((item, index) => {
              return (
                <Grid item xs={6} key={index}>
                  <FormControlLabel
                    value={item.title}
                    control={
                      <Checkbox
                        onChange={props.handleChangeHeaderColumns}
                        checked={item.visible}
                        disabled={item.readOnly}
                        name={item.name}
                      />
                    }
                    label={item.title as string}
                  />
                </Grid>
              )
            })}
          </Grid>
          <div style={{ textAlign: 'right' }}>
            <ApplyButton onClick={() => setOpen(false)}>閉じる</ApplyButton>
          </div>
        </BootstrapDialog>
      )}
    </>
  )
}
