import React, { useState } from 'react'
import { Modal, Box, IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { Button } from '@mui/material'

interface ImagePreviewProps {
  image: File | string
  alt?: string
  deletable?: boolean // 削除可能かどうかを示すフラグ
  onDelete?: () => void // 削除ボタンがクリックされたときに呼び出される関数
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  maxHeight: '80%',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

export const ImagePreview: React.FC<ImagePreviewProps> = ({
  image,
  alt = '',
  deletable = false,
  onDelete,
}) => {
  const [open, setOpen] = useState(false)
  const imageUrl =
    typeof image === 'string' ? image : URL.createObjectURL(image)

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    if (typeof image !== 'string') {
      URL.revokeObjectURL(imageUrl)
    }
  }

  return (
    <>
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <img
          src={imageUrl}
          alt={alt}
          style={{ width: '100px', height: 'auto', cursor: 'pointer' }} // サムネイルとして表示
          onClick={handleOpen}
        />
        {deletable && onDelete && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation() // モーダルを開くイベントの伝播を止める
              onDelete()
            }}
            size="small"
            style={{ position: 'absolute', top: 0, right: 0, color: 'red' }}
          >
            <CancelIcon />
          </IconButton>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="original-image-modal"
        aria-describedby="original-size-image-preview"
      >
        <Box sx={style}>
          <img
            src={imageUrl}
            alt={alt}
            style={{ width: 'auto', maxHeight: '100%' }}
          />
          {deletable && onDelete && (
            <Button
              startIcon={<CancelIcon />}
              onClick={(e) => {
                e.stopPropagation() // モーダルを閉じるイベントの伝播を止める
                onDelete()
              }}
              color="error"
              variant="contained"
              sx={{ mt: 2 }}
            >
              この画像を削除する
            </Button>
          )}
        </Box>
      </Modal>
    </>
  )
}
