import { Box, CircularProgress } from '@mui/material'
import { styled } from '@mui/styles'
import * as React from 'react'

export function Loading() {
  const Loading = styled('div')({
    backgroundColor: '#F3F3F4',
    opacity: '0.5',
    position: 'fixed',
    zIndex: '1000',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
  })
  return (
    <Loading>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    </Loading>
  )
}
