import * as React from 'react'
import EnhancedTable, { HeadCell } from 'components/atoms/TableComponents'
import SearchForm from './SearchForm'
import { LOCAL_STORAGE_PAGE_KEY, ROUTES } from 'commons/constants'
import {
  getLocalStorageDisplayLimit,
  getLocalStoragePageNumber,
  getLocalStorageOrder,
  isOrder,
} from 'commons/table'
import { Order } from 'commons/array'

const headCells: readonly HeadCell<Accident>[] = [
  {
    id: 'date',
    label: '発生日',
    type: 'string',
  },
  {
    id: 'driverName',
    label: 'ドライバー',
    type: 'string',
  },
  {
    id: 'vehicleNumber',
    label: '管理番号/車両ナンバー',
    type: 'string',
  },
  {
    id: 'content',
    label: '事故内容',
    type: 'string',
  },
  {
    id: 'damagedObjectText',
    label: '対人/対物',
    type: 'string',
  },
  {
    id: 'responsibilityText',
    label: '加害/被害',
    type: 'string',
  },
  {
    id: 'damageAmount',
    label: '損害金額',
    type: 'money',
  },
  {
    id: 'statusText',
    label: '事故対応',
    type: 'status',
  },
]

export default function AccidentsTable(props: {
  data: Accident[]
  totalCount: number
  paginateParams: PaginateParams
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setSearchParams: React.Dispatch<React.SetStateAction<Record<string, unknown>>>
  searchParams: SearchAccident
  defaultSearchParams: SearchAccident
  focusId?: number
  isLoading?: boolean
}) {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Accident>('date')
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(
    props.paginateParams.displayLimit
  )
  const rows = props.data
  const totalCount = props.totalCount
  const isOrderBy = (arg): arg is keyof Accident => {
    const headCellItems = headCells.map((h) => {
      return h.id
    })
    return headCellItems.includes(arg)
  }
  React.useEffect(() => {
    // ソート条件をlocalStorageから取得してset
    const { order, orderBy } = getLocalStorageOrder(
      LOCAL_STORAGE_PAGE_KEY.ACCIDENTS
    )
    if (isOrder(order) && isOrderBy(orderBy)) {
      setOrder(order)
      setOrderBy(orderBy)
    }
    //「ページあたりの行数」をloalStorageから取得してセット
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = getLocalStoragePageNumber(
      LOCAL_STORAGE_PAGE_KEY.ACCIDENTS,
      true
    )
    const newPaginateParams = {
      pageNumber: pageNumber,
      displayLimit: displayLimit,
    }
    props.setPaginateParams(newPaginateParams)
    setRowsPerPage(displayLimit)
    setPage(pageNumber)
  }, [])
  return (
    <EnhancedTable<Accident>
      {...props}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      page={page}
      setPage={setPage}
      rows={rows}
      rowsPerPage={rowsPerPage}
      totalCount={totalCount}
      setRowsPerPage={setRowsPerPage}
      headCells={headCells}
      localStoragePageKey={LOCAL_STORAGE_PAGE_KEY.ACCIDENTS}
      basePath={ROUTES.ACCIDENTS_INDEX}
      isNewVersion
      searchForm={
        <SearchForm
          setSearchParams={props.setSearchParams}
          setPaginateParams={props.setPaginateParams}
          setPage={setPage}
          searchParams={props.searchParams}
          defaultSearchParams={props.defaultSearchParams}
          isLoading={props.isLoading}
        />
      }
    />
  )
}
