import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { addParamPrefix, camelCaseKeysToUnderscore } from 'commons/functions'

export function fetchArticles(
  searchParams?: SearchArticle,
  paginateParams?: PaginateParams
) {
  const customSearchParams = searchParams
    ? addParamPrefix(searchParams, 'search')
    : {}
  const customPaginateParams = paginateParams
    ? addParamPrefix(paginateParams, 'pagination')
    : {}
  const params = camelCaseKeysToUnderscore({
    ...customSearchParams,
    ...customPaginateParams,
  })
  return axios.get(API_ENDPOINTS.ARTICLES, {
    params: params,
  })
}

export function fetchMaintenanceArticles(
  searchParams?: SearchMaintenanceArticle,
  paginateParams?: PaginateParams
) {
  const customSearchParams = searchParams
    ? addParamPrefix(searchParams, 'search')
    : {}
  const customPaginateParams = paginateParams
    ? addParamPrefix(paginateParams, 'pagination')
    : {}
  const params = camelCaseKeysToUnderscore({
    ...customSearchParams,
    ...customPaginateParams,
    maintenance: true,
  })
  return axios.get(API_ENDPOINTS.ARTICLES, {
    params: params,
  })
}

export function getArticle(id: number) {
  return axios.get([API_ENDPOINTS.ARTICLES, id].join(''))
}

export function deleteArticle(id: number) {
  return axios.delete([API_ENDPOINTS.ARTICLES, id].join(''))
}

export function createArticle(params: { data: Article; formData: FormData }) {
  const headers = { 'Content-Type': 'multipart/form-data' }
  return axios.post(API_ENDPOINTS.ARTICLES, params.formData, {
    headers: headers,
    params: camelCaseKeysToUnderscore(params.data),
  })
}

export function updateArticle(params: { data: Article; formData: FormData }) {
  const headers = { 'Content-Type': 'multipart/form-data' }
  return axios.patch(
    [API_ENDPOINTS.ARTICLES, params.data.id].join(''),
    params.formData,
    {
      headers: headers,
      params: camelCaseKeysToUnderscore(params.data),
    }
  )
}

export function getVideoConversionStatuses(id: number) {
  return axios.get(
    [API_ENDPOINTS.ARTICLES, id, 'video_conversion_statuses'].join('/')
  )
}
