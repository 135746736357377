import * as React from 'react'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { fetchEducationAttendancesWithinAYear } from 'components/apis/educationAttendances'
import { useQuery } from '@tanstack/react-query'

function EducationList() {
  const { data: educationAttendances, isLoading } = useQuery(
    [`education_attendances/educationList`, 'educationAttendances'],
    () =>
      fetchEducationAttendancesWithinAYear().then((res) => {
        return res.data.educationAttendances
      })
  )

  return isLoading ? (
    <CircularProgressBox />
  ) : (
    <ul>
      {educationAttendances.map((item) => (
        <li key={item.id}>
          <b>
            {item.chapter}. {item.title}
          </b>
          <ul>
            <li>
              正解数：{item.questionCount}問中{item.correctCount}問{' '}
            </li>
            <li>受講日：{item.attendanceDatetime}</li>
            {item.resultText ? <li>合否：{item.resultText}</li> : ''}
          </ul>
        </li>
      ))}
    </ul>
  )
}

export default EducationList
