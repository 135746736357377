import * as React from 'react'
import { theme } from 'components/theme'
import { CustomLabel } from 'components/atoms/FormComponents'

// 項目グルーピング形式の詳細画面用CustomLabel
export const DetailLabelContainer: React.FC<{
  children?: React.ReactNode | string
}> = (props) => {
  return (
    <CustomLabel
      sx={{
        marginRight: '32px',
        [theme.breakpoints.down('md')]: {
          marginRight: '0',
        },
      }}
    >
      {props.children}
    </CustomLabel>
  )
}
