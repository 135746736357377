import * as React from 'react'
import {
  CustomLabel,
  CustomInputShort,
  CustomDateSelect,
  CustomTextArea,
  CustomInput,
  CustomCheckBoxForm,
  WithDescriptionTooltipIcon,
  CustomDropZone,
} from 'components/atoms/FormComponents'
import {
  Control,
  FieldErrors,
  UseFormRegister,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { BackGroundPalePink } from 'components/atoms/BackGroundComponents'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { styled } from '@mui/styles'
import { FlexBox, FlexBoxRow } from 'components/atoms/BoxComponents'
import { LEASE_OPTIONS } from 'commons/leaseOptions'
import { VEHICLES, TOOLTIP_TEXTS } from 'commons/constants'
import { theme } from 'components/theme'
import NumberFormat from 'components/organisms/NumberFormat'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

type leaseCardProps = {
  register: UseFormRegister<Vehicle>
  control: Control<Vehicle>
  setValue: UseFormSetValue<Vehicle>
  watch: UseFormWatch<Vehicle>
  errors?: FieldErrors<Vehicle>
  appendEmptyItem: () => void
  deleteItem: (index: number) => void
  index: number
}

const leaseCard: React.FC<leaseCardProps> = (props) => {
  const { register, control, setValue, watch, index } = props
  const CardTitle = styled('p')({
    fontWeight: 'bold',
  })

  const CustomCheckBoxFormLong = styled(CustomCheckBoxForm)({
    width: '250px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  })

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const old_files = watch(`leases.${index}.files`)
        ? watch(`leases.${index}.files`)
        : []
      setValue(`leases.${index}.files`, [...old_files, ...acceptedFiles])
    },
    [watch(`leases.${index}.files`)]
  )

  return (
    <BackGroundPalePink sx={{ marginBottom: '30px' }}>
      <CardTitle>
        {VEHICLES.LABELS.LEASE}
        {index + 1}
      </CardTitle>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            width: '100%',
          },
        }}
      >
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.LEASE_CONTRACT_ID}
          <FlexBox alignItems="center">
            <CustomInputShort {...register(`leases.${index}.contractId`)} />
          </FlexBox>
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.LEASE_CONTRACT_NUMBER}
          <FlexBox alignItems="center">
            <CustomInputShort {...register(`leases.${index}.contractNumber`)} />
          </FlexBox>
        </CustomLabel>
      </FlexBox>
      <FlexBox>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.LEASE_CONTRACT_OPTION}
          <FlexBoxRow
            sx={{
              flexWrap: 'wrap',
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                width: '100%',
              },
            }}
          >
            {LEASE_OPTIONS.map((lease, options_index) => (
              <React.Fragment key={options_index}>
                <Controller
                  name={`leases.${index}.${lease.value}`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomCheckBoxFormLong>
                      <label>
                        <input
                          type="checkbox"
                          checked={value}
                          onChange={() => onChange(!value)}
                        />
                        {lease.label}
                      </label>
                    </CustomCheckBoxFormLong>
                  )}
                />
              </React.Fragment>
            ))}
          </FlexBoxRow>
        </CustomLabel>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            width: '100%',
          },
        }}
      >
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.LEASE_TOTAL_FEE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`leases.${index}.totalFee`}
            control={control}
          />
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.LEASE_MONTHLY_FEE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`leases.${index}.monthlyFee`}
            control={control}
          />
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.LEASE_DEPOSITE_FEE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`leases.${index}.depositFee`}
            control={control}
          />
        </CustomLabel>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            width: '100%',
          },
        }}
      >
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.LEASE_RESIDUAL_VALUE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`leases.${index}.residualValue`}
            control={control}
          />
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.LEASE_CANCEL_PENALTY}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`leases.${index}.cancelPenalty`}
            control={control}
          />
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.LEASE_MONTHLY_DEGRESSION}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`leases.${index}.monthlyDegression`}
            control={control}
          />
        </CustomLabel>
      </FlexBox>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.LEASE_START_DATE}
        <CustomDateSelect
          formKeys={{
            year: `leases.${index}.startYear`,
            month: `leases.${index}.startMonth`,
            day: `leases.${index}.startDay`,
          }}
          control={control}
        />
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.LEASE_END_DATE}
        <FlexBox alignItems="center">
          <CustomDateSelect
            formKeys={{
              year: `leases.${index}.endYear`,
              month: `leases.${index}.endMonth`,
              day: `leases.${index}.endDay`,
            }}
            control={control}
          />
          <WithDescriptionTooltipIcon
            text={`${TOOLTIP_TEXTS.NOTIFICATION_AUTO_CREATE}${TOOLTIP_TEXTS.VEHICLE_NOTIFICATION_TARGET_USER}`}
          ></WithDescriptionTooltipIcon>
        </FlexBox>
      </CustomLabel>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            width: '100%',
          },
        }}
      >
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.LEASE_PAYMENTS_NUMBER}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`leases.${index}.paymentsNumber`}
            control={control}
          />
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.LEASE_PAYMENTS_DATE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`leases.${index}.paymentsDate`}
            control={control}
          />
        </CustomLabel>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            width: '100%',
          },
        }}
      >
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.LEASE_COMPANY_NAME}
          <CustomInput {...register(`leases.${index}.companyName`)} />
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.LEASE_CHARGE_PERSON}
          <CustomInput {...register(`leases.${index}.chargePerson`)} />
        </CustomLabel>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            width: '100%',
          },
        }}
      >
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.LEASE_ADDRESS}
          <CustomInput {...register(`leases.${index}.address`)} />
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.LEASE_PHONE_NUMBER}
          <CustomInput {...register(`leases.${index}.phoneNumber`)} />
        </CustomLabel>
      </FlexBox>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.LEASE_MEMO}
        <CustomTextArea {...register(`leases.${index}.memo`)} />
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.FILE_UPLOAD}(
        {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
        <Controller
          render={() => (
            <CustomDropZone
              multiple={true}
              onDrop={onDrop}
              accept={'image/*, application/pdf'}
            />
          )}
          name={`leases.${index}.fileUrls`}
          control={control}
        />
        {watch(`leases.${index}.files`) &&
          Object.values(watch(`leases.${index}.files`))?.map(
            (image: File, fileIndex: number) => (
              <FlexBox flexDirection={'row'} key={fileIndex}>
                <p>
                  <a
                    href={URL.createObjectURL(image)}
                    download={decodeURI(image.name)}
                  >
                    {decodeURI(image.name)}
                  </a>
                </p>
                <IconButton
                  component="span"
                  onClick={() =>
                    setValue(
                      `leases.${index}.files`,
                      watch(`leases.${index}.files`).filter(
                        (_, i) => i !== fileIndex
                      )
                    )
                  }
                >
                  <CancelIcon />
                </IconButton>
              </FlexBox>
            )
          )}
      </CustomLabel>
      <DeleteButton onClick={() => props.deleteItem(index)}>削除</DeleteButton>
    </BackGroundPalePink>
  )
}

export default leaseCard
