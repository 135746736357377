const ARTICLE_MENU = {
  name: 'articles',
  title: 'お知らせ一覧',
  url: '/articles/',
}

const EDUCATION_DOCUMENTS_MENU = {
  name: 'education_documents',
  title: '資料一覧',
  url: '/educations/',
}

const EDUCATION_ATTENDANCES_MENU = {
  name: 'education_attendances',
  title: '受講履歴',
  url: '/educations/attendances/',
}

const ADMIN_ARTICLES_MENU = {
  name: 'articles',
  title: 'お知らせ一覧',
  url: '/admin/articles/',
}

const ADMIN_ARTICLE_MAINTENANCES_MENU = {
  name: 'articles',
  title: 'お知らせ管理',
  url: '/admin/articles/maintenances',
}

const ADMIN_EDUCATION_DOCUMENTS_MENU = {
  name: 'education_documents',
  title: '資料一覧',
  url: '/admin/educations/',
}

const ADMIN_EDUCATION_VIDEOS_MENU = {
  name: 'education_videos',
  title: '動画教材',
  url: '/admin/educations/videos/',
}

const ADMIN_EDUCATION_ATTENDANCES_MENU = {
  name: 'education_attendances',
  title: '受講履歴',
  url: '/admin/educations/attendances/',
}

const ADMIN_EDUCATION_VIDEO_LOGS_MENU = {
  name: 'education_video_logs',
  title: '動画視聴履歴',
  url: '/admin/educations/video_logs/',
}

const ADMIN_EDUCATION_COMMENTS_MENU = {
  name: 'education_comments',
  title: 'コメント履歴',
  url: '/admin/educations/comments/',
}

const ADMIN_EDUCATION_EXPORT_CSV_MENU = {
  name: 'education_export_csv',
  title: 'CSV出力',
  url: '/admin/educations/export_csv/',
}

const ADMIN_EDUCATION_ANNUAL_PLANS_MENU = {
  name: 'education_annual_plans',
  title: '年間計画',
  url: '/admin/educations/annual_plans/',
}

const DRIVING_COURSE_NEWCOMER_MENU = {
  name: 'driving_course_newcomer',
  title: '初任運転者講習',
  url: '/admin/driving_course_newcomer/',
}

const ADMIN_EDUCATION_SETTINGS_MENU = {
  name: 'education_settings',
  title: '各種設定',
  url: '/admin/educations/settings/',
}

const ADMIN_ALCOHOL_CHECKS_MENU = {
  name: 'alcohol_checks',
  title: 'アルコールチェック',
  url: '/admin/alcohol_checks/',
}

const ADMIN_VEHICLES_MENU = {
  name: 'vehicles',
  title: '車両管理',
  url: '/admin/vehicles/',
}

const ADMIN_DRIVERS_MENU = {
  name: 'drivers',
  title: '従業員管理',
  url: '/admin/drivers/',
}

const ADMIN_COMPANIES_MENU = {
  name: 'companies',
  title: '会社管理',
  url: '/admin/companies/',
}

const CSV_SETTINGS_MENU = {
  name: 'csv_settings',
  title: 'CSV設定',
  url: '/admin/csv_settings/',
}

export const XMILE_ADMIN_MENUS: Menus = {
  articles: {
    name: 'articles',
    title: 'お知らせ',
    children: {
      article_lists: ADMIN_ARTICLES_MENU,
      article_maintenances: ADMIN_ARTICLE_MAINTENANCES_MENU,
    },
  },
  educations: {
    name: 'educations',
    title: '教育管理',
    children: {
      education_documents: ADMIN_EDUCATION_DOCUMENTS_MENU,
      education_videos: ADMIN_EDUCATION_VIDEOS_MENU,
      education_attendances: ADMIN_EDUCATION_ATTENDANCES_MENU,
      education_video_logs: ADMIN_EDUCATION_VIDEO_LOGS_MENU,
      education_comments: ADMIN_EDUCATION_COMMENTS_MENU,
      education_export_csv: ADMIN_EDUCATION_EXPORT_CSV_MENU,
      education_annual_plans: ADMIN_EDUCATION_ANNUAL_PLANS_MENU,
      driving_course_newcomer: DRIVING_COURSE_NEWCOMER_MENU,
      education_settings: ADMIN_EDUCATION_SETTINGS_MENU,
    },
  },
  alcoholChecks: ADMIN_ALCOHOL_CHECKS_MENU,
  masters: {
    name: 'masters',
    title: 'マスタ管理',
    url: '/masters/',
    children: {
      vehicles: ADMIN_VEHICLES_MENU,
      drivers: ADMIN_DRIVERS_MENU,
      companies: ADMIN_COMPANIES_MENU,
      csv_settings: CSV_SETTINGS_MENU,
    },
  },
}

// 将来的にADMIN_MENUSのURLは全て/admin/から始まる形に変更したい
export const ADMIN_MENUS: Menus = {
  articles: {
    name: 'articles',
    title: 'お知らせ',
    children: {
      article_lists: ADMIN_ARTICLES_MENU,
      article_maintenances: ADMIN_ARTICLE_MAINTENANCES_MENU,
    },
  },
  educations: {
    name: 'educations',
    title: '教育管理',
    children: {
      education_documents: ADMIN_EDUCATION_DOCUMENTS_MENU,
      education_videos: ADMIN_EDUCATION_VIDEOS_MENU,
      education_attendances: ADMIN_EDUCATION_ATTENDANCES_MENU,
      education_video_logs: ADMIN_EDUCATION_VIDEO_LOGS_MENU,
      education_comments: ADMIN_EDUCATION_COMMENTS_MENU,
      education_export_csv: ADMIN_EDUCATION_EXPORT_CSV_MENU,
      education_annual_plans: ADMIN_EDUCATION_ANNUAL_PLANS_MENU,
      driving_course_newcomer: DRIVING_COURSE_NEWCOMER_MENU,
      education_settings: ADMIN_EDUCATION_SETTINGS_MENU,
    },
  },
  alcoholChecks: ADMIN_ALCOHOL_CHECKS_MENU,
  masters: {
    name: 'masters',
    title: 'マスタ管理',
    url: '/masters/',
    children: {
      vehicles: ADMIN_VEHICLES_MENU,
      drivers: ADMIN_DRIVERS_MENU,
      companies: ADMIN_COMPANIES_MENU,
      csv_settings: CSV_SETTINGS_MENU,
    },
  },
}

export const MENUS: Menus = {
  articles: ARTICLE_MENU,
  educations: {
    name: 'educations',
    title: '教育管理',
    children: {
      education_documents: EDUCATION_DOCUMENTS_MENU,
      education_videos: ADMIN_EDUCATION_VIDEOS_MENU,
      education_attendances: EDUCATION_ATTENDANCES_MENU,
    },
  },
}

export const ASSISTANT_STAFF_MENUS: Menus = {
  articles: ARTICLE_MENU,
  educations: {
    name: 'educations',
    title: '教育管理',
    children: {
      education_documents: EDUCATION_DOCUMENTS_MENU,
      education_videos: ADMIN_EDUCATION_VIDEOS_MENU,
      education_attendances: EDUCATION_ATTENDANCES_MENU,
    },
  },
}

export const DISPATCHER_MENUS: Menus = {
  articles: ARTICLE_MENU,
  educations: {
    name: 'educations',
    title: '教育管理',
    children: {
      education_documents: EDUCATION_DOCUMENTS_MENU,
      education_videos: ADMIN_EDUCATION_VIDEOS_MENU,
      education_attendances: EDUCATION_ATTENDANCES_MENU,
    },
  },
}
