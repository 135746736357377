import * as React from 'react'
import { useForm } from 'react-hook-form'
import { ARTICLES, LOCAL_STORAGE_PAGE_KEY } from 'commons/constants'
import { BackGroundGray } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomInputShort,
  CustomForm,
  ResetButton,
} from 'components/atoms/FormComponents'
import { Box, Typography } from '@mui/material'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import {
  getLocalStorageSearchParams,
  setLocalStorageSearchParams,
  getLocalStorageDisplayLimit,
  removeLocalStoragePageNumber,
} from 'commons/table'

interface Props {
  setSearchParams: React.Dispatch<React.SetStateAction<SearchArticle>>
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  defaultSearchParams: SearchArticle
  isLoading?: boolean
}

function SearchForm(props: Props) {
  const {
    setSearchParams,
    setPaginateParams,
    setPage,
    defaultSearchParams,
    isLoading = false,
  } = props

  React.useEffect(() => {
    const searchParams = getLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.ARTICLES
    )
    if (searchParams) {
      // リクエストで送るparamsのstate
      setSearchParams(searchParams)
      // formのvalue
      Object.keys(searchParams).forEach((key: keyof SearchArticle) => {
        setValue(key, searchParams[key])
      })
    }
  }, [])

  // フォームオブジェクト
  const { handleSubmit, register, reset, setValue } = useForm<SearchArticle>({
    mode: 'onSubmit',
  })

  // フォームsubmit時のコールバック
  const onSubmit = (data: SearchArticle) => {
    setSearchParams(data)
    setLocalStorageSearchParams(LOCAL_STORAGE_PAGE_KEY.ARTICLES, data)
  }

  const resetParams = () => {
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = 1

    setPaginateParams({ pageNumber, displayLimit: displayLimit })
    setPage(pageNumber)
    removeLocalStoragePageNumber(LOCAL_STORAGE_PAGE_KEY.ARTICLES)
    setSearchParams(defaultSearchParams)
    setLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.ARTICLES,
      defaultSearchParams
    )
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '90%' }}>
        <BackGroundGray>
          <Typography
            sx={{ marginBottom: '20px', fontWeight: 'bold' }}
            variant={'body1'}
          >
            条件で絞り込み
          </Typography>
          <CustomForm onSubmit={handleSubmit(onSubmit)}>
            <CustomLabel>
              {ARTICLES.LABELS.TITLE}
              <CustomInputShort {...register('title')} />
            </CustomLabel>

            <ApplyButton pattern="inRow" type="submit" disabled={isLoading}>
              絞り込む
            </ApplyButton>
            <ResetButton
              onClick={() => {
                reset()
                resetParams()
              }}
              disabled={isLoading}
            >
              <Typography>リセット</Typography>
            </ResetButton>
          </CustomForm>
        </BackGroundGray>
      </Box>
    </Box>
  )
}

export default SearchForm
