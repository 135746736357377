import { AxiosResponse } from 'axios'
import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { addParamPrefix, camelCaseKeysToUnderscore } from 'commons/functions'

export function fetchPaymentStatements(
  searchParams?: SearchPaymentStatement,
  paginateParams?: PaginateParams
) {
  const customSearchParams = searchParams
    ? addParamPrefix(searchParams, 'search')
    : {}
  const customPaginateParams = paginateParams
    ? addParamPrefix(paginateParams, 'pagination')
    : {}
  const params = camelCaseKeysToUnderscore({
    ...customSearchParams,
    ...customPaginateParams,
  })
  return axios.get(API_ENDPOINTS.PAYMENT_STATEMENTS, {
    params: params,
  })
}

export function getPaymentStatement(id: number) {
  return axios.get([API_ENDPOINTS.PAYMENT_STATEMENTS, id].join(''))
}

export function fetchPaymentStatementOptions(): Promise<
  AxiosResponse<{
    approveStatusOptions: SelectOption[]
    paymentStatusOptions: SelectOption[]
  }>
> {
  return axios.get([API_ENDPOINTS.PAYMENT_STATEMENTS, 'options'].join(''))
}

export function deletePaymentStatement(id: number) {
  return axios.delete([API_ENDPOINTS.PAYMENT_STATEMENTS, id].join(''))
}

export function createPaymentStatement(params: PaymentStatement) {
  return axios.post(
    API_ENDPOINTS.PAYMENT_STATEMENTS,
    camelCaseKeysToUnderscore(params)
  )
}

export function updatePaymentStatement(params: PaymentStatement) {
  return axios.patch(
    [API_ENDPOINTS.PAYMENT_STATEMENTS, params.id].join(''),
    camelCaseKeysToUnderscore(params)
  )
}

export function sendPaymentStatementMail(params: SendPaymentStatementMail) {
  return axios.post(
    [API_ENDPOINTS.PAYMENT_STATEMENTS, params.id, '/send_mail'].join(''),
    camelCaseKeysToUnderscore(params)
  )
}
