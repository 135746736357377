import { useQuery } from '@tanstack/react-query'
import { createQueryKey } from 'commons/functions'
import { fetchDrivers } from 'components/apis/drivers'
import { useErrorHandler } from 'react-error-boundary'

export default function useDrivers(
  searchParams?: SearchDriver,
  paginateParams?: PaginateParams
) {
  const handleError = useErrorHandler()

  const queryKey = createQueryKey('drivers', {
    ...searchParams,
    ...paginateParams,
  })

  const { data: driversData, isLoading: isLoadingDriversData } = useQuery({
    queryKey,
    queryFn: async () => {
      const res = await fetchDrivers(searchParams, paginateParams)
      return res.data
    },
    onError: (error) => {
      handleError(error)
    },
  })

  return { driversData, isLoadingDriversData }
}
