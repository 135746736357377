import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import { LOCAL_STORAGE_USER_ID, VALIDATION_MESSAGES } from 'commons/constants'
import { fetchDriverList } from 'components/apis/drivers'
import {
  CustomLabel,
  CustomSelect,
  ErrorMessage,
} from 'components/atoms/FormComponents'
import { UserContext } from 'providers/UserProvider'
import { useParams } from 'react-router-dom'

export default function DriverLabel(props: {
  control: any
  setValue: any
  errors: any
  answered: boolean
}) {
  const { control, setValue, errors, answered } = props
  const { id: selectedUserId, educationId } = useParams()
  const user = React.useContext(UserContext)
  const { data: drivers } = useQuery(
    [`education_tests/Form`, 'coworker_driver_list'],
    () =>
      // アカウント権限に関わらず全社、事業所のドライバーを表示
      fetchDriverList(true).then((res) => {
        const drivers: DriverSelectOption[] = res.data.drivers || []

        if (answered) {
          const selectedDriver = drivers.find(
            (driver) => String(driver.id) === selectedUserId
          )
          return [
            {
              value: selectedDriver.id,
              label: selectedDriver.fullName,
            },
          ]
        }

        // "ドライバー 共通アカウント"ユーザー向けの処理
        if (user.isDriverCommonAccount) {
          const currentDriver = res.data.drivers?.find(
            (value: DriverSelectOption) => String(value.id) === String(user.id)
          )
          if (!currentDriver) {
            setValue(
              'driverId',
              parseInt(localStorage.getItem(LOCAL_STORAGE_USER_ID))
            )
          }
        }

        return Object.values(drivers).map((value: DriverSelectOption) => {
          return { value: value.id, label: value.fullName }
        })
      })
  )

  // 受講する場合はeducationIdがundefined, 履歴出力時は値が入るので、ページが分かる
  const isHistoryPage = answered && educationId

  const selectedValue = (value) =>
    isHistoryPage && drivers?.length === 1
      ? drivers[0]
      : drivers?.find((driver) => String(driver.value) === String(value))

  return (
    <CustomLabel>
      ドライバー
      <Controller
        name="driverId"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <CustomSelect
            options={drivers}
            value={selectedValue(value)}
            onChange={(option: ReactSelectOptionProps) =>
              onChange(option.value)
            }
            placeholder="選択してください"
            isDisabled={answered}
          />
        )}
      />
      {errors.driverId && (
        <ErrorMessage sx={{ marginTop: '0.5rem' }}>
          {VALIDATION_MESSAGES.required}
        </ErrorMessage>
      )}
    </CustomLabel>
  )
}
