import * as React from 'react'
import { useFieldArray } from 'react-hook-form'
import {
  BackGroundWhite,
  BackGroundGray,
} from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomInput,
  CustomTextArea,
  HeadingText,
  CustomDateSelect,
} from 'components/atoms/FormComponents'
import {
  AddButtonOutlined,
  DeleteButton,
} from 'components/atoms/ButtonComponenets'
import { DRIVERS, PERSONNEL_CHANGE_HISTORIES } from 'commons/constants'
import { Box } from '@mui/material'

function PersonnelChangeHistoriesForm(props: {
  register
  control
  personnelChangeHistoriesIndex: number
}) {
  const { register, control, personnelChangeHistoriesIndex } = props

  return (
    <React.Fragment>
      <CustomLabel>
        {PERSONNEL_CHANGE_HISTORIES.LABELS.POSITION}
        <CustomInput
          {...register(
            `personnelChangeHistories[${personnelChangeHistoriesIndex}].position`
          )}
        />
      </CustomLabel>

      <CustomLabel sx={{ marginBottom: '30px' }}>
        {PERSONNEL_CHANGE_HISTORIES.LABELS.APPOINTED_DATE}
        <CustomDateSelect
          formKeys={{
            year: `personnelChangeHistories.${personnelChangeHistoriesIndex}.appointedYear`,
            month: `personnelChangeHistories.${personnelChangeHistoriesIndex}.appointedMonth`,
            day: `personnelChangeHistories.${personnelChangeHistoriesIndex}.appointedDay`,
          }}
          control={props.control}
        />
      </CustomLabel>

      <CustomLabel>
        {PERSONNEL_CHANGE_HISTORIES.LABELS.MEMO}
        <CustomTextArea
          rows={3}
          {...register(
            `personnelChangeHistories[${personnelChangeHistoriesIndex}].memo`
          )}
        />
      </CustomLabel>
    </React.Fragment>
  )
}

export default function DriversPersonnelChangeHistory(props: {
  register
  control
}) {
  const { register, control } = props
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: 'personnelChangeHistories',
  })
  const initPersonnelChangeHistory = {
    appointedYear: undefined,
    appointedMonth: undefined,
    appointedDay: undefined,
    position: null,
    memo: null,
  }

  const deletePersonnelChangeHistory = (index) => {
    remove(index)
  }

  const addPersonnelChangeHistory = () => {
    append(initPersonnelChangeHistory)
  }

  return (
    <BackGroundWhite>
      <HeadingText>{DRIVERS.LABELS.PERSONNEL_CHANGE_HISTORY}</HeadingText>
      {fields.map((_, index) => (
        <Box mb={2} key={index}>
          <BackGroundGray>
            {DRIVERS.LABELS.PERSONNEL_CHANGE_HISTORY}
            {index + 1}
            <PersonnelChangeHistoriesForm
              register={register}
              control={control}
              personnelChangeHistoriesIndex={index}
            />
            <DeleteButton onClick={() => deletePersonnelChangeHistory(index)}>
              削除
            </DeleteButton>
          </BackGroundGray>
        </Box>
      ))}
      <AddButtonOutlined onClick={addPersonnelChangeHistory}>
        {DRIVERS.LABELS.PERSONNEL_CHANGE_HISTORY}を追加
      </AddButtonOutlined>
    </BackGroundWhite>
  )
}
