import { styled } from '@mui/styles'
import { palette } from 'components/theme'
import * as React from 'react'

type BaseBadgeProps = {
  color: string
  backgroundColor: string
}

const BaseBadge = styled('span')<BaseBadgeProps>(({ theme }) => ({
  backgroundColor: theme.backgroundColor,
  color: theme.color,
  borderRadius: '4px',
  padding: '2px',
  fontSize: '12px',
  fontWeight: 'bold',
}))

export const PrimaryBadge = () => {
  return (
    <BaseBadge
      theme={{ color: 'white', backgroundColor: palette.primary.main }}
    >
      受講済
    </BaseBadge>
  )
}

export const ErrorBadge = () => {
  return (
    <BaseBadge theme={{ color: 'white', backgroundColor: palette.error.main }}>
      未受講
    </BaseBadge>
  )
}
