import { AxiosResponse } from 'axios'
import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { addParamPrefix, camelCaseKeysToUnderscore } from 'commons/functions'

export function fetchAccidents(
  searchParams: SearchAccident,
  paginateParams: PaginateParams
): Promise<AxiosResponse<{ accidents: Accident[]; totalCount: number }>> {
  const customSearchParams = addParamPrefix(searchParams, 'search')
  const customPaginateParams = addParamPrefix(paginateParams, 'pagination')
  const params = camelCaseKeysToUnderscore({
    ...customSearchParams,
    ...customPaginateParams,
  })

  return axios.get(API_ENDPOINTS.ACCIDENTS, {
    params: params,
  })
}

export function getAccident(id: number): Promise<AxiosResponse<Accident>> {
  return axios.get([API_ENDPOINTS.ACCIDENTS, id].join(''))
}

export function deleteAccident(id: number) {
  return axios.delete([API_ENDPOINTS.ACCIDENTS, id].join(''))
}

export function createAccident(params: { data: Accident; formData: FormData }) {
  const headers = { 'Content-Type': 'multipart/form-data' }
  return axios.post(API_ENDPOINTS.ACCIDENTS, params.formData, {
    headers: headers,
    params: camelCaseKeysToUnderscore(params.data),
  })
}

export function updateAccident(params: { data: Accident; formData: FormData }) {
  const headers = { 'Content-Type': 'multipart/form-data' }
  return axios.post(
    [API_ENDPOINTS.ACCIDENTS, params.data.id].join(''),
    params.formData,
    {
      headers: headers,
      params: camelCaseKeysToUnderscore(params.data),
    }
  )
}

export function getVideoConversionStatuses(id: number) {
  return axios.get(
    [API_ENDPOINTS.ACCIDENTS, id, 'video_conversion_statuses'].join('/')
  )
}
