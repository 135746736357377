import * as React from 'react'
import {
  CustomLabel,
  CustomInputShort,
  CustomDateSelect,
  CustomTextArea,
  CustomRadioButton,
  CustomRadioGroup,
  CustomDropZone,
} from 'components/atoms/FormComponents'
import {
  Control,
  FieldErrors,
  UseFormRegister,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { BackGroundPalePink } from 'components/atoms/BackGroundComponents'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { styled } from '@mui/styles'
import { FlexBox } from 'components/atoms/BoxComponents'
import { ENUMS } from 'commons/enums'
import { VEHICLES } from 'commons/constants'
import { theme } from 'components/theme'
import NumberFormat from 'components/organisms/NumberFormat'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

type InsuranceCompulsoryCardProps = {
  register: UseFormRegister<Vehicle>
  control: Control<Vehicle>
  setValue: UseFormSetValue<Vehicle>
  watch: UseFormWatch<Vehicle>
  errors?: FieldErrors<Vehicle>
  appendEmptyItem: () => void
  deleteItem: (index: number) => void
  index: number
}

const InsuranceCompulsoryCard: React.FC<InsuranceCompulsoryCardProps> = (
  props
) => {
  const { register, control, setValue, watch, index } = props
  const CardTitle = styled('p')({
    fontWeight: 'bold',
  })
  const MidiumCustomRadioGroup = styled(CustomRadioGroup)({
    width: '270px',
  })
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const old_files = watch(`insurancesCompulsory.${index}.files`)
        ? watch(`insurancesCompulsory.${index}.files`)
        : []
      setValue(`insurancesCompulsory.${index}.files`, [
        ...old_files,
        ...acceptedFiles,
      ])
    },
    [watch(`insurancesCompulsory.${index}.files`)]
  )

  return (
    <BackGroundPalePink sx={{ marginBottom: '30px' }}>
      <CardTitle>
        {VEHICLES.LABELS.INSURANCE_COMPULSORY}
        {index + 1}
      </CardTitle>
      <CustomLabel>
        {VEHICLES.LABELS.INSURANCE_COMPULSORY_STATUS}
        <MidiumCustomRadioGroup>
          {Object.entries(ENUMS.VEHICLES.INSURANCE_STATUS).map(([key, obj]) => (
            <React.Fragment key={key}>
              <CustomRadioButton
                {...register(`insurancesCompulsory.${index}.isActive`)}
                id={`insurancesCompulsory_isActive_${obj.value}_${index}`}
                value={obj.value}
                type="radio"
              />
              <label
                htmlFor={`insurancesCompulsory_isActive_${obj.value}_${index}`}
              >
                {obj.label}
              </label>
            </React.Fragment>
          ))}
        </MidiumCustomRadioGroup>
      </CustomLabel>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.INSURANCE_COMPULSORY_TYPE}
          <CustomInputShort
            {...register(`insurancesCompulsory.${index}.insuranceType`)}
          />
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.INSURANCE_COMPULSORY_COMPANY_NAME}
          <CustomInputShort
            {...register(`insurancesCompulsory.${index}.companyName`)}
          />
        </CustomLabel>
      </FlexBox>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.INSURANCE_COMPULSORY_START_DATE}
        <CustomDateSelect
          formKeys={{
            year: `insurancesCompulsory.${index}.startYear`,
            month: `insurancesCompulsory.${index}.startMonth`,
            day: `insurancesCompulsory.${index}.startDay`,
          }}
          control={control}
        />
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.INSURANCE_COMPULSORY_END_DATE}
        <CustomDateSelect
          formKeys={{
            year: `insurancesCompulsory.${index}.endYear`,
            month: `insurancesCompulsory.${index}.endMonth`,
            day: `insurancesCompulsory.${index}.endDay`,
          }}
          control={control}
        />
      </CustomLabel>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.INSURANCE_COMPULSORY_CONTRACT_NUMBER}
          <CustomInputShort
            {...register(`insurancesCompulsory.${index}.contractNumber`)}
          />
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.INSURANCE_COMPULSORY_CONTRACT_BRANCH_NUMBER}
          <CustomInputShort
            {...register(`insurancesCompulsory.${index}.contractBranchNumber`)}
          />
        </CustomLabel>
      </FlexBox>
      <FlexBox>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.INSURANCE_COMPULSORY_COST}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`insurancesCompulsory.${index}.cost`}
            control={control}
          />
        </CustomLabel>
      </FlexBox>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.INSURANCE_COMPULSORY_MEMO}
        <CustomTextArea {...register(`insurancesCompulsory.${index}.memo`)} />
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.FILE_UPLOAD}(
        {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
        <Controller
          render={() => (
            <CustomDropZone
              multiple={true}
              onDrop={onDrop}
              accept={'image/*, application/pdf'}
            />
          )}
          name={`insurancesCompulsory.${index}.fileUrls`}
          control={control}
        />
        {watch(`insurancesCompulsory.${index}.files`) &&
          Object.values(watch(`insurancesCompulsory.${index}.files`))?.map(
            (image: File, fileIndex: number) => (
              <FlexBox flexDirection={'row'} key={fileIndex}>
                <p>
                  <a
                    href={URL.createObjectURL(image)}
                    download={decodeURI(image.name)}
                  >
                    {decodeURI(image.name)}
                  </a>
                </p>
                <IconButton
                  component="span"
                  onClick={() =>
                    setValue(
                      `insurancesCompulsory.${index}.files`,
                      watch(`insurancesCompulsory.${index}.files`).filter(
                        (_, i) => i !== fileIndex
                      )
                    )
                  }
                >
                  <CancelIcon />
                </IconButton>
              </FlexBox>
            )
          )}
      </CustomLabel>
      <DeleteButton onClick={() => props.deleteItem(index)}>削除</DeleteButton>
    </BackGroundPalePink>
  )
}

export default InsuranceCompulsoryCard
