import { useQuery } from '@tanstack/react-query'
import { createQueryKey } from 'commons/functions'
import { fetchArticles } from 'components/apis/articles'
import { useErrorHandler } from 'react-error-boundary'

export default function useArticles(
  searchParams?: SearchArticle,
  paginateParams?: PaginateParams
) {
  const errorHandler = useErrorHandler()
  const queryKey = createQueryKey('articles', {
    ...searchParams,
    ...paginateParams,
  })

  const { data: articlesData, isLoading: isLoadingArticlesData } = useQuery({
    queryKey,
    queryFn: async () => {
      const res = await fetchArticles(searchParams, paginateParams)
      return res.data
    },
    onError: (err) => {
      errorHandler(err)
    },
  })

  return {
    articlesData,
    isLoadingArticlesData,
  }
}
