import * as React from 'react'
import { Controller } from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomDateSelect,
  CustomInput,
  CustomLabel,
  CustomRadioButton,
  CustomRadioGroup,
  CustomSelect,
  CustomSelectShort,
  ErrorMessage,
  HeadingText,
  UnitText,
  WithRequiredBadge,
  YearMounthDatePicker,
  WithDescriptionTooltipIcon,
} from 'components/atoms/FormComponents'
import { styled } from '@mui/styles'
import { FlexBox } from 'components/atoms/BoxComponents'
import { dayRange, monthRange, yearRange } from 'commons/date'
import { ENUMS } from 'commons/enums'
import { VALIDATION_MESSAGES, VEHICLES } from 'commons/constants'
import { range } from 'commons/functions'
import { decimalDigitCutMessage } from 'commons/infoMessage'
import CompanyAndOfficeSelect from 'components/atoms/ReactHookFormPartials/InputForm/CompanyAndOfficeSelect'
import NumberFormat from 'components/organisms/NumberFormat'
import { VEHICLE_BODY_TYPE } from 'commons/vehicleBodyType'
import { useQuery } from '@tanstack/react-query'
import { fetchTrailers } from 'components/apis/vehicles'

export default function Basic(props: {
  register
  control
  errors
  type
  getValues
  setValue
  watch
}) {
  const { register, control, getValues, setValue, watch } = props

  const FormSubText = styled('p')({
    fontSize: '14px',
    marginLeft: '8px',
  })

  const NumberSubInput = styled(CustomInput)({
    width: '64px',
  })

  const MidiumInput = styled(CustomInput)({
    width: '160px',
  })

  const SmallInput = styled(CustomInput)({
    width: '100px',
  })

  const LongCustomRadioGroup = styled(CustomRadioGroup)({
    width: '550px',
  })

  const MidiumCustomRadioGroup = styled(CustomRadioGroup)({
    width: '270px',
  })

  const SmallCustomRadioGroup = styled(CustomRadioGroup)({
    width: '180px',
  })

  const LowSpace = styled('div')({
    marginLeft: '10px',
  })

  const tonnageRange = range(1, 31).map((value) => ({
    label: `${value}t`,
    value: value,
  }))

  // 総排気量の入力可能な小数点以下桁数
  const engineDisplacementValidDigit = 2

  const { data: trailers } = useQuery(
    [`admin/vehicles/form_sections/basic`, 'trailers'],
    () =>
      fetchTrailers().then((res) =>
        Object.values(res.data.trailers).map((trailer: Vehicle) => {
          return { value: trailer.id, label: trailer.number }
        })
      )
  )

  return (
    <BackGroundWhite>
      <HeadingText>基本情報</HeadingText>
      <FlexBox flexWrap="wrap">
        <CustomLabel>
          <WithRequiredBadge>{VEHICLES.LABELS.NUMBER}</WithRequiredBadge>
          <CustomInput {...register('number', { required: true })} />
          {props.errors.number && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
      </FlexBox>
      <CustomLabel>
        車台番号
        <CustomInput {...register('uniqueNumber', {})} />
      </CustomLabel>
      <CompanyAndOfficeSelect
        companyKeyName={'companyId'}
        officeKeyName={'officeId'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
      />
      <FlexBox>
        <CustomLabel>
          {VEHICLES.LABELS.NAME}
          <CustomInput {...register('name', {})} />
        </CustomLabel>
      </FlexBox>
      <FlexBox flexWrap="wrap">
        <CustomLabel>
          登録年月日/交付年月日
          <CustomDateSelect
            formKeys={{
              year: 'registeredYear',
              month: 'registeredMonth',
              day: 'registeredDay',
            }}
            control={props.control}
          ></CustomDateSelect>
          <FlexBox flexWrap="wrap">
            <Controller
              name="registeredYear"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelectShort
                  options={yearRange}
                  value={yearRange.find((c) => c.value === value)}
                  onChange={(option: ReactSelectOptionProps) =>
                    onChange(option.value)
                  }
                  placeholder="未選択"
                />
              )}
            />
            <UnitText>年</UnitText>
            <Controller
              name="registeredMonth"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelectShort
                  options={monthRange}
                  value={monthRange.find((c) => c.value === value)}
                  onChange={(option: ReactSelectOptionProps) =>
                    onChange(option.value)
                  }
                  placeholder="未選択"
                />
              )}
            />
            <UnitText>月</UnitText>
            <Controller
              name="registeredDay"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelectShort
                  options={dayRange}
                  value={dayRange.find((c) => c.value === value)}
                  onChange={(option: ReactSelectOptionProps) =>
                    onChange(option.value)
                  }
                  placeholder="未選択"
                />
              )}
            />
            <UnitText>日</UnitText>
          </FlexBox>
        </CustomLabel>
        <CustomLabel>
          初度登録年月
          <Controller
            name="firstRegisteredDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <YearMounthDatePicker
                onChange={onChange}
                selected={value && new Date(value)}
              />
            )}
          />
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.INSPECTIONS_DATE}
          <CustomDateSelect
            formKeys={{
              year: 'inspectionExpireYear',
              month: 'inspectionExpireMonth',
              day: 'inspectionExpireDay',
            }}
            control={props.control}
            defaultToday={false}
            yearsFrom2000={true}
          />
        </CustomLabel>
      </FlexBox>
      <CustomLabel>
        自動車の種別
        <LongCustomRadioGroup>
          {Object.entries(ENUMS.VEHICLES.LICENSE_CAR_TYPE).map(([key, obj]) => (
            <React.Fragment key={key}>
              <CustomRadioButton
                {...register('category')}
                id={`category_${obj.value}`}
                value={obj.value}
                type="radio"
              />
              <label htmlFor={`category_${obj.value}`}>{obj.label}</label>
            </React.Fragment>
          ))}
        </LongCustomRadioGroup>
      </CustomLabel>
      <FlexBox flexWrap="wrap">
        <CustomLabel>
          車両の用途
          <MidiumCustomRadioGroup>
            {Object.entries(ENUMS.VEHICLES.USE_CAR_TYPE).map(([key, obj]) => (
              <React.Fragment key={key}>
                <CustomRadioButton
                  {...register('usage')}
                  id={`usage_${obj.value}`}
                  value={obj.value}
                  type="radio"
                />
                <label htmlFor={`usage_${obj.value}`}>{obj.label}</label>
              </React.Fragment>
            ))}
          </MidiumCustomRadioGroup>
        </CustomLabel>
        <CustomLabel>
          ナンバー種別
          <MidiumCustomRadioGroup>
            {Object.entries(ENUMS.VEHICLES.CAR_TYPE).map(([key, obj]) => (
              <React.Fragment key={key}>
                <CustomRadioButton
                  {...register('numberPlateType')}
                  id={`numberPlateType_${obj.value}`}
                  value={obj.value}
                  type="radio"
                />
                <label htmlFor={`numberPlateType_${obj.value}`}>
                  {obj.label}
                </label>
              </React.Fragment>
            ))}
          </MidiumCustomRadioGroup>
        </CustomLabel>
      </FlexBox>
      <CustomLabel>
        {VEHICLES.LABELS.SHAPE}
        <FlexBox>
          <Controller
            name="shape"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                options={VEHICLE_BODY_TYPE}
                value={VEHICLE_BODY_TYPE?.find((c) => c.value === value)}
                onChange={(option: ReactSelectOptionProps) =>
                  onChange(option.value)
                }
                placeholder={`${VEHICLES.LABELS.SHAPE}を選択する`}
              />
            )}
          />
          <WithDescriptionTooltipIcon
            text={`セミトレーラーまたはフルトレーラーで登録すると「${VEHICLES.LABELS.CONNECTED_TRAILER}」に表示されるようになります。`}
          />
        </FlexBox>
      </CustomLabel>
      <CustomLabel>
        {VEHICLES.LABELS.CONNECTED_TRAILER}
        <FlexBox>
          <Controller
            name="trailerId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                options={trailers}
                value={trailers?.find((c) => c.value === value)}
                onChange={(option: ReactSelectOptionProps) =>
                  onChange(option.value)
                }
                placeholder={`${VEHICLES.LABELS.CONNECTED_TRAILER}を選択する`}
              />
            )}
          />
          <WithDescriptionTooltipIcon
            text={`${VEHICLES.LABELS.SHAPE}でセミトレーラーまたはフルトレーラーが選択された車両が表示されます。`}
          />
        </FlexBox>
      </CustomLabel>
      <CustomLabel>
        {VEHICLES.LABELS.MANUFACTURE}
        <CustomInput {...register('manufacture')} />
      </CustomLabel>
      <CustomLabel>
        {VEHICLES.LABELS.PERSON_CAPACITY}
        <FlexBox alignItems="center">
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={MidiumInput} />
            )}
            name="personCapacity"
            control={control}
          />
          <FormSubText>人</FormSubText>
        </FlexBox>
      </CustomLabel>
      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {`${VEHICLES.LABELS.WEIGHT_CAPACITY}`}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={MidiumInput} />
              )}
              name="weightCapacity1"
              control={control}
            />
            <FormSubText>kg</FormSubText>
          </FlexBox>
        </CustomLabel>
        <CustomLabel>
          {`${VEHICLES.LABELS.WEIGHT_CAPACITY}(2)`}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={MidiumInput} />
              )}
              name="weightCapacity2"
              control={control}
            />
            <FormSubText>kg</FormSubText>
          </FlexBox>
        </CustomLabel>
        <CustomLabel>
          {`${VEHICLES.LABELS.WEIGHT_CAPACITY}(3)`}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={MidiumInput} />
              )}
              name="weightCapacity3"
              control={control}
            />
            <FormSubText>kg</FormSubText>
          </FlexBox>
        </CustomLabel>
      </FlexBox>
      <CustomLabel>
        {`${VEHICLES.LABELS.WEIGHT}`}
        <FlexBox alignItems="center">
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={MidiumInput} />
            )}
            name="weight"
            control={control}
          />
          <FormSubText>kg</FormSubText>
        </FlexBox>
      </CustomLabel>
      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {`${VEHICLES.LABELS.TOTAL_WEIGHT}`}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={MidiumInput} />
              )}
              name="totalWeight1"
              control={control}
            />
            <FormSubText>kg</FormSubText>
          </FlexBox>
        </CustomLabel>
        <CustomLabel>
          {`${VEHICLES.LABELS.TOTAL_WEIGHT}(2)`}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={MidiumInput} />
              )}
              name="totalWeight2"
              control={control}
            />
            <FormSubText>kg</FormSubText>
          </FlexBox>
        </CustomLabel>
        <CustomLabel>
          {`${VEHICLES.LABELS.TOTAL_WEIGHT}(3)`}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={MidiumInput} />
              )}
              name="totalWeight3"
              control={control}
            />
            <FormSubText>kg</FormSubText>
          </FlexBox>
        </CustomLabel>
      </FlexBox>
      <CustomLabel>
        {VEHICLES.LABELS.SIZE}
        <FlexBox flexWrap="wrap">
          <MidiumCustomRadioGroup>
            {Object.entries(ENUMS.VEHICLES.SIZES).map(([key, obj]) => (
              <React.Fragment key={key}>
                <CustomRadioButton
                  {...register('size')}
                  id={`size_${obj.value}`}
                  value={obj.value}
                  type="radio"
                />
                <label htmlFor={`size_${obj.value}`}>{obj.label}</label>
              </React.Fragment>
            ))}
          </MidiumCustomRadioGroup>
        </FlexBox>
      </CustomLabel>
      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {VEHICLES.LABELS.TONNAGE}
          <Controller
            name="tonnage"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                options={tonnageRange}
                value={tonnageRange?.find((c) => c.value === value)}
                onChange={(option: ReactSelectOptionProps) =>
                  onChange(option.value)
                }
                placeholder={`${VEHICLES.LABELS.TONNAGE}を選択する`}
              />
            )}
          />
        </CustomLabel>
      </FlexBox>
      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {VEHICLES.LABELS.OUTER_LENGTH}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={MidiumInput} />
              )}
              name="outerLength"
              control={control}
            />
            <FormSubText>cm</FormSubText>
          </FlexBox>
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.OUTER_WIDTH}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={MidiumInput} />
              )}
              name="outerWidth"
              control={control}
            />
            <FormSubText>cm</FormSubText>
          </FlexBox>
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.OUTER_HEIGHT}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={MidiumInput} />
              )}
              name="outerHeight"
              control={control}
            />
            <FormSubText>cm</FormSubText>
          </FlexBox>
        </CustomLabel>
      </FlexBox>
      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {VEHICLES.LABELS.CABIN_TYPE}
          <LongCustomRadioGroup>
            {Object.entries(ENUMS.VEHICLES.CAB_TYPE).map(([key, obj]) => (
              <React.Fragment key={key}>
                <CustomRadioButton
                  {...register('cabinType')}
                  id={`cabinType_${obj.value}`}
                  value={obj.value}
                  type="radio"
                />
                <label htmlFor={`cabinType_${obj.value}`}>{obj.label}</label>
              </React.Fragment>
            ))}
          </LongCustomRadioGroup>
        </CustomLabel>
      </FlexBox>
      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {VEHICLES.LABELS.AXILE_WEIGHT_FRONT_FRONT}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={SmallInput} />
              )}
              name="axileWeightFrontFront"
              control={control}
            />
            <FormSubText>kg</FormSubText>
          </FlexBox>
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.AXILE_WEIGHT_FRONT_REAR}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={SmallInput} />
              )}
              name="axileWeightFrontRear"
              control={control}
            />
            <FormSubText>kg</FormSubText>
          </FlexBox>
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.AXILE_WEIGHT_REAR_FRONT}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={SmallInput} />
              )}
              name="axileWeightRearFront"
              control={control}
            />
            <FormSubText>kg</FormSubText>
          </FlexBox>
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.AXILE_WEIGHT_FRONT_REAR_REAR}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={SmallInput} />
              )}
              name="axileWeightRearRear"
              control={control}
            />
            <FormSubText>kg</FormSubText>
          </FlexBox>
        </CustomLabel>
      </FlexBox>
      <CustomLabel>
        {VEHICLES.LABELS.DRIVE_SYSTEM}
        <FlexBox alignItems="center">
          <CustomInput {...register('driveSystem')} />
        </FlexBox>
      </CustomLabel>
      <CustomLabel>
        {VEHICLES.LABELS.MODEL}
        <FlexBox flexWrap="wrap" alignItems="center">
          <NumberSubInput {...register('model1')} />
          <p>-</p>
          <NumberSubInput {...register('model2')} />
          <LowSpace />
          <Controller
            name={'modelModified'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <label>
                <input
                  type="checkbox"
                  checked={value}
                  onChange={() => onChange(!value)}
                />
                改
              </label>
            )}
          />
        </FlexBox>
      </CustomLabel>
      <CustomLabel>
        {VEHICLES.LABELS.ENGINE_MODEL}
        <CustomInput {...register('engineModel')} />
      </CustomLabel>
      <CustomLabel>
        {VEHICLES.LABELS.ENGINE_DISPLACEMENT}
        <FlexBox alignItems="center">
          <MidiumInput {...register('engineDisplacement')} />
          <FormSubText>kw/l</FormSubText>
        </FlexBox>
        <ErrorMessage sx={{ fontSize: '13px' }}>
          {decimalDigitCutMessage(engineDisplacementValidDigit)}
        </ErrorMessage>
      </CustomLabel>
      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {VEHICLES.LABELS.FUEL_TYPE}
          <CustomInput {...register('fuelType')} />
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.FUEL_TANK_NUMBER}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={MidiumInput} />
            )}
            name="fuelTankNumber"
            control={control}
          />
        </CustomLabel>
      </FlexBox>
      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {VEHICLES.LABELS.FUEL_TANK_CAPACITY}1
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={MidiumInput} />
            )}
            name="fuelTankCapacity1"
            control={control}
          />
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.FUEL_TANK_CAPACITY}2
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={MidiumInput} />
            )}
            name="fuelTankCapacity2"
            control={control}
          />
        </CustomLabel>
      </FlexBox>
      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {VEHICLES.LABELS.FUEL_TANK_CAPACITY}3
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={MidiumInput} />
            )}
            name="fuelTankCapacity3"
            control={control}
          />
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.FUEL_TANK_CAPACITY}4
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={MidiumInput} />
            )}
            name="fuelTankCapacity4"
            control={control}
          />
        </CustomLabel>
      </FlexBox>
      <CustomLabel>
        {VEHICLES.LABELS.HAS_SCR}
        <SmallCustomRadioGroup>
          {Object.entries(ENUMS.VEHICLES.SCR_TYPE).map(([key, obj]) => (
            <React.Fragment key={key}>
              <CustomRadioButton
                {...register('hasScr')}
                id={`hasScr_${obj.value}`}
                value={obj.value}
                type="radio"
              />
              <label htmlFor={`hasScr_${obj.value}`}>{obj.label}</label>
            </React.Fragment>
          ))}
        </SmallCustomRadioGroup>
      </CustomLabel>
      <CustomLabel>
        {VEHICLES.LABELS.TRANSMISSION_TYPE}
        <LongCustomRadioGroup>
          {Object.entries(ENUMS.VEHICLES.MITION_TYPE).map(([key, obj]) => (
            <React.Fragment key={key}>
              <CustomRadioButton
                {...register('transmissionType')}
                id={`transmissionType_${obj.value}`}
                value={obj.value}
                type="radio"
              />
              <label htmlFor={`transmissionType_${obj.value}`}>
                {obj.label}
              </label>
            </React.Fragment>
          ))}
        </LongCustomRadioGroup>
      </CustomLabel>
      <CustomLabel>
        {VEHICLES.LABELS.HAS_BED}
        <MidiumCustomRadioGroup>
          {Object.entries(ENUMS.VEHICLES.BED_TYPE).map(([key, obj]) => (
            <React.Fragment key={key}>
              <CustomRadioButton
                {...register('hasBed')}
                id={`hasBed_${obj.value}`}
                value={obj.value}
                type="radio"
              />
              <label htmlFor={`hasBed_${obj.value}`}>{obj.label}</label>
            </React.Fragment>
          ))}
        </MidiumCustomRadioGroup>
      </CustomLabel>
      <CustomLabel>
        {VEHICLES.LABELS.TIRE_COUNT}
        <FlexBox alignItems="center">
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={MidiumInput} />
            )}
            name="tireCount"
            control={control}
          />
          <FormSubText>本</FormSubText>
        </FlexBox>
      </CustomLabel>
      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {VEHICLES.LABELS.TIRE_SIZE_FRONT}
          <MidiumInput {...register('tireSizeFront')} />
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.TIRE_SIZE_REAR}
          <MidiumInput {...register('tireSizeRear')} />
        </CustomLabel>
      </FlexBox>
      <CustomLabel>
        {VEHICLES.LABELS.IS_SPECIFIED_COLD}
        <MidiumCustomRadioGroup>
          {Object.entries(ENUMS.VEHICLES.COLD_TYPE).map(([key, obj]) => (
            <React.Fragment key={key}>
              <CustomRadioButton
                {...register('isSpecifiedCold')}
                id={`isSpecifiedCold_${obj.value}`}
                value={obj.value}
                type="radio"
              />
              <label htmlFor={`isSpecifiedCold_${obj.value}`}>
                {obj.label}
              </label>
            </React.Fragment>
          ))}
        </MidiumCustomRadioGroup>
      </CustomLabel>
      <CustomLabel>
        {VEHICLES.LABELS.RECYCLE_PRICE}
        <FlexBox alignItems="center">
          <FormSubText>¥</FormSubText>
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={MidiumInput} />
            )}
            name="recyclePrice"
            control={control}
          />
        </FlexBox>
      </CustomLabel>
    </BackGroundWhite>
  )
}
