import axios from 'commons/axiosClient'
import { AxiosResponse } from 'axios'
import { API_ENDPOINTS } from 'commons/constants'
import { addParamPrefix, camelCaseKeysToUnderscore } from 'commons/functions'

export function fetchEducationAnnualPlans(
  searchParams?: SearchEducationAnnualPlan,
  paginateParams?: PaginateParams
) {
  const customSearchParams = searchParams
    ? addParamPrefix(searchParams, 'search')
    : {}
  const customPaginateParams = paginateParams
    ? addParamPrefix(paginateParams, 'pagination')
    : {}
  const params = camelCaseKeysToUnderscore({
    ...customSearchParams,
    ...customPaginateParams,
  })
  return axios.get(API_ENDPOINTS.EDUCATION_ANNUAL_PLANS, {
    params: params,
  })
}

export function getEducationAnnualPlan(
  id: number
): Promise<AxiosResponse<EducationAnnualPlan>> {
  return axios.get([API_ENDPOINTS.EDUCATION_ANNUAL_PLANS, id].join(''))
}

export function createEducationAnnualPlan(
  annualPlanParams: EducationAnnualPlan
) {
  const params = camelCaseKeysToUnderscore(annualPlanParams)
  return axios.post(API_ENDPOINTS.EDUCATION_ANNUAL_PLANS, params)
}

export function updateEducationAnnualPlan(
  annualPlanParams: EducationAnnualPlan
) {
  const params = camelCaseKeysToUnderscore(annualPlanParams)
  return axios.patch(
    [API_ENDPOINTS.EDUCATION_ANNUAL_PLANS, params.id].join(''),
    params
  )
}

export function deleteEducationAnnualPlan(id: number) {
  return axios.delete([API_ENDPOINTS.EDUCATION_ANNUAL_PLANS, id].join(''))
}

export function findByYear(annualPlanParams: SearchEducationAnnualPlan) {
  const params = camelCaseKeysToUnderscore(annualPlanParams)
  return axios.post(
    [API_ENDPOINTS.EDUCATION_ANNUAL_PLANS, 'find_by_year'].join(''),
    params
  )
}

export function getEducationValidStatuses() {
  return axios.get(
    [API_ENDPOINTS.EDUCATION_ANNUAL_PLANS, 'get_education_valid_statuses'].join(
      ''
    )
  )
}
