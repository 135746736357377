import * as React from 'react'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { fetchEducations } from 'components/apis/educations'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { EDUCATION_LIST_TEXT } from 'commons/constants'
import { ExplanationChip } from 'components/atoms/chips/ExplanationChip'

type EducationLinkProps = {
  education: Education
  isThisMonth?: boolean
}

function EducationLink(props: EducationLinkProps) {
  const { education, isThisMonth } = props

  return (
    <>
      <Link to={`${education.id}/`}>{education.title}</Link>
      {education.month && (
        <span style={{ marginLeft: '8px' }}>({education.month}月)</span>
      )}
      {isThisMonth && (
        <ExplanationChip label="今月の項目" style={{ marginLeft: '8px' }} />
      )}
    </>
  )
}

function AdminEducationList() {
  const currentMonth = new Date().getMonth() + 1
  const { data: educations, isLoading } = useQuery(
    [`admin/education_attendances/educationList`, 'educations'],
    () =>
      fetchEducations().then((res) => [
        ...res.data.educations,
        ...res.data.customEducations,
      ])
  )

  return isLoading ? (
    <CircularProgressBox />
  ) : (
    <>
      <span style={{ whiteSpace: 'pre-wrap' }}>{EDUCATION_LIST_TEXT}</span>
      <ol>
        {educations.map((item) => (
          <React.Fragment key={item.id}>
            <li>
              <EducationLink
                education={item}
                isThisMonth={currentMonth === item.month}
              />
            </li>
          </React.Fragment>
        ))}
      </ol>
    </>
  )
}

export default AdminEducationList
