import * as React from 'react'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  CustomTextArea,
  CustomDateSelect,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { CAREERS, DRIVERS } from 'commons/constants'
import { Box, styled, useTheme } from '@mui/material'
import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import CancelIcon from '@mui/icons-material/Cancel'
import Button from '@mui/material/Button'

type CareerDateFormProps = {
  control: Control<Driver>
  setValue: UseFormSetValue<Driver>
  careerIndex: number
  clearable: boolean
  defaultToday?: boolean
}

const ClearButton = styled('button')({
  background: 'transparent',
  padding: 0,
  border: 0,
  cursor: 'pointer',
  marginLeft: '8px',
  display: 'flex',
  alignItems: 'center',
})

const CareerDateForm: React.FC<CareerDateFormProps> = (props) => {
  const handleClearButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    careerIndex: number
  ) => {
    e.preventDefault()
    props.setValue(`careers.${careerIndex}.recordedYear`, null, {
      shouldValidate: true,
    })
    props.setValue(`careers.${careerIndex}.recordedMonth`, null, {
      shouldValidate: true,
    })
    props.setValue(`careers.${careerIndex}.recordedDay`, null, {
      shouldValidate: true,
    })
  }

  const theme = useTheme()

  return (
    <FlexBox flexWrap="wrap" alignItems="center">
      <CustomDateSelect
        formKeys={{
          year: `careers.${props.careerIndex}.recordedYear`,
          month: `careers.${props.careerIndex}.recordedMonth`,
          day: `careers.${props.careerIndex}.recordedDay`,
        }}
        control={props.control}
        defaultToday={props.defaultToday}
      ></CustomDateSelect>

      {props.clearable && (
        <>
          <ClearButton
            onClick={(e) => {
              handleClearButtonClick(e, props.careerIndex)
            }}
            sx={{
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            }}
          >
            <CancelIcon />
          </ClearButton>
          <Button
            onClick={(e) => {
              handleClearButtonClick(e, props.careerIndex)
            }}
          >
            クリア
          </Button>
        </>
      )}
    </FlexBox>
  )
}

type DriversCareerProps = {
  register: UseFormRegister<Driver>
  control: Control<Driver>
  setValue: UseFormSetValue<Driver>
}

const DriversCareer: React.FC<DriversCareerProps> = (props) => {
  const dateTitles = ['入社日', '運転手選定日', '退社日', '死亡日']

  return (
    <BackGroundWhite>
      <HeadingText>{DRIVERS.LABELS.CAREER}</HeadingText>
      {dateTitles.map((dateTitle, index) => (
        <Box mb={2} key={index}>
          <CustomLabel>
            {dateTitle}
            <CareerDateForm
              control={props.control}
              careerIndex={index}
              setValue={props.setValue}
              clearable={dateTitle === '退社日' || dateTitle === '死亡日'}
              defaultToday={dateTitle == '入社日'}
            />
          </CustomLabel>
          {(dateTitle === '退社日' || dateTitle === '死亡日') && (
            <CustomLabel>
              {CAREERS.LABELS.REASON}
              <CustomTextArea
                rows={3}
                {...props.register(`careers.${index}.reason`)}
              />
            </CustomLabel>
          )}
        </Box>
      ))}
    </BackGroundWhite>
  )
}

export default DriversCareer
