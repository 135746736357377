import React from 'react'

import { Typography } from '@mui/material'
import Button, { ButtonProps } from '@mui/material/Button'

import { palette } from 'components/theme'

type Props = ButtonProps &
  Partial<{ pattern: keyof typeof patterns; target: string }>

export const ApplyButton: React.FC<Props> = (props) => {
  return (
    <Button
      {...props}
      style={patterns[props.pattern]}
      sx={{
        height: '40px',
        backgroundColor: palette.primary.main,
        '&:hover': {
          backgroundColor: palette.primary.deep,
        },
        ...props.sx,
      }}
      variant="contained"
    >
      <Typography
        sx={{
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {props.children}
      </Typography>
    </Button>
  )
}

const editModal = {
  width: '110px',
  backgroundColor: palette.background.activeMenu,
  color: palette.text.black.main,
  '&:hover': { backgroundColor: 'white' },
}

const outlined = {
  backgroundColor: 'white',
  border: `1px solid ${palette.border.pale}`,
  color: palette.text.black.main,
  '&:hover': { background: 'none' },
}

const unsetOutlined = {
  ...outlined,
  height: 'unset',
}

const block = { display: 'block' }

const sendTarget = { height: '28px', width: '100px' }

const sidebar = { margin: '15px 10px' }

const mail = { ...block, width: '180px', marginBottom: '10px' }

const inRow = { marginRight: '1rem' }

const marginRight8 = { marginRight: '8px' }

const width80 = { width: '80px' }

const width120 = { width: '120px' }

const complete = { margin: '0 10px' }

const patterns = {
  block,
  complete,
  editModal,
  inRow,
  mail,
  marginRight8,
  unsetOutlined,
  outlined,
  sendTarget,
  sidebar,
  width80,
  width120,
}
