import ja from 'date-fns/locale/ja'
import * as React from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/src/stylesheets/datepicker.scss'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import {
  Control,
  Controller,
  RegisterOptions,
  UseFormGetValues,
  UseFormRegister,
} from 'react-hook-form'
import Select from 'react-select'

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonProps,
  IconButton,
  InputProps,
  List,
  Tooltip,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import InfoIcon from '@mui/icons-material/Info'
import { styled, SxProps, Theme } from '@mui/material/styles'

import { FORM_LINK } from 'commons/constants'
import {
  dayRange,
  monthRange,
  yearRange,
  yearRangeFrom2000,
} from 'commons/date'
import { hourRange, minutesRange } from 'commons/time'
import { validatePhoneNumberInput } from 'commons/validation'
import NumberFormat from 'components/organisms/NumberFormat'
import { palette, theme } from 'components/theme'
import ProfileImage from 'images/profile.png'
import { DriverFormKeys } from 'types/driverForm'
import { NotificationFormKeys } from 'types/notificationForm'
import { VehicleFormKeys } from 'types/vehicleForm'

import '../../stylesheets/react_datepicker_custom.scss'
import { IconLabelButtons } from './ButtonComponenets'
import { FlexBox } from './BoxComponents'
import { ApplyButton } from './buttons/ApplyButton'

export type FormKeys = DriverFormKeys | VehicleFormKeys | NotificationFormKeys

// フォーム全体を囲むラッパー
export const CustomForm = styled('form')({
  width: '100%',
})

// inputタグとlabelタグを囲むラッパー
export const CustomLabel = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  color: palette.text.gray.pale,
  marginBottom: '20px',
  marginRight: '20px',
  width: '100%',
})

// 縦並びのForm要素を囲むラッパー
export const FormGroup = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  color: palette.text.gray.pale,
  marginBottom: '20px',
  width: '100%',
})

export const WithRequiredBadge: React.FC = (props) => {
  const RequiredBadgeElement = styled('span')({
    height: 'fit-content',
    lineHeight: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.border.red,
    border: `1px solid ${palette.border.red}`,
    borderRadius: '2px',
    padding: '1px',
  })
  return (
    <FlexBox alignItems="center">
      {props.children}
      <RequiredBadgeElement style={{ marginLeft: '8px' }}>
        必須
      </RequiredBadgeElement>
    </FlexBox>
  )
}

// テキストフィールド
export const CustomInput = styled('input')({
  width: '378px',
  height: '2rem',
  border: '1px solid #CDCFD1',
  borderRadius: '6px',
  padding: '0 0.5rem',
  maxWidth: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
})

export const MidiumInput = styled(CustomInput)({
  width: '160px',
})

// テキストフィールド
export const CustomInputUnderline = styled('input')({
  width: '100%',
  height: '2rem',
  border: 0,
  borderBottom: '1px solid #CDCFD1',
  padding: '0 0.5rem',
  maxWidth: '100%',
  background: 'transparent',
})

// テキストフィールド
export const CustomInputShort = styled('input')({
  width: '160px',
  height: '2rem',
  border: '1px solid #CDCFD1',
  borderRadius: '6px',
  padding: '0 0.5rem',
  maxWidth: '100%',
})

export const PhoneNumberInput = styled(CustomInput)({
  width: '80px',
})

export const HeadingText = styled('p')({
  fontWeight: 'bold',
  marginTop: 0,
})

export const BetweenPhoneNumbers = styled('span')({
  marginLeft: '4px',
  marginRight: '4px',
  paddingTop: '4px',
})

export const UnitText = styled('span')({
  marginLeft: '4px',
  marginRight: '4px',
  paddingTop: '8px',
})

// テキストエリア
export const CustomTextArea = styled('textarea')({
  border: '1px solid #CDCFD1',
  borderRadius: '6px',
  padding: '0.5rem',
  width: '100%',
})

// ラジオボタン群を囲むラッパー
export const CustomRadioGroup = styled(Box)({
  display: 'flex',
  borderRadius: '6px',
  overflow: 'hidden',
  border: '1px solid #b6b6b6',
  width: '189px',
  maxWidth: '100%',

  label: {
    height: '50px',
    color: '#b6b6b6',
    width: '100%',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid #b6b6b6',
  },

  'label:last-child': {
    borderRight: '0',
  },

  'input:checked + label': {
    background: palette.header.background,
    fontWeight: '500',
    color: '#fff',
  },
})

// ラジオボタン
export const CustomRadioButton = styled('input')({
  display: 'none',
  width: '100%',
  margin: 0,
  '&$checked +label': {
    backgroundColor: palette.background.main,
  },
})

export const CustomCheckBoxForm = styled(Box)({
  display: 'flex',
  borderRadius: '6px',
  overflow: 'hidden',
  border: '1px solid #b6b6b6',
  width: '189px',
  maxWidth: '100%',
  marginRight: '8px',

  label: {
    height: '50px',
    width: '100%',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid #b6b6b6',
  },

  'label:last-child': {
    borderRight: '0',
  },

  'input:checked + label': {
    background: palette.header.background,
    fontWeight: '500',
    color: '#fff',
  },
})

export const LargeCheckBoxForm = styled(CustomCheckBoxForm)({
  width: '1000px',

  label: {
    color: '#000',
    justifyContent: 'flex-start',
  },

  'label:last-child': {
    borderRight: '0',
  },

  'input:checked + label': {
    background: palette.header.background,
    fontWeight: '500',
  },
})

export const CustomCheckBoxFormShort = styled(CustomCheckBoxForm)({
  width: '120px',
})

export const CustomSelect = styled(Select)({
  width: '378px',
  maxWidth: '100%',
  color: palette.text.black.main,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
})

export const CustomSelectShort = styled(Select)({
  width: '150px',
  maxWidth: '100%',
  color: palette.text.black.main,
})

export const CustomSelectMiddle = styled(Select)({
  width: '250px',
  maxWidth: '100%',
  color: palette.text.black.main,
})

// react-selectのoptionsに配列を渡す際に、先頭に結合して使用
export const WithEmptyOption = (hashList) =>
  [{ label: '全て', value: undefined }].concat(hashList)

// DatePicker
interface DatePickerProps extends InputProps {
  inputRef?: React.Ref<HTMLInputElement>
  onClick?: React.MouseEventHandler<HTMLInputElement>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  value?: string | ReadonlyArray<string> | number
  selected?: string | Date
  defaultToday?: boolean
  midium?: boolean
  dateFormat?: string
}

// SimpleTimePicker
interface SimpleTimePickerProps extends InputProps {
  inputRef?: React.Ref<HTMLInputElement>
  onClick?: React.MouseEventHandler<HTMLInputElement>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  value?: string | ReadonlyArray<string> | number
  selected?: string | Date
  timeIntervals?: number
}

export const DatePicker = (props: DatePickerProps) => {
  registerLocale('ja', ja)
  const ReactDatePickerElement = styled(ReactDatePicker)({
    border: '1px solid #CDCFD1',
    borderRadius: '6px',
    height: '2rem',
    width: props.midium ? '160px' : '378px',
    maxWidth: '100%',
    padding: '0 0.5rem',
  })
  const DatePickerHeader = styled('div')({
    display: 'flex',
    justifyContent: 'center',
  })
  const YearMonthText = styled('div')({
    margin: '0 60px',
    paddingTop: '3px',
    fontWeight: '700',
    fontSize: '.944rem',
  })
  const ChangeMonthButton = styled('div')({
    fontSize: '18px',
    fontWeight: '500',
    color: palette.text.gray.pale,
    cursor: 'pointer',
  })

  const defaultDate = props.defaultToday === false ? undefined : new Date()
  return (
    <ReactDatePickerElement
      {...props}
      selected={props.selected !== undefined ? props.selected : defaultDate}
      locale="ja"
      dateFormat="yyyy-MM-dd"
      placeholderText="年/月/日"
      autoComplete="off"
      renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
        <DatePickerHeader>
          <ChangeMonthButton onClick={decreaseMonth}>{'<'}</ChangeMonthButton>
          <YearMonthText>
            {date.getFullYear()}年{date.getMonth() + 1}月
          </YearMonthText>
          <ChangeMonthButton onClick={increaseMonth}>{'>'}</ChangeMonthButton>
        </DatePickerHeader>
      )}
    />
  )
}

export const YearMounthDatePicker = (props: DatePickerProps) => {
  registerLocale('ja', ja)
  const ReactDatePickerElement = styled(ReactDatePicker)({
    border: '1px solid #CDCFD1',
    borderRadius: '6px',
    height: '36px',
    width: '160px',
    maxWidth: '100%',
    padding: '0 0.5rem',
  })
  return (
    <ReactDatePickerElement
      {...props}
      locale="ja"
      dateFormat={props.dateFormat ? props.dateFormat : 'yyyy-MM'}
      placeholderText="年/月"
      showMonthYearPicker
      autoComplete="off"
    />
  )
}

export const YearMounthDayDatePicker = (props: DatePickerProps) => {
  registerLocale('ja', ja)
  const ReactDatePickerElement = styled(ReactDatePicker)({
    border: '1px solid #CDCFD1',
    borderRadius: '6px',
    height: '36px',
    width: '180px',
    maxWidth: '100%',
    padding: '0 0.5rem',
  })
  return (
    <ReactDatePickerElement
      {...props}
      locale="ja"
      dateFormat="yyyy-MM-dd"
      placeholderText="年/月/日"
      autoComplete="off"
    />
  )
}

export const SimpleTimePicker = (props: SimpleTimePickerProps) => {
  registerLocale('ja', ja)
  const SimpleTimePickerElement = styled(ReactDatePicker)({
    border: '1px solid #CDCFD1',
    borderRadius: '6px',
    height: '2rem',
    width: '100px',
    maxWidth: '100%',
    padding: '0 0.5rem',
  })

  return (
    <SimpleTimePickerElement
      {...props}
      locale="ja"
      dateFormat="HH:mm"
      placeholderText="時:分"
      autoComplete="off"
      timeIntervals={props.timeIntervals ? props.timeIntervals : 60}
      timeCaption="時刻"
      showTimeSelectOnly
      showTimeSelect
    />
  )
}

export const YearMonthPickerButton = (props: { onSelect: (e) => void }) => {
  return (
    <ReactDatePicker
      showMonthYearPicker
      autoComplete="off"
      locale="ja"
      onSelect={(value) => {
        props.onSelect(value)
      }}
      customInput={<Button>月で指定</Button>}
    />
  )
}

export function CustomSubmit(props: { children: React.ReactNode }) {
  const CustomSubmitElement = styled(IconLabelButtons)({
    background: '#0050B2',
    color: 'white',
    border: 'none',
    padding: '10px',
    borderRadius: '5px',
    fontWeight: 'bold',
    marginRight: '10px',
    height: '40px',
  })
  return (
    <CustomSubmitElement type="submit">{props.children}</CustomSubmitElement>
  )
}

const DropZoneElement = styled('div')({
  border: '1px dashed #b6b6b6',
  textAlign: 'center',
  cursor: 'pointer',
})
export const CustomDropZone = (props: DropzoneOptions) => {
  const { getRootProps, getInputProps } = useDropzone({ ...props })
  return (
    <DropZoneElement {...getRootProps()}>
      <input {...getInputProps()} />
      <p>
        アップロードするファイルを選択するか、このエリアにドラッグ&ドロップしてください
      </p>
    </DropZoneElement>
  )
}

// 事故報告書の動画添付専用のDropZoneコンポーネント
const VideoDropZoneElement = styled(DropZoneElement)({
  'margin-bottom': '10px',
})
export const CustomVideoDropZone = (props: DropzoneOptions) => {
  const { getRootProps, getInputProps } = useDropzone({ ...props })
  return (
    <VideoDropZoneElement {...getRootProps()}>
      <input {...getInputProps()} />
      <p>
        アップロードするファイルを選択するか、このエリアにドラッグ&ドロップしてください
      </p>
    </VideoDropZoneElement>
  )
}

const FileInputLabelElement = styled('label')({
  borderRadius: '5px',
  background: 'white',
  fontWeight: 'bold',
  padding: '6px 8px',
  backgroundColor: 'white',
  cursor: 'pointer',
  border: '1px solid #CDCFD1',
  fontSize: '14px',
  textAlign: 'center',
  color: palette.text.black.main,
})

const FileInputElement = styled('input')({
  display: 'none',
})

export const CustomFileInput = (props: {
  onChange: React.ChangeEventHandler<HTMLInputElement>
}) => {
  return (
    <FileInputLabelElement>
      ファイルを選択
      <FileInputElement
        onChange={props.onChange}
        type="file"
        accept={'image/*'}
      />
    </FileInputLabelElement>
  )
}

export const CustomCsvImport = (props: {
  onChange: React.ChangeEventHandler<HTMLInputElement>
  label?: string
}) => {
  const fileInputRef = React.useRef(null)
  const handleClick = () => {
    fileInputRef.current.click()
  }

  return (
    <>
      <ApplyButton onClick={handleClick}>
        {props.label ? props.label : 'CSV取込'}
      </ApplyButton>
      <FileInputElement
        onChange={props.onChange}
        type="file"
        accept={'text/csv'}
        ref={fileInputRef}
      ></FileInputElement>
    </>
  )
}

const AvatarElement = styled('div')({
  width: '100px',
  height: '100px',
  borderRadius: '50%',
  overflow: 'hidden',
  border: '1px solid #CDCFD1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const AvatarSquareElementStyle = {
  width: '160px',
  height: '160px',
  borderRadius: '8px',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const AvatarSquareElement = styled('div')(AvatarSquareElementStyle)

const AvatarImageElement = styled('img')({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
})

const AvatarSquarePlaceholerStyle = {
  width: '100%',
  height: '100%',
  background: '#CDCFD1',
}

const AvatarSquarePlaceholer = styled('div')(AvatarSquarePlaceholerStyle)

export const Avatar = (props: { src: string }) => {
  return (
    <AvatarElement>
      {props.src ? (
        <AvatarImageElement src={props.src} />
      ) : (
        <AvatarImageElement src={ProfileImage} />
      )}
    </AvatarElement>
  )
}

export const AvatarSquare = (props: {
  src: string
  style?: React.CSSProperties
  placeholderStyle?: React.CSSProperties
}) => {
  return (
    <AvatarSquareElement
      style={{ ...AvatarSquareElementStyle, ...props.style }}
    >
      {props.src ? (
        <AvatarImageElement src={props.src} />
      ) : (
        <AvatarSquarePlaceholer
          style={{ ...AvatarSquarePlaceholerStyle, ...props.placeholderStyle }}
        />
      )}
    </AvatarSquareElement>
  )
}

export function ResetButton(props: ButtonProps) {
  const ResetButtonElement = styled(IconLabelButtons)({
    background: 'white',
    color: palette.text.black.main,
    border: 'none',
    padding: '10px',
    borderRadius: '5px',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginRight: '10px',
    height: '40px',
    '&:hover': {
      backgroundColor: palette.background.grey,
    },
  })
  return (
    <ResetButtonElement {...props}>
      <CancelIcon fontSize={'small'} />
      {props.children}
    </ResetButtonElement>
  )
}

export const CustomDateSelect = (props: {
  formKeys: {
    year: FormKeys
    month: FormKeys
    day: FormKeys
  }
  /**
   * - FIXME: FormKeysだとコンパイルが通らないのでanyを使っている
   * - 実際に渡しているkeyの型がFormKeysなので問題はない認識 [comment](https://github.com/X-Mile/track-manager/pull/707#discussion_r1012938777)
   */
  control: Control<any>
  required?: boolean
  defaultToday?: boolean
  yearsFrom2000?: boolean
  defaultValue?: {
    year?: number
    month?: number
    day?: number
  }
}) => {
  const rules = {
    required: props.required !== undefined ? props.required : false,
  }
  const { defaultValue, defaultToday = true } = props
  const today = new Date(Date.now())
  const years = props.yearsFrom2000 ? yearRangeFrom2000 : yearRange

  return (
    <FlexBox alignItems="center">
      <FlexBox flexWrap="wrap">
        <FlexBox>
          <Controller
            name={props.formKeys.year}
            control={props.control}
            defaultValue={
              defaultValue?.year || (defaultToday && today.getFullYear())
            }
            render={({ field: { onChange, value } }) => (
              <CustomSelectShort
                options={years}
                value={years.find((c) => c.value === value)}
                onChange={(option: ReactSelectOptionProps) =>
                  onChange(option.value)
                }
                placeholder="未選択"
                sx={{
                  maxWidth: '120px',
                  [theme.breakpoints.down('md')]: { marginBottom: '8px' },
                }}
              />
            )}
            rules={rules}
          />
          <UnitText sx={{ marginRight: '8px' }}>年</UnitText>
        </FlexBox>
        <FlexBox>
          <Controller
            name={props.formKeys.month}
            control={props.control}
            defaultValue={
              defaultValue?.month || (defaultToday && today.getMonth() + 1)
            }
            render={({ field: { onChange, value } }) => (
              <CustomSelectShort
                options={monthRange}
                value={monthRange.find((c) => c.value === value)}
                onChange={(option: ReactSelectOptionProps) =>
                  onChange(option.value)
                }
                placeholder="未選択"
                sx={{
                  maxWidth: '120px',
                  [theme.breakpoints.down('md')]: { marginBottom: '8px' },
                }}
              />
            )}
            rules={rules}
          />
          <UnitText sx={{ marginRight: '8px' }}>月</UnitText>
        </FlexBox>
        <FlexBox>
          <Controller
            name={props.formKeys.day}
            control={props.control}
            defaultValue={
              defaultValue?.day || (defaultToday && today.getDate())
            }
            render={({ field: { onChange, value } }) => (
              <CustomSelectShort
                options={dayRange}
                value={dayRange.find((c) => c.value === value)}
                onChange={(option: ReactSelectOptionProps) =>
                  onChange(option.value)
                }
                placeholder="未選択"
                sx={{
                  maxWidth: '120px',
                  [theme.breakpoints.down('md')]: { marginBottom: '8px' },
                }}
              />
            )}
            rules={rules}
          />
          <UnitText>日</UnitText>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export const CustomDateSelectWithoutDay = (props: {
  formKeys: {
    year: FormKeys
    month: FormKeys
  }
  control: Control<any>
  required?: boolean
}) => {
  const rules = {
    required: props.required !== undefined ? props.required : false,
  }

  return (
    <FlexBox alignItems="center">
      <FlexBox flexWrap="wrap">
        <Controller
          name={props.formKeys.year}
          control={props.control}
          render={({ field: { onChange, value } }) => (
            <CustomSelectShort
              options={yearRange}
              value={yearRange.find((c) => c.value === value)}
              onChange={(option: ReactSelectOptionProps) =>
                onChange(option.value)
              }
              placeholder="未選択"
              sx={{ maxWidth: '120px' }}
            />
          )}
          rules={rules}
        />
        <UnitText sx={{ marginRight: '8px' }}>年</UnitText>
        <Controller
          name={props.formKeys.month}
          control={props.control}
          render={({ field: { onChange, value } }) => (
            <CustomSelectShort
              options={monthRange}
              value={monthRange.find((c) => c.value === value)}
              onChange={(option: ReactSelectOptionProps) =>
                onChange(option.value)
              }
              placeholder="未選択"
              sx={{ maxWidth: '120px' }}
            />
          )}
          rules={rules}
        />
        <UnitText sx={{ marginRight: '8px' }}>月</UnitText>
      </FlexBox>
    </FlexBox>
  )
}

export const CustomDateSelectOnlyYear = (props: {
  formKey: string
  control: Control<any>
  required?: boolean
  defaultThisYear?: boolean
  fiscalYearSelect?: boolean
  yearsFrom2000?: boolean
  isDisabled?: boolean
}) => {
  const rules = {
    required: props.required !== undefined ? props.required : false,
  }
  const { defaultThisYear = true } = props
  const today = new Date(Date.now())
  const unitText = props.fiscalYearSelect ? '年度' : '年'
  const years = props.yearsFrom2000 ? yearRangeFrom2000 : yearRange

  return (
    <FlexBox alignItems="center">
      <FlexBox flexWrap="wrap">
        <Controller
          name={props.formKey}
          control={props.control}
          defaultValue={defaultThisYear && today.getFullYear()}
          render={({ field: { onChange, value } }) => (
            <CustomSelectShort
              options={years}
              value={years.find((c) => c.value === value)}
              onChange={(option: ReactSelectOptionProps) =>
                onChange(option.value)
              }
              placeholder="未選択"
              sx={{ maxWidth: '120px' }}
              isDisabled={props.isDisabled}
            />
          )}
          rules={rules}
        />
        <UnitText sx={{ marginRight: '8px' }}>{unitText}</UnitText>
      </FlexBox>
    </FlexBox>
  )
}

export const CustomHourMinSelect = (props: {
  formKeys: {
    hour: FormKeys
    minutes: FormKeys
  }
  control: Control<any>
  required?: boolean
  defaultNow?: boolean
  defaultValue?: {
    hour?: number
    minutes?: number
  }
}) => {
  const rules = {
    required: props.required !== undefined ? props.required : false,
  }
  const { defaultValue, defaultNow = true } = props
  const today = new Date(Date.now())

  return (
    <FlexBox alignItems="center">
      <FlexBox flexWrap="wrap">
        <FlexBox>
          <Controller
            name={props.formKeys.hour}
            control={props.control}
            defaultValue={
              defaultValue?.hour || (defaultNow && today.getHours())
            }
            render={({ field: { onChange, value } }) => (
              <CustomSelectShort
                options={hourRange}
                value={hourRange.find((c) => c.value === value)}
                onChange={(option: ReactSelectOptionProps) =>
                  onChange(option.value)
                }
                placeholder="未選択"
                sx={{
                  maxWidth: '120px',
                  [theme.breakpoints.down('md')]: { marginBottom: '8px' },
                }}
              />
            )}
            rules={rules}
          />
          <UnitText sx={{ marginRight: '8px' }}>時</UnitText>
        </FlexBox>
        <FlexBox>
          <Controller
            name={props.formKeys.minutes}
            control={props.control}
            defaultValue={
              defaultValue?.minutes || (defaultNow && today.getMinutes())
            }
            render={({ field: { onChange, value } }) => (
              <CustomSelectShort
                options={minutesRange}
                value={minutesRange.find((c) => c.value === value)}
                onChange={(option: ReactSelectOptionProps) =>
                  onChange(option.value)
                }
                placeholder="未選択"
                sx={{
                  maxWidth: '120px',
                  [theme.breakpoints.down('md')]: { marginBottom: '8px' },
                }}
              />
            )}
            rules={rules}
          />
          <UnitText sx={{ marginRight: '8px' }}>分</UnitText>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export const CustomPhoneNumberInput = (props: {
  formKeys: {
    first: FormKeys
    second: FormKeys
    third: FormKeys
  }
  register: UseFormRegister<any>
  required?: boolean
}) => {
  const options: RegisterOptions = {
    required: props.required !== undefined ? props.required : false,
  }
  if (props.required == true) {
    const validation = {
      format: validatePhoneNumberInput,
    }
    options.validate = validation
  }

  return (
    <FlexBox alignItems="center">
      <FlexBox flexWrap="wrap" alignItems="center">
        <CustomInput
          {...props.register(props.formKeys.first, options)}
          type="text"
          sx={{ maxWidth: '100px' }}
        />
        <UnitText>-</UnitText>
        <CustomInput
          {...props.register(props.formKeys.second, options)}
          type="text"
          sx={{ maxWidth: '100px' }}
        />
        <UnitText>-</UnitText>
        <CustomInput
          {...props.register(props.formKeys.third, options)}
          type="text"
          sx={{ maxWidth: '100px' }}
        />
      </FlexBox>
    </FlexBox>
  )
}

// エラーメッセージ
export const ErrorMessage = styled('label')({
  color: palette.error.main,
})

export const FORM_VALIDATIONS = {
  KATAKANA: /^[ァ-ヶー]*$/,
}

type ConvertibleCustomInputProps = {
  mode: FormMode
  formKey: FormKeys
  register: UseFormRegister<any>
  getValues: UseFormGetValues<any>
  required?: boolean
  suffix?: string
  sx?: SxProps<Theme>
}

const ConvertibleTextShow = styled('p')({
  color: palette.text.black.main,
  margin: '4px 0',
})

export const ConvertibleCustomInput: React.FC<ConvertibleCustomInputProps> = (
  props
) => {
  const options: RegisterOptions = {
    required: props.required !== undefined ? props.required : false,
  }
  const value = React.useMemo(() => {
    return `${props.getValues(props.formKey)}${props.suffix ?? ''}`
  }, [props.mode])

  if (props.mode == 'edit') {
    return (
      <FlexBox alignItems="center" sx={props.sx}>
        <CustomInputUnderline
          {...props.register(props.formKey, { ...options })}
        />
        {props.suffix ? ` ${props.suffix}` : ''}
      </FlexBox>
    )
  } else {
    return (
      <Box sx={props.sx}>
        <ConvertibleTextShow>
          {value && value != 'null' ? value.toUpperCase() : '未入力'}
        </ConvertibleTextShow>
      </Box>
    )
  }
}

type ConvertibleCustomDateSelectProps = {
  mode: FormMode
  formKeys: {
    year: FormKeys
    month: FormKeys
    day: FormKeys
  }
  control: Control<any>
  getValues: UseFormGetValues<any>
  required?: boolean
}

export const ConvertibleCustomDateSelect: React.FC<
  ConvertibleCustomDateSelectProps
> = (props) => {
  const value = React.useMemo(() => {
    const year = props.getValues(props.formKeys.year)
    const month = props.getValues(props.formKeys.month)
    const day = props.getValues(props.formKeys.day)
    if (!year || !month || !day) return '未入力'
    return `${year}年${month}月${day}日`
  }, [props.mode])

  if (props.mode == 'edit') {
    return (
      <CustomDateSelect
        formKeys={props.formKeys}
        control={props.control}
        required={props.required}
      />
    )
  } else {
    return (
      <Box width="150px">
        <ConvertibleTextShow>{value || '未入力'}</ConvertibleTextShow>
      </Box>
    )
  }
}

type ConvertibleCustomDateSelectWithoutDayProps = {
  mode: FormMode
  formKeys: {
    year: FormKeys
    month: FormKeys
  }
  control: Control<any>
  getValues: UseFormGetValues<any>
  required?: boolean
}

export const ConvertibleCustomDateSelectWithoutDay: React.FC<
  ConvertibleCustomDateSelectWithoutDayProps
> = (props) => {
  const value = React.useMemo(() => {
    const year = props.getValues(props.formKeys.year)
    const month = props.getValues(props.formKeys.month)
    if (!year || !month) return '未入力'
    return `${year}年${month}月`
  }, [props.mode])

  if (props.mode == 'edit') {
    return (
      <CustomDateSelectWithoutDay
        formKeys={props.formKeys}
        control={props.control}
        required={props.required}
      />
    )
  } else {
    return (
      <Box width="150px">
        <ConvertibleTextShow>{value || '未入力'}</ConvertibleTextShow>
      </Box>
    )
  }
}

type ConvertibleCustomSelectProps = {
  mode: FormMode
  formKey: FormKeys
  control: Control<any>
  getValues: UseFormGetValues<any>
  options: ReactSelectOptionProps[]
  required?: boolean
}

export const ConvertibleCustomSelect: React.FC<ConvertibleCustomSelectProps> = (
  props
) => {
  const rules = {
    required: props.required !== undefined ? props.required : false,
  }
  const value = React.useMemo(() => {
    const v = props.getValues(props.formKey)
    const label = props.options.find((g) => {
      return g.value == v
    })?.label
    if (!label) return '未入力'
    return label
  }, [props.options])

  if (props.mode == 'edit') {
    return (
      <Controller
        name={props.formKey}
        control={props.control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <CustomSelectShort
            options={props.options}
            value={props.options.find((c) => c.value === value)}
            onChange={(option: ReactSelectOptionProps) =>
              onChange(option.value)
            }
            placeholder="未選択"
            sx={{ width: '150px' }}
          />
        )}
      />
    )
  } else {
    return (
      <Box maxWidth="150px">
        <ConvertibleTextShow>{value || '未入力'}</ConvertibleTextShow>
      </Box>
    )
  }
}

type ConvertibleCustomPhoneNumberSelectProps = {
  mode: FormMode
  formKeys: {
    first: FormKeys
    second: FormKeys
    third: FormKeys
  }
  register: UseFormRegister<any>
  getValues: UseFormGetValues<any>
  required?: boolean
}

export const ConvertibleCustomPhoneNumberSelect: React.FC<
  ConvertibleCustomPhoneNumberSelectProps
> = (props) => {
  const value = React.useMemo(() => {
    const first = props.getValues(props.formKeys.first)
    const second = props.getValues(props.formKeys.second)
    const third = props.getValues(props.formKeys.third)
    if (!first || !second || !third) return '未入力'
    return `${first}-${second}-${third}`
  }, [props.mode])

  if (props.mode == 'edit') {
    return (
      <CustomPhoneNumberInput
        formKeys={props.formKeys}
        register={props.register}
        required={props.required}
      ></CustomPhoneNumberInput>
    )
  } else {
    return (
      <Box width="150px">
        <ConvertibleTextShow>{value || '未入力'}</ConvertibleTextShow>
      </Box>
    )
  }
}

type ConvertibleCustomDropZoneProps = {
  onDrop: (acceptedFiles: File[]) => void
  control: Control<any>
  mode: FormMode
  getValues: UseFormGetValues<any>
  formKey: FormKeys
  files: File[]
  onCancelIconClick?: (index: number) => void
}

const ConvertibleCustomDropZoneListItem = styled('li')({
  listStyle: 'none',
  borderBottom: `1px solid ${palette.border.pale}`,
  padding: '12px 24px',
  width: '100%',
})

type ConvertibleCustomDropZoneListProps = {
  files: File[]
  onCancelIconClick: (index: number) => void
  mode: FormMode
}

const ConvertibleCustomDropZoneList: React.FC<
  ConvertibleCustomDropZoneListProps
> = (props) => {
  return (
    <List disablePadding={true}>
      {props.mode == 'show' && props.files.length == 0 ? (
        <ConvertibleTextShow>ファイルがありません</ConvertibleTextShow>
      ) : (
        props.files.map((file, i) => {
          return (
            <ConvertibleCustomDropZoneListItem key={`file-${i}`}>
              <FlexBox alignItems="center">
                <DescriptionOutlinedIcon
                  sx={{ marginRight: '8px' }}
                ></DescriptionOutlinedIcon>
                <ConvertibleTextShow>
                  <a
                    href={URL.createObjectURL(file)}
                    download={decodeURI(file.name)}
                  >
                    {decodeURI(file.name)}
                  </a>
                </ConvertibleTextShow>
                {props.mode == 'edit' && (
                  <IconButton
                    component="span"
                    onClick={() => props.onCancelIconClick(i)}
                  >
                    <CancelIcon />
                  </IconButton>
                )}
              </FlexBox>
            </ConvertibleCustomDropZoneListItem>
          )
        })
      )}
    </List>
  )
}

export const ConvertibleCustomDropZone: React.FC<
  ConvertibleCustomDropZoneProps
> = (props) => {
  if (props.mode == 'edit') {
    return (
      <>
        <Controller
          render={() => (
            <CustomDropZone
              multiple={true}
              onDrop={props.onDrop}
              accept={'image/*'}
            />
          )}
          name={props.formKey}
          control={props.control}
        />
        <ConvertibleCustomDropZoneList
          files={props.files}
          onCancelIconClick={props.onCancelIconClick}
          mode={props.mode}
        ></ConvertibleCustomDropZoneList>
      </>
    )
  } else {
    return (
      <ConvertibleCustomDropZoneList
        files={props.files}
        onCancelIconClick={props.onCancelIconClick}
        mode={props.mode}
      ></ConvertibleCustomDropZoneList>
    )
  }
}

type ConvertibleCustomCheckBoxProps = {
  control: Control<any>
  items: { label: string; value: string }[]
  baseKey: FormKeys
  mode: FormMode
  showText: string
}

const ConvertibleCustomCheckBoxInput = styled('input')({
  marginRight: '8px',
})

export const ConvertibleCustomCheckBox: React.FC<
  ConvertibleCustomCheckBoxProps
> = (props) => {
  if (props.mode == 'edit') {
    return (
      <FlexBox flexWrap="wrap" maxWidth="600px">
        {props.items.map((item, index) => (
          <Controller
            key={`convertible-custom-checkbox-${index}`}
            name={`${props.baseKey}.${item.value}`}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <CustomCheckBoxFormShort sx={{ marginBottom: '10px' }}>
                <label>
                  <ConvertibleCustomCheckBoxInput
                    type="checkbox"
                    checked={value as boolean}
                    onChange={() => onChange(!value)}
                  />
                  {item.label}
                </label>
              </CustomCheckBoxFormShort>
            )}
          />
        ))}
      </FlexBox>
    )
  } else {
    return (
      <ConvertibleTextShow>{props.showText || '未入力'}</ConvertibleTextShow>
    )
  }
}

type ConvertibleCustomTextAreaProps = {
  formKey: FormKeys
  mode: FormMode
  getValues: UseFormGetValues<any>
  register: UseFormRegister<any>
}

export const ConvertibleCustomTextArea: React.FC<
  ConvertibleCustomTextAreaProps
> = (props) => {
  const value = React.useMemo(() => {
    return props.getValues(props.formKey)
  }, [])
  if (props.mode == 'edit') {
    return (
      <CustomTextArea
        {...props.register(props.formKey)}
        sx={{ minHeight: '200px' }}
      ></CustomTextArea>
    )
  } else {
    return <ConvertibleTextShow>{value || '未入力'}</ConvertibleTextShow>
  }
}

type ConvertibleCustomRadioGroupProps = {
  formKey: FormKeys
  mode: FormMode
  items: [string, { value: number | string; label: string }][]
  getValues: UseFormGetValues<any>
  register: UseFormRegister<any>
}

export const ConvertibleCustomRadioGroup: React.FC<
  ConvertibleCustomRadioGroupProps
> = (props) => {
  const selectedValue = React.useMemo(() => {
    return props.getValues(props.formKey)
  }, [])
  const selectedText = props.items.map((item) => {
    const obj = item[1]
    if (selectedValue === String(obj.value)) {
      return obj.label
    }
  })

  if (props.mode == 'edit') {
    return (
      <CustomRadioGroup>
        {props.items.map(([key, obj]) => (
          <React.Fragment key={key}>
            <CustomRadioButton
              {...props.register(props.formKey)}
              id={`${props.formKey}_${obj.value}`}
              value={obj.value}
              type="radio"
            />
            <label htmlFor={`${props.formKey}_${obj.value}`}>{obj.label}</label>
          </React.Fragment>
        ))}
      </CustomRadioGroup>
    )
  } else {
    return (
      <ConvertibleTextShow>
        {selectedText.filter(Boolean).length > 0 ? selectedText : '未入力'}
      </ConvertibleTextShow>
    )
  }
}

const IconWrapper = styled('span')({
  background: 'transparent',
  borderRadius: '9999px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '22px',
  height: '22px',
  padding: '4px',
  cursor: 'pointer',
})

type WithDescriptionTooltipIconProps = {
  text: React.ReactNode | string
}

export const WithDescriptionTooltipIcon: React.FC<
  WithDescriptionTooltipIconProps
> = (props) => {
  return (
    <span
      style={{ display: 'flex', alignItems: 'center', marginBottom: '2px' }}
    >
      {props.children}
      <Tooltip title={props.text} sx={{ marginLeft: '8px' }}>
        <IconWrapper>
          <InfoIcon
            sx={{ color: palette.info.main, fontSize: '22px' }}
          ></InfoIcon>
        </IconWrapper>
      </Tooltip>
    </span>
  )
}

export function setUnsubscribedAlert(text: string) {
  return (
    <div>
      <Alert severity="error">
        <AlertTitle>{text}</AlertTitle>
        <h3>
          詳しくはお電話（03-6161-6018）もしくは、
          <a href={FORM_LINK} target="_blank" rel="noopener noreferrer">
            問い合わせフォーム
          </a>
          からお問合せください
        </h3>
        <p>
          ※電話受付時間：土日祝を除く平日10:00〜18:00
          <br />
          ※フォーム問い合わせ後、数営業日以内にご返信させて頂きます。
        </p>
      </Alert>
    </div>
  )
}

export function FeeComponent(props: { control; name: string; sx?: SxProps }) {
  const { name, control } = props
  return (
    <Controller
      render={({ field }) => (
        <NumberFormat
          sx={{ maxWidth: '200px', ...props.sx }}
          {...field}
          customInput={CustomInput}
          thousandSeparator={true}
          prefix={'¥ '}
          placeholder={'¥ 0'}
        />
      )}
      name={name}
      control={control}
    />
  )
}
