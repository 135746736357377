import * as React from 'react'
import { styled } from '@mui/styles'
import { Box, Grid } from '@mui/material'
import { TextBold } from 'components/atoms/TextComponents'
import { HEAD_TEXTS } from 'commons/constants'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import LiveTvIcon from '@mui/icons-material/LiveTv'
import MessageIcon from '@mui/icons-material/Message'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { FlexBox } from 'components/atoms/BoxComponents'
import { createEducationLogs } from 'components/apis/educations'
import { UserContext } from 'providers/UserProvider'
import CommonAccountModal from './CommonAccountModal'
import { palette } from 'components/theme'
import CustomEducationDocumentModal from './CustomEducationDocumentModal'
import { IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import CustomEducationVideoPlayerModal from './CustomEducationVideoPlayerModal'

const MonthlyEducationContent = styled('div')({
  height: '100%',
  width: 'auto',
  left: '0px',
  top: '0px',
  borderRadius: '12px',
  padding: '24px',
})

const EducationBackGround = styled(Box)({
  boxSizing: 'border-box',
  borderRadius: '6px',
  width: '100%',
  height: '100%',
  border: '1px solid #E6E7E8',
  padding: '15px',
})

const AttendedText = styled('div')({
  textAlign: 'center',
})

type Props = {
  education: Education
  isShowComment?: boolean
}

type DetailProps = {
  education: Education
  isShowComment: boolean
  onClickDocument: (url: string) => void
  onClickTest: (url: string) => void
  onClickVideo: () => void
}

function EducationContentDetail({
  education,
  isShowComment,
  onClickDocument,
  onClickVideo,
}: DetailProps) {
  const user = React.useContext(UserContext)
  const commentUrl = user.admin
    ? `/admin/educations/${education.id}/comments/new`
    : `/educations/${education.id}/comments/new`

  return (
    <FlexBox
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      height="90%"
    >
      <Box sx={{ height: '90px', position: 'relative', width: '100%' }}>
        <TextBold>
          {education.chapter ? `${education.chapter}. ` : ''}
          {education.title}
        </TextBold>
        {user.admin && (
          <IconButton
            sx={{ position: 'absolute', right: 0, top: 0 }}
            onClick={() =>
              (window.location.href = `/admin/educations/${education.id}/edit`)
            }
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
      <Box>
        <Box mb={2}>
          <ApplyButton
            sx={{ width: '150px' }}
            startIcon={<PictureAsPdfIcon />}
            onClick={() => onClickDocument(education.documentPath)}
            disabled={!education.fileUrls || education.fileUrls.length === 0}
          >
            {HEAD_TEXTS.DOCUMENT_VIEW}
          </ApplyButton>
        </Box>
        <Box mb={2}>
          <ApplyButton
            sx={{ width: '150px' }}
            startIcon={<LiveTvIcon />}
            onClick={() => onClickVideo()}
            disabled={
              !education.customVideos || education.customVideos.length === 0
            }
          >
            {HEAD_TEXTS.VIDEO_VIEW}
          </ApplyButton>
        </Box>
        <Box mb={2}>
          <ApplyButton
            sx={{ width: '150px' }}
            startIcon={<MessageIcon />}
            onClick={() => window.open(commentUrl, '_blank')}
            disabled={!education.id}
          >
            {HEAD_TEXTS.COMMENT}
          </ApplyButton>
        </Box>
      </Box>
    </FlexBox>
  )
}

export default function CustomEducationContent({
  education,
  isShowComment = true,
}: Props) {
  const user = React.useContext(UserContext)
  const [isDocumentModalOpen, setIsDocumentModalOpen] =
    React.useState<boolean>(false)
  const [isVideoPlayerModalOpen, setIsVideoPlayerModalOpen] =
    React.useState<boolean>(false)
  const [isCommonAccountModalOpen, setIsCommonAccountModalOpen] =
    React.useState<boolean>(false)
  const [queue, setQueue] =
    React.useState<{ key: string; params?: { url?: string } }>()

  function onClickDocument(url) {
    if (user.isDriverCommonAccount) {
      setQueue({
        key: 'EducationContent.onClickDocument',
        params: {
          url: url,
        },
      })
      setIsCommonAccountModalOpen(true)
    } else {
      createEducationLogs(education.id, {
        contentType: 'document',
        userId: user.id,
      }).then(() => {
        showDocumentModal()
      })
    }
  }

  function onClickTest(url) {
    if (user.isDriverCommonAccount) {
      setQueue({
        key: 'EducationContent.onClickTest',
        params: {
          url: url,
        },
      })
      setIsCommonAccountModalOpen(true)
    } else {
      window.open(url, '_blank').focus()
    }
  }

  function onClickVideo() {
    if (user.isDriverCommonAccount) {
      setQueue({ key: 'EducationContent.onClickVideo' })
      setIsCommonAccountModalOpen(true)
    } else {
      showVideoModal()
    }
  }

  function showDocumentModal() {
    setIsDocumentModalOpen(true)
  }

  function showVideoModal() {
    setIsVideoPlayerModalOpen(true)
  }

  function onComplete({ id }: { id: string }) {
    switch (queue.key) {
      case 'EducationContent.onClickDocument':
        {
          const { url } = queue.params
          createEducationLogs(education.id, {
            contentType: 'document',
            userId: parseInt(id),
          })
            .then(() => setIsCommonAccountModalOpen(false))
            .then(() => {
              window.open(url, '_blank').focus()
            })
        }
        break
      case 'EducationContent.onClickVideo':
        {
          showVideoModal()
          setIsCommonAccountModalOpen(false)
        }
        break
      case 'EducationContent.onClickTest':
        {
          const { url } = queue.params
          setIsCommonAccountModalOpen(false)
          window.open(url, '_blank').focus()
        }
        break
      default:
        break
    }
  }
  return (
    <Grid item xs={12} sm={6} md={6} lg={4} key={education.chapter}>
      <CommonAccountModal
        isModalOpen={isCommonAccountModalOpen}
        setIsModalOpen={setIsCommonAccountModalOpen}
        onComplete={onComplete}
      />
      <CustomEducationDocumentModal
        isModalOpen={isDocumentModalOpen}
        setIsModalOpen={setIsDocumentModalOpen}
        education={education}
      />
      <CustomEducationVideoPlayerModal
        isModalOpen={isVideoPlayerModalOpen}
        setIsModalOpen={setIsVideoPlayerModalOpen}
        education={education}
      />
      <MonthlyEducationContent>
        <EducationBackGround
          sx={{
            background: education.attended ? palette.disabled.main : 'white',
          }}
        >
          <EducationContentDetail
            education={education}
            isShowComment={isShowComment}
            onClickDocument={onClickDocument}
            onClickTest={onClickTest}
            onClickVideo={onClickVideo}
          />
          {education.attended && (
            <Box my={1}>
              <AttendedText>受講済み</AttendedText>
            </Box>
          )}
        </EducationBackGround>
      </MonthlyEducationContent>
    </Grid>
  )
}
