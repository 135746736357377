import * as React from 'react'
import { useForm } from 'react-hook-form'
import {
  CustomForm,
  CustomLabel,
  CustomTextArea,
  HeadingText,
  setUnsubscribedAlert,
} from 'components/atoms/FormComponents'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import {
  updateDriver,
  checkSubscribed,
  fetchDrivers,
} from 'components/apis/drivers'
import DriversProfile from 'components/organisms/admin/drivers/form_sections/profile/Profile'
import DriversFamilyProfile from 'components/organisms/admin/drivers/form_sections/family_profiles/FamilyProfiles'
import DriversChargedVehicles from 'components/organisms/admin/drivers/form_sections/charged_vehicles/ChargedVehicles'
import DriversCareer from 'components/organisms/admin/drivers/form_sections/career/Career'
import DriversLicense from 'components/organisms/admin/drivers/form_sections/license/License'
import DriversPersonalHistory from 'components/organisms/admin/drivers/form_sections/personal_history/PersonalHistory'
import { fetchS3ImagesV2, fetchS3ImageV2 } from 'commons/fetchS3Images'
import { toastOnError, toastOnSuccess } from 'commons/toaster'
import { useNavigate } from 'react-router-dom'
import EmergencyContact from 'components/organisms/admin/drivers/form_sections/emergency_contact/EmergencyContact'
import ProfileImage from 'components/organisms/admin/drivers/form_sections/profile_image/ProfileImage'
import AptitudeTestRecords from 'components/organisms/admin/drivers/form_sections/aptitude_test_records/AptitudeTestRecords'
import DirectionRecords from 'components/organisms/admin/drivers/form_sections/direction_records/DirectionRecords'
import ViolationHistory from 'components/organisms/admin/drivers/form_sections/violation_history/ViolationHistory'
import MedicalRecords from 'components/organisms/admin/drivers/form_sections/medical_records/MedicalRecords'
import DrivingExperiences from './form_sections/driving_experiences/DrivingExperiences'
import Insurances from './form_sections/insurance/Insurance'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Loading } from 'components/atoms/LoadingComponents'
import { useErrorHandler } from 'react-error-boundary'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { styled } from '@mui/styles'
import {
  DRIVER_LINK_LIST_ITEMS,
  LOCAL_STORAGE_PAGE_KEY,
  ALERT_TEXT,
} from 'commons/constants'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import FormSubmitSelectPageModal from 'components/atoms/FormSubmitSelectPageModal'
import ProcessingModal from 'components/atoms/ProcessingModal'
import { ShouldSubmitOnKeyPress } from 'commons/formUtils'
import DriversPersonnelChangeHistory from 'components/organisms/admin/drivers/form_sections/personnel_change_history/PersonnelChangeHistory'
import {
  getLocalStorageDisplayLimit,
  getLocalStorageSearchParams,
  getNextResourceId,
} from 'commons/table'
import { UserContext } from 'providers/UserProvider'
import { slackNotification } from 'commons/slackNotification'

interface Props {
  type: ProfileEvent
  data?: Driver
  companies?: Company[]
  offices?: Office[]
  roles?: Role[]
  drivers?: DriverSelectOption[]
  isXmileSystemAdminUser?: boolean
  isAdminUser?: boolean
}

export default function DriversForm(props: Props) {
  const navigate = useNavigate()
  const handleError = useErrorHandler()

  // 担当車両の乗車日のフォーマットを変更
  const chargedVehicleList = []
  props.data?.chargedVehicles?.map((item) => {
    const fromDate = item.fromDate ? new Date(item.fromDate) : null
    const toDate = item.toDate ? new Date(item.toDate) : null
    chargedVehicleList.push({ ...item, fromDate: fromDate, toDate: toDate })
  })
  const [profileImage, setProfileImage] = React.useState<File>()
  const [ledgerFiles, setLedgerFiles] = React.useState<File[]>([])
  const [licenseImages, setLicenseImages] = React.useState<File[]>([])
  const [aptitudeTestRecordsFiles, setAptitudeTestRecordsFiles] =
    React.useState<File[]>([])
  const [directionRecordsFiles, setDirectionRecordsFiles] = React.useState<
    File[]
  >([])
  const [medicalRecordsFiles, setMedicalRecordsFiles] = React.useState<File[]>(
    []
  )
  const [isLoading, setLoading] = React.useState(true)
  const [isModalShow, setModalShow] = React.useState(false)
  const [isProcessingModalShow, setIsProcessingModalShow] =
    React.useState(false)
  const user = React.useContext(UserContext)

  const mutation = useMutation({
    mutationFn: updateDriver,
    onSuccess: () => {
      setLoading(false)
    },
    onError: (error: AxiosError) => {
      setLoading(false)
      switch (error.response.status) {
        case 422:
          toastOnError(error.response.data.errors.join(','))
          // 422エラー時にslack通知する
          slackNotification(error, user)
          break
        default:
          handleError(error.response.status)
      }
    },
  })

  const mutationWithoutNavigation = useMutation({
    mutationFn: updateDriver,
    onSuccess: (response) => {
      const driverId = response.data.id
      setValue('id', driverId)
      setLoading(false)
      toastOnSuccess('保存しました')
    },
    onError: (error: AxiosError) => {
      setLoading(false)
      switch (error.response.status) {
        case 422:
          toastOnError(error.response.data.errors.join(','))
          // 422エラー時にslack通知する
          slackNotification(error, user)
          break
        default:
          handleError(error.response.status)
      }
    },
    onSettled: () => {
      setIsProcessingModalShow(false)
    },
  })

  // 更新時の初期値
  const defaultValues = {
    ...props.data,
    chargedVehicles: chargedVehicleList,
  }

  const baseQueryKey = [
    `drivers`,
    `${props?.data?.id}`,
    `${props.type}`,
    `form`,
  ]

  useQuery(
    [...baseQueryKey, `attachedProfileImage`],
    () => fetchS3ImageV2(defaultValues?.profile?.attachedProfileImage),
    {
      enabled: props.type == 'update',
      onSuccess: (data) => setProfileImage(data),
      onError: (e: AxiosError) => {
        handleError(e.response.status)
        // 422エラー時にslack通知する
        slackNotification(e, user)
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )

  useQuery(
    [...baseQueryKey, `attachedFiles`],
    () => fetchS3ImagesV2(defaultValues?.license?.attachedFiles),
    {
      enabled: props.type == 'update',
      onSuccess: (data) => setLicenseImages(data),
      onError: (e: AxiosError) => {
        handleError(e.response.status)
        // 422エラー時にslack通知する
        slackNotification(e, user)
      },
      refetchOnWindowFocus: false,
    }
  )

  useQuery(
    [...baseQueryKey, `attachedLedgerFiles`],
    () => fetchS3ImagesV2(defaultValues?.profile?.attachedLedgerFiles),
    {
      enabled: props.type == 'update',
      onSuccess: (data) => setLedgerFiles(data),
      onError: (e: AxiosError) => {
        handleError(e.response.status)
        // 422エラー時にslack通知する
        slackNotification(e, user)
      },
      refetchOnWindowFocus: false,
    }
  )
  useQuery(
    [...baseQueryKey, `attachedMedicalRecordFiles`],
    () => fetchS3ImagesV2(defaultValues?.attachedMedicalRecordFiles),
    {
      enabled: props.type == 'update',
      onSuccess: (data) => setMedicalRecordsFiles(data),
      onError: (e: AxiosError) => {
        handleError(e.response.status)
        // 422エラー時にslack通知する
        slackNotification(e, user)
      },
      refetchOnWindowFocus: false,
    }
  )

  useQuery(
    [...baseQueryKey, `attachedDirectionRecordFiles`],
    () => fetchS3ImagesV2(defaultValues?.attachedDirectionRecordFiles),
    {
      enabled: props.type == 'update',
      onSuccess: (data) => setDirectionRecordsFiles(data),
      onError: (e: AxiosError) => {
        handleError(e.response.status)
        // 422エラー時にslack通知する
        slackNotification(e, user)
      },
      refetchOnWindowFocus: false,
    }
  )

  useQuery(
    [...baseQueryKey, `attachedAptitudeTestRecordFiles`],
    () => fetchS3ImagesV2(defaultValues?.attachedAptitudeTestRecordFiles),
    {
      enabled: props.type == 'update',
      onSuccess: (data) => setAptitudeTestRecordsFiles(data),
      onError: (e: AxiosError) => {
        handleError(e.response.status)
        // 422エラー時にslack通知する
        slackNotification(e, user)
      },
      refetchOnWindowFocus: false,
    }
  )

  React.useEffect(() => {
    defaultValues.medicalRecords?.forEach((medicalRecord, index) => {
      fetchS3ImagesV2(medicalRecord.attachedFiles)
        .then((data: File[]) => {
          setValue(`medicalRecords.${index}.files`, data)
        })
        .catch((e: AxiosError) => {
          handleError(e.response.status)
        })
    })
  }, [])

  React.useEffect(() => {
    defaultValues.aptitudeTestRecords?.forEach((aptitudeTestRecord, index) => {
      fetchS3ImagesV2(aptitudeTestRecord.attachedFiles)
        .then((data: File[]) => {
          setValue(`aptitudeTestRecords.${index}.files`, data)
        })
        .catch((e: AxiosError) => {
          handleError(e.response.status)
        })
    })
  }, [])

  React.useEffect(() => {
    defaultValues.directionRecords?.forEach((directionRecord, index) => {
      fetchS3ImagesV2(directionRecord.attachedFiles)
        .then((data: File[]) => {
          setValue(`directionRecords.${index}.files`, data)
        })
        .catch((e: AxiosError) => {
          handleError(e.response.status)
        })
    })
  }, [])

  // フォームオブジェクト
  const {
    register,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
    trigger,
  } = useForm<Driver>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: defaultValues,
  })

  const { data: subscribed, isLoading: isSubscribedLoading } = useQuery(
    [`drivers/Form`, 'subscribed'],
    () => checkSubscribed('drivers').then((res) => res.data.subscribed),
    {
      onSettled: () => setLoading(false),
      onError: (e: AxiosError) => {
        handleError(e.response.status)
        // 422エラー時にslack通知する
        slackNotification(e, user)
      },
    }
  )

  React.useEffect(() => {
    if (isSubscribedLoading) {
      setLoading(true)
    }
  }, [isSubscribedLoading])

  const formatParamsData = (data): { data: Driver; formData: FormData } => {
    data.profile.imageCreatedDate = `${data.profile.imageCreatedYear}-${data.profile.imageCreatedMonth}-${data.profile.imageCreatedDay}`

    if (data.aptitudeTestRecords) {
      data.emergencyContacts.forEach((emergencyContact) => {
        emergencyContact.phoneNumber = `${emergencyContact.phoneNumberFirst}-${emergencyContact.phoneNumberSecond}-${emergencyContact.phoneNumberThird}`
      })
    }

    if (data.aptitudeTestRecords) {
      data.aptitudeTestRecords.forEach((aptitudeTestRecord) => {
        aptitudeTestRecord.consultationDate = `${aptitudeTestRecord.consultationYear}-${aptitudeTestRecord.consultationMonth}-${aptitudeTestRecord.consultationDay}`
      })
    }

    if (data.directionRecords) {
      data.directionRecords.forEach((directionRecord) => {
        directionRecord.consultationDate = `${directionRecord.consultationYear}-${directionRecord.consultationMonth}-${directionRecord.consultationDay}`
      })
    }

    if (data.violationHistories) {
      data.violationHistories.forEach((violationHistory) => {
        violationHistory.violationDate = `${violationHistory.violationYear}-${violationHistory.violationMonth}-${violationHistory.violationDay}`
      })
    }

    if (data.medicalRecords) {
      data.medicalRecords.forEach((medicalRecord) => {
        medicalRecord.consultationDate = `${medicalRecord.consultationYear}-${medicalRecord.consultationMonth}-${medicalRecord.consultationDay}`
      })
    }

    if (data.chargedVehicles) {
      data.chargedVehicles.forEach((chargedVehicle) => {
        chargedVehicle.fromDate = `${chargedVehicle.fromYear}-${chargedVehicle.fromMonth}-${chargedVehicle.fromDay}`
        chargedVehicle.toDate = `${chargedVehicle.toYear}-${chargedVehicle.toMonth}-${chargedVehicle.toDay}`
      })
    }

    if (data.personnelChangeHistories) {
      data.personnelChangeHistories.forEach((personnelChangeHistory) => {
        personnelChangeHistory.appointedDate = `${personnelChangeHistory.appointedYear}-${personnelChangeHistory.appointedMonth}-${personnelChangeHistory.appointedDay}`
      })
    }

    if (data.insurance) {
      data.insurance.health.subscribedDate = [
        data.insurance.health.subscribedYear,
        data.insurance.health.subscribedMonth,
        data.insurance.health.subscribedDay,
      ].join('-')
      data.insurance.pension.subscribedDate = [
        data.insurance.pension.subscribedYear,
        data.insurance.pension.subscribedMonth,
        data.insurance.pension.subscribedDay,
      ].join('-')
      data.insurance.employment.subscribedDate = [
        data.insurance.employment.subscribedYear,
        data.insurance.employment.subscribedMonth,
        data.insurance.employment.subscribedDay,
      ].join('-')
      data.insurance.injury.subscribedDate = [
        data.insurance.injury.subscribedYear,
        data.insurance.injury.subscribedMonth,
        data.insurance.injury.subscribedDay,
      ].join('-')
    }

    // ドライバー新規作成時に入社情報が設定されない不具合の暫定対応
    // 取り急ぎの対応なので、抜本的な対応が必要
    data.careers[0]['category'] = 'joined'
    data.careers[1]['category'] = 'selected_driver'
    data.careers[2]['category'] = 'retired'
    data.careers[3]['category'] = 'died'

    const formData = new FormData()
    if (profileImage) {
      formData.append('profile_image', profileImage)
    }
    ledgerFiles.forEach((file) => {
      formData.append('ledger_files[]', file)
    })
    licenseImages.forEach((image) => {
      formData.append('license_files[]', image)
    })
    aptitudeTestRecordsFiles.forEach((image) => {
      formData.append('aptitude_tests_files[]', image)
    })
    directionRecordsFiles.forEach((image) => {
      formData.append('direction_records_files[]', image)
    })
    medicalRecordsFiles.forEach((image) => {
      formData.append('medical_records_files[]', image)
    })
    data?.medicalRecords.forEach((medicalRecord, index) => {
      medicalRecord.files.forEach((file) => {
        formData.append(`medical_record_children_files[${index}][]`, file)
      })
    })
    data?.aptitudeTestRecords.forEach((aptitudeTestRecord, index) => {
      aptitudeTestRecord.files.forEach((file) => {
        formData.append(`aptitude_test_record_children_files[${index}][]`, file)
      })
    })
    data?.directionRecords.forEach((directionRecord, index) => {
      directionRecord.files.forEach((file) => {
        formData.append(`direction_record_children_files[${index}][]`, file)
      })
    })

    return { data, formData }
  }

  const Section = styled('div')({
    margin: '1rem',
  })

  const EnabledForSubscribedUser = styled('div')({
    pointerEvents: subscribed ? 'auto' : 'none',
    opacity: subscribed ? '1' : '0.45',
  })

  const submit = async (callback: () => void) => {
    setModalShow(false)
    const hasNotError = await trigger(undefined, { shouldFocus: true })
    if (hasNotError) {
      callback()
    } else {
      toastOnError('入力内容に不備があります')
    }
  }

  const submitCallback = async (to: 'show' | 'new' | 'next' | 'index') => {
    setIsProcessingModalShow(true)
    // getValues()の戻り値をそのまま渡すと送信後にフォームの値が元に戻ってしまう不具合が発生した為、deepCopyした値を渡して対処している
    // https://github.com/X-Mile/track-manager/pull/795#discussion_r1034714181
    const raw = structuredClone(getValues())
    const { data, formData } = formatParamsData(raw)
    mutation.mutate(
      { data, formData },
      {
        onSuccess: (res) => {
          scrollTo({ top: 0 })
          if (to === 'new') {
            if (props.type === 'create') {
              return navigate(0)
            }
            navigate('/admin/drivers/new/')
          } else if (to === 'show') {
            navigate(`/admin/drivers/${res.data.id}/`)
          } else if (to === 'next') {
            navigateToNextResource(res.data.id)
          } else if (to === 'index') {
            navigate(`/admin/drivers?focus_id=${res.data.id}`)
          }
        },
        onSettled: () => {
          setIsProcessingModalShow(false)
        },
      }
    )
  }

  /**
   *
   * @param id
   * - 「次のレコード」を取得して遷移するメソッド
   * - TODO: 全件取得してフロント側で「次のレコード」を見つける実装なのでdisplayLimitを越えた場合に機能しない。バックエンド側で「次のレコード」を見つけて返すようにしたい。
   */
  const navigateToNextResource = async (id: number) => {
    // localStorageから検索条件を取得
    const searchParams = getLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.DRIVERS
    )
    const countPerPage = getLocalStorageDisplayLimit()
    // 検索条件を適用してfetch, 結果をcountPerPage毎に分割
    const driversPerDisplayLimit = await fetchDrivers(searchParams, {
      displayLimit: 1000,
    }).then((res) => {
      const arr = []
      while (res.data.drivers.length > 1) {
        const per = res.data.drivers.splice(0, countPerPage)
        arr.push(per)
      }
      return arr
    })
    // 「次のデータ」のidを取得
    const nextId = getNextResourceId<Driver>(
      LOCAL_STORAGE_PAGE_KEY.DRIVERS,
      id,
      driversPerDisplayLimit
    )
    if (nextId === null) {
      return navigate(`/admin/drivers/`)
    }
    navigate(`/admin/drivers/${nextId}/`)
  }

  const handleKeyDown = async (e) => {
    if (ShouldSubmitOnKeyPress(e)) {
      const hasNotError = await trigger(undefined, { shouldFocus: true })
      if (hasNotError) {
        setIsProcessingModalShow(true)
        const copy = structuredClone(getValues())
        const { data, formData } = formatParamsData(copy)
        mutationWithoutNavigation.mutate({ data, formData })
      } else {
        toastOnError('入力内容に不備があります')
      }
    }
  }

  const handleModalCancelSelected = () => {
    setModalShow(false)
  }

  return (
    <>
      {isLoading && <Loading />}
      {!subscribed &&
        setUnsubscribedAlert(ALERT_TEXT.UNSUBSCRIBED_ALERT_MESSAGE)}
      <CustomForm>
        <Section id={DRIVER_LINK_LIST_ITEMS.PROFILE_IMAGE.ID}>
          <ProfileImage
            control={control}
            setProfileImage={setProfileImage}
            profileImage={profileImage}
          />
        </Section>
        <Section id={DRIVER_LINK_LIST_ITEMS.PROFILE.ID}>
          <DriversProfile
            register={register}
            control={control}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            watch={watch}
            ledgerFiles={ledgerFiles}
            setLedgerFiles={setLedgerFiles}
            isXmileSystemAdminUser={props.isXmileSystemAdminUser}
            onInputKeyDown={handleKeyDown}
            type={props.type}
            companies={props.companies}
            offices={props.offices}
            roles={props.roles}
            drivers={props.drivers}
            isAdminUser={props.isAdminUser}
          />
        </Section>
        <EnabledForSubscribedUser>
          <Section id={DRIVER_LINK_LIST_ITEMS.EMERGENCY_CONTACTS.ID}>
            <EmergencyContact
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              onInputKeyDown={handleKeyDown}
            />
          </Section>
          <Section id={DRIVER_LINK_LIST_ITEMS.FAMILY_PROFILES.ID}>
            <DriversFamilyProfile
              register={register}
              control={control}
              getValues={getValues}
              onInputKeyDown={handleKeyDown}
            />
          </Section>
          <Section id={DRIVER_LINK_LIST_ITEMS.INSURANCES.ID}>
            <Insurances
              register={register}
              control={control}
              setValue={setValue}
              watch={watch}
              errors={errors}
              onInputKeyDown={handleKeyDown}
              subscribedDriverLedger={subscribed}
            />
          </Section>
        </EnabledForSubscribedUser>
        <Section id={DRIVER_LINK_LIST_ITEMS.CAREERS.ID}>
          <DriversCareer
            register={register}
            control={control}
            setValue={setValue}
          />
        </Section>
        <EnabledForSubscribedUser>
          <Section id={DRIVER_LINK_LIST_ITEMS.CHARGED_VEHICLES.ID}>
            <DriversChargedVehicles
              register={register}
              control={control}
              errors={errors}
            />
          </Section>
        </EnabledForSubscribedUser>
        <Section id={DRIVER_LINK_LIST_ITEMS.LICENSES.ID}>
          <DriversLicense
            register={register}
            control={control}
            setValue={setValue}
            licenseImages={licenseImages}
            setLicenseImages={setLicenseImages}
            onInputKeyDown={handleKeyDown}
          />
        </Section>
        <EnabledForSubscribedUser>
          <Section id={DRIVER_LINK_LIST_ITEMS.PERSONAL_HISTORIES.ID}>
            <DriversPersonalHistory
              register={register}
              control={control}
              onInputKeyDown={handleKeyDown}
            />
          </Section>

          <Section id={DRIVER_LINK_LIST_ITEMS.PERSONNEL_CHANGE_HISTORIES.ID}>
            <DriversPersonnelChangeHistory
              register={register}
              control={control}
            />
          </Section>

          <Section id={DRIVER_LINK_LIST_ITEMS.DRIVING_EXPERIENCES.ID}>
            <DrivingExperiences
              register={register}
              control={control}
              errors={errors}
              onInputKeyDown={handleKeyDown}
            />
          </Section>
        </EnabledForSubscribedUser>
        <Section id={DRIVER_LINK_LIST_ITEMS.MEDICAL_RECORDS.ID}>
          <MedicalRecords
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            medicalRecordsFiles={medicalRecordsFiles}
            setMedicalRecordsFiles={setMedicalRecordsFiles}
            onInputKeyDown={handleKeyDown}
          />
        </Section>
        <Section id={DRIVER_LINK_LIST_ITEMS.APTITUDE_TEST_RECORDS.ID}>
          <AptitudeTestRecords
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            aptitudeTestRecordsFiles={aptitudeTestRecordsFiles}
            setAptitudeTestRecordsFiles={setAptitudeTestRecordsFiles}
            onInputKeyDown={handleKeyDown}
          />
        </Section>
        <EnabledForSubscribedUser>
          <Section id={DRIVER_LINK_LIST_ITEMS.DIRECTION_RECORDS.ID}>
            <DirectionRecords
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              watch={watch}
              directionRecordsFiles={directionRecordsFiles}
              setDirectionRecordsFiles={setDirectionRecordsFiles}
              onInputKeyDown={handleKeyDown}
            />
          </Section>
          <Section id={DRIVER_LINK_LIST_ITEMS.VIOLATION_HISTORIES.ID}>
            <ViolationHistory
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              onInputKeyDown={handleKeyDown}
            />
          </Section>
        </EnabledForSubscribedUser>
        <Section id={DRIVER_LINK_LIST_ITEMS.MEMO.ID}>
          <BackGroundWhite>
            <HeadingText>メモ</HeadingText>
            <CustomLabel>
              <CustomTextArea {...register('profile.memo')} />
            </CustomLabel>
          </BackGroundWhite>
        </Section>
      </CustomForm>
      <FlexBox
        justifyContent={'flex-end'}
        sx={{ marginTop: '8px', marginRight: '1rem' }}
      >
        <ApplyButton
          pattern="block"
          disabled={isLoading}
          onClick={(e) => {
            e.preventDefault()
            setModalShow(true)
          }}
        >
          保存する
        </ApplyButton>
      </FlexBox>
      <FormSubmitSelectPageModal
        isOpen={isModalShow}
        resourceName="ドライバー"
        handlers={{
          cancel: handleModalCancelSelected,
          toShow: () => submit(() => submitCallback('show')),
          toIndex: () => submit(() => submitCallback('index')),
        }}
      ></FormSubmitSelectPageModal>
      <ProcessingModal isOpen={isProcessingModalShow} />
    </>
  )
}
