import * as React from 'react'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { ButtonProps } from '@mui/material/Button'

export function CameraButton(props: ButtonProps) {
  return (
    <ApplyButton {...props} startIcon={<CameraAltIcon />}>
      {props.children}
    </ApplyButton>
  )
}
