import * as React from 'react'
import {
  CustomLabel,
  CustomDateSelect,
  CustomTextArea,
  CustomInputShort,
  CustomCheckBoxForm,
  CustomDropZone,
} from 'components/atoms/FormComponents'
import {
  Control,
  FieldErrors,
  UseFormRegister,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { BackGroundPalePink } from 'components/atoms/BackGroundComponents'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { styled } from '@mui/styles'
import { FlexBox, FlexBoxRow } from 'components/atoms/BoxComponents'
import { PARTS_REPLACED } from 'commons/partsReplaced'
import { VEHICLES } from 'commons/constants'
import { theme } from 'components/theme'
import NumberFormat from 'components/organisms/NumberFormat'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

type PartsReplacedCardProps = {
  register: UseFormRegister<Vehicle>
  control: Control<Vehicle>
  setValue: UseFormSetValue<Vehicle>
  watch: UseFormWatch<Vehicle>
  errors?: FieldErrors<Vehicle>
  appendEmptyItem: () => void
  deleteItem: (index: number) => void
  index: number
}

const PartsReplacedCard: React.FC<PartsReplacedCardProps> = (props) => {
  const { register, control, setValue, watch, index } = props
  const CardTitle = styled('p')({
    fontWeight: 'bold',
  })

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const old_files = watch(`partsReplaced.${index}.files`)
        ? watch(`partsReplaced.${index}.files`)
        : []
      setValue(`partsReplaced.${index}.files`, [...old_files, ...acceptedFiles])
    },
    [watch(`partsReplaced.${index}.files`)]
  )

  return (
    <BackGroundPalePink sx={{ marginBottom: '30px' }}>
      <CardTitle>
        {VEHICLES.LABELS.PARTS_REPLACED}
        {index + 1}
      </CardTitle>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.PARTS_REPLACED_DATE}
        <CustomDateSelect
          formKeys={{
            year: `partsReplaced.${index}.replacedYear`,
            month: `partsReplaced.${index}.replacedMonth`,
            day: `partsReplaced.${index}.replacedDay`,
          }}
          control={control}
        />
      </CustomLabel>

      <FlexBox>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.PARTS_REPLACED}
          <FlexBoxRow>
            {PARTS_REPLACED.map((partsReplaced, option_index) => (
              <React.Fragment key={option_index}>
                <Controller
                  name={`partsReplaced.${index}.${partsReplaced.value}`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomCheckBoxForm>
                      <label>
                        <input
                          type="checkbox"
                          checked={value}
                          onChange={() => onChange(!value)}
                        />
                        {partsReplaced.label}
                      </label>
                    </CustomCheckBoxForm>
                  )}
                />
              </React.Fragment>
            ))}
          </FlexBoxRow>
        </CustomLabel>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.PARTS_REPLACED_MILEAGE}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={CustomInputShort} />
              )}
              name={`partsReplaced.${index}.mileage`}
              control={control}
            />
          </FlexBox>
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.PARTS_REPLACED_AMOUNT_ENGINE_OIL}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat {...field} customInput={CustomInputShort} />
              )}
              name={`partsReplaced.${index}.amountEngineOil`}
              control={control}
            />
          </FlexBox>
        </CustomLabel>
      </FlexBox>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.PARTS_REPLACED_MEMO}
        <CustomTextArea {...register(`partsReplaced.${index}.memo`)} />
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.FILE_UPLOAD}(
        {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
        <Controller
          render={() => (
            <CustomDropZone
              multiple={true}
              onDrop={onDrop}
              accept={'image/*, application/pdf'}
            />
          )}
          name={`partsReplaced.${index}.fileUrls`}
          control={control}
        />
        {watch(`partsReplaced.${index}.files`) &&
          Object.values(watch(`partsReplaced.${index}.files`))?.map(
            (image: File, fileIndex: number) => (
              <FlexBox flexDirection={'row'} key={fileIndex}>
                <p>
                  <a
                    href={URL.createObjectURL(image)}
                    download={decodeURI(image.name)}
                  >
                    {decodeURI(image.name)}
                  </a>
                </p>
                <IconButton
                  component="span"
                  onClick={() =>
                    setValue(
                      `partsReplaced.${index}.files`,
                      watch(`partsReplaced.${index}.files`).filter(
                        (_, i) => i !== fileIndex
                      )
                    )
                  }
                >
                  <CancelIcon />
                </IconButton>
              </FlexBox>
            )
          )}
      </CustomLabel>
      <DeleteButton onClick={() => props.deleteItem(index)}>削除</DeleteButton>
    </BackGroundPalePink>
  )
}

export default PartsReplacedCard
