import * as React from 'react'
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormGetValues,
  useFieldArray,
} from 'react-hook-form'
import {
  BackGroundPalePink,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  ConvertibleCustomDateSelect,
  ConvertibleCustomTextArea,
  ConvertibleCustomSelect,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { DRIVERS, CHARGED_VEHICLES } from 'commons/constants'
import { Box, styled } from '@mui/material'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { useNavigate } from 'react-router-dom'
import { palette } from 'components/theme'
import { fetchVehicleList } from 'components/apis/vehicles'

type ChargedVehicleProps = {
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
  handleModeChange: (name: DriverFormModeMapKey, mode: FormMode) => void
}

const ChargedVehicle = (props: ChargedVehicleProps) => {
  const navigate = useNavigate()

  const reload = () => {
    if (
      window.confirm('保存していないデータは削除されます。よろしいですか？')
    ) {
      navigate(0)
    }
  }

  const { fields } = useFieldArray({
    control: props.control,
    name: 'chargedVehicles',
  })

  const [vehicles, setVehicles] = React.useState<SelectOption[]>([])
  React.useEffect(() => {
    fetchVehicleList().then((response) => {
      setVehicles(
        Object.values(response.data.vehicles).map((vehicle: Vehicle) => {
          return { value: vehicle.id, label: vehicle.number }
        })
      )
    })
  }, [])

  return (
    <BackGroundWhite>
      <FlexBox justifyContent="space-between" marginBottom="16px">
        <HeadingText>{DRIVERS.LABELS.CHARGED_VEHICLES}</HeadingText>
        <Box>
          {/* 一時的にコメントアウト */}
          {/* {props.mode == 'show' && (
            <EditButtonOutlined
              onClick={() => {
                props.handleModeChange('chargedVehicles', 'edit')
              }}
            >
              編集
            </EditButtonOutlined>
          )} */}
          {props.mode == 'edit' && (
            <FlexBox>
              <ApplyButton pattern="marginRight8" type="submit">
                保存する
              </ApplyButton>
              <CancelButtonOutlined onClick={reload}>
                キャンセル
              </CancelButtonOutlined>
            </FlexBox>
          )}
        </Box>
      </FlexBox>
      {fields.length == 0 && <p>データがありません</p>}
      {fields.map((_, i) => {
        return (
          <Box key={`charged-vehicle-card-${i}`} mb={2}>
            <ChargedVehicleInner
              index={i}
              mode={props.mode}
              control={props.control}
              register={props.register}
              errors={props.errors}
              getValues={props.getValues}
              key={`charged-vehicle-${i}`}
              vehicles={vehicles}
            ></ChargedVehicleInner>
          </Box>
        )
      })}
    </BackGroundWhite>
  )
}

type ChargedVehicleInnerProps = {
  index: number
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
  vehicles: SelectOption[]
}

const Title = styled('p')({
  fontWeight: 'bold',
  color: palette.text.gray.deep,
})

const ChargedVehicleInner: React.FC<ChargedVehicleInnerProps> = (props) => {
  return (
    <BackGroundPalePink>
      <Title>
        {DRIVERS.LABELS.CHARGED_VEHICLES}
        {props.index + 1}
      </Title>
      <CustomLabel>
        {CHARGED_VEHICLES.LABELS.VEHICLE}
        <ConvertibleCustomSelect
          mode={props.mode}
          formKey={`chargedVehicles.${props.index}.vehicleId`}
          control={props.control}
          getValues={props.getValues}
          options={props.vehicles}
        />
      </CustomLabel>
      <CustomLabel>
        {CHARGED_VEHICLES.LABELS.FROM_DATE}
        <ConvertibleCustomDateSelect
          mode={props.mode}
          formKeys={{
            year: `chargedVehicles.${props.index}.fromYear`,
            month: `chargedVehicles.${props.index}.fromMonth`,
            day: `chargedVehicles.${props.index}.fromDay`,
          }}
          control={props.control}
          getValues={props.getValues}
        />
      </CustomLabel>
      <CustomLabel>
        {CHARGED_VEHICLES.LABELS.TO_DATE}
        <ConvertibleCustomDateSelect
          mode={props.mode}
          formKeys={{
            year: `chargedVehicles.${props.index}.toYear`,
            month: `chargedVehicles.${props.index}.toMonth`,
            day: `chargedVehicles.${props.index}.toDay`,
          }}
          control={props.control}
          getValues={props.getValues}
        />
      </CustomLabel>
      <CustomLabel>
        {CHARGED_VEHICLES.LABELS.MEMO}
        <ConvertibleCustomTextArea
          mode={props.mode}
          formKey={`chargedVehicles.${props.index}.memo`}
          register={props.register}
          getValues={props.getValues}
        />
      </CustomLabel>
    </BackGroundPalePink>
  )
}

export default ChargedVehicle
