import { styled } from '@mui/styles'
import React, { useState } from 'react'

type Props = {
  minuteStep: number
  name: string
  value: string
  onChange: (value: string) => void
}

function TimePicker(props: Props) {
  const { minuteStep, name, value, onChange } = props
  const [inputValue, setInputValue] = useState(value ?? '')
  const [selectValue, setSelectValue] = useState(value ?? '')
  const [open, setOpen] = useState<boolean>(false)

  const hours = Array.from({ length: 24 }, (_, i) =>
    i < 10 ? '0' + i : '' + i
  )
  const minutes = Array.from({ length: 60 / minuteStep }, (_, i) =>
    i * minuteStep < 10 ? '0' + i * minuteStep : '' + i * minuteStep
  )

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newInputValue = e.target.value
    const element = document.getElementById(
      newInputValue.replace(':', '').padEnd(4, '0')
    )
    const target =
      element ??
      document.getElementById(newInputValue.split(':')[0].padEnd(4, '0'))

    setInputValue(newInputValue)
    setSelectValue('')
    onChange(newInputValue)

    if (target) {
      element.scrollIntoView({ behavior: 'smooth' })
    }

    // 最初の3文字が数値であれば2文字目と3文字目の間に":"を挿入する
    if (/^\d{3}/.test(newInputValue)) {
      const formattedValue = newInputValue.replace(/^(\d{2})(\d{1})/, '$1:$2')

      setInputValue(formattedValue)
    }

    if (newInputValue.length > 4) {
      setOpen(false)

      return
    }

    setOpen(true)
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value

    setSelectValue(selectedValue)
    setInputValue(selectedValue)
    onChange(selectedValue)
    setOpen(false)
  }

  return (
    <div style={{ position: 'relative' }}>
      <Input
        name={name}
        onFocus={() => setOpen(true)}
        onBlur={() => {
          setTimeout(() => {
            setOpen(false)
          }, 200)
        }}
        value={inputValue}
        onChange={handleInputChange}
        autoComplete="off"
      />
      {open && (
        <Select value={selectValue} onChange={handleSelectChange} size={4}>
          {hours.map((hour) =>
            minutes.map((minute) => (
              <Option
                key={`${hour}:${minute}`}
                value={`${hour}:${minute}`}
                id={`${hour}${minute}`}
              >
                {hour}:{minute}
              </Option>
            ))
          )}
        </Select>
      )}
    </div>
  )
}

export default TimePicker

const Input = styled('input')({
  width: '100%',
  fontSize: '1rem',
  height: '38px',
  padding: '0 6px',
  borderRadius: '6px',
  border: 'solid 1px #cecfd1',
})

const Select = styled('select')({
  position: 'absolute',
  width: '100%',
  borderRadius: '3px',
  border: 'solid 1px #cdced1',
  left: 0,
  top: '42px',
})

const Option = styled('option')({
  padding: '6px',
  fontSize: '1rem',
})
