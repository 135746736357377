import * as React from 'react'
import { styled } from '@mui/styles'
import { Box } from '@mui/material'
import successImage from 'images/educations/result/success.png'
import failImage from 'images/educations/result/fail.png'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { CustomLabel } from 'components/atoms/FormComponents'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { useNavigate } from 'react-router-dom'

const Wrapper = styled(Box)({
  boxSizing: 'border-box',
  borderRadius: '6px',
  width: '100%',
  height: '100%',
})

const PassScore = styled('p')({
  fontSize: '36px',
  color: '#0050B2',
  fontWeight: 'bold',
  margin: '0 auto',
})
const InLine = styled('div')({
  display: 'inline-block',
  _display: 'inline',
})

const PassText = styled('h1')({
  fontFamily: 'Noto Sans JP',
  fontWeight: 900,
  fontSize: '58px',
  margin: '0 auto',
})

export function SuccessPanel() {
  return (
    <>
      <Wrapper
        sx={{
          backgroundImage: `url(${successImage})`,
          padding: '16px 24px',
          margin: 'auto',
          textAlign: 'center',
          color: '#DB1F41',
          fontWeight: 'bold',
        }}
      >
        <PassText>合格</PassText>
        <p>
          おめでとうございます！
          <br />
          引き続き安全運転に努めてください
        </p>
      </Wrapper>
    </>
  )
}

export function FailPanel() {
  return (
    <Wrapper
      sx={{
        backgroundImage: `url(${failImage})`,
        padding: '16px 24px',
        margin: 'auto',
        textAlign: 'center',
        color: '#1C62C9',
        fontWeight: 'bold',
      }}
    >
      <PassText>不合格</PassText>
      <p>
        間違えた箇所を
        <br />
        資料や動画で振り返ってみましょう
      </p>
    </Wrapper>
  )
}

export function ResultComponent(props: {
  questionAnswers
  correctAnswerSum
  isPass
}) {
  const navigate = useNavigate()

  return (
    <>
      <BackGroundWhite>
        <Wrapper
          sx={{
            textAlign: 'center',
          }}
        >
          <CustomLabel sx={{ marginBottom: '0' }}>回答結果</CustomLabel>
          <InLine>
            <PassScore>{props.correctAnswerSum}</PassScore>
          </InLine>
          <InLine>問 / {props.questionAnswers.length}問中</InLine>

          {props.isPass === 'fail' && (
            <Box>
              <ApplyButton onClick={() => navigate(0)}>再受験する</ApplyButton>
            </Box>
          )}
        </Wrapper>
      </BackGroundWhite>
      {props.isPass === 'pass' ? <SuccessPanel /> : <FailPanel />}
    </>
  )
}
