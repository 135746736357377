import * as React from 'react'
import { UserContext } from 'providers/UserProvider'

const EmptyCurrentContent = () => {
  const user = React.useContext(UserContext)

  // 非管理者ユーザーの場合は管理者へ問い合わせを促す
  if (!user.admin) {
    return (
      <p>
        今月の項目は設定されていません。詳しくは社内の管理者に問い合わせください。
      </p>
    )
  }

  // 事業所に所属する管理者の場合は年間計画の設定を促す
  if (user.officeId) {
    return (
      <p>
        <a href="/admin/educations/annual_plans/">年間計画</a>
        から今月の項目を設定してください。
      </p>
    )
  }

  // 事業所が存在する会社のシステム管理者には各営業所の計画参照を促す
  if (user.company.hasOffices) {
    return <p>システム管理者の方は該当営業所の年間計画を参照ください。</p>
  } else {
    return (
      <p>
        <a href="/admin/educations/annual_plans/">年間計画</a>
        から今月の項目を設定してください。
      </p>
    )
  }
}

export default EmptyCurrentContent
