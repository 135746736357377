import * as React from 'react'
import {
  CustomLabel,
  CustomInputShort,
  CustomDateSelect,
  CustomTextArea,
  CustomInput,
  CustomDropZone,
} from 'components/atoms/FormComponents'
import {
  Control,
  FieldErrors,
  UseFormRegister,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { BackGroundPalePink } from 'components/atoms/BackGroundComponents'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { styled } from '@mui/styles'
import { FlexBox } from 'components/atoms/BoxComponents'
import { VEHICLES } from 'commons/constants'
import { theme } from 'components/theme'
import NumberFormat from 'components/organisms/NumberFormat'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

type MaintenanceCardProps = {
  register: UseFormRegister<Vehicle>
  control: Control<Vehicle>
  setValue: UseFormSetValue<Vehicle>
  watch: UseFormWatch<Vehicle>
  errors?: FieldErrors<Vehicle>
  appendEmptyItem: () => void
  deleteItem: (index: number) => void
  index: number
}

const MaintenanceCard: React.FC<MaintenanceCardProps> = (props) => {
  const { register, control, setValue, watch, index } = props
  const CardTitle = styled('p')({
    fontWeight: 'bold',
  })

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const old_files = watch(`maintenances.${index}.files`)
        ? watch(`maintenances.${index}.files`)
        : []
      setValue(`maintenances.${index}.files`, [...old_files, ...acceptedFiles])
    },
    [watch(`maintenances.${index}.files`)]
  )

  return (
    <BackGroundPalePink sx={{ marginBottom: '30px' }}>
      <CardTitle>
        {VEHICLES.LABELS.MAINTENANCE}
        {index + 1}
      </CardTitle>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.MAINTENANCE_TITLE}
          <FlexBox alignItems="center">
            <CustomInputShort {...register(`maintenances.${index}.title`)} />
          </FlexBox>
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.MAINTENANCE_NUMBER}
          <FlexBox alignItems="center">
            <CustomInputShort {...register(`maintenances.${index}.number`)} />
          </FlexBox>
        </CustomLabel>
      </FlexBox>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.MAINTENANCE_DATE}
        <CustomDateSelect
          formKeys={{
            year: `maintenances.${index}.maintenanceYear`,
            month: `maintenances.${index}.maintenanceMonth`,
            day: `maintenances.${index}.maintenanceDay`,
          }}
          control={control}
        />
      </CustomLabel>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.MAINTENANCE_TOTAL_FEE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`maintenances.${index}.totalFee`}
            control={control}
          />
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.MAINTENANCE_PARTS_FEE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`maintenances.${index}.partsFee`}
            control={control}
          />
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.MAINTENANCE_TECHNICAL_FEE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`maintenances.${index}.technicalFee`}
            control={control}
          />
        </CustomLabel>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            width: '100%',
          },
        }}
      >
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.MAINTENANCE_AREA}
          <CustomInput {...register(`maintenances.${index}.maintenanceArea`)} />
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.MAINTENANCE_COMPANY_NAME}
          <CustomInput {...register(`maintenances.${index}.companyName`)} />
        </CustomLabel>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.MAINTENANCE_MILEAGE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`maintenances.${index}.mileage`}
            control={control}
          />
        </CustomLabel>
      </FlexBox>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.MAINTENANCE_MEMO}
        <CustomTextArea {...register(`maintenances.${index}.memo`)} />
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.FILE_UPLOAD}(
        {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
        <Controller
          render={() => (
            <CustomDropZone
              multiple={true}
              onDrop={onDrop}
              accept={'image/*, application/pdf'}
            />
          )}
          name={`maintenances.${index}.fileUrls`}
          control={control}
        />
        {watch(`maintenances.${index}.files`) &&
          Object.values(watch(`maintenances.${index}.files`))?.map(
            (image: File, fileIndex: number) => (
              <FlexBox flexDirection={'row'} key={fileIndex}>
                <p>
                  <a
                    href={URL.createObjectURL(image)}
                    download={decodeURI(image.name)}
                  >
                    {decodeURI(image.name)}
                  </a>
                </p>
                <IconButton
                  component="span"
                  onClick={() =>
                    setValue(
                      `maintenances.${index}.files`,
                      watch(`maintenances.${index}.files`).filter(
                        (_, i) => i !== fileIndex
                      )
                    )
                  }
                >
                  <CancelIcon />
                </IconButton>
              </FlexBox>
            )
          )}
      </CustomLabel>
      <DeleteButton onClick={() => props.deleteItem(index)}>削除</DeleteButton>
    </BackGroundPalePink>
  )
}

export default MaintenanceCard
