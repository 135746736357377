import * as React from 'react'

type HistoryRowProps = {
  editorName: string
  companyFullName: string
  updatedAt: string
}
function HistoryRow(props: HistoryRowProps) {
  const { editorName, companyFullName, updatedAt } = props
  return (
    <div>
      <span>
        {editorName}({companyFullName}) : {updatedAt}
      </span>
    </div>
  )
}

export default HistoryRow
