import * as React from 'react'
import { ERRORS_TEXTS } from 'commons/constants'

function Unauthorized() {
  const LoginPage = '/users/sign_in'

  return (
    <>
      <h3>{ERRORS_TEXTS.UNAUTHORIZED}</h3>
      <h3>
        ログインページは
        <a href={LoginPage} target="_blank" rel="noopener noreferrer">
          こちら
        </a>
      </h3>
    </>
  )
}

export default Unauthorized
