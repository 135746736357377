import * as React from 'react'
import {
  ADMIN_ROUTES,
  APPLICATION_NAME,
  LOCAL_STORAGE_PAGE_KEY,
  PAGE_TITLES,
  TABLE_DEFAULT_ROWS,
} from 'commons/constants'
import { ADMIN_MENUS } from 'commons/menus'
import VehiclesTable from 'components/organisms/vehicles/Table'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { AddButton } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { CustomCsvImport } from 'components/atoms/FormComponents'
import { Box } from '@mui/material'
import { importVehicle } from 'components/apis/vehicles'
import { FlexBox } from 'components/atoms/BoxComponents'
import { useErrorHandler } from 'react-error-boundary'
import { styled } from '@mui/system'
import { Helmet } from 'react-helmet-async'
import { UserContext } from 'providers/UserProvider'
import { toastOnSuccess, toastOnErrorUseComponent } from 'commons/toaster'
import { Link } from 'react-router-dom'
import ProcessingModal from 'components/atoms/ProcessingModal'
import VehiclePaymentHistoryCsvModal from 'components/organisms/vehicles/paymentHistoryCsvModal'
import { useLocation } from 'react-router-dom'
import useVehicles from './hooks/useVehicles'
import ExportCsvModal from 'components/organisms/vehicles/ExportCsvModal'
import { getLocalStorageSearchParams } from 'commons/table'

function AdminVehiclesIndex() {
  const addBtnTitle = '車両の新規登録'
  const user = React.useContext(UserContext)
  const [isProcessingModalShow, setIsProcessingModalShow] =
    React.useState(false)

  const localStorageSearchParams = getLocalStorageSearchParams(
    LOCAL_STORAGE_PAGE_KEY.VEHICLES
  )

  const defaultSearchParams = { officeId: user.officeId }
  const [searchParams, setSearchParams] = React.useState<SearchVehicle>(
    localStorageSearchParams ?? defaultSearchParams
  )

  const [paginateParams, setPaginateParams] = React.useState<PaginateParams>({
    pageNumber: 1,
    displayLimit: TABLE_DEFAULT_ROWS,
  })
  const [showMaintenanceCsvModal, setShowMaintenanceCsvModal] =
    React.useState(false)
  const [showExportCsvModal, setShowExportCsvModal] = React.useState(false)
  const handleError = useErrorHandler()
  const queryParams = useLocation().search
  const focusId = new URLSearchParams(queryParams).get('focus_id')

  const InvisibleForMobile = styled('div')({
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  })

  const ToCsvSettingImport = () => (
    <div>
      CSVの紐付けが登録されていません。
      <br />
      <Link to="/admin/csv_settings/vehicle_import">
        マスタ管理→CSV設定→ヘッダーの設定
      </Link>
      から登録してください。
    </div>
  )

  const ToCsvSettingCautions = (errors) => (
    <div>
      CSVのインポートに失敗しました。
      <br />
      {errors ? (
        errorList(errors)
      ) : (
        <>
          お手数ですが
          <Link to="/admin/csv_settings/cautions/vehicle_import">
            インポートの注意事項
          </Link>
          をご確認した上で、再度実行してください。
        </>
      )}
    </div>
  )

  const errorList = (errors) => {
    return (
      <ul>
        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    )
  }

  const onInputChange = (e) => {
    setIsProcessingModalShow(true)
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    e.target.value = ''

    importVehicle(formData)
      .then(() => {
        toastOnSuccess('CSVインポート完了')
      })
      .catch((e) => {
        switch (e.response.status) {
          case 404:
            toastOnErrorUseComponent(ToCsvSettingImport())
            break
          case 422:
            toastOnErrorUseComponent(
              ToCsvSettingCautions(e.response.data.errors)
            )
            break
          default:
            handleError(e.response.status)
        }
      })
      .finally(() => {
        setIsProcessingModalShow(false)
      })
  }

  const { vehiclesData, isLoadingVehiclesData } = useVehicles(
    searchParams,
    paginateParams
  )

  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>{PAGE_TITLES.VEHICLES.INDEX ?? APPLICATION_NAME}</title>
        </Helmet>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
          }}
        >
          <h1>{ADMIN_MENUS.masters.children.vehicles.title}</h1>

          <FlexBox alignItems="center">
            <InvisibleForMobile>
              <ApplyButton
                disabled={vehiclesData?.totalCount === 0}
                onClick={() => setShowExportCsvModal(true)}
              >
                CSV出力
              </ApplyButton>
            </InvisibleForMobile>
            {user.isSystemAdmin && user.subscribedCsvSettings && (
              <InvisibleForMobile>
                <Box ml={2}>
                  <CustomCsvImport onChange={onInputChange} />
                </Box>
              </InvisibleForMobile>
            )}

            <Box ml={2}>
              <AddButton href={ADMIN_ROUTES.VEHICLES_NEW}>
                {addBtnTitle}
              </AddButton>
            </Box>
            <InvisibleForMobile>
              {/* 一括出力時に時間がかかりすぎてしまいタイムアウトするため改善必要。改善までコメントアウト */}
              {/* <Box ml={2}>
                <ExportButton
                  onClick={() =>
                    window.open(
                      `/api/v1/vehicles/ledgers/export_all_vehicles`,
                      '_blank'
                    )
                  }
                >
                  台帳一括出力
                </ExportButton>
              </Box> */}
            </InvisibleForMobile>
          </FlexBox>
        </Box>
        <VehiclesTable
          data={vehiclesData?.vehicles}
          totalCount={vehiclesData?.totalCount}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          defaultSearchParams={defaultSearchParams}
          paginateParams={paginateParams}
          setPaginateParams={setPaginateParams}
          focusId={parseInt(focusId)}
          isLoading={isLoadingVehiclesData}
        />
      </PageWrapper>
      <ProcessingModal isOpen={isProcessingModalShow} />
      <ExportCsvModal
        isModalOpen={showExportCsvModal}
        setIsModalOpen={setShowExportCsvModal}
        vehicleIds={vehiclesData?.vehicleIds}
        searchParams={searchParams}
      />
      <VehiclePaymentHistoryCsvModal
        showModal={showMaintenanceCsvModal}
        setShowModal={setShowMaintenanceCsvModal}
        vehicleIds={vehiclesData?.vehicleIds}
      />
    </>
  )
}

export default AdminVehiclesIndex
