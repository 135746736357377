import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { addParamPrefix, camelCaseKeysToUnderscore } from 'commons/functions'

export function fetchNotifications(
  searchParams?: SearchNotification,
  paginateParams?: PaginateParams
) {
  const customSearchParams = searchParams
    ? addParamPrefix(searchParams, 'search')
    : {}
  const customPaginateParams = paginateParams
    ? addParamPrefix(paginateParams, 'pagination')
    : {}
  const params = camelCaseKeysToUnderscore({
    ...customSearchParams,
    ...customPaginateParams,
  })
  return axios.get(API_ENDPOINTS.NOTIFICATIONS, {
    params: params,
  })
}

export function getNotification(id: number) {
  return axios.get([API_ENDPOINTS.NOTIFICATIONS, id].join(''))
}

export function createNotification(notificationParams: NotificationParams) {
  const params = camelCaseKeysToUnderscore(notificationParams)
  return axios.post(API_ENDPOINTS.NOTIFICATIONS, params)
}

export function updateNotification(notificationParams: NotificationParams) {
  const params = camelCaseKeysToUnderscore(notificationParams)
  return axios.patch([API_ENDPOINTS.NOTIFICATIONS, params.id].join(''), params)
}

export function deleteNotification(id: number) {
  return axios.delete([API_ENDPOINTS.NOTIFICATIONS, id].join(''))
}

export function fetchNotificationTypeOptions() {
  return axios.get(API_ENDPOINTS.NOTIFICATIONS_TYPE_LIST)
}
