import * as React from 'react'
import { Controller } from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomInput,
  CustomInputShort,
  CustomLabel,
  CustomRadioButton,
  CustomRadioGroup,
  CustomTextArea,
  HeadingText,
  YearMounthDayDatePicker,
} from 'components/atoms/FormComponents'
import { styled } from '@mui/styles'
import { ENUMS } from 'commons/enums'
import { FlexBox } from 'components/atoms/BoxComponents'
import NumberFormat from 'components/organisms/NumberFormat'
import { VEHICLES } from 'commons/constants'

export default function Purchase(props: { register; control; errors; type }) {
  const { register, control } = props

  const FormSubText = styled('p')({
    fontSize: '14px',
    marginLeft: '8px',
  })

  const MidiumCustomRadioGroup = styled(CustomRadioGroup)({
    width: '270px',
  })

  const CustomBoxMidium = styled('div')({
    width: '180px',
  })

  return (
    <BackGroundWhite>
      <HeadingText>{VEHICLES.LABELS.PURCHASE}</HeadingText>

      <CustomLabel>
        {VEHICLES.LABELS.PURCHASE_TYPE}
        <MidiumCustomRadioGroup>
          {Object.entries(ENUMS.VEHICLES.PURCHASE_TYPE).map(([key, obj]) => (
            <React.Fragment key={key}>
              <CustomRadioButton
                {...register('isPurchased')}
                id={`isPurchased_${obj.value}`}
                value={obj.value}
                type="radio"
              />
              <label htmlFor={`isPurchased_${obj.value}`}>{obj.label}</label>
            </React.Fragment>
          ))}
        </MidiumCustomRadioGroup>
      </CustomLabel>

      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {VEHICLES.LABELS.PURCHASE_DATE}
          <CustomBoxMidium>
            <Controller
              name="purchasedDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <YearMounthDayDatePicker
                  onChange={onChange}
                  selected={value && new Date(value)}
                />
              )}
            />
          </CustomBoxMidium>
        </CustomLabel>
        <CustomLabel>
          {VEHICLES.LABELS.PURCHASE_PRICE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name="purchasedPrice"
            control={control}
          />
        </CustomLabel>
      </FlexBox>

      <CustomLabel>
        {VEHICLES.LABELS.DEPRECATION_PERIOD}
        <FlexBox alignItems="center">
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name="depreciationPeriod"
            control={control}
          />
          <FormSubText>年</FormSubText>
        </FlexBox>
      </CustomLabel>

      <CustomLabel>
        {VEHICLES.LABELS.RETAILER}
        <CustomInput {...register('retailer', {})} />
      </CustomLabel>

      <CustomLabel>
        {VEHICLES.LABELS.RETAILER_CHARGE_PERSON}
        <CustomInput {...register('retailerChargePerson', {})} />
      </CustomLabel>

      <CustomLabel>
        {VEHICLES.LABELS.RETAILER_ADDRESS}
        <CustomInput {...register('retailerAddress', {})} />
      </CustomLabel>

      <CustomLabel>
        {VEHICLES.LABELS.RETAILER_PHONE_NUMBER}
        <CustomInput {...register('retailerPhoneNumber', {})} />
      </CustomLabel>

      <CustomLabel>
        {VEHICLES.LABELS.PURCHASE_MEMO}
        <CustomTextArea rows={5} {...register('purchaseMemo')} />
      </CustomLabel>
    </BackGroundWhite>
  )
}
