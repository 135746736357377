import * as React from 'react'
import { ADMIN_MENUS } from 'commons/menus'
import VehiclesForm from 'components/organisms/vehicles/Form'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { getVehicle } from 'components/apis/vehicles'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { useParams } from 'react-router-dom'
import SidebarAnchorList from 'components/organisms/layouts/SidebarAnchorList'
import { FlexBox } from 'components/atoms/BoxComponents'
import { useQuery } from '@tanstack/react-query'
import { theme } from 'components/theme'
import { styled } from '@mui/styles'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import { useErrorHandler } from 'react-error-boundary'
import { AxiosError } from 'axios'

function AdminVehiclesEdit() {
  const { id } = useParams()
  const handleError = useErrorHandler()

  const { data: vehicle, isLoading } = useQuery(
    [`admin/vehicles/${id}/edit`, 'vehicle'],
    () => getVehicle(parseInt(id)).then((res) => res.data),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      refetchOnWindowFocus: false,
    }
  )
  const Title = styled('h1')({
    [theme.breakpoints.down('sm')]: {
      margin: '12px',
    },
  })

  return (
    <FlexBox>
      {!isLoading && <SidebarAnchorList page="vehicles"></SidebarAnchorList>}
      <PageWrapper>
        <Helmet>
          <title>{PAGE_TITLES.VEHICLES.EDIT ?? APPLICATION_NAME}</title>
        </Helmet>
        <Title>{ADMIN_MENUS.masters.children.vehicles.title}</Title>
        {isLoading ? (
          <CircularProgressBox />
        ) : (
          <VehiclesForm type="update" data={vehicle} />
        )}
      </PageWrapper>
    </FlexBox>
  )
}

export default AdminVehiclesEdit
