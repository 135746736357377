import { useQuery } from '@tanstack/react-query'
import { createQueryKey } from 'commons/functions'
import { fetchCompanies } from 'components/apis/companies'
import { useErrorHandler } from 'react-error-boundary'

export default function useCompanies(
  searchParams?: SearchCompany,
  paginateParams?: PaginateParams
) {
  const handleError = useErrorHandler()
  const queryKey = createQueryKey('companies', {
    ...searchParams,
    ...paginateParams,
  })

  const { data: companyData, isLoading: isLoadingCompanyData } = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await fetchCompanies(searchParams, paginateParams)
      return response.data
    },
    onError: (error) => {
      handleError(error)
    },
  })

  return { companyData, isLoadingCompanyData }
}
