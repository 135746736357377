import { useTheme } from '@mui/material'
import { styled } from '@mui/styles'
import {
  DRIVER_LINK_LIST_ITEMS,
  VEHICLE_LINK_LIST_ITEMS,
  DELIVERY_REQUEST_LINK_LIST_ITEMS,
  PROPOSAL_DELIVERY_LINK_LIST_ITEMS,
  DRIVING_COURSE_NEWCOMER_LIST_ITEMS,
  DRIVING_COURSE_NEWCOMER_COURSE2_LIST_ITEMS,
} from 'commons/constants'
import * as React from 'react'
import AnchorList from './AnchorList'

type SidebarAnchorListProps = {
  page:
    | 'drivers'
    | 'vehicles'
    | 'delivery_requests'
    | 'proposal_deliveries'
    | 'driving_course_newcomer'
    | 'driving_course_newcomer_course2'
}

const SidebarAnchorList: React.FC<SidebarAnchorListProps> = (props) => {
  const theme = useTheme()
  const SidebarAnchorListInner = styled('div')({
    top: 0,
    position: 'sticky',
    width: '180px',
    height: '90vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  })

  const getDriverItems = () => {
    if (props.page == 'drivers') {
      return Object.values(DRIVER_LINK_LIST_ITEMS)
    } else if (props.page == 'vehicles') {
      return Object.values(VEHICLE_LINK_LIST_ITEMS)
    } else if (props.page == 'delivery_requests') {
      return Object.values(DELIVERY_REQUEST_LINK_LIST_ITEMS)
    } else if (props.page == 'proposal_deliveries') {
      return Object.values(PROPOSAL_DELIVERY_LINK_LIST_ITEMS)
    } else if (props.page == 'driving_course_newcomer') {
      return Object.values(DRIVING_COURSE_NEWCOMER_LIST_ITEMS)
    } else if (props.page == 'driving_course_newcomer_course2') {
      return Object.values(DRIVING_COURSE_NEWCOMER_COURSE2_LIST_ITEMS)
    }
  }
  {
    return (
      <SidebarAnchorListInner>
        <AnchorList items={getDriverItems()}></AnchorList>
      </SidebarAnchorListInner>
    )
  }
}

export default SidebarAnchorList
