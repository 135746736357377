import * as React from 'react'
import {
  APPLICATION_NAME,
  LOCAL_STORAGE_PAGE_KEY,
  PAGE_TITLES,
  ROUTES,
  TABLE_DEFAULT_ROWS,
} from 'commons/constants'
import { MENUS } from 'commons/menus'
import AccidentsTable from 'components/organisms/accidents/Table'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { AddButton } from 'components/atoms/ButtonComponenets'
import { Box } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import useAccidents from './hooks/useAccidents'
import { getLocalStorageSearchParams } from 'commons/table'

function AccidentsIndex() {
  const localStorageSearchParams = getLocalStorageSearchParams(
    LOCAL_STORAGE_PAGE_KEY.ACCIDENTS
  )
  const defaultSearchParams = {}
  const [searchParams, setSearchParams] = React.useState<SearchAccident>(
    localStorageSearchParams ?? defaultSearchParams
  )
  const [paginateParams, setPaginateParams] = React.useState<PaginateParams>({
    pageNumber: 1,
    displayLimit: TABLE_DEFAULT_ROWS,
  })
  const queryParams = useLocation().search
  const focusId = new URLSearchParams(queryParams).get('focus_id')

  const { accidentsData, isLoadingAccidentsData } = useAccidents(
    searchParams,
    paginateParams
  )

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.ACCIDENTS.INDEX ?? APPLICATION_NAME}</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1>{MENUS.inspectionAndAccident.children.accidents.title}</h1>
        <AddButton href={ROUTES.ACCIDENTS_NEW}>事故報告の新規作成</AddButton>
      </Box>
      <AccidentsTable
        data={accidentsData?.accidents}
        totalCount={accidentsData?.totalCount}
        setSearchParams={setSearchParams}
        paginateParams={paginateParams}
        setPaginateParams={setPaginateParams}
        searchParams={searchParams}
        defaultSearchParams={defaultSearchParams}
        focusId={parseInt(focusId)}
        isLoading={isLoadingAccidentsData}
      />
    </PageWrapper>
  )
}

export default AccidentsIndex
