import * as React from 'react'
import axios from 'commons/axiosClient'
import { AxiosError } from 'axios'
import { Controller, useForm } from 'react-hook-form'
import {
  CustomDropZone,
  CustomForm,
  CustomInput,
  CustomLabel,
  CustomTextArea,
} from 'components/atoms/FormComponents'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { ApplyButton } from 'components/atoms/ButtonComponenets'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { toastOnError } from 'commons/toaster'
import { useErrorHandler } from 'react-error-boundary'
import { fetchS3Images } from 'commons/fetchS3Images'
import { FlexBox } from 'components/atoms/BoxComponents'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

type EducationParams = {
  fileObjects: File[]
} & Education

export const EducationForm = ({ education }: { education?: Education }) => {
  const navigate = useNavigate()
  const handleError = useErrorHandler()
  const [fileObjects, setFileObjects] = React.useState<File[]>([])

  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3Images(education?.fileUrls)
      setFileObjects(files)
    })()
  }, [education?.fileUrls])

  const {
    register,
    control,
    formState: { errors },
    getValues,
  } = useForm<EducationParams>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...education,
      fileObjects,
    },
  })

  const method = (data) => {
    const headers = { 'Content-Type': 'multipart/form-data' }
    return education
      ? axios.patch(`/api/v1/educations/${education.id}`, data, { headers })
      : axios.post('/api/v1/educations', data, { headers })
  }

  const mutation = useMutation({
    mutationFn: method,
    onSuccess: () => {
      navigate(-1)
    },
    onError: (e: AxiosError<{ errors: string[] }>) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors.join(','))
          break
        default:
          handleError(e.response.status)
      }
    },
  })
  const handleSubmit = async (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append('education[title]', getValues('title'))
    data.append('education[summary]', getValues('summary'))
    fileObjects.forEach((image) => data.append('education[files][]', image))
    await mutation.mutateAsync(data)
    navigate('/admin/educations')
  }

  // 画像設定時のコールバック
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setFileObjects([...fileObjects, ...acceptedFiles])
    },
    [fileObjects]
  )

  return (
    <CustomForm>
      <BackGroundWhite>
        <CustomLabel>
          項目名
          <CustomInput {...register('title')} required />
          {errors.title && <span>必須項目です</span>}
        </CustomLabel>
        <CustomLabel>
          教育の概要（受講履歴出力時に表示されます）
          <CustomTextArea rows={5} {...register('summary')} />
        </CustomLabel>
        <CustomLabel>
          添付資料
          <Controller
            render={() => (
              <CustomDropZone
                multiple={true}
                onDrop={onDrop}
                accept={'image/*,application/pdf'}
              />
            )}
            name="fileObjects"
            control={control}
          />
        </CustomLabel>
        {Object.values(fileObjects)?.map((image: File, index: number) => (
          <FlexBox flexDirection={'row'} key={index}>
            <p>
              <a
                href={URL.createObjectURL(image)}
                download={decodeURI(image.name)}
              >
                {decodeURI(image.name)}
              </a>
            </p>
            <IconButton
              component="span"
              onClick={() =>
                setFileObjects(fileObjects.filter((_, i) => i !== index))
              }
            >
              <CancelIcon />
            </IconButton>
          </FlexBox>
        ))}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ApplyButton
            pattern="block"
            color="secondary" // 色味を変更するためのprops
            onClick={() => navigate('/admin/educations')}
          >
            保存せずに戻る
          </ApplyButton>
          <ApplyButton
            pattern="block"
            onClick={(e) => {
              e.preventDefault()
              handleSubmit(e)
            }}
          >
            保存する
          </ApplyButton>
        </div>
      </BackGroundWhite>
    </CustomForm>
  )
}
