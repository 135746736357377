import * as React from 'react'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { Helmet } from 'react-helmet-async'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { ROUTE_PATH, EXECUTIVE_USER_ROUTE_PATH } from 'commons/constants'
import { Box } from '@mui/material'

function ExecutivesFundContactsComplete() {
  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.FUND_CONTACTS.COMPLETE ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>経営・資金相談問い合わせ</h1>
      <BackGroundWhite>
        <h2>お問い合わせを受け付けました。</h2>
        <Box sx={{ marginBottom: '40px' }}>
          この度はお問い合わせをいただき誠にありがとうございます。
          <br />
          お問い合わせいただきました内容を確認の上、担当者よりご連絡をさせていただきます。
          <br />
          いましばらくお待ちくださいますよう、よろしくお願い申し上げます。
        </Box>

        <FlexBox gap={2}>
          <ApplyButton href={EXECUTIVE_USER_ROUTE_PATH}>
            経営者トップページへ
          </ApplyButton>
          <ApplyButton href={ROUTE_PATH}>モビポケトップページへ</ApplyButton>
        </FlexBox>
      </BackGroundWhite>
    </PageWrapper>
  )
}

export default ExecutivesFundContactsComplete
