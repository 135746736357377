import * as React from 'react'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { Link } from 'react-router-dom'
import { useErrorHandler } from 'react-error-boundary'
import { fetchAllEducationVideos } from 'components/apis/educations'
import { EDUCATION_LIST_TEXT } from 'commons/constants'
import { ExplanationChip } from 'components/atoms/chips/ExplanationChip'

type EducationLinkProps = {
  education: Education
  isThisMonth?: boolean
}

function EducationLink(props: EducationLinkProps) {
  const { education, isThisMonth } = props

  return (
    <>
      <Link to={`${education.id}/`}>{education.title}</Link>
      {education.month && (
        <span style={{ marginLeft: '8px' }}>({education.month}月)</span>
      )}
      {isThisMonth && (
        <ExplanationChip label="今月の項目" style={{ marginLeft: '8px' }} />
      )}
    </>
  )
}

function VideoLogLinks(props: { videos; currentMonth }) {
  return (
    <ol>
      {props.videos.map((item) => (
        <React.Fragment key={item.id}>
          <li>
            <EducationLink
              education={item}
              isThisMonth={props.currentMonth === item.month}
            />
          </li>
        </React.Fragment>
      ))}
    </ol>
  )
}

function AdminVideoList() {
  const [educationVideos, setEducationVideos] = React.useState([])
  const [accidentVideos, setAccidentVideos] = React.useState([])
  const [nearMissVideos, setNearMissVideos] = React.useState([])
  const [kytVideos, setKytVideos] = React.useState([])
  const [businessVideos, setBusinessVideos] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const currentMonth = new Date().getMonth() + 1
  const handleError = useErrorHandler()

  React.useEffect(() => {
    fetchAllEducationVideos()
      .then((response) => {
        setEducationVideos(response.data.education_videos)
        setAccidentVideos(response.data.accident_videos)
        setNearMissVideos(response.data.near_miss_videos)
        setKytVideos(response.data.kyt_videos)
        setBusinessVideos(response.data.business_videos)
      })
      .catch((e) => {
        handleError(e.response.status)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return loading ? (
    <CircularProgressBox />
  ) : (
    <>
      <span style={{ whiteSpace: 'pre-wrap' }}>{EDUCATION_LIST_TEXT}</span>
      <h3>法定項目</h3>
      <VideoLogLinks videos={educationVideos} currentMonth={currentMonth} />
      <h3>事故動画</h3>
      <VideoLogLinks videos={accidentVideos} currentMonth={currentMonth} />
      <h3>ヒヤリハット動画</h3>
      <VideoLogLinks videos={nearMissVideos} currentMonth={currentMonth} />
      <h3>危険予知訓練(KYT)</h3>
      <VideoLogLinks videos={kytVideos} currentMonth={currentMonth} />
      <h3>業務教育</h3>
      <VideoLogLinks videos={businessVideos} currentMonth={currentMonth} />
    </>
  )
}

export default AdminVideoList
