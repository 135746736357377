import ja from 'date-fns/locale/ja'
import React from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/src/stylesheets/datepicker.scss'
import { InputProps } from 'react-select'

import { styled } from '@mui/system'

type Props = Partial<
  InputProps & {
    inputRef: React.Ref<HTMLInputElement>
    selected: string | Date
    variant: keyof typeof variants
    pattern: keyof typeof patterns
  }
>

export const CustomDatePicker = (props: Props) => {
  registerLocale('ja', ja)
  const Component = styled(ReactDatePicker)(patterns[props.pattern] ?? primary)

  return (
    <Component
      {...props}
      {...variants[props.variant]}
      locale="ja"
      autoComplete="off"
    />
  )
}

const primary = {
  border: '1px solid #ccc',
  borderRadius: 6,
  height: 36,
  width: 180,
  maxWidth: '100%',
  padding: '0 0.5rem',
}

const secondary = {
  ...primary,
  height: 40,
  fontSize: '1rem',
}

const patterns = { primary, secondary }

const variants = {
  yearMonthDate: {
    dateFormat: 'yyyy年MM月dd日',
    placeholderText: '年/月/日',
  },
  yearMonth: {
    dateFormat: 'yyyy年MM月',
    placeholderText: '年/月',
    showMonthYearPicker: true,
  },
  monthDate: {
    dateFormat: 'MM月dd日',
    placeholderText: '月/日',
  },
  hourMinutes: {
    dateFormat: 'HH:mm',
    placeholderText: '時:分',
    showTimeSelectOnly: true,
    showTimeSelect: true,
  },
}
