import * as React from 'react'
import { Box, Alert } from '@mui/material'

type NotificationPropsType = {
  severity: 'info' | 'error'
  texts: string[]
}

const AlertStyle = {
  container: {
    border: '1px solid gray',
    marginBottom: '20px',
    fontSize: '14px',
  },
  ul: {
    margin: '0px',
    paddingLeft: '20px',
  },
}

type MessageType = {
  text: string
}

function Message({ text }: MessageType) {
  return <>{text}</>
}

type MessageListProps = {
  texts: string[]
}

function MessageList({ texts }: MessageListProps) {
  return (
    <ul style={AlertStyle.ul}>
      {texts.map((text, index) => (
        <li key={index}>
          <Message text={text} />
        </li>
      ))}
    </ul>
  )
}

function AlertMessage({ severity, texts }: NotificationPropsType) {
  if (texts.length === 0) {
    return null
  }

  return (
    <Box mb={2}>
      <Alert severity={severity} style={AlertStyle.container}>
        {texts.length > 1 ? (
          <MessageList texts={texts} />
        ) : (
          <Message text={texts[0]} />
        )}
      </Alert>
    </Box>
  )
}

export default AlertMessage
