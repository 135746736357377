import * as React from 'react'
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormGetValues,
} from 'react-hook-form'
import {
  BackGroundWhite,
  BackGroundPalePink,
} from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  ErrorMessage,
  ConvertibleCustomDateSelect,
  ConvertibleCustomInput,
  ConvertibleCustomDropZone,
  ConvertibleCustomCheckBox,
  ConvertibleCustomTextArea,
  ConvertibleCustomSelect,
  ConvertibleCustomRadioGroup,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { DRIVERS, LICENSES, VALIDATION_MESSAGES } from 'commons/constants'
import { Box } from '@mui/material'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { useNavigate } from 'react-router-dom'
import { LICENSE_TYPE } from 'commons/licenseType'
import { DriverKeys } from 'types/driverForm'
import { theme } from 'components/theme'
import { ENUMS } from 'commons/enums'
import { TOP_LICENSE_TYPE } from 'commons/topLicenseType'

type LicenseProps = {
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  licenseImages: File[]
  setLicenseImages: React.Dispatch<React.SetStateAction<File[]>>
  mode: FormMode
  handleModeChange: (name: DriverFormModeMapKey, mode: FormMode) => void
}

const License = (props: LicenseProps) => {
  const navigate = useNavigate()

  const reload = () => {
    if (
      window.confirm('保存していないデータは削除されます。よろしいですか？')
    ) {
      navigate(0)
    }
  }

  const onDrop = (acceptedFiles: File[]) => {
    props.setLicenseImages([...props.licenseImages, ...acceptedFiles])
  }
  const removeLicenseImage = (index: number) => {
    props.setLicenseImages(props.licenseImages.filter((_, i) => i !== index))
  }

  const checkBoxShowText = React.useMemo(() => {
    return LICENSE_TYPE.reduce((prev, cur) => {
      if (props.getValues(`license.${cur.value}` as unknown as DriverKeys)) {
        if (prev == '') return prev + `${cur.label}`
        return prev + ` / ${cur.label}`
      }
      return prev
    }, '')
  }, [props.mode])

  return (
    <BackGroundWhite>
      <FlexBox justifyContent="space-between" marginBottom="16px">
        <HeadingText>{DRIVERS.LABELS.LICENSE}</HeadingText>
        <Box>
          {/* 一時的にコメントアウト */}
          {/* {props.mode == 'show' && (
            <EditButtonOutlined
              onClick={() => {
                props.handleModeChange('license', 'edit')
              }}
            >
              編集
            </EditButtonOutlined>
          )} */}
          {props.mode == 'edit' && (
            <FlexBox>
              <ApplyButton pattern="marginRight8" type="submit">
                保存する
              </ApplyButton>
              <CancelButtonOutlined onClick={reload}>
                キャンセル
              </CancelButtonOutlined>
            </FlexBox>
          )}
        </Box>
      </FlexBox>
      <FlexBox alignItems="center">
        <CustomLabel sx={{ marginRight: '32px' }}>
          {LICENSES.LABELS.LICENSE_NUMBER}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey="license.licenseNumber"
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors?.license?.licenseNumber && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
      </FlexBox>
      <FlexBox
        alignItems="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel>
          {LICENSES.LABELS.ISSUANCED_DATE}
          <ConvertibleCustomDateSelect
            mode={props.mode}
            formKeys={{
              year: `license.issuancedYear`,
              month: `license.issuancedMonth`,
              day: `license.issuancedDay`,
            }}
            control={props.control}
            getValues={props.getValues}
          />
        </CustomLabel>
        <CustomLabel sx={{ marginRight: '32px' }}>
          {LICENSES.LABELS.EXPIRED_DATE}
          <ConvertibleCustomDateSelect
            mode={props.mode}
            formKeys={{
              year: `license.expiredYear`,
              month: `license.expiredMonth`,
              day: `license.expiredDay`,
            }}
            control={props.control}
            getValues={props.getValues}
          />
        </CustomLabel>
      </FlexBox>
      <CustomLabel sx={{ marginRight: '32px' }}>
        {LICENSES.LABELS.LICENSE_TYPE}
        <ConvertibleCustomCheckBox
          mode={props.mode}
          baseKey="license"
          control={props.control}
          items={LICENSE_TYPE}
          showText={checkBoxShowText || '未入力'}
        ></ConvertibleCustomCheckBox>
      </CustomLabel>

      <BackGroundPalePink sx={{ marginBottom: '16px' }}>
        <HeadingText>{DRIVERS.LABELS.LICENSE_DATE}</HeadingText>
        <CustomLabel sx={{ marginBottom: '3px' }}>
          {DRIVERS.LABELS.BASIC_LICENSE}
          <ConvertibleCustomRadioGroup
            mode={props.mode}
            items={Object.entries(ENUMS.DRIVERS.BASIC_LICENSE)}
            formKey={'license.basicLicense'}
            getValues={props.getValues}
            register={props.register}
          />
        </CustomLabel>
        <CustomLabel>
          {DRIVERS.LABELS.BASIC_LICENSE_DATE}
          <ConvertibleCustomDateSelect
            mode={props.mode}
            formKeys={{
              year: `license.basicLicenseYear`,
              month: `license.basicLicenseMonth`,
              day: `license.basicLicenseDay`,
            }}
            control={props.control}
            getValues={props.getValues}
          />
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '3px' }}>
          {DRIVERS.LABELS.TOP_LICENSE}
          <ConvertibleCustomSelect
            mode={props.mode}
            formKey={'license.topLicense'}
            control={props.control}
            getValues={props.getValues}
            options={TOP_LICENSE_TYPE}
          />
        </CustomLabel>
        <CustomLabel>
          {DRIVERS.LABELS.TOP_LICENSE_DATE}
          <ConvertibleCustomDateSelect
            mode={props.mode}
            formKeys={{
              year: `license.topLicenseYear`,
              month: `license.topLicenseMonth`,
              day: `license.topLicenseDay`,
            }}
            control={props.control}
            getValues={props.getValues}
          />
        </CustomLabel>
      </BackGroundPalePink>

      <CustomLabel sx={{ width: '100%' }}>
        {LICENSES.LABELS.FILES}
        <ConvertibleCustomDropZone
          mode={props.mode}
          formKey={`license.files`}
          getValues={props.getValues}
          control={props.control}
          files={props.licenseImages}
          onDrop={onDrop}
          onCancelIconClick={removeLicenseImage}
        />
      </CustomLabel>
      <CustomLabel sx={{ marginRight: '32px' }}>
        {LICENSES.LABELS.MEMO}
        <ConvertibleCustomTextArea
          mode={props.mode}
          formKey={`license.memo`}
          register={props.register}
          getValues={props.getValues}
        />
      </CustomLabel>
    </BackGroundWhite>
  )
}

export default License
