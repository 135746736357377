import * as React from 'react'
import * as NF from 'react-number-format'
import { toastOnError } from 'commons/toaster'

const NumberFormat = (props) => {
  const [toastActive, setToastActive] = React.useState(false)

  const displayDialog = (e) => {
    const number_full_width_keycode = 229
    if (e.keyCode === number_full_width_keycode) {
      if (!toastActive) {
        toastOnError(
          `半角入力の数字のみ入力可能です`,
          () => setToastActive(true),
          () => setToastActive(false)
        )
      }
    }
  }

  return <NF.default {...props} onKeyDown={(e) => displayDialog(e)} />
}

export default NumberFormat
