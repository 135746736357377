import * as React from 'react'
import { MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { getArticle } from 'components/apis/articles'
import { useParams, useNavigate } from 'react-router-dom'
import { useErrorHandler } from 'react-error-boundary'
import ArticleDetail from 'components/organisms/articles/Detail'
import { FlexBox } from 'components/atoms/BoxComponents'
import { useTheme } from '@mui/material'

function ArticlesShow() {
  const [article, setArticle] = React.useState<Article>(null)
  const [loading, setLoading] = React.useState(true)
  const { id } = useParams()
  const theme = useTheme()
  const navigate = useNavigate()
  const handleError = useErrorHandler()

  React.useEffect(() => {
    getArticle(parseInt(id))
      .then((response) => {
        setArticle(response.data)
      })
      .catch((e) => {
        handleError(e.response.status)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <PageWrapper>
      <FlexBox
        justifyContent={'space-between'}
        sx={{
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
          alignItems: 'center',
        }}
      >
        <h1>{MENUS.articles.title}</h1>
      </FlexBox>
      {loading ? <CircularProgressBox /> : <ArticleDetail data={article} />}
    </PageWrapper>
  )
}

export default ArticlesShow
