import * as React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/styles'
import CustomCenteringModal from 'components/atoms/CustomCenteringModal'
import { palette } from 'components/theme'

const ModalHeader = styled('div')({
  top: 0,
  left: 0,
  width: '103vw',
  height: '9vh',
  zIndex: 10000,
  position: 'fixed',
  backgroundColor: palette.header.background,
})

const StyledImg = styled('img')({
  top: 0,
  left: 0,
  width: '100%',
  height: 'auto',
  position: 'absolute',
})

type Props = {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  education: Education
}

export default function EducationDocumentModal({
  isModalOpen,
  setIsModalOpen,
  education,
}: Props) {
  return (
    <>
      <ModalHeader style={{ visibility: isModalOpen ? 'visible' : 'hidden' }}>
        <Button
          onClick={() => {
            setIsModalOpen(false)
          }}
          style={{
            padding: '6vw',
            fontSize: '1.25rem',
            color: palette.header.text,
          }}
        >
          <img src="/images/back_arrow.png" style={{ marginRight: '.75rem' }} />
          戻る
        </Button>
      </ModalHeader>
      <CustomCenteringModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        style={{ width: '103vw', height: '90vh', inset: '9vh 0' }}
      >
        <StyledImg src={education.documentPath} />
      </CustomCenteringModal>
    </>
  )
}
