import * as React from 'react'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { Box, styled } from '@mui/material'
import { palette, theme } from 'components/theme'
import { VEHICLES } from 'commons/constants'
import { FlexBox } from 'components/atoms/BoxComponents'

type BodyInfoProps = {
  data: Vehicle
}

export default function BodyInfo(props: BodyInfoProps) {
  const Inner = styled('div')({
    padding: '32px',
  })
  const Title = styled('h2')({
    fontSize: '20px',
    margin: 0,
    marginBottom: '16px',
  })
  const SubTitle = styled('p')({
    fontSize: '16px',
    margin: 0,
    color: palette.text.gray.deep,
    fontWeight: 'bold',
  })
  const Border = styled('div')({
    width: '100%',
    borderBottom: `1px solid ${palette.border.pale}`,
  })
  const Text = styled('p')({
    fontSize: '16px',
    margin: 0,
  })
  const Label = styled('p')({
    fontSize: '14px',
    margin: 0,
    color: palette.text.gray.pale,
    marginTop: '32px',
    marginBottom: '8px',
  })
  const TextWrapper = styled('div')({
    width: '300px',
    paddingBottom: '8px',
    paddingLeft: '4px',
    borderBottom: `1px solid ${palette.border.pale}`,
    overflowWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  })
  const TextWrapperShort = styled(TextWrapper)({
    width: '140px',
  })

  const booleanTypes = {
    '0': 'なし',
    '1': 'あり',
  }
  const temperatureZone = {
    freeze: '低温',
    chill: '中温',
    keep: '保冷',
    warm: '加温',
  }

  const getCoolerTemperatureText = React.useCallback((): string => {
    let text = ''
    if (props.data.coolerTemperatureFreeze) text += temperatureZone.freeze
    if (props.data.coolerTemperatureChill) {
      if (text !== '') text += ' / '
      text += temperatureZone.chill
    }
    if (props.data.coolerTemperatureKeep) {
      if (text !== '') text += ' / '
      text += temperatureZone.keep
    }
    if (props.data.coolerTemperatureWarm) {
      if (text !== '') text += ' / '
      text += temperatureZone.warm
    }
    return text
  }, [
    props.data.coolerTemperatureFreeze,
    props.data.coolerTemperatureChill,
    props.data.coolerTemperatureKeep,
    props.data.coolerTemperatureWarm,
  ])

  const bodyManufacturedDate = props.data.bodyManufacturedDate
    ? props.data.bodyManufacturedDate.slice(0, -3)
    : ''
  const coolerManufacturedDate = props.data.coolerManufacturedDate
    ? props.data.coolerManufacturedDate.slice(0, -3)
    : ''

  return (
    <BackGroundWhite withoutPadding={true}>
      <Inner>
        <Title>{VEHICLES.LABELS.BODY}</Title>
        <FlexBox
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Box mr={3}>
            <Label>{VEHICLES.LABELS.MANUFACTURE}</Label>
            <TextWrapper>
              <Text>{props.data.bodyManufacturer || '未入力'}</Text>
            </TextWrapper>
          </Box>
          <Box>
            <Label>{VEHICLES.LABELS.BODY_SERIAL_NUMBER}</Label>
            <TextWrapper>
              <Text>{props.data.bodySerialNumber || '未入力'}</Text>
            </TextWrapper>
          </Box>
        </FlexBox>
        <FlexBox
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Box mr={3}>
            <Label>{VEHICLES.LABELS.BODY_MANUFACTURED_DATE}</Label>
            <TextWrapper>
              <Text>{bodyManufacturedDate || '未入力'}</Text>
            </TextWrapper>
          </Box>
        </FlexBox>
        <FlexBox
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Box mr={3}>
            <Label>{VEHICLES.LABELS.BODY_INNER}</Label>
            <TextWrapper>
              <Text>
                長さ:
                {props.data.bodyInnerLength
                  ? `${props.data.bodyInnerLength}cm `
                  : '未入力 '}
                幅:
                {props.data.bodyInnerWidth
                  ? `${props.data.bodyInnerWidth}cm `
                  : '未入力 '}
                高さ:
                {props.data.bodyInnerHeight
                  ? `${props.data.bodyInnerHeight}cm `
                  : '未入力 '}
              </Text>
            </TextWrapper>
          </Box>
          <Box>
            <Label>{VEHICLES.LABELS.BODY_CAPACITY}</Label>
            <TextWrapperShort>
              <Text>
                {props.data.bodyCapacity
                  ? `${props.data.bodyCapacity}立米`
                  : '未入力'}
              </Text>
            </TextWrapperShort>
          </Box>
        </FlexBox>
        <FlexBox
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Box mr={3}>
            <Label>{VEHICLES.LABELS.BODY_WALL_THICKNESS}</Label>
            <TextWrapperShort>
              <Text>
                {props.data.bodyWallThickness
                  ? `${props.data.bodyWallThickness}cm `
                  : '未入力'}
              </Text>
            </TextWrapperShort>
          </Box>
          <Box mr={3}>
            <Label>{VEHICLES.LABELS.BODY_FLOOR_HEIGHT}</Label>
            <TextWrapperShort>
              <Text>
                {props.data.bodyFloorHeight
                  ? `${props.data.bodyFloorHeight}cm `
                  : '未入力'}
              </Text>
            </TextWrapperShort>
          </Box>
          <Box>
            <Label>{VEHICLES.LABELS.BODY_GATE_HEIGHT}</Label>
            <TextWrapperShort>
              <Text>
                {props.data.bodyGateHeight
                  ? `${props.data.bodyGateHeight}cm`
                  : '未入力'}
              </Text>
            </TextWrapperShort>
          </Box>
        </FlexBox>
        <FlexBox
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Box mr={3}>
            <Label>{VEHICLES.LABELS.BODY_GATE_FRAME}</Label>
            <TextWrapper>
              <Text>
                幅:
                {props.data.bodyGateFrameWidth
                  ? `${props.data.bodyGateFrameWidth}cm `
                  : '未入力 '}
                高さ:
                {props.data.bodyGateFrameHeight
                  ? `${props.data.bodyGateFrameHeight}cm `
                  : '未入力 '}
              </Text>
            </TextWrapper>
          </Box>
        </FlexBox>
      </Inner>
      <Border></Border>
      <Inner>
        <SubTitle>{VEHICLES.LABELS.COOLER}</SubTitle>
        <FlexBox
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Box mr={3}>
            <Label>{VEHICLES.LABELS.COOLER_MANUFACTURE}</Label>
            <TextWrapper>
              <Text>{props.data.coolerManufacturer || '未入力'}</Text>
            </TextWrapper>
          </Box>
          <Box>
            <Label>{VEHICLES.LABELS.COOLER_MODEL}</Label>
            <TextWrapper>
              <Text>{props.data.coolerModel || '未入力'}</Text>
            </TextWrapper>
          </Box>
        </FlexBox>
        <FlexBox
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Box mr={3}>
            <Label>{VEHICLES.LABELS.COOLER_SERIAL_NUMBER}</Label>
            <TextWrapper>
              <Text>{props.data.coolerSerialNumber || '未入力'}</Text>
            </TextWrapper>
          </Box>
          <Box>
            <Label>{VEHICLES.LABELS.COOLER_MANUFACTURED_DATE}</Label>
            <TextWrapper>
              <Text>{coolerManufacturedDate || '未入力'}</Text>
            </TextWrapper>
          </Box>
        </FlexBox>
        <FlexBox
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Box mr={3}>
            <Label>{VEHICLES.LABELS.COOLER_FREON_TYPE}</Label>
            <TextWrapper>
              <Text>{props.data.coolerFreonType || '未入力'}</Text>
            </TextWrapper>
          </Box>
          <Box>
            <Label>{VEHICLES.LABELS.COOLER_TEMPERATURE}</Label>
            <TextWrapper>
              <Text>{getCoolerTemperatureText() || '未入力'}</Text>
            </TextWrapper>
          </Box>
        </FlexBox>
        <FlexBox
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Box mr={3}>
            <Label>{VEHICLES.LABELS.COOLER_HAS_STANDBY_DEVICE}</Label>
            <TextWrapperShort>
              <Text>
                {booleanTypes[props.data.coolerHasStandbyDevice] || '未入力'}
              </Text>
            </TextWrapperShort>
          </Box>
          <Box mr={3}>
            <Label>{VEHICLES.LABELS.COOLER_FLOOER_COUNT}</Label>
            <TextWrapper>
              <Text>{props.data.coolerFloorCount || '未入力'}</Text>
            </TextWrapper>
          </Box>
          <Box>
            <Label>{VEHICLES.LABELS.COOLER_EVAPORATOR_COUNT}</Label>
            <TextWrapperShort>
              <Text>{props.data.coolerEvaporatorCount || '未入力'}</Text>
            </TextWrapperShort>
          </Box>
        </FlexBox>
        <FlexBox
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Box mr={3}>
            <Label>{VEHICLES.LABELS.BODY_GATE_MATERIAL}</Label>
            <TextWrapper>
              <Text>{props.data.bodyGateMaterial || '未入力'}</Text>
            </TextWrapper>
          </Box>
          <Box mr={3}>
            <Label>{VEHICLES.LABELS.SIDE_DOOR}</Label>
            <TextWrapperShort>
              <Text>
                {booleanTypes[props.data.bodyHasSidedoor] || '未入力'}
              </Text>
            </TextWrapperShort>
          </Box>
        </FlexBox>
      </Inner>
    </BackGroundWhite>
  )
}
