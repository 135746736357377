import * as React from 'react'
import { Box } from '@mui/material'
import { APPLICATION_NAME, HEAD_TEXTS, PAGE_TITLES } from 'commons/constants'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import EducationTestsForm from 'components/organisms/education_tests/Form'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

function EducationTestsShow() {
  const { id } = useParams()

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.EDUCATIONS.TESTS[id] ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>{HEAD_TEXTS.WEB_TEST}</h1>
      <Box sx={{ marginTop: '30px' }}>
        <EducationTestsForm />
      </Box>
    </PageWrapper>
  )
}

export default EducationTestsShow
