import * as React from 'react'
import {
  CustomLabel,
  CustomInputShort,
  CustomDateSelect,
  CustomTextArea,
  CustomDropZone,
} from 'components/atoms/FormComponents'
import {
  Control,
  FieldErrors,
  UseFormRegister,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { BackGroundPalePink } from 'components/atoms/BackGroundComponents'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { styled } from '@mui/styles'
import { FlexBox } from 'components/atoms/BoxComponents'
import { VEHICLES } from 'commons/constants'
import NumberFormat from 'components/organisms/NumberFormat'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

type TaxCardProps = {
  register: UseFormRegister<Vehicle>
  control: Control<Vehicle>
  setValue: UseFormSetValue<Vehicle>
  watch: UseFormWatch<Vehicle>
  errors?: FieldErrors<Vehicle>
  appendEmptyItem: () => void
  deleteItem: (index: number) => void
  index: number
}

const TaxCard: React.FC<TaxCardProps> = (props) => {
  const { register, control, setValue, watch, index } = props
  const CardTitle = styled('p')({
    fontWeight: 'bold',
  })
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const old_files = watch(`taxBase.${index}.files`)
        ? watch(`taxBase.${index}.files`)
        : []
      setValue(`taxBase.${index}.files`, [...old_files, ...acceptedFiles])
    },
    [watch(`taxBase.${index}.files`)]
  )

  return (
    <BackGroundPalePink sx={{ marginBottom: '30px' }}>
      <CardTitle>
        {VEHICLES.LABELS.TAX_BASE}
        {index + 1}
      </CardTitle>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.TAX_BASE_TOTAL_FEE}
        <FlexBox alignItems="center">
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`taxBase.${index}.totalFee`}
            control={control}
          />
        </FlexBox>
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.TAX_BASE_START_DATE}
        <CustomDateSelect
          formKeys={{
            year: `taxBase.${index}.startYear`,
            month: `taxBase.${index}.startMonth`,
            day: `taxBase.${index}.startDay`,
          }}
          control={control}
        />
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.TAX_BASE_END_DATE}
        <CustomDateSelect
          formKeys={{
            year: `taxBase.${index}.endYear`,
            month: `taxBase.${index}.endMonth`,
            day: `taxBase.${index}.endDay`,
          }}
          control={control}
        />
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.TAX_BASE_MEMO}
        <CustomTextArea {...register(`taxBase.${index}.memo`)} />
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.FILE_UPLOAD}(
        {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
        <Controller
          render={() => (
            <CustomDropZone
              multiple={true}
              onDrop={onDrop}
              accept={'image/*, application/pdf'}
            />
          )}
          name={`taxBase.${index}.fileUrls`}
          control={control}
        />
        {watch(`taxBase.${index}.files`) &&
          Object.values(watch(`taxBase.${index}.files`))?.map(
            (image: File, fileIndex: number) => (
              <FlexBox flexDirection={'row'} key={fileIndex}>
                <p>
                  <a
                    href={URL.createObjectURL(image)}
                    download={decodeURI(image.name)}
                  >
                    {decodeURI(image.name)}
                  </a>
                </p>
                <IconButton
                  component="span"
                  onClick={() =>
                    setValue(
                      `taxBase.${index}.files`,
                      watch(`taxBase.${index}.files`).filter(
                        (_, i) => i !== fileIndex
                      )
                    )
                  }
                >
                  <CancelIcon />
                </IconButton>
              </FlexBox>
            )
          )}
      </CustomLabel>
      <DeleteButton onClick={() => props.deleteItem(index)}>削除</DeleteButton>
    </BackGroundPalePink>
  )
}

export default TaxCard
