// Educationを新規作成するページを実装してください。Formは別コンポーネントで定義するので、このページはラッパーとしての粒度で大丈夫です

import PageWrapper from 'components/organisms/layouts/PageWrapper'
import React from 'react'
import { EducationForm } from './components'

const AdminEducationsNew = () => {
  return (
    <PageWrapper>
      <EducationForm />
    </PageWrapper>
  )
}

export default AdminEducationsNew
