import * as React from 'react'
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormGetValues,
  useFieldArray,
} from 'react-hook-form'
import {
  BackGroundPalePink,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  ErrorMessage,
  ConvertibleCustomInput,
  ConvertibleCustomSelect,
  ConvertibleCustomDateSelect,
  ConvertibleCustomDropZone,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import {
  DRIVERS,
  APTITUDE_TEST_RECORDS,
  VALIDATION_MESSAGES,
} from 'commons/constants'
import { Box, styled } from '@mui/material'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { useNavigate } from 'react-router-dom'
import { palette } from 'components/theme'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

type AptitudeTestRecordsProps = {
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
  handleModeChange: (mapKey: DriverFormModeMapKey, mode: FormMode) => void
  aptitudeTestRecordsFiles: File[]
  aptitudeTestRecordChildrenFiles: File[][]
  setAptitudeTestRecordsFiles: React.Dispatch<React.SetStateAction<File[]>>
}

export default function AptitudeTestRecords(props: AptitudeTestRecordsProps) {
  const navigate = useNavigate()
  const { fields } = useFieldArray({
    control: props.control,
    name: 'aptitudeTestRecords',
  })

  const reload = () => {
    if (
      window.confirm('保存していないデータは削除されます。よろしいですか？')
    ) {
      navigate(0)
    }
  }

  const onDrop = (acceptedFiles: File[]) => {
    props.setAptitudeTestRecordsFiles([
      ...props.aptitudeTestRecordsFiles,
      ...acceptedFiles,
    ])
  }
  const removeAptitudeTestRecordImage = (index: number) => {
    props.setAptitudeTestRecordsFiles(
      props.aptitudeTestRecordsFiles.filter((_, i) => i !== index)
    )
  }

  return (
    <BackGroundWhite>
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        marginBottom="16px"
      >
        <HeadingText>{DRIVERS.LABELS.APTITUDE_TEST}</HeadingText>
        <Box>
          {/* 一時的にコメントアウト */}
          {/* {props.mode == 'show' && (
            <EditButtonOutlined
              onClick={() => {
                props.handleModeChange('aptitudeTestRecords', 'edit')
              }}
            >
              編集
            </EditButtonOutlined>
          )} */}
          {props.mode == 'edit' && (
            <FlexBox>
              <ApplyButton pattern="marginRight8" type="submit">
                保存する
              </ApplyButton>
              <CancelButtonOutlined onClick={reload}>
                キャンセル
              </CancelButtonOutlined>
            </FlexBox>
          )}
        </Box>
      </FlexBox>
      {fields.map((_, i) => {
        return (
          <Box key={`aptitude-test-record-${i}`} mb={2}>
            <AptitudeTestRecordInner
              index={i}
              mode={props.mode}
              control={props.control}
              register={props.register}
              errors={props.errors}
              getValues={props.getValues}
              aptitudeTestRecordChildrenFiles={
                props.aptitudeTestRecordChildrenFiles
              }
            ></AptitudeTestRecordInner>
          </Box>
        )
      })}
      <CustomLabel sx={{ marginTop: '32px' }}>
        {DRIVERS.LABELS.FILE}(適性診断の
        {DRIVERS.LABELS.FILE_UPLOAD_DESCRIPTION_PARENT})
        <ConvertibleCustomDropZone
          mode={props.mode}
          formKey={`aptitudeTestRecords.files`}
          control={props.control}
          getValues={props.getValues}
          files={props.aptitudeTestRecordsFiles}
          onDrop={onDrop}
          onCancelIconClick={removeAptitudeTestRecordImage}
        ></ConvertibleCustomDropZone>
      </CustomLabel>
    </BackGroundWhite>
  )
}

type AptitudeTestRecordInnerProps = {
  index: number
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
  aptitudeTestRecordChildrenFiles: File[][]
}

const Title = styled('p')({
  fontWeight: 'bold',
  color: palette.text.gray.deep,
})

const Text = styled('p')({
  fontSize: '16px',
  margin: 0,
})

const FileTextWrapper = styled('div')({
  width: '100%',
  paddingBottom: '8px',
  paddingLeft: '4px',
  marginTop: '16px',
  borderBottom: `1px solid ${palette.border.pale}`,
  overflowWrap: 'break-word',
})

const aptitudeTypeOptions = [
  { value: 'initial', label: '初任診断' },
  { value: 'age_approptiate', label: '適齢診断' },
  { value: 'specific_first', label: '特定診断Ⅰ' },
  { value: 'specific_second', label: '特定診断Ⅱ' },
  { value: 'genaral', label: '一般診断' },
]

const AptitudeTestRecordInner: React.FC<AptitudeTestRecordInnerProps> = (
  props
) => {
  return (
    <BackGroundPalePink>
      <Title>
        {DRIVERS.LABELS.APTITUDE_TEST}
        {props.index + 1}
      </Title>
      <FlexBox alignItems="center" flexWrap="wrap">
        <CustomLabel sx={{ marginRight: '32px' }}>
          {APTITUDE_TEST_RECORDS.LABELS.APTITUDE_TYPE}
          <ConvertibleCustomSelect
            mode={props.mode}
            formKey={`aptitudeTestRecords.${props.index}.aptitudeType`}
            control={props.control}
            getValues={props.getValues}
            options={aptitudeTypeOptions}
          ></ConvertibleCustomSelect>
        </CustomLabel>
        <CustomLabel sx={{ marginRight: '32px' }}>
          {APTITUDE_TEST_RECORDS.LABELS.CONSULTATION_DATE}
          <ConvertibleCustomDateSelect
            mode={props.mode}
            formKeys={{
              year: `aptitudeTestRecords.${props.index}.consultationYear`,
              month: `aptitudeTestRecords.${props.index}.consultationMonth`,
              day: `aptitudeTestRecords.${props.index}.consultationDay`,
            }}
            control={props.control}
            getValues={props.getValues}
          ></ConvertibleCustomDateSelect>
        </CustomLabel>
      </FlexBox>
      <FlexBox alignItems="center" flexWrap="wrap">
        <CustomLabel>
          {APTITUDE_TEST_RECORDS.LABELS.INSTITUTION_NAME}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey={`aptitudeTestRecords.${props.index}.institutionName`}
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.aptitudeTestRecords?.[props.index]?.institutionName && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
        <CustomLabel>
          {APTITUDE_TEST_RECORDS.LABELS.RESULT}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey={`aptitudeTestRecords.${props.index}.result`}
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.aptitudeTestRecords?.[props.index]?.result && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
      </FlexBox>
      <CustomLabel>
        {DRIVERS.LABELS.FILE}(適性診断の
        {DRIVERS.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
        <FileTextWrapper>
          {props.aptitudeTestRecordChildrenFiles[props.index]?.length > 0 ? (
            props.aptitudeTestRecordChildrenFiles[props.index].map(
              (file, i) => {
                return (
                  <FlexBox key={`${file.name}-${i}`} mb={1}>
                    <InsertDriveFileOutlinedIcon fontSize="small" />
                    <Text sx={{ marginLeft: '8px' }}>
                      <a
                        href={URL.createObjectURL(file)}
                        download={decodeURI(file.name)}
                      >
                        {decodeURI(file.name)}
                      </a>
                    </Text>
                  </FlexBox>
                )
              }
            )
          ) : (
            <Text>ファイルがありません</Text>
          )}
        </FileTextWrapper>
      </CustomLabel>
    </BackGroundPalePink>
  )
}
