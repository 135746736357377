import * as React from 'react'
import { Control, Controller } from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  CustomFileInput,
  Avatar,
  CustomDateSelect,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { DRIVERS } from 'commons/constants'
import { Box, styled, useTheme } from '@mui/material'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { useNavigate } from 'react-router-dom'

type ProfileImageProps = {
  control: Control<Driver>
  onProfileImageChange: React.Dispatch<React.SetStateAction<File>>
  imageFile: File
  data: {
    fullName: string
    employeeNumber: number
    companyName?: string
    officeName: string
    birthday: string
    currentCareer: string
    joinedDate: string
    roleText: string
    isExecutive: boolean
  }
  mode: FormMode
  handleModeChange: (mapKey: DriverFormModeMapKey, mode: FormMode) => void
  isXmileSystemAdminUser?: boolean
  isGroupParentCompanyAdmin?: boolean
}

type EditInputGroupProps = {
  control: Control<Driver>
  onProfileImageChange: React.Dispatch<React.SetStateAction<File>>
  imageFile: File
}

export default function ProfileImage(props: ProfileImageProps) {
  const [imageURL, setImageURL] = React.useState(null)
  const navigate = useNavigate()

  // imageFileに変更が加わった場合に画像表示用のURLを生成する
  React.useEffect(() => {
    if (!props.imageFile) return
    const url = URL.createObjectURL(props.imageFile)
    setImageURL(url)
  }, [props.imageFile])

  const reload = () => {
    if (
      window.confirm('保存していないデータは削除されます。よろしいですか？')
    ) {
      navigate(0)
    }
  }

  const BoldText = styled('p')({
    fontWeight: 'bold',
    margin: 0,
  })

  const theme = useTheme()
  return (
    <BackGroundWhite>
      <Box>
        <FlexBox justifyContent="space-between">
          <FlexBox
            alignItems="center"
            sx={{
              [theme.breakpoints.down('sm')]: {
                width: '100%',
                flexDirection: 'column',
              },
            }}
          >
            {props.mode == 'show' && (
              <Box
                sx={{
                  marginRight: '16px',
                  [theme.breakpoints.down('sm')]: {
                    marginRight: '0',
                  },
                }}
              >
                <FlexBox mb={2} justifyContent="center">
                  <Avatar src={imageURL} />
                </FlexBox>
              </Box>
            )}
            <Box mr={2}>
              <HeadingText sx={{ fontSize: '22px' }}>
                {props.data.fullName}({props.data.roleText})
                {props.data.isExecutive && ' ※経営者'}
              </HeadingText>
              <FlexBox
                mt={1.5}
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    marginBottom: '0',
                  },
                  width: '100%',
                  marginBottom: '8px',
                }}
              >
                <FlexBox alignItems="center" mr={3}>
                  {DRIVERS.LABELS.EMPLOYEE_NUMBER}:
                  <BoldText sx={{ marginLeft: '4px' }}>
                    {props.data.employeeNumber}
                  </BoldText>
                </FlexBox>
                <FlexBox alignItems="center" mr={3}>
                  {DRIVERS.LABELS.CURRENT_CAREER_STATUS}:
                  <BoldText sx={{ marginLeft: '4px' }}>
                    {props.data.currentCareer}
                  </BoldText>
                </FlexBox>
                {(props.isXmileSystemAdminUser ||
                  props.isGroupParentCompanyAdmin) && (
                  <FlexBox alignItems="center" mr={3}>
                    {DRIVERS.LABELS.COMPANY}:
                    <BoldText sx={{ marginLeft: '4px' }}>
                      {props.data.companyName}
                    </BoldText>
                  </FlexBox>
                )}
                <FlexBox alignItems="center">
                  {DRIVERS.LABELS.OFFICE}:
                  <BoldText sx={{ marginLeft: '4px' }}>
                    {props.data.officeName}
                  </BoldText>
                </FlexBox>
              </FlexBox>
              <FlexBox
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                  },
                }}
              >
                <FlexBox alignItems="center" mr={3}>
                  {DRIVERS.LABELS.JOINED}:
                  <BoldText sx={{ marginLeft: '4px' }}>
                    {props.data.joinedDate}
                  </BoldText>
                </FlexBox>
                <FlexBox alignItems="center">
                  {DRIVERS.LABELS.BIRTHDAY}:
                  <BoldText sx={{ marginLeft: '4px' }}>
                    {props.data.birthday}
                  </BoldText>
                </FlexBox>
              </FlexBox>
            </Box>
          </FlexBox>
          <Box>
            {/* 一時的にコメントアウト */}
            {/* {props.mode == 'show' && (
              <EditButtonOutlined
                onClick={() => {
                  props.handleModeChange('profileImage', 'edit')
                }}
              >
                プロフィール画像を編集
              </EditButtonOutlined>
            )} */}
            {props.mode == 'edit' && (
              <FlexBox>
                <ApplyButton pattern="marginRight8" type="submit">
                  保存する
                </ApplyButton>
                <CancelButtonOutlined onClick={reload}>
                  キャンセル
                </CancelButtonOutlined>
              </FlexBox>
            )}
          </Box>
        </FlexBox>
        {props.mode == 'edit' && (
          <EditInputGroup
            imageFile={props.imageFile}
            control={props.control}
            onProfileImageChange={props.onProfileImageChange}
          />
        )}
      </Box>
    </BackGroundWhite>
  )
}

const EditInputGroup: React.FC<EditInputGroupProps> = (props) => {
  const [imageURL, setImageURL] = React.useState(null)

  // imageFileに変更が加わった場合に画像表示用のURLを生成する
  React.useEffect(() => {
    if (!props.imageFile) return
    const url = URL.createObjectURL(props.imageFile)
    setImageURL(url)
  }, [])

  // 画像設定時のコールバック
  const onFileChange = (e) => {
    const file = e.target.files[0]
    const url = URL.createObjectURL(file)
    setImageURL(url)
    props.onProfileImageChange(file)
  }

  const Inner = styled('div')({
    borderTop: '1px solid #CDCFD1',
    paddingTop: '24px',
    marginTop: '24px',
  })

  return (
    <Inner>
      <FlexBox>
        <CustomLabel sx={{ marginBottom: 0 }}>
          {DRIVERS.LABELS.PROFILE_IMAGE}
          <FlexBox alignItems="center">
            <FlexBox justifyContent="center">
              <Avatar src={imageURL} />
            </FlexBox>
            <Box ml={2}>
              <Controller
                name="profile.imageUrl"
                render={() => <CustomFileInput onChange={onFileChange} />}
                control={props.control}
              />
            </Box>
          </FlexBox>
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: 0 }}>
          <Box mb={1}> {DRIVERS.LABELS.PROFILE_IMAGE_DATE}</Box>
          <CustomDateSelect
            formKeys={{
              year: `profile.imageCreatedYear`,
              month: `profile.imageCreatedMonth`,
              day: `profile.imageCreatedDay`,
            }}
            control={props.control}
          ></CustomDateSelect>
        </CustomLabel>
      </FlexBox>
    </Inner>
  )
}
