import { AxiosResponse } from 'axios'
import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { camelCaseKeysToUnderscore } from 'commons/functions'

export function createFundContact(params: {
  data: FundContact
  formData: FormData
}) {
  const headers = { 'Content-Type': 'multipart/form-data' }
  return axios.post(API_ENDPOINTS.FUND_CONTACTS, params.formData, {
    headers: headers,
    params: camelCaseKeysToUnderscore(params.data),
  })
}

export function fetchFundContactTypeList(): Promise<
  AxiosResponse<ContactType[]>
> {
  return axios.get(API_ENDPOINTS.FUND_CONTACT_TYPE_LIST)
}
