import React from 'react'
import { styled } from '@mui/system'
import { palette } from 'components/theme'

export const SectionTitle = (props: {
  children?: React.ReactNode | string
}) => (
  <Container {...props}>
    <Title>{props.children}</Title>
  </Container>
)

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding-bottom: 0;
`

const Title = styled('h2')`
  margin: 0;
  color: ${palette.text.black.main};
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.8px;
`
