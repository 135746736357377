import * as React from 'react'
import {
  BackGroundPalePink,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import { Box, styled } from '@mui/material'
import { palette, theme } from 'components/theme'
import { VEHICLES } from 'commons/constants'
import { FlexBox } from 'components/atoms/BoxComponents'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

type ToolsProps = {
  data: Vehicle
  equipmentImages: File[]
}

export default function Tools(props: ToolsProps) {
  const Inner = styled('div')({
    padding: '32px',
  })
  const Title = styled('h2')({
    fontSize: '20px',
    margin: 0,
    marginBottom: '16px',
  })
  const SubTitle = styled('p')({
    fontSize: '16px',
    margin: 0,
    color: palette.text.gray.deep,
    fontWeight: 'bold',
  })
  const Border = styled('div')({
    width: '100%',
    borderBottom: `1px solid ${palette.border.pale}`,
  })
  const Text = styled('p')({
    fontSize: '16px',
    margin: 0,
  })
  const Label = styled('p')({
    fontSize: '14px',
    margin: 0,
    color: palette.text.gray.pale,
    marginTop: '32px',
    marginBottom: '8px',
  })
  const TextWrapper = styled('div')({
    width: '300px',
    paddingBottom: '8px',
    paddingLeft: '4px',
    borderBottom: `1px solid ${palette.border.pale}`,
    overflowWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  })
  const TextWrapperLong = styled(TextWrapper)({
    width: '420px',
  })
  const TextWrapperFull = styled(TextWrapper)({
    width: '628px',
  })

  const others = {
    hasAbs: 'ABS',
    hasAirbag: 'エアバッグ',
    hasBackmonitor: 'バックモニター',
    hasCarnavi: 'カーナビ',
    hasCatacon: 'キャタコン',
    hasRadio: '無線',
    hasToolbox: '工具',
    hasWarningTriangle: '三角反射板',
  }

  const getOthersText = React.useCallback((): string => {
    let text = ''
    if (props.data.hasAbs) text += others.hasAbs
    if (props.data.hasAirbag) {
      if (text !== '') text += ' / '
      text += others.hasAirbag
    }
    if (props.data.hasBackmonitor) {
      if (text !== '') text += ' / '
      text += others.hasBackmonitor
    }
    if (props.data.hasCarnavi) {
      if (text !== '') text += ' / '
      text += others.hasCarnavi
    }
    if (props.data.hasCatacon) {
      if (text !== '') text += ' / '
      text += others.hasCatacon
    }
    if (props.data.hasRadio) {
      if (text !== '') text += ' / '
      text += others.hasRadio
    }
    if (props.data.hasToolbox) {
      if (text !== '') text += ' / '
      text += others.hasToolbox
    }
    if (props.data.hasWarningTriangle) {
      if (text !== '') text += ' / '
      text += others.hasWarningTriangle
    }
    return text
  }, [
    props.data.hasAbs,
    props.data.hasAirbag,
    props.data.hasBackmonitor,
    props.data.hasCarnavi,
    props.data.hasCatacon,
    props.data.hasRadio,
    props.data.hasToolbox,
    props.data.hasWarningTriangle,
  ])

  const etcCards = [
    {
      number: props.data.etcCard1,
      expireDate: props.data.etcCard1ExpireDate,
    },
    {
      number: props.data.etcCard2,
      expireDate: props.data.etcCard2ExpireDate,
    },
    {
      number: props.data.etcCard3,
      expireDate: props.data.etcCard3ExpireDate,
    },
    {
      number: props.data.etcCard4,
      expireDate: props.data.etcCard4ExpireDate,
    },
    {
      number: props.data.etcCard5,
      expireDate: props.data.etcCard5ExpireDate,
    },
  ]

  const fuelCards = [
    props.data.fuelCard1,
    props.data.fuelCard2,
    props.data.fuelCard3,
    props.data.fuelCard4,
    props.data.fuelCard5,
  ]

  return (
    <BackGroundWhite withoutPadding={true}>
      <Inner>
        <Title>{VEHICLES.LABELS.TOOLS}</Title>
      </Inner>
      <Border></Border>
      <Inner>
        <SubTitle>{VEHICLES.LABELS.DIGI_TACHO}</SubTitle>
        <FlexBox
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Box mr={3}>
            <Label>{VEHICLES.LABELS.DIGI_TACHO_MANUFACTURE}</Label>
            <TextWrapper>
              <Text>{props.data.digiTachoManufacturer || '未入力'}</Text>
            </TextWrapper>
          </Box>
          <Box>
            <Label>{VEHICLES.LABELS.DIGI_TACHO_SERIAL_NUMBER}</Label>
            <TextWrapper>
              <Text>{props.data.digiTachoSerialNumber || '未入力'}</Text>
            </TextWrapper>
          </Box>
        </FlexBox>
      </Inner>
      <Border></Border>
      <Inner>
        <SubTitle>{VEHICLES.LABELS.DRIVER_RECORDER}</SubTitle>
        <FlexBox
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Box mr={3}>
            <Label>{VEHICLES.LABELS.DRIVER_RECORDER_MANUFACTURE}</Label>
            <TextWrapper>
              <Text>{props.data.driveRecorderManufacturer || '未入力'}</Text>
            </TextWrapper>
          </Box>
          <Box>
            <Label>{VEHICLES.LABELS.DRIVER_RECORDER_SERIAL_NUMBER}</Label>
            <TextWrapper>
              <Text>{props.data.driveRecorderSerialNumber || '未入力'}</Text>
            </TextWrapper>
          </Box>
        </FlexBox>
      </Inner>
      <Border></Border>
      <Inner>
        <SubTitle>{VEHICLES.LABELS.ETC}</SubTitle>
        <FlexBox
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Box mr={3}>
            <Label>{VEHICLES.LABELS.ETC_DEVICE}</Label>
            <TextWrapper>
              <Text>{props.data.etcDevice || '未入力'}</Text>
            </TextWrapper>
          </Box>
          <Box>
            <Label>{VEHICLES.LABELS.ETC_ASL_ID}</Label>
            <TextWrapper>
              <Text>{props.data.etcAslId || '未入力'}</Text>
            </TextWrapper>
          </Box>
        </FlexBox>
      </Inner>
      <Border></Border>
      <Inner>
        <SubTitle sx={{ marginBottom: '16px' }}>
          {VEHICLES.LABELS.ETC_CARD}
        </SubTitle>
        {etcCards.map((e, i) => {
          return (
            <Box mb={2} key={`etc-${i}`}>
              <BackGroundPalePink>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label sx={{ marginTop: 0, marginBottom: '16px' }}>
                      {VEHICLES.LABELS.ETC_CARD_NUMBER}
                      {i + 1}
                    </Label>
                    <TextWrapper>
                      <Text>{e?.number || '未入力'}</Text>
                    </TextWrapper>
                  </Box>
                  <Box>
                    <Label sx={{ marginTop: 0, marginBottom: '16px' }}>
                      {VEHICLES.LABELS.ETC_CARD_EXPIRE_DATE}
                      {i + 1}
                    </Label>
                    <TextWrapper>
                      <Text>{e?.expireDate || '未入力'}</Text>
                    </TextWrapper>
                  </Box>
                </FlexBox>
              </BackGroundPalePink>
            </Box>
          )
        })}
      </Inner>
      <Border></Border>
      <Inner>
        <SubTitle sx={{ marginBottom: '16px' }}>
          {VEHICLES.LABELS.FUEL_CARD}
        </SubTitle>
        <FlexBox flexWrap="wrap">
          {fuelCards.map((f, i) => {
            return (
              <Box mr={3} mb={4} key={`fuel-card-${i}`}>
                <Label sx={{ marginTop: 0 }}>
                  {VEHICLES.LABELS.FUEL_CARD_NUMBER}
                  {i + 1}
                </Label>
                <TextWrapperLong>
                  <Text>{f || '未入力'}</Text>
                </TextWrapperLong>
              </Box>
            )
          })}
        </FlexBox>
      </Inner>
      <Border></Border>
      <Inner>
        <SubTitle sx={{ marginBottom: '16px' }}>
          {VEHICLES.LABELS.OTHERS}
        </SubTitle>
        <TextWrapperFull>
          <Text>{getOthersText() || '未入力'}</Text>
        </TextWrapperFull>
      </Inner>
      <Border></Border>
      <Inner>
        <SubTitle sx={{ marginBottom: '16px' }}>
          {VEHICLES.LABELS.FILE}
        </SubTitle>
        {props.equipmentImages.length == 0 ? (
          <Text>ファイルがありません</Text>
        ) : (
          <TextWrapperFull>
            {Object.values(props.equipmentImages).map((file: File, index) => {
              return (
                <FlexBox key={`equipment-image-${index}`} mb={1}>
                  <InsertDriveFileOutlinedIcon fontSize="small" />
                  <Text sx={{ marginLeft: '8px' }}>
                    <a
                      href={URL.createObjectURL(file)}
                      download={decodeURI(file.name)}
                    >
                      {decodeURI(file.name)}
                    </a>
                  </Text>
                </FlexBox>
              )
            })}
          </TextWrapperFull>
        )}
      </Inner>
      <Border></Border>
      <Inner>
        <SubTitle sx={{ marginBottom: '16px' }}>
          {VEHICLES.LABELS.MEMO}
        </SubTitle>
        <TextWrapperFull>
          <Text>{props.data.equipmentMemo || '未入力'}</Text>
        </TextWrapperFull>
      </Inner>
    </BackGroundWhite>
  )
}
