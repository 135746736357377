import * as React from 'react'
import { Button } from '@mui/material'

type ModalProps = {
  onClose: () => void
  onCancel?: () => void
}

function ConfirmCloseVideo({ onClose, onCancel }: ModalProps) {
  return (
    <div>
      動画を終了してもよろしいですか？
      <br />
      <Button onClick={onClose}>終了する</Button>
      <Button onClick={onCancel}>再生する</Button>
    </div>
  )
}

export default ConfirmCloseVideo
