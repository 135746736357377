import { useState } from 'react'

interface UseImagesUploadReturn {
  images: File[]
  addImage: (file: File) => void
  removeImage: (index: number) => void
  setImages: (images: File[]) => void
}

export const useImagesUpload = (): UseImagesUploadReturn => {
  const [images, setImages] = useState<File[]>([])

  const addImage = (file: File) => {
    setImages((prevImages) => [...prevImages, file])
  }

  const removeImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index))
  }

  return { images, addImage, removeImage, setImages }
}
