import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { addParamPrefix, camelCaseKeysToUnderscore } from 'commons/functions'

export function getResultRangeOption() {
  return axios.get(API_ENDPOINTS.ALCOHOL_CHECKS_RESULT_RANGE)
}

export function getAlcoholCheck(id: number) {
  return axios.get([API_ENDPOINTS.ALCOHOL_CHECKS, id].join(''))
}

export function getLatestUncompletedAlcoholCheck(userId: number) {
  return axios.get(
    [API_ENDPOINTS.ALCOHOL_CHECKS, 'latest_uncompleted/', userId].join('')
  )
}

export function fetchAlcoholChecks(
  searchParams: SearchAlcoholCheck,
  paginateParams: PaginateParams
) {
  const customSearchParams = addParamPrefix(searchParams, 'search')
  const customPaginateParams = addParamPrefix(paginateParams, 'pagination')
  const params = camelCaseKeysToUnderscore({
    ...customSearchParams,
    ...customPaginateParams,
  })

  return axios.get(API_ENDPOINTS.ALCOHOL_CHECKS, { params: params })
}

export function createAlcoholCheck(params: {
  data: AlcoholCheck
  formData: FormData
}) {
  const headers = { 'Content-Type': 'multipart/form-data' }
  return axios.post(API_ENDPOINTS.ALCOHOL_CHECKS, params.formData, {
    headers: headers,
    params: camelCaseKeysToUnderscore(params.data),
  })
}

export function updateAlcoholCheck(params: {
  data: AlcoholCheck
  formData: FormData
}) {
  const headers = { 'Content-Type': 'multipart/form-data' }
  return axios.patch(
    [API_ENDPOINTS.ALCOHOL_CHECKS, params.data.id].join(''),
    params.formData,
    {
      headers: headers,
      params: camelCaseKeysToUnderscore(params.data),
    }
  )
}

export function deleteAlcoholCheck(id: number) {
  return axios.delete([API_ENDPOINTS.ALCOHOL_CHECKS, id].join(''))
}
