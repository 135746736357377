import * as React from 'react'
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormRegister,
} from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { AddButtonOutlined } from 'components/atoms/ButtonComponenets'
import { Box } from '@mui/material'
import DrivingExperienceCard from 'components/organisms/admin/drivers/form_sections/driving_experiences/DrivingExperienceCard'
import { DRIVERS } from 'commons/constants'
import { HeadingText } from 'components/atoms/FormComponents'

type DrivingExperiencesProps = {
  register: UseFormRegister<Driver>
  control: Control<Driver>
  errors: FieldErrors<Driver>
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>
}

const DrivingExperiences: React.FC<DrivingExperiencesProps> = (props) => {
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: 'drivingExperiences',
  })

  const appendEmptyItem = () => {
    const emptyItem = {
      vehicleType: '',
      capacity: null,
      loadCapacity: null,
      experienceYear: null,
      experienceMonth: null,
    }
    append(emptyItem, { shouldFocus: false })
  }

  const deleteItem = (targetIndex: number) => {
    remove(targetIndex)
  }

  return (
    <BackGroundWhite>
      <HeadingText>{DRIVERS.LABELS.DRIVING_EXPERIENCE}</HeadingText>
      {fields.map((_, i) => {
        return (
          <Box mb={2} key={`driving-experience-card-${i}`}>
            <DrivingExperienceCard
              register={props.register}
              errors={props.errors}
              control={props.control}
              appendEmptyItem={appendEmptyItem}
              deleteItem={deleteItem}
              index={i}
              onInputKeyDown={props.onInputKeyDown}
            ></DrivingExperienceCard>
          </Box>
        )
      })}
      <AddButtonOutlined onClick={appendEmptyItem}>
        {DRIVERS.LABELS.DRIVING_EXPERIENCE}を追加
      </AddButtonOutlined>
    </BackGroundWhite>
  )
}

export default DrivingExperiences
