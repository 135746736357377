import * as React from 'react'
import { UserContext } from 'providers/UserProvider'
import Forbidden from 'components/pages/errors/Forbidden'

function XmileAdminRoute(props: { component: React.ReactNode }) {
  const isXmileAdmin: boolean = React.useContext(UserContext).isXmileAdmin

  if (isXmileAdmin) {
    return <>{props.component}</>
  }

  return <Forbidden />
}

export default XmileAdminRoute
