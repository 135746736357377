import * as React from 'react'
import { Chip } from '@mui/material'
import { palette } from 'components/theme'

export const ThreeMonthsInspectionChip = (props: {
  label: string
  style: React.CSSProperties
}) => {
  return (
    <Chip
      label={props.label}
      size="small"
      style={{ ...props.style, backgroundColor: '#DAE5E4' }}
    />
  )
}

export const AnnualInspectionChip = (props: {
  label: string
  style: React.CSSProperties
}) => {
  return (
    <Chip
      label={props.label}
      size="small"
      style={{ ...props.style, backgroundColor: palette.primary.pale }}
    />
  )
}
