import * as React from 'react'
import { VALIDATION_MESSAGES } from 'commons/constants'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomInput,
  ErrorMessage,
  WithRequiredBadge,
  CustomDateSelectOnlyYear,
} from 'components/atoms/FormComponents'
import { Box } from '@mui/material'
import { EDUCATION_ANNUAL_PLANS } from 'commons/constants'
import CompanyAndOfficeSelect from 'components/atoms/ReactHookFormPartials/InputForm/CompanyAndOfficeSelect'
import { Section } from './Section'
import { Title } from './Title'
import { useFormContext } from 'react-hook-form'

export const EducationPlanHeader: React.FC<{
  educationAnnualPlan: EducationAnnualPlan
}> = ({ educationAnnualPlan }) => {
  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useFormContext()
  return (
    <Section>
      <BackGroundWhite>
        <Box>
          <Title style={{ marginBottom: '16px' }}>
            {EDUCATION_ANNUAL_PLANS.LABELS.BASIC_INFO}
          </Title>
          <CustomLabel>
            <WithRequiredBadge>
              {EDUCATION_ANNUAL_PLANS.LABELS.ANNUAL_PLAN_TITLE}
            </WithRequiredBadge>
            <CustomInput
              {...register('annualPlanTitle', { required: true })}
              defaultValue={educationAnnualPlan?.annualPlanTitle}
              placeholder="入力例：○○年度安全教育実施計画"
            />
            {errors.annualPlanTitle && (
              <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
            )}
          </CustomLabel>
          <CustomLabel sx={{ marginBottom: '30px' }}>
            <WithRequiredBadge>
              {EDUCATION_ANNUAL_PLANS.LABELS.FISCAL_YEAR}
            </WithRequiredBadge>
            <CustomDateSelectOnlyYear
              formKey="year"
              fiscalYearSelect
              control={control}
              required
              defaultThisYear
            />
            {errors.year && (
              <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
            )}
          </CustomLabel>
          <CompanyAndOfficeSelect
            companyKeyName={'companyId'}
            officeKeyName={'officeId'}
            control={control}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
        </Box>
      </BackGroundWhite>
    </Section>
  )
}
