import { AxiosResponse } from 'axios'
import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import {
  addParamPrefix,
  camelCaseKeysToUnderscore,
  convertValueToInt,
} from 'commons/functions'

export function fetchCompanies(
  searchParams?: SearchCompany,
  paginateParams?: PaginateParams
): Promise<AxiosResponse<{ companies: Company[]; totalCount: number }>> {
  const customSearchParams = searchParams
    ? addParamPrefix(searchParams, 'search')
    : {}
  const customPaginateParams = paginateParams
    ? addParamPrefix(paginateParams, 'pagination')
    : {}
  const params = camelCaseKeysToUnderscore({
    ...customSearchParams,
    ...customPaginateParams,
  })
  return axios.get(API_ENDPOINTS.COMPANIES, {
    params: params,
  })
}

export function getCompany(id: number): Promise<AxiosResponse<Company>> {
  return axios.get([API_ENDPOINTS.COMPANIES, id].join(''))
}

export function deleteCompany(id: number) {
  return axios.delete([API_ENDPOINTS.COMPANIES, id].join(''))
}

export function createCompany(params: { data: Company; formData: FormData }) {
  const snakeCaseData = camelCaseKeysToUnderscore(
    convertValueToInt(params.data)
  )
  const headers = { 'Content-Type': 'multipart/form-data' }

  for (const key in snakeCaseData) {
    if (snakeCaseData[key] !== undefined && snakeCaseData[key] !== null) {
      if (typeof snakeCaseData[key] === 'object') {
        params.formData.append(key, JSON.stringify(snakeCaseData[key]))
      } else {
        params.formData.append(key, snakeCaseData[key])
      }
    }
  }

  return axios.post(API_ENDPOINTS.COMPANIES, params.formData, {
    headers: headers,
  })
}

export function updateCompany(params: { data: Company; formData: FormData }) {
  const snakeCaseData = camelCaseKeysToUnderscore(
    convertValueToInt(params.data)
  )
  const headers = { 'Content-Type': 'multipart/form-data' }

  for (const key in snakeCaseData) {
    if (snakeCaseData[key] !== undefined && snakeCaseData[key] !== null) {
      if (typeof snakeCaseData[key] === 'object') {
        params.formData.append(key, JSON.stringify(snakeCaseData[key]))
      } else {
        params.formData.append(key, snakeCaseData[key])
      }
    }
  }

  return axios.patch(
    [API_ENDPOINTS.COMPANIES, params.data.id].join(''),
    params.formData,
    {
      headers: headers,
    }
  )
}

export function fetchAllOffices(): Promise<
  AxiosResponse<{ offices: Office[] }>
> {
  return axios.get(API_ENDPOINTS.ALL_OFFICE_LIST, {})
}

export function fetchAllCompanies(
  placeholder?: string
): Promise<AxiosResponse<{ companies: Company[]; totalCount: number }>> {
  return axios.get(API_ENDPOINTS.ALL_COMPANY_LIST, {
    params: { placeholder: placeholder },
  })
}

export function fetchVariableOffices(
  companyId: number,
  placeholder?: string
): Promise<AxiosResponse<{ offices: Office[] }>> {
  const params = camelCaseKeysToUnderscore({
    currentCompanyId: companyId,
    placeholder: placeholder,
  })
  return axios.get(API_ENDPOINTS.VARIABLE_OFFICE_LIST, { params: params })
}

export function checkSubscribed(feature: string) {
  const params = { feature: feature }
  return axios.get(API_ENDPOINTS.COMPANIES_SUBSCRIBED_FEATURE, {
    params: params,
  })
}

export function fetchGroupParentCompanies(): Promise<
  AxiosResponse<{ groupParentCompanies: Company[] }>
> {
  return axios.get(API_ENDPOINTS.GROUP_PARENT_COMPANY_LIST, {})
}
