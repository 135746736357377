import * as React from 'react'
import { jaJP } from '@mui/material/locale'
import { createTheme } from '@mui/material/styles'

export const theme = createTheme(
  {
    // レスポンシブのブレイクポイント
    breakpoints: {
      keys: ['xs', 'sm', 'md', 'lg', 'xl'],
      values: {
        xs: 320, // スマホ用
        sm: 768, // タブレット用
        md: 992, // PC用
        lg: 1240,
        xl: 1800,
      },
    },
  },
  jaJP
)

export const palette = {
  // カラーパレット追加
  primary: {
    main: '#0050B2',
    deep: '#003D82',
    pale: '#739AC5',
  },
  error: {
    main: '#CC0000',
    pale: '#FCF2F2',
  },
  info: {
    main: '#027BC3',
  },
  warning: {
    main: '#EFA92C',
  },
  text: {
    black: {
      main: '#202830',
    },
    gray: {
      deep: '#52585E',
      pale: '#6B7075',
    },
    disabled: {
      main: '#CDCFD1',
    },
  },
  header: {
    text: '#FFFFFF',
    background: '#00295D',
  },
  border: {
    deep: '#CDCFD1', // inputやbuttonの縁
    pale: '#E6E7E8', //コンテンツの境目など
    red: '#CC0000', // フォームの「必須」ラベル
  },
  background: {
    main: '#F3F3F4', // 背景
    tableCell: '#FDFDFD', // テーブルの偶数行の色
    tableHeaderCell: {
      deep: '#F5F0ED',
      pale: '#FAF7F5',
    },
    activeMenu: '#DCE5E4',
    formItemInner: '#FAF7F5',
    grey: '#F5F5F5',
  },
  disabled: {
    main: '#F3F3F4',
  },
}

export const spacing = {
  sidebarWidth: '290px',
}

export const styles = {
  defaultFormWidth: 376,
  shortFormWidth: 160,
}

export const videoUrlStyle: React.CSSProperties = {
  marginTop: '18px',
  marginLeft: '14px',
  position: 'relative',
}

export const videoFlexBoxStyle: React.CSSProperties = {
  paddingTop: '10px',
  paddingBottom: '20px',
  position: 'relative',
}

export const articleVideoUrlStyle: React.CSSProperties = {
  marginTop: '18px',
  marginLeft: '10px',
  position: 'relative',
}

export const articleVideoFlexBoxStyle: React.CSSProperties = {
  margin: '25px 0px',
  position: 'relative',
}
