import * as React from 'react'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { ARTICLES } from 'commons/constants'
import {
  deleteArticle,
  getVideoConversionStatuses,
} from 'components/apis/articles'
import { useNavigate } from 'react-router-dom'
import { toastOnError } from 'commons/toaster'
import { useQuery, useMutation } from '@tanstack/react-query'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import {
  BoxBorderBottom,
  FlexBox,
  MarginBox,
} from 'components/atoms/BoxComponents'
import { Typography, CircularProgress } from '@mui/material'
import { TextBold } from 'components/atoms/TextComponents'
import { UserContext } from 'providers/UserProvider'
import { fetchS3Images } from 'commons/fetchS3Images'
import { styled } from '@mui/material'
import { HeadingText } from 'components/atoms/FormComponents'
import { slackNotification } from 'commons/slackNotification'
import {
  isVideoFile,
  isUnPlayableFile,
  checkVideoConversionsStatuses,
} from 'commons/functions'
import VideoPlayerModal from 'components/atoms/videos/VideoPlayerModal'
import {
  ConversionStatusButton,
  VideoViewButton,
  ConversionStatusTooltip,
} from 'components/atoms/videos/VideoViewButton'
import {
  articleVideoFlexBoxStyle,
  articleVideoUrlStyle,
} from 'components/theme'

export default function ArticleMaintenancesDetail(props: { data: Article }) {
  const { data } = props
  const user = React.useContext(UserContext)
  const [files, setFiles] = React.useState<File[]>([])
  const [isLoading, setIsLoading] = React.useState(true)
  // 添付動画のModalの状態管理
  const [isVideoModalShow, setIsVideoModalShow] = React.useState(false)
  // 動画ファイルのURLの状態管理
  const [videoUrl, setVideoUrl] = React.useState('')

  const navigate = useNavigate()
  const deleteMutation = useMutation({
    mutationFn: deleteArticle,
    onSuccess: () => navigate(`/admin/articles/maintenances`),
    onError: (e: { response: { errors: string } }) => {
      toastOnError(e.response.errors)
      // 422エラー時にslack通知する
      slackNotification(e, user)
    },
  })
  const onClickDelete = () => {
    deleteMutation.mutate(data.id)
  }
  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3Images(data.fileUrls)
      setFiles(files)
      setIsLoading(false)
    })()
  }, [data.fileUrls])

  const Section = styled('div')({
    margin: '0 1rem 1rem 1rem',
  })

  const showVideoModal = (video) => {
    setIsVideoModalShow(true)
    setVideoUrl(URL.createObjectURL(video))
  }

  const { data: VideoConversionStatuses } = useQuery(
    [`articles`, `${data.id}`, `video_conversion_statuses`],
    () =>
      getVideoConversionStatuses(data.id).then((res) => {
        return res.data
      })
  )

  if (isLoading) {
    return (
      <div style={{ padding: 16, textAlign: 'center' }}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <>
      <Section>
        <BackGroundWhite>
          <FlexBox justifyContent="space-between" marginBottom="5px">
            <HeadingText>{ARTICLES.LABELS.DESTINATION}</HeadingText>
          </FlexBox>
          <FlexBox flexDirection={'column'}>
            <BoxBorderBottom>
              <Typography>{data.destinationDetail}</Typography>
            </BoxBorderBottom>
          </FlexBox>
        </BackGroundWhite>
      </Section>
      <Section>
        <BackGroundWhite>
          <FlexBox justifyContent="space-between" marginBottom="5px">
            <HeadingText>お知らせ内容</HeadingText>
          </FlexBox>
          <FlexBox flexDirection={'column'}>
            <MarginBox sx={{ margin: '1rem' }}>
              <TextBold>{ARTICLES.LABELS.TITLE}</TextBold>
              <BoxBorderBottom>
                <Typography>{data.title}</Typography>
              </BoxBorderBottom>
            </MarginBox>

            <MarginBox sx={{ margin: '1rem' }}>
              <TextBold>{ARTICLES.LABELS.DESCRIPTION}</TextBold>
              <BoxBorderBottom>
                <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                  {data.description}
                </Typography>
              </BoxBorderBottom>
            </MarginBox>

            <MarginBox sx={{ margin: '1rem' }}>
              <TextBold>{ARTICLES.LABELS.USER_NAME}</TextBold>
              <BoxBorderBottom>
                <Typography>{data.creatorDetail}</Typography>
              </BoxBorderBottom>
            </MarginBox>

            <MarginBox sx={{ margin: '1rem' }}>
              <TextBold>{ARTICLES.LABELS.UPDATED_AT}</TextBold>
              <BoxBorderBottom>
                <Typography>{data.updatedAt}</Typography>
              </BoxBorderBottom>
            </MarginBox>

            <MarginBox sx={{ margin: '1rem' }}>
              <TextBold>{ARTICLES.LABELS.PUBLISH_STATUS}</TextBold>
              <BoxBorderBottom>
                <Typography>{data.publishStatus}</Typography>
              </BoxBorderBottom>
            </MarginBox>
          </FlexBox>
          {Object.values(files)?.map((file, index) => (
            <FlexBox
              flexDirection={'row'}
              key={index}
              style={articleVideoFlexBoxStyle}
            >
              <VideoPlayerModal
                isModalOpen={isVideoModalShow}
                setIsModalOpen={setIsVideoModalShow}
                videoUrl={videoUrl}
              />
              {isVideoFile(file) &&
                (isUnPlayableFile(file) ? (
                  <ConversionStatusButton
                    status={checkVideoConversionsStatuses(
                      file.name,
                      VideoConversionStatuses
                    )}
                  />
                ) : (
                  <VideoViewButton
                    onClick={() => showVideoModal(file)}
                    disabled={isUnPlayableFile(file)}
                  />
                ))}
              <p style={articleVideoUrlStyle}>
                <a
                  href={URL.createObjectURL(file)}
                  download={decodeURI(file.name)}
                >
                  {decodeURI(file.name)}
                </a>
              </p>
              {isUnPlayableFile(file) && (
                <ConversionStatusTooltip
                  status={checkVideoConversionsStatuses(
                    file.name,
                    VideoConversionStatuses
                  )}
                />
              )}
            </FlexBox>
          ))}
        </BackGroundWhite>
      </Section>
      <FlexBox
        flexDirection={{ xs: 'column-reverse', sm: 'row' }}
        justifyContent={{ xs: 'space-between' }}
        alignItems={{ xs: 'start', sm: 'center' }}
      >
        <DeleteButton sx={{ margin: 'none' }} onClick={onClickDelete}>
          このデータを削除する
        </DeleteButton>
      </FlexBox>
    </>
  )
}
