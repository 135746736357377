import * as React from 'react'
import { MENUS } from 'commons/menus'
import AccidentsForm from 'components/organisms/accidents/Form'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import { Helmet } from 'react-helmet-async'

function AccidentsNew() {
  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.ACCIDENTS.NEW ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>{MENUS.inspectionAndAccident.children.accidents.title}</h1>
      <AccidentsForm type="create" />
    </PageWrapper>
  )
}

export default AccidentsNew
