import * as React from 'react'
import { COMPANIES } from 'commons/constants'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  BoxBorderBottom,
  FlexBox,
  MarginBox,
} from 'components/atoms/BoxComponents'
import { Typography, Box } from '@mui/material'
import { TextBold, ValueNumber } from 'components/atoms/TextComponents'
import {
  DeleteButton,
  CopyContentButton,
} from 'components/atoms/ButtonComponenets'
import { deleteCompany } from 'components/apis/companies'
import { useNavigate } from 'react-router-dom'
import { toastOnError } from 'commons/toaster'
import { useMutation, useQuery } from '@tanstack/react-query'
import { UserContext } from 'providers/UserProvider'
import { fetchS3ImageV2 } from 'commons/fetchS3Images'
import { useErrorHandler } from 'react-error-boundary'
import { AxiosError } from 'axios'
import SignatureImage from 'components/atoms/SignatureImage'
import { slackNotification } from 'commons/slackNotification'
import { QRCodeCanvas } from 'qrcode.react'

export default function CompanyDetail(props: { data: Company }) {
  const { data } = props
  const user = React.useContext(UserContext)
  const handleError = useErrorHandler()
  const navigate = useNavigate()
  const mutation = useMutation({
    mutationFn: deleteCompany,
    onSuccess: () => navigate(`/admin/companies/`),
    onError: (e: { response: { errors: string } }) => {
      toastOnError(e.response.errors)
      // 422エラー時にslack通知する
      slackNotification(e, user)
    },
  })

  const onClickDelete = () => {
    mutation.mutate(data.id)
  }

  const [signatureImage, setSignatureImage] = React.useState<File>()

  useQuery(
    [
      `companies/Detail`,
      `${data?.attachedSignatureImage?.url}`,
      `attachedSignatureImage`,
    ],
    () => fetchS3ImageV2(data?.attachedSignatureImage),
    {
      onSuccess: (data) => setSignatureImage(data),
      onError: (e: AxiosError) => handleError(e.response.status),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )

  return (
    <BackGroundWhite>
      <FlexBox flexDirection={'column'}>
        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{COMPANIES.LABELS.NAME}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.name}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        {user.isXmileAdmin && (
          <MarginBox sx={{ margin: '1rem' }}>
            <TextBold>{COMPANIES.LABELS.PARENT_COMPANY_NAME}</TextBold>
            <BoxBorderBottom>
              <Typography>{data.parentCompanyName}</Typography>
            </BoxBorderBottom>
          </MarginBox>
        )}

        {data.loginId && (
          <>
            <MarginBox sx={{ margin: '1rem' }}>
              <TextBold>{COMPANIES.LABELS.COMPANY_LOGIN_ID}</TextBold>
              <BoxBorderBottom>
                <Typography>{data.loginId}</Typography>
              </BoxBorderBottom>
            </MarginBox>

            <MarginBox sx={{ margin: '1rem' }}>
              <TextBold>{COMPANIES.LABELS.EXCLUSIVE_LOGIN_URL_AND_QR}</TextBold>
              <FlexBox flexWrap="wrap">
                <div>
                  <BoxBorderBottom>
                    <Typography>{data.exclusiveLoginUrl}</Typography>
                  </BoxBorderBottom>
                </div>
                <div style={{ marginLeft: '10px' }}>
                  <CopyContentButton content={data.exclusiveLoginUrl} />
                </div>
              </FlexBox>
              <QRCodeCanvas
                style={{ marginTop: '10px' }}
                value={data.exclusiveLoginUrl}
              />
            </MarginBox>
          </>
        )}

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{COMPANIES.LABELS.ZIP_CODE}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.zipCode}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{COMPANIES.LABELS.ADDRESS}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.address}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{COMPANIES.LABELS.PHONE_NUMBER}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.phoneNumber}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{COMPANIES.LABELS.FAX_NUMBER}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.faxNumber}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{COMPANIES.LABELS.EMAIL}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.email}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{COMPANIES.LABELS.BANK_ACCOUNT_NUMBER}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.bankAccountNumber}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        {data.companyId === null && (
          <>
            <MarginBox sx={{ margin: '1rem' }}>
              <TextBold>
                {COMPANIES.LABELS.PAYMENT_STATEMENT_REFERENCE_DATE_TYPE}
              </TextBold>
              <BoxBorderBottom>
                <Typography>
                  {data.paymentStatementReferenceDateTypeText}
                </Typography>
              </BoxBorderBottom>
            </MarginBox>

            <MarginBox sx={{ margin: '1rem' }}>
              <TextBold>{COMPANIES.LABELS.ANNUAL_START_MONTH}</TextBold>
              <BoxBorderBottom>
                <Typography>
                  {data.annualStartMonth ? `${data.annualStartMonth}月` : ''}
                </Typography>
              </BoxBorderBottom>
            </MarginBox>
          </>
        )}

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{COMPANIES.LABELS.INVOICE_REGISTRATION_NUMBER}</TextBold>
          <BoxBorderBottom>
            <Typography>
              {data.invoiceRegistrationNumber &&
                `T${data.invoiceRegistrationNumber}`}
            </Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{COMPANIES.LABELS.G_MARK}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.hasGMarkText}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{COMPANIES.LABELS.SIGNATURE_IMAGE}</TextBold>
          <FlexBox alignItems="center" mt={1}>
            <Box mr={2}>
              <SignatureImage image={signatureImage} />
            </Box>
          </FlexBox>
        </MarginBox>
      </FlexBox>
      {user.isXmileAdmin && (
        <DeleteButton sx={{ margin: 'none' }} onClick={onClickDelete}>
          このデータを削除する
        </DeleteButton>
      )}
    </BackGroundWhite>
  )
}
