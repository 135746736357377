import { AxiosResponse } from 'axios'
import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { camelCaseKeysToUnderscore } from 'commons/functions'

export function fetchPaymentStatementSetting(): Promise<
  AxiosResponse<PaymentStatementSetting>
> {
  return axios.get(API_ENDPOINTS.PAYMENT_STATEMENT_SETTINGS)
}

export function createPaymentStatementSetting() {
  return axios.post(API_ENDPOINTS.PAYMENT_STATEMENT_SETTINGS)
}

export function updatePaymentStatementSetting(params: PaymentStatementSetting) {
  return axios.patch(
    API_ENDPOINTS.PAYMENT_STATEMENT_SETTINGS,
    camelCaseKeysToUnderscore(params)
  )
}
