import * as React from 'react'
import { APPLICATION_NAME, PAGE_TITLES, API_ENDPOINTS } from 'commons/constants'
import { ADMIN_MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { Helmet } from 'react-helmet-async'
import { WithDescriptionTooltipIcon } from 'components/atoms/FormComponents'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { useErrorHandler } from 'react-error-boundary'
import { checkCreatedAssociationCategories } from 'components/apis/csvHeaderAssociations'
import { palette } from 'components/theme'
import { FlexBox } from 'components/atoms/BoxComponents'
import CircularProgress from '@mui/material/CircularProgress'

function AdminCsvSettingsIndex() {
  // ヘッダー設定済みの種別(category)を格納する配列
  const [createdAssociationCategories, setCreatedAssociationCategories] =
    React.useState([])
  const [loading, setLoading] = React.useState(true)
  const handleError = useErrorHandler()

  React.useEffect(() => {
    checkCreatedAssociationCategories()
      .then((response) => {
        // ヘッダー設定済みの種別(category)を設定
        setCreatedAssociationCategories(response.data.categories)
      })
      .catch((e) => {
        handleError(e.response.status)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const hasDriverTemplate = () =>
    createdAssociationCategories.includes('driver')
  const hasVehicleTemplate = () =>
    createdAssociationCategories.includes('vehicle')

  // テンプレートダウンロードのリンクを非活性にするスタイル
  const disabledLinkStyle: React.CSSProperties = {
    color: palette.text.gray.pale,
    pointerEvents: 'none',
  }

  const TemplateDownloadDescription = () => {
    return (
      <WithDescriptionTooltipIcon
        text={'ヘッダーの設定完了後にダウンロード可能です。'}
      />
    )
  }

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.CSV_SETTINGS.INDEX ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>{ADMIN_MENUS.masters.children.csv_settings.title}</h1>
      <BackGroundWhite>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div style={{ marginBottom: '50px' }}>
              <h3>ヘッダーの設定</h3>
              <ul>
                <li>
                  <a href="/admin/csv_settings/driver_import">
                    従業員CSVインポート
                  </a>
                </li>
                <li>
                  <a href="/admin/csv_settings/vehicle_import">
                    車両CSVインポート
                  </a>
                </li>
              </ul>
            </div>
            <div style={{ marginBottom: '50px' }}>
              <h3>テンプレートダウンロード</h3>
              <ul>
                <li>
                  <FlexBox>
                    <a
                      href={`${API_ENDPOINTS.CSV_TEMPLATE}download?category=driver`}
                      target="_blank"
                      style={hasDriverTemplate() ? {} : disabledLinkStyle}
                      rel="noreferrer"
                    >
                      従業員CSVインポート
                    </a>
                    {!hasDriverTemplate() && <TemplateDownloadDescription />}
                  </FlexBox>
                </li>
                <li>
                  <FlexBox>
                    <a
                      href={`${API_ENDPOINTS.CSV_TEMPLATE}download?category=vehicle`}
                      target="_blank"
                      style={hasVehicleTemplate() ? {} : disabledLinkStyle}
                      rel="noreferrer"
                    >
                      車両CSVインポート
                    </a>
                    {!hasVehicleTemplate() && <TemplateDownloadDescription />}
                  </FlexBox>
                </li>
              </ul>
            </div>
            <div>
              <h3>インポート時の注意事項</h3>
              <ul>
                <li>
                  <a href="/admin/csv_settings/cautions/driver_import">
                    従業員CSVインポート
                  </a>
                </li>
                <li>
                  <a href="/admin/csv_settings/cautions/vehicle_import">
                    車両CSVインポート
                  </a>
                </li>
              </ul>
            </div>
          </>
        )}
      </BackGroundWhite>
    </PageWrapper>
  )
}

export default AdminCsvSettingsIndex
