import * as React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { ENUMS } from 'commons/enums'
import {
  CustomForm,
  CustomLabel,
  CustomSelect,
  CustomInput,
  ErrorMessage,
  WithRequiredBadge,
  DatePicker,
  CustomRadioButton,
  CustomRadioGroup,
} from 'components/atoms/FormComponents'
import {
  createEducationAttendances,
  updateEducationAttendance,
} from 'components/apis/educationAttendances'
import { USER_EDUCATIONS } from 'commons/constants'
import { VALIDATION_MESSAGES } from 'commons/constants'
import { slackNotification } from 'commons/slackNotification'
import { fetchDriverList } from 'components/apis/drivers'
import { fetchEducationSetting } from 'components/apis/education_settings'
import { UserContext } from 'providers/UserProvider'
import { useParams, useNavigate } from 'react-router-dom'
import { toastOnError, toastOnSuccess } from 'commons/toaster'
import NumberFormat from 'components/organisms/NumberFormat'
import { ShouldSubmitOnKeyPress } from 'commons/formUtils'
import FormSubmitSelectPageModal from 'components/atoms/FormSubmitSelectPageModal'
import ProcessingModal from 'components/atoms/ProcessingModal'
import { useErrorHandler } from 'react-error-boundary'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { FlexBox } from 'components/atoms/BoxComponents'

interface Props {
  type: 'create' | 'update'
  data?: UserEducation
  questionCount?: number
}

function EducationAttendanceForm(props: Props) {
  const user = React.useContext(UserContext)
  const { id } = useParams()
  const navigate = useNavigate()

  const [isModalShow, setModalShow] = React.useState(false)
  const [isProcessingModalShow, setIsProcessingModalShow] =
    React.useState(false)
  const [isMutationCalled, setIsMutationCalled] = React.useState(false)
  const [passScore, setPassScore] = React.useState<number>(null)
  const handleError = useErrorHandler()
  const safetyEducationType = 'safety'

  const doMethod =
    props.type === 'create' && !isMutationCalled
      ? createEducationAttendances
      : updateEducationAttendance

  const mutation = useMutation({
    mutationFn: doMethod,
    onError: (e: AxiosError<{ errors: string }>) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors)
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
  })

  const mutationWithoutNavigation = useMutation({
    mutationFn: doMethod,
    onSuccess: (res) => {
      setIsMutationCalled(true)
      setValue('id', res.data.id)
      toastOnSuccess('保存しました')
    },
    onError: (e: AxiosError<{ errors: string[] }>) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors.join(','))
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
    onSettled: () => {
      setIsProcessingModalShow(false)
    },
  })

  // 更新時の初期値
  const defaultValues = {
    ...props.data,
    questionCount: 10,
    driverId: props.data?.driverId || user.id,
    attendanceDate: props.data?.attendanceDate
      ? new Date(props.data.attendanceDate)
      : new Date(),
    attendanceTime:
      props.data?.attendanceTime ||
      new Date().toLocaleTimeString().slice(0, -3),
    isWebtest: props.data?.isWebtest || '0',
  }

  // ドライバーのセレクトボックスのoptionsをAPIから取得
  const { data: drivers } = useQuery(
    [`education_attendances/Form`, 'driver_list'],
    () =>
      fetchDriverList(true).then((res) =>
        Object.values(res.data.drivers).map((value: DriverSelectOption) => {
          return { value: value.id, label: value.fullName }
        })
      ),
    {
      onError: (error: AxiosError) =>
        navigate(`/errors?status=${error.response.status}`),
    }
  )

  React.useEffect(() => {
    fetchEducationSetting()
      .then((response) => {
        setPassScore(response.data.passingScore)
      })
      .catch((e) => {
        handleError(e.response.status)
      })
  }, [])

  const {
    getValues,
    setValue,
    control,
    register,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: defaultValues,
  })

  const formatParamsData = (data) => {
    const params = {
      ...data,
      userId: data.driverId,
      educationId: id,
      educationType: safetyEducationType,
      attendanceDatetime: [
        data.attendanceDate.toISOString().split('T')[0],
        data.attendanceTime,
      ].join(' '),
    }

    return params
  }

  const submit = async (callback: () => void) => {
    setModalShow(false)
    const hasNotError = await trigger(undefined, { shouldFocus: true })
    if (hasNotError) {
      callback()
    } else {
      toastOnError('入力内容に不備があります')
    }
  }

  const submitCallback = async (to: 'show' | 'index') => {
    setIsProcessingModalShow(true)
    const raw = structuredClone(getValues())
    const data = formatParamsData(raw)
    mutation.mutate(data, {
      onSuccess: (res) => {
        scrollTo({ top: 0 })
        if (to === 'show') {
          navigate(`/admin/educations/attendances/${id}/${res.data.id}/`)
        } else if (to === 'index') {
          navigate(
            `/admin/educations/attendances/${id}?focus_id=${res.data.id}`
          )
        }
      },
      onSettled: () => {
        setIsProcessingModalShow(false)
      },
    })
  }

  /**
   * keyboardInputを監視し、Enterが押された場合は更新リクエストを叩く(成功時に遷移しない)
   */
  const handleKeyDown = async (e) => {
    if (ShouldSubmitOnKeyPress(e)) {
      const hasNotError = await trigger(undefined, { shouldFocus: true })
      if (hasNotError) {
        setIsProcessingModalShow(true)
        e.preventDefault()
        const copy = structuredClone(getValues())
        const data = formatParamsData(copy)
        mutationWithoutNavigation.mutate(data)
        ;(document.activeElement as HTMLElement)?.blur()
      } else {
        toastOnError('入力内容に不備があります')
      }
    }
  }

  const handleModalCancelSelected = () => {
    setModalShow(false)
  }

  return (
    <>
      <BackGroundWhite>
        <CustomForm onKeyDown={handleKeyDown}>
          <>
            <CustomLabel>
              <WithRequiredBadge>
                {USER_EDUCATIONS.LABELS.USER_NAME}
              </WithRequiredBadge>
              <Controller
                name="driverId"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <CustomSelect
                    options={drivers}
                    value={drivers?.find(
                      (c) => String(c.value) === String(value)
                    )}
                    onChange={(option: ReactSelectOptionProps) =>
                      onChange(option.value)
                    }
                    placeholder="選択してください"
                  />
                )}
              />
              {errors.driverId && (
                <ErrorMessage sx={{ marginTop: '0.5rem' }}>
                  {VALIDATION_MESSAGES.required}
                </ErrorMessage>
              )}
            </CustomLabel>
            <CustomLabel>
              {`${USER_EDUCATIONS.LABELS.QUESTION_COUNT}(自動入力)`}
              <Controller
                control={control}
                name="questionCount"
                rules={{ required: true }}
                render={({ field }) => (
                  <NumberFormat
                    {...field}
                    customInput={CustomInput}
                    disabled={true}
                  />
                )}
              />
            </CustomLabel>
            <CustomLabel>
              <WithRequiredBadge>
                {USER_EDUCATIONS.LABELS.CORRECT_COUNT}
              </WithRequiredBadge>
              <Controller
                control={control}
                name="correctCount"
                rules={{ required: true }}
                render={({ field }) => (
                  <NumberFormat {...field} customInput={CustomInput} />
                )}
              />
              {errors.correctCount && (
                <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
              )}
            </CustomLabel>
            <CustomLabel>
              <WithRequiredBadge>
                {USER_EDUCATIONS.LABELS.ATTENDANCE_DATE}
              </WithRequiredBadge>
              <Controller
                control={control}
                name="attendanceDate"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <DatePicker onChange={onChange} selected={value} />
                )}
              />
              {errors.attendanceDate && (
                <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
              )}
            </CustomLabel>
            <CustomLabel>
              <WithRequiredBadge>
                {USER_EDUCATIONS.LABELS.ATTENDANCE_TIME}
              </WithRequiredBadge>
              <CustomInput
                {...register('attendanceTime', { required: true })}
              />
              {errors.attendanceTime && (
                <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
              )}
            </CustomLabel>

            <CustomLabel>
              <WithRequiredBadge>
                {USER_EDUCATIONS.LABELS.IS_WEBTEST}
              </WithRequiredBadge>
              <CustomRadioGroup>
                {Object.entries(ENUMS.USER_EDUCATIONS.IS_WEBTEST).map(
                  ([key, obj], index) => (
                    <React.Fragment key={index}>
                      <CustomRadioButton
                        {...register('isWebtest', { required: true })}
                        id={key}
                        value={obj.value}
                        key={index}
                        type="radio"
                      />
                      <label htmlFor={key}>{obj.label}</label>
                    </React.Fragment>
                  )
                )}
              </CustomRadioGroup>
              {errors.isWebtest && (
                <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
              )}
            </CustomLabel>
          </>
        </CustomForm>
      </BackGroundWhite>
      <FlexBox justifyContent={'flex-end'} sx={{ marginTop: '8px' }}>
        <ApplyButton
          pattern="block"
          onClick={(e) => {
            e.preventDefault()
            setModalShow(true)
          }}
        >
          保存する
        </ApplyButton>
      </FlexBox>

      <FormSubmitSelectPageModal
        isOpen={isModalShow}
        resourceName="受講履歴"
        handlers={{
          cancel: handleModalCancelSelected,
          toShow: () => submit(() => submitCallback('show')),
          toIndex: () => submit(() => submitCallback('index')),
        }}
      ></FormSubmitSelectPageModal>
      <ProcessingModal isOpen={isProcessingModalShow} />
    </>
  )
}

export default EducationAttendanceForm
