import { styled } from '@mui/system'

export const InvisibleForMobile = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 768px)': {
    display: 'none',
  },
})

export const InvisibleForLapTop = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '@media (min-width: 768px)': {
    display: 'none',
  },
})
