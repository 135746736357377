import * as React from 'react'
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormGetValues,
  useFieldArray,
} from 'react-hook-form'
import {
  BackGroundPalePink,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  ErrorMessage,
  ConvertibleCustomInput,
  ConvertibleCustomDateSelect,
  ConvertibleCustomRadioGroup,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import {
  DRIVERS,
  FAMILY_PROFILES,
  VALIDATION_MESSAGES,
} from 'commons/constants'
import { Box, styled } from '@mui/material'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { useNavigate } from 'react-router-dom'
import { palette, theme } from 'components/theme'
import { ENUMS } from 'commons/enums'

type FamilyProilesProps = {
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
  handleModeChange: (mapKey: DriverFormModeMapKey, mode: FormMode) => void
}

export default function FamilyProiles(props: FamilyProilesProps) {
  const navigate = useNavigate()
  const { fields } = useFieldArray({
    control: props.control,
    name: 'familyProfiles',
  })

  const reload = () => {
    if (
      window.confirm('保存していないデータは削除されます。よろしいですか？')
    ) {
      navigate(0)
    }
  }

  return (
    <BackGroundWhite>
      <FlexBox justifyContent="space-between" marginBottom="16px">
        <HeadingText>{DRIVERS.LABELS.FAMILY_PROFILE}</HeadingText>
        <Box>
          {/* 一時的にコメントアウト */}
          {/* {props.mode == 'show' && (
            <EditButtonOutlined
              onClick={() => {
                props.handleModeChange('familyProfiles', 'edit')
              }}
            >
              編集
            </EditButtonOutlined>
          )} */}
          {props.mode == 'edit' && (
            <FlexBox>
              <ApplyButton pattern="marginRight8" type="submit">
                保存する
              </ApplyButton>
              <CancelButtonOutlined onClick={reload}>
                キャンセル
              </CancelButtonOutlined>
            </FlexBox>
          )}
        </Box>
      </FlexBox>
      {fields.length == 0 && <p>データがありません</p>}
      {fields.map((_, i) => {
        return (
          <Box key={`family-profile-card-${i}`} mb={2}>
            <FamilyProfileInner
              index={i}
              mode={props.mode}
              control={props.control}
              register={props.register}
              errors={props.errors}
              getValues={props.getValues}
            ></FamilyProfileInner>
          </Box>
        )
      })}
    </BackGroundWhite>
  )
}

type FamilyProfileInnerProps = {
  index: number
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
}

const Title = styled('p')({
  fontWeight: 'bold',
  color: palette.text.gray.deep,
})

const FamilyProfileInner: React.FC<FamilyProfileInnerProps> = (props) => {
  return (
    <BackGroundPalePink>
      <Title>
        {DRIVERS.LABELS.FAMILY_PROFILE}
        {props.index + 1}
      </Title>
      <FlexBox
        alignItems="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel
          sx={{
            marginRight: '32px',
            [theme.breakpoints.down('md')]: {
              marginRight: '0',
            },
          }}
        >
          {FAMILY_PROFILES.LABELS.LAST_NAME}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey={`familyProfiles.${props.index}.lastName`}
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.familyProfiles?.[props.index]?.lastName && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
        <CustomLabel
          sx={{
            marginRight: '32px',
            [theme.breakpoints.down('md')]: {
              marginRight: '0',
            },
          }}
        >
          {FAMILY_PROFILES.LABELS.FIRST_NAME}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey={`familyProfiles.${props.index}.firstName`}
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.familyProfiles?.[props.index]?.firstName && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
      </FlexBox>
      <CustomLabel sx={{ marginRight: '32px' }}>
        {FAMILY_PROFILES.LABELS.BIRTHED_DATE}
        <ConvertibleCustomDateSelect
          mode={props.mode}
          formKeys={{
            year: `familyProfiles.${props.index}.birthedYear`,
            month: `familyProfiles.${props.index}.birthedMonth`,
            day: `familyProfiles.${props.index}.birthedDay`,
          }}
          control={props.control}
          getValues={props.getValues}
        />
      </CustomLabel>
      <CustomLabel>
        {FAMILY_PROFILES.LABELS.RELATIONSHIP}
        <ConvertibleCustomInput
          mode={props.mode}
          formKey={`familyProfiles.${props.index}.relationship`}
          register={props.register}
          getValues={props.getValues}
          sx={{ width: '150px' }}
        ></ConvertibleCustomInput>
        {props.errors.familyProfiles?.[props.index]?.relationship && (
          <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
        )}
      </CustomLabel>
      <CustomLabel>
        {FAMILY_PROFILES.LABELS.IS_DEPENDENT}
        <ConvertibleCustomRadioGroup
          mode={props.mode}
          items={Object.entries(ENUMS.DRIVERS.IS_DEPENDENT)}
          formKey={`familyProfiles.${props.index}.isDependent`}
          getValues={props.getValues}
          register={props.register}
        ></ConvertibleCustomRadioGroup>
        {props.errors.familyProfiles?.[props.index]?.isDependent && (
          <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
        )}
      </CustomLabel>
    </BackGroundPalePink>
  )
}
