import * as React from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import {
  BackGroundWhite,
  BackGroundGray,
} from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomInput,
  CustomTextArea,
  HeadingText,
  UnitText,
  CustomSelectShort,
} from 'components/atoms/FormComponents'
import {
  AddButtonOutlined,
  DeleteButton,
} from 'components/atoms/ButtonComponenets'
import { yearRange, monthRange } from 'commons/date'
import { FlexBox } from 'components/atoms/BoxComponents'
import { PERSONAL_HISTORIES } from 'commons/constants'
import { Box, useTheme } from '@mui/material'

function PersonalHistoryForm(props: {
  register
  control
  personalHistoryIndex: number
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>
}) {
  const { register, control, personalHistoryIndex } = props
  const theme = useTheme()
  return (
    <React.Fragment>
      <CustomLabel>
        {PERSONAL_HISTORIES.LABELS.INSTITUTION_NAME}
        <CustomInput
          onKeyDown={props.onInputKeyDown}
          {...register(
            `personalHistories[${personalHistoryIndex}].institutionName`
          )}
        />
      </CustomLabel>

      <CustomLabel>
        {PERSONAL_HISTORIES.LABELS.ADMISSION_DATE}
        <FlexBox flexWrap="wrap">
          <Controller
            name={`personalHistories[${personalHistoryIndex}].admissionYear`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelectShort
                options={yearRange}
                value={
                  yearRange.find((c) => c.value === value) || {
                    value: 1980,
                    label: 1980,
                  }
                }
                onChange={(option: ReactSelectOptionProps) =>
                  onChange(option.value)
                }
                placeholder="未選択"
              />
            )}
          />
          <UnitText>年</UnitText>
          <Controller
            name={`personalHistories[${personalHistoryIndex}].admissionMonth`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelectShort
                options={monthRange}
                value={monthRange.find((c) => c.value === value)}
                onChange={(option: ReactSelectOptionProps) =>
                  onChange(option.value)
                }
                placeholder="未選択"
              />
            )}
          />
          <UnitText>月</UnitText>
        </FlexBox>
      </CustomLabel>

      <CustomLabel>
        {PERSONAL_HISTORIES.LABELS.RETIREMENT_DATE}
        <FlexBox flexWrap="wrap">
          <Controller
            name={`personalHistories[${personalHistoryIndex}].retirementYear`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelectShort
                options={yearRange}
                value={
                  yearRange.find((c) => c.value === value) || {
                    value: 1980,
                    label: 1980,
                  }
                }
                onChange={(option: ReactSelectOptionProps) =>
                  onChange(option.value)
                }
                placeholder="未選択"
              />
            )}
          />
          <UnitText>年</UnitText>
          <Controller
            name={`personalHistories[${personalHistoryIndex}].retirementMonth`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelectShort
                options={monthRange}
                value={monthRange.find((c) => c.value === value)}
                onChange={(option: ReactSelectOptionProps) =>
                  onChange(option.value)
                }
                placeholder="未選択"
              />
            )}
          />
          <UnitText>月</UnitText>
        </FlexBox>
      </CustomLabel>

      <CustomLabel>
        {PERSONAL_HISTORIES.LABELS.MEMO}
        <CustomTextArea
          rows={3}
          {...register(`personalHistories[${personalHistoryIndex}].memo`)}
        />
      </CustomLabel>
    </React.Fragment>
  )
}

export default function DriversPersonalHistory(props: {
  register
  control
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>
}) {
  const { register, control } = props
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: 'personalHistories',
  })
  const initPersonalHistory = {
    admissionYear: undefined,
    admissionMonth: undefined,
    retirementYear: undefined,
    retirementMonth: undefined,
    institutionName: null,
    memo: null,
  }

  const deletePersonalHistory = (index) => {
    remove(index)
  }

  const addPersonalHistory = () => {
    append(initPersonalHistory)
  }

  return (
    <BackGroundWhite>
      <HeadingText>履歴</HeadingText>
      {fields.map((_, index) => (
        <Box mb={2} key={index}>
          <BackGroundGray>
            履歴{index + 1}
            <PersonalHistoryForm
              register={register}
              control={control}
              personalHistoryIndex={index}
              onInputKeyDown={props.onInputKeyDown}
            />
            <DeleteButton onClick={() => deletePersonalHistory(index)}>
              削除
            </DeleteButton>
          </BackGroundGray>
        </Box>
      ))}
      <AddButtonOutlined onClick={addPersonalHistory}>
        履歴を追加
      </AddButtonOutlined>
    </BackGroundWhite>
  )
}
