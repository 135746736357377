import * as React from 'react'
import {
  BackGroundPalePink,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import { Box, styled } from '@mui/material'
import { palette, theme } from 'components/theme'
import { VEHICLES } from 'commons/constants'
import { FlexBox } from 'components/atoms/BoxComponents'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

type MaintenancesProps = {
  data: Vehicle
  maintenancesImages: File[]
  maintenanceChildrenFiles: File[][]
}

export default function Maintenances(props: MaintenancesProps) {
  const Title = styled('h2')({
    fontSize: '20px',
    margin: 0,
    marginBottom: '16px',
  })
  const SubTitle = styled('p')({
    fontSize: '16px',
    margin: 0,
    color: palette.text.gray.deep,
    fontWeight: 'bold',
  })

  const Text = styled('p')({
    fontSize: '16px',
    margin: 0,
  })
  const Label = styled('p')({
    fontSize: '14px',
    margin: 0,
    color: palette.text.gray.pale,
    marginTop: '32px',
    marginBottom: '8px',
  })
  const TextWrapper = styled('div')({
    width: '300px',
    paddingBottom: '8px',
    paddingLeft: '4px',
    borderBottom: `1px solid ${palette.border.pale}`,
    overflowWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  })
  const TextWrapperFull = styled(TextWrapper)({
    width: '628px',
  })
  const FileTextWrapper = styled(TextWrapper)({
    width: '100%',
    marginTop: '16px',
  })
  const TextWrapperLong = styled(TextWrapper)({
    width: '420px',
  })
  const TextWrapperShort = styled(TextWrapper)({
    width: '140px',
  })

  const formatDate = (year: string, month: string, day: string) => {
    if (year && month && day) return `${year}年${month}月${day}日`
    else return '未入力'
  }
  const formatMoneyText = (money: string | number) => {
    if (!money) return ''
    const parsed = parseInt(money as string)
    if (isNaN(parsed)) {
      return money
    } else {
      return new Intl.NumberFormat('ja-JP', {
        style: 'currency',
        currency: 'JPY',
      }).format(parsed)
    }
  }

  return (
    <BackGroundWhite>
      <Title>{VEHICLES.LABELS.MAINTENANCE}</Title>
      {props.data.maintenances.length == 0 ? (
        <Text>データがありません</Text>
      ) : (
        props.data.maintenances.map((maintenance, i) => {
          return (
            <Box mb={3} key={`maintenance-${i}`}>
              <BackGroundPalePink>
                <SubTitle>
                  {VEHICLES.LABELS.MAINTENANCE}
                  {i + 1}
                </SubTitle>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.MAINTENANCE_TITLE}</Label>
                    <TextWrapperLong>
                      <Text>{maintenance.title}</Text>
                    </TextWrapperLong>
                  </Box>
                  <Box>
                    <Label>{VEHICLES.LABELS.MAINTENANCE_NUMBER}</Label>
                    <TextWrapperShort>
                      <Text>{maintenance.number || '未入力'}</Text>
                    </TextWrapperShort>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.MAINTENANCE_DATE}</Label>
                    <TextWrapper>
                      <Text>
                        {formatDate(
                          maintenance.maintenanceYear,
                          maintenance.maintenanceMonth,
                          maintenance.maintenanceDay
                        ) || '未入力'}
                      </Text>
                    </TextWrapper>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.MAINTENANCE_TOTAL_FEE}</Label>
                    <TextWrapperShort>
                      <Text>
                        {formatMoneyText(maintenance.totalFee) || '未入力'}
                      </Text>
                    </TextWrapperShort>
                  </Box>
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.MAINTENANCE_PARTS_FEE}</Label>
                    <TextWrapperShort>
                      <Text>
                        {formatMoneyText(maintenance.partsFee) || '未入力'}
                      </Text>
                    </TextWrapperShort>
                  </Box>
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.MAINTENANCE_TECHNICAL_FEE}</Label>
                    <TextWrapperShort>
                      <Text>
                        {formatMoneyText(maintenance.technicalFee) || '未入力'}
                      </Text>
                    </TextWrapperShort>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.MAINTENANCE_AREA}</Label>
                    <TextWrapper>
                      <Text>{maintenance.maintenanceArea || '未入力'}</Text>
                    </TextWrapper>
                  </Box>
                  <Box>
                    <Label>{VEHICLES.LABELS.MAINTENANCE_COMPANY_NAME}</Label>
                    <TextWrapper>
                      <Text>{maintenance.companyName || '未入力'}</Text>
                    </TextWrapper>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box>
                    <Label>{VEHICLES.LABELS.MAINTENANCE_MILEAGE}</Label>
                    <TextWrapperShort>
                      <Text>
                        {maintenance.mileage
                          ? `${maintenance.mileage}km`
                          : '未入力'}
                      </Text>
                    </TextWrapperShort>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box>
                    <Label>{VEHICLES.LABELS.MAINTENANCE_MEMO}</Label>
                    <TextWrapperFull>
                      <Text>{maintenance.memo || '未入力'}</Text>
                    </TextWrapperFull>
                  </Box>
                </FlexBox>
                <Label>
                  {VEHICLES.LABELS.FILE}(
                  {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
                </Label>
                <FileTextWrapper>
                  {props.maintenanceChildrenFiles[i]?.length > 0 ? (
                    props.maintenanceChildrenFiles[i].map((file, i) => {
                      return (
                        <FlexBox key={`${file.name}-${i}`} mb={1}>
                          <InsertDriveFileOutlinedIcon fontSize="small" />
                          <Text sx={{ marginLeft: '8px' }}>
                            <a
                              href={URL.createObjectURL(file)}
                              download={decodeURI(file.name)}
                            >
                              {decodeURI(file.name)}
                            </a>
                          </Text>
                        </FlexBox>
                      )
                    })
                  ) : (
                    <Text>ファイルなし</Text>
                  )}
                </FileTextWrapper>
              </BackGroundPalePink>
            </Box>
          )
        })
      )}
      <Label>
        {VEHICLES.LABELS.FILE}({VEHICLES.LABELS.MAINTENANCE}の
        {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_PARENT})
      </Label>
      <FileTextWrapper>
        {props.maintenancesImages.length > 0 ? (
          props.maintenancesImages.map((file, i) => {
            return (
              <FlexBox key={`${file.name}-${i}`} mb={1}>
                <InsertDriveFileOutlinedIcon fontSize="small" />
                <Text sx={{ marginLeft: '8px' }}>
                  <a
                    href={URL.createObjectURL(file)}
                    download={decodeURI(file.name)}
                  >
                    {decodeURI(file.name)}
                  </a>
                </Text>
              </FlexBox>
            )
          })
        ) : (
          <Text>ファイルなし</Text>
        )}
      </FileTextWrapper>
    </BackGroundWhite>
  )
}
