import * as React from 'react'
import { USER_EDUCATIONS } from 'commons/constants'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  BoxBorderBottom,
  FlexBox,
  MarginBox,
} from 'components/atoms/BoxComponents'
import { Typography } from '@mui/material'
import { TextBold } from 'components/atoms/TextComponents'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { deleteEducationAttendance } from 'components/apis/educationAttendances'
import { useNavigate } from 'react-router-dom'
import { toastOnError } from 'commons/toaster'
import { useMutation } from '@tanstack/react-query'
import { UserContext } from 'providers/UserProvider'
import { slackNotification } from 'commons/slackNotification'

export default function EducationAttendanceDetail(props: {
  data: UserEducation
}) {
  const { data } = props
  const navigate = useNavigate()
  const mutation = useMutation({
    mutationFn: deleteEducationAttendance,
    onSuccess: () => navigate('/admin/educations/attendances/'),
    onError: (e: { response: { errors: string } }) => {
      toastOnError(e.response.errors)
      // 422エラー時にslack通知する
      slackNotification(e, user)
    },
  })
  const user = React.useContext(UserContext)

  const onClickDelete = () => {
    mutation.mutate(data.id)
  }

  return (
    <BackGroundWhite>
      <FlexBox flexDirection={'column'}>
        {user.isXmileAdmin ||
          (user.isGroupParentCompanyAdmin && (
            <MarginBox sx={{ margin: '1rem' }}>
              <TextBold>{USER_EDUCATIONS.LABELS.COMPANY}</TextBold>
              <BoxBorderBottom>
                <Typography>{data.companyName}</Typography>
              </BoxBorderBottom>
            </MarginBox>
          ))}

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{USER_EDUCATIONS.LABELS.OFFICE}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.officeName}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{USER_EDUCATIONS.LABELS.USER_NAME}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.userName}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{USER_EDUCATIONS.LABELS.QUESTION_COUNT}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.questionCount}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{USER_EDUCATIONS.LABELS.CORRECT_COUNT}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.correctCount}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{USER_EDUCATIONS.LABELS.ATTENDANCE_DATETIME}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.attendanceDatetime}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{USER_EDUCATIONS.LABELS.IS_WEBTEST}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.isWebtestText}</Typography>
          </BoxBorderBottom>
        </MarginBox>
      </FlexBox>
      <DeleteButton sx={{ margin: 'none' }} onClick={onClickDelete}>
        このデータを削除する
      </DeleteButton>
    </BackGroundWhite>
  )
}
