import * as React from 'react'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { Helmet } from 'react-helmet-async'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { styled } from '@mui/styles'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { FlexBox } from 'components/atoms/BoxComponents'
import { ADMIN_ROUTES } from 'commons/constants'

export const CautionLi = styled('li')({
  marginBottom: '25px',
})

export const BoldLabel = styled('label')({
  fontWeight: 'bold',
})

function AdminCsvSettingsCautionsDriverImportIndex() {
  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.CSV_SETTINGS.INDEX ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>従業員CSVインポート 注意事項</h1>
      <BackGroundWhite>
        <div style={{ marginBottom: '50px' }}>
          <h2>1. CSV作成時の注意事項</h2>
          <ul>
            <CautionLi>
              <BoldLabel>事業所</BoldLabel>
              <ul>
                <li>
                  モビポケの会社管理で登録済みの事業所名を入力してください。
                </li>
                <li>
                  未登録の事業所が入力された場合、その従業員の所属事業所にはインポート実行ユーザーの所属事業所を設定します。
                </li>
              </ul>
            </CautionLi>
            <CautionLi>
              <BoldLabel>権限</BoldLabel>
              <ul>
                <li>
                  以下のいずれかを入力してください。
                  <ul>
                    <li>ドライバー</li>
                    <li>チーム長</li>
                    <li>配車係</li>
                    <li>事業所責任者</li>
                    <li>システム管理者</li>
                    <li>アシスタント</li>
                  </ul>
                </li>
                <li>
                  従業員を新規でインポートする際に権限が未指定の場合、権限にはドライバーが設定されます。
                </li>
                <li>
                  従業員を上書きでインポートする際に権限が未指定の場合、権限は変更されません。
                </li>
              </ul>
            </CautionLi>
            <CautionLi>
              <BoldLabel>ログインID</BoldLabel>
              <ul>
                <li>
                  新規インポート時に未指定の場合(列がない場合も含む)、ログインIDは自動生成されます。自動生成されたログインIDは従業員管理から確認ください。
                </li>
              </ul>
            </CautionLi>
            <CautionLi>
              <BoldLabel>パスワード</BoldLabel>
              <ul>
                <li>
                  新規インポート時に未指定の場合(列がない場合も含む)、、インポートの実行年月日が登録されます。
                  (例:
                  2023年7月1日に新規インポートされた従業員のパスワードは20230701)
                </li>
              </ul>
            </CautionLi>
            <CautionLi>
              <BoldLabel>姓名、姓名（カナ）</BoldLabel>
              <ul>
                <li>入力必須です。</li>
                <li>
                  姓と名の間は半角または全角スペースを入力してください。
                  <br />
                  入力例: モビポケ 太郎、モビポケ タロウ
                </li>
                <li>
                  <BoldLabel>姓名（カナ）</BoldLabel>
                  は半角または全角のカタカナで入力してください。
                  <br />
                  半角カタカナの場合は全角カタカナに変換されて登録されます。
                </li>
              </ul>
            </CautionLi>
            <CautionLi>
              <BoldLabel>血液型</BoldLabel>
              <ul>
                <li>
                  ABO式血液型で入力してください。Rh式血液型には現在は対応しておりません。
                  <br />
                  入力例: A型、B型、O、AB
                </li>
              </ul>
            </CautionLi>
            <CautionLi>
              <BoldLabel>都道府県</BoldLabel>
              <ul>
                <li>「都・道・府・県」まで含めて入力してください。</li>
              </ul>
            </CautionLi>
            <CautionLi>
              <BoldLabel>資格の種類、免許証の種類</BoldLabel>
              <ul>
                <li>
                  保有している資格(免許証)の列に「有り」「有」「あり」
                  のいずれかを入力してください。
                </li>
                <li>保有していない項目の列は空欄で構いません。</li>
              </ul>
            </CautionLi>
            <CautionLi>
              <BoldLabel>年月日項目(生年月日等)</BoldLabel>
              <ul>
                <li>
                  西暦表記で入力してください。
                  <br />
                  入力例: 2023年1月1日、2023/1/1、2023.1.1、2023-1-1、20230101
                </li>
              </ul>
            </CautionLi>
          </ul>
        </div>
      </BackGroundWhite>
      <FlexBox
        justifyContent={'flex-end'}
        flexDirection={'row-reverse'}
        sx={{ margin: '8px 0 20px 0' }}
      >
        <ApplyButton href={ADMIN_ROUTES.CSV_SETTING_INDEX}>
          CSV設定画面へ
        </ApplyButton>
      </FlexBox>
    </PageWrapper>
  )
}

export default AdminCsvSettingsCautionsDriverImportIndex
