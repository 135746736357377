import axios from 'commons/axiosClient'
import { ADMIN_API_ENDPOINTS, API_ENDPOINTS } from 'commons/constants'

export function deleteDirectionRecord(id: number) {
  return axios.delete([API_ENDPOINTS.DIRECTION_RECORDS, id].join(''))
}

export function deleteAdminDirectionRecord(id: number) {
  return axios.delete([ADMIN_API_ENDPOINTS.DIRECTION_RECORDS, id].join(''))
}
