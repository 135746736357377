import { useQuery } from '@tanstack/react-query'
import { createQueryKey } from 'commons/functions'
import { fetchMaintenanceArticles } from 'components/apis/articles'
import { useErrorHandler } from 'react-error-boundary'

export default function useMaintenanceArticles(
  searchParams?: SearchMaintenanceArticle,
  paginateParams?: PaginateParams
) {
  const handleError = useErrorHandler()
  const queryKey = createQueryKey('articles_maintenances', {
    ...searchParams,
    ...paginateParams,
  })

  const {
    data: maintenanceArticlesData,
    isLoading: isLoadingMaintenanceArticlesData,
  } = useQuery({
    queryKey,
    queryFn: async () => {
      const result = await fetchMaintenanceArticles(
        searchParams,
        paginateParams
      )
      return result.data
    },
    onError: (error) => {
      handleError(error)
    },
  })

  return {
    maintenanceArticlesData,
    isLoadingMaintenanceArticlesData,
  }
}
