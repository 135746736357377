import { styled } from '@mui/styles'
import { palette, theme } from 'components/theme'
import * as React from 'react'
import Modal from 'react-modal'

type CustomModalProps = {
  isOpen: boolean
  text: {
    main: string
    confirm: string
    cancel: string
  }
  onCancelSelected: () => void
  onConfirmSelected: () => void
}
const StyledModal = styled(Modal)({
  marginLeft: 0,
  display: 'flex',
  flexFlow: 'wrap',
  flexDirection: 'column',
  justifyContents: 'center',
  alignItems: 'center',
  width: '500px',
  height: '200px',
  background: 'white',
  border: '1px solid rgb(204, 204, 204)',
  [theme.breakpoints.down('sm')]: { width: '300px' },
})
const Inner = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})
const Text = styled('p')({
  margin: '0 0 24px',
  whiteSpace: 'pre-wrap',
})
const Button = styled('button')({
  padding: '8px',
  width: '100px',
  cursor: 'pointer',
  borderRadius: '4px',
})
const CancelButton = styled(Button)({
  background: 'white',
  color: `${palette.primary.deep}`,
  border: `1px solid ${palette.primary.deep}`,
  marginRight: '16px',
})
const ConfirmButton = styled(Button)({
  background: `${palette.primary.deep}`,
  color: 'white',
  border: `1px solid ${palette.primary.deep}`,
})

const CustomModal: React.FC<CustomModalProps> = (props) => {
  return (
    <StyledModal
      isOpen={props.isOpen}
      style={{
        overlay: {
          minHeight: '100%',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {},
      }}
    >
      <Inner>
        <Text>{props.text.main}</Text>
        <div>
          <CancelButton onClick={props.onCancelSelected}>
            {props.text.cancel}
          </CancelButton>
          <ConfirmButton onClick={props.onConfirmSelected}>
            {props.text.confirm}
          </ConfirmButton>
        </div>
      </Inner>
    </StyledModal>
  )
}

export default CustomModal
