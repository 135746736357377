import * as React from 'react'
import { CustomLabel, CustomSelect } from 'components/atoms/FormComponents'
import { Control, Controller } from 'react-hook-form'
import { BackGroundPalePink } from 'components/atoms/BackGroundComponents'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Box } from '@mui/material'

type VideoCardProps = {
  subjectId: number
  videoOptions: SelectOption[]
  control: Control<EducationAnnualPlan>
  deleteItem: (index: number) => void
  index: number
}

const VideoCard: React.FC<VideoCardProps> = (props) => {
  const { subjectId, videoOptions, index, control, deleteItem } = props

  return (
    <BackGroundPalePink sx={{ marginBottom: '10px' }}>
      動画
      {index + 1}
      <FlexBox>
        <Box>
          <CustomLabel
            sx={{
              marginBottom: '10px',
            }}
          >
            <Controller
              name={`subjects.${subjectId}.videos.${index}.id`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  options={videoOptions}
                  value={videoOptions?.find((option) => option.value === value)}
                  onChange={(option: ReactSelectOptionProps) =>
                    onChange(option.value)
                  }
                  placeholder="未選択"
                />
              )}
            />
          </CustomLabel>
        </Box>
        <DeleteButton onClick={() => deleteItem(index)}>削除</DeleteButton>
      </FlexBox>
    </BackGroundPalePink>
  )
}

export default VideoCard
