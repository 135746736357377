import * as React from 'react'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { styled } from '@mui/material'
import { palette, theme } from 'components/theme'
import { VEHICLES } from 'commons/constants'
import { ENUMS } from 'commons/enums'

type VehicleOperationStatusProps = {
  data: {
    operationStatus: string
  }
}

export default function VehicleOperationStatus(
  props: VehicleOperationStatusProps
) {
  const Title = styled('h2')({
    fontSize: '20px',
    margin: 0,
  })
  const Text = styled('p')({
    fontSize: '16px',
    margin: 0,
  })
  const TextWrapper = styled('div')({
    width: '300px',
    marginTop: '48px',
    paddingBottom: '8px',
    paddingLeft: '4px',
    borderBottom: `1px solid ${palette.border.pale}`,
    overflowWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  })

  const statusText = Object.entries(ENUMS.VEHICLES.OPERATION_STATUS_TYPE)
    .map((item) => {
      return item[1]
    })
    .find((item) => {
      return item.value == props.data.operationStatus
    })?.label

  return (
    <BackGroundWhite>
      <Title>{VEHICLES.LABELS.OPERATION_STATUS}</Title>
      <TextWrapper>
        <Text>{statusText}</Text>
      </TextWrapper>
    </BackGroundWhite>
  )
}
