export const NOTIFICATION_MODELS = [
  { label: '受講履歴', value: 'userEducation' },
  { label: '年間計画', value: 'educationAnnualPlan' },
  { label: '事故報告書', value: 'accident' },
  { label: 'ドライバー', value: 'user' },
  { label: '車両', value: 'vehicle' },
  { label: 'アルコールチェック', value: 'alcoholCheck' },
  { label: '請求書', value: 'bill' },
  { label: '取引先', value: 'partnerCompany' },
]
