import * as React from 'react'
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormGetValues,
  useFieldArray,
} from 'react-hook-form'
import {
  BackGroundPalePink,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  ErrorMessage,
  ConvertibleCustomInput,
  ConvertibleCustomSelect,
  ConvertibleCustomDateSelect,
  ConvertibleCustomDropZone,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import {
  DRIVERS,
  DIRECTION_RECORDS,
  VALIDATION_MESSAGES,
} from 'commons/constants'
import { Box, styled } from '@mui/material'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { useNavigate } from 'react-router-dom'
import { palette } from 'components/theme'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

type DirectionRecordsProps = {
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
  handleModeChange: (mapKey: DriverFormModeMapKey, mode: FormMode) => void
  directionRecordsFiles: File[]
  directionRecordChildrenFiles: File[][]
  setDirectionRecordsFiles: React.Dispatch<React.SetStateAction<File[]>>
}

export default function DirectionRecords(props: DirectionRecordsProps) {
  const navigate = useNavigate()
  const { fields } = useFieldArray({
    control: props.control,
    name: 'directionRecords',
  })

  const reload = () => {
    if (
      window.confirm('保存していないデータは削除されます。よろしいですか？')
    ) {
      navigate(0)
    }
  }

  const onDrop = (acceptedFiles: File[]) => {
    props.setDirectionRecordsFiles([
      ...props.directionRecordsFiles,
      ...acceptedFiles,
    ])
  }
  const removeDirectionRecordImage = (index: number) => {
    props.setDirectionRecordsFiles(
      props.directionRecordsFiles.filter((_, i) => i !== index)
    )
  }

  return (
    <BackGroundWhite>
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        marginBottom="16px"
      >
        <HeadingText>{DRIVERS.LABELS.DIRECTION_RECORDS}</HeadingText>
        <Box>
          {/* 一時的にコメントアウト */}
          {/* {props.mode == 'show' && (
            <EditButtonOutlined
              onClick={() => {
                props.handleModeChange('directionRecords', 'edit')
              }}
            >
              編集
            </EditButtonOutlined>
          )} */}
          {props.mode == 'edit' && (
            <FlexBox>
              <ApplyButton pattern="marginRight8" type="submit">
                保存する
              </ApplyButton>
              <CancelButtonOutlined onClick={reload}>
                キャンセル
              </CancelButtonOutlined>
            </FlexBox>
          )}
        </Box>
      </FlexBox>
      {fields.map((_, i) => {
        return (
          <Box key={`direction-record-${i}`} mb={2}>
            <DirectionRecordInner
              index={i}
              mode={props.mode}
              control={props.control}
              register={props.register}
              errors={props.errors}
              getValues={props.getValues}
              directionRecordChildrenFiles={props.directionRecordChildrenFiles}
            ></DirectionRecordInner>
          </Box>
        )
      })}
      <CustomLabel sx={{ marginTop: '32px' }}>
        {DRIVERS.LABELS.FILE}(特別指導の
        {DRIVERS.LABELS.FILE_UPLOAD_DESCRIPTION_PARENT})
        <ConvertibleCustomDropZone
          mode={props.mode}
          formKey={`directionRecordsFileUrls`}
          control={props.control}
          getValues={props.getValues}
          files={props.directionRecordsFiles}
          onDrop={onDrop}
          onCancelIconClick={removeDirectionRecordImage}
        ></ConvertibleCustomDropZone>
      </CustomLabel>
    </BackGroundWhite>
  )
}

type DirectionRecordInnerProps = {
  index: number
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
  directionRecordChildrenFiles: File[][]
}

const Title = styled('p')({
  fontWeight: 'bold',
  color: palette.text.gray.deep,
})

const driverTypeOptions = [
  { value: 'initial', label: '初任' },
  { value: 'older', label: '高齢' },
  { value: 'specific', label: '特別' },
]

const Text = styled('p')({
  fontSize: '16px',
  margin: 0,
})

const FileTextWrapper = styled('div')({
  width: '100%',
  paddingBottom: '8px',
  paddingLeft: '4px',
  marginTop: '16px',
  borderBottom: `1px solid ${palette.border.pale}`,
  overflowWrap: 'break-word',
})

const DirectionRecordInner: React.FC<DirectionRecordInnerProps> = (props) => {
  return (
    <BackGroundPalePink>
      <Title>
        {DRIVERS.LABELS.DIRECTION_RECORDS}
        {props.index + 1}
      </Title>
      <FlexBox alignItems="center" flexWrap="wrap">
        <CustomLabel sx={{ marginRight: '32px' }}>
          {DIRECTION_RECORDS.LABELS.DRIVER_TYPE}
          <ConvertibleCustomSelect
            mode={props.mode}
            formKey={`directionRecords.${props.index}.driverType`}
            control={props.control}
            getValues={props.getValues}
            options={driverTypeOptions}
          ></ConvertibleCustomSelect>
        </CustomLabel>
        <CustomLabel sx={{ marginRight: '32px' }}>
          {DIRECTION_RECORDS.LABELS.CONSULTATION_DATE}
          <ConvertibleCustomDateSelect
            mode={props.mode}
            formKeys={{
              year: `directionRecords.${props.index}.consultationYear`,
              month: `directionRecords.${props.index}.consultationMonth`,
              day: `directionRecords.${props.index}.consultationDay`,
            }}
            control={props.control}
            getValues={props.getValues}
          ></ConvertibleCustomDateSelect>
        </CustomLabel>
      </FlexBox>
      <FlexBox alignItems="center" flexWrap="wrap">
        <CustomLabel sx={{ width: '100%' }}>
          {DIRECTION_RECORDS.LABELS.DESCRIPTION}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey={`directionRecords.${props.index}.description`}
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.directionRecords?.[props.index]?.description && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
      </FlexBox>
      <CustomLabel>
        {DRIVERS.LABELS.FILE}(特別指導の
        {DRIVERS.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
        <FileTextWrapper>
          {props.directionRecordChildrenFiles[props.index]?.length > 0 ? (
            props.directionRecordChildrenFiles[props.index].map((file, i) => {
              return (
                <FlexBox key={`${file.name}-${i}`} mb={1}>
                  <InsertDriveFileOutlinedIcon fontSize="small" />
                  <Text sx={{ marginLeft: '8px' }}>
                    <a
                      href={URL.createObjectURL(file)}
                      download={decodeURI(file.name)}
                    >
                      {decodeURI(file.name)}
                    </a>
                  </Text>
                </FlexBox>
              )
            })
          ) : (
            <Text>ファイルがありません</Text>
          )}
        </FileTextWrapper>
      </CustomLabel>
    </BackGroundPalePink>
  )
}
