import * as React from 'react'
import { Controller } from 'react-hook-form'
import {
  BackGroundWhite,
  BackGroundPalePink,
} from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomInput,
  HeadingText,
  CustomTextArea,
  CustomCheckBoxFormShort,
  CustomDropZone,
  CustomDateSelect,
  WithDescriptionTooltipIcon,
  CustomRadioGroup,
  CustomRadioButton,
  CustomSelect,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { LICENSE_TYPE } from 'commons/licenseType'
import { DRIVERS, LICENSES, TOOLTIP_TEXTS } from 'commons/constants'
import { ENUMS } from 'commons/enums'
import { Box, styled, useTheme } from '@mui/material'
import { TOP_LICENSE_TYPE } from 'commons/topLicenseType'
import Button from '@mui/material/Button'

const ClearButton = styled('button')({
  background: 'transparent',
  padding: 0,
  border: 0,
  cursor: 'pointer',
  marginLeft: '8px',
  display: 'flex',
  alignItems: 'center',
})

export default function DriversLicense(props: {
  register
  control
  setValue
  licenseImages: File[]
  setLicenseImages
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>
}) {
  const { register, control, setValue, licenseImages, setLicenseImages } = props
  const licenseArray = [LICENSE_TYPE.slice(0, 5), LICENSE_TYPE.slice(5)]
  const theme = useTheme()
  const handleClearDateButton = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    dateType: string
  ) => {
    e.preventDefault()
    setValue(`license.${dateType}Year`, null, { shouldValidate: true })
    setValue(`license.${dateType}Month`, null, { shouldValidate: true })
    setValue(`license.${dateType}Day`, null, { shouldValidate: true })
  }

  // 画像設定時のコールバック
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setLicenseImages([...licenseImages, ...acceptedFiles])
    },
    [licenseImages]
  )

  return (
    <BackGroundWhite>
      <HeadingText>{DRIVERS.LABELS.LICENSE}</HeadingText>
      <CustomLabel>
        {LICENSES.LABELS.LICENSE_NUMBER}
        <CustomInput
          {...register('license.licenseNumber')}
          onKeyDown={props.onInputKeyDown}
        />
      </CustomLabel>

      <CustomLabel>
        {LICENSES.LABELS.ISSUANCED_DATE}
        <FlexBox
          alignItems="center"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          <CustomDateSelect
            formKeys={{
              year: 'license.issuancedYear',
              month: 'license.issuancedMonth',
              day: 'license.issuancedDay',
            }}
            control={props.control}
            defaultToday={false}
            defaultValue={{ year: new Date().getFullYear() }}
          ></CustomDateSelect>
          <ClearButton
            onClick={(e) => {
              handleClearDateButton(e, 'issuanced')
            }}
            sx={{
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            }}
          >
            <CancelIcon />
          </ClearButton>
          <Button
            onClick={(e) => {
              handleClearDateButton(e, 'issuanced')
            }}
          >
            クリア
          </Button>
        </FlexBox>
      </CustomLabel>
      <CustomLabel>
        {LICENSES.LABELS.EXPIRED_DATE}
        <FlexBox
          alignItems="center"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          <CustomDateSelect
            formKeys={{
              year: 'license.expiredYear',
              month: 'license.expiredMonth',
              day: 'license.expiredDay',
            }}
            control={control}
            defaultToday={false}
            defaultValue={{ year: new Date().getFullYear() }}
          ></CustomDateSelect>
          <WithDescriptionTooltipIcon
            text={`${TOOLTIP_TEXTS.NOTIFICATION_AUTO_CREATE}${TOOLTIP_TEXTS.DRIVER_NOTIFICATION_TARGET_USER}`}
          ></WithDescriptionTooltipIcon>
          <ClearButton
            onClick={(e) => handleClearDateButton(e, 'expired')}
            sx={{ [theme.breakpoints.down('sm')]: { display: 'none' } }}
          >
            <CancelIcon />
          </ClearButton>
          <Button onClick={(e) => handleClearDateButton(e, 'expired')}>
            クリア
          </Button>
        </FlexBox>
      </CustomLabel>

      <CustomLabel>
        {LICENSES.LABELS.LICENSE_TYPE}
        {licenseArray.map((licenseTypes, arrayIndex) => (
          <React.Fragment key={arrayIndex}>
            <FlexBox flexWrap="wrap">
              {licenseTypes.map((licenseType, index) => (
                <React.Fragment key={index}>
                  <Controller
                    name={`license.${licenseType.value}`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CustomCheckBoxFormShort>
                        <label>
                          <input
                            type="checkbox"
                            checked={value}
                            onChange={() => onChange(!value)}
                          />
                          {licenseType.label}
                        </label>
                      </CustomCheckBoxFormShort>
                    )}
                  />
                </React.Fragment>
              ))}
            </FlexBox>
          </React.Fragment>
        ))}
      </CustomLabel>

      <BackGroundPalePink>
        <HeadingText>{DRIVERS.LABELS.LICENSE_DATE}</HeadingText>
        <CustomLabel>
          {DRIVERS.LABELS.BASIC_LICENSE}
          <Box sx={{ marginBottom: '20px' }}>
            <CustomRadioGroup>
              {Object.entries(ENUMS.DRIVERS.BASIC_LICENSE).map(
                ([key, obj], index) => (
                  <React.Fragment key={index}>
                    <CustomRadioButton
                      {...props.register('license.basicLicense')}
                      id={key}
                      value={obj.value}
                      type="radio"
                    />
                    <label htmlFor={key}>{obj.label}</label>
                  </React.Fragment>
                )
              )}
            </CustomRadioGroup>
          </Box>
          <FlexBox
            alignItems="center"
            justifyContent="flex-start"
            flexWrap="wrap"
          >
            <CustomDateSelect
              formKeys={{
                year: 'license.basicLicenseYear',
                month: 'license.basicLicenseMonth',
                day: 'license.basicLicenseDay',
              }}
              control={props.control}
              defaultToday={false}
              defaultValue={{ year: 1980 }}
            />
            <ClearButton
              onClick={(e) => handleClearDateButton(e, 'basicLicense')}
              sx={{ [theme.breakpoints.down('sm')]: { display: 'none' } }}
            >
              <CancelIcon />
            </ClearButton>
            <Button onClick={(e) => handleClearDateButton(e, 'basicLicense')}>
              クリア
            </Button>
          </FlexBox>
        </CustomLabel>
        <CustomLabel>
          {DRIVERS.LABELS.TOP_LICENSE}
          <Box sx={{ marginBottom: '20px' }}>
            <Controller
              name={`license.topLicense`}
              control={props.control}
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  options={TOP_LICENSE_TYPE}
                  value={TOP_LICENSE_TYPE.find((c) => c.value === value)}
                  onChange={(option: ReactSelectOptionProps) =>
                    onChange(option.value)
                  }
                  placeholder="選択してください"
                />
              )}
            />
          </Box>
          <FlexBox
            alignItems="center"
            justifyContent="flex-start"
            flexWrap="wrap"
          >
            <CustomDateSelect
              formKeys={{
                year: 'license.topLicenseYear',
                month: 'license.topLicenseMonth',
                day: 'license.topLicenseDay',
              }}
              control={props.control}
              defaultToday={false}
              defaultValue={{ year: 1980 }}
            />
            <ClearButton
              onClick={(e) => handleClearDateButton(e, 'topLicense')}
              sx={{ [theme.breakpoints.down('sm')]: { display: 'none' } }}
            >
              <CancelIcon />
            </ClearButton>
            <Button onClick={(e) => handleClearDateButton(e, 'topLicense')}>
              クリア
            </Button>
          </FlexBox>
        </CustomLabel>
      </BackGroundPalePink>

      <CustomLabel>
        {LICENSES.LABELS.FILES}
        <Controller
          render={() => (
            <CustomDropZone
              multiple={true}
              onDrop={onDrop}
              accept={'image/*, application/pdf'}
            />
          )}
          name="license.files"
          control={control}
        />
        {Object.values(licenseImages).map((image, index) => (
          <FlexBox flexDirection={'row'} key={index}>
            <p>
              <a
                href={URL.createObjectURL(image)}
                download={decodeURI(image.name)}
              >
                {decodeURI(image.name)}
              </a>
            </p>
            <IconButton
              component="span"
              onClick={() =>
                setLicenseImages(licenseImages.filter((_, i) => i !== index))
              }
            >
              <CancelIcon />
            </IconButton>
          </FlexBox>
        ))}
      </CustomLabel>

      <CustomLabel>
        {LICENSES.LABELS.MEMO}
        <CustomTextArea rows={3} {...register('license.memo')} />
      </CustomLabel>
    </BackGroundWhite>
  )
}
