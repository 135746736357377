import * as React from 'react'
import { NOTIFICATIONS } from 'commons/constants'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  BoxBorderBottom,
  FlexBox,
  MarginBox,
} from 'components/atoms/BoxComponents'
import { Typography } from '@mui/material'
import { TextBold } from 'components/atoms/TextComponents'
import { Link } from 'react-router-dom'
import { UserContext } from 'providers/UserProvider'
type Props = {
  path: string | undefined
  text: string
}

const LinkBoxStyle = {
  textDecoration: 'none',
}

function LinkBox(props: Props) {
  const { path, text } = props
  return (
    <BoxBorderBottom>
      <Link to={path} style={LinkBoxStyle}>
        <Typography>{text}</Typography>
      </Link>
    </BoxBorderBottom>
  )
}

export default function NotificationDetail(props: { data: Notification }) {
  const { data } = props
  const user = React.useContext(UserContext)

  return (
    <BackGroundWhite>
      <FlexBox flexDirection={'column'}>
        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{NOTIFICATIONS.LABELS.NOTIFICATION_TYPE}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.notificationType}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{NOTIFICATIONS.LABELS.DATE}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.date}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        {user.isXmileAdmin ||
          (user.isGroupParentCompanyAdmin && (
            <MarginBox sx={{ margin: '1rem' }}>
              <TextBold>{NOTIFICATIONS.LABELS.COMPANY}</TextBold>
              <BoxBorderBottom>
                <Typography>{data.companyName}</Typography>
              </BoxBorderBottom>
            </MarginBox>
          ))}

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{NOTIFICATIONS.LABELS.OFFICE}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.officeName}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{NOTIFICATIONS.LABELS.NAME}</TextBold>
          <LinkBox path={data.path} text={data.name} />
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{NOTIFICATIONS.LABELS.CONTENT}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.content}</Typography>
          </BoxBorderBottom>
        </MarginBox>
      </FlexBox>
    </BackGroundWhite>
  )
}
