import * as React from 'react'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

export function CircularProgressBox() {
  return (
    <Box sx={{ display: 'flex' }}>
      <div style={{ flexGrow: 1 }}></div>
      <CircularProgress />
    </Box>
  )
}
