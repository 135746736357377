import * as React from 'react'
import { toastOnError } from 'commons/toaster'
import { CustomInput } from 'components/atoms/FormComponents'

type Props = {
  field: any
  placeholder: string
  maxLength: number
}

const TextLimitFormat = (props: Props) => {
  const [toastActive, setToastActive] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')

  const displayDialog = (e, maxLength) => {
    if (e.target.value.length > maxLength) {
      setInputValue(e.target.value.slice(0, maxLength))
      if (!toastActive) {
        toastOnError(
          `${maxLength}字まで入力可能です`,
          () => setToastActive(true),
          () => setToastActive(false)
        )
      }
    } else {
      setInputValue(e.target.value)
    }
  }

  return (
    <CustomInput
      {...props.field}
      placeholder={props.placeholder}
      value={inputValue}
      onInput={(e) => displayDialog(e, props.maxLength)}
    />
  )
}

export default TextLimitFormat
