import * as React from 'react'
import {
  CustomLabel,
  CustomInputShort,
  CustomDateSelect,
  CustomTextArea,
  CustomInput,
  WithDescriptionTooltipIcon,
  CustomDropZone,
} from 'components/atoms/FormComponents'
import {
  Control,
  FieldErrors,
  UseFormRegister,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { BackGroundPalePink } from 'components/atoms/BackGroundComponents'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { styled } from '@mui/styles'
import { FlexBox } from 'components/atoms/BoxComponents'
import { VEHICLES, TOOLTIP_TEXTS } from 'commons/constants'
import { theme } from 'components/theme'
import NumberFormat from 'components/organisms/NumberFormat'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

type InspectionAnnuallyCardProps = {
  register: UseFormRegister<Vehicle>
  control: Control<Vehicle>
  setValue: UseFormSetValue<Vehicle>
  watch: UseFormWatch<Vehicle>
  errors?: FieldErrors<Vehicle>
  appendEmptyItem: () => void
  deleteItem: (index: number) => void
  index: number
}

const InspectionAnnuallyCard: React.FC<InspectionAnnuallyCardProps> = (
  props
) => {
  const { register, control, setValue, watch, index } = props
  const CardTitle = styled('p')({
    fontWeight: 'bold',
  })
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const old_files = watch(`inspectionsAnnually.${index}.files`)
        ? watch(`inspectionsAnnually.${index}.files`)
        : []
      setValue(`inspectionsAnnually.${index}.files`, [
        ...old_files,
        ...acceptedFiles,
      ])
    },
    [watch(`inspectionsAnnually.${index}.files`)]
  )

  return (
    <BackGroundPalePink sx={{ marginBottom: '30px' }}>
      <CardTitle>
        {VEHICLES.LABELS.INSPECTIONS_ANNUALLY}
        {index + 1}
      </CardTitle>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.INSPECTIONS_INSPECT_DATE}
        <CustomDateSelect
          formKeys={{
            year: `inspectionsAnnually.${index}.inspectYear`,
            month: `inspectionsAnnually.${index}.inspectMonth`,
            day: `inspectionsAnnually.${index}.inspectDay`,
          }}
          control={control}
        />
      </CustomLabel>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.INSPECTIONS_TOTAL_FEE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`inspectionsAnnually.${index}.totalFee`}
            control={control}
          />
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.INSPECTIONS_PARTS_FEE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`inspectionsAnnually.${index}.partsFee`}
            control={control}
          />
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.INSPECTIONS_TECHNICAL_FEE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`inspectionsAnnually.${index}.technicalFee`}
            control={control}
          />
        </CustomLabel>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.INSPECTIONS_COMPANY_NAME}
          <CustomInput
            {...register(`inspectionsAnnually.${index}.companyName`)}
          />
        </CustomLabel>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {VEHICLES.LABELS.INSPECTIONS_MILEAGE}
          <Controller
            render={({ field }) => (
              <NumberFormat {...field} customInput={CustomInputShort} />
            )}
            name={`inspectionsAnnually.${index}.mileage`}
            control={control}
          />
        </CustomLabel>
      </FlexBox>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.INSPECTIONS_NEXT_DATE}
        <FlexBox
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <CustomDateSelect
            formKeys={{
              year: `inspectionsAnnually.${index}.nextYear`,
              month: `inspectionsAnnually.${index}.nextMonth`,
              day: `inspectionsAnnually.${index}.nextDay`,
            }}
            control={control}
          />
          <WithDescriptionTooltipIcon
            text={`${TOOLTIP_TEXTS.NOTIFICATION_AUTO_CREATE}${TOOLTIP_TEXTS.VEHICLE_NOTIFICATION_TARGET_USER}`}
          ></WithDescriptionTooltipIcon>
        </FlexBox>
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.INSPECTIONS_MEMO}
        <CustomTextArea {...register(`inspectionsAnnually.${index}.memo`)} />
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VEHICLES.LABELS.FILE_UPLOAD}(
        {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
        <Controller
          render={() => (
            <CustomDropZone
              multiple={true}
              onDrop={onDrop}
              accept={'image/*, application/pdf'}
            />
          )}
          name={`inspectionsAnnually.${index}.fileUrls`}
          control={control}
        />
        {watch(`inspectionsAnnually.${index}.files`) &&
          Object.values(watch(`inspectionsAnnually.${index}.files`))?.map(
            (image: File, fileIndex: number) => (
              <FlexBox flexDirection={'row'} key={fileIndex}>
                <p>
                  <a
                    href={URL.createObjectURL(image)}
                    download={decodeURI(image.name)}
                  >
                    {decodeURI(image.name)}
                  </a>
                </p>
                <IconButton
                  component="span"
                  onClick={() =>
                    setValue(
                      `inspectionsAnnually.${index}.files`,
                      watch(`inspectionsAnnually.${index}.files`).filter(
                        (_, i) => i !== fileIndex
                      )
                    )
                  }
                >
                  <CancelIcon />
                </IconButton>
              </FlexBox>
            )
          )}
      </CustomLabel>
      <DeleteButton onClick={() => props.deleteItem(index)}>削除</DeleteButton>
    </BackGroundPalePink>
  )
}

export default InspectionAnnuallyCard
