import * as React from 'react'
import { ADMIN_MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { getEducationAnnualPlan } from 'components/apis/educationAnnualPlans'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import EducationAnnualPlanDetail from 'components/organisms/admin/education_annual_plans/Detail'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { FlexBox } from 'components/atoms/BoxComponents'
import { EditButton } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import { useTheme } from '@mui/material'

function AdminEducationAnnualPlansShow() {
  const { id } = useParams()
  const theme = useTheme()
  const navigate = useNavigate()

  const { data, isLoading } = useQuery(['educationAnnualPlan'], () =>
    getEducationAnnualPlan(parseInt(id)).then((res) => res.data)
  )

  if (isLoading) {
    return <CircularProgressBox />
  }

  return (
    <PageWrapper>
      <Helmet>
        <title>
          {PAGE_TITLES.EDUCATIONS.ANNUAL_PLANS.SHOW ?? APPLICATION_NAME}
        </title>
      </Helmet>
      <FlexBox
        justifyContent={'space-between'}
        sx={{
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
          alignItems: 'center',
        }}
      >
        <h1>{ADMIN_MENUS.educations.children.education_annual_plans.title}</h1>
        <FlexBox
          gap={2}
          sx={{
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
            alignItems: 'center',
          }}
        >
          <ApplyButton
            onClick={() =>
              window.open(
                `/education_annual_plans/${data.id}/show.pdf`,
                '_blank'
              )
            }
          >
            PDF出力
          </ApplyButton>
          <EditButton
            onClick={() => {
              navigate('edit/')
            }}
          >
            編集する
          </EditButton>
        </FlexBox>
      </FlexBox>
      <EducationAnnualPlanDetail data={data} />
    </PageWrapper>
  )
}

export default AdminEducationAnnualPlansShow
