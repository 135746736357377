import * as React from 'react'
import { Carousel } from 'react-responsive-carousel/lib/js'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

export default function CarouselComponent(props: {
  images?: File[]
  imagesV2?: AttachedFileType[]
  urls?: string[]
}) {
  const { images = [], urls = [], imagesV2 = [] } = props
  const urlList = []
  Object.values(images).map(
    (image) =>
      !image.name.endsWith('.pdf') && urlList.push(URL.createObjectURL(image))
  )
  Object.values(urls).map((url) => !url.endsWith('.pdf') && urlList.push(url))
  Object.values(imagesV2).map(
    (image) => !image.filename.endsWith('.pdf') && urlList.push(image.url)
  )

  return (
    <div className="carousel-wrapper">
      <Carousel showArrows autoPlay swipeable infiniteLoop>
        {Object.values(urlList).map((url, index) => {
          return <img key={index} src={url} />
        })}
      </Carousel>
    </div>
  )
}
