import { AxiosResponse } from 'axios'
import axios from 'commons/axiosClient'
import { API_ENDPOINTS, ROUTES } from 'commons/constants'
import {
  addParamPrefix,
  camelCaseKeysToUnderscore,
  convertValueToInt,
} from 'commons/functions'

export function fetchDrivers(
  searchParams?: SearchDriver,
  paginateParams?: PaginateParams
): Promise<
  AxiosResponse<{
    drivers: Driver[]
    totalCount: number
    resultDriverIds: number[]
  }>
> {
  const customSearchParams = searchParams
    ? addParamPrefix(searchParams, 'search')
    : {}
  const customPaginateParams = paginateParams
    ? addParamPrefix(paginateParams, 'pagination')
    : {}
  const params = camelCaseKeysToUnderscore({
    ...customSearchParams,
    ...customPaginateParams,
  })
  return axios.get(API_ENDPOINTS.DRIVERS, {
    params: params,
  })
}

export function fetchDriverList(
  excludeRemovedDriver?: boolean,
  searchParams?: { companyId?: number; officeId?: number }
): Promise<
  AxiosResponse<{ drivers: DriverSelectOption[]; totalCount: number }>
> {
  const customSearchParams = searchParams
    ? addParamPrefix(searchParams, 'search')
    : {}
  const params = camelCaseKeysToUnderscore({
    excludeRemovedDriver: excludeRemovedDriver,
    ...customSearchParams,
  })

  return axios.get(API_ENDPOINTS.DRIVERS_LIST, {
    params: params,
  })
}

export function fetchDriverSortList(
  excludeRemovedDriver?: boolean,
  searchParams?: { companyId?: number; officeId?: number }
): Promise<
  AxiosResponse<{ drivers: DriverGroupSelectOption[]; totalCount: number }>
> {
  const customSearchParams = searchParams
    ? addParamPrefix(searchParams, 'search')
    : {}
  const params = camelCaseKeysToUnderscore({
    excludeRemovedDriver: excludeRemovedDriver,
    ...customSearchParams,
  })

  return axios.get(API_ENDPOINTS.DRIVERS_SORT_LIST, {
    params: params,
  })
}

export function fetchDriversRoleList(): Promise<AxiosResponse<Role[]>> {
  return axios.get(API_ENDPOINTS.DRIVERS_ROLE_LIST)
}

export function getDriver(id: number): Promise<AxiosResponse<Driver>> {
  return axios.get([API_ENDPOINTS.DRIVERS, id].join(''))
}

export function deleteDriver(id: number) {
  return axios.delete([API_ENDPOINTS.DRIVERS, id].join(''))
}

function concatParams(array: Array<string>) {
  return array.filter(Boolean).join('-')
}

export function updateDriver(params: {
  data: Driver
  formData: FormData
}): Promise<AxiosResponse<Driver>> {
  const snakeCaseData = camelCaseKeysToUnderscore(
    convertValueToInt(params.data)
  )

  if (snakeCaseData.profile) {
    snakeCaseData.profile.phone_number = concatParams([
      snakeCaseData.profile.phone_number_first,
      snakeCaseData.profile.phone_number_second,
      snakeCaseData.profile.phone_number_third,
    ])
    snakeCaseData.profile.personal_phone_number1 = concatParams([
      snakeCaseData.profile.personal_phone_number1_first,
      snakeCaseData.profile.personal_phone_number1_second,
      snakeCaseData.profile.personal_phone_number1_third,
    ])
    snakeCaseData.profile.personal_phone_number2 = concatParams([
      snakeCaseData.profile.personal_phone_number2_first,
      snakeCaseData.profile.personal_phone_number2_second,
      snakeCaseData.profile.personal_phone_number2_third,
    ])
    snakeCaseData.profile.ledger_created_date = concatParams([
      snakeCaseData.profile.ledger_created_year,
      snakeCaseData.profile.ledger_created_month,
      snakeCaseData.profile.ledger_created_day,
    ])
    snakeCaseData.profile.birthday = concatParams([
      snakeCaseData.profile.birthed_year,
      snakeCaseData.profile.birthed_month,
      snakeCaseData.profile.birthed_day,
    ])
  }
  if (snakeCaseData.family_profiles) {
    snakeCaseData.family_profiles.map((family_profile, index) => {
      snakeCaseData.family_profiles[index].birthday = concatParams([
        family_profile.birthed_year,
        family_profile.birthed_month,
        family_profile.birthed_day,
      ])
    })
  }
  if (snakeCaseData.careers) {
    snakeCaseData.careers.map((career, index) => {
      snakeCaseData.careers[index].recorded_date = concatParams([
        career.recorded_year,
        career.recorded_month,
        career.recorded_day,
      ])
    })
  }
  if (snakeCaseData.license) {
    snakeCaseData.license.issuanced_date = concatParams([
      snakeCaseData.license.issuanced_year,
      snakeCaseData.license.issuanced_month,
      snakeCaseData.license.issuanced_day,
    ])
    snakeCaseData.license.expired_date = concatParams([
      snakeCaseData.license.expired_year,
      snakeCaseData.license.expired_month,
      snakeCaseData.license.expired_day,
    ])
    snakeCaseData.license.basic_license_date = concatParams([
      snakeCaseData.license.basic_license_year,
      snakeCaseData.license.basic_license_month,
      snakeCaseData.license.basic_license_day,
    ])
    snakeCaseData.license.top_license_date = concatParams([
      snakeCaseData.license.top_license_year,
      snakeCaseData.license.top_license_month,
      snakeCaseData.license.top_license_day,
    ])
  }
  if (snakeCaseData.personal_histories) {
    snakeCaseData.personal_histories.map((personal_history, index) => {
      snakeCaseData.personal_histories[index].admission_date = concatParams([
        personal_history.admission_year,
        personal_history.admission_month,
        '1',
      ])
      snakeCaseData.personal_histories[index].retirement_date = concatParams([
        personal_history.retirement_year,
        personal_history.retirement_month,
        '1',
      ])
    })
  }
  for (const key in snakeCaseData) {
    if (snakeCaseData[key] && snakeCaseData[key].length != 0) {
      params.formData.append(key, JSON.stringify(snakeCaseData[key]))
    }
  }
  const headers = { 'Content-Type': 'multipart/form-data' }
  return axios.post(ROUTES.USERS_UPDATE_ATTRIBUTES, params.formData, {
    headers: headers,
  })
}

export function importDriver(formData) {
  const headers = { 'Content-Type': 'multipart/form-data' }
  return axios.post(API_ENDPOINTS.DRIVERS_CSV_IMPORT, formData, {
    headers: headers,
  })
}

export function isLoginIdDuplicate(params) {
  return axios.post(
    API_ENDPOINTS.DRIVERS_LOGIN_ID_DUPLICATE,
    camelCaseKeysToUnderscore(params)
  )
}

export function checkSubscribed(feature: string) {
  const params = { feature: feature }
  return axios.get(API_ENDPOINTS.DRIVERS_SUBSCRIBED_FEATURE, {
    params: params,
  })
}

export function fetchLockedDriversList(): Promise<
  AxiosResponse<{ drivers: Driver[] }>
> {
  return axios.get(API_ENDPOINTS.DRIVERS_LOCKED_DRIVERS_LIST)
}

export function unlockDriver(id: number) {
  return axios.post([API_ENDPOINTS.DRIVERS, id, '/unlock'].join(''))
}

export function fetchChargedVehicle(id: number) {
  const params = { id: id }
  return axios.get(API_ENDPOINTS.DRIVERS_CHARGED_VEHICLE, {
    params: params,
  })
}

export function exportDriversCsv(searchParams?: SearchDriver) {
  const params = camelCaseKeysToUnderscore(
    addParamPrefix(searchParams, 'search')
  )
  return axios.get(API_ENDPOINTS.DRIVERS_CSV_EXPORT, {
    params: params,
  })
}
