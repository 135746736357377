import * as React from 'react'
import BootstrapDialog from 'components/atoms/BootstrapDialog'
import { UserContext } from 'providers/UserProvider'
import { ApplyButton, ExportButton } from 'components/atoms/ButtonComponenets'
import { exportVehiclesCsv } from 'components/apis/vehicles'
import { toastOnErrorUseComponent } from 'commons/toaster'
import { useErrorHandler } from 'react-error-boundary'
import { Link } from 'react-router-dom'
import { FlexBoxColumn } from 'components/atoms/BoxComponents'
import VehiclePaymentHistoryCsvModal from 'components/organisms/vehicles/paymentHistoryCsvModal'
import { Box } from '@mui/material'
import { ALERT_TEXT } from 'commons/constants'

type Props = {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  vehicleIds: number[]
  searchParams: SearchVehicle
}

const ToCsvSettingImport = () => (
  <div>
    CSVの紐付けが登録されていません。
    <br />
    <Link to="/admin/csv_settings/vehicle_import">
      マスタ管理→CSV設定→ヘッダーの設定
    </Link>
    から登録してください。
  </div>
)

const downloadFile = (filename: string, data: BlobPart) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  window.URL.revokeObjectURL(url)
}

function ExportCsvModal({
  isModalOpen,
  setIsModalOpen,
  vehicleIds,
  searchParams,
}: Props) {
  const user = React.useContext(UserContext)
  const handleError = useErrorHandler()
  const [showMaintenanceCsvModal, setShowMaintenanceCsvModal] =
    React.useState(false)

  const exportCsv = () => {
    setIsModalOpen(false)

    exportVehiclesCsv(searchParams)
      .then((response) => {
        const filename = 'vehicles.csv'
        downloadFile(filename, response.data)
      })
      .catch((e) => {
        switch (e.response.status) {
          case 404:
            toastOnErrorUseComponent(ToCsvSettingImport())
            break
          default:
            handleError(e.response.status)
        }
      })
  }

  const isEmptySearchParams = (searchParams: SearchVehicle) => {
    // XMileシステム管理者、グループ親会社システム管理者以外のユーザーは検索条件に"会社"が表示されないため、会社以外の検索条件が未指定かどうかを判定する
    const searchParamsCopy =
      user.isXmileAdmin || user.isGroupParentCompanyAdmin
        ? searchParams
        : searchParamsRemovedCompanyId(searchParams)
    const selectedParams = Object.values(searchParamsCopy).filter(function (x) {
      return x !== '' && x !== undefined && x !== null
    })
    if (selectedParams.length == 0) {
      return true
    }
    return false
  }

  const searchParamsRemovedCompanyId = (searchParams: SearchVehicle) => {
    const { companyId, ...searchParamsWithoutCompanyId } = searchParams
    return searchParamsWithoutCompanyId
  }

  const openVehiclePaymentHistoryCsvModal = () => {
    setIsModalOpen(false)
    setShowMaintenanceCsvModal(true)
  }

  return (
    <>
      {isModalOpen && (
        <BootstrapDialog
          title={'CSV出力'}
          changeDialogStatus={() => setIsModalOpen(false)}
        >
          <FlexBoxColumn gap={2}>
            {user.subscribedCsvSettings && (
              <Box mx={'auto'}>
                <ExportButton
                  onClick={exportCsv}
                  modalText={
                    isEmptySearchParams(searchParams)
                      ? `${ALERT_TEXT.LONG_TIME_EXPORT}${ALERT_TEXT.ALL_DATA_EXPORT}`
                      : ALERT_TEXT.LONG_TIME_EXPORT
                  }
                >
                  車両データ出力
                </ExportButton>
              </Box>
            )}

            <Box mx={'auto'}>
              <ApplyButton onClick={() => openVehiclePaymentHistoryCsvModal()}>
                支払履歴出力
              </ApplyButton>
            </Box>
          </FlexBoxColumn>
        </BootstrapDialog>
      )}
      <VehiclePaymentHistoryCsvModal
        showModal={showMaintenanceCsvModal}
        setShowModal={setShowMaintenanceCsvModal}
        vehicleIds={vehicleIds}
      />
    </>
  )
}

export default ExportCsvModal
