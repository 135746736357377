import { useQuery } from '@tanstack/react-query'
import { createQueryKey } from 'commons/functions'
import { fetchAdminEducationAttendances } from 'components/apis/educationAttendances'
import { useErrorHandler } from 'react-error-boundary'

export default function useEducationAttendances(
  params: UserEducation,
  searchParams: SearchUserEducation,
  paginateParams: PaginateParams
) {
  const handleError = useErrorHandler()
  const queryKey = createQueryKey('education_attendances', {
    ...params,
    ...searchParams,
    ...paginateParams,
  })

  const {
    data: educationAttendancesData,
    isLoading: isLoadingEducationAttendancesData,
  } = useQuery({
    queryKey,
    queryFn: async () => {
      const educationAttendanceListResponse =
        await fetchAdminEducationAttendances(
          params,
          searchParams,
          paginateParams
        )

      return { educationAttendanceList: educationAttendanceListResponse.data }
    },
    onError(error) {
      handleError(error)
    },
  })

  return {
    educationAttendanceList: educationAttendancesData?.educationAttendanceList,
    isLoadingEducationAttendancesData,
  }
}
