import * as React from 'react'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { styled } from '@mui/material'
import { palette, theme } from 'components/theme'
import { VEHICLES } from 'commons/constants'

type LicenseProps = {
  data: string[]
}

export default function License(props: LicenseProps) {
  const Title = styled('h2')({
    fontSize: '20px',
    margin: 0,
  })
  const Text = styled('p')({
    fontSize: '16px',
    margin: 0,
  })
  const TextWrapper = styled('div')({
    width: '300px',
    marginTop: '48px',
    paddingBottom: '8px',
    paddingLeft: '4px',
    borderBottom: `1px solid ${palette.border.pale}`,
    overflowWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  })

  return (
    <BackGroundWhite>
      <Title>{VEHICLES.LABELS.EQUIPMENT}</Title>
      <TextWrapper>
        <Text>{props.data?.join('/')}</Text>
      </TextWrapper>
    </BackGroundWhite>
  )
}
