import * as React from 'react'
import { palette } from 'components/theme'
import { Link, useNavigate } from 'react-router-dom'
import CommonAccountModal from 'components/organisms/educations/CommonAccountModal'

type ItemLinkProps = {
  education: Education
  user: User
  isValid: boolean
}

function EducationItemLink({ education, user, isValid }: ItemLinkProps) {
  const navigate = useNavigate()
  const [isCommonAccountModalOpen, setIsCommonAccountModalOpen] =
    React.useState<boolean>(false)

  function onComplete() {
    navigate(`./${education.id}`)
  }

  function onClick(e) {
    e.preventDefault()
    if (user.isDriverCommonAccount) {
      setIsCommonAccountModalOpen(true)
    } else {
      onComplete()
    }
  }

  return (
    <>
      <CommonAccountModal
        isModalOpen={isCommonAccountModalOpen}
        setIsModalOpen={setIsCommonAccountModalOpen}
        onComplete={onComplete}
      />
      <Link
        to={String(education.id)}
        onClick={onClick}
        style={{
          pointerEvents: isValid ? 'auto' : 'none',
          color: isValid ? '' : palette.text.disabled.main,
        }}
      >
        {education.title}
      </Link>
    </>
  )
}

export default EducationItemLink
