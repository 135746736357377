import * as React from 'react'
import { UserContext } from 'providers/UserProvider'
import Forbidden from 'components/pages/errors/Forbidden'

// 経営者向けメニューの利用権限を制御するコンポーネント
function ExecutiveSystemAdminRoute(props: { component: React.ReactNode }) {
  const user = React.useContext(UserContext)
  if (user.isExecutive || user.isSystemAdmin) {
    return <>{props.component}</>
  }

  return <Forbidden />
}

export default ExecutiveSystemAdminRoute
