import * as React from 'react'
import CustomCenteringModal from 'components/atoms/CustomCenteringModal'
import TmpAccountSelectContainer from './common_account/TmpAccountSelectContainer'

const CommonAccountModalStyle = {
  margin: 'auto',
  width: 'fit-content',
  display: 'flex',
  alignItems: 'start',
  height: '470px',
  justifyContent: 'start',
}

type Props = {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  onComplete: ({ id }: { id: string }) => void
  onClose?: () => boolean
}
function CommonAccountModal({
  isModalOpen,
  setIsModalOpen,
  onComplete,
  onClose = () => true,
}: Props) {
  return (
    <CustomCenteringModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      customClose={onClose}
      style={CommonAccountModalStyle}
    >
      <TmpAccountSelectContainer onComplete={onComplete} />
    </CustomCenteringModal>
  )
}

export default CommonAccountModal
