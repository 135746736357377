import * as React from 'react'
import { ADMIN_MENUS } from 'commons/menus'
import DriversForm from 'components/organisms/admin/drivers/Form'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { fetchDriversRoleList, getDriver } from 'components/apis/drivers'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { useParams } from 'react-router-dom'
import { fetchAllOffices, fetchAllCompanies } from 'components/apis/companies'
import { UserContext } from 'providers/UserProvider'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import SidebarAnchorList from 'components/organisms/layouts/SidebarAnchorList'
import { FlexBox } from 'components/atoms/BoxComponents'
import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import { AxiosError } from 'axios'
import { useErrorHandler } from 'react-error-boundary'

function AdminDriversEdit() {
  const { id } = useParams()
  const user: User = React.useContext(UserContext)
  const handleError = useErrorHandler()

  const { data: driver, isLoading: isDriverLoading } = useQuery(
    [`admin/drivers/${id}/edit`, 'driver'],
    () => getDriver(parseInt(id)).then((res) => res.data),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      refetchOnWindowFocus: false,
    }
  )

  const { data: companies, isLoading: isCompaniesLoading } = useQuery(
    [`admin/drivers/${id}/edit`, 'companies'],
    () => fetchAllCompanies().then((res) => res.data.companies),
    // Xmile admin userのみfetch
    {
      enabled: user.isXmileAdmin || user.isGroupParentCompanyAdmin,
      onError: (e: AxiosError) => handleError(e.response.status),
      initialData: [],
      refetchOnWindowFocus: false,
    }
  )

  const { data: offices, isLoading: isOfficesLoading } = useQuery(
    [`admin/drivers/${id}/edit`, 'offices'],
    () => fetchAllOffices().then((res) => res.data.offices),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      refetchOnWindowFocus: false,
    }
  )

  const { data: driversRole, isLoading: isDriversRoleLoading } = useQuery(
    [`admin/drivers/${id}/edit`, 'roles'],
    () => fetchDriversRoleList().then((res) => res.data),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      refetchOnWindowFocus: false,
    }
  )

  return (
    <FlexBox>
      <SidebarAnchorList page="drivers" />
      <PageWrapper>
        <Helmet>
          <title>{PAGE_TITLES.DRIVERS.EDIT ?? APPLICATION_NAME}</title>
        </Helmet>
        <h1>{ADMIN_MENUS.masters.children.drivers.title}</h1>
        {isDriverLoading ||
        isCompaniesLoading ||
        isOfficesLoading ||
        isDriversRoleLoading ? (
          <CircularProgressBox />
        ) : (
          <DriversForm
            type="update"
            data={driver}
            companies={companies}
            offices={offices}
            roles={driversRole}
            isAdminUser={user.admin}
            isXmileSystemAdminUser={user.isXmileAdmin}
          />
        )}
      </PageWrapper>
    </FlexBox>
  )
}

export default AdminDriversEdit
