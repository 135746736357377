import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const AccidentIcon = () => {
  return (
    <SvgIcon>
      <path
        d="M13.4062 2C13.4062 2 14.1 4.48438 14.1 6.5C14.1 8.43125 12.8344 9.99688 10.9031 9.99688C8.9625 9.99688 7.5 8.43125 7.5 6.5L7.52813 6.1625C5.63438 8.4125 4.5 11.3281 4.5 14.4969C4.5 18.6406 7.85625 21.9969 12 21.9969C16.1438 21.9969 19.5 18.6406 19.5 14.4969C19.5 9.44375 17.0719 4.93438 13.4062 2ZM11.7281 19.1844C10.0594 19.1844 8.70937 17.8719 8.70937 16.2406C8.70937 14.7219 9.69375 13.6531 11.3438 13.3156C13.0031 12.9781 14.7188 12.1813 15.675 10.8969C16.0406 12.1063 16.2281 13.3813 16.2281 14.6844C16.2281 17.1688 14.2125 19.1844 11.7281 19.1844Z"
        fill="#003333"
      />
    </SvgIcon>
  )
}
