import { styled } from '@mui/styles'
import * as React from 'react'
import Modal from 'react-modal'
import CircularProgress from '@mui/material/CircularProgress'

type ProcessingModalProps = {
  isOpen: boolean
}

const StyledModal = styled(Modal)({
  marginLeft: 0,
  display: 'flex',
  flexFlow: 'wrap',
  flexDirection: 'column',
  justifyContents: 'center',
  alignItems: 'center',
  width: '300px',
  height: '200px',
  background: 'white',
  border: '1px solid rgb(204, 204, 204)',
})
const Inner = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})
const Text = styled('p')({
  margin: '0 0 40px',
})

const ProcessingModal: React.FC<ProcessingModalProps> = (props) => {
  return (
    <StyledModal
      isOpen={props.isOpen}
      style={{
        overlay: {
          minHeight: '100%',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {},
      }}
    >
      <Inner>
        <Text>処理中です</Text>
        <CircularProgress />
      </Inner>
    </StyledModal>
  )
}

export default ProcessingModal
