import * as React from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { ALCOHOL_CHECKS, VALIDATION_MESSAGES } from 'commons/constants'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  ErrorMessage,
  CustomTextArea,
  CustomForm,
  CustomSelect,
  CustomDropZone,
  WithRequiredBadge,
} from 'components/atoms/FormComponents'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { CameraButton } from 'components/atoms/buttons/CameraButton'
import { JapaneseDateFormat } from 'commons/dateFormat'
import { fetchDriverList } from 'components/apis/drivers'
import {
  createAlcoholCheck,
  updateAlcoholCheck,
  getResultRangeOption,
  getLatestUncompletedAlcoholCheck,
} from 'components/apis/alcohol_checks'
import { toastOnError } from 'commons/toaster'
import { useErrorHandler } from 'react-error-boundary'
import { UserContext } from 'providers/UserProvider'
import { useQuery, useMutation } from '@tanstack/react-query'
import { toastOnSuccess } from 'commons/toaster'
import FormSubmitSelectPageModal from 'components/atoms/FormSubmitSelectPageModal'
import ProcessingModal from 'components/atoms/ProcessingModal'
import { AxiosError } from 'axios'
import { ShouldSubmitOnKeyPress } from 'commons/formUtils'
import { useNavigate } from 'react-router-dom'
import { FlexBox } from 'components/atoms/BoxComponents'
import { slackNotification } from 'commons/slackNotification'
import { CameraModal } from './CameraModal'
import { Tabs, Tab } from '@mui/material'
import TabView from 'components/organisms/alcohol_checks/TabView'

export enum TabName {
  BEFORE = 'BeforeWork',
  AFTER = 'AfterWork',
}
const tabNames = Object.values(TabName)

export default function AlcoholChecksForm() {
  const {
    register,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
    trigger,
  } = useForm<AlcoholCheck>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  })
  const handleError = useErrorHandler()

  const [resultPhotoBeforeWork, setResultPhotoBeforeWork] =
    React.useState<File>()
  const [resultPhotoAfterWork, setResultPhotoAfterWork] = React.useState<File>()

  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0)

  const watchUserId = useWatch({ control, name: 'userId' })

  const user = React.useContext(UserContext)
  const [isModalShow, setModalShow] = React.useState(false)
  const [isAvailableCamera, setIsAvailableCamera] = React.useState(false)
  const [resultPhotoCameraModalShow, setResultPhotoCameraModalShow] =
    React.useState(false)
  const [isProcessingModalShow, setIsProcessingModalShow] =
    React.useState(false)
  const navigate = useNavigate()

  // 業前のデータを取得
  const { data: uncompletedAlcoholCheck } = useQuery<AlcoholCheck>(
    [watchUserId],
    async () => {
      if (!watchUserId) return {}

      const res = await getLatestUncompletedAlcoholCheck(watchUserId)
      if (Object.keys(res.data).length > 0) {
        // 業後タブを選択状態にする
        setSelectedTabIndex(1)
        return res.data
      }
      // データがない人の場合は初期状態のタブに戻す
      setSelectedTabIndex(0)
      return {}
    }
  )

  const hasUncompletedAlcoholCheck = () =>
    uncompletedAlcoholCheck && Object.keys(uncompletedAlcoholCheck).length > 0

  const mutation = useMutation({
    mutationFn:
      uncompletedAlcoholCheck && Object.keys(uncompletedAlcoholCheck).length > 0
        ? updateAlcoholCheck
        : createAlcoholCheck,
    onError: (e: AxiosError<{ errors: string[] }>) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors.join(','))
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
  })

  const mutationWithoutNavigation = useMutation({
    mutationFn: hasUncompletedAlcoholCheck()
      ? updateAlcoholCheck
      : createAlcoholCheck,
    onSuccess: (res) => {
      setValue('id', res.data.id)
      toastOnSuccess('保存しました')
    },
    onError: (e: AxiosError<{ errors: string[] }>) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors.join(','))
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
    onSettled: () => {
      setIsProcessingModalShow(false)
    },
  })

  // ドライバー・車両のセレクトボックスのoptionsをAPIから取得
  const { data: drivers } = useQuery(
    [`alcohol_checks/Form`, 'driver_list'],
    () =>
      fetchDriverList(true).then((res) => {
        // "ドライバー 共通アカウント"ユーザー向けの処理
        // "ドライバー 共通アカウント"はセレクトの選択肢には表示しないため、userIdの初期値もundefinedにする
        const currentDriver = res.data.drivers?.find(
          (value: DriverSelectOption) => String(value.id) === String(user.id)
        )
        if (!currentDriver) {
          setValue('userId', undefined)
        }
        return Object.values(res.data.drivers).map(
          (value: DriverSelectOption) => {
            return { value: String(value.id), label: value.fullName }
          }
        )
      })
  )

  // 測定結果セレクト選択肢の設定
  const { data: resultRange } = useQuery([], () =>
    getResultRangeOption().then((res) => res.data.resultRange)
  )

  const ResultPhotoCameraModal = () => (
    <CameraModal
      title={ALCOHOL_CHECKS.LABELS.RESULT_PHOTO}
      isModalOpen={resultPhotoCameraModalShow}
      setIsModalOpen={setResultPhotoCameraModalShow}
      setPhoto={
        tabNames[selectedTabIndex] == TabName.BEFORE
          ? setResultPhotoBeforeWork
          : setResultPhotoAfterWork
      }
      setValue={setValue}
      createdAtKey={`resultPhoto${tabNames[selectedTabIndex]}CreatedAt`}
    />
  )

  // カメラの存在チェック
  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const isCameraAvailable = devices.some(
        (device) => device.kind === 'videoinput'
      )
      setIsAvailableCamera(isCameraAvailable)
    })
  }, [])

  const onDropResultPhoto = React.useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]

      if (tabNames[selectedTabIndex] === TabName.BEFORE) {
        setResultPhotoBeforeWork(file)
      } else {
        setResultPhotoAfterWork(file)
      }
      setValue(
        `resultPhoto${tabNames[selectedTabIndex]}CreatedAt`,
        new Date(file?.lastModified)
      )
    },
    [resultPhotoBeforeWork, resultPhotoAfterWork, selectedTabIndex]
  )

  const formatParamsData = (data) => {
    const formData = new FormData()
    if (resultPhotoBeforeWork)
      formData.append('result_photo_before_work', resultPhotoBeforeWork)
    if (resultPhotoAfterWork)
      formData.append('result_photo_after_work', resultPhotoAfterWork)
    if (hasUncompletedAlcoholCheck()) {
      data.id = uncompletedAlcoholCheck.id
    }

    return { data, formData }
  }

  const submit = async (callback: () => void) => {
    setModalShow(false)
    const hasNotError = await trigger(undefined, { shouldFocus: true })
    if (hasNotError) {
      callback()
    } else {
      toastOnError('入力内容に不備があります')
    }
  }

  const submitCallback = async (to: 'show' | 'index') => {
    setIsProcessingModalShow(true)
    // getValues()の戻り値をそのまま渡すと送信後にフォームの値が元に戻ってしまう不具合が発生した為、deepCopyした値を渡して対処している
    // https://github.com/X-Mile/track-manager/pull/795#discussion_r1034714181
    const raw = structuredClone(getValues())
    const { data, formData } = formatParamsData(raw)
    mutation.mutate(
      { data, formData },
      {
        onSuccess: (res) => {
          scrollTo({ top: 0 })
          if (to === 'show') {
            navigate(`/admin/alcohol_checks/${res.data.id}/`)
          } else if (to === 'index') {
            navigate(`/admin/alcohol_checks?focus_id=${res.data.id}`)
          }
        },
        onSettled: () => {
          setIsProcessingModalShow(false)
        },
      }
    )
  }

  /**
   * keyboardInputを監視し、Enterが押された場合は更新リクエストを叩く(成功時に遷移しない)
   */
  const handleKeyDown = async (e) => {
    if (ShouldSubmitOnKeyPress(e)) {
      const hasNotError = await trigger(undefined, { shouldFocus: true })
      if (hasNotError) {
        setIsProcessingModalShow(true)
        e.preventDefault()
        const copy = structuredClone(getValues())
        const { data, formData } = formatParamsData(copy)
        mutationWithoutNavigation.mutate({ data, formData })
        ;(document.activeElement as HTMLElement)?.blur()
      } else {
        toastOnError('入力内容に不備があります')
      }
    }
  }

  const handleModalCancelSelected = () => {
    setModalShow(false)
  }

  const handleTabChange = (_: React.SyntheticEvent, tabIndex: number) => {
    setSelectedTabIndex(tabIndex)
  }

  const TabForm = () => (
    <>
      <div>
        <CustomLabel>
          <WithRequiredBadge>{ALCOHOL_CHECKS.LABELS.RESULT}</WithRequiredBadge>

          <Controller
            name={`result${tabNames[selectedTabIndex]}`}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                options={resultRange}
                value={resultRange?.find((c) => c.value === Number(value))}
                onChange={(option: ReactSelectOptionProps) =>
                  onChange(option.value)
                }
                placeholder="選択してください"
              />
            )}
          />
          {errors[`result${tabNames[selectedTabIndex]}`] && (
            <ErrorMessage sx={{ marginTop: '0.5rem' }}>
              {VALIDATION_MESSAGES.required}
            </ErrorMessage>
          )}
        </CustomLabel>
      </div>
      <CustomLabel>
        <WithRequiredBadge>
          {ALCOHOL_CHECKS.LABELS.RESULT_PHOTO}
        </WithRequiredBadge>
        <div style={{ marginBottom: '10px' }}>
          <CameraButton
            disabled={!isAvailableCamera}
            onClick={() => {
              setResultPhotoCameraModalShow(true)
            }}
          >
            カメラ起動
          </CameraButton>
        </div>
        <Controller
          render={() => (
            <CustomDropZone
              multiple={false}
              onDrop={onDropResultPhoto}
              accept={'image/*'}
            />
          )}
          name={`resultPhoto${tabNames[selectedTabIndex]}`}
          control={control}
          rules={{
            validate: () =>
              tabNames[selectedTabIndex] === TabName.BEFORE
                ? resultPhotoBeforeWork != undefined
                : resultPhotoAfterWork != undefined,
          }}
        />
        {errors[`resultPhoto${tabNames[selectedTabIndex]}`] && (
          <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
        )}
        {((tabNames[selectedTabIndex] === TabName.BEFORE &&
          resultPhotoBeforeWork) ||
          (tabNames[selectedTabIndex] === TabName.AFTER &&
            resultPhotoAfterWork)) && (
          <>
            撮影日時：
            {JapaneseDateFormat(
              watch(`resultPhoto${tabNames[selectedTabIndex]}CreatedAt`)
            )}
            <img
              src={URL.createObjectURL(
                tabNames[selectedTabIndex] == TabName.BEFORE
                  ? resultPhotoBeforeWork
                  : resultPhotoAfterWork
              )}
              style={{ width: '50%' }}
            />
          </>
        )}
      </CustomLabel>
      <CustomLabel>
        {ALCOHOL_CHECKS.LABELS.MEMO}
        <CustomTextArea
          rows={5}
          {...register(`memo${tabNames[selectedTabIndex]}`)}
        />
        {errors[`memo${tabNames[selectedTabIndex]}`] && (
          <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
        )}
      </CustomLabel>
    </>
  )

  const renderBeforeWork = () => {
    if (uncompletedAlcoholCheck && hasUncompletedAlcoholCheck()) {
      return <TabView data={uncompletedAlcoholCheck} tabName={TabName.BEFORE} />
    }
    return <TabForm />
  }

  const renderAfterWork = () => <TabForm />

  return (
    <>
      <FlexBox justifyContent={'flex-end'} sx={{ marginBottom: '8px' }}>
        <ApplyButton
          pattern="block"
          onClick={(e) => {
            e.preventDefault()
            setModalShow(true)
          }}
        >
          保存する
        </ApplyButton>
      </FlexBox>
      <BackGroundWhite>
        <CustomForm onKeyDown={handleKeyDown}>
          <FlexBox flexWrap="wrap">
            <div>
              <CustomLabel>
                <WithRequiredBadge>
                  {ALCOHOL_CHECKS.LABELS.DRIVER}
                </WithRequiredBadge>
                <Controller
                  name="userId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      options={drivers}
                      value={drivers?.find((c) => c.value === String(value))}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option.value)
                      }
                      placeholder="選択してください"
                    />
                  )}
                />
                {errors.userId && (
                  <ErrorMessage sx={{ marginTop: '0.5rem' }}>
                    {VALIDATION_MESSAGES.required}
                  </ErrorMessage>
                )}
              </CustomLabel>
            </div>
          </FlexBox>

          <Tabs
            value={selectedTabIndex}
            onChange={handleTabChange}
            variant="fullWidth"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#0050B2',
              },
            }}
            sx={{
              borderBottom: '1px solid #0050B2',
              marginBottom: '1.5rem',
              marginTop: '1rem',
            }}
          >
            <Tab label="業前" sx={{ fontWeight: 'bold' }} />
            <Tab
              label="業後"
              disabled={
                uncompletedAlcoholCheck && hasUncompletedAlcoholCheck()
                  ? false
                  : true
              }
              sx={{ fontWeight: 'bold' }}
            />
          </Tabs>
          {selectedTabIndex === 0 && renderBeforeWork()}
          {selectedTabIndex === 1 && renderAfterWork()}
        </CustomForm>
      </BackGroundWhite>
      <FormSubmitSelectPageModal
        isOpen={isModalShow}
        resourceName="アルコールチェック"
        handlers={{
          cancel: handleModalCancelSelected,
          toShow: () => submit(() => submitCallback('show')),
          toIndex: () => submit(() => submitCallback('index')),
        }}
      ></FormSubmitSelectPageModal>
      <ProcessingModal isOpen={isProcessingModalShow} />
      <ResultPhotoCameraModal />
    </>
  )
}
