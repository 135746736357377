import * as React from 'react'
import {
  BackGroundPalePink,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import { Box, styled } from '@mui/material'
import { palette, theme } from 'components/theme'
import { VEHICLES } from 'commons/constants'
import { FlexBox } from 'components/atoms/BoxComponents'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

type InsuranceVoluntaryProps = {
  data: Vehicle
  insurancesVoluntaryImages: File[]
  insuranceVoluntaryChildrenFiles: File[][]
}

export default function InsuranceVoluntary(props: InsuranceVoluntaryProps) {
  const Title = styled('h2')({
    fontSize: '20px',
    margin: 0,
    marginBottom: '16px',
  })
  const SubTitle = styled('p')({
    fontSize: '16px',
    margin: 0,
    color: palette.text.gray.deep,
    fontWeight: 'bold',
  })

  const Text = styled('p')({
    fontSize: '16px',
    margin: 0,
  })
  const Label = styled('p')({
    fontSize: '14px',
    margin: 0,
    color: palette.text.gray.pale,
    marginTop: '32px',
    marginBottom: '8px',
  })
  const TextWrapper = styled('div')({
    width: '300px',
    paddingBottom: '8px',
    paddingLeft: '4px',
    borderBottom: `1px solid ${palette.border.pale}`,
    overflowWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  })
  const TextWrapperFull = styled(TextWrapper)({
    width: '628px',
  })
  const FileTextWrapper = styled(TextWrapper)({
    width: '100%',
    marginTop: '16px',
  })
  const TextWrapperLong = styled(TextWrapper)({
    width: '420px',
  })
  const TextWrapperShort = styled(TextWrapper)({
    width: '140px',
  })

  const formatDate = (year: string, month: string, day: string) => {
    if (year && month && day) return `${year}年${month}月${day}日`
    else return '未入力'
  }
  const formatMoneyText = (money: string | number) => {
    if (!money) return ''
    const parsed = parseInt(money as string)
    if (isNaN(parsed)) {
      return money
    } else {
      return new Intl.NumberFormat('ja-JP', {
        style: 'currency',
        currency: 'JPY',
      }).format(parsed)
    }
  }

  return (
    <BackGroundWhite>
      <Title>{VEHICLES.LABELS.INSURANCE_VOLUNTARY}</Title>
      {props.data.insurancesVoluntary.length == 0 ? (
        <Text>データがありません</Text>
      ) : (
        props.data.insurancesVoluntary.map((insuranceVoluntary, i) => {
          return (
            <Box mb={3} key={`insurance-voluntary-${i}`}>
              <BackGroundPalePink>
                <SubTitle>
                  {VEHICLES.LABELS.INSURANCE_VOLUNTARY}
                  {i + 1}
                </SubTitle>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: { flexDirection: 'column' },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.INSURANCE_VOLUNTARY_STATUS}</Label>
                    <TextWrapperShort>
                      <Text>
                        {insuranceVoluntary.isActive === '1' && '契約中'}
                        {insuranceVoluntary.isActive === '0' && '解約済み'}
                      </Text>
                    </TextWrapperShort>
                  </Box>
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.INSURANCE_VOLUNTARY_TYPE}</Label>
                    <TextWrapperLong>
                      <Text>
                        {insuranceVoluntary.insuranceType || '未入力'}
                      </Text>
                    </TextWrapperLong>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: { flexDirection: 'column' },
                  }}
                >
                  <Box mr={3}>
                    <Label>
                      {VEHICLES.LABELS.INSURANCE_VOLUNTARY_COMPANY_NAME}
                    </Label>
                    <TextWrapperFull>
                      <Text>{insuranceVoluntary.companyName || '未入力'}</Text>
                    </TextWrapperFull>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: { flexDirection: 'column' },
                  }}
                >
                  <Box mr={3}>
                    <Label>
                      {VEHICLES.LABELS.INSURANCE_VOLUNTARY_CONTRACT_NUMBER}
                    </Label>
                    <TextWrapperShort>
                      <Text>
                        {insuranceVoluntary.contractNumber || '未入力'}
                      </Text>
                    </TextWrapperShort>
                  </Box>
                  <Box mr={3}>
                    <Label>
                      {
                        VEHICLES.LABELS
                          .INSURANCE_VOLUNTARY_CONTRACT_BRANCH_NUMBER
                      }
                    </Label>
                    <TextWrapperShort>
                      <Text>
                        {insuranceVoluntary.contractBranchNumber || '未入力'}
                      </Text>
                    </TextWrapperShort>
                  </Box>
                  <Box>
                    <Label>{VEHICLES.LABELS.INSURANCE_VOLUNTARY_COST}</Label>
                    <TextWrapperShort>
                      <Text>
                        {formatMoneyText(insuranceVoluntary.cost) || '未入力'}
                      </Text>
                    </TextWrapperShort>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: { flexDirection: 'column' },
                  }}
                >
                  <Box mr={3}>
                    <Label>
                      {VEHICLES.LABELS.INSURANCE_VOLUNTARY_START_DATE}
                    </Label>
                    <TextWrapper>
                      <Text>
                        {formatDate(
                          insuranceVoluntary.startYear,
                          insuranceVoluntary.startMonth,
                          insuranceVoluntary.startDay
                        )}
                      </Text>
                    </TextWrapper>
                  </Box>
                  <Box>
                    <Label>
                      {VEHICLES.LABELS.INSURANCE_VOLUNTARY_END_DATE}
                    </Label>
                    <TextWrapper>
                      <Text>
                        {formatDate(
                          insuranceVoluntary.endYear,
                          insuranceVoluntary.endMonth,
                          insuranceVoluntary.endDay
                        )}
                      </Text>
                    </TextWrapper>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: { flexDirection: 'column' },
                  }}
                >
                  <Box>
                    <Label>{VEHICLES.LABELS.INSURANCE_VOLUNTARY_MEMO}</Label>
                    <TextWrapperFull>
                      <Text>{insuranceVoluntary.memo || '未入力'}</Text>
                    </TextWrapperFull>
                  </Box>
                </FlexBox>
                <Label>
                  {VEHICLES.LABELS.FILE}(
                  {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
                </Label>
                <FileTextWrapper>
                  {props.insuranceVoluntaryChildrenFiles[i]?.length > 0 ? (
                    props.insuranceVoluntaryChildrenFiles[i].map((file, i) => {
                      return (
                        <FlexBox key={`${file.name}-${i}`} mb={1}>
                          <InsertDriveFileOutlinedIcon fontSize="small" />
                          <Text sx={{ marginLeft: '8px' }}>
                            <a
                              href={URL.createObjectURL(file)}
                              download={decodeURI(file.name)}
                            >
                              {decodeURI(file.name)}
                            </a>
                          </Text>
                        </FlexBox>
                      )
                    })
                  ) : (
                    <Text>ファイルなし</Text>
                  )}
                </FileTextWrapper>
              </BackGroundPalePink>
            </Box>
          )
        })
      )}
      <Label>
        {VEHICLES.LABELS.FILE}({VEHICLES.LABELS.INSURANCE_VOLUNTARY}の
        {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_PARENT})
      </Label>
      <FileTextWrapper>
        {props.insurancesVoluntaryImages.length > 0 ? (
          props.insurancesVoluntaryImages.map((file, i) => {
            return (
              <FlexBox key={`${file.name}-${i}`} mb={1}>
                <InsertDriveFileOutlinedIcon fontSize="small" />
                <Text sx={{ marginLeft: '8px' }}>
                  <a
                    href={URL.createObjectURL(file)}
                    download={decodeURI(file.name)}
                  >
                    {decodeURI(file.name)}
                  </a>
                </Text>
              </FlexBox>
            )
          })
        ) : (
          <Text>ファイルなし</Text>
        )}
      </FileTextWrapper>
    </BackGroundWhite>
  )
}
