import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'

export function fetchContactAddresses() {
  return axios.get(API_ENDPOINTS.CONTACT_ADDRESSES)
}

export function bulkUpdateContactAddresses(params) {
  return axios.post(API_ENDPOINTS.CONTACT_ADDRESSES_BULK_UPDATE, params)
}
