import * as React from 'react'
import { deleteDriver, updateDriver } from 'components/apis/drivers'
import { useNavigate } from 'react-router-dom'
import { toastOnError } from 'commons/toaster'
import { useForm } from 'react-hook-form'
import { CustomForm } from 'components/atoms/FormComponents'
import { fetchS3ImagesV2, fetchS3ImageV2 } from 'commons/fetchS3Images'
import ProfileImage from 'components/organisms/admin/drivers/show/sections/ProfileImage'
import Profile from 'components/organisms/admin/drivers/show/sections/Profile'
import EmergencyContacts from 'components/organisms/admin/drivers/show/sections/EmergencyContacts'
import License from 'components/organisms/admin/drivers/show/sections/License'
import FamilyProiles from 'components/organisms/admin/drivers/show/sections/FamilyProfiles'
import MedicalRecords from 'components/organisms/admin/drivers/show/sections/MedicalRecord'
import Career from 'components/organisms/admin/drivers/show/sections/Career'
import ChargedVehicle from 'components/organisms/admin/drivers/show/sections/ChargedVehicle'
import PersonalHistories from 'components/organisms/admin/drivers/show/sections/PersonalHistories'
import PersonnelChangeHistories from 'components/organisms/admin/drivers/show/sections/PersonnelChangeHistories'
import DrivingExperiences from 'components/organisms/admin/drivers/show/sections/DrivingExperiences'
import AptitudeTestRecords from 'components/organisms/admin/drivers/show/sections/AptitudeTestRecords'
import DirectionRecords from 'components/organisms/admin/drivers/show/sections/DirectionRecords'
import ViolationHistories from 'components/organisms/admin/drivers/show/sections/ViolationHistories'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { useErrorHandler } from 'react-error-boundary'
import Memo from './sections/Memo'
import Insurances from './sections/Insurances'
import { UserContext } from 'providers/UserProvider'
import { styled } from '@mui/styles'
import { DRIVER_LINK_LIST_ITEMS } from 'commons/constants'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { slackNotification } from 'commons/slackNotification'

export default function DriverDetail(props: { data: Driver }) {
  const {
    handleSubmit,
    control,
    register,
    getValues,
    formState: { errors },
  } = useForm<Driver>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: props.data,
  })
  const handleError = useErrorHandler()

  const [profileImage, setProfileImage] = React.useState<File>()
  const [ledgerFiles, setLedgerFiles] = React.useState<File[]>([])
  const [licenseImages, setLicenseImages] = React.useState<File[]>([])
  const [aptitudeTestRecordsFiles, setAptitudeTestRecordsFiles] =
    React.useState<File[]>([])
  const [directionRecordsFiles, setDirectionRecordsFiles] = React.useState<
    File[]
  >([])
  const [medicalRecordsFiles, setMedicalRecordsFiles] = React.useState<File[]>(
    []
  )
  const [medicalRecordChildrenFiles, setMedicalRecordChildrenFiles] =
    React.useState<File[][]>([])
  const [aptitudeTestRecordChildrenFiles, setAptitudeTestRecordChildrenFiles] =
    React.useState<File[][]>([])
  const [directionRecordChildrenFiles, setDirectionRecordChildrenFiles] =
    React.useState<File[][]>([])
  const user = React.useContext(UserContext)

  const [modeMap, setModeMap] = React.useState<DriverFormModeMap>({
    profileImage: 'show',
    profile: 'show',
    emergencyContacts: 'show',
    familyProfiles: 'show',
    insurances: 'show',
    license: 'show',
    medicalRecords: 'show',
    career: 'show',
    chargedVehicles: 'show',
    personalHistories: 'show',
    drivingExperiences: 'show',
    aptitudeTestRecords: 'show',
    directionRecords: 'show',
    violationHistories: 'show',
  })

  const navigate = useNavigate()

  const joinedDate = React.useMemo(() => {
    const joined = props.data.careers.find((c) => {
      return c.category == 'joined'
    })
    if (
      [joined.recordedYear, joined.recordedMonth, joined.recordedDay].includes(
        null
      )
    ) {
      return ''
    }
    return `${joined.recordedYear}年${joined.recordedMonth}月${joined.recordedDay}日`
  }, [props.data.careers])

  const birthDayText = [
    props.data.profile.birthedYear,
    props.data.profile.birthedMonth,
    props.data.profile.birthedDay,
  ].includes(null)
    ? ''
    : `${props.data.profile.birthedYear}年${props.data.profile.birthedMonth}月${props.data.profile.birthedDay}日`

  const handleModeChange = (name: string, mode: FormMode) => {
    const obj = {}
    obj[name] = mode

    setModeMap((prevState) => ({ ...prevState, ...obj }))
  }

  const deleteMutation = useMutation({
    mutationFn: deleteDriver,
    onSuccess: () => navigate('/admin/drivers/'),
    onError: (e: AxiosError<{ errors: string }>) => {
      // 422エラー時にslack通知する
      slackNotification(e, user)
    },
  })

  const updateMutation = useMutation({
    mutationFn: updateDriver,
    onSuccess: () => {
      navigate(0)
    },
    onError: (e: AxiosError) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors.join(','))
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
  })

  const onClickDelete = () => {
    deleteMutation.mutate(props.data.id)
  }

  const onSubmit = (data) => {
    const formData = new FormData()

    data.profile.imageCreatedDate = `${data.profile.imageCreatedYear}-${data.profile.imageCreatedMonth}-${data.profile.imageCreatedDay}`

    if (data.emergencyContacts) {
      data.emergencyContacts.forEach((emergencyContact) => {
        emergencyContact.phoneNumber = `${emergencyContact.phoneNumberFirst}-${emergencyContact.phoneNumberSecond}-${emergencyContact.phoneNumberThird}`
      })
    }

    if (data.medicalRecords) {
      data.medicalRecords.forEach((medicalRecord) => {
        medicalRecord.consultationDate = `${medicalRecord.consultationYear}-${medicalRecord.consultationMonth}-${medicalRecord.consultationDay}`
      })
    }

    if (data.chargedVehicles) {
      data.chargedVehicles.forEach((chargedVehicle) => {
        chargedVehicle.fromDate = `${chargedVehicle.fromYear}-${chargedVehicle.fromMonth}-${chargedVehicle.fromDay}`
        chargedVehicle.toDate = `${chargedVehicle.toYear}-${chargedVehicle.toMonth}-${chargedVehicle.toDay}`
      })
    }

    if (data.aptitudeTestRecords) {
      data.aptitudeTestRecords.forEach((aptitudeTestRecord) => {
        aptitudeTestRecord.consultationDate = `${aptitudeTestRecord.consultationYear}-${aptitudeTestRecord.consultationMonth}-${aptitudeTestRecord.consultationDay}`
      })
    }

    if (data.directionRecords) {
      data.directionRecords.forEach((directionRecord) => {
        directionRecord.consultationDate = `${directionRecord.consultationYear}-${directionRecord.consultationMonth}-${directionRecord.consultationDay}`
      })
    }

    if (data.violationHistories) {
      data.violationHistories.forEach((violationHistory) => {
        violationHistory.violationDate = `${violationHistory.violationYear}-${violationHistory.violationMonth}-${violationHistory.violationDay}`
      })
    }

    if (data.personnelChangeHistories) {
      data.personnelChangeHistories.forEach((personnelChangeHistory) => {
        personnelChangeHistory.appointedDate = `${personnelChangeHistory.appointedYear}-${personnelChangeHistory.appointedMonth}-${personnelChangeHistory.appointedDay}`
      })
    }

    if (profileImage) {
      formData.append('profile_image', profileImage)
    }

    ledgerFiles.forEach((file) => {
      formData.append('ledger_files[]', file)
    })

    licenseImages.forEach((image) => {
      formData.append('license_files[]', image)
    })

    directionRecordsFiles.forEach((image) => {
      formData.append(`direction_records_files[]`, image)
    })

    medicalRecordsFiles.forEach((image) => {
      formData.append(`medical_records_files[]`, image)
    })

    aptitudeTestRecordsFiles.forEach((image) => {
      formData.append(`aptitude_tests_files[]`, image)
    })

    updateMutation.mutate({ data, formData })
  }

  // S3から画像URLを取得してFileオブジェクトに変換
  React.useEffect(() => {
    ;(async () => {
      const profileImage = await fetchS3ImageV2(
        props.data?.profile?.attachedProfileImage
      )
      setProfileImage(profileImage)
    })()
  }, [props.data?.profile?.attachedProfileImage])

  React.useEffect(() => {
    ;(async () => {
      const ledgerImages = await fetchS3ImagesV2(
        props.data?.profile?.attachedLedgerFiles
      )
      setLedgerFiles(ledgerImages)
    })()
  }, [props.data?.profile?.attachedLedgerFiles])

  React.useEffect(() => {
    ;(async () => {
      const licenseImages = await fetchS3ImagesV2(
        props.data?.license?.attachedFiles
      )
      setLicenseImages(licenseImages)
    })()
  }, [props.data?.license?.attachedFiles])

  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3ImagesV2(
        props.data?.attachedMedicalRecordFiles
      )
      setMedicalRecordsFiles(files)
    })()
  }, [props.data?.attachedMedicalRecordFiles])

  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3ImagesV2(
        props.data?.attachedDirectionRecordFiles
      )
      setDirectionRecordsFiles(files)
    })()
  }, [props.data?.attachedDirectionRecordFiles])

  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3ImagesV2(
        props.data?.attachedAptitudeTestRecordFiles
      )
      setAptitudeTestRecordsFiles(files)
    })()
  }, [props.data?.attachedAptitudeTestRecordFiles])

  React.useEffect(() => {
    ;(async () => {
      const medicalRecordChildrenFiles = await Promise.all(
        props.data.medicalRecords.map(async (medicalRecord) => {
          return await fetchS3ImagesV2(medicalRecord.attachedFiles)
        })
      )
      setMedicalRecordChildrenFiles(medicalRecordChildrenFiles)
    })()
  }, [props.data.medicalRecords])

  React.useEffect(() => {
    ;(async () => {
      const aptitudeTestRecordChildrenFiles = await Promise.all(
        props.data.aptitudeTestRecords.map(async (aptitudeTestRecord) => {
          return await fetchS3ImagesV2(aptitudeTestRecord.attachedFiles)
        })
      )
      setAptitudeTestRecordChildrenFiles(aptitudeTestRecordChildrenFiles)
    })()
  }, [props.data.aptitudeTestRecords])

  React.useEffect(() => {
    ;(async () => {
      const directionRecordChildrenFiles = await Promise.all(
        props.data.directionRecords.map(async (directionRecord) => {
          return await fetchS3ImagesV2(directionRecord.attachedFiles)
        })
      )
      setDirectionRecordChildrenFiles(directionRecordChildrenFiles)
    })()
  }, [props.data.directionRecords])

  const Section = styled('section')({
    margin: '1rem',
  })

  return (
    <CustomForm onSubmit={handleSubmit(onSubmit)}>
      <Section id={DRIVER_LINK_LIST_ITEMS.PROFILE_IMAGE.ID}>
        <ProfileImage
          control={control}
          onProfileImageChange={(file) => {
            setProfileImage(file)
          }}
          imageFile={profileImage}
          data={{
            fullName: props.data.profile.fullName,
            roleText: props.data.roleText,
            isExecutive: props.data.isExecutive,
            employeeNumber: props.data.profile.employeeNumber,
            currentCareer: props.data.currentCareer,
            joinedDate: joinedDate,
            companyName: props.data.companyName,
            officeName: props.data.officeName,
            birthday: birthDayText,
          }}
          mode={modeMap.profileImage}
          handleModeChange={handleModeChange}
          isXmileSystemAdminUser={user.isXmileAdmin}
          isGroupParentCompanyAdmin={user.isGroupParentCompanyAdmin}
        />
      </Section>
      <Section id={DRIVER_LINK_LIST_ITEMS.PROFILE.ID}>
        <Profile
          register={register}
          getValues={getValues}
          errors={errors}
          control={control}
          mode={modeMap.profile}
          handleModeChange={handleModeChange}
          ledgerFiles={ledgerFiles}
          setLedgerFiles={setLedgerFiles}
        />
      </Section>
      <Section id={DRIVER_LINK_LIST_ITEMS.EMERGENCY_CONTACTS.ID}>
        <EmergencyContacts
          register={register}
          getValues={getValues}
          errors={errors}
          control={control}
          mode={modeMap.emergencyContacts}
          handleModeChange={handleModeChange}
        />
      </Section>
      <Section id={DRIVER_LINK_LIST_ITEMS.FAMILY_PROFILES.ID}>
        <FamilyProiles
          register={register}
          getValues={getValues}
          errors={errors}
          control={control}
          mode={modeMap.familyProfiles}
          handleModeChange={handleModeChange}
        />
      </Section>
      <Section id={DRIVER_LINK_LIST_ITEMS.INSURANCES.ID}>
        <Insurances
          register={register}
          getValues={getValues}
          errors={errors}
          control={control}
          mode={modeMap.insurances}
          handleModeChange={handleModeChange}
        />
      </Section>
      <Section id={DRIVER_LINK_LIST_ITEMS.CAREERS.ID}>
        <Career
          register={register}
          getValues={getValues}
          errors={errors}
          control={control}
          mode={modeMap.career}
          handleModeChange={handleModeChange}
        />
      </Section>
      <Section id={DRIVER_LINK_LIST_ITEMS.CHARGED_VEHICLES.ID}>
        <ChargedVehicle
          register={register}
          getValues={getValues}
          errors={errors}
          control={control}
          mode={modeMap.chargedVehicles}
          handleModeChange={handleModeChange}
        />
      </Section>
      <Section id={DRIVER_LINK_LIST_ITEMS.LICENSES.ID}>
        <License
          register={register}
          getValues={getValues}
          errors={errors}
          control={control}
          licenseImages={licenseImages}
          setLicenseImages={setLicenseImages}
          mode={modeMap.license}
          handleModeChange={handleModeChange}
        />
      </Section>
      <Section id={DRIVER_LINK_LIST_ITEMS.PERSONAL_HISTORIES.ID}>
        <PersonalHistories
          register={register}
          getValues={getValues}
          errors={errors}
          control={control}
          mode={modeMap.personalHistories}
          handleModeChange={handleModeChange}
        ></PersonalHistories>
      </Section>
      <Section id={DRIVER_LINK_LIST_ITEMS.PERSONNEL_CHANGE_HISTORIES.ID}>
        <PersonnelChangeHistories
          register={register}
          getValues={getValues}
          errors={errors}
          control={control}
          mode={modeMap.personalHistories}
          handleModeChange={handleModeChange}
        ></PersonnelChangeHistories>
      </Section>
      <Section id={DRIVER_LINK_LIST_ITEMS.DRIVING_EXPERIENCES.ID}>
        <DrivingExperiences
          register={register}
          getValues={getValues}
          errors={errors}
          control={control}
          mode={modeMap.drivingExperiences}
          handleModeChange={handleModeChange}
        ></DrivingExperiences>
      </Section>
      <Section id={DRIVER_LINK_LIST_ITEMS.MEDICAL_RECORDS.ID}>
        <MedicalRecords
          register={register}
          getValues={getValues}
          errors={errors}
          control={control}
          mode={modeMap.medicalRecords}
          handleModeChange={handleModeChange}
          medicalRecordsFiles={medicalRecordsFiles}
          medicalRecordChildrenFiles={medicalRecordChildrenFiles}
          setMedicalRecordsFiles={setMedicalRecordsFiles}
        ></MedicalRecords>
      </Section>
      <Section id={DRIVER_LINK_LIST_ITEMS.APTITUDE_TEST_RECORDS.ID}>
        <AptitudeTestRecords
          register={register}
          getValues={getValues}
          errors={errors}
          control={control}
          mode={modeMap.aptitudeTestRecords}
          handleModeChange={handleModeChange}
          aptitudeTestRecordsFiles={aptitudeTestRecordsFiles}
          aptitudeTestRecordChildrenFiles={aptitudeTestRecordChildrenFiles}
          setAptitudeTestRecordsFiles={setAptitudeTestRecordsFiles}
        ></AptitudeTestRecords>
      </Section>
      <Section id={DRIVER_LINK_LIST_ITEMS.DIRECTION_RECORDS.ID}>
        <DirectionRecords
          register={register}
          getValues={getValues}
          errors={errors}
          control={control}
          mode={modeMap.directionRecords}
          handleModeChange={handleModeChange}
          directionRecordsFiles={directionRecordsFiles}
          directionRecordChildrenFiles={directionRecordChildrenFiles}
          setDirectionRecordsFiles={setDirectionRecordsFiles}
        ></DirectionRecords>
      </Section>
      <Section id={DRIVER_LINK_LIST_ITEMS.VIOLATION_HISTORIES.ID}>
        <ViolationHistories
          register={register}
          getValues={getValues}
          errors={errors}
          control={control}
          mode={modeMap.violationHistories}
          handleModeChange={handleModeChange}
        ></ViolationHistories>
      </Section>
      <Section id={DRIVER_LINK_LIST_ITEMS.MEMO.ID}>
        <Memo data={props.data} />
      </Section>
      <DeleteButton sx={{ margin: 'none' }} onClick={onClickDelete}>
        このデータを削除する
      </DeleteButton>
    </CustomForm>
  )
}
