import * as React from 'react'
import { Controller } from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomSelect,
  HeadingText,
} from 'components/atoms/FormComponents'
import { ENUMS } from 'commons/enums'

export default function Basic(props: { control }) {
  const { control } = props
  const greenManagementVehicleTypeOptions = [
    ...Object.values(ENUMS.VEHICLES.GREEN_MANAGEMENT_VEHICLE_TYPE),
  ]

  return (
    <BackGroundWhite>
      <HeadingText>グリーン経営認証</HeadingText>
      <CustomLabel>
        ※グリーン経営認証の帳票作成時に必要な項目です。認証を申請予定の場合は入力ください。
      </CustomLabel>
      <CustomLabel>
        車両種別
        <Controller
          name="greenManagementVehicleType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomSelect
              options={greenManagementVehicleTypeOptions}
              value={greenManagementVehicleTypeOptions?.find(
                (c) => c.value === value
              )}
              onChange={(option: ReactSelectOptionProps) =>
                onChange(option.value)
              }
              placeholder={'未選択'}
            />
          )}
        />
      </CustomLabel>
    </BackGroundWhite>
  )
}
