import * as React from 'react'
import {
  ADMIN_ROUTES,
  APPLICATION_NAME,
  PAGE_TITLES,
  TABLE_DEFAULT_ROWS,
} from 'commons/constants'
import { ADMIN_MENUS } from 'commons/menus'
import EducationAnnualPlanTable from 'components/organisms/admin/education_annual_plans/Table'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { AddButton } from 'components/atoms/ButtonComponenets'
import { Box } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { UserContext } from 'providers/UserProvider'
import { useLocation } from 'react-router-dom'
import useEducationAnnualPlans from './hooks/useEducationAnnualPlans'

function AdminEducationAnnualPlansIndex() {
  const title = '年間計画の新規作成'
  const user = React.useContext(UserContext)
  const defaultSearchParams = { officeId: user.officeId }
  const [searchParams, setSearchParams] =
    React.useState<SearchEducationAnnualPlan>(defaultSearchParams)
  const [paginateParams, setPaginateParams] = React.useState<PaginateParams>({
    pageNumber: 1,
    displayLimit: TABLE_DEFAULT_ROWS,
  })
  const queryParams = useLocation().search
  const focusId = new URLSearchParams(queryParams).get('focus_id')

  const { educationAnnualPlansData, isLoadingEducationAnnualPlansData } =
    useEducationAnnualPlans(searchParams, paginateParams)

  return (
    <PageWrapper>
      <Helmet>
        <title>
          {PAGE_TITLES.EDUCATIONS.ANNUAL_PLANS.INDEX ?? APPLICATION_NAME}
        </title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1>{ADMIN_MENUS.educations.children.education_annual_plans.title}</h1>
        <AddButton href={ADMIN_ROUTES.EDUCATION_ANNUAL_PLANS_NEW}>
          {title}
        </AddButton>
      </Box>
      <EducationAnnualPlanTable
        data={educationAnnualPlansData?.educationAnnualPlans}
        totalCount={educationAnnualPlansData?.totalCount}
        setSearchParams={setSearchParams}
        paginateParams={paginateParams}
        setPaginateParams={setPaginateParams}
        defaultSearchParams={defaultSearchParams}
        focusId={parseInt(focusId)}
        isLoading={isLoadingEducationAnnualPlansData}
      />
    </PageWrapper>
  )
}

export default AdminEducationAnnualPlansIndex
