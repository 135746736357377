export const TOP_LICENSE_TYPE = [
  { label: '大二', value: 'second_class_large' },
  { label: '普二', value: 'second_class_standard' },
  { label: '大特二', value: 'second_class_large_special' },
  { label: 'け引二', value: 'second_class_towing' },
  { label: '大型', value: 'large' },
  { label: '普通', value: 'standard' },
  { label: '大特', value: 'large_special' },
  { label: '大自二', value: 'large_motorcycle' },
  { label: '普自二', value: 'standard_motorcycle' },
]
