import * as React from 'react'

import {
  Dialog,
  DialogActions,
  DialogTitle,
  styled,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CachedIcon from '@mui/icons-material/Cached'
import CancelIcon from '@mui/icons-material/Cancel'
import ContentCopy from '@mui/icons-material/ContentCopy'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import ModeEditIcon from '@mui/icons-material/ModeEdit'

import Button, { ButtonProps } from '@mui/material/Button'

import { ALERT_TEXT } from 'commons/constants'
import { palette } from 'components/theme'

import { ApplyButton } from './buttons/ApplyButton'

export function IconLabelButtons(props: ButtonProps) {
  return (
    <Button
      variant="contained"
      onClick={() => props.onClick}
      {...props}
      sx={{
        height: '40px',
        ...props.sx,
      }}
    >
      {props.children}
    </Button>
  )
}

export function AddButton(props: ButtonProps) {
  return (
    <ApplyButton {...props} startIcon={<AddIcon />}>
      {props.children}
    </ApplyButton>
  )
}

export function AddButtonOutlined(props: ButtonProps) {
  return (
    <ApplyButton
      {...props}
      pattern="outlined"
      startIcon={<AddIcon />}
      // FIXME: 以下もstyleで表現できるようにする
      sx={{ boxShadow: 0 }}
    >
      {props.children}
    </ApplyButton>
  )
}

export function CancelButtonOutlined(props: ButtonProps) {
  return (
    <ApplyButton
      {...props}
      pattern="outlined"
      startIcon={<CancelIcon />}
      // FIXME: 以下もstyleで表現できるようにする
      sx={{ boxShadow: 0 }}
    >
      {props.children}
    </ApplyButton>
  )
}

export function EditButton(props: ButtonProps) {
  return (
    <ApplyButton {...props} startIcon={<ModeEditIcon />}>
      {props.children}
    </ApplyButton>
  )
}

export function EditLabelButton(props: ButtonProps) {
  return (
    <IconLabelButtons
      startIcon={<ModeEditIcon />}
      variant="text"
      {...props}
      sx={{
        color: palette.primary.deep,
        border: 'none',
        background: 'none',
        '&:hover': { background: 'none' },
        ...props.sx,
      }}
    >
      {props.children}
    </IconLabelButtons>
  )
}

export function EditButtonOutlined(props: ButtonProps) {
  return (
    <ApplyButton
      {...props}
      pattern="unsetOutlined"
      startIcon={<ModeEditIcon />}
    >
      {props.children}
    </ApplyButton>
  )
}

interface DeleteButtonProps extends ButtonProps {
  modalText?: string
}

export function DeleteButton(props: DeleteButtonProps) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconLabelButtons
        startIcon={<DeleteForeverIcon />}
        variant="text"
        {...props}
        onClick={handleClickOpen}
        sx={{
          color: palette.error.main,
          border: 'none',
          background: 'none',
          '&:hover': { background: 'none' },
          ...props.sx,
        }}
      >
        {props.children}
      </IconLabelButtons>

      {/* キャンセルボタンクリック後に表示するダイアログ */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {props.modalText || 'このデータを削除します。よろしいですか？'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>キャンセルする</Button>
          <Button
            onClick={(e) => {
              props.onClick(e)
              handleClose()
            }}
            autoFocus
          >
            削除する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export function BulkUpdateButton(props: ButtonProps) {
  // ボタン活性時の枠線の色
  const borderColor = palette.border.deep

  return (
    <Button
      startIcon={<CachedIcon />}
      variant="outlined"
      onClick={() => props.onClick}
      {...props}
      sx={{
        width: '200px',
        height: '40px',
        color: palette.text.black.main,
        backgroundColor: 'white',
        border: `1px solid ${borderColor}`,
        // hover時にスタイルを変えない
        '&:hover': {
          backgroundColor: 'transparent',
          borderColor: borderColor,
        },
        ...props.sx,
      }}
    >
      <Typography sx={{ fontWeight: 'bold' }}>{props.children}</Typography>
    </Button>
  )
}

export function ButtonAsPlanText(props: ButtonProps) {
  const ButtonAsPlanTextElement = styled(Button)({
    textDecoration: 'none',
    color: palette.text.black.main,
  })
  return (
    <ButtonAsPlanTextElement onClick={props.onClick} {...props}>
      {props.children}
    </ButtonAsPlanTextElement>
  )
}

export function TelButton(props: ButtonProps) {
  return (
    <ApplyButton {...props} startIcon={<LocalPhoneIcon />}>
      {props.children}
    </ApplyButton>
  )
}

interface ExportButtonProps extends ButtonProps {
  modalText?: string
}

export function ExportButton(props: ExportButtonProps) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconLabelButtons
        {...props}
        onClick={handleClickOpen}
        sx={{
          backgroundColor: palette.primary.main,
          '&:hover': {
            backgroundColor: palette.primary.deep,
          },
          ...props.sx,
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>{props.children}</Typography>
      </IconLabelButtons>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {props.modalText ? props.modalText : ALERT_TEXT.LONG_TIME_EXPORT}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>キャンセルする</Button>
          <Button
            onClick={(e) => {
              props.onClick(e)
              handleClose()
            }}
            autoFocus
          >
            実行する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export function FormOperationButtonGray(props: ButtonProps) {
  const FormOperationButtonGrayElement = styled(IconLabelButtons)({
    background: '#E6E7E8',
    color: palette.text.black.main,
    border: 'none',
    padding: '10px',
    borderRadius: '5px',
    cursor: 'pointer',
    marginRight: '10px',
    height: '40px',
    '&:hover': { backgroundColor: palette.background.grey },
  })
  return (
    <FormOperationButtonGrayElement {...props}>
      {props.children}
    </FormOperationButtonGrayElement>
  )
}

export function CopyContentButton(props: { content: string }) {
  const ButtonGrayElement = styled(IconLabelButtons)({
    background: palette.background.main,
    color: palette.text.black.main,
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': { backgroundColor: palette.background.grey },
  })
  const copyToClipboard = async () => {
    await global.navigator.clipboard.writeText(props.content)
  }

  return (
    <ButtonGrayElement startIcon={<ContentCopy />} onClick={copyToClipboard}>
      コピー
    </ButtonGrayElement>
  )
}

export { ApplyButton }
