import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BackGroundGray } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomInputShort,
  CustomForm,
  DatePicker,
  ResetButton,
  CustomSelect,
  WithEmptyOption,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Box, Typography } from '@mui/material'
import { toISOStringWithTimezone } from 'commons/functions'
import { fetchDriverList } from 'components/apis/drivers'
import { fetchVehicleList } from 'components/apis/vehicles'
import { ENUMS } from 'commons/enums'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { styles } from 'components/theme'
import { useQuery } from '@tanstack/react-query'
import SearchCompanyAndOffice from 'components/atoms/ReactHookFormPartials/SearchForm/SearchCompanyAndOffice'
import {
  setLocalStorageSearchParams,
  getLocalStorageDisplayLimit,
  removeLocalStoragePageNumber,
} from 'commons/table'
import { LOCAL_STORAGE_PAGE_KEY } from 'commons/constants'

interface Props {
  setSearchParams: React.Dispatch<React.SetStateAction<SearchAccident>>
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  searchParams: SearchAccident
  defaultSearchParams: SearchAccident
  isLoading?: boolean
}

function SearchForm(props: Props) {
  const {
    setSearchParams,
    setPaginateParams,
    setPage,
    searchParams,
    defaultSearchParams,
    isLoading = false,
  } = props

  // 対人/対物、加害/被害、事故対応のセレクトボックスオプション
  const damagedObjectOptions = [
    ...Object.values(ENUMS.ACCIDENTS.DAMAGED_OBJECT),
  ]
  const responsibilityOptions = [
    ...Object.values(ENUMS.ACCIDENTS.RESPONSIBILITY),
  ]
  const statusOptions = [...Object.values(ENUMS.ACCIDENTS.STATUS)]

  // ドライバー・車両のセレクトボックスのoptionsをAPIから取得
  const { data: drivers } = useQuery(
    [`admin/accidents/SearchForm`, 'driver_list'],
    () =>
      fetchDriverList().then((res) =>
        Object.values(res.data.drivers).map((value: DriverSelectOption) => {
          return { value: value.id, label: value.fullName }
        })
      )
  )

  const { data: vehicles } = useQuery(
    [`admin/accidents/SearchForm`, 'vehicles'],
    () =>
      fetchVehicleList().then((res) =>
        Object.values(res.data.vehicles).map((vehicle: Vehicle) => {
          return { value: vehicle.id, label: vehicle.number }
        })
      )
  )

  // フォームオブジェクト
  const { register, handleSubmit, control, reset, getValues, setValue, watch } =
    useForm<SearchAccident>({
      mode: 'onSubmit',
      defaultValues: searchParams,
    })

  // フォームsubmit時のコールバック
  const onSubmit = (data: SearchAccident) => {
    const registeredStartAt = data.registeredStartAt
      ? new Date(data.registeredStartAt)
      : undefined
    const registeredEndAt = data.registeredEndAt
      ? new Date(data.registeredEndAt)
      : undefined

    const params = {
      ...data,
      registeredStartAt: registeredStartAt
        ? toISOStringWithTimezone(registeredStartAt).split('T')[0]
        : undefined,
      registeredEndAt: registeredEndAt
        ? toISOStringWithTimezone(registeredEndAt).split('T')[0]
        : undefined,
    }
    setSearchParams(params)
    setLocalStorageSearchParams(LOCAL_STORAGE_PAGE_KEY.ACCIDENTS, params)
  }

  const resetParams = () => {
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = 1

    setPaginateParams({ pageNumber, displayLimit: displayLimit })
    setPage(pageNumber)
    removeLocalStoragePageNumber(LOCAL_STORAGE_PAGE_KEY.ACCIDENTS)
    setSearchParams(defaultSearchParams)
    setLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.ACCIDENTS,
      defaultSearchParams
    )
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '90%' }}>
        <BackGroundGray>
          <Typography
            sx={{ marginBottom: '20px', fontWeight: 'bold' }}
            variant={'body1'}
          >
            条件で絞り込み
          </Typography>
          <CustomForm onSubmit={handleSubmit(onSubmit)}>
            <FlexBox sx={{ flexDirection: { xs: 'column' } }}>
              <SearchCompanyAndOffice
                companyKeyName={'companyId'}
                officeKeyName={'officeId'}
                control={control}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
              />
            </FlexBox>
            <FlexBox sx={{ flexDirection: { xs: 'column' } }}>
              <CustomLabel>
                集計期間
                <FlexBox sx={{ flexDirection: { xs: 'column' } }}>
                  <Controller
                    control={control}
                    name="registeredStartAt"
                    render={({ field: { onChange, value } }) => {
                      const date = value ? new Date(value) : null
                      return (
                        <DatePicker
                          onChange={onChange}
                          selected={date}
                          sx={{
                            width: {
                              xs: '100%',
                              md: `${styles.shortFormWidth}px`,
                            },
                          }}
                        />
                      )
                    }}
                  />
                  <Typography sx={{ margin: '0 1rem' }}>〜</Typography>
                  <Controller
                    control={control}
                    name="registeredEndAt"
                    render={({ field: { onChange, value } }) => {
                      const date = value ? new Date(value) : null
                      return (
                        <DatePicker
                          onChange={onChange}
                          selected={date}
                          sx={{
                            width: {
                              xs: '100%',
                              md: `${styles.shortFormWidth}px`,
                            },
                          }}
                        />
                      )
                    }}
                  />
                </FlexBox>
              </CustomLabel>
              <CustomLabel>
                管理番号
                <CustomInputShort {...register('id')} />
              </CustomLabel>
            </FlexBox>

            <FlexBox sx={{ flexDirection: { xs: 'column' } }}>
              <CustomLabel>
                車両ナンバー
                <Controller
                  name="vehicleId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      options={WithEmptyOption(vehicles)}
                      value={vehicles?.find((c) => c.value === Number(value))}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option.value)
                      }
                      placeholder="全て"
                    />
                  )}
                />
              </CustomLabel>
              <CustomLabel>
                ドライバー
                <Controller
                  name="driverId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      options={WithEmptyOption(drivers)}
                      value={drivers?.find((c) => c.value === value)}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option.value)
                      }
                      placeholder="全て"
                    />
                  )}
                />
              </CustomLabel>
            </FlexBox>

            <FlexBox sx={{ flexDirection: { xs: 'column' } }}>
              <CustomLabel>
                対人/対物
                <Controller
                  name="damagedObject"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      sx={{ width: styles.shortFormWidth }}
                      options={WithEmptyOption(damagedObjectOptions)}
                      value={damagedObjectOptions?.find(
                        (c) => c.value === value
                      )}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option?.value)
                      }
                      placeholder="全て"
                    />
                  )}
                />
              </CustomLabel>
              <CustomLabel>
                加害/被害
                <Controller
                  name="responsibility"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      sx={{ width: styles.shortFormWidth }}
                      options={WithEmptyOption(responsibilityOptions)}
                      value={responsibilityOptions?.find(
                        (c) => c.value === value
                      )}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option?.value)
                      }
                      placeholder="全て"
                    />
                  )}
                />
              </CustomLabel>
              <CustomLabel>
                事故対応
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      sx={{ width: styles.shortFormWidth }}
                      options={WithEmptyOption(statusOptions)}
                      value={statusOptions?.find((c) => c.value === value)}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option?.value)
                      }
                      placeholder="全て"
                    />
                  )}
                />
              </CustomLabel>
            </FlexBox>

            <ApplyButton pattern="inRow" type="submit" disabled={isLoading}>
              絞り込む
            </ApplyButton>
            <ResetButton
              onClick={() => {
                reset(defaultSearchParams)
                resetParams()
              }}
              disabled={isLoading}
            >
              <Typography>リセット</Typography>
            </ResetButton>
          </CustomForm>
        </BackGroundGray>
      </Box>
    </Box>
  )
}

export default SearchForm
