import * as React from 'react'
import { styled } from '@mui/styles'
import { Box, Button } from '@mui/material'
import { palette, theme, spacing } from 'components/theme'
import Collapse from '@mui/material/Collapse'
import { FlexBox } from 'components/atoms/BoxComponents'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ApartmentIcon from '@mui/icons-material/Apartment'
import BusinessIcon from '@mui/icons-material/Business'
import { EducationIcon } from 'components/icons/EducationIcon'
import { AccidentIcon } from 'components/icons/AccidentIcon'
import { BillIcon } from 'components/icons/BillIcon'
import { SalesIcon } from 'components/icons/SalesIcon'
import { InspectionIcon } from 'components/icons/InspectionIcon'
import { MasterIcon } from 'components/icons/MasterIcon'
import { VehicleIcon } from 'components/icons/VehicleIcon'
import { DriverIcon } from 'components/icons/DriverIcon'
import { WorkIcon } from 'components/icons/WorkIcon'
import { AlcoholIcon } from 'components/icons/AlcoholIcon'
import { NotificationListIcon } from 'components/icons/NotificationListIcon'
import { DeliveryDetailIcon } from 'components/icons/DeliveryDetailIcon'
import { HelpIcon } from 'components/icons/HelpIcon'
import MailIcon from '@mui/icons-material/Mail'
import CloudCircleIcon from '@mui/icons-material/CloudCircle'
import ArticleIcon from '@mui/icons-material/Article'
import Badge from '@mui/material/Badge'
import { fetchLockedDriversList } from 'components/apis/drivers'
import {
  PRD_DOMAIN,
  EXECUTIVE_USER_ROUTE_PATH,
  STG_DOMAIN,
} from 'commons/constants'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { CsvSettingIcon } from 'components/icons/CsvSettingIcon'
import { TimecardIcon } from 'components/icons/TimecardIcon'

import useMenu from './Menu.hooks'
import { XMILE_ADMIN_MENUS } from 'commons/menus'
import { UserVehicleIcon } from 'components/icons/UserVehiclezIcon'

type Props = {
  isAdmin?: boolean
  isXmileAdmin?: boolean
  isAssistantStaff?: boolean
  isDispatcher?: boolean
  isExecutive?: boolean
  currentUserId: number
  isCommonDriverOnTimecardPage?: boolean
}

const SidebarContainer = styled(Box)({
  height: '100%',
  minWidth: spacing.sidebarWidth,
  padding: '12px 8px 8px',
  borderRight: 'solid',
  borderRightColor: palette.border.pale,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'block',
  },
})

const SidebarMenu = styled('div')({
  width: '100%',
  verticalAlign: 'middle',
  whiteSpace: 'pre',
  height: '50px',
  marginTop: '5px',
  lineHeight: '60px',
  display: 'flex',
})

const CustomLink = styled('a')({
  height: '100%',
  minHeight: '30px',
  padding: 0,
  fontWeight: 'bold',
  fontSize: '20px',
  verticalAlign: 'middle',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  '& span': {
    color: palette.text.gray.deep,
  },
  borderRadius: '4px',
})

const CustomButton = styled(Button)({
  height: '100%',
  width: '100%',
  minHeight: '30px',
  padding: 0,
  fontWeight: 'bold',
  fontSize: '20px',
  verticalAlign: 'middle',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& span': {
    color: palette.text.gray.deep,
  },
})

const ActiveLink = styled(CustomLink)({
  backgroundColor: palette.background.activeMenu,
  '& span': {
    color: palette.text.black.main,
  },
  width: '100%',
})

const CustomIcon = styled('svg')({
  color: palette.text.black.main,
  width: '30px',
  height: '30px',
  margin: '0 10px',
})

function isDeveloping(menu?: Menu): boolean {
  return (
    menu?.isDeveloping &&
    (document.domain == PRD_DOMAIN || document.domain == STG_DOMAIN)
  )
}

function RenderIcon(props: { menu: Menu }) {
  const { menu } = props

  // レンダリングごとにlookupが発生するので、効率は良くはない
  // シンプルに辞書型で定義するのが一番早いと思うが、そもそも別の場所で管理した方が綺麗なので、余裕があるタイミングで全面的に見直す
  const icon = () => {
    switch (menu.name) {
      case 'educations':
      case 'education_documents':
      case 'education_papertests':
      case 'education_attendances':
      case 'education_export_csv':
      case 'education_videos':
      case 'education_annual_plans':
      case 'education_video_logs':
      case 'education_comments':
      case 'driving_course_newcomer':
      case 'education_settings':
        return <EducationIcon />
      case 'accidents':
        return <AccidentIcon />
      case 'bills':
      case 'bill_settings':
      case 'payment_statements':
      case 'payment_statement_settings':
        return <BillIcon />
      case 'delivery_details':
        return <DeliveryDetailIcon />
      case 'sales':
        return <SalesIcon />
      case 'delivery_requests':
      case 'subscription_delivery_requests':
      case 'delivery_statuses':
      case 'proposal_deliveries':
      case 'delivery_request_settings':
        return <WorkIcon />
      case 'vehicle_inspection_plans':
        return <InspectionIcon />
      case 'masters':
        return <MasterIcon />
      case 'vehicles':
      case 'inspection_and_accident':
      case 'refuelings':
      case 'vehicle_managements_settings':
      case 'fuel_consumptions':
      case 'vehicle_managements':
      case 'vehicle_cost_managements':
        return <VehicleIcon />
      case 'drivers':
        return <DriverIcon />
      case 'companies':
        return <ApartmentIcon />
      case 'partner_companies':
        return <BusinessIcon />
      case 'alcohol_checks':
        return <AlcoholIcon />
      case 'timecard':
      case 'timecard_check_in-out':
      case 'timecard_record':
      case 'timecard_management':
      case 'timecard_setting':
        return <TimecardIcon />
      case 'timecard_dashboard':
        return <SalesIcon />
      case 'vehicle_allocation_table':
        return <UserVehicleIcon />
      case 'notifications':
      case 'notification_lists':
      case 'private_notifications':
        return <NotificationListIcon />
      case 'helps':
        return <HelpIcon />
      case 'contact_addresses':
        return <MailIcon />
      case 'csv_settings':
        return <CsvSettingIcon />
      case 'resources':
        return <CloudCircleIcon />
      case 'articles':
        return <ArticleIcon />
      default:
        return
    }
  }

  const badgeStyle = {
    '& .MuiBadge-badge': {
      color: 'white',
      backgroundColor: 'red',
      fontSize: '12px',
      fontWeight: 'bold',
      marginLeft: '12px',
    },
  }

  return menu.showBadge ? (
    <Badge
      badgeContent={'!'}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      sx={badgeStyle}
    >
      <CustomIcon>{icon()}</CustomIcon>
    </Badge>
  ) : (
    <CustomIcon>{icon()}</CustomIcon>
  )
}

function Link(props: {
  menu: Menu
  currentUserId?: number
  children?: React.ReactNode
}) {
  const { menu } = props

  const isMenuActive = (menuUrl: string, expectedUrl: string) =>
    menuUrl === expectedUrl

  const isActive = isMenuActive(menu.url, window.location.pathname)
  const LinkComponent = isActive ? ActiveLink : CustomLink

  return (
    <LinkComponent
      href={menu.url}
      target={menu.openNewTab ? '_blank' : ''}
      rel={menu.openNewTab ? 'noopener noreferrer' : ''}
    >
      {props.children}
    </LinkComponent>
  )
}

function ParentLink(props: {
  menu: Menu
  isAdmin: boolean
  key: number
  isCommentable: boolean
}) {
  const { menu } = props
  const isActive = Object.values(menu.children).some(
    (child) => child.url == window.location.pathname
  )

  const [checked, setChecked] = React.useState(isActive)
  const handleClick = () => {
    setChecked((prev) => !prev)
  }

  return (
    <FlexBox
      flexDirection={'column'}
      sx={{ alignItems: 'center', width: '100%' }}
    >
      <SidebarMenu>
        <CustomButton onClick={handleClick}>
          <RenderIcon menu={menu} />
          <span>{menu.title}</span>
          {checked ? (
            <ArrowDropUpIcon style={{ color: palette.text.black.main }} />
          ) : (
            <ArrowDropDownIcon style={{ color: palette.text.black.main }} />
          )}
        </CustomButton>
      </SidebarMenu>
      {Object.values(menu.children).map((child: Menu, index: number) => {
        // 開発中のメニューは本番環境では表示しない
        if (isDeveloping(child)) return

        // コメント機能が無効の場合は非表示
        if (!props.isCommentable && child.name == 'education_comments') return

        return (
          <Collapse
            in={checked}
            key={index}
            sx={{
              alignSelf: 'self-start',
              width: '100%',
              paddingLeft: '1rem',
            }}
          >
            <SidebarMenu>
              <Link menu={child}>
                <span>└ </span>
                <RenderIcon menu={child} />
                <span>{child.title}</span>
              </Link>
            </SidebarMenu>
          </Collapse>
        )
      })}
    </FlexBox>
  )
}

function Sidebar(props: Props) {
  const { menus, isCommentable } = useMenu(
    props.isAdmin,
    props.isXmileAdmin,
    props.isAssistantStaff,
    props.isDispatcher
  )
  const [lockedDrivers, setLockedDrivers] = React.useState([])

  React.useEffect(() => {
    fetchLockedDriversList().then((response) => {
      setLockedDrivers(response.data.drivers)
    })
  }, [])
  // アカウントロックされているユーザーがいる場合バッジを表示する
  if (props.isAdmin) {
    menus.masters.children.drivers.showBadge = lockedDrivers.length > 0
    menus.masters.showBadge = lockedDrivers.length > 0
  }

  const BlankSpace = styled('div')({
    height: '70px',
    flexShrink: 0,
  })

  const SectionLine = styled('div')({
    border: `1.8px solid ${palette.border.pale}`,
  })

  return (
    <SidebarContainer>
      <FlexBox flexDirection={'column'} style={{ height: '100%' }}>
        {/* position:fixedのヘッダーと重なる箇所を余白で調整 */}
        {!props.isCommonDriverOnTimecardPage && <BlankSpace />}
        <FlexBox
          flexDirection={'column'}
          style={{ height: '100%', overflowY: 'auto' }}
        >
          {(props.isExecutive || props.isAdmin) && (
            <ApplyButton pattern="sidebar" href={EXECUTIVE_USER_ROUTE_PATH}>
              経営者メニューを利用する
            </ApplyButton>
          )}

          {Object.values(menus).map((menu, index) => {
            // 開発中のメニューは本番環境では表示しない
            if (isDeveloping(menu)) return

            return (
              <React.Fragment key={index}>
                {menu.children ? (
                  <>
                    <ParentLink
                      menu={menu}
                      isAdmin={props.isAdmin}
                      key={index}
                      isCommentable={isCommentable}
                    />
                    {/* お知らせの下に区切り線表示（アナウンスメニューのグルーピング） */}
                    {menu.name == 'articles' && <SectionLine />}
                  </>
                ) : (
                  <>
                    <SidebarMenu>
                      <Link menu={menu} currentUserId={props.currentUserId}>
                        <RenderIcon menu={menu} />
                        <span>{menu.title}</span>
                      </Link>
                    </SidebarMenu>
                    {/* 経営ダッシュボードの下に区切り線表示（編集系メニューのグルーピング） */}
                    {menu.name == 'sales' && <SectionLine />}
                  </>
                )}
              </React.Fragment>
            )
          })}
        </FlexBox>
      </FlexBox>
    </SidebarContainer>
  )
}

export default Sidebar
