import { useQuery } from '@tanstack/react-query'
import { createQueryKey } from 'commons/functions'
import { fetchEducationVideoLogs } from 'components/apis/educationVideoLogs'
import { useErrorHandler } from 'react-error-boundary'

export default function useAdminEducationVideoLogsChapter(
  baseParams: EducationVideoLog,
  searchParams: SearchEducationVideoLog,
  paginateParams: PaginateParams
) {
  const handleError = useErrorHandler()
  const queryKeys = createQueryKey('education_video_logs', {
    ...baseParams,
    ...searchParams,
    ...paginateParams,
  })

  const {
    data: adminEducationVideoLogsData,
    isLoading: isLoadingAdminEducationVideoLogs,
  } = useQuery({
    queryKey: queryKeys,
    queryFn: async () => {
      const response = await fetchEducationVideoLogs(
        baseParams,
        searchParams,
        paginateParams
      )
      return response.data
    },
    onError: (error) => {
      handleError(error)
    },
  })

  return {
    adminEducationVideoLogsData,
    isLoadingAdminEducationVideoLogs,
  }
}
