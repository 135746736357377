import * as React from 'react'
import { MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { getAccident } from 'components/apis/accidents'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import AccidentDetail from 'components/organisms/admin/accidents/Detail'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import { FlexBox } from 'components/atoms/BoxComponents'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { EditButton } from 'components/atoms/ButtonComponenets'
import { useTheme } from '@mui/material'

function AdminAccidentsShow() {
  const { id } = useParams()
  const theme = useTheme()
  const navigate = useNavigate()
  const { data: accident, isLoading } = useQuery(
    [`admin/accidents/show`, 'accident'],
    () => getAccident(parseInt(id)).then((res) => res.data)
  )

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.ACCIDENTS.SHOW ?? APPLICATION_NAME}</title>
      </Helmet>
      <FlexBox
        justifyContent={'space-between'}
        sx={{
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
          alignItems: 'center',
        }}
      >
        <h1>{MENUS.inspectionAndAccident.children.accidents.title}</h1>
        <FlexBox
          gap={2}
          sx={{
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
            alignItems: 'center',
          }}
        >
          <ApplyButton
            onClick={() =>
              window.open(`/accidents/${accident.id}/report`, '_blank')
            }
          >
            PDF出力
          </ApplyButton>
          <EditButton
            onClick={() => {
              navigate('edit/')
            }}
          >
            編集する
          </EditButton>
        </FlexBox>
      </FlexBox>
      {isLoading ? <CircularProgressBox /> : <AccidentDetail data={accident} />}
    </PageWrapper>
  )
}

export default AdminAccidentsShow
