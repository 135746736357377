import { useQuery } from '@tanstack/react-query'
import { createQueryKey } from 'commons/functions'
import { fetchAccidents } from 'components/apis/accidents'
import { useErrorHandler } from 'react-error-boundary'

export default function useAccidents(
  searchParams: SearchAccident,
  paginateParams: PaginateParams
) {
  const handleErrors = useErrorHandler()
  const queryKey = createQueryKey('accidents', {
    ...searchParams,
    ...paginateParams,
  })

  const { data: accidentsData, isLoading: isLoadingAccidentsData } = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await fetchAccidents(searchParams, paginateParams)
      return response.data
    },
    onError: (error) => {
      handleErrors(error)
    },
  })

  return {
    accidentsData,
    isLoadingAccidentsData,
  }
}
