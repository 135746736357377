import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BackGroundGray } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomForm,
  ResetButton,
  CustomSelect,
  WithEmptyOption,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Box, Typography } from '@mui/material'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { LOCAL_STORAGE_PAGE_KEY, EDUCATION_COMMENTS } from 'commons/constants'
import { yearRangeFrom2000 } from 'commons/date'
import { getFiscalYear } from 'commons/functions'
import SearchCompanyAndOffice from 'components/atoms/ReactHookFormPartials/SearchForm/SearchCompanyAndOffice'
import {
  getLocalStorageSearchParams,
  setLocalStorageSearchParams,
  getLocalStorageDisplayLimit,
  removeLocalStoragePageNumber,
} from 'commons/table'
import { fetchDriverList } from 'components/apis/drivers'
import { useQuery } from '@tanstack/react-query'

interface Props {
  setSearchParams: React.Dispatch<React.SetStateAction<SearchEducationComment>>
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  defaultSearchParams: SearchEducationComment
  isLoading?: boolean
}

function SearchForm(props: Props) {
  const {
    setSearchParams,
    setPaginateParams,
    setPage,
    defaultSearchParams,
    isLoading = false,
  } = props
  React.useEffect(() => {
    const searchParams = getLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_COMMENTS
    )
    if (searchParams) {
      // リクエストで送るparamsのstate
      setSearchParams(searchParams)
      // formのvalue
      Object.keys(searchParams).forEach((key: keyof SearchEducationComment) => {
        setValue(key, searchParams[key])
      })
    }
  }, [LOCAL_STORAGE_PAGE_KEY.EDUCATION_COMMENTS])

  const statusSelectOptions: SelectOption[] = [
    { value: 'driver_no_comment', label: 'ドライバーコメント未登録' },
    { value: 'admin_no_comment', label: '管理者コメント未登録' },
  ]

  // フォームオブジェクト
  const { handleSubmit, control, reset, getValues, setValue, watch } =
    useForm<SearchEducationComment>({
      mode: 'onSubmit',
      defaultValues: defaultSearchParams,
    })

  // フォームsubmit時のコールバック
  const onSubmit = (data: SearchEducationComment) => {
    setSearchParams(data)

    setLocalStorageSearchParams(LOCAL_STORAGE_PAGE_KEY.EDUCATION_COMMENTS, data)
  }

  const resetParams = () => {
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = 1

    setPaginateParams({ pageNumber, displayLimit: displayLimit })
    setPage(pageNumber)
    removeLocalStoragePageNumber(LOCAL_STORAGE_PAGE_KEY.EDUCATION_COMMENTS)
    setSearchParams(defaultSearchParams)
    setLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_COMMENTS,
      defaultSearchParams
    )
  }

  const { data: drivers } = useQuery(
    [`education_comments/SearchForm`, 'driver_list'],
    () =>
      fetchDriverList().then((res) =>
        Object.values(res.data.drivers).map((value: DriverSelectOption) => {
          return { value: String(value.id), label: value.fullName }
        })
      )
  )

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '90%' }}>
        <BackGroundGray>
          <Typography
            sx={{ marginBottom: '20px', fontWeight: 'bold' }}
            variant={'body1'}
          >
            条件で絞り込み
          </Typography>
          <CustomForm onSubmit={handleSubmit(onSubmit)}>
            <FlexBox>
              <SearchCompanyAndOffice
                companyKeyName={'companyId'}
                officeKeyName={'officeId'}
                control={control}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
              />
            </FlexBox>
            <FlexBox flexWrap="wrap">
              <div>
                <CustomLabel>
                  {EDUCATION_COMMENTS.LABELS.FISCAL_YEAR}
                  <Controller
                    name="year"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CustomSelect
                        options={yearRangeFrom2000}
                        value={yearRangeFrom2000?.find(
                          (c) => c.value === value
                        )}
                        onChange={(option: ReactSelectOptionProps) =>
                          onChange(option.value)
                        }
                        placeholder=""
                        menuPortalTarget={document.body}
                      />
                    )}
                  />
                </CustomLabel>
              </div>

              <div>
                <CustomLabel>
                  {EDUCATION_COMMENTS.LABELS.DRIVER_NAME}
                  <Controller
                    name="driverId"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CustomSelect
                        options={WithEmptyOption(drivers)}
                        value={drivers?.find(
                          (c) => String(c.value) === String(value)
                        )}
                        onChange={(option: ReactSelectOptionProps) =>
                          onChange(option.value)
                        }
                        placeholder="全て"
                        menuPortalTarget={document.body}
                      />
                    )}
                  />
                </CustomLabel>
              </div>
            </FlexBox>

            <FlexBox flexWrap="wrap">
              <div>
                <CustomLabel>
                  {EDUCATION_COMMENTS.LABELS.STATUS}
                  <Controller
                    name="status"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CustomSelect
                        options={WithEmptyOption(statusSelectOptions)}
                        value={statusSelectOptions?.find(
                          (c) => c.value === value
                        )}
                        onChange={(option: ReactSelectOptionProps) =>
                          onChange(option.value)
                        }
                        placeholder="全て"
                        menuPortalTarget={document.body}
                      />
                    )}
                  />
                </CustomLabel>
              </div>
            </FlexBox>

            <FlexBox flexWrap="wrap">
              <ApplyButton
                sx={{ marginRight: '1rem', marginBottom: '8px' }}
                type="submit"
                disabled={isLoading}
              >
                絞り込む
              </ApplyButton>
              <ResetButton
                sx={{ marginRight: '1rem', marginBottom: '8px' }}
                onClick={() => {
                  reset()
                  resetParams()
                }}
                disabled={isLoading}
              >
                <Typography>リセット</Typography>
              </ResetButton>
            </FlexBox>
          </CustomForm>
        </BackGroundGray>
      </Box>
    </Box>
  )
}

export default SearchForm
