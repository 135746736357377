export const LEASE_OPTIONS: {
  label: string
  value:
    | 'hasOptionMaintenance'
    | 'hasOptionPurchase'
    | 'hasOptionTire'
    | 'hasOptionOpenEnd'
    | 'hasOptionCloseEnd'
}[] = [
  { label: 'メンテナンス付きリース', value: 'hasOptionMaintenance' },
  { label: '購入選択権付きリース', value: 'hasOptionPurchase' },
  { label: 'タイヤリース', value: 'hasOptionTire' },
  { label: 'オープンエンド', value: 'hasOptionOpenEnd' },
  { label: 'クローズエンド', value: 'hasOptionCloseEnd' },
]
