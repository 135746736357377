import { useQuery } from '@tanstack/react-query'
import { createQueryKey } from 'commons/functions'
import { fetchAlcoholChecks } from 'components/apis/alcohol_checks'
import { useErrorHandler } from 'react-error-boundary'

export default function useAlcoholChecks(
  searchParams: SearchAlcoholCheck,
  paginateParams: PaginateParams
) {
  const handleError = useErrorHandler()
  const queryKey = createQueryKey('alcohol_check', {
    ...searchParams,
    ...paginateParams,
  })

  const { data: alcoholChecksData, isLoading: isLoadingAlcoholChecksData } =
    useQuery({
      queryKey,
      queryFn: async () => {
        const result = await fetchAlcoholChecks(searchParams, paginateParams)
        return result.data
      },
      onError: (error) => {
        handleError(error)
      },
    })

  return {
    alcoholChecksData,
    isLoadingAlcoholChecksData,
  }
}
