import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const VehicleIcon = () => {
  return (
    <SvgIcon>
      <path
        d="M19.2727 8.63636H16.5455V5H2V16.8182H3.81818C3.81818 18.3273 5.03636 19.5455 6.54545 19.5455C8.05455 19.5455 9.27273 18.3273 9.27273 16.8182H14.7273C14.7273 18.3273 15.9455 19.5455 17.4545 19.5455C18.9636 19.5455 20.1818 18.3273 20.1818 16.8182H22V12.2727L19.2727 8.63636ZM6.54545 17.7273C6.04545 17.7273 5.63636 17.3182 5.63636 16.8182C5.63636 16.3182 6.04545 15.9091 6.54545 15.9091C7.04545 15.9091 7.45455 16.3182 7.45455 16.8182C7.45455 17.3182 7.04545 17.7273 6.54545 17.7273ZM18.8182 10L20.6 12.2727H16.5455V10H18.8182ZM17.4545 17.7273C16.9545 17.7273 16.5455 17.3182 16.5455 16.8182C16.5455 16.3182 16.9545 15.9091 17.4545 15.9091C17.9545 15.9091 18.3636 16.3182 18.3636 16.8182C18.3636 17.3182 17.9545 17.7273 17.4545 17.7273Z"
        fill="#003333"
      />
    </SvgIcon>
  )
}
