import * as React from 'react'
import {
  APPLICATION_NAME,
  PAGE_TITLES,
  TABLE_DEFAULT_ROWS,
} from 'commons/constants'
import { ADMIN_MENUS } from 'commons/menus'
import NotificationsTable from 'components/organisms/notifications/Table'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { Helmet } from 'react-helmet-async'
import { UserContext } from 'providers/UserProvider'
import useNotifications from './hooks/useNotifications'

function AdminNotificationIndex() {
  const user = React.useContext(UserContext)
  const defaultSearchParams = {
    notificationType: '',
    leftDays: undefined,
    includePastNotifications: false,
    officeId: user.officeId,
  }
  const [searchParams, setSearchParams] =
    React.useState<SearchNotification>(defaultSearchParams)
  const [paginateParams, setPaginateParams] = React.useState<PaginateParams>({
    pageNumber: 1,
    displayLimit: TABLE_DEFAULT_ROWS,
  })

  const { notificationsData, isLoadingNotificationsData } = useNotifications(
    searchParams,
    paginateParams
  )

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.NOTIFICATIONS.INDEX ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>{ADMIN_MENUS.notifications.title}</h1>
      <NotificationsTable
        data={notificationsData?.notifications}
        totalCount={notificationsData?.totalCount}
        setSearchParams={setSearchParams}
        defaultSearchParams={defaultSearchParams}
        paginateParams={paginateParams}
        setPaginateParams={setPaginateParams}
        isLoading={isLoadingNotificationsData}
      />
    </PageWrapper>
  )
}

export default AdminNotificationIndex
