import * as React from 'react'
import { AvatarSquare } from 'components/atoms/FormComponents'

type Props = {
  image: File
  style?: {
    img?: React.CSSProperties
    placeholder?: React.CSSProperties
  }
}

function SignatureImage({ image, style }: Props) {
  const imageURL = image ? URL.createObjectURL(image) : ''

  const signatureImageStyle = {
    ...SignatureImageStyle,
    ...style,
  }

  return (
    <AvatarSquare
      src={imageURL}
      style={signatureImageStyle.img}
      placeholderStyle={signatureImageStyle.placeholder}
    />
  )
}

const SignatureImageStyle = {
  img: {
    border: 'solid',
    borderRadius: '0px',
    borderColor: '#D8D8D8',
    marginBottom: '8px',
    width: '116px',
    height: '116px',
  },
  placeholder: {
    backgroundColor: '#FFFFFF',
  },
}

export default SignatureImage
