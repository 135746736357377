import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandCircleDown'
import { palette } from 'components/theme'

export default function SingleAccordion(props: {
  summary: string
  isExpanded?: boolean
  children?: React.ReactNode
}) {
  const { summary, isExpanded = true, children } = props
  const [expanded, setExpanded] = React.useState<boolean>(isExpanded)

  return (
    <Accordion
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded)
      }}
      sx={{
        padding: '5px 10px',
        boxShadow: 'none',
        border: '1px solid #E6E7E8',
        borderRadius: '6px',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: palette.text.black.main }} />}
        sx={{ fontWeight: 'bold' }}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}
