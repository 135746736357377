import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const WorkIcon = () => {
  return (
    <SvgIcon>
      <path
        d="M19.5 5.66667H14.35C14 4.7 13.0833 4 12 4C10.9167 4 10 4.7 9.65 5.66667H4.5V20.6667H19.5V5.66667ZM12 5.66667C12.4583 5.66667 12.8333 6.04167 12.8333 6.5C12.8333 6.95833 12.4583 7.33333 12 7.33333C11.5417 7.33333 11.1667 6.95833 11.1667 6.5C11.1667 6.04167 11.5417 5.66667 12 5.66667ZM13.6667 17.3333H7.83333V15.6667H13.6667V17.3333ZM16.1667 14H7.83333V12.3333H16.1667V14ZM16.1667 10.6667H7.83333V9H16.1667V10.6667Z"
        fill="#003333"
      />
    </SvgIcon>
  )
}
