import * as React from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import {
  BackGroundWhite,
  BackGroundPalePink,
} from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  CustomTextArea,
  CustomSelect,
  CustomDateSelect,
  WithRequiredBadge,
  ErrorMessage,
} from 'components/atoms/FormComponents'
import {
  AddButtonOutlined,
  DeleteButton,
} from 'components/atoms/ButtonComponenets'
import { fetchVehicleList } from 'components/apis/vehicles'
import {
  CHARGED_VEHICLES,
  DRIVERS,
  VALIDATION_MESSAGES,
} from 'commons/constants'
import { Box } from '@mui/material'

function ChargedVehicleForm(props: {
  register
  control
  chargedVehicleIndex: number
  vehicles
  errors
}) {
  const { register, control, chargedVehicleIndex, vehicles, errors } = props

  return (
    <React.Fragment>
      <CustomLabel>
        <WithRequiredBadge>{DRIVERS.LABELS.CHARGED_VEHICLES}</WithRequiredBadge>
        <Controller
          name={`chargedVehicles.${chargedVehicleIndex}.vehicleId`}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <CustomSelect
              options={vehicles}
              value={vehicles?.find((c) => c.value === value)}
              onChange={(option: ReactSelectOptionProps) =>
                onChange(option.value)
              }
              placeholder="選択してください"
            />
          )}
        />
        {errors?.chargedVehicles?.[chargedVehicleIndex]?.vehicleId && (
          <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
        )}
      </CustomLabel>

      <CustomLabel>
        {CHARGED_VEHICLES.LABELS.FROM_DATE}
        <CustomDateSelect
          formKeys={{
            year: `chargedVehicles.${chargedVehicleIndex}.fromYear`,
            month: `chargedVehicles.${chargedVehicleIndex}.fromMonth`,
            day: `chargedVehicles.${chargedVehicleIndex}.fromDay`,
          }}
          control={props.control}
        />
      </CustomLabel>
      <CustomLabel>
        {CHARGED_VEHICLES.LABELS.TO_DATE}
        <CustomDateSelect
          formKeys={{
            year: `chargedVehicles.${chargedVehicleIndex}.toYear`,
            month: `chargedVehicles.${chargedVehicleIndex}.toMonth`,
            day: `chargedVehicles.${chargedVehicleIndex}.toDay`,
          }}
          control={props.control}
        />
      </CustomLabel>

      <CustomLabel>
        {CHARGED_VEHICLES.LABELS.MEMO}
        <CustomTextArea
          rows={3}
          {...register(`chargedVehicles.${chargedVehicleIndex}.memo`)}
        />
      </CustomLabel>
    </React.Fragment>
  )
}

export default function DriversChargedVehicles(props: {
  register
  control
  errors
}) {
  const { register, control, errors } = props
  const [vehicles, setVehicles] = React.useState<SelectOption[]>([])

  React.useEffect(() => {
    fetchVehicleList().then((response) => {
      setVehicles(
        Object.values(response.data.vehicles).map((vehicle: Vehicle) => {
          return { value: vehicle.id, label: vehicle.number }
        })
      )
    })
  }, [])

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'chargedVehicles',
  })

  const initChargedVehicle = {
    vehicleId: null,
    fromDate: null,
    toDate: null,
    memo: null,
  }

  const deleteChargedVehicle = (index) => {
    remove(index)
  }

  const addChargedVehicle = () => {
    append(initChargedVehicle)
  }

  return (
    <BackGroundWhite>
      <HeadingText>担当車両</HeadingText>
      {fields.map((_, index) => (
        <Box mb={2} key={index}>
          <BackGroundPalePink>
            担当車両{index + 1}
            <ChargedVehicleForm
              register={register}
              control={control}
              chargedVehicleIndex={index}
              vehicles={vehicles}
              errors={errors}
            />
            <DeleteButton onClick={() => deleteChargedVehicle(index)}>
              削除
            </DeleteButton>
          </BackGroundPalePink>
        </Box>
      ))}
      <AddButtonOutlined onClick={() => addChargedVehicle()}>
        担当車両を追加
      </AddButtonOutlined>
    </BackGroundWhite>
  )
}
