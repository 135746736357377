import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { addParamPrefix, camelCaseKeysToUnderscore } from 'commons/functions'

export function fetchEducationVideoLogs(
  baseParams: EducationVideoLog,
  searchParams: SearchEducationVideoLog,
  paginateParams: PaginateParams
) {
  const customSearchParams = addParamPrefix(searchParams, 'search')
  const customPaginateParams = addParamPrefix(paginateParams, 'pagination')
  const params = camelCaseKeysToUnderscore({
    ...baseParams,
    ...customSearchParams,
    ...customPaginateParams,
  })

  return axios.get(API_ENDPOINTS.EDUCATION_VIDEO_LOGS, {
    params: params,
  })
}

export function getPlayedDuration(educationId: number, userId: number) {
  return axios.get(
    [API_ENDPOINTS.EDUCATION_VIDEO_LOGS, 'played_duration'].join(''),
    {
      params: camelCaseKeysToUnderscore({
        educationId: educationId,
        userId: userId,
      }),
    }
  )
}

export function fetchEducationVideoLogViewedMonth(
  educationId: number,
  driverId: number
) {
  return axios.get(API_ENDPOINTS.EDUCATION_VIDEO_LOGS_VIEWED_MONTH, {
    params: {
      education_id: educationId,
      driver_id: driverId,
    },
  })
}
