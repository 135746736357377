import * as React from 'react'
import { styled } from '@mui/styles'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomRadioButton,
  CustomRadioGroup,
  HeadingText,
} from 'components/atoms/FormComponents'
import { ENUMS } from 'commons/enums'
import { VEHICLES } from 'commons/constants'

export default function Status(props: { register; control; errors; type }) {
  const { register } = props
  const LongCustomRadioGroup = styled(CustomRadioGroup)({
    width: '550px',
  })

  return (
    <BackGroundWhite>
      <HeadingText>{VEHICLES.LABELS.OPERATION_STATUS}</HeadingText>
      <CustomLabel>
        <LongCustomRadioGroup>
          {Object.entries(ENUMS.VEHICLES.OPERATION_STATUS_TYPE).map(
            ([key, obj]) => (
              <React.Fragment key={key}>
                <CustomRadioButton
                  {...register('operationStatus')}
                  id={`operationStatus_${obj.value}`}
                  value={obj.value}
                  type="radio"
                />
                <label htmlFor={`operationStatus_${obj.value}`}>
                  {obj.label}
                </label>
              </React.Fragment>
            )
          )}
        </LongCustomRadioGroup>
      </CustomLabel>
    </BackGroundWhite>
  )
}
