import axios, { AxiosResponse } from 'axios'
import { API_ENDPOINTS } from 'commons/constants'
import { addParamPrefix, camelCaseKeysToUnderscore } from 'commons/functions'

export function fetchNews(
  paginateParams?: PaginateParams
): Promise<AxiosResponse<{ news: News[]; totalCount: number }>> {
  const customPaginateParams = paginateParams
    ? addParamPrefix(paginateParams, 'pagination')
    : {}
  const params = camelCaseKeysToUnderscore({
    ...customPaginateParams,
  })
  return axios.get(API_ENDPOINTS.NEWS, {
    params: params,
  })
}
