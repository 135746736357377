import React from 'react'

type Props = {
  id?: string
  children: React.ReactNode
  style?: keyof typeof styles
}

export const Section: React.FC<Props> = ({ id, children, style }) => (
  <section id={id} style={styles[style ?? 'primary']}>
    {children}
  </section>
)

const primary = {
  display: 'flex',
  width: '100%',
  padding: '24px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  alignSelf: 'stretch',
  borderRadius: '12px',
  background: '#FFF',
  boxShadow:
    '0px 1px 4px 0px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.26)',
} as React.CSSProperties

const secondary = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  borderRadius: '12px',
  background: '#FFF',
  boxShadow:
    '0px 1px 4px 0px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.26)',
} as React.CSSProperties

const styles = { primary, secondary }
