import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BackGroundGray } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomForm,
  ResetButton,
  CustomSelect,
  WithEmptyOption,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Box, Typography } from '@mui/material'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { NOTIFICATIONS, LOCAL_STORAGE_PAGE_KEY } from 'commons/constants'
import { fetchNotificationTypeOptions } from 'components/apis/notifications'
import SearchCompanyAndOffice from 'components/atoms/ReactHookFormPartials/SearchForm/SearchCompanyAndOffice'
import {
  getLocalStorageSearchParams,
  setLocalStorageSearchParams,
  getLocalStorageDisplayLimit,
  removeLocalStoragePageNumber,
} from 'commons/table'

interface Props {
  setSearchParams: React.Dispatch<React.SetStateAction<SearchNotification>>
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  defaultSearchParams: SearchNotification
  isLoading?: boolean
}

function SearchForm(props: Props) {
  const {
    setSearchParams,
    setPaginateParams,
    setPage,
    defaultSearchParams,
    isLoading = false,
  } = props
  React.useEffect(() => {
    const searchParams = getLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.NOTIFICATIONS
    )
    if (searchParams) {
      // リクエストで送るparamsのstate
      setSearchParams(searchParams)
      // formのvalue
      Object.keys(searchParams).forEach((key: keyof SearchNotification) => {
        setValue(key, searchParams[key])
      })
    }
  }, [])

  const [notificationTypeOptions, setNotificationTypeOptions] =
    React.useState<SelectOptions>({})

  // フォームオブジェクト
  const { handleSubmit, control, reset, getValues, setValue, watch } =
    useForm<SearchNotification>({
      mode: 'onSubmit',
      defaultValues: {
        notificationType: '',
        leftDays: undefined,
        includePastNotifications: false,
      },
    })

  // フォームsubmit時のコールバック
  const onSubmit = (data: SearchNotification) => {
    setSearchParams(data)
    setLocalStorageSearchParams(LOCAL_STORAGE_PAGE_KEY.NOTIFICATIONS, data)
  }

  const resetParams = () => {
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = 1

    setPaginateParams({ pageNumber, displayLimit: displayLimit })
    setPage(pageNumber)
    removeLocalStoragePageNumber(LOCAL_STORAGE_PAGE_KEY.NOTIFICATIONS)
    setSearchParams(defaultSearchParams)
    setLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.NOTIFICATIONS,
      defaultSearchParams
    )
  }

  React.useEffect(() => {
    fetchNotificationTypeOptions().then((response) => {
      const obj = Object.entries(response.data).reduce((acc, [key, values]) => {
        acc[key] = Object.values(values).map((value) => {
          return { label: value?.text, value: value?.value }
        })
        return acc
      }, {})
      setNotificationTypeOptions(obj)
    })
  }, [])

  const leftDaysOptions = [
    { value: 14, label: '14日以内' },
    { value: 30, label: '30日以内' },
    { value: 60, label: '60日以内' },
    { value: 90, label: '90日以内' },
  ]

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '90%' }}>
        <BackGroundGray>
          <Typography
            sx={{ marginBottom: '20px', fontWeight: 'bold' }}
            variant={'body1'}
          >
            条件で絞り込み
          </Typography>
          <CustomForm onSubmit={handleSubmit(onSubmit)}>
            <FlexBox>
              <SearchCompanyAndOffice
                companyKeyName={'companyId'}
                officeKeyName={'officeId'}
                control={control}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
              />
            </FlexBox>
            <FlexBox>
              <CustomLabel>
                {NOTIFICATIONS.LABELS.NOTIFICATION_TYPE}
                <Controller
                  name="notificationType"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      options={WithEmptyOption(
                        notificationTypeOptions?.notificationType
                      )}
                      value={notificationTypeOptions?.notificationType?.find(
                        (c) => c.value === value
                      )}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option.value)
                      }
                      placeholder="全て"
                    />
                  )}
                />
              </CustomLabel>
            </FlexBox>

            <FlexBox>
              <CustomLabel>
                {NOTIFICATIONS.LABELS.DATE}
                <Controller
                  name="leftDays"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      options={WithEmptyOption(leftDaysOptions)}
                      value={leftDaysOptions.find((c) => c.value === value)}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option.value)
                      }
                      placeholder="全て"
                    />
                  )}
                />
              </CustomLabel>
            </FlexBox>
            <div>
              <CustomLabel>
                <Controller
                  name={'includePastNotifications'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <label>
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={() => onChange(!value)}
                      />
                      過去のアラートを含める
                    </label>
                  )}
                />
              </CustomLabel>
            </div>

            <ApplyButton pattern="inRow" type="submit" disabled={isLoading}>
              絞り込む
            </ApplyButton>
            <ResetButton
              onClick={() => {
                reset()
                resetParams()
              }}
              disabled={isLoading}
            >
              <Typography>リセット</Typography>
            </ResetButton>
          </CustomForm>
        </BackGroundGray>
      </Box>
    </Box>
  )
}

export default SearchForm
