import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BackGroundGray } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomForm,
  ResetButton,
  CustomSelect,
  WithEmptyOption,
  WithDescriptionTooltipIcon,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Box, Typography } from '@mui/material'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { LOCAL_STORAGE_PAGE_KEY, USER_EDUCATIONS } from 'commons/constants'
import { fetchDriverList } from 'components/apis/drivers'
import { yearRangeFrom2000 } from 'commons/date'
import { useQuery } from '@tanstack/react-query'
import SearchCompanyAndOffice from 'components/atoms/ReactHookFormPartials/SearchForm/SearchCompanyAndOffice'
import {
  getLocalStorageSearchParams,
  setLocalStorageSearchParams,
  getLocalStorageDisplayLimit,
  removeLocalStoragePageNumber,
} from 'commons/table'

interface Props {
  setSearchParams: React.Dispatch<React.SetStateAction<SearchUserEducation>>
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  defaultSearchParams: SearchUserEducation
  isLoading?: boolean
}

export const PassingDescription = () => {
  return (
    <WithDescriptionTooltipIcon
      text={`2023/7/2以前の受講履歴は全て「合格」として扱われます`}
    />
  )
}

function SearchForm(props: Props) {
  const {
    setSearchParams,
    setPaginateParams,
    setPage,
    defaultSearchParams,
    isLoading = false,
  } = props
  React.useEffect(() => {
    const searchParams = getLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_ATTENDANCES
    )
    if (searchParams) {
      // リクエストで送るparamsのstate
      setSearchParams(searchParams)
      // formのvalue
      Object.keys(searchParams).forEach((key: keyof SearchUserEducation) => {
        setValue(key, searchParams[key])
      })
    }
  }, [LOCAL_STORAGE_PAGE_KEY.EDUCATION_ATTENDANCES])

  // 受講状況の選択肢
  const attendSelectOptions: SelectOption[] = [
    { value: 'attended', label: '受講のみ' },
    { value: 'not_attended', label: '未受講のみ' },
  ]
  // 合否の選択肢
  const passedSelectOptions: SelectOption[] = [
    { value: 'passed', label: '合格' },
    { value: 'fail', label: '未合格' },
  ]

  // フォームオブジェクト
  const { handleSubmit, control, reset, getValues, setValue, watch } =
    useForm<SearchUserEducation>({
      mode: 'onSubmit',
      defaultValues: defaultSearchParams,
    })

  // フォームsubmit時のコールバック
  const onSubmit = (data: SearchUserEducation) => {
    setSearchParams(data)
    setLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_ATTENDANCES,
      data
    )
  }

  const { data: drivers } = useQuery(
    [`education_attendances/SearchForm`, 'driver_list'],
    () =>
      fetchDriverList().then((res) =>
        Object.values(res.data.drivers).map((value: DriverSelectOption) => {
          return { value: String(value.id), label: value.fullName }
        })
      )
  )

  const resetParams = () => {
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = 1

    setPaginateParams({ pageNumber, displayLimit: displayLimit })
    setPage(pageNumber)
    removeLocalStoragePageNumber(LOCAL_STORAGE_PAGE_KEY.EDUCATION_ATTENDANCES)
    setSearchParams(defaultSearchParams)
    setLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_ATTENDANCES,
      defaultSearchParams
    )
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '90%' }}>
        <BackGroundGray>
          <Typography
            sx={{ marginBottom: '20px', fontWeight: 'bold' }}
            variant={'body1'}
          >
            条件で絞り込み
          </Typography>
          <CustomForm onSubmit={handleSubmit(onSubmit)}>
            <FlexBox>
              <SearchCompanyAndOffice
                companyKeyName={'companyId'}
                officeKeyName={'officeId'}
                control={control}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
              />
            </FlexBox>
            <FlexBox>
              <CustomLabel>
                {USER_EDUCATIONS.LABELS.USER_NAME}
                <Controller
                  name="driverId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      options={WithEmptyOption(drivers)}
                      value={drivers?.find((c) => c.value === value)}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option.value)
                      }
                      placeholder="全て"
                    />
                  )}
                />
              </CustomLabel>
            </FlexBox>
            <FlexBox>
              <CustomLabel>
                <FlexBox>受講年度</FlexBox>
                <Controller
                  name="attendedYear"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      options={yearRangeFrom2000}
                      value={yearRangeFrom2000?.find((c) => c.value === value)}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option.value)
                      }
                      placeholder=""
                    />
                  )}
                />
              </CustomLabel>
            </FlexBox>

            <FlexBox flexWrap="wrap">
              <div>
                <CustomLabel>
                  受講状況
                  <Controller
                    name="attendStatus"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CustomSelect
                        options={WithEmptyOption(attendSelectOptions)}
                        value={attendSelectOptions?.find(
                          (c) => c.value === value
                        )}
                        onChange={(option: ReactSelectOptionProps) =>
                          onChange(option.value)
                        }
                        placeholder="全て"
                      />
                    )}
                  />
                </CustomLabel>
              </div>
              <div>
                <CustomLabel>
                  合否(一度でも合格している場合は合格として扱います)
                  <FlexBox flexWrap="wrap">
                    <Controller
                      name="isPassed"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <CustomSelect
                          options={WithEmptyOption(passedSelectOptions)}
                          value={passedSelectOptions?.find(
                            (c) => c.value === value
                          )}
                          onChange={(option: ReactSelectOptionProps) =>
                            onChange(option.value)
                          }
                          placeholder="全て"
                        />
                      )}
                    />
                    <PassingDescription />
                  </FlexBox>
                </CustomLabel>
              </div>
            </FlexBox>

            <FlexBox flexWrap="wrap">
              <ApplyButton pattern="inRow" type="submit" disabled={isLoading}>
                絞り込む
              </ApplyButton>
              <ResetButton
                sx={{ marginRight: '1rem', marginBottom: '8px' }}
                onClick={() => {
                  reset()
                  resetParams()
                }}
                disabled={isLoading}
              >
                <Typography>リセット</Typography>
              </ResetButton>
            </FlexBox>
          </CustomForm>
        </BackGroundGray>
      </Box>
    </Box>
  )
}

export default SearchForm
