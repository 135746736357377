import * as React from 'react'
import { Button } from '@mui/material'

type ModalProps = {
  onClose: () => void
  onCancel: () => void
}

function ConfirmCloseDialog({ onClose, onCancel }: ModalProps) {
  return (
    <div>
      まだ学習中ですが、中断してもよろしいですか？
      <br />
      <Button onClick={onClose}>中断する</Button>
      <Button onClick={onCancel}>再生する</Button>
    </div>
  )
}

export default ConfirmCloseDialog
