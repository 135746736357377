import * as React from 'react'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import { ADMIN_MENUS } from 'commons/menus'
import { useErrorHandler } from 'react-error-boundary'
import { fetchEducationSetting } from 'components/apis/education_settings'
import EducationSettingsForm from 'components/organisms/education_settings/Form'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'

function AdminEducationSettings() {
  const [passingScore, setPassingScore] =
    React.useState<UserEducationSettings>(null)
  const [loading, setLoading] = React.useState<boolean>(true)
  const handleError = useErrorHandler()

  //点数を保存するformを生やす

  //点数を取得する
  React.useEffect(() => {
    fetchEducationSetting()
      .then((response) => {
        setPassingScore(response.data)
      })
      .catch((e) => {
        handleError(e.response.status)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  //TODOここをFormに切り分けたい
  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.EDUCATIONS.SETTINGS ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>{ADMIN_MENUS.educations.children.education_settings.title}</h1>
      {loading ? (
        <CircularProgressBox />
      ) : (
        <EducationSettingsForm
          data={passingScore}
          setPassingScore={setPassingScore}
        />
      )}
    </PageWrapper>
  )
}

export default AdminEducationSettings
