import * as React from 'react'
import {
  CustomForm,
  CustomInputShort,
  CustomLabel,
  CustomRadioGroup,
  CustomRadioButton,
} from 'components/atoms/FormComponents'
import NumberFormat from 'components/organisms/NumberFormat'
import { FlexBox } from 'components/atoms/BoxComponents'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import {
  BackGroundWhite,
  BackGroundGray,
} from 'components/atoms/BackGroundComponents'
import { Controller } from 'react-hook-form'
import { styled } from '@mui/styles'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { toastOnError, toastOnSuccess } from 'commons/toaster'
import { useErrorHandler } from 'react-error-boundary'
import { updateEducationSetting } from 'components/apis/education_settings'
import { UserContext } from 'providers/UserProvider'
import { ENUMS } from 'commons/enums'
import { palette } from 'components/theme'

const FieldDescription = styled('p')({
  fontSize: '14px',
  color: 'black',
})

export default function EducationSettingsForm(props: {
  data: UserEducationSettings
  setPassingScore: React.Dispatch<React.SetStateAction<UserEducationSettings>>
}) {
  const defaultValues = props.data
  const setPassingScore = props.setPassingScore
  const handleError = useErrorHandler()
  const user = React.useContext(UserContext)

  const { register, getValues, handleSubmit, control } =
    useForm<UserEducationSettings>({
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
      defaultValues: defaultValues,
    })

  const formParamsData = (data) => {
    const params = {
      company_id: user.company_id,
      ...data,
    }
    return params
  }

  const onSubmit = (data) => {
    const params = formParamsData(data)
    updateEducationSetting(params)
      .then(() => {
        toastOnSuccess('更新完了')
        setPassingScore(data.passingScore)
      })
      .catch((e) => {
        switch (e.response.status) {
          case 422:
            toastOnError(e.response.data.errors)
            break
          default:
            handleError(e.response.status)
        }
      })
  }

  const disableSetting =
    user.role !== 'system_admin' || !getValues('isPresentAnnualStartMonth')

  return (
    <>
      {user.role === 'office_admin' && (
        <BackGroundGray>
          事業者責任者は閲覧のみ可能です。変更したい場合はシステム管理者にご連絡ください。
        </BackGroundGray>
      )}
      <Box sx={{ marginBottom: '20px' }} />
      <CustomForm onSubmit={handleSubmit(onSubmit)}>
        <BackGroundWhite>
          <CustomLabel>
            WEBテスト合格点
            <Controller
              name="passingScore"
              control={control}
              render={({ field }) => (
                <NumberFormat
                  {...field}
                  customInput={CustomInputShort}
                  disabled={user.role === 'system_admin' ? false : true}
                />
              )}
            />
          </CustomLabel>
          <CustomLabel>
            回答の選択肢シャッフル(2回目以降)
            <CustomRadioGroup>
              {Object.entries(ENUMS.EDUCATION_SETTINGS.IS_SHUFFLE).map(
                ([key, obj]) => (
                  <React.Fragment key={key}>
                    <CustomRadioButton
                      {...register('isShuffle')}
                      id={`isShuffle_${obj.value}`}
                      value={obj.value}
                      type="radio"
                      disabled={user.role === 'system_admin' ? false : true}
                    />
                    <label htmlFor={`isShuffle_${obj.value}`}>
                      {obj.label}
                    </label>
                  </React.Fragment>
                )
              )}
            </CustomRadioGroup>
          </CustomLabel>
          <CustomLabel>
            テストの受講制限：動画を見てから30日以内
            <CustomRadioGroup>
              {Object.entries(ENUMS.EDUCATION_SETTINGS.IS_SHUFFLE).map(
                ([key, obj]) => (
                  <React.Fragment key={key}>
                    <CustomRadioButton
                      {...register('isAfterVideo')}
                      id={`isAfterVideo_${obj.value}`}
                      value={obj.value}
                      type="radio"
                      disabled={user.role === 'system_admin' ? false : true}
                    />
                    <label htmlFor={`isAfterVideo_${obj.value}`}>
                      {obj.label}
                    </label>
                  </React.Fragment>
                )
              )}
            </CustomRadioGroup>
          </CustomLabel>
          <CustomLabel>
            受講後のコメント登録
            <span style={{ fontSize: '14px' }}>
              <a
                href={`/admin/companies/${user.company_id}/edit/`}
                target="_blank"
                rel="noreferrer"
              >
                会社管理
              </a>
              にて年度開始月を設定している場合のみ有効にできます。
            </span>
            <CustomRadioGroup>
              {Object.entries(ENUMS.EDUCATION_SETTINGS.IS_COMMENTABLE).map(
                ([key, obj]) => (
                  <React.Fragment key={key}>
                    <CustomRadioButton
                      {...register('isCommentable')}
                      id={`isCommentable${obj.value}`}
                      value={obj.value}
                      type="radio"
                      disabled={disableSetting}
                    />
                    <label
                      htmlFor={`isCommentable${obj.value}`}
                      style={{
                        backgroundColor:
                          disableSetting &&
                          key === 'TRUE' &&
                          palette.disabled.main,
                      }}
                    >
                      {obj.label}
                    </label>
                  </React.Fragment>
                )
              )}
            </CustomRadioGroup>
          </CustomLabel>
          <CustomLabel>
            未来の受講項目の受講を制限
            <span style={{ fontSize: '14px' }}>
              <a
                href={`/admin/companies/${user.company_id}/edit/`}
                target="_blank"
                rel="noreferrer"
              >
                会社管理
              </a>
              にて年度開始月を設定している場合のみ有効にできます。
            </span>
            <CustomRadioGroup>
              {Object.entries(
                ENUMS.EDUCATION_SETTINGS.IS_DISABLED_FUTURE_ATTENDANCE
              ).map(([key, obj]) => (
                <React.Fragment key={key}>
                  <CustomRadioButton
                    {...register('isDisabledFutureAttendance')}
                    id={`isDisabledFutureAttendance${obj.value}`}
                    value={obj.value}
                    type="radio"
                    disabled={disableSetting}
                  />
                  <label
                    htmlFor={`isDisabledFutureAttendance${obj.value}`}
                    style={{
                      backgroundColor:
                        disableSetting &&
                        key === 'TRUE' &&
                        palette.disabled.main,
                    }}
                  >
                    {obj.label}
                  </label>
                </React.Fragment>
              ))}
            </CustomRadioGroup>
          </CustomLabel>
          <FieldDescription>
            <h2>注意点</h2>
            ・各設定は全事業所共通で適用されます。
            <br />
            ・設定の更新は、貴社のシステム管理者のみ可能です。
          </FieldDescription>
        </BackGroundWhite>
        {user.role === 'system_admin' && (
          <FlexBox justifyContent={'flex-end'} sx={{ marginTop: '8px' }}>
            <ApplyButton pattern="block" type="submit">
              保存する
            </ApplyButton>
          </FlexBox>
        )}
      </CustomForm>
    </>
  )
}
