import * as React from 'react'
import {
  TABLE_DEFAULT_ROWS,
  ADMIN_ROUTES,
  PAGE_TITLES,
  APPLICATION_NAME,
} from 'commons/constants'
import AdminAlcoholChecksTable from 'components/organisms/admin/alcohol_checks/Table'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { AddButton } from 'components/atoms/ButtonComponenets'
import { Box } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { UserContext } from 'providers/UserProvider'
import { toFormattedDate } from 'commons/functions'
import { useLocation } from 'react-router-dom'
import useAlcoholChecks from './hooks/useAlcoholChecks'

function AdminAlcoholChecksIndex() {
  const user = React.useContext(UserContext)
  const defaultSearchParams = {
    officeId: user.officeId,
    createdAt: toFormattedDate(new Date()),
  }
  const [searchParams, setSearchParams] =
    React.useState<SearchAlcoholCheck>(defaultSearchParams)
  const [paginateParams, setPaginateParams] = React.useState<PaginateParams>({
    pageNumber: 1,
    displayLimit: TABLE_DEFAULT_ROWS,
  })
  const queryParams = useLocation().search
  const focusId = new URLSearchParams(queryParams).get('focus_id')

  const { alcoholChecksData, isLoadingAlcoholChecksData } = useAlcoholChecks(
    searchParams,
    paginateParams
  )

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.ALCOHOL_CHECKS.INDEX ?? APPLICATION_NAME}</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1>アルコールチェックの記録一覧</h1>
        <AddButton href={ADMIN_ROUTES.ALCOHOL_CHECKS_NEW}>
          アルコールチェックの新規作成
        </AddButton>
      </Box>
      <AdminAlcoholChecksTable
        data={alcoholChecksData?.data}
        totalCount={alcoholChecksData?.totalCount}
        setSearchParams={setSearchParams}
        paginateParams={paginateParams}
        setPaginateParams={setPaginateParams}
        defaultSearchParams={defaultSearchParams}
        focusId={parseInt(focusId)}
        isLoading={isLoadingAlcoholChecksData}
      />
    </PageWrapper>
  )
}

export default AdminAlcoholChecksIndex
