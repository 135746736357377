import * as React from 'react'
import {
  Control,
  Controller,
  UseFormWatch,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form'
import { CustomLabel, CustomSelect } from 'components/atoms/FormComponents'
import {
  fetchAllCompanies,
  fetchVariableOffices,
} from 'components/apis/companies'
import { UserContext } from 'providers/UserProvider'
import { useQuery } from '@tanstack/react-query'
import { FlexBox } from 'components/atoms/BoxComponents'

type CompanyAndOfficeSelectProps = {
  companyKeyName: string
  officeKeyName: string
  control: Control<any>
  getValues: UseFormGetValues<any>
  setValue: UseFormSetValue<any>
  watch: UseFormWatch<any>
}

export default function CompanyAndOfficeSelect(
  props: CompanyAndOfficeSelectProps
) {
  const user = React.useContext(UserContext)
  const { companyKeyName, officeKeyName, control, getValues, setValue, watch } =
    props
  const enabled = user.isXmileAdmin || user.isGroupParentCompanyAdmin

  const { data: companyData, isLoading: isLoadingCompany } = useQuery(
    ['companies'],
    () => fetchAllCompanies().then((res) => res.data),
    { enabled: enabled }
  )

  const {
    data: officeData,
    isLoading: isLoadingOffice,
    refetch: officeRefetch,
  } = useQuery(['variableOffices'], () =>
    fetchVariableOffices(
      enabled ? getValues(companyKeyName) : user.company_id
    ).then((res) => res.data)
  )

  React.useEffect(() => {
    officeRefetch()
  }, [watch(companyKeyName), isLoadingCompany])

  // XMile管理者の場合は会社、事業所のいずれかを取得中の場合は非表示
  if (enabled && (isLoadingCompany || isLoadingOffice)) {
    return null
    // XMile管理者以外の場合は事業所を取得中の場合は非表示
  } else if (user.admin && isLoadingOffice) {
    return null
  }

  const companyList = companyData?.companies
    .filter((company) => company.name != '　')
    .map((company: Company) => {
      return {
        label: company.name,
        value: company.id,
        selected: company.selected,
      }
    })

  const officeList = officeData?.offices.map((office: Office) => {
    return {
      label: office.name,
      value: office.id,
      selected: office.selected,
    }
  })

  const isAdminOrAssistant =
    user.role === 'system_admin' || user.isAssistantStaff

  const isAdminOrAssistantHaveOffices =
    isAdminOrAssistant && user.company.hasOffices

  const isReadingOfficesAdmin =
    user.isGroupParentCompanyAdmin || user.isXmileAdmin
  return (
    <FlexBox flexWrap="wrap">
      <div style={{ display: enabled ? 'block' : 'none' }}>
        <CustomLabel>
          会社
          <Controller
            name={companyKeyName}
            control={control}
            defaultValue={
              enabled
                ? companyList?.find((option) => option.selected === true)?.value
                : user.company_id
            }
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                options={companyList}
                value={companyList?.find((c) => c.value === value)}
                onChange={(option: ReactSelectOptionProps) => {
                  onChange(option.value)
                  setValue(officeKeyName, undefined)
                }}
              />
            )}
          />
        </CustomLabel>
      </div>

      <div
        style={{
          display:
            isAdminOrAssistantHaveOffices ||
            isReadingOfficesAdmin ||
            officeList.length > 1
              ? 'block'
              : 'none',
        }}
      >
        <CustomLabel>
          事業所
          <Controller
            name={officeKeyName}
            control={control}
            defaultValue={
              officeList?.find((option) => option.selected === true)?.value ||
              undefined
            }
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                options={officeList}
                value={officeList?.find((c) => c.value === value)}
                onChange={(option: ReactSelectOptionProps) => {
                  onChange(option.value)
                }}
                placeholder="未選択"
              />
            )}
          />
        </CustomLabel>
      </div>
    </FlexBox>
  )
}
