import * as React from 'react'
import { Controller } from 'react-hook-form'
import type { Control } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import { fetchEducations } from 'components/apis/educations'
import { FlexBox } from 'components/atoms/BoxComponents'
import { CustomLabel, CustomSelect } from 'components/atoms/FormComponents'

type EducationMaterialSelectProps = {
  educationKeyName: string
  control: Control<any>
}

export default function EducationMaterialSelect(
  props: EducationMaterialSelectProps
) {
  const { educationKeyName, control } = props

  const { data: educationData, isLoading } = useQuery(['educations'], () =>
    fetchEducations().then((res) => res.data)
  )

  if (isLoading) {
    return null
  }

  const educationMaterialList = educationData?.educations
    .filter((edu) => edu.title != '　')
    .map((education: Education) => {
      return {
        label: `${education.id}. ${education.title}`,
        value: education.id,
      }
    })

  return (
    <FlexBox flexWrap="wrap">
      <CustomLabel>
        法定項目
        <Controller
          name={educationKeyName}
          control={control}
          defaultValue={1}
          render={({ field: { onChange, value } }) => (
            <CustomSelect
              options={educationMaterialList}
              value={educationMaterialList?.find((c) => c.value === value)}
              onChange={(option: ReactSelectOptionProps) => {
                onChange(option.value)
              }}
              styles={{
                menuList: (provided) => ({ ...provided, maxHeight: 220 }),
              }}
            />
          )}
        />
      </CustomLabel>
    </FlexBox>
  )
}
