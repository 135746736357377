import * as React from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material'
import { Close } from '@mui/icons-material'

type Props = {
  title: string
  children: React.ReactNode
  changeDialogStatus: () => void
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export default function BootstrapDialog({
  title,
  children,
  changeDialogStatus,
}: Props) {
  const handleClose = () => {
    changeDialogStatus()
  }

  return (
    <StyledDialog
      onClose={handleClose}
      maxWidth="xs"
      aria-labelledby="customized-dialog-title"
      open={true}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
        style={{ textAlign: 'center' }}
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers sx={{ maxHeight: 520, minWidth: 300 }}>
        {children}
      </DialogContent>
    </StyledDialog>
  )
}
