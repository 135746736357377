import { AxiosResponse } from 'axios'
import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import {
  addParamPrefix,
  camelCaseKeysToUnderscore,
  convertValueToInt,
} from 'commons/functions'

export function fetchVehicles(
  searchParams?: SearchVehicle,
  paginateParams?: PaginateParams
): Promise<
  AxiosResponse<{
    vehicles: Vehicle[]
    totalCount: number
    vehicleIds: number[]
  }>
> {
  const customSearchParams = searchParams
    ? addParamPrefix(searchParams, 'search')
    : {}
  const customPaginateParams = paginateParams
    ? addParamPrefix(paginateParams, 'pagination')
    : {}
  const params = camelCaseKeysToUnderscore({
    ...customSearchParams,
    ...customPaginateParams,
  })
  return axios.get(API_ENDPOINTS.VEHICLES, {
    params: params,
  })
}

export function getVehicle(id: number): Promise<AxiosResponse<Vehicle>> {
  return axios.get([API_ENDPOINTS.VEHICLES, id].join(''))
}

export function fetchVehicleOptions() {
  return axios.get(API_ENDPOINTS.VEHICLES_OPTIONS)
}

export function deleteVehicle(id: number) {
  return axios.delete([API_ENDPOINTS.VEHICLES, id].join(''))
}

export function updateVehicle(params: { data: Vehicle; formData: FormData }) {
  const headers = { 'Content-Type': 'multipart/form-data' }
  const newParams = camelCaseKeysToUnderscore(convertValueToInt(params.data))
  for (const key in newParams) {
    if (newParams[key] && newParams[key].length != 0) {
      params.formData.append(key, JSON.stringify(newParams[key]))
    }
  }
  return axios.post(API_ENDPOINTS.VEHICLES_UPDATE_ATTRIBUTES, params.formData, {
    headers: headers,
  })
}

export function importVehicle(formData) {
  const headers = { 'Content-Type': 'multipart/form-data' }
  return axios.post(API_ENDPOINTS.VEHICLES_CSV_IMPORT, formData, {
    headers: headers,
  })
}

export function fetchTrailers(): Promise<
  AxiosResponse<{ trailers: Vehicle[] }>
> {
  return axios.get(API_ENDPOINTS.VEHICLES_TRAILERS)
}

export function fetchVehicleList(searchParams?: {
  companyId?: number
  officeId?: number
}): Promise<AxiosResponse<{ vehicles: Vehicle[] }>> {
  const customSearchParams = searchParams
    ? addParamPrefix(searchParams, 'search')
    : {}
  const params = camelCaseKeysToUnderscore(customSearchParams)

  return axios.get([API_ENDPOINTS.VEHICLES, 'list'].join(''), {
    params: params,
  })
}

export function getVehicleCo2EmissionFactor(id: number) {
  return axios.get(
    [API_ENDPOINTS.VEHICLES, id, '/co2_emission_factor'].join('')
  )
}

export function exportVehiclesCsv(searchParams?: SearchVehicle) {
  const params = camelCaseKeysToUnderscore(
    addParamPrefix(searchParams, 'search')
  )
  return axios.get(API_ENDPOINTS.VEHICLES_CSV_EXPORT, {
    params: params,
  })
}

export function exportVehiclesPaymentHistoriesCsv(
  vehicleIds?: number[],
  startAt?: string,
  endAt?: string
) {
  const params = camelCaseKeysToUnderscore({
    ids: vehicleIds,
    startAt: startAt,
    endAt: endAt,
  })
  return axios.get(API_ENDPOINTS.VEHICLES_PAYMENT_HISTORIES_CSV_EXPORT, {
    params: params,
  })
}
