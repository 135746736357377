import * as React from 'react'
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  CircularProgress,
  styled,
} from '@mui/material'
import { ADMIN_ROUTES, LOCAL_STORAGE_PAGE_KEY } from 'commons/constants'
import {
  RecordNotFound,
  IdHeader,
  EnhancedTableToolbar,
  CustomPagination,
} from 'components/atoms/TableComponents'
import {
  getLocalStorageDisplayLimit,
  getLocalStoragePageNumber,
  setLocalStoragePageNumber,
  removeLocalStoragePageNumber,
} from 'commons/table'
import SearchForm from './SearchForm'
import { palette } from 'components/theme'
import { UserContext } from 'providers/UserProvider'
import { setLocalStorageDisplayLimit } from 'commons/table'
import { Order } from 'commons/array'

export default function AdminAlcoholChecksTable(props: {
  data: AdminAlcoholCheckTable[]
  totalCount: number
  paginateParams: PaginateParams
  setPaginateParams: React.Dispatch<
    React.SetStateAction<Record<string, unknown>>
  >
  setSearchParams: React.Dispatch<React.SetStateAction<SearchAlcoholCheck>>
  defaultSearchParams: SearchAlcoholCheck
  focusId?: number
  isLoading?: boolean
}) {
  const [order, setOrder] = React.useState<Order>('asc')
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(
    props.paginateParams.displayLimit
  )
  const rows = props.data
  const totalCount = props.totalCount

  React.useEffect(() => {
    //「ページあたりの行数」をloalStorageから取得してセット
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = getLocalStoragePageNumber(
      LOCAL_STORAGE_PAGE_KEY.ALCOHOL_CHECKS,
      true
    )
    const newPaginateParams = {
      pageNumber: pageNumber,
      displayLimit: displayLimit,
    }
    props.setPaginateParams(newPaginateParams)
    setRowsPerPage(displayLimit)
    setPage(pageNumber)
  }, [])

  return (
    <PlainTable
      {...props}
      order={order}
      setOrder={setOrder}
      page={page}
      setPage={setPage}
      rows={rows}
      rowsPerPage={rowsPerPage}
      totalCount={totalCount}
      setRowsPerPage={setRowsPerPage}
      searchForm={
        <SearchForm
          setSearchParams={props.setSearchParams}
          setPaginateParams={props.setPaginateParams}
          setPage={setPage}
          defaultSearchParams={props.defaultSearchParams}
          isLoading={props.isLoading}
        />
      }
    />
  )
}

type Props = {
  order: Order
  setOrder: React.Dispatch<React.SetStateAction<Order>>
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  rows: AdminAlcoholCheckTable[]
  rowsPerPage: number
  totalCount: number
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>
  children?: React.ReactNode | string
  searchForm?: React.ReactNode
  paginateParams: PaginateParams
  setPaginateParams: React.Dispatch<
    React.SetStateAction<Record<string, unknown>>
  >
  focusId?: number
  isLoading?: boolean
}

function PlainTable(props: Props) {
  const {
    page,
    setPage,
    rows,
    rowsPerPage,
    setRowsPerPage,
    totalCount,
    paginateParams,
    setPaginateParams,
    focusId,
    isLoading = false,
  } = props
  const [isOpen, setIsOpen] = React.useState(true)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
    setPaginateParams({
      ...paginateParams,
      pageNumber: newPage,
    })
    setLocalStoragePageNumber(LOCAL_STORAGE_PAGE_KEY.ALCOHOL_CHECKS, newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const count = parseInt(event.target.value, 10)
    const firstPage = 1
    setRowsPerPage(count)
    setPaginateParams({
      pageNumber: firstPage,
      displayLimit: count,
    })
    setPage(firstPage)
    setLocalStorageDisplayLimit(count)
    Object.values(LOCAL_STORAGE_PAGE_KEY).forEach((key) => {
      removeLocalStoragePageNumber(key)
    })
  }

  const customPagination = {
    rowsPerPage: rowsPerPage,
    handleChangeRowsPerPage: handleChangeRowsPerPage,
    totalCount: totalCount,
    page: page,
    handleChangePage: handleChangePage,
  }

  const user = React.useContext(UserContext)
  const headerColor = palette.background.tableHeaderCell.pale
  const headerLabels =
    user.isXmileAdmin || user.isGroupParentCompanyAdmin
      ? ['会社', '事業所', 'ドライバー', '計測結果']
      : ['事業所', 'ドライバー', '計測結果']

  const tableRows = rows
    ? Object.values(rows).map((row, index) => {
        const number = (page - 1) * rowsPerPage + index + 1
        return (
          <TableRow
            hover
            tabIndex={-1}
            key={index}
            sx={{
              cursor: 'pointer',
              backgroundColor: Object.values(row.alcoholChecks)
                .map((alcoholCheck) => alcoholCheck.id)
                .includes(focusId)
                ? palette.background.grey
                : 'white',
            }}
          >
            <IdHeader>{number}</IdHeader>
            {(user.isXmileAdmin || user.isGroupParentCompanyAdmin) && (
              <TableCell>{row.companyName}</TableCell>
            )}
            <TableCell>{row.officeName}</TableCell>
            <TableCell>{row.driverName}</TableCell>
            <TableCell>
              {row.alcoholChecks.length == 0 ? (
                <span>未提出</span>
              ) : (
                Object.values(row.alcoholChecks).map((alcoholCheck, i) => {
                  return (
                    <>
                      {i > 0 && <br />}
                      <a
                        style={{ marginRight: '10px' }}
                        key={i}
                        href={`${ADMIN_ROUTES.ALCOHOL_CHECKS_INDEX}${alcoholCheck.id}/`}
                      >
                        <b>業前: {alcoholCheck.resultBeforeWork}mg/L</b>
                        {alcoholCheck.createdTime}{' '}
                        <b>業後: {alcoholCheck.resultAfterWork}mg/L</b>
                        {alcoholCheck.updatedTime}
                      </a>
                    </>
                  )
                })
              )}
            </TableCell>
          </TableRow>
        )
      })
    : null

  return (
    <Box>
      <StyledPaper>
        <EnhancedTableToolbar isOpen={isOpen} setIsOpen={setIsOpen} />
        {isOpen && props.searchForm}
        {isLoading === true ? (
          <div style={{ padding: 16, textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : rows.length == 0 ? (
          <RecordNotFound />
        ) : (
          <>
            <CustomPagination {...customPagination} />
            <TableContainer sx={{ overflowX: 'scroll' }}>
              <Table sx={{ whiteSpace: 'nowrap' }}>
                <TableHead sx={{ backgroundColor: headerColor }}>
                  <TableRow>
                    <IdHeader color={headerColor}>No</IdHeader>
                    {headerLabels.map((label, index) => (
                      <TableCell key={index} align={'left'} padding={'normal'}>
                        {label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{tableRows}</TableBody>
              </Table>
            </TableContainer>
            <CustomPagination {...customPagination} />
          </>
        )}
      </StyledPaper>
    </Box>
  )
}

const StyledPaper = styled(Paper)({
  marginBottom: '12px',
})
