import { AxiosResponse } from 'axios'
import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { addParamPrefix, camelCaseKeysToUnderscore } from 'commons/functions'

export function fetchServiceStations(
  searchParams: SearchServiceStation,
  paginateParams: PaginateParams
): Promise<
  AxiosResponse<{
    serviceStations: ServiceStation[]
    totalCount: number
  }>
> {
  const customSearchParams = addParamPrefix(searchParams, 'search')
  const customPaginateParams = addParamPrefix(paginateParams, 'pagination')
  const params = camelCaseKeysToUnderscore({
    ...customSearchParams,
    ...customPaginateParams,
  })

  return axios.get(API_ENDPOINTS.SERVICE_STATIONS, {
    params: params,
  })
}

export function getServiceStation(id: number) {
  return axios.get([API_ENDPOINTS.SERVICE_STATIONS, id].join(''))
}

export function deleteServiceStation(id: number) {
  return axios.delete([API_ENDPOINTS.SERVICE_STATIONS, id].join(''))
}

export function createServiceStation(params: ServiceStation) {
  return axios.post(
    API_ENDPOINTS.SERVICE_STATIONS,
    camelCaseKeysToUnderscore(params)
  )
}

export function updateServiceStation(params: ServiceStation) {
  return axios.patch(
    [API_ENDPOINTS.SERVICE_STATIONS, params.id].join(''),
    camelCaseKeysToUnderscore(params)
  )
}

export function fetchServiceStationList(): Promise<
  AxiosResponse<{ serviceStations: ReactSelectOptionProps }>
> {
  return axios.get([API_ENDPOINTS.SERVICE_STATIONS, 'list'].join(''))
}
