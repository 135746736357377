import * as React from 'react'
import { styled } from '@mui/styles'
import { Box, Grid } from '@mui/material'
import { TextBold } from 'components/atoms/TextComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { palette } from 'components/theme'
import Button from '@mui/material/Button'
import EducationVideoPlayerModal from 'components/organisms/educations/EducationVideoPlayerModal'
import { UserContext } from 'providers/UserProvider'
import CommonAccountModal from 'components/organisms/educations/CommonAccountModal'
import MessageIcon from '@mui/icons-material/Message'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { HEAD_TEXTS } from 'commons/constants'

type Props = {
  education: Education
  isShowComment?: boolean
}

export default function VideoContent({
  education,
  isShowComment = true,
}: Props) {
  const user: User = React.useContext(UserContext)
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
  const [isCommonAccountModalOpen, setIsCommonAccountModalOpen] =
    React.useState<boolean>(false)
  const [queue, setQueue] = React.useState<{ key: string }[]>([])

  const EducationContent = styled('div')({
    height: '100%',
    width: 'auto',
    left: '0px',
    top: '0px',
    borderRadius: '12px',
    padding: '24px',
  })

  const Thumbnail = styled('img')({
    width: '100%',
    height: '100%',
    minHeight: '100px',
    objectFit: 'contain',
  })

  const ThumbnailEmpty = styled('div')({
    width: '100%',
    height: '100%',
    background: palette.background.grey,
    minHeight: '160px',
    objectFit: 'contain',
  })

  const OverLay = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'white',
    opacity: 0.6,
  })

  const VideoBackGround = styled(Box)({
    boxSizing: 'border-box',
    borderRadius: '6px',
    width: '100%',
    height: '100%',
    border: '1px solid #E6E7E8',
    padding: '24px 32px',
  })

  const ViewedText = styled('div')({
    textAlign: 'center',
  })

  function showVideoModal() {
    if (user.isDriverCommonAccount) {
      setQueue([
        {
          key: 'EducationVideosIndex.onClickVideo',
        },
      ])
      setIsCommonAccountModalOpen(true)
    } else {
      setIsModalOpen(true)
    }
  }

  function onComplete() {
    switch (queue[0].key) {
      case 'EducationVideosIndex.onClickVideo':
        {
          setIsModalOpen(true)
          setIsCommonAccountModalOpen(false)
        }
        break
      default:
        break
    }
  }

  function onClose() {
    setQueue([])
    return true
  }

  function navigateToComment(education: Education) {
    const commentUrl = user.admin
      ? `/admin/educations/${education.id}/comments/new`
      : `/educations/${education.id}/comments/new`
    window.open(commentUrl, '_blank')
  }

  return (
    <>
      <Grid item xs={12} sm={4} position="relative">
        <EducationContent>
          <VideoBackGround
            sx={{
              background: education.viewed ? palette.disabled.main : 'white',
            }}
          >
            <FlexBox
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
              height="100%"
            >
              <Box mb={2}>
                <TextBold>{education.title}</TextBold>
              </Box>
              <Button onClick={() => showVideoModal()}>
                {education.videoThumbnailUrl ? (
                  <Thumbnail src={education.videoThumbnailUrl} />
                ) : (
                  <ThumbnailEmpty />
                )}
              </Button>
              {isShowComment && education.isCommentable && (
                <Box mb={2}>
                  <ApplyButton
                    sx={{ width: '150px' }}
                    startIcon={<MessageIcon />}
                    onClick={() => navigateToComment(education)}
                  >
                    {HEAD_TEXTS.COMMENT}
                  </ApplyButton>
                </Box>
              )}
            </FlexBox>
            {education.viewed && <ViewedText>視聴済み</ViewedText>}
          </VideoBackGround>
        </EducationContent>
        {!education.videoUrl && <OverLay></OverLay>}
      </Grid>
      <CommonAccountModal
        isModalOpen={isCommonAccountModalOpen}
        setIsModalOpen={setIsCommonAccountModalOpen}
        onComplete={onComplete}
        onClose={onClose}
      />
      <EducationVideoPlayerModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        education={education}
      />
    </>
  )
}
