import * as React from 'react'
import {
  CustomDateSelect,
  CustomLabel,
  CustomTextArea,
  ErrorMessage,
} from 'components/atoms/FormComponents'
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form'
import { DRIVERS, VIOLATION_HISTORIES } from 'commons/constants'
import { BackGroundPalePink } from 'components/atoms/BackGroundComponents'
import { DeleteButton } from '../../../../../atoms/ButtonComponenets'
import { styled } from '@mui/styles'
import { formatCustomDateErrorMessage } from 'commons/errorMessage'

type ViolationHistoryCardProps = {
  register: UseFormRegister<Driver>
  setValue: UseFormSetValue<Driver>
  control: Control<Driver>
  errors: FieldErrors<Driver>
  appendEmptyItem: () => void
  deleteItem: (index: number) => void
  index: number
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>
}

const ViolationHistoryCard: React.FC<ViolationHistoryCardProps> = (props) => {
  const CardTitle = styled('p')({
    fontWeight: 'bold',
  })

  const customDateErrorMessage = React.useCallback(() => {
    return formatCustomDateErrorMessage({
      year: props.errors?.violationHistories?.[props.index].violationYear,
      month: props.errors?.violationHistories?.[props.index].violationMonth,
      day: props.errors?.violationHistories?.[props.index].violationDay,
    })
  }, [props.errors?.violationHistories?.[props.index]])

  return (
    <BackGroundPalePink sx={{ marginBottom: '30px' }}>
      <CardTitle>
        {DRIVERS.LABELS.VIOLATION_HISTORY}
        {props.index + 1}
      </CardTitle>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VIOLATION_HISTORIES.LABELS.VIOLATION_DATE}
        <CustomDateSelect
          formKeys={{
            year: `violationHistories.${props.index}.violationYear`,
            month: `violationHistories.${props.index}.violationMonth`,
            day: `violationHistories.${props.index}.violationDay`,
          }}
          control={props.control}
          required
        />
        <ErrorMessage>{customDateErrorMessage()}</ErrorMessage>
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {VIOLATION_HISTORIES.LABELS.DESCRIPTION}
        <CustomTextArea
          sx={{ marginTop: '4px', height: '200px' }}
          {...props.register(`violationHistories.${props.index}.description`)}
        />
      </CustomLabel>
      <DeleteButton onClick={() => props.deleteItem(props.index)}>
        削除
      </DeleteButton>
    </BackGroundPalePink>
  )
}

export default ViolationHistoryCard
