import * as React from 'react'
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormGetValues,
} from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  ConvertibleCustomDateSelect,
  ConvertibleCustomTextArea,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { DRIVERS, CAREERS } from 'commons/constants'
import { Box } from '@mui/material'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { useNavigate } from 'react-router-dom'

type CareerProps = {
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
  handleModeChange: (name: DriverFormModeMapKey, mode: FormMode) => void
}

const Career = (props: CareerProps) => {
  const navigate = useNavigate()

  const reload = () => {
    if (
      window.confirm('保存していないデータは削除されます。よろしいですか？')
    ) {
      navigate(0)
    }
  }

  return (
    <BackGroundWhite>
      <FlexBox
        justifyContent="space-between"
        flexWrap="wrap"
        marginBottom="16px"
      >
        <HeadingText>{DRIVERS.LABELS.CAREER}</HeadingText>
        <Box>
          {/* 一時的にコメントアウト */}
          {/* {props.mode == 'show' && (
            <EditButtonOutlined
              onClick={() => {
                props.handleModeChange('career', 'edit')
              }}
            >
              編集
            </EditButtonOutlined>
          )} */}
          {props.mode == 'edit' && (
            <FlexBox>
              <ApplyButton pattern="marginRight8" type="submit">
                保存する
              </ApplyButton>
              <CancelButtonOutlined onClick={reload}>
                キャンセル
              </CancelButtonOutlined>
            </FlexBox>
          )}
        </Box>
      </FlexBox>
      <FlexBox alignItems="center" flexWrap="wrap">
        {[CAREERS.LABELS.JOINED_DATE, CAREERS.LABELS.SELECTED_DRIVER_DATE].map(
          (title, i) => {
            return (
              <CustomLabel sx={{ marginRight: '32px' }} key={`career-${i}`}>
                {title}
                <ConvertibleCustomDateSelect
                  mode={props.mode}
                  formKeys={{
                    year: `careers.${i}.recordedYear`,
                    month: `careers.${i}.recordedMonth`,
                    day: `careers.${i}.recordedDay`,
                  }}
                  control={props.control}
                  getValues={props.getValues}
                />
              </CustomLabel>
            )
          }
        )}
      </FlexBox>
      {[CAREERS.LABELS.RETIRED_DATE, CAREERS.LABELS.DIED_DATE].map(
        // 変数iをインデックスとして使用すると、（0: 入社日）と（1: 運転者選定日）が参照されてしまうため、（2: 退職日）と（3: 死亡日）が参照されるように+2しています。
        (title, i) => {
          return (
            <Box key={`career-${i}`}>
              <CustomLabel sx={{ marginBottom: '0px' }}>
                {title}
                <ConvertibleCustomDateSelect
                  mode={props.mode}
                  formKeys={{
                    year: `careers.${i + 2}.recordedYear`,
                    month: `careers.${i + 2}.recordedMonth`,
                    day: `careers.${i + 2}.recordedDay`,
                  }}
                  control={props.control}
                  getValues={props.getValues}
                />
              </CustomLabel>
              <CustomLabel>
                {CAREERS.LABELS.REASON}
                <ConvertibleCustomTextArea
                  mode={props.mode}
                  formKey={`careers.${i + 2}.reason`}
                  register={props.register}
                  getValues={props.getValues}
                />
              </CustomLabel>
            </Box>
          )
        }
      )}
    </BackGroundWhite>
  )
}

export default Career
