import * as React from 'react'
import { styled } from '@mui/styles'
import { palette } from 'components/theme'

type Props = {
  children?: React.ReactNode | string
}

export function TextBold(props: Props) {
  const TextBoldElement = styled('p')({
    fontWeight: 'bold',
    margin: 0,
  })
  return <TextBoldElement>{props.children}</TextBoldElement>
}

export function TextGray(props: Props) {
  const TextGrayElement = styled('p')({
    fontWeight: 'bold',
    color: palette.text.gray.pale,
  })
  return <TextGrayElement>{props.children}</TextGrayElement>
}

export function TextBorderBottom(props: Props) {
  const TextGrayElement = styled('p')({
    fontWeight: 'bold',
    color: palette.text.gray.pale,
  })
  return <TextGrayElement>{props.children}</TextGrayElement>
}

export function NotCreatedMessage(text: string) {
  return <label>{`作成した${text}はありません。`}</label>
}
export const ParagraphGroup = styled('p')({
  margin: '4em 0px',
})

export const TextRed = styled('label')({
  color: palette.error.main,
})

type ValueTextProps = {
  value: string | number | boolean | Date
  unitText?: string
}

export const ValueText: React.FC<ValueTextProps> = (props) => {
  const unitText = props.unitText ? props.unitText : ''
  return (
    <ValueLabel>
      {props.value && String(props.value).indexOf('null') == -1
        ? `${props.value}${unitText}`
        : '未入力'}
    </ValueLabel>
  )
}

export const ValueNumber: React.FC<{ value: number | null }> = (props) => {
  return <ValueLabel>{props.value ? `${props.value}` : 0}</ValueLabel>
}

const ValueLabel = styled('label')({
  color: palette.text.black.main,
  whiteSpace: 'pre-wrap',
})

export const ValueTextMoney: React.FC<{ value: string | number }> = (props) => {
  return (
    <ValueLabel>
      {Number(props.value) > 0
        ? `¥${Number(props.value).toLocaleString()}`
        : '¥0'}
    </ValueLabel>
  )
}
