import * as React from 'react'
import { styled } from '@mui/styles'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { fetchEducationMonthlyVideos } from 'components/apis/educations'
import { Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import VideoContent from 'components/organisms/educations/VideoContent'

export const ThisMonthVideoComponent = () => {
  const Title = styled('p')({
    fontSize: '25px',
    color: '#0050B2',
    fontWeight: 'bold',
    margin: '0 auto',
  })
  const { data: educationVideos } = useQuery(
    [`educations/videos`, 'education_videos'],
    () => fetchEducationMonthlyVideos().then((res) => res.data.educationVideos)
  )

  return (
    <>
      {educationVideos?.length > 0 && (
        <BackGroundWhite smallPadding={true}>
          <div style={{ textAlign: 'center' }}>
            <Title>今月の動画</Title>
          </div>
          <Grid container spacing={1} justifyContent="center">
            {educationVideos.map((education: Education, index: number) => {
              return (
                <React.Fragment key={index}>
                  <VideoContent education={education} />
                </React.Fragment>
              )
            })}
          </Grid>
        </BackGroundWhite>
      )}
    </>
  )
}
