import * as React from 'react'
import { Box } from '@mui/material'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { ADMIN_MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES, API_ENDPOINTS } from 'commons/constants'
import { CustomLabel, CustomSelect } from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { yearRangeFrom2000 } from 'commons/date'
import { Controller, useForm } from 'react-hook-form'
import CompanyAndOfficeSelect from 'components/atoms/ReactHookFormPartials/InputForm/CompanyAndOfficeSelect'
import { findByYear } from 'components/apis/educationAnnualPlans'
import { ADMIN_ROUTES } from 'commons/constants'
import { UserContext } from 'providers/UserProvider'

function AdminEducationExportCsvIndex() {
  const user = React.useContext(UserContext)
  const [educationAnnualPlan, setEducationAnnualPlan] = React.useState(null)
  // フォームオブジェクト
  const { control, getValues, setValue, watch } = useForm<SearchUserEducation>({
    mode: 'onSubmit',
    defaultValues: {
      companyId: user.company_id,
      officeId: user.office_id,
      attendedYear: user.company.thisYear || new Date().getFullYear(),
    },
  })

  const exportUrl = () => {
    let url =
      API_ENDPOINTS.USER_EDUCATION_CSV_EXPORT +
      '?attended_year=' +
      getValues('attendedYear')
    getValues('companyId') && (url += `&company_id=${getValues('companyId')}`)
    getValues('officeId') && (url += `&office_id=${getValues('officeId')}`)
    return url
  }

  const exportable = educationAnnualPlan && educationAnnualPlan.yearStartDate

  React.useEffect(() => {
    const params = {
      companyId: getValues('companyId'),
      officeId: getValues('officeId'),
      year: getValues('attendedYear'),
    }
    findByYear(params).then((res) => setEducationAnnualPlan(res.data))
  }, [watch('companyId'), watch('officeId'), watch('attendedYear')])

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.EDUCATIONS.EXPORT_CSV ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>{ADMIN_MENUS.educations.children.education_export_csv.title}</h1>
      <Box sx={{ marginTop: '30px' }}>
        <BackGroundWhite>
          <Box sx={{ maxWidth: '400px' }}>
            <CompanyAndOfficeSelect
              companyKeyName={'companyId'}
              officeKeyName={'officeId'}
              control={control}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
            />
          </Box>
          <CustomLabel>
            <FlexBox>受講年度</FlexBox>
            <Controller
              name="attendedYear"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  options={yearRangeFrom2000}
                  value={yearRangeFrom2000?.find((c) => c.value === value)}
                  onChange={(option: ReactSelectOptionProps) =>
                    onChange(option.value)
                  }
                  placeholder=""
                  menuPortalTarget={document.body}
                />
              )}
            />
          </CustomLabel>
          {!exportable && (
            <Box sx={{ marginBottom: '20px' }}>
              <span>
                指定年度の年間計画が未作成、もしくは実施予定日が未登録のためCSVを出力することができません。
                <br />
                <a href={ADMIN_ROUTES.EDUCATION_ANNUAL_PLANS_INDEX}>年間計画</a>
                を作成し、実施予定日を登録してください。
              </span>
            </Box>
          )}
          <ApplyButton
            disabled={!exportable}
            onClick={() => window.open(exportUrl(), '_blank')}
          >
            CSV出力
          </ApplyButton>
        </BackGroundWhite>
      </Box>
    </PageWrapper>
  )
}

export default AdminEducationExportCsvIndex
