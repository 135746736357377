import * as React from 'react'
import { FlexBox } from 'components/atoms/BoxComponents'
import { useForm, Controller } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import BootstrapDialog from 'components/atoms/BootstrapDialog'
import {
  CustomLabel,
  YearMounthDatePicker,
} from 'components/atoms/FormComponents'
import {
  toFormattedBeginningOfMonth,
  toFormattedEndOfMonth,
} from 'commons/functions'
import { exportVehiclesPaymentHistoriesCsv } from 'components/apis/vehicles'
import { useErrorHandler } from 'react-error-boundary'
import { toastOnSuccess, toastOnError } from 'commons/toaster'
import { styled } from '@mui/material/styles'

const CustomForm = styled('form')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
})

export default function VehiclePaymentHistoryCsvModal(props: {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  vehicleIds: number[]
}) {
  const [processing, setProcessing] = React.useState(false)
  const today = new Date(Date.now())
  const { control, getValues, handleSubmit } =
    useForm<VehiclePaymentHistoryCsv>({
      mode: 'onSubmit',
      defaultValues: {
        startAt: toFormattedBeginningOfMonth(today),
        endAt: toFormattedEndOfMonth(today),
      },
    })
  const handleError = useErrorHandler()

  const onSubmit = () => {
    setProcessing(true)
    const startAt = getValues('startAt')
    const endAt = getValues('endAt')
    const formatedStartAt =
      typeof startAt == 'string'
        ? startAt
        : toFormattedBeginningOfMonth(startAt)
    const formatedEndAt =
      typeof endAt == 'string' ? endAt : toFormattedEndOfMonth(endAt)
    exportVehiclesPaymentHistoriesCsv(
      props.vehicleIds,
      formatedStartAt,
      formatedEndAt
    )
      .then((response) => {
        const filename = 'vehicle_payment_history.csv'
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        toastOnSuccess('ダウンロードが完了しました。')
      })
      .catch((e) => {
        handleError(e.response.status)
        toastOnError('CSVの出力が失敗しました。')
      })
      .finally(() => {
        props.setShowModal(false)
        setProcessing(false)
      })
  }

  return (
    <>
      {props.showModal && (
        <BootstrapDialog
          title={'支払履歴出力'}
          changeDialogStatus={() => props.setShowModal(false)}
        >
          <CustomLabel>
            検索結果の車両の点検、修理・整備、リースの支払い履歴を出力します。
          </CustomLabel>
          <CustomForm onSubmit={handleSubmit(onSubmit)}>
            <Box mx={'auto'}>
              <CustomLabel>
                期間
                <FlexBox>
                  <Box>
                    <Controller
                      name="startAt"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <YearMounthDatePicker
                          onChange={onChange}
                          selected={value && new Date(value)}
                          sx={{ width: '130px' }}
                        />
                      )}
                    />
                  </Box>
                  <Typography sx={{ margin: '0 1rem' }}>〜</Typography>
                  <Box>
                    <Controller
                      name="endAt"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <YearMounthDatePicker
                          onChange={onChange}
                          selected={value && new Date(value)}
                          sx={{ width: '130px' }}
                        />
                      )}
                    />
                  </Box>
                </FlexBox>
              </CustomLabel>
            </Box>
            <Box mx={'auto'}>
              <ApplyButton pattern="block" type="submit" disabled={processing}>
                出力
              </ApplyButton>
            </Box>
          </CustomForm>
        </BootstrapDialog>
      )}
    </>
  )
}
