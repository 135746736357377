import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const UserVehicleIcon = () => {
  return (
    <SvgIcon>
      <path
        fill="#033"
        d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 14H6v-2h6v2zm3-4H9v-2h6v2zm3-4h-6V7h6v2z"
      />
    </SvgIcon>
  )
}
