import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import Modal from 'react-modal'
import { Box } from '@mui/material'
import { yearRangeFrom2000 } from 'commons/date'
import { getFiscalYear } from 'commons/functions'
import { FlexBox } from 'components/atoms/BoxComponents'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import {
  CustomForm,
  CustomLabel,
  CustomSelectShort,
} from 'components/atoms/FormComponents'
import EducationMaterialSelect from 'components/atoms/ReactHookFormPartials/InputForm/EducationMaterialSelect'
import { palette } from 'components/theme'

export default function AttendanceHistoryModal(props: {
  educationId: string
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { getValues, control, handleSubmit } = useForm<ExportAttendanceHistory>(
    {
      mode: 'onSubmit',
      defaultValues: { year: getFiscalYear(new Date(Date.now())) },
    }
  )

  const onSubmit = () => {
    window.open(
      `tests/${getValues('educationId')}?year=${getValues('year')}`,
      '_blank'
    )
    props.setShowModal(false)
  }

  return (
    <Modal
      isOpen={props.showModal}
      style={{
        overlay: {
          minHeight: '100%',
          zIndex: 1000,
        },
        content: {
          backgroundColor: palette.background.main,
          maxHeight: '600px',
          maxWidth: '440px',
          marginLeft: 0,
          margin: 'auto',
          display: 'flex',
          flexFlow: 'wrap',
          overFlow: 'hidden',
          flexDirection: 'column',
          justifyContents: 'center',
          alignItems: 'center',
        },
      }}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        props.setShowModal(false)
      }}
    >
      <h2>受講履歴出力</h2>
      <Box sx={{ justifyContents: 'left' }}>
        <CustomLabel sx={{ maxWidth: '420px' }}>
          出力する年度と法定項目を選択してください。選択された年度に対応する出力結果が表示されます。
        </CustomLabel>
        <FlexBox>
          <CustomLabel>
            年度
            <Controller
              name="year"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelectShort
                  options={yearRangeFrom2000}
                  value={yearRangeFrom2000?.find((c) => c.value === value)}
                  onChange={(option: ReactSelectOptionProps) =>
                    onChange(option.value)
                  }
                />
              )}
            />
          </CustomLabel>
        </FlexBox>
        <EducationMaterialSelect
          educationKeyName={'educationId'}
          control={control}
        />
      </Box>
      <FlexBox>
        <CustomForm onSubmit={handleSubmit(onSubmit)}>
          <ApplyButton pattern="block" type="submit">
            出力
          </ApplyButton>
        </CustomForm>
      </FlexBox>
      <div style={{ position: 'absolute', bottom: 20 }}>
        <CancelButtonOutlined onClick={() => props.setShowModal(false)}>
          キャンセル
        </CancelButtonOutlined>
      </div>
    </Modal>
  )
}
