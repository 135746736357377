import * as React from 'react'
import { UserContext } from 'providers/UserProvider'
import Forbidden from 'components/pages/errors/Forbidden'
import { useParams } from 'react-router-dom'

function AdminRoute(props: {
  component: React.ReactNode
  /** 追加の条件を加えたい場合に指定する */
  options?: { currentUser: boolean }
}) {
  const isAdmin: boolean = React.useContext(UserContext).admin

  let optionalCondition = false

  // paramsのidがログイン中のユーザーのものと一致する場合は通す
  if (props?.options?.currentUser) {
    const currentUser = React.useContext(UserContext)
    const { id } = useParams()
    optionalCondition = parseInt(id) === currentUser.id
  }

  if (isAdmin || optionalCondition) {
    return <>{props.component}</>
  }

  return <Forbidden />
}

export default AdminRoute
