import * as React from 'react'
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormGetValues,
  useFieldArray,
} from 'react-hook-form'
import {
  BackGroundPalePink,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  ErrorMessage,
  ConvertibleCustomInput,
  ConvertibleCustomDateSelect,
  ConvertibleCustomDropZone,
  ConvertibleCustomTextArea,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import {
  DRIVERS,
  MEDICAL_RECORDS,
  VALIDATION_MESSAGES,
} from 'commons/constants'
import { Box, styled } from '@mui/material'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { useNavigate } from 'react-router-dom'
import { palette, theme } from 'components/theme'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

type MedicalRecordsProps = {
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
  handleModeChange: (mapKey: DriverFormModeMapKey, mode: FormMode) => void
  medicalRecordsFiles: File[]
  medicalRecordChildrenFiles: File[][]
  setMedicalRecordsFiles: React.Dispatch<React.SetStateAction<File[]>>
}

export default function MedicalRecords(props: MedicalRecordsProps) {
  const navigate = useNavigate()
  const { fields } = useFieldArray({
    control: props.control,
    name: 'medicalRecords',
  })

  const reload = () => {
    if (
      window.confirm('保存していないデータは削除されます。よろしいですか？')
    ) {
      navigate(0)
    }
  }

  const onDrop = (acceptedFiles: File[]) => {
    props.setMedicalRecordsFiles([
      ...props.medicalRecordsFiles,
      ...acceptedFiles,
    ])
  }
  const removeMedicalRecordImage = (index: number) => {
    props.setMedicalRecordsFiles(
      props.medicalRecordsFiles.filter((_, i) => i !== index)
    )
  }

  return (
    <BackGroundWhite>
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        marginBottom="16px"
      >
        <HeadingText>{DRIVERS.LABELS.MEDICAL_RECORDS}</HeadingText>
        <Box>
          {/* 一時的にコメントアウト */}
          {/* {props.mode == 'show' && (
            <EditButtonOutlined
              onClick={() => {
                props.handleModeChange('medicalRecords', 'edit')
              }}
            >
              編集
            </EditButtonOutlined>
          )} */}
          {props.mode == 'edit' && (
            <FlexBox>
              <ApplyButton pattern="marginRight8" type="submit">
                保存する
              </ApplyButton>
              <CancelButtonOutlined onClick={reload}>
                キャンセル
              </CancelButtonOutlined>
            </FlexBox>
          )}
        </Box>
      </FlexBox>
      {fields.map((_, i) => {
        return (
          <Box key={`medical-record-card-${i}`} mb={2}>
            <MedicalRecordInner
              index={i}
              mode={props.mode}
              control={props.control}
              register={props.register}
              errors={props.errors}
              getValues={props.getValues}
              medicalRecordChildrenFiles={props.medicalRecordChildrenFiles}
            ></MedicalRecordInner>
          </Box>
        )
      })}
      <CustomLabel sx={{ marginTop: '32px' }}>
        {DRIVERS.LABELS.FILE}(健康診断の
        {DRIVERS.LABELS.FILE_UPLOAD_DESCRIPTION_PARENT})
        <ConvertibleCustomDropZone
          mode={props.mode}
          formKey={`medicalRecords.files`}
          control={props.control}
          getValues={props.getValues}
          files={props.medicalRecordsFiles}
          onDrop={onDrop}
          onCancelIconClick={removeMedicalRecordImage}
        ></ConvertibleCustomDropZone>
      </CustomLabel>
    </BackGroundWhite>
  )
}

type MedicalRecordInnerProps = {
  index: number
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
  medicalRecordChildrenFiles: File[][]
}

const Title = styled('p')({
  fontWeight: 'bold',
  color: palette.text.gray.deep,
})

const Text = styled('p')({
  fontSize: '16px',
  margin: 0,
})

const FileTextWrapper = styled('div')({
  width: '100%',
  paddingBottom: '8px',
  paddingLeft: '4px',
  marginTop: '16px',
  borderBottom: `1px solid ${palette.border.pale}`,
  overflowWrap: 'break-word',
})

const MedicalRecordInner: React.FC<MedicalRecordInnerProps> = (props) => {
  return (
    <BackGroundPalePink>
      <Title>
        {DRIVERS.LABELS.MEDICAL_RECORDS}
        {props.index + 1}
      </Title>
      <FlexBox
        alignItems="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel
          sx={{
            marginRight: '32px',
            [theme.breakpoints.down('md')]: {
              marginRight: '0',
            },
          }}
        >
          {MEDICAL_RECORDS.LABELS.CONSULTATION_DATE}
          <ConvertibleCustomDateSelect
            mode={props.mode}
            formKeys={{
              year: `medicalRecords.${props.index}.consultationYear`,
              month: `medicalRecords.${props.index}.consultationMonth`,
              day: `medicalRecords.${props.index}.consultationDay`,
            }}
            control={props.control}
            getValues={props.getValues}
          ></ConvertibleCustomDateSelect>
        </CustomLabel>
        <CustomLabel
          sx={{
            marginRight: '32px',
            [theme.breakpoints.down('md')]: {
              marginRight: '0',
            },
          }}
        >
          {MEDICAL_RECORDS.LABELS.RESULT}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey={`medicalRecords.${props.index}.result`}
            register={props.register}
            getValues={props.getValues}
          />
          {props.errors.medicalRecords?.[props.index]?.result && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
      </FlexBox>
      <CustomLabel>
        {MEDICAL_RECORDS.LABELS.MEMO}
        <ConvertibleCustomTextArea
          mode={props.mode}
          formKey={`medicalRecords.${props.index}.memo`}
          register={props.register}
          getValues={props.getValues}
        ></ConvertibleCustomTextArea>
        {props.errors.medicalRecords?.[props.index]?.memo && (
          <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
        )}
      </CustomLabel>
      <CustomLabel>
        {DRIVERS.LABELS.FILE}(健康診断の
        {DRIVERS.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
        <FileTextWrapper>
          {props.medicalRecordChildrenFiles[props.index]?.length > 0 ? (
            props.medicalRecordChildrenFiles[props.index].map((file, i) => {
              return (
                <FlexBox key={`${file.name}-${i}`} mb={1}>
                  <InsertDriveFileOutlinedIcon fontSize="small" />
                  <Text sx={{ marginLeft: '8px' }}>
                    <a
                      href={URL.createObjectURL(file)}
                      download={decodeURI(file.name)}
                    >
                      {decodeURI(file.name)}
                    </a>
                  </Text>
                </FlexBox>
              )
            })
          ) : (
            <Text>ファイルがありません</Text>
          )}
        </FileTextWrapper>
      </CustomLabel>
    </BackGroundPalePink>
  )
}
