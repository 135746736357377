export const PREFECTURES = [
  { id: 1, label: '北海道', value: '北海道' },
  { id: 2, label: '青森県', value: '青森県' },
  { id: 3, label: '岩手県', value: '岩手県' },
  { id: 4, label: '宮城県', value: '宮城県' },
  { id: 5, label: '秋田県', value: '秋田県' },
  { id: 6, label: '山形県', value: '山形県' },
  { id: 7, label: '福島県', value: '福島県' },
  { id: 8, label: '茨城県', value: '茨城県' },
  { id: 9, label: '栃木県', value: '栃木県' },
  { id: 10, label: '群馬県', value: '群馬県' },
  { id: 11, label: '埼玉県', value: '埼玉県' },
  { id: 12, label: '千葉県', value: '千葉県' },
  { id: 13, label: '東京都', value: '東京都' },
  { id: 14, label: '神奈川県', value: '神奈川県' },
  { id: 15, label: '新潟県', value: '新潟県' },
  { id: 16, label: '富山県', value: '富山県' },
  { id: 17, label: '石川県', value: '石川県' },
  { id: 18, label: '福井県', value: '福井県' },
  { id: 19, label: '山梨県', value: '山梨県' },
  { id: 20, label: '長野県', value: '長野県' },
  { id: 21, label: '岐阜県', value: '岐阜県' },
  { id: 22, label: '静岡県', value: '静岡県' },
  { id: 23, label: '愛知県', value: '愛知県' },
  { id: 24, label: '三重県', value: '三重県' },
  { id: 25, label: '滋賀県', value: '滋賀県' },
  { id: 26, label: '京都府', value: '京都府' },
  { id: 27, label: '大阪府', value: '大阪府' },
  { id: 28, label: '兵庫県', value: '兵庫県' },
  { id: 29, label: '奈良県', value: '奈良県' },
  { id: 30, label: '和歌山県', value: '和歌山県' },
  { id: 31, label: '鳥取県', value: '鳥取県' },
  { id: 32, label: '島根県', value: '島根県' },
  { id: 33, label: '岡山県', value: '岡山県' },
  { id: 34, label: '広島県', value: '広島県' },
  { id: 35, label: '山口県', value: '山口県' },
  { id: 36, label: '徳島県', value: '徳島県' },
  { id: 37, label: '香川県', value: '香川県' },
  { id: 38, label: '愛媛県', value: '愛媛県' },
  { id: 39, label: '高知県', value: '高知県' },
  { id: 40, label: '福岡県', value: '福岡県' },
  { id: 41, label: '佐賀県', value: '佐賀県' },
  { id: 42, label: '長崎県', value: '長崎県' },
  { id: 43, label: '熊本県', value: '熊本県' },
  { id: 44, label: '大分県', value: '大分県' },
  { id: 45, label: '宮崎県', value: '宮崎県' },
  { id: 46, label: '鹿児島県', value: '鹿児島県' },
  { id: 47, label: '沖縄県', value: '沖縄県' },
]
