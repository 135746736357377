import * as React from 'react'
import { styled } from '@mui/styles'
import { Box, SxProps, useTheme } from '@mui/material'
import { palette } from 'components/theme'

type Props = {
  children?: React.ReactNode
  sx?: SxProps
  withoutPadding?: boolean
  smallPadding?: boolean
}

const Wrapper = styled(Box)({
  boxSizing: 'border-box',
  borderRadius: '6px',
  width: '100%',
  height: '100%',
})

const WrapperWithBorder = styled(Wrapper)({
  border: '1px solid #E6E7E8',
})

export function BackGroundWhite(props: Props) {
  const withoutPadding = props.withoutPadding ?? false
  const defaultPadding = props.smallPadding ? '15px' : '24px 32px'
  return (
    <WrapperWithBorder
      sx={{
        background: 'white',
        padding: withoutPadding ? 0 : defaultPadding,
      }}
    >
      {props.children}
    </WrapperWithBorder>
  )
}

export function BackGroundGray(props: Props) {
  const withoutPadding = props.withoutPadding ?? false

  return (
    <WrapperWithBorder
      sx={{
        background: palette.background.tableHeaderCell.pale,
        padding: withoutPadding ? 0 : '16px 24px',
      }}
    >
      {props.children}
    </WrapperWithBorder>
  )
}

export function BackGroundPalePink(props: Props) {
  const withoutPadding = props.withoutPadding ?? false
  const theme = useTheme()

  return (
    <Wrapper
      sx={{
        background: palette.background.formItemInner,
        padding: withoutPadding ? 0 : '16px 24px',
        [theme.breakpoints.down('md')]: {
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
        },
        ...props.sx,
      }}
    >
      {props.children}
    </Wrapper>
  )
}
