import { AxiosResponse } from 'axios'
import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { addParamPrefix, camelCaseKeysToUnderscore } from 'commons/functions'

export function fetchVehicleCostManagements(
  searchParams?: SearchVehicleCostManagement,
  paginateParams?: PaginateParams
): Promise<
  AxiosResponse<{
    vehicles: Vehicle[]
    totalCount: number
  }>
> {
  const customSearchParams = searchParams
    ? addParamPrefix(searchParams, 'search')
    : {}
  const customPaginateParams = paginateParams
    ? addParamPrefix(paginateParams, 'pagination')
    : {}
  const params = camelCaseKeysToUnderscore({
    ...customSearchParams,
    ...customPaginateParams,
  })
  return axios.get(API_ENDPOINTS.VEHICLE_COST_MANAGEMENTS, {
    params: params,
  })
}
