import * as React from 'react'
import {
  CustomDateSelect,
  CustomInput,
  CustomLabel,
  CustomSelect,
  ErrorMessage,
  CustomDropZone,
  WithRequiredBadge,
} from 'components/atoms/FormComponents'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import {
  APTITUDE_TEST_RECORDS,
  DRIVERS,
  VALIDATION_MESSAGES,
} from 'commons/constants'
import { BackGroundPalePink } from 'components/atoms/BackGroundComponents'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { styled } from '@mui/styles'
import { formatCustomDateErrorMessage } from 'commons/errorMessage'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { FlexBox } from 'components/atoms/BoxComponents'

type AptitudeTestRecordCardProps = {
  register: UseFormRegister<Driver>
  setValue: UseFormSetValue<Driver>
  watch: UseFormWatch<Driver>
  control: Control<Driver>
  errors: FieldErrors<Driver>
  appendEmptyItem: () => void
  deleteItem: (index: number) => void
  index: number
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>
}

const AptitudeTestRecordCard: React.FC<AptitudeTestRecordCardProps> = (
  props
) => {
  const { register, control, setValue, watch, index, errors } = props
  const consultationDateErrorMessage = React.useCallback(() => {
    return formatCustomDateErrorMessage({
      year: errors?.aptitudeTestRecords?.[index]?.consultationYear,
      month: errors?.aptitudeTestRecords?.[index]?.consultationMonth,
      day: errors?.aptitudeTestRecords?.[index]?.consultationDay,
    })
  }, [errors?.aptitudeTestRecords?.[index]])

  const CardTitle = styled('p')({
    fontWeight: 'bold',
  })

  const aptitudeTypeOptions = [
    { value: 'initial', label: '初任診断' },
    { value: 'age_approptiate', label: '適齢診断' },
    { value: 'specific_first', label: '特定診断Ⅰ' },
    { value: 'specific_second', label: '特定診断Ⅱ' },
    { value: 'genaral', label: '一般診断' },
  ]

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const oldFiles = watch(`aptitudeTestRecords.${index}.files`)
        ? watch(`aptitudeTestRecords.${index}.files`)
        : []
      setValue(`aptitudeTestRecords.${index}.files`, [
        ...oldFiles,
        ...acceptedFiles,
      ])
    },
    [watch(`aptitudeTestRecords.${index}.files`)]
  )

  return (
    <BackGroundPalePink sx={{ marginBottom: '30px' }}>
      <CardTitle>
        {DRIVERS.LABELS.APTITUDE_TEST}
        {index + 1}
      </CardTitle>
      <CustomLabel
        sx={{
          marginBottom: '30px',
        }}
      >
        <WithRequiredBadge>
          {APTITUDE_TEST_RECORDS.LABELS.APTITUDE_TYPE}
        </WithRequiredBadge>
        <Controller
          name={`aptitudeTestRecords.${index}.aptitudeType`}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <CustomSelect
              options={aptitudeTypeOptions}
              value={aptitudeTypeOptions?.find(
                (option) => option.value === value
              )}
              onChange={(option: ReactSelectOptionProps) =>
                onChange(option.value)
              }
              placeholder="未選択"
            />
          )}
        />
        {errors?.aptitudeTestRecords?.[index]?.aptitudeType && (
          <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
        )}
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {APTITUDE_TEST_RECORDS.LABELS.CONSULTATION_DATE}
        <CustomDateSelect
          formKeys={{
            year: `aptitudeTestRecords.${index}.consultationYear`,
            month: `aptitudeTestRecords.${index}.consultationMonth`,
            day: `aptitudeTestRecords.${index}.consultationDay`,
          }}
          control={control}
          required
        />
        <ErrorMessage>{consultationDateErrorMessage()}</ErrorMessage>
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {APTITUDE_TEST_RECORDS.LABELS.INSTITUTION_NAME}
        <CustomInput
          sx={{ marginTop: '4px', height: '38px' }}
          onKeyDown={props.onInputKeyDown}
          {...register(`aptitudeTestRecords.${index}.institutionName`)}
        />
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {APTITUDE_TEST_RECORDS.LABELS.RESULT}
        <CustomInput
          sx={{ marginTop: '4px', height: '38px' }}
          onKeyDown={props.onInputKeyDown}
          {...register(`aptitudeTestRecords.${index}.result`)}
        />
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {DRIVERS.LABELS.FILE_UPLOAD}(適性診断の
        {DRIVERS.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
        <Controller
          render={() => (
            <CustomDropZone
              multiple={true}
              onDrop={onDrop}
              accept={'image/*, application/pdf'}
            />
          )}
          name={`aptitudeTestRecords.${index}.fileUrls`}
          control={control}
        />
        {watch(`aptitudeTestRecords.${index}.files`) &&
          Object.values(watch(`aptitudeTestRecords.${index}.files`))?.map(
            (image: File, fileIndex: number) => (
              <FlexBox flexDirection={'row'} key={fileIndex}>
                <p>
                  <a
                    href={URL.createObjectURL(image)}
                    download={decodeURI(image.name)}
                  >
                    {decodeURI(image.name)}
                  </a>
                </p>
                <IconButton
                  component="span"
                  onClick={() =>
                    setValue(
                      `aptitudeTestRecords.${index}.files`,
                      watch(`aptitudeTestRecords.${index}.files`).filter(
                        (_, i) => i !== fileIndex
                      )
                    )
                  }
                >
                  <CancelIcon />
                </IconButton>
              </FlexBox>
            )
          )}
      </CustomLabel>
      <DeleteButton onClick={() => props.deleteItem(index)}>削除</DeleteButton>
    </BackGroundPalePink>
  )
}

export default AptitudeTestRecordCard
