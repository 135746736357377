import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  LOCAL_STORAGE_PAGE_KEY,
  VEHICLES,
  VEHICLE_LINK_LIST_ITEMS,
  ALERT_TEXT,
} from 'commons/constants'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomForm,
  CustomTextArea,
  CustomDropZone,
  HeadingText,
  setUnsubscribedAlert,
} from 'components/atoms/FormComponents'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import {
  fetchVehicleOptions,
  fetchVehicles,
  updateVehicle,
} from 'components/apis/vehicles'
import { FlexBox } from 'components/atoms/BoxComponents'
import CarouselComponent from 'components/atoms/ResponsiveCarousel'
import { fetchS3ImagesV2 } from 'commons/fetchS3Images'
import { IconButton, styled } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { UserContext } from 'providers/UserProvider'
import { toastOnError } from 'commons/toaster'
import { useErrorHandler } from 'react-error-boundary'

import Status from './form_sections/status/Status'
import License from './form_sections/license/License'
import Basic from './form_sections/basic/Basic'
import Body from './form_sections/body/Body'
import Tools from './form_sections/tools/Tools'
import Purchase from './form_sections/purchase/Purchase'
import Lease from './form_sections/lease/Lease'
import WeightTax from './form_sections/taxes/TaxWeight'
import Tax from './form_sections/taxes/Tax'
import InsuranceCompulsory from './form_sections/insurances/InsuranceCompulsory'
import InsuranceVoluntary from './form_sections/insurances/InsuranceVoluntary'
import InspectionAnnually from './form_sections/inspections/InspectionAnnually'
import InspectionThreeMonths from './form_sections/inspections/InspectionThreeMonths'
import InspectionOthers from './form_sections/inspections/InspectionOthers'
import Maintenance from './form_sections/maintenance/Maintenance'
import PartsReplaced from './form_sections/parts/PartsReplaced'
import PartsPurchased from './form_sections/parts/PartsPurchased'
import Equipments from './form_sections/equipments/Equipments'
import GreenManagement from './form_sections/green_management/GreenManagement'
import { Loading } from 'components/atoms/LoadingComponents'
import { checkSubscribed } from 'components/apis/drivers'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toastOnSuccess } from 'commons/toaster'
import FormSubmitSelectPageModal from 'components/atoms/FormSubmitSelectPageModal'
import ProcessingModal from 'components/atoms/ProcessingModal'
import { ShouldSubmitOnKeyPress } from 'commons/formUtils'
import { toISOStringWithTimezone } from 'commons/functions'
import { useNavigate } from 'react-router-dom'
import {
  getLocalStorageDisplayLimit,
  getLocalStorageSearchParams,
  getNextResourceId,
} from 'commons/table'
import { slackNotification } from 'commons/slackNotification'

interface Props {
  type: 'create' | 'update'
  data?: Vehicle
}

export default function VehiclesForm(props: Props) {
  const user = React.useContext(UserContext)
  const navigate = useNavigate()
  const [images, setImages] = React.useState<File[]>([])
  const [equipmentFiles, setEquipmentFiles] = React.useState<File[]>([])
  const [taxBasisFiles, setTaxBasisFiles] = React.useState<File[]>([])
  const [taxWeightFiles, setTaxWeightFiles] = React.useState<File[]>([])
  const [leaseFiles, setLeaseFiles] = React.useState<File[]>([])
  const [insuranceVoluntaryFiles, setInsuranceVoluntaryFiles] = React.useState<
    File[]
  >([])
  const [insuranceCompulsoryFiles, setInsuranceCompulsoryFiles] =
    React.useState<File[]>([])
  const [inspectionAnnuallyFiles, setInspectionAnnuallyFiles] = React.useState<
    File[]
  >([])
  const [inspectionThreeMonthsFiles, setInspectionThreeMonthsFiles] =
    React.useState<File[]>([])
  const [inspectionOthersFiles, setInspectionOthersFiles] = React.useState<
    File[]
  >([])
  const [partsReplacedFiles, setPartsReplacedFiles] = React.useState<File[]>([])
  const [partsPurchasedFiles, setPartsPurchasedFiles] = React.useState<File[]>(
    []
  )
  const [maintenanceFiles, setMaintenanceFiles] = React.useState<File[]>([])
  const [isLoading, setLoading] = React.useState(true)
  const handleError = useErrorHandler()
  const [isModalShow, setModalShow] = React.useState(false)
  const [isProcessingModalShow, setIsProcessingModalShow] =
    React.useState(false)

  const mutation = useMutation({
    mutationFn: updateVehicle,
    onSuccess: () => {
      setModalShow(false)
      scrollTo({ top: 0 })
    },
    onError: (e: AxiosError<{ errors: string[] }>) => {
      setModalShow(false)
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors.join(','))
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
  })

  const mutationWithoutNavigation = useMutation({
    mutationFn: updateVehicle,
    onSuccess: () => {
      toastOnSuccess('保存しました')
    },
    onError: (e: AxiosError<{ errors: string[] }>) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors.join(','))
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
    onSettled: () => {
      setIsProcessingModalShow(false)
    },
  })

  // 更新時の初期値
  const defaultValues = {
    ...props.data,
    firstRegisteredDate: props.data?.firstRegisteredDate
      ? new Date(props.data.firstRegisteredDate)
      : undefined,
    bodyManufacturedDate: props.data?.bodyManufacturedDate
      ? new Date(props.data.bodyManufacturedDate)
      : undefined,
    coolerManufacturedDate: props.data?.coolerManufacturedDate
      ? new Date(props.data.coolerManufacturedDate)
      : undefined,
    etcCard1ExpireDate: props.data?.etcCard1ExpireDate
      ? new Date(props.data.etcCard1ExpireDate)
      : undefined,
    etcCard2ExpireDate: props.data?.etcCard2ExpireDate
      ? new Date(props.data.etcCard2ExpireDate)
      : undefined,
    etcCard3ExpireDate: props.data?.etcCard3ExpireDate
      ? new Date(props.data.etcCard3ExpireDate)
      : undefined,
    etcCard4ExpireDate: props.data?.etcCard4ExpireDate
      ? new Date(props.data.etcCard4ExpireDate)
      : undefined,
    etcCard5ExpireDate: props.data?.etcCard5ExpireDate
      ? new Date(props.data.etcCard5ExpireDate)
      : undefined,
    purchasedDate: props.data?.purchasedDate
      ? new Date(props.data.purchasedDate)
      : undefined,
  }

  const baseKeys = [`vehicles`, `${defaultValues.id}`, `VehiclesForm`]

  useQuery(
    [...baseKeys, `attachedFiles`],
    () => fetchS3ImagesV2(defaultValues.attachedFiles),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      onSuccess: (data) => setImages(data),
      refetchOnWindowFocus: false,
    }
  )

  useQuery(
    [...baseKeys, `attachedEquipmentFiles`],
    () => fetchS3ImagesV2(defaultValues.attachedEquipmentFiles),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      onSuccess: (data) => setEquipmentFiles(data),
      refetchOnWindowFocus: false,
    }
  )

  useQuery(
    [...baseKeys, `attachedTaxBasisFiles`],
    () => fetchS3ImagesV2(defaultValues.attachedTaxBasisFiles),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      onSuccess: (data) => setTaxBasisFiles(data),
      refetchOnWindowFocus: false,
    }
  )

  useQuery(
    [...baseKeys, `attachedTaxWeightFiles`],
    () => fetchS3ImagesV2(defaultValues.attachedTaxWeightFiles),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      onSuccess: (data) => setTaxWeightFiles(data),
      refetchOnWindowFocus: false,
    }
  )
  useQuery(
    [...baseKeys, `attachedLeaseFiles`],
    () => fetchS3ImagesV2(defaultValues.attachedLeaseFiles),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      onSuccess: (data) => setLeaseFiles(data),
      refetchOnWindowFocus: false,
    }
  )
  useQuery(
    [...baseKeys, `attachedInsuranceVoluntaryFiles`],
    () => fetchS3ImagesV2(defaultValues.attachedInsuranceVoluntaryFiles),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      onSuccess: (data) => setInsuranceVoluntaryFiles(data),
      refetchOnWindowFocus: false,
    }
  )

  useQuery(
    [...baseKeys, `attachedInsuranceCompulsoryFiles`],
    () => fetchS3ImagesV2(defaultValues.attachedInsuranceCompulsoryFiles),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      onSuccess: (data) => setInsuranceCompulsoryFiles(data),
      refetchOnWindowFocus: false,
    }
  )

  useQuery(
    [...baseKeys, `attachedInspectionAnnuallyFiles`],
    () => fetchS3ImagesV2(defaultValues.attachedInspectionAnnuallyFiles),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      onSuccess: (data) => setInspectionAnnuallyFiles(data),
      refetchOnWindowFocus: false,
    }
  )

  useQuery(
    [...baseKeys, `attachedInspectionThreeMonthsFiles`],
    () => fetchS3ImagesV2(defaultValues.attachedInspectionThreeMonthsFiles),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      onSuccess: (data) => setInspectionThreeMonthsFiles(data),
      refetchOnWindowFocus: false,
    }
  )

  useQuery(
    [...baseKeys, `attachedInspectionOthersFiles`],
    () => fetchS3ImagesV2(defaultValues.attachedInspectionOthersFiles),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      onSuccess: (data) => setInspectionOthersFiles(data),
      refetchOnWindowFocus: false,
    }
  )

  useQuery(
    [...baseKeys, `attachedPartsReplacedFiles`],
    () => fetchS3ImagesV2(defaultValues.attachedPartsReplacedFiles),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      onSuccess: (data) => setPartsReplacedFiles(data),
      refetchOnWindowFocus: false,
    }
  )

  useQuery(
    [...baseKeys, `attachedPartsPurchasedFiles`],
    () => fetchS3ImagesV2(defaultValues.attachedPartsPurchasedFiles),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      onSuccess: (data) => setPartsPurchasedFiles(data),
      refetchOnWindowFocus: false,
    }
  )

  useQuery(
    [...baseKeys, `attachedMaintenanceFiles`],
    () => fetchS3ImagesV2(defaultValues.attachedMaintenanceFiles),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      onSuccess: (data) => setMaintenanceFiles(data),
      refetchOnWindowFocus: false,
    }
  )

  React.useEffect(() => {
    defaultValues.taxBase?.forEach((taxBase, index) => {
      fetchS3ImagesV2(taxBase.attachedFiles)
        .then((data: File[]) => {
          setValue(`taxBase.${index}.files`, data)
        })
        .catch((e: AxiosError) => {
          handleError(e.response.status)
        })
    })
  }, [])

  React.useEffect(() => {
    defaultValues.taxWeight?.forEach((taxWeight, index) => {
      fetchS3ImagesV2(taxWeight.attachedFiles)
        .then((data: File[]) => {
          setValue(`taxWeight.${index}.files`, data)
        })
        .catch((e: AxiosError) => {
          handleError(e.response.status)
        })
    })
  }, [])

  React.useEffect(() => {
    defaultValues.leases?.forEach((lease, index) => {
      fetchS3ImagesV2(lease.attachedFiles)
        .then((data: File[]) => {
          setValue(`leases.${index}.files`, data)
        })
        .catch((e: AxiosError) => {
          handleError(e.response.status)
        })
    })
  }, [])

  React.useEffect(() => {
    defaultValues.insurancesCompulsory?.forEach(
      (insurancesCompulsory, index) => {
        fetchS3ImagesV2(insurancesCompulsory.attachedFiles)
          .then((data: File[]) => {
            setValue(`insurancesCompulsory.${index}.files`, data)
          })
          .catch((e: AxiosError) => {
            handleError(e.response.status)
          })
      }
    )
  }, [])

  React.useEffect(() => {
    defaultValues.insurancesVoluntary?.forEach((insurancesVoluntary, index) => {
      fetchS3ImagesV2(insurancesVoluntary.attachedFiles)
        .then((data: File[]) => {
          setValue(`insurancesVoluntary.${index}.files`, data)
        })
        .catch((e: AxiosError) => {
          handleError(e.response.status)
        })
    })
  }, [])

  React.useEffect(() => {
    defaultValues.inspectionsAnnually?.forEach((inspectionsAnnually, index) => {
      fetchS3ImagesV2(inspectionsAnnually.attachedFiles)
        .then((data: File[]) => {
          setValue(`inspectionsAnnually.${index}.files`, data)
        })
        .catch((e: AxiosError) => {
          handleError(e.response.status)
        })
    })
  }, [])

  React.useEffect(() => {
    defaultValues.inspectionsThreeMonths?.forEach(
      (inspectionsThreeMonths, index) => {
        fetchS3ImagesV2(inspectionsThreeMonths.attachedFiles)
          .then((data: File[]) => {
            setValue(`inspectionsThreeMonths.${index}.files`, data)
          })
          .catch((e: AxiosError) => {
            handleError(e.response.status)
          })
      }
    )
  }, [])

  React.useEffect(() => {
    defaultValues.inspectionsOthers?.forEach((inspectionsOthers, index) => {
      fetchS3ImagesV2(inspectionsOthers.attachedFiles)
        .then((data: File[]) => {
          setValue(`inspectionsOthers.${index}.files`, data)
        })
        .catch((e: AxiosError) => {
          handleError(e.response.status)
        })
    })
  }, [])

  React.useEffect(() => {
    defaultValues.maintenances?.forEach((maintenance, index) => {
      fetchS3ImagesV2(maintenance.attachedFiles)
        .then((data: File[]) => {
          setValue(`maintenances.${index}.files`, data)
        })
        .catch((e: AxiosError) => {
          handleError(e.response.status)
        })
    })
  }, [])

  React.useEffect(() => {
    defaultValues.partsReplaced?.forEach((partsReplaced, index) => {
      fetchS3ImagesV2(partsReplaced.attachedFiles)
        .then((data: File[]) => {
          setValue(`partsReplaced.${index}.files`, data)
        })
        .catch((e: AxiosError) => {
          handleError(e.response.status)
        })
    })
  }, [])

  React.useEffect(() => {
    defaultValues.partsPurchased?.forEach((partsPurchased, index) => {
      fetchS3ImagesV2(partsPurchased.attachedFiles)
        .then((data: File[]) => {
          setValue(`partsPurchased.${index}.files`, data)
        })
        .catch((e: AxiosError) => {
          handleError(e.response.status)
        })
    })
  }, [])

  const { data: vehicleOptions, isLoading: isVehicleOptionsLoading } = useQuery(
    [`vehicles/Form`, 'vehicle_options'],
    () =>
      fetchVehicleOptions().then((res) => {
        const obj = Object.entries(res.data).reduce((acc, [key, values]) => {
          acc[key] = Object.values(values).map((value) => {
            return { label: value?.text, value: value?.value }
          })
          return acc
        }, {})
        return obj
      })
  )

  const { data: subscribed, isLoading: isSubscribedLoading } = useQuery(
    [`vehicles/Form`, 'subscribed'],
    () => checkSubscribed('vehicles').then((res) => res.data.subscribed)
  )

  const isQueryLoading = isVehicleOptionsLoading || isSubscribedLoading

  React.useEffect(() => {
    setLoading(isQueryLoading)
  }, [isQueryLoading])

  // フォームオブジェクト
  const {
    register,
    setValue,
    getValues,
    trigger,
    control,
    watch,
    formState: { errors },
  } = useForm<Vehicle>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: defaultValues,
  })

  const formatParamsData = (data) => {
    // inputで入力しないが送信するparams
    data.lastUpdatedUser = user.id
    const formData = new FormData()
    images.map((image) => formData.append('files[]', image))
    equipmentFiles.map((image) => formData.append('equipment_files[]', image))
    taxBasisFiles.map((image) => formData.append('tax_basis_files[]', image))
    taxWeightFiles.map((image) => formData.append('tax_weight_files[]', image))
    leaseFiles.map((image) => formData.append('lease_files[]', image))
    insuranceVoluntaryFiles.map((image) =>
      formData.append('insurance_voluntary_files[]', image)
    )
    insuranceCompulsoryFiles.map((image) =>
      formData.append('insurance_compulsory_files[]', image)
    )
    inspectionAnnuallyFiles.map((image) =>
      formData.append('inspection_annually_files[]', image)
    )
    inspectionThreeMonthsFiles.map((image) =>
      formData.append('inspection_three_months_files[]', image)
    )
    inspectionOthersFiles.map((image) =>
      formData.append('inspection_others_files[]', image)
    )
    partsReplacedFiles.map((image) =>
      formData.append('parts_replaced_files[]', image)
    )
    partsPurchasedFiles.map((image) =>
      formData.append('parts_purchased_files[]', image)
    )
    maintenanceFiles.map((image) =>
      formData.append('maintenance_files[]', image)
    )

    // 日付データを結合
    const registeredDate = [
      data.registeredYear,
      data.registeredMonth,
      data.registeredDay,
    ]
    if (registeredDate.every) {
      data.registeredDate = registeredDate.join('-')
    }

    // 初度登録年月の整形
    data.firstRegisteredDate =
      data.firstRegisteredDate &&
      toISOStringWithTimezone(data.firstRegisteredDate).split('T')[0]

    // 車検満了日の整形
    const inspectionExpireDate = [
      data.inspectionExpireYear,
      data.inspectionExpireMonth,
      data.inspectionExpireDay,
    ]
    if (inspectionExpireDate.every) {
      data.inspectionExpireDate = inspectionExpireDate.join('-')
    }

    // 荷台情報の製造年月の整形
    data.bodyManufacturedDate =
      data.bodyManufacturedDate &&
      toISOStringWithTimezone(data.bodyManufacturedDate).split('T')[0]

    // 冷凍機の製造年月の整形
    data.coolerManufacturedDate =
      data.coolerManufacturedDate &&
      toISOStringWithTimezone(data.coolerManufacturedDate).split('T')[0]

    // 車両型式を結合
    const model = [data.model1, data.model2]
    if (model.every) {
      data.model = model.join('-')
    }

    // 車両税の日付整形
    if (data.taxBase) {
      data.taxBase.forEach((taxBase, index) => {
        taxBase.startDate = `${taxBase.startYear}-${taxBase.startMonth}-${taxBase.startDay}`
        taxBase.endDate = `${taxBase.endYear}-${taxBase.endMonth}-${taxBase.endDay}`

        taxBase.files.forEach((file) => {
          formData.append(`tax_base_children_files[${index}][]`, file)
        })
      })
    }
    // 車両重量税の日付整形
    if (data.taxWeight) {
      data.taxWeight.forEach((taxWeight, index) => {
        taxWeight.startDate = `${taxWeight.startYear}-${taxWeight.startMonth}-${taxWeight.startDay}`
        taxWeight.endDate = `${taxWeight.endYear}-${taxWeight.endMonth}-${taxWeight.endDay}`
        taxWeight.files.forEach((file) => {
          formData.append(`tax_weight_children_files[${index}][]`, file)
        })
      })
    }
    // リースの日付整形
    if (data.leases) {
      data.leases.forEach((lease, index) => {
        lease.startDate = `${lease.startYear}-${lease.startMonth}-${lease.startDay}`
        lease.endDate = `${lease.endYear}-${lease.endMonth}-${lease.endDay}`
        lease.files.forEach((file) => {
          formData.append(`lease_children_files[${index}][]`, file)
        })
      })
    }
    // 自賠責保険の日付整形
    if (data.insurancesCompulsory) {
      data.insurancesCompulsory.forEach((insurancesCompulsory, index) => {
        insurancesCompulsory.startDate = `${insurancesCompulsory.startYear}-${insurancesCompulsory.startMonth}-${insurancesCompulsory.startDay}`
        insurancesCompulsory.endDate = `${insurancesCompulsory.endYear}-${insurancesCompulsory.endMonth}-${insurancesCompulsory.endDay}`
        insurancesCompulsory.files.forEach((file) => {
          formData.append(
            `insurance_compulsory_children_files[${index}][]`,
            file
          )
        })
      })
    }
    // 任意保険の日付整形
    if (data.insurancesVoluntary) {
      data.insurancesVoluntary.forEach((insurancesVoluntary, index) => {
        insurancesVoluntary.startDate = `${insurancesVoluntary.startYear}-${insurancesVoluntary.startMonth}-${insurancesVoluntary.startDay}`
        insurancesVoluntary.endDate = `${insurancesVoluntary.endYear}-${insurancesVoluntary.endMonth}-${insurancesVoluntary.endDay}`
        insurancesVoluntary.files.forEach((file) => {
          formData.append(
            `insurance_voluntary_children_files[${index}][]`,
            file
          )
        })
      })
    }
    // 定期点検（車検）の日付整形
    if (data.inspectionsAnnually) {
      data.inspectionsAnnually.forEach((inspectionAnnually, index) => {
        inspectionAnnually.date = `${inspectionAnnually.inspectYear}-${inspectionAnnually.inspectMonth}-${inspectionAnnually.inspectDay}`
        inspectionAnnually.nextDate = `${inspectionAnnually.nextYear}-${inspectionAnnually.nextMonth}-${inspectionAnnually.nextDay}`
        inspectionAnnually.files.forEach((file) => {
          formData.append(
            `inspection_annually_children_files[${index}][]`,
            file
          )
        })
      })
    }
    // 定期点検（3ヵ月）の日付整形
    if (data.inspectionsThreeMonths) {
      data.inspectionsThreeMonths.forEach((inspectionThreeMonths, index) => {
        inspectionThreeMonths.date = `${inspectionThreeMonths.inspectYear}-${inspectionThreeMonths.inspectMonth}-${inspectionThreeMonths.inspectDay}`
        inspectionThreeMonths.files.forEach((file) => {
          formData.append(
            `inspection_three_months_children_files[${index}][]`,
            file
          )
        })
      })
    }
    // 定期点検（その他）の日付整形
    if (data.inspectionsOthers) {
      data.inspectionsOthers.forEach((inspectionOthers, index) => {
        inspectionOthers.date = `${inspectionOthers.inspectYear}-${inspectionOthers.inspectMonth}-${inspectionOthers.inspectDay}`
        inspectionOthers.nextDate = `${inspectionOthers.nextYear}-${inspectionOthers.nextMonth}-${inspectionOthers.nextDay}`
        inspectionOthers.files.forEach((file) => {
          formData.append(`inspection_others_children_files[${index}][]`, file)
        })
      })
    }
    // 修理・整備記録の日付整形
    if (data.maintenances) {
      data.maintenances.forEach((maintenance, index) => {
        maintenance.date = `${maintenance.maintenanceYear}-${maintenance.maintenanceMonth}-${maintenance.maintenanceDay}`
        maintenance.files.forEach((file) => {
          formData.append(`maintenance_children_files[${index}][]`, file)
        })
      })
    }
    // 交換部品の日付整形
    if (data.partsReplaced) {
      data.partsReplaced.forEach((partsReplaced, index) => {
        partsReplaced.date = `${partsReplaced.replacedYear}-${partsReplaced.replacedMonth}-${partsReplaced.replacedDay}`
        partsReplaced.files.forEach((file) => {
          formData.append(`parts_replaced_children_files[${index}][]`, file)
        })
      })
    }
    // 購入部品の日付整形
    if (data.partsPurchased) {
      data.partsPurchased.forEach((partsPurchased, index) => {
        partsPurchased.date = `${partsPurchased.purchasedYear}-${partsPurchased.purchasedMonth}-${partsPurchased.purchasedDay}`
        partsPurchased.files.forEach((file) => {
          formData.append(`parts_purchased_children_files[${index}][]`, file)
        })
      })
    }

    // ETCカード有効期限
    data.etcCard1ExpireDate =
      data.etcCard1ExpireDate &&
      toISOStringWithTimezone(data.etcCard1ExpireDate as Date).split('T')[0]
    data.etcCard2ExpireDate =
      data.etcCard2ExpireDate &&
      toISOStringWithTimezone(data.etcCard2ExpireDate as Date).split('T')[0]
    data.etcCard3ExpireDate =
      data.etcCard3ExpireDate &&
      toISOStringWithTimezone(data.etcCard3ExpireDate as Date).split('T')[0]
    data.etcCard4ExpireDate =
      data.etcCard4ExpireDate &&
      toISOStringWithTimezone(data.etcCard4ExpireDate as Date).split('T')[0]
    data.etcCard5ExpireDate =
      data.etcCard5ExpireDate &&
      toISOStringWithTimezone(data.etcCard5ExpireDate as Date).split('T')[0]

    // 購入日
    data.purchasedDate =
      data.purchasedDate &&
      toISOStringWithTimezone(data.purchasedDate as Date).split('T')[0]
    return { data, formData }
  }

  const submit = async (callback: () => void) => {
    setModalShow(false)
    const hasNotError = await trigger(undefined, { shouldFocus: true })
    if (hasNotError) {
      callback()
    } else {
      toastOnError('入力内容に不備があります')
    }
  }

  const submitCallback = async (to: 'show' | 'new' | 'next' | 'index') => {
    setIsProcessingModalShow(true)
    const raw = structuredClone(getValues())
    const { data, formData } = formatParamsData(raw)
    mutation.mutate(
      { data, formData },
      {
        onSuccess: (res) => {
          scrollTo({ top: 0 })
          if (to === 'new') {
            if (props.type === 'create') {
              return navigate(0)
            }
            navigate('/admin/vehicles/new/')
          } else if (to === 'show') {
            navigate(`/admin/vehicles/${res.data.id}/`)
          } else if (to === 'next') {
            navigateToNextResource(res.data.id)
          } else if (to === 'index') {
            navigate(`/admin/vehicles?focus_id=${res.data.id}`)
          }
        },
        onSettled: () => {
          setIsProcessingModalShow(false)
        },
      }
    )
  }

  /**
   *
   * @param id
   * - 「次のレコード」を取得して遷移するメソッド
   * - TODO: 全件取得してフロント側で「次のレコード」を見つける実装なのでdisplayLimitを越えた場合に機能しない。バックエンド側で「次のレコード」を見つけて返すようにしたい。
   */
  const navigateToNextResource = async (id: number) => {
    // localStorageから検索条件を取得
    const searchParams = getLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.VEHICLES
    )
    const countPerPage = getLocalStorageDisplayLimit()
    const vehiclesPerDisplayLimit = await fetchVehicles(searchParams, {
      displayLimit: 1000,
    }).then((res) => {
      // 検索条件を適用してfetch, 結果をcountPerPage毎に分割
      const arr = []
      while (res.data.vehicles.length > 1) {
        const per = res.data.vehicles.splice(0, countPerPage)
        arr.push(per)
      }
      return arr
    })
    // 「次のデータ」のidを取得
    const nextId = getNextResourceId<Vehicle>(
      LOCAL_STORAGE_PAGE_KEY.VEHICLES,
      id,
      vehiclesPerDisplayLimit
    )
    if (nextId === null) {
      navigate(`/admin/vehicles/`)
      return
    }
    navigate(`/admin/vehicles/${nextId}/`)
  }

  // 画像設定時のコールバック
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setImages([...images, ...acceptedFiles])
    },
    [images]
  )

  const ColumnGroup = styled('div')({
    display: 'inline-flex',
    flexDirection: 'column',
    width: '100%',
  })

  const Section = styled('div')({
    margin: '1rem',
  })

  const EnabledForSubscribedUser = styled('div')({
    pointerEvents: subscribed ? 'auto' : 'none',
    opacity: subscribed ? '1' : '0.45',
  })

  /**
   * keyboardInputを監視し、Enterが押された場合は更新リクエストを叩く(成功時に遷移しない)
   */
  const handleKeyDown = async (e) => {
    if (ShouldSubmitOnKeyPress(e)) {
      const hasNotError = await trigger(undefined, { shouldFocus: true })
      if (hasNotError) {
        setIsProcessingModalShow(true)
        e.preventDefault()
        const copy = structuredClone(getValues())
        const { data, formData } = formatParamsData(copy)
        mutationWithoutNavigation.mutate({ data, formData })
        ;(document.activeElement as HTMLElement)?.blur()
      } else {
        toastOnError('入力内容に不備があります')
      }
    }
  }

  const handleModalCancelSelected = () => {
    setModalShow(false)
  }

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      {!subscribed &&
        setUnsubscribedAlert(ALERT_TEXT.UNSUBSCRIBED_ALERT_MESSAGE)}
      <CustomForm onKeyDown={handleKeyDown}>
        <ColumnGroup>
          {/* 画像の設定 */}
          <EnabledForSubscribedUser>
            <Section id={VEHICLE_LINK_LIST_ITEMS.VEHICLE_IMAGE.ID}>
              <BackGroundWhite>
                <CustomLabel>
                  {VEHICLES.LABELS.FILES}
                  <Controller
                    render={() => (
                      <CustomDropZone
                        multiple={true}
                        onDrop={onDrop}
                        accept={'image/*'}
                      />
                    )}
                    name="files"
                    control={control}
                  />
                  {Object.values(images).map((image: File, index: number) => (
                    <FlexBox flexDirection={'row'} key={index}>
                      <p>
                        <a
                          href={URL.createObjectURL(image)}
                          download={decodeURI(image.name)}
                        >
                          {decodeURI(image.name)}
                        </a>
                      </p>
                      <IconButton
                        component="span"
                        onClick={() =>
                          setImages(images.filter((_, i) => i !== index))
                        }
                      >
                        <CancelIcon />
                      </IconButton>
                    </FlexBox>
                  ))}
                  <CarouselComponent images={images} />
                </CustomLabel>
              </BackGroundWhite>
            </Section>
            <Section id={VEHICLE_LINK_LIST_ITEMS.OPERATION_STATUS.ID}>
              <Status
                {...props}
                register={register}
                control={control}
                errors={errors}
              />
            </Section>
          </EnabledForSubscribedUser>
          <Section id={VEHICLE_LINK_LIST_ITEMS.EQUIPMENT.ID}>
            <Equipments
              {...props}
              control={control}
              vehicleOptions={vehicleOptions}
            />
          </Section>
          <Section id={VEHICLE_LINK_LIST_ITEMS.LICENSES.ID}>
            <License
              {...props}
              register={register}
              control={control}
              errors={errors}
            />
          </Section>
          <Section id={VEHICLE_LINK_LIST_ITEMS.BASIC_INFO.ID}>
            <Basic
              {...props}
              register={register}
              control={control}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
            />
          </Section>
          <EnabledForSubscribedUser>
            <Section id={VEHICLE_LINK_LIST_ITEMS.BODY_INFO.ID}>
              <Body
                {...props}
                register={register}
                control={control}
                errors={errors}
              />
            </Section>
            <Section id={VEHICLE_LINK_LIST_ITEMS.TOOLS.ID}>
              <Tools
                {...props}
                register={register}
                control={control}
                equipmentFiles={equipmentFiles}
                setEquipmentFiles={setEquipmentFiles}
              />
            </Section>
            <Section id={VEHICLE_LINK_LIST_ITEMS.PURCHASE.ID}>
              <Purchase
                {...props}
                register={register}
                control={control}
                errors={errors}
              />
            </Section>
            <Section id={VEHICLE_LINK_LIST_ITEMS.LEASE.ID}>
              <Lease
                {...props}
                register={register}
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
                files={leaseFiles}
                setFiles={setLeaseFiles}
              />
            </Section>
            <Section id={VEHICLE_LINK_LIST_ITEMS.TAX_BASE.ID}>
              <Tax
                {...props}
                register={register}
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
                files={taxBasisFiles}
                setFiles={setTaxBasisFiles}
              />
            </Section>
            <Section id={VEHICLE_LINK_LIST_ITEMS.TAX_WEIGHT.ID}>
              <WeightTax
                {...props}
                register={register}
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
                files={taxWeightFiles}
                setFiles={setTaxWeightFiles}
              />
            </Section>
            <Section id={VEHICLE_LINK_LIST_ITEMS.INSURANCE_COMPULSORY.ID}>
              <InsuranceCompulsory
                {...props}
                register={register}
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
                files={insuranceCompulsoryFiles}
                setFiles={setInsuranceCompulsoryFiles}
              />
            </Section>
            <Section id={VEHICLE_LINK_LIST_ITEMS.INSURANCE_VOLUNTARY.ID}>
              <InsuranceVoluntary
                {...props}
                register={register}
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
                files={insuranceVoluntaryFiles}
                setFiles={setInsuranceVoluntaryFiles}
              />
            </Section>
            <Section id={VEHICLE_LINK_LIST_ITEMS.INSPECTIONS_ANNUALLY.ID}>
              <InspectionAnnually
                {...props}
                register={register}
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
                files={inspectionAnnuallyFiles}
                setFiles={setInspectionAnnuallyFiles}
              />
            </Section>
            <Section id={VEHICLE_LINK_LIST_ITEMS.INSPECTIONS_THREE_MONTHS.ID}>
              <InspectionThreeMonths
                {...props}
                register={register}
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
                files={inspectionThreeMonthsFiles}
                setFiles={setInspectionThreeMonthsFiles}
              />
            </Section>
            <Section id={VEHICLE_LINK_LIST_ITEMS.INSPECTIONS_OTHERS.ID}>
              <InspectionOthers
                {...props}
                register={register}
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
                files={inspectionOthersFiles}
                setFiles={setInspectionOthersFiles}
              />
            </Section>
            <Section id={VEHICLE_LINK_LIST_ITEMS.MAINTENANCES.ID}>
              <Maintenance
                {...props}
                register={register}
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
                files={maintenanceFiles}
                setFiles={setMaintenanceFiles}
              />
            </Section>
            <Section id={VEHICLE_LINK_LIST_ITEMS.PARTS_REPLACED.ID}>
              <PartsReplaced
                {...props}
                register={register}
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
                files={partsReplacedFiles}
                setFiles={setPartsReplacedFiles}
              />
            </Section>
            <Section id={VEHICLE_LINK_LIST_ITEMS.PARTS_PURCHASED.ID}>
              <PartsPurchased
                {...props}
                register={register}
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
                files={partsPurchasedFiles}
                setFiles={setPartsPurchasedFiles}
              />
            </Section>
            <Section id={VEHICLE_LINK_LIST_ITEMS.GREEN_MANAGEMENT.ID}>
              <GreenManagement control={control} />
            </Section>
          </EnabledForSubscribedUser>
          <Section id={VEHICLE_LINK_LIST_ITEMS.MEMO.ID}>
            <BackGroundWhite>
              <HeadingText>メモ</HeadingText>
              <CustomLabel>
                <CustomTextArea {...register('memo')} />
              </CustomLabel>
            </BackGroundWhite>
          </Section>
        </ColumnGroup>
      </CustomForm>
      <FlexBox
        justifyContent={'flex-end'}
        sx={{ marginTop: '8px', marginRight: '1rem' }}
      >
        <ApplyButton
          pattern="block"
          disabled={isLoading}
          onClick={(e) => {
            e.preventDefault()
            setModalShow(true)
          }}
        >
          保存する
        </ApplyButton>
      </FlexBox>
      <FormSubmitSelectPageModal
        isOpen={isModalShow}
        resourceName="車両"
        handlers={{
          cancel: handleModalCancelSelected,
          toShow: () => submit(() => submitCallback('show')),
          toIndex: () => submit(() => submitCallback('index')),
        }}
      ></FormSubmitSelectPageModal>
      <ProcessingModal isOpen={isProcessingModalShow} />
    </React.Fragment>
  )
}
