import * as React from 'react'
import {
  BackGroundPalePink,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import { Box, styled } from '@mui/material'
import { palette, theme } from 'components/theme'
import { VEHICLES } from 'commons/constants'
import { FlexBox } from 'components/atoms/BoxComponents'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

type LeaseProps = {
  data: Vehicle
  leaseImages: File[]
  leaseChildrenFiles: File[][]
}

export default function Lease(props: LeaseProps) {
  const Title = styled('h2')({
    fontSize: '20px',
    margin: 0,
    marginBottom: '16px',
  })
  const SubTitle = styled('p')({
    fontSize: '16px',
    margin: 0,
    color: palette.text.gray.deep,
    fontWeight: 'bold',
  })

  const Text = styled('p')({
    fontSize: '16px',
    margin: 0,
  })
  const Label = styled('p')({
    fontSize: '14px',
    margin: 0,
    color: palette.text.gray.pale,
    marginTop: '32px',
    marginBottom: '8px',
  })
  const TextWrapper = styled('div')({
    width: '300px',
    paddingBottom: '8px',
    paddingLeft: '4px',
    borderBottom: `1px solid ${palette.border.pale}`,
    overflowWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  })
  const TextWrapperShort = styled(TextWrapper)({
    width: '140px',
  })
  const TextWrapperFull = styled(TextWrapper)({
    width: '628px',
  })
  const FileTextWrapper = styled(TextWrapper)({
    width: '100%',
    marginTop: '16px',
  })
  const leaseOptions = {
    hasOptionMaintenance: 'メンテナンス付きリース',
    hasOptionPurchase: '購入選択権付きリース',
    hasOptionTire: 'タイヤリース',
    hasOptionOpenEnd: 'オープンエンド',
    hasOptionCloseEnd: 'クローズエンド',
  }

  const getContractOptionText = React.useCallback(
    (lease: Lease): string => {
      let text = ''
      if (lease.hasOptionMaintenance) text += leaseOptions.hasOptionMaintenance
      if (lease.hasOptionPurchase) {
        if (text !== '') text += ' / '
        text += leaseOptions.hasOptionPurchase
      }
      if (lease.hasOptionTire) {
        if (text !== '') text += ' / '
        text += leaseOptions.hasOptionTire
      }
      if (lease.hasOptionOpenEnd) {
        if (text !== '') text += ' / '
        text += leaseOptions.hasOptionOpenEnd
      }
      if (lease.hasOptionCloseEnd) {
        if (text !== '') text += ' / '
        text += leaseOptions.hasOptionCloseEnd
      }
      return text
    },
    [
      props.data.hasAbs,
      props.data.hasAirbag,
      props.data.hasBackmonitor,
      props.data.hasCarnavi,
      props.data.hasCatacon,
      props.data.hasRadio,
      props.data.hasToolbox,
      props.data.hasWarningTriangle,
    ]
  )

  const formatDate = (year: string, month: string, day: string) => {
    if (year && month && day) return `${year}年${month}月${day}日`
    else return '未入力'
  }

  return (
    <BackGroundWhite>
      <Title>{VEHICLES.LABELS.LEASE}</Title>
      {props.data.leases.length == 0 ? (
        <Text>データがありません</Text>
      ) : (
        props.data.leases.map((l, i) => {
          return (
            <Box mb={3} key={`lease-${i}`}>
              <BackGroundPalePink>
                <SubTitle>
                  {VEHICLES.LABELS.LEASE}
                  {i + 1}
                </SubTitle>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.LEASE_CONTRACT_ID}</Label>
                    <TextWrapper>
                      <Text>{l.contractId || '未入力'}</Text>
                    </TextWrapper>
                  </Box>
                  <Box>
                    <Label>{VEHICLES.LABELS.LEASE_CONTRACT_NUMBER}</Label>
                    <TextWrapper>
                      <Text>{l.contractNumber || '未入力'}</Text>
                    </TextWrapper>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box>
                    <Label>{VEHICLES.LABELS.LEASE_CONTRACT_OPTION}</Label>
                    <TextWrapperFull>
                      <Text>{getContractOptionText(l) || '未入力'}</Text>
                    </TextWrapperFull>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.LEASE_TOTAL_FEE}</Label>
                    <TextWrapperShort>
                      <Text>{l.totalFee ? `${l.totalFee}円` : '未入力'}</Text>
                    </TextWrapperShort>
                  </Box>
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.LEASE_MONTHLY_FEE}</Label>
                    <TextWrapperShort>
                      <Text>
                        {l.monthlyFee ? `${l.monthlyFee}円` : '未入力'}
                      </Text>
                    </TextWrapperShort>
                  </Box>
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.LEASE_DEPOSITE_FEE}</Label>
                    <TextWrapperShort>
                      <Text>
                        {l.depositFee ? `${l.depositFee}円` : '未入力'}
                      </Text>
                    </TextWrapperShort>
                  </Box>
                  <Box>
                    <Label>{VEHICLES.LABELS.LEASE_RESIDUAL_VALUE}</Label>
                    <TextWrapperShort>
                      <Text>
                        {l.residualValue ? `${l.residualValue}円` : '未入力'}
                      </Text>
                    </TextWrapperShort>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.LEASE_CANCEL_PENALTY}</Label>
                    <TextWrapperShort>
                      <Text>
                        {l.cancelPenalty ? `${l.cancelPenalty}円` : '未入力'}
                      </Text>
                    </TextWrapperShort>
                  </Box>
                  <Box>
                    <Label>{VEHICLES.LABELS.LEASE_MONTHLY_DEGRESSION}</Label>
                    <TextWrapperShort>
                      <Text>
                        {l.monthlyDegression
                          ? `${l.monthlyDegression}円`
                          : '未入力'}
                      </Text>
                    </TextWrapperShort>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.LEASE_START_DATE}</Label>
                    <TextWrapper>
                      <Text>
                        {formatDate(l.startYear, l.startMonth, l.startDay)}
                      </Text>
                    </TextWrapper>
                  </Box>
                  <Box>
                    <Label>{VEHICLES.LABELS.LEASE_END_DATE}</Label>
                    <TextWrapper>
                      <Text>{formatDate(l.endYear, l.endMonth, l.endDay)}</Text>
                    </TextWrapper>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.LEASE_PAYMENTS_NUMBER}</Label>
                    <TextWrapperShort>
                      <Text>{l.paymentsNumber || '未入力'}</Text>
                    </TextWrapperShort>
                  </Box>
                  <Box>
                    <Label>{VEHICLES.LABELS.LEASE_PAYMENTS_DATE}</Label>
                    <TextWrapperShort>
                      <Text>{l.paymentsDate || '未入力'}</Text>
                    </TextWrapperShort>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.LEASE_COMPANY_NAME}</Label>
                    <TextWrapper>
                      <Text>{l.companyName || '未入力'}</Text>
                    </TextWrapper>
                  </Box>
                  <Box>
                    <Label>{VEHICLES.LABELS.LEASE_CHARGE_PERSON}</Label>
                    <TextWrapper>
                      <Text>{l.chargePerson || '未入力'}</Text>
                    </TextWrapper>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.LEASE_ADDRESS}</Label>
                    <TextWrapperFull>
                      <Text>{l.address || '未入力'}</Text>
                    </TextWrapperFull>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.LEASE_PHONE_NUMBER}</Label>
                    <TextWrapperShort>
                      <Text>{l.phoneNumber || '未入力'}</Text>
                    </TextWrapperShort>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.LEASE_MEMO}</Label>
                    <TextWrapperFull>
                      <Text>{l.memo || '未入力'}</Text>
                    </TextWrapperFull>
                  </Box>
                </FlexBox>
                <Label>
                  {VEHICLES.LABELS.FILE}(
                  {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
                </Label>
                <FileTextWrapper>
                  {props.leaseChildrenFiles[i]?.length > 0 ? (
                    props.leaseChildrenFiles[i].map((file, i) => {
                      return (
                        <FlexBox key={`${file.name}-${i}`} mb={1}>
                          <InsertDriveFileOutlinedIcon fontSize="small" />
                          <Text sx={{ marginLeft: '8px' }}>
                            <a
                              href={URL.createObjectURL(file)}
                              download={decodeURI(file.name)}
                            >
                              {decodeURI(file.name)}
                            </a>
                          </Text>
                        </FlexBox>
                      )
                    })
                  ) : (
                    <Text>ファイルなし</Text>
                  )}
                </FileTextWrapper>
              </BackGroundPalePink>
            </Box>
          )
        })
      )}
      <Label>
        {VEHICLES.LABELS.FILE}({VEHICLES.LABELS.LEASE}の
        {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_PARENT})
      </Label>
      <FileTextWrapper>
        {props.leaseImages.length > 0 ? (
          props.leaseImages.map((file, i) => {
            return (
              <FlexBox key={`${file.name}-${i}`} mb={1}>
                <InsertDriveFileOutlinedIcon fontSize="small" />
                <Text sx={{ marginLeft: '8px' }}>
                  <a
                    href={URL.createObjectURL(file)}
                    download={decodeURI(file.name)}
                  >
                    {decodeURI(file.name)}
                  </a>
                </Text>
              </FlexBox>
            )
          })
        ) : (
          <Text>ファイルなし</Text>
        )}
      </FileTextWrapper>
    </BackGroundWhite>
  )
}
