import * as React from 'react'
import { Controller, Control } from 'react-hook-form'
import { CustomInput } from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Box } from '@mui/material'
import NumberFormat from 'components/organisms/NumberFormat'

type Props = {
  control: Control<any>
}

function InvoiceRegistrationNumberField({ control }: Props) {
  return (
    <FlexBox style={InvoiceRegistrationNumberStyle.containerStyle}>
      <Box style={InvoiceRegistrationNumberStyle.prefixTextStyle}>T</Box>
      <Controller
        render={({ field }) => (
          <NumberFormat
            {...field}
            customInput={CustomInput}
            format="#############"
            valueIsNumericString={true}
            defaultValue=""
          />
        )}
        name="invoiceRegistrationNumber"
        control={control}
      />
    </FlexBox>
  )
}

const InvoiceRegistrationNumberStyle = {
  containerStyle: {
    justifyContent: 'left',
    alignItems: 'center',
  },
  prefixTextStyle: {
    marginRight: 5,
  },
}

export default InvoiceRegistrationNumberField
