import * as React from 'react'
import {
  BackGroundGray,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  CustomInput,
  CustomDateSelect,
  WithRequiredBadge,
  ErrorMessage,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Box, styled, useTheme } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import Button from '@mui/material/Button'
import { formatCustomDateErrorMessage } from 'commons/errorMessage'
import { VALIDATION_MESSAGES } from 'commons/constants'

const ClearButton = styled('button')({
  background: 'transparent',
  padding: 0,
  border: 0,
  cursor: 'pointer',
  marginLeft: '8px',
  display: 'flex',
  alignItems: 'center',
})

function InsuranceForm(props: {
  type
  register
  control
  setValue
  watch
  errors
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>
  subscribedDriverLedger
}) {
  const { type, register, setValue, watch, errors, subscribedDriverLedger } =
    props
  const types = {
    health: '健康保険',
    pension: '厚生年金保険',
    employment: '雇用保険',
    injury: '労災保険',
  }
  const theme = useTheme()
  const handleClearButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    setValue(`insurance.${type}.subscribedYear`, null, { shouldValidate: true })
    setValue(`insurance.${type}.subscribedMonth`, null, {
      shouldValidate: true,
    })
    setValue(`insurance.${type}.subscribedDay`, null, { shouldValidate: true })
  }
  const requiredInsurance =
    ['executive', 'regular'].includes(watch('profile.employmentStatus')) &&
    subscribedDriverLedger

  const dateErrorMessage = React.useCallback(() => {
    return formatCustomDateErrorMessage({
      year: eval(`errors?.insurance?.${type}?.subscribedYear`),
      month: eval(`errors?.insurance?.${type}?.subscribedMonth`),
      day: eval(`errors?.insurance?.${type}?.subscribedDay`),
    })
  }, [errors])

  return (
    <Box mb={2}>
      <BackGroundGray>
        <CustomLabel>
          {requiredInsurance ? (
            <WithRequiredBadge>{types[type]}の加入年月日</WithRequiredBadge>
          ) : (
            <>{types[type]}の加入年月日</>
          )}
          <FlexBox flexWrap="wrap">
            <CustomDateSelect
              formKeys={{
                year: `insurance.${type}.subscribedYear`,
                month: `insurance.${type}.subscribedMonth`,
                day: `insurance.${type}.subscribedDay`,
              }}
              control={props.control}
              required={requiredInsurance}
            ></CustomDateSelect>
            <ClearButton
              onClick={(e) => {
                handleClearButtonClick(e)
              }}
              sx={{
                [theme.breakpoints.down('sm')]: {
                  display: 'none',
                },
              }}
            >
              <CancelIcon />
            </ClearButton>
            <Button
              onClick={(e) => {
                handleClearButtonClick(e)
              }}
            >
              クリア
            </Button>
          </FlexBox>
          <ErrorMessage>{requiredInsurance && dateErrorMessage()}</ErrorMessage>
        </CustomLabel>
        <CustomLabel>
          {requiredInsurance ? (
            <WithRequiredBadge>保険番号</WithRequiredBadge>
          ) : (
            <>保険番号</>
          )}
          <CustomInput
            {...register(`insurance.${type}.number`, {
              required: type == 'create' || requiredInsurance,
            })}
            onKeyDown={props.onInputKeyDown}
          />
          <ErrorMessage>
            {requiredInsurance &&
              eval(`errors?.insurance?.${type}?.number`) &&
              VALIDATION_MESSAGES.required}
          </ErrorMessage>
        </CustomLabel>
      </BackGroundGray>
    </Box>
  )
}

export default function Insurances(props: {
  register
  control
  setValue
  watch
  errors
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>
  subscribedDriverLedger
}) {
  const {
    register,
    control,
    setValue,
    watch,
    errors,
    onInputKeyDown,
    subscribedDriverLedger,
  } = props

  return (
    <BackGroundWhite>
      <HeadingText>保険</HeadingText>
      <InsuranceForm
        type="health"
        register={register}
        control={control}
        setValue={setValue}
        watch={watch}
        errors={errors}
        onInputKeyDown={onInputKeyDown}
        subscribedDriverLedger={subscribedDriverLedger}
      />
      <InsuranceForm
        type="pension"
        register={register}
        control={control}
        setValue={setValue}
        watch={watch}
        errors={errors}
        onInputKeyDown={onInputKeyDown}
        subscribedDriverLedger={subscribedDriverLedger}
      />
      <InsuranceForm
        type="employment"
        register={register}
        control={control}
        setValue={setValue}
        watch={watch}
        errors={errors}
        onInputKeyDown={onInputKeyDown}
        subscribedDriverLedger={subscribedDriverLedger}
      />
      <InsuranceForm
        type="injury"
        register={register}
        control={control}
        setValue={setValue}
        watch={watch}
        errors={errors}
        onInputKeyDown={onInputKeyDown}
        subscribedDriverLedger={subscribedDriverLedger}
      />
    </BackGroundWhite>
  )
}
