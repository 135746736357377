import { styled } from '@mui/styles'
import { palette } from 'components/theme'
import * as React from 'react'

type AnchorListItem = {
  ID: string
  TEXT: string
}

type AnchorListProps = {
  items: AnchorListItem[]
}

const List = styled('ul')({
  paddingLeft: '8px',
  margin: '70px 0',
})

const ListItem = styled('li')({
  listStyle: 'none',
  marginBottom: '12px',
  cursor: 'pointer',
})

const ListItemLink = styled('p')({
  textDecoration: 'none',
  color: `${palette.text.black.main}`,
})

const headerHeight = 80

const scrollPage = (id: string, isSmooth: boolean) => {
  const elm = document.getElementById(id) as HTMLElement
  if (!elm) throw new Error('target element not found.')
  window.scrollTo({
    top: elm.offsetTop - headerHeight,
    behavior: isSmooth ? 'smooth' : 'auto',
  })
  history.pushState(null, null, `#${id}`)
}

const AnchorList: React.FC<AnchorListProps> = (props) => {
  React.useEffect(() => {
    if (location.hash) {
      scrollPage(location.hash.replace('#', ''), false)
    }
  }, [])

  return (
    <List>
      {props.items.map((item, i) => {
        return (
          <ListItem key={`sidebar-anchor-list-item-${i}`}>
            <ListItemLink
              onClick={() => {
                scrollPage(item.ID, true)
              }}
            >
              {item.TEXT}
            </ListItemLink>
          </ListItem>
        )
      })}
    </List>
  )
}

export default AnchorList
