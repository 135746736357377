import * as React from 'react'
import { Box } from '@mui/material'
import {
  APPLICATION_NAME,
  PAGE_TITLES,
  TABLE_DEFAULT_ROWS,
} from 'commons/constants'
import { ADMIN_MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { Helmet } from 'react-helmet-async'
import EducationVideoLogsTable from 'components/organisms/education_video_logs/Table'
import { useParams } from 'react-router-dom'
import { UserContext } from 'providers/UserProvider'
import useAdminEducationVideoLogsChapter from './hooks/useAdminEducationVideoLogsChapter'

function AdminEducationVideoLogsChapterIndex() {
  const user = React.useContext(UserContext)
  const { id } = useParams()
  const params = {
    educationId: id,
  }
  const defaultSearchParams = {
    attendedYear: user.company.thisYear || new Date().getFullYear(),
    officeId: user.officeId,
  }
  const [searchParams, setSearchParams] =
    React.useState<SearchEducationVideoLog>(defaultSearchParams)
  const [paginateParams, setPaginateParams] = React.useState<PaginateParams>({
    pageNumber: 1,
    displayLimit: TABLE_DEFAULT_ROWS,
  })

  const { adminEducationVideoLogsData, isLoadingAdminEducationVideoLogs } =
    useAdminEducationVideoLogsChapter(params, searchParams, paginateParams)
  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.EDUCATIONS.VIDEO_LOGS ?? APPLICATION_NAME}</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
        }}
      >
        <h1>{ADMIN_MENUS.educations.children.education_video_logs.title}</h1>
      </Box>
      <h3>{adminEducationVideoLogsData?.title}</h3>
      <EducationVideoLogsTable
        data={adminEducationVideoLogsData?.educationVideoLogs}
        totalCount={adminEducationVideoLogsData?.totalCount}
        setSearchParams={setSearchParams}
        paginateParams={paginateParams}
        setPaginateParams={setPaginateParams}
        searchParams={searchParams}
        defaultSearchParams={defaultSearchParams}
        isLoading={isLoadingAdminEducationVideoLogs}
      />
    </PageWrapper>
  )
}

export default AdminEducationVideoLogsChapterIndex
