import * as React from 'react'
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormGetValues,
  useFieldArray,
} from 'react-hook-form'
import {
  BackGroundPalePink,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  ErrorMessage,
  ConvertibleCustomInput,
  ConvertibleCustomDateSelect,
} from 'components/atoms/FormComponents'
import {
  DRIVERS,
  VIOLATION_HISTORIES,
  VALIDATION_MESSAGES,
} from 'commons/constants'
import { Box, styled } from '@mui/material'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { useNavigate } from 'react-router-dom'
import { palette } from 'components/theme'
import { FlexBox } from 'components/atoms/BoxComponents'

type ViolationHistoriesProps = {
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
  handleModeChange: (mapKey: DriverFormModeMapKey, mode: FormMode) => void
}

export default function ViolationHistories(props: ViolationHistoriesProps) {
  const navigate = useNavigate()
  const { fields } = useFieldArray({
    control: props.control,
    name: 'violationHistories',
  })

  const reload = () => {
    if (
      window.confirm('保存していないデータは削除されます。よろしいですか？')
    ) {
      navigate(0)
    }
  }

  return (
    <BackGroundWhite>
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        marginBottom="16px"
      >
        <HeadingText>{DRIVERS.LABELS.VIOLATION_HISTORY}</HeadingText>
        <Box>
          {/* 一時的にコメントアウト */}
          {/* {props.mode == 'show' && (
            <EditButtonOutlined
              onClick={() => {
                props.handleModeChange('violationHistories', 'edit')
              }}
            >
              編集
            </EditButtonOutlined>
          )} */}
          {props.mode == 'edit' && (
            <FlexBox>
              <ApplyButton pattern="marginRight8" type="submit">
                保存する
              </ApplyButton>
              <CancelButtonOutlined onClick={reload}>
                キャンセル
              </CancelButtonOutlined>
            </FlexBox>
          )}
        </Box>
      </FlexBox>
      {fields.length == 0 && <p>データがありません</p>}
      {fields.map((_, i) => {
        return (
          <Box key={`violation-history-${i}`} mb={2}>
            <ViolationHistoryInner
              index={i}
              mode={props.mode}
              control={props.control}
              register={props.register}
              errors={props.errors}
              getValues={props.getValues}
            ></ViolationHistoryInner>
          </Box>
        )
      })}
    </BackGroundWhite>
  )
}

type ViolationHistoryInnerProps = {
  index: number
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
}

const Title = styled('p')({
  fontWeight: 'bold',
  color: palette.text.gray.deep,
})

const ViolationHistoryInner: React.FC<ViolationHistoryInnerProps> = (props) => {
  return (
    <BackGroundPalePink>
      <Title>
        {DRIVERS.LABELS.VIOLATION_HISTORY}
        {props.index + 1}
      </Title>
      <CustomLabel sx={{ marginRight: '32px' }}>
        {VIOLATION_HISTORIES.LABELS.VIOLATION_DATE}
        <ConvertibleCustomDateSelect
          mode={props.mode}
          formKeys={{
            year: `violationHistories.${props.index}.violationYear`,
            month: `violationHistories.${props.index}.violationMonth`,
            day: `violationHistories.${props.index}.violationDay`,
          }}
          control={props.control}
          getValues={props.getValues}
        ></ConvertibleCustomDateSelect>
      </CustomLabel>
      <CustomLabel sx={{ width: '100%' }}>
        {VIOLATION_HISTORIES.LABELS.DESCRIPTION}
        <ConvertibleCustomInput
          mode={props.mode}
          formKey={`violationHistories.${props.index}.description`}
          register={props.register}
          getValues={props.getValues}
        ></ConvertibleCustomInput>
        {props.errors.violationHistories?.[props.index]?.description && (
          <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
        )}
      </CustomLabel>
    </BackGroundPalePink>
  )
}
