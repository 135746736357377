import * as React from 'react'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { TABLE_DEFAULT_ROWS } from 'commons/constants'
import { useParams } from 'react-router-dom'
import UserArticlesTable from 'components/organisms/user_articles/Table'
import { Box } from '@mui/material'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { UserContext } from 'providers/UserProvider'
import { ADMIN_ROUTES } from 'commons/constants'
import useUserArticles from './hooks/useUserArticles'

function UserArticlesIndex() {
  const title = 'お知らせ確認状況'
  const user = React.useContext(UserContext)
  const params = useParams()
  const articleId = parseInt(params.id)
  const defaultSearchParams = { officeId: user.officeId }
  const [searchParams, setSearchParams] =
    React.useState<SearchUserArticle>(defaultSearchParams)
  const [paginateParams, setPaginateParams] = React.useState<PaginateParams>({
    pageNumber: 1,
    displayLimit: TABLE_DEFAULT_ROWS,
  })

  const { userArticlesData, isLoadingUserArticlesData } = useUserArticles(
    articleId,
    searchParams,
    paginateParams
  )

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1>{title}</h1>
        <ApplyButton
          href={[ADMIN_ROUTES.ARTICLES_MAINTENANCES_INDEX, articleId].join('')}
        >
          お知らせに戻る
        </ApplyButton>
      </Box>
      <h3>{userArticlesData?.title}</h3>
      <UserArticlesTable
        data={userArticlesData?.userArticles}
        totalCount={userArticlesData?.totalCount}
        setSearchParams={setSearchParams}
        paginateParams={paginateParams}
        setPaginateParams={setPaginateParams}
        defaultSearchParams={defaultSearchParams}
        isLoading={isLoadingUserArticlesData}
      />
    </PageWrapper>
  )
}

export default UserArticlesIndex
