import * as React from 'react'
import { ADMIN_MENUS } from 'commons/menus'
import DriversForm from 'components/organisms/admin/drivers/Form'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { fetchAllOffices, fetchAllCompanies } from 'components/apis/companies'
import { fetchDriversRoleList } from 'components/apis/drivers'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { UserContext } from 'providers/UserProvider'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import SidebarAnchorList from 'components/organisms/layouts/SidebarAnchorList'
import { FlexBox } from 'components/atoms/BoxComponents'
import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import { useErrorHandler } from 'react-error-boundary'
import { AxiosError } from 'axios'

function AdminDriversNew() {
  const user = React.useContext(UserContext)
  const handleError = useErrorHandler()

  const { data: companies, isLoading: isCompaniesLoading } = useQuery(
    ['admin/drivers/new', 'companies'],
    () => fetchAllCompanies().then((res) => res.data.companies),
    {
      enabled: user.isXmileAdmin || user.isGroupParentCompanyAdmin,
      initialData: [],
      onError: (e: AxiosError) => handleError(e.response.status),
      refetchOnWindowFocus: false,
    }
  )

  const { data: offices, isLoading: isOfficesLoading } = useQuery(
    ['admin/drivers/new', 'offices'],
    () => fetchAllOffices().then((res) => res.data.offices),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      refetchOnWindowFocus: false,
    }
  )

  const { data: roles, isLoading: isRoleListLoading } = useQuery(
    ['admin/drivers/new', 'roles'],
    () => fetchDriversRoleList().then((res) => res.data),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      refetchOnWindowFocus: false,
    }
  )

  const loading = isCompaniesLoading || isOfficesLoading || isRoleListLoading

  return (
    <FlexBox>
      {!loading && <SidebarAnchorList page="drivers"></SidebarAnchorList>}
      <PageWrapper>
        <Helmet>
          <title>{PAGE_TITLES.DRIVERS.NEW ?? APPLICATION_NAME}</title>
        </Helmet>
        <h1>{ADMIN_MENUS.masters.children.drivers.title}</h1>
        {loading ? (
          <CircularProgressBox />
        ) : (
          <DriversForm
            type="create"
            companies={companies}
            offices={offices}
            roles={roles}
            isAdminUser={user.admin}
            isXmileSystemAdminUser={user.isXmileAdmin}
          />
        )}
      </PageWrapper>
    </FlexBox>
  )
}

export default AdminDriversNew
