import * as React from 'react'
import { styled } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { palette } from 'components/theme'
import { WithDescriptionTooltipIcon } from 'components/atoms/FormComponents'
import { ALERT_TEXT } from 'commons/constants'

type VideoViewButtonProps = {
  onClick: () => void
  disabled: boolean
}

export const VideoViewButton = (props: VideoViewButtonProps) => {
  const theme = useTheme()

  const ViewButton = styled('button')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    'box-sizing': 'border-box',
    'flex-direction': 'row',
    'align-items': 'center',
    padding: '0px 16px',
    gap: '8px',
    width: '89px',
    height: '40px',
    background: props.disabled ? '#C0C0C0' : '#FFFFFF',
    border: '1px solid #CDCFD1',
    'border-radius': '6px',
    flex: 'none',
    order: '0',
    'flex-grow': '0',
    margin: '10px',
    cursor: props.disabled ? 'default' : 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '97.5%',
      height: '35px',
      position: 'absolute',
      top: '64px',
      left: '1.25%',
      right: '1.25%',
      alignSelf: 'stretch',
    },
  })

  const ViewText = styled('div')({
    'font-family': 'Roboto',
    'font-style': 'normal',
    'font-weight': '700',
    'font-size': '16px',
    'line-height': '100%',
    'letter-spacing': '0.04em',
    color: props.disabled ? palette.text.gray.deep : '#202830',
    flex: 'none',
    order: '1',
    'flex-grow': '0',
    [theme.breakpoints.down('sm')]: {
      'margin-bottom': '5px',
    },
  })

  const ViewImage = styled('img')({
    width: '24px',
    height: '18px',
    [theme.breakpoints.down('sm')]: {
      width: '18px',
      height: '16px',
    },
  })

  return (
    <ViewButton onClick={props.onClick} disabled={props.disabled}>
      <ViewImage src="/images/playMovie.png" />
      <ViewText>再生</ViewText>
    </ViewButton>
  )
}

export const ConversionStatusButton = (props: { status: string }) => {
  const theme = useTheme()

  const statusMessage = (status) => {
    switch (status) {
      case 'success':
        return '変換完了'
      case 'failure':
        return '変換失敗'
      default:
        return '変換中'
    }
  }

  const messageColor = (status) => {
    switch (status) {
      case 'success':
        return palette.primary.deep
      case 'failure':
        return palette.error.main
      default:
        return palette.text.black.main
    }
  }

  const ViewButton = styled('button')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    'box-sizing': 'border-box',
    'flex-direction': 'row',
    'align-items': 'center',
    padding: '0px 16px',
    gap: '8px',
    width: '89px',
    height: '40px',
    background: '#DCDCDC',
    border: '1px solid #CDCFD1',
    'border-radius': '6px',
    flex: 'none',
    order: '0',
    'flex-grow': '0',
    margin: '10px',
    cursor: 'default',
    [theme.breakpoints.down('sm')]: {
      width: '97.5%',
      height: '35px',
      position: 'absolute',
      top: '64px',
      left: '1.25%',
      right: '1.25%',
      alignSelf: 'stretch',
    },
  })

  const ViewText = styled('div')({
    'font-family': 'Roboto',
    'font-style': 'normal',
    'font-weight': '700',
    'font-size': '16px',
    'line-height': '100%',
    'letter-spacing': '0.04em',
    color: messageColor(props.status),
    flex: 'none',
    order: '1',
    'flex-grow': '0',
    [theme.breakpoints.down('sm')]: {
      'margin-bottom': '5px',
    },
  })

  return (
    <ViewButton>
      <ViewText>{statusMessage(props.status)}</ViewText>
    </ViewButton>
  )
}

// 変換状況説明のためのツールチップ
export const ConversionStatusTooltip = (props: { status: string }) => {
  const conversionStatusText = (status) => {
    switch (status) {
      case 'success':
        return ALERT_TEXT.AVI_VIDEO_ALERT_SHOW_SUCCESS
      case 'failure':
        return ALERT_TEXT.AVI_VIDEO_ALERT_SHOW_FAILED
      default:
        return ALERT_TEXT.AVI_VIDEO_ALERT_SHOW_PENDING
    }
  }

  return (
    <WithDescriptionTooltipIcon text={conversionStatusText(props.status)} />
  )
}
