import { useQuery } from '@tanstack/react-query'
import { createQueryKey } from 'commons/functions'
import { fetchNotifications } from 'components/apis/notifications'
import { useErrorHandler } from 'react-error-boundary'

export default function useNotifications(
  searchParams?: SearchNotification,
  paginateParams?: PaginateParams
) {
  const errorHandler = useErrorHandler()
  const queryKey = createQueryKey('notifications', {
    ...searchParams,
    ...paginateParams,
  })

  const { data: notificationsData, isLoading: isLoadingNotificationsData } =
    useQuery({
      queryKey,
      queryFn: async () => {
        const res = await fetchNotifications(searchParams, paginateParams)

        return res.data
      },
      onError(error) {
        errorHandler(error)
      },
    })

  return {
    notificationsData,
    isLoadingNotificationsData,
  }
}
