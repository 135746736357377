import { AxiosResponse } from 'axios'
import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import {
  addParamPrefix,
  camelCaseKeysToUnderscore,
  convertValueToInt,
} from 'commons/functions'

export function fetchPartnerCompanies(
  searchParams?: SearchPartnerCompany,
  paginateParams?: PaginateParams
) {
  const customSearchParams = searchParams
    ? addParamPrefix(searchParams, 'search')
    : {}
  const customPaginateParams = paginateParams
    ? addParamPrefix(paginateParams, 'pagination')
    : {}
  const params = camelCaseKeysToUnderscore({
    ...customSearchParams,
    ...customPaginateParams,
  })
  return axios.get(API_ENDPOINTS.PARTNER_COMPANIES, {
    params: params,
  })
}

export function getPartnerCompany(
  id: number
): Promise<AxiosResponse<PartnerCompany>> {
  return axios.get([API_ENDPOINTS.PARTNER_COMPANIES, id].join(''))
}

export function fetchPartnerCompanyList(searchParams?: {
  companyId?: number
  officeId?: number
  relationCategory?: string
}): Promise<AxiosResponse<{ partnerCompanies: PartnerCompanySelectOption[] }>> {
  const customSearchParams = searchParams
    ? addParamPrefix(searchParams, 'search')
    : {}
  const params = camelCaseKeysToUnderscore({
    ...customSearchParams,
  })
  return axios.get(API_ENDPOINTS.PARTNER_COMPANY_LIST, { params: params })
}

export function deletePartnerCompany(id: number) {
  return axios.delete([API_ENDPOINTS.PARTNER_COMPANIES, id].join(''))
}

export function createPartnerCompany(params: PartnerCompany) {
  const newParams = camelCaseKeysToUnderscore(convertValueToInt(params))
  return axios.post(API_ENDPOINTS.PARTNER_COMPANIES, newParams)
}

export function updatePartnerCompany(params: PartnerCompany) {
  const newParams = camelCaseKeysToUnderscore(convertValueToInt(params))
  return axios.patch(
    [API_ENDPOINTS.PARTNER_COMPANIES, params.id].join(''),
    newParams
  )
}

export function fetchProposalCandidateCarriers(
  searchParams?: SearchProposalCandidateCarriers,
  paginateParams?: PaginateParams
) {
  const customSearchParams = searchParams
    ? addParamPrefix(searchParams, 'search')
    : {}
  const customPaginateParams = paginateParams
    ? addParamPrefix(paginateParams, 'pagination')
    : {}
  const params = camelCaseKeysToUnderscore({
    ...customSearchParams,
    ...customPaginateParams,
  })

  return axios.get(API_ENDPOINTS.PROPOSAL_CANDIDATE_CARRIERS, {
    params: params,
  })
}

export function importPartnerCompany(formData) {
  const headers = { 'Content-Type': 'multipart/form-data' }
  return axios.post(API_ENDPOINTS.PARTNER_COMPANIES_CSV_IMPORT, formData, {
    headers: headers,
  })
}

export function importPartnerCompanyVehicle(formData) {
  const headers = { 'Content-Type': 'multipart/form-data' }
  return axios.post(
    API_ENDPOINTS.PARTNER_COMPANIES_VEHICLE_CSV_IMPORT,
    formData,
    {
      headers: headers,
    }
  )
}
