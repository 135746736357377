import * as React from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { SUBMIT_BUTTON_TEXT } from 'commons/constants'
import { toastOnError, toastOnSuccess } from 'commons/toaster'
import { createEducationAttendances } from 'components/apis/educationAttendances'
import { getEducationTests } from 'components/apis/education_tests'
import { fetchEducationSetting } from 'components/apis/education_settings'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import {
  CustomForm,
  CustomLabel,
  ErrorMessage,
} from 'components/atoms/FormComponents'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { UserContext } from 'providers/UserProvider'
import DriverLabel from './DriverLabel'
import { slackNotification } from 'commons/slackNotification'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { ResultComponent } from './Result'
import { ThisMonthVideoComponent } from './ThisMonthVideo'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Box } from '@mui/material'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { fetchEducationVideoLogViewedMonth } from 'components/apis/educationVideoLogs'
import NotShowVideoModal from 'components/organisms/education_tests/NotShowVideoModal'
import Modal from 'react-modal'
import EducationCommentForm from './educationComment'
import { fetchEducationComment } from 'components/apis/educationComments'

export default function EducationTestsForm(props: {
  showAnswer?: boolean
  correctAnswerSum?: number
  selectedAnswers?: number[]
  isPass?: string
  releaseVersion?: number
}) {
  const userContext = React.useContext(UserContext)
  const { id: userId } = userContext
  const { id, educationId } = useParams()
  const handleError = useErrorHandler()
  const [questionAnswers, setQuestionAnswers] = React.useState<
    QuestionAnswer[]
  >([])
  const [showAnswer, setShowAnswer] = React.useState<boolean>(
    props.showAnswer ?? false
  )
  const [result, setResult] = React.useState<number[]>(null)
  const [correctAnswerSum, setCorrectAnswerSum] = React.useState<number>(
    props.correctAnswerSum
  )
  const [isViewedVideo, setIsViewedVideo] = React.useState<boolean>(true)
  const [isPass, setIsPass] = React.useState<string>(props.isPass)
  const [passScore, setPassScore] = React.useState<number>(null)
  const [isShuffle, setIsShuffle] = React.useState<string>('0')
  const [isAfterVideo, setIsAfterVideo] = React.useState<string>('0')
  const [isCommentable, setIsCommentable] = React.useState<string>('0')
  const [isPresentAnnualStartMonth, setIsPresentAnnualStartMonth] =
    React.useState<boolean>(false)
  const [isPresentEducationComment, setIsPresentEducationComment] =
    React.useState<boolean>(false)
  const [loading, setLoading] = React.useState(true)

  // WEBテストの設問数を管理する変数。
  // 修正する際は、education_passing_score.rbの値も修正してください。
  const questionCount = 10
  const safetyEducationType = 'safety'
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      driverId: userId,
      answers: [],
    },
  })
  const watchDriverId = watch('driverId')
  const navigate = useNavigate()

  //Vehicleなどでも利用されているため、Componentに切り分けたい
  const InvisibleForMobile = styled('div')({
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  })

  React.useEffect(() => {
    const fetchQuestionAnswers = async () => {
      try {
        const hasCorrectAnswer = (qa) => {
          return qa.answers.some((answer) => answer.text === qa.correctAnswer)
        }
        const findCorrectAnswerId = (qa) => {
          return qa.answers.find((answer) => answer.text === qa.correctAnswer)
            ?.id
        }
        const questionAnswers = (
          await getEducationTests(
            parseInt(educationId ?? id),
            getValues('driverId') ?? userId,
            props.releaseVersion
          )
        ).data.questionAnswers
        setQuestionAnswers(questionAnswers)

        if (props.selectedAnswers) {
          const correctAnswerIds: number[] = questionAnswers
            .filter(hasCorrectAnswer)
            .map(findCorrectAnswerId)

          const result = correctAnswerIds.map((id) =>
            props.selectedAnswers.includes(id) ? 1 : 0
          )

          setResult(Object.values(result))
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    fetchQuestionAnswers()
  }, [watchDriverId])

  React.useEffect(() => {
    fetchEducationSetting()
      .then((response) => {
        setPassScore(response.data.passingScore)
        setIsShuffle(response.data.isShuffle)
        setIsAfterVideo(response.data.isAfterVideo)
        setIsCommentable(response.data.isCommentable)
        setIsPresentAnnualStartMonth(response.data.isPresentAnnualStartMonth)
      })
      .catch((e) => {
        handleError(e.response.status)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  React.useEffect(() => {
    fetchEducationVideoLogViewedMonth(
      parseInt(educationId ?? id),
      getValues('driverId') ?? userId
    )
      .then((response) => {
        if (response.data.viewed !== 'common_account' && isAfterVideo === '1') {
          setIsViewedVideo(response.data.viewed)
        }
      })
      .catch((e) => {
        handleError(e.response.status)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [watchDriverId, isAfterVideo])

  React.useEffect(() => {
    fetchEducationComment({
      educationId: educationId ?? id,
      driverId: getValues('driverId') ?? userId,
    })
      .then((res) => {
        const educationComment = res.data
        if (!educationComment.id) {
          setIsPresentEducationComment(false)
        } else {
          setIsPresentEducationComment(true)
        }
      })
      .catch((e) => {
        handleError(e)
      })
  }, [watchDriverId])

  const mutation = useMutation({
    mutationFn: createEducationAttendances,
    onSuccess: () => {
      setShowAnswer(true)
      toastOnSuccess('回答完了')
      scroll.scrollToTop()
    },
    onError: (e: AxiosError) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors)
          // 422エラー時にslack通知する
          slackNotification(e, userContext)
          break
        default:
          handleError(e.response.status)
      }
    },
  })

  const onSubmit = (data: { driverId: number; answers: string[] }) => {
    const { driverId, answers } = data
    const getSelectedAnswers = (): { id: number; text: string }[] => {
      return answers.map((ans) => JSON.parse(ans))
    }
    const getCorrectAnswers = (): number[] => {
      return questionAnswers.map((q, i) =>
        getSelectedAnswers()[i].text === q.correctAnswer ? 1 : 0
      )
    }

    const selectedAnswersId = getSelectedAnswers().map((ans) => ans.id)
    const correctCount = getCorrectAnswers().reduce(
      (sum, value) => sum + value,
      0
    )
    const is_pass = correctCount >= passScore ? 'pass' : 'fail'
    const params = {
      userId: driverId,
      educationId: educationId ?? id,
      questionsId: questionAnswers.map((q) => q.question.id),
      selectedAnswersId,
      correctCount,
      questionCount,
      isWebtest: true,
      educationType: safetyEducationType,
    }
    mutation.mutate(params)

    setResult(getCorrectAnswers())
    setCorrectAnswerSum(correctCount)
    setIsPass(is_pass)
  }

  const showResultSymbol = (result) => (result == 1 ? '✅' : '❌')

  function getQuestionPdf(educationId: string) {
    const releaseVersion = props.releaseVersion || 'latest'
    const questionUrl = `/education_export_pdf/${educationId}/question.pdf?release_version=${releaseVersion}`
    window.open(questionUrl, '_blank')
  }

  function getAnswerPdf(educationId: string) {
    const releaseVersion = props.releaseVersion || 'latest'
    const answerUrl = `/education_export_pdf/${educationId}/answer.pdf?release_version=${releaseVersion}`
    window.open(answerUrl, '_blank')
  }

  return (
    <>
      {correctAnswerSum != null && (
        <>
          <ResultComponent
            questionAnswers={questionAnswers}
            correctAnswerSum={correctAnswerSum}
            isPass={isPass}
          />
          {isPass == 'pass' &&
            isCommentable === '1' &&
            isPresentAnnualStartMonth &&
            !isPresentEducationComment && (
              <EducationCommentForm
                educationId={parseInt(educationId ?? id)}
                driverId={watch('driverId')}
              />
            )}
          {/* 合否に関わらず今月の動画を案内 */}
          <ThisMonthVideoComponent />
        </>
      )}
      <BackGroundWhite>
        <CustomForm onSubmit={handleSubmit(onSubmit)}>
          <>
            <div style={{ display: 'flex' }}>
              <DriverLabel
                control={control}
                setValue={setValue}
                errors={errors}
                answered={correctAnswerSum != null}
              />
              {userContext.admin ? (
                <InvisibleForMobile>
                  <FlexBox>
                    <Box>
                      <ApplyButton
                        pattern="width120"
                        onClick={() => getQuestionPdf(educationId ?? id)}
                      >
                        問題を印刷
                      </ApplyButton>
                    </Box>
                    <Box ml={2}>
                      <ApplyButton
                        pattern="width120"
                        onClick={() => getAnswerPdf(educationId ?? id)}
                      >
                        解答を印刷
                      </ApplyButton>
                    </Box>
                  </FlexBox>
                </InvisibleForMobile>
              ) : (
                <div></div>
              )}
            </div>
            <CustomLabel
              sx={{
                marginBottom: '0',
              }}
            >
              合格点は{passScore}点以上です
            </CustomLabel>
            {isShuffle == '1' && (
              <CustomLabel
                sx={{
                  fontSize: '13px',
                  marginBottom: '0',
                }}
              >
                ※2回目以降の受験は回答の選択肢がシャッフルされます
              </CustomLabel>
            )}
            {userContext.role === 'system_admin' && (
              <CustomLabel
                sx={{
                  fontSize: '13px',
                }}
              >
                <FlexBox>
                  {isShuffle == '1'
                    ? '2回目以降の回答の選択肢を固定する場合は、'
                    : '2回目以降の回答の選択肢をシャッフルする場合は、'}
                  <a href="/admin/educations/settings/">各種設定</a>
                  から設定できます
                </FlexBox>
              </CustomLabel>
            )}
            {loading ? (
              <CircularProgressBox />
            ) : (
              <ol>
                {questionAnswers.map((item, index) => (
                  <li style={{ marginBottom: '2rem' }} key={index}>
                    <b>
                      {result && showResultSymbol(result[index])}
                      {item.question.text}
                    </b>
                    <br />
                    {item.answers.map((answer, i) => (
                      <React.Fragment key={i}>
                        <label>
                          <input
                            {...register(`answers.${index}`, {
                              required: '回答を入力してください',
                            })}
                            value={JSON.stringify(answer)}
                            type="radio"
                            checked={props?.selectedAnswers?.includes(
                              answer.id
                            )}
                          />
                          {answer.text}
                        </label>
                        <br />
                      </React.Fragment>
                    ))}
                    {showAnswer && <p>正解：{item.correctAnswer}</p>}
                    {showAnswer && item.question.explanation && (
                      <div>
                        <p style={{ margin: 'auto auto 0px -12px' }}>
                          【設問の解説】
                        </p>
                        <span style={{ whiteSpace: 'pre-line' }}>
                          {item.question.explanation}
                        </span>
                      </div>
                    )}
                    {errors[index] && (
                      <ErrorMessage>{errors[index].message}</ErrorMessage>
                    )}
                  </li>
                ))}
              </ol>
            )}
          </>
          <FlexBox>
            {props.selectedAnswers == null && (
              <ApplyButton pattern="block" type="submit" disabled={showAnswer}>
                {SUBMIT_BUTTON_TEXT.TESTS_SHOW}
              </ApplyButton>
            )}
            {isPass === 'fail' && (
              <Box ml={2}>
                <ApplyButton onClick={() => navigate(0)}>
                  再受験する
                </ApplyButton>
              </Box>
            )}
          </FlexBox>
          {Object.keys(errors).length != 0 &&
            toastOnError('未入力の項目があります')}
        </CustomForm>
        {props === undefined && showAnswer && (
          <p>
            もう一度受講する場合、ブラウザの更新ボタンを押して画面をリロードしてください。
          </p>
        )}
      </BackGroundWhite>
      <Modal
        isOpen={!isViewedVideo}
        closeTimeoutMS={500}
        style={{
          overlay: {
            minHeight: '100%',
            zIndex: 1000,
          },
          content: {
            marginLeft: 0,
            margin: 'auto',
            display: 'flex',
            flexFlow: 'wrap',
            flexDirection: 'column',
            justifyContents: 'center',
            alignItems: 'center',
          },
        }}
      >
        <NotShowVideoModal />
      </Modal>
    </>
  )
}
