import { TAX_RATE } from 'commons/constants'

export const range = (from: number, to: number) => {
  const max = to - from
  if (max < 0) {
    return [...Array(-max)].map((_, i) => from - i)
  } else {
    return [...Array(max)].map((_, i) => from + i)
  }
}

export const convertToStringYen = (num: number) =>
  num !== null
    ? '¥'.concat(String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'))
    : ''

export const addCommas = (num) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
export const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, '')

export function isDate(sDate) {
  if (sDate.toString() == parseInt(sDate).toString()) return false
  const tryDate = new Date(sDate)
  return tryDate && tryDate.toString() != 'NaN' && tryDate instanceof Date
}

export function convertValueToInt(obj) {
  Object.entries(obj).map(([key, value]) => {
    const numValue = parseInt(value as string)
    if (!isNaN(numValue)) {
      if (!isDate(value)) {
        obj[key] = numValue
      }
    }
  })
  return obj
}

export function camelCaseKeysToUnderscore(obj) {
  if (typeof obj != 'object') return obj

  for (const oldName in obj) {
    // Camel to underscore
    const newName = oldName.replace(/([A-Z])/g, function ($1) {
      return '_' + $1.toLowerCase()
    })

    // Only process if names are different
    if (newName != oldName) {
      // Check for the old property name to avoid a ReferenceError in strict mode.
      if (Object.prototype.hasOwnProperty.call(obj, oldName)) {
        obj[newName] = obj[oldName]
        delete obj[oldName]
      }
    }

    // Recursion
    if (typeof obj[newName] == 'object') {
      obj[newName] = camelCaseKeysToUnderscore(obj[newName])
    }
  }
  return obj
}

/**
 * Delay for a number of milliseconds
 */
export function sleep(delay) {
  const start = new Date().getTime()
  while (new Date().getTime() < start + delay);
}

export function addParamPrefix(params, prefix: 'search' | 'pagination') {
  return Object.entries(params).reduce((obj, [key, value]) => {
    return {
      ...obj,
      [`${prefix}[${key}]`]: value,
    }
  }, {})
}

export function formatDate(
  dateString,
  options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
  locales: Intl.LocalesArgument = 'ja-JP'
) {
  // 例：
  // 入力値 "2023-07-21"
  // 出力値（デフォルト）"2023年7月21日(金)"
  const date = new Date(dateString)
  const formattedDate = date.toLocaleString(locales, options)
  return formattedDate
}

export function toISOStringWithTimezone(date: Date): string {
  const pad = function (str: string): string {
    return ('0' + str).slice(-2)
  }
  const year = date.getFullYear().toString()
  const month = pad((date.getMonth() + 1).toString())
  const day = pad(date.getDate().toString())
  const hour = pad(date.getHours().toString())
  const min = pad(date.getMinutes().toString())
  const sec = pad(date.getSeconds().toString())
  const tz = -date.getTimezoneOffset()
  const sign = tz >= 0 ? '+' : '-'
  const tzHour = pad((tz / 60).toString())
  const tzMin = pad((tz % 60).toString())
  return `${year}-${month}-${day}T${hour}:${min}:${sec}${sign}${tzHour}:${tzMin}`
}

// 引数の日付を月初日に変換する関数
export const toBeginningOfMonth = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), 1)

// 引数の日付を月末日に変換する関数
export const toEndOfMonth = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0)

// 引数の日付をYYYY-MM-DD形式に変換する関数
export const toFormattedDate = (date: Date): string =>
  toISOStringWithTimezone(date).split('T')[0]

// 引数の日付を月初日のYYYY-MM-DD形式に変換する関数
export const toFormattedBeginningOfMonth = (date: Date): string =>
  toFormattedDate(toBeginningOfMonth(date))

// 引数の日付を月末日のYYYY-MM-DD形式に変換する関数
export const toFormattedEndOfMonth = (date: Date): string =>
  toFormattedDate(toEndOfMonth(date))

export function retrieveNumbers(text: string | number): number {
  return typeof text === 'number' ? text : parseInt(text.replace(/[^0-9]/g, ''))
}

export function convertToFloat(text: string | number): number {
  return typeof text === 'number'
    ? text
    : parseFloat(text.replace(/[^\d.]/g, ''))
}

export function getFiscalYear(date: Date) {
  return date.getMonth() + 1 < 4 ? date.getFullYear() - 1 : date.getFullYear()
}

// 指定した年月の最終日付を返す関数
export function getEndDate(year: number, month: number) {
  return new Date(year, month + 1, 0)
}

export function calculateHighwayTax(fee: number, vehicleCount?: number) {
  if (!fee) {
    return 0
  }

  return Math.floor((fee / (1 + TAX_RATE)) * TAX_RATE) * (vehicleCount || 1)
}

// 消費税の計算
export function CalculateTax(fees: number[], vehicleCount?: number) {
  const subtotal = fees.reduce(function (sum, value) {
    if (!value) {
      value = 0
    }
    return sum + value
  }, 0)

  return Math.floor(subtotal * TAX_RATE) * (vehicleCount || 1)
}

// ファイルが動画ファイルか判定する関数
export const isVideoFile = (file: File): boolean => {
  if (file && file.type) {
    return file.type.startsWith('video/')
  }
  return false
}

// ファイルが,再生できないファイル形式か判定する関数
export const isUnPlayableFile = (file: File): boolean => {
  if (file && file.type) {
    if (file.type === 'video/x-msvideo' || file.type === 'video/mp2t') {
      return true
    }
    // tsファイル形式を読み込めないブラウザがあるため、その応急迂処置
    if (file.type === 'application/octet-stream' && file.name.endsWith('.ts')) {
      return true
    }
  }
  return false
}

// アプリ上で再生できない動画の変換状態をチェックする
export const checkVideoConversionsStatuses = (
  fileName: string,
  statuses
): string | undefined => {
  const videoStatus = statuses?.find((status) => status.file === fileName)
  return videoStatus ? videoStatus.status : undefined
}

// base64データをFileオブジェクトに変換する
export const base64ToFile = (base64, fileName, contentType) => {
  const bin = atob(base64.replace(/^.*,/, ''))
  const buffer = new Uint8Array(bin.length).map((_, i) => bin.charCodeAt(i))
  return new File([buffer.buffer], fileName, { type: contentType })
}

export const textLengthWithoutNewline = (text: string): number => {
  if (!text) {
    return 0
  } else {
    return text.replace(/\n/g, '').trim().length
  }
}

/**
 * useQueryのqueryKeyを作成する関数
 *
 * @param {string} prefix
 * @param {Record<string, string | number | boolean | number[] | string[] | Date | Date[]>} searchParams - 検索条件のオブジェクト
 * @return {string[]} - ["prefix_key1:value1|key2:value2"]」の形式の配列
 */
export const createQueryKey = (
  prefix: string,
  searchParams: Record<
    string,
    string | number | boolean | number[] | string[] | Date | Date[]
  >
): string[] => {
  const searchParamsKeys = Object.entries(searchParams)
    .map(([key, value]) => `${key}:${encodeURIComponent(String(value))}`)
    .join('|')

  const keys = prefix + '_' + searchParamsKeys

  return [keys]
}

export function extractNumber(str: string): string | null {
  if (typeof str !== 'string' || !str.includes('¥')) return str

  // 不要な文字（円記号、スペース、カンマ）を取り除く
  return str.replace(/[¥ ,]/g, '')
}
