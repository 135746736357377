import * as React from 'react'
import { MENUS } from 'commons/menus'
import AccidentsForm from 'components/organisms/accidents/Form'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { getAccident } from 'components/apis/accidents'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import { Helmet } from 'react-helmet-async'

function AccidentsEdit() {
  const params = useParams()
  const id = parseInt(params.id)
  const { data: accident, isLoading } = useQuery(
    [`accidents/edit`, 'accident'],
    () => getAccident(id).then((res) => res.data)
  )

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.ACCIDENTS.EDIT ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>{MENUS.inspectionAndAccident.children.accidents.title}</h1>
      {isLoading ? (
        <CircularProgressBox />
      ) : (
        <AccidentsForm type="update" data={accident} />
      )}
    </PageWrapper>
  )
}

export default AccidentsEdit
