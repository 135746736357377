import * as React from 'react'
import { styled } from '@mui/styles'
import { palette } from 'components/theme'

type Props = {
  href: string
  children?: React.ReactNode | string
  key?: number
  onClick?: () => void
}

export function LinkAsPlanText(props: Props) {
  const LinkAsPlanTextElement = styled('a')({
    textDecoration: 'none',
    color: palette.text.black.main,
  })
  return (
    <LinkAsPlanTextElement href={props.href} onClick={props.onClick}>
      {props.children}
    </LinkAsPlanTextElement>
  )
}
