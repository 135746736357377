import * as React from 'react'

function NotPurchasedError() {
  const formLink =
    'https://docs.google.com/forms/d/e/1FAIpQLSfeKK_ms7mKGZXUubu3MOeJel5D-1m1z4DI8wfaxEm5hAbeXg/viewform'
  return (
    <>
      <h3>
        ライセンスが未購入です。機能を利用するにはライセンスを購入してください。
      </h3>
      <h3>
        詳しくはお電話（03-6161-6018）もしくは、
        <a href={formLink} target="_blank" rel="noopener noreferrer">
          問い合わせフォーム
        </a>
        からお問合せください
      </h3>
      <p>
        ※電話受付時間：土日祝を除く平日10:00〜18:00
        <br />
        ※フォーム問い合わせ後、数営業日以内にご返信させて頂きます。
      </p>
    </>
  )
}

export default NotPurchasedError
