import * as React from 'react'
import { ThemeProvider, useMediaQuery } from '@mui/material'
import { styled } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { theme, palette } from 'components/theme'
import { LinkAsPlanText } from 'components/atoms/LinkComponents'
import Collapse from '@mui/material/Collapse'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import MenuIcon from '@mui/icons-material/Menu'
import { ButtonAsPlanText } from 'components/atoms/ButtonComponenets'
import Badge from '@mui/material/Badge'
import {
  LOCAL_STORAGE_USER_ID,
  LOCAL_STORAGE_USER_NAME,
  PRD_DOMAIN,
  EXECUTIVE_USER_ROUTE_PATH,
  LOCAL_STORAGE_PAGE_KEY,
  LOCAL_STORAGE_DISPLAY_LIMIT_KEY,
} from 'commons/constants'
import useMenu from './Menu.hooks'
import { removeLocalStoragePageNumber } from 'commons/table'

type Props = {
  auth: boolean
  sighOutPath: string
  companyName: string
  officeName?: string
  fullName: string
  isAdmin: boolean
  isXmileAdmin: boolean
  isAssistantStaff: boolean
  isDispatcher: boolean
  isExecutive: boolean
  canNavigate: boolean
}

const AppTitle = styled('a')({
  fontWeight: 'bold',
  fontSize: '36px',
  color: palette.header.text,
  textDecoration: 'none',
})

const badgeStyle = {
  '& .MuiBadge-badge': {
    color: 'white',
    backgroundColor: 'red',
    fontSize: '12px',
    fontWeight: 'bold',
  },
}

function showMenu(menus: Menus, isCommentable: boolean, isChild = false) {
  return Object.values(menus).map((menu: Menu, index: number) => {
    // 開発中のメニューは本番環境では表示しない
    if (menu?.isDeveloping && document.domain == PRD_DOMAIN) {
      return
    }

    // コメント機能が無効の場合は非表示
    if (!isCommentable && menu.name == 'education_comments') return

    const hasChild = Boolean(menu.children)
    if (hasChild) {
      const [checked, setChecked] = React.useState(false)
      const handleClick = () => {
        setChecked((prev) => !prev)
      }
      return (
        <React.Fragment key={index}>
          <ButtonAsPlanText
            onClick={handleClick}
            sx={{ paddingLeft: '1rem', fontSize: '1rem' }}
          >
            {menu.showBadge ? (
              <Badge
                badgeContent={'!'}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={badgeStyle}
              >
                {menu.title}
              </Badge>
            ) : (
              menu.title
            )}
            {checked ? (
              <ArrowDropUpIcon style={{ color: palette.text.black.main }} />
            ) : (
              <ArrowDropDownIcon style={{ color: palette.text.black.main }} />
            )}
          </ButtonAsPlanText>
          <Collapse in={checked}>
            {showMenu(menu.children, isCommentable, true)}
          </Collapse>
        </React.Fragment>
      )
    }

    return (
      <LinkAsPlanText href={menu.url} key={index}>
        <MenuItem>
          {isChild && '└'}
          {menu.showBadge ? (
            <Badge
              badgeContent={'!'}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              sx={badgeStyle}
            >
              {menu.title}
            </Badge>
          ) : (
            menu.title
          )}
        </MenuItem>
      </LinkAsPlanText>
    )
  })
}

function Logo() {
  return (
    <img
      src="/logo.png"
      width={150}
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginRight: '24px',
        marginLeft: '10px',
      }}
    />
  )
}

function HeaderContent(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const resetLocalStorage = React.useCallback(() => {
    Object.values(LOCAL_STORAGE_PAGE_KEY).forEach((key) => {
      localStorage.removeItem(key)
      removeLocalStoragePageNumber(key)
    })
    localStorage.removeItem(LOCAL_STORAGE_USER_ID)
    localStorage.removeItem(LOCAL_STORAGE_USER_NAME)
    localStorage.removeItem(LOCAL_STORAGE_DISPLAY_LIMIT_KEY)
  }, [])

  const isUpMd = useMediaQuery(() => theme.breakpoints.up('md'))

  const { menus, hasLockedDrivers, isCommentable } = useMenu(
    props.isAdmin,
    props.isXmileAdmin,
    props.isAssistantStaff,
    props.isDispatcher
  )

  React.useEffect(() => {
    if (props.isAdmin) {
      // アカウントロックされているユーザーがいる場合バッジを表示する
      menus.masters.children.drivers.showBadge = hasLockedDrivers
      menus.masters.showBadge = hasLockedDrivers
    }
  }, [hasLockedDrivers])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{ backgroundColor: palette.header.background }}
      >
        <Toolbar>
          {props.canNavigate ? (
            <AppTitle href="/">
              <Logo />
            </AppTitle>
          ) : (
            <Logo />
          )}
          <Box sx={{ flexGrow: 1 }}>
            {props.auth && (
              <Typography variant="h6" display={isUpMd ? 'inline' : 'none'}>
                <p>
                  {props.officeName
                    ? [props.companyName, props.officeName].join(' ')
                    : props.companyName}{' '}
                  様
                </p>
              </Typography>
            )}
          </Box>
          {props.auth && (
            <div>
              <Typography display={isUpMd ? 'inline' : 'none'}>
                {props.fullName}
              </Typography>
              {props.canNavigate && (
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  {isUpMd ? (
                    <AccountCircle />
                  ) : hasLockedDrivers ? (
                    <Badge
                      badgeContent={'!'}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      sx={badgeStyle}
                    >
                      <MenuIcon />
                    </Badge>
                  ) : (
                    <MenuIcon />
                  )}
                </IconButton>
              )}
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/* <MenuItem onClick={handleClose}>設定</MenuItem> */}
                <Box display={isUpMd ? 'none' : 'inline'}>
                  {(props.isExecutive || props.isAdmin) && (
                    <LinkAsPlanText href={EXECUTIVE_USER_ROUTE_PATH}>
                      <MenuItem>経営者メニュー</MenuItem>
                    </LinkAsPlanText>
                  )}
                  {showMenu(menus, isCommentable)}
                </Box>
                <LinkAsPlanText
                  href={props.sighOutPath}
                  onClick={resetLocalStorage}
                >
                  <MenuItem>ログアウト</MenuItem>
                </LinkAsPlanText>
              </Menu>
            </div>
          )}
        </Toolbar>
        <Typography display={isUpMd ? 'none' : 'block'} margin="0 20px">
          {props.fullName}
        </Typography>
      </AppBar>
    </Box>
  )
}

// NOTE: Header,Sidebarなどはapplication.html直下なのでApp.tsxのThemeProviderは使えず、各々で囲う必要がある
const Header: React.FC<Props> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <HeaderContent {...props} />
    </ThemeProvider>
  )
}

export default Header
