import { useQuery } from '@tanstack/react-query'
import { createQueryKey } from 'commons/functions'
import { fetchUserArticles } from 'components/apis/user_articles'
import { useErrorHandler } from 'react-error-boundary'

export default function useUserArticles(
  articleId: number,
  searchParams?: SearchUserArticle,
  paginateParams?: PaginateParams
) {
  const errorHandle = useErrorHandler()
  const queryKey = createQueryKey('user_articles', {
    articleId,
    ...searchParams,
    ...paginateParams,
  })

  const { data: userArticlesData, isLoading: isLoadingUserArticlesData } =
    useQuery({
      queryKey,
      queryFn: async () => {
        const res = await fetchUserArticles(
          articleId,
          searchParams,
          paginateParams
        )
        return res.data
      },
      onError: (error) => {
        errorHandle(error)
      },
    })

  return {
    userArticlesData,
    isLoadingUserArticlesData,
  }
}
