import * as React from 'react'
import {
  XMILE_ADMIN_MENUS,
  ADMIN_MENUS,
  ASSISTANT_STAFF_MENUS,
  DISPATCHER_MENUS,
  MENUS,
} from 'commons/menus'
import { fetchLockedDriversList } from 'components/apis/drivers'
import { fetchEducationSetting } from 'components/apis/education_settings'

const menuPolicy = (
  isAdmin: boolean,
  isXmileAdmin: boolean,
  isAssistantStaff: boolean,
  isDispatcher: boolean
) => {
  if (isXmileAdmin) {
    return XMILE_ADMIN_MENUS
  } else if (isAdmin) {
    return ADMIN_MENUS
  } else if (isAssistantStaff) {
    return ASSISTANT_STAFF_MENUS
  } else if (isDispatcher) {
    return DISPATCHER_MENUS
  } else {
    return MENUS
  }
}

function useMenu(
  isAdmin: boolean,
  isXmileAdmin: boolean,
  isAssistantStaff: boolean,
  isDispatcher: boolean
) {
  const [hasLockedDrivers, setLockedDrivers] = React.useState<boolean>(false)
  const [isCommentable, setIsCommentable] = React.useState<boolean>(false)

  const menus = menuPolicy(
    isAdmin,
    isXmileAdmin,
    isAssistantStaff,
    isDispatcher
  )

  React.useEffect(() => {
    fetchLockedDriversList().then((response) => {
      setLockedDrivers(response.data.drivers.length > 0)
    })

    fetchEducationSetting().then((response) => {
      setIsCommentable(response.data.isCommentable === '1')
    })
  }, [])

  return { menus, hasLockedDrivers, isCommentable }
}

export default useMenu
