import * as React from 'react'
import {
  ADMIN_ROUTES,
  DRIVERS,
  LOCAL_STORAGE_PAGE_KEY,
} from 'commons/constants'
import EnhancedTable, { HeadCell } from 'components/atoms/TableComponents'
import SearchForm from '../drivers/SearchForm'
import { UserContext } from 'providers/UserProvider'
import {
  getLocalStorageDisplayLimit,
  getLocalStoragePageNumber,
  getLocalStorageOrder,
  isOrder,
} from 'commons/table'

type HeadCellItem = {
  id: number
  fullName: string
  fullNameKana: string
  companyName?: string
  officeName: string
  email: string
  phoneNumber: string
  currentCareer: string
}
import { Order } from 'commons/array'

const headCells: readonly HeadCell<HeadCellItem>[] = [
  {
    id: 'fullName',
    label: DRIVERS.LABELS.FULLNAME,
    type: 'string',
  },
  {
    id: 'fullNameKana',
    label: DRIVERS.LABELS.FULLNAME_KANA,
    type: 'string',
  },
  {
    id: 'officeName',
    label: DRIVERS.LABELS.OFFICE,
    type: 'string',
  },
  {
    id: 'email',
    label: DRIVERS.LABELS.EMAIL,
    type: 'string',
  },
  {
    id: 'phoneNumber',
    label: DRIVERS.LABELS.PHONE_NUMBER,
    type: 'string',
  },
  {
    id: 'currentCareer',
    label: DRIVERS.LABELS.CURRENT_CAREER_STATUS,
    type: 'string',
  },
]

const headCellsWithCompany: readonly HeadCell<HeadCellItem>[] = [
  {
    id: 'fullName',
    label: DRIVERS.LABELS.FULLNAME,
    type: 'string',
  },
  {
    id: 'fullNameKana',
    label: DRIVERS.LABELS.FULLNAME_KANA,
    type: 'string',
  },
  {
    id: 'companyName',
    label: DRIVERS.LABELS.COMPANY,
    type: 'string',
  },
  {
    id: 'officeName',
    label: DRIVERS.LABELS.OFFICE,
    type: 'string',
  },
  {
    id: 'email',
    label: DRIVERS.LABELS.EMAIL,
    type: 'string',
  },
  {
    id: 'phoneNumber',
    label: DRIVERS.LABELS.PHONE_NUMBER,
    type: 'string',
  },
  {
    id: 'currentCareer',
    label: DRIVERS.LABELS.CURRENT_CAREER_STATUS,
    type: 'string',
  },
]

export default function DriversTable(props: {
  data: Driver[]
  totalCount: number
  paginateParams: PaginateParams
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setSearchParams: React.Dispatch<React.SetStateAction<SearchDriver>>
  searchParams: SearchDriver
  defaultSearchParams: SearchDriver
  isXmileSystemAdminUser?: boolean
  focusId?: number
  isLoading?: boolean
}) {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof HeadCellItem>('id')
  const isOrderBy = (arg): arg is keyof HeadCellItem => {
    const headCellItems = headCells.map((h) => {
      return h.id
    })
    return headCellItems.includes(arg)
  }
  React.useEffect(() => {
    // ソート条件をlocalStorageから取得してset
    const { order, orderBy } = getLocalStorageOrder(
      LOCAL_STORAGE_PAGE_KEY.DRIVERS
    )
    if (isOrder(order) && isOrderBy(orderBy)) {
      setOrder(order)
      setOrderBy(orderBy)
    }
    //「ページあたりの行数」をloalStorageから取得してセット
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = getLocalStoragePageNumber(
      LOCAL_STORAGE_PAGE_KEY.DRIVERS,
      true
    )
    const newPaginateParams = {
      pageNumber: pageNumber,
      displayLimit: displayLimit,
    }
    props.setPaginateParams(newPaginateParams)
    setRowsPerPage(displayLimit)
    setPage(pageNumber)
  }, [])
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(
    props.paginateParams.displayLimit
  )
  const rows = props.data as unknown as HeadCellItem[]
  const totalCount = props.totalCount
  const user = React.useContext(UserContext)

  return (
    <EnhancedTable<HeadCellItem>
      {...props}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      page={page}
      setPage={setPage}
      rows={rows}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      headCells={
        user.isXmileAdmin || user.isGroupParentCompanyAdmin
          ? headCellsWithCompany
          : headCells
      }
      totalCount={totalCount}
      localStoragePageKey={LOCAL_STORAGE_PAGE_KEY.DRIVERS}
      basePath={ADMIN_ROUTES.DRIVERS_INDEX}
      isNewVersion
      searchForm={
        <SearchForm
          setSearchParams={props.setSearchParams}
          setPaginateParams={props.setPaginateParams}
          setPage={setPage}
          searchParams={props.searchParams}
          defaultSearchParams={props.defaultSearchParams}
          isLoading={props.isLoading}
        />
      }
    />
  )
}
