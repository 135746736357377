import { AxiosResponse } from 'axios'
import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { camelCaseKeysToUnderscore } from 'commons/functions'

export function getCompanyStartDate(): Promise<
  AxiosResponse<CompanyStartDate>
> {
  return axios.get(API_ENDPOINTS.COMPANY_START_DATES)
}

export function updateCompanyStartDate(
  params: CompanyStartDate
): Promise<AxiosResponse<CompanyStartDate>> {
  return axios.patch(
    API_ENDPOINTS.COMPANY_START_DATES,
    camelCaseKeysToUnderscore(params)
  )
}
