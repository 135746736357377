import * as React from 'react'
import { ADMIN_MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { getVehicle } from 'components/apis/vehicles'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import VehicleDetail from 'components/organisms/vehicles/show/Detail'
import { useNavigate, useParams } from 'react-router-dom'
import { FlexBox } from 'components/atoms/BoxComponents'
import { EditButton } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import SidebarAnchorList from 'components/organisms/layouts/SidebarAnchorList'
import { useQuery } from '@tanstack/react-query'
import { styled } from '@mui/styles'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import { AxiosError } from 'axios'
import { useErrorHandler } from 'react-error-boundary'
import { useTheme } from '@mui/material'

function AdminVehiclesShow() {
  const { id } = useParams()
  const theme = useTheme()
  const navigate = useNavigate()
  const handleError = useErrorHandler()

  const { data: vehicle, isLoading } = useQuery(
    [`vehicles/${id}`, 'vehicle'],
    () => getVehicle(parseInt(id)).then((res) => res.data),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      refetchOnWindowFocus: false,
    }
  )

  const Title = styled('h1')({
    margin: '12px',
  })

  const InvisibleForMobile = styled('div')({
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  })

  return (
    <FlexBox>
      <SidebarAnchorList page="vehicles" />
      <PageWrapper>
        <Helmet>
          <title>{PAGE_TITLES.VEHICLES.SHOW ?? APPLICATION_NAME}</title>
        </Helmet>
        <FlexBox
          justifyContent={'space-between'}
          sx={{
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
            alignItems: 'center',
          }}
        >
          <Title>{ADMIN_MENUS.masters.children.vehicles.title}</Title>
          <FlexBox gap={2} sx={{ alignItems: 'center' }}>
            {!isLoading && (
              <InvisibleForMobile>
                <ApplyButton
                  onClick={() =>
                    window.open(
                      `/api/v1/vehicles/ledgers/${vehicle.id}/export/`,
                      '_blank'
                    )
                  }
                >
                  台帳出力
                </ApplyButton>
              </InvisibleForMobile>
            )}
            <EditButton
              onClick={() => {
                navigate('edit/')
              }}
            >
              {/* TODO: 項目ごとに編集するボタンを設置するタイミングで、挙動を区別するために「まとめて編集する」などに文言を変更 */}
              編集する
            </EditButton>
          </FlexBox>
        </FlexBox>
        {isLoading ? <CircularProgressBox /> : <VehicleDetail data={vehicle} />}
      </PageWrapper>
    </FlexBox>
  )
}

export default AdminVehiclesShow
