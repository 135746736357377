import * as React from 'react'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { Helmet } from 'react-helmet-async'
import { styled } from '@mui/styles'
import { FlexBox } from 'components/atoms/BoxComponents'
import { palette } from 'components/theme'
import { UserContext } from 'providers/UserProvider'

const ContactFormWrapper = styled('div')({
  background: 'white',
  width: '480px',
  borderRadius: '8px',
  border: `1px solid ${palette.border.pale}`,
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  margin: '5px',
  '@media (max-width: 768px)': {
    width: '100%',
  },
})

const ContactFormIconWrapper = styled('div')({
  width: '64px',
  height: '64px',
  border: `1.4px solid ${palette.primary.main}`,
  borderRadius: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const ContactFormTitle = styled('div')({
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '24px',
})

const ContactFormDescription = styled('div')({
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '16px',
})

const ContactFormTelBackGround = styled('div')({
  background: palette.background.main,
  borderRadius: '8px',
  width: '100%',
  marginTop: '10px',
})

const ContactFormTelTitle = styled('div')({
  background: palette.primary.deep,
  borderRadius: '8px 8px 0 0',
  width: '100%',
  height: '27px',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '16px',
  color: 'white',
  textAlign: 'center',
})

const ContactFormTelContent = styled('div')({
  padding: '20px',
  textAlign: 'center',
})

const ContactFormTel = styled('div')({
  color: palette.primary.deep,
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '24px',
})

const ContactFormTelNumber = (props: { telNumber: string }) => {
  return (
    <ContactFormTel>
      <img
        src="/images/tel.png"
        style={{
          marginRight: '5px',
        }}
      />
      {props.telNumber}
    </ContactFormTel>
  )
}

const ContactFormReceptionTime = styled('div')({
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '16px',
  color: palette.text.gray.deep,
})

const RecruitConsultationForm = () => {
  return (
    <ContactFormWrapper>
      <FlexBox>
        <ContactFormIconWrapper>
          <img src="/images/recruit.png" />
        </ContactFormIconWrapper>
        <FlexBox flexDirection="column" sx={{ marginLeft: '15px' }}>
          <ContactFormTitle>求人相談</ContactFormTitle>
          <ContactFormDescription>
            ドライバーの募集・求人
            {/* 空白行表示のための改行 */}
            <br />
            <br />
          </ContactFormDescription>
        </FlexBox>
      </FlexBox>
      <ContactFormTelBackGround>
        <ContactFormTelTitle>お問合せ先</ContactFormTelTitle>
        <ContactFormTelContent>
          <b>X Mile・モビポケの人材事業部</b>
          <ContactFormTelNumber telNumber={'050-1780-3183'} />
          <ContactFormReceptionTime>
            受付時間 平日: 10:00 ~ 18:00
          </ContactFormReceptionTime>
        </ContactFormTelContent>
      </ContactFormTelBackGround>
    </ContactFormWrapper>
  )
}

const FundConsultationForm = () => {
  return (
    <ContactFormWrapper>
      <FlexBox>
        <ContactFormIconWrapper>
          <img src="/images/fund.png" />
        </ContactFormIconWrapper>
        <FlexBox flexDirection="column" sx={{ marginLeft: '15px' }}>
          <ContactFormTitle>資金相談</ContactFormTitle>
          <ContactFormDescription>
            補助金、融資・資金調達、M&Aに関するご相談
            {/* 空白行表示のための改行 */}
            <br />
            <br />
          </ContactFormDescription>
        </FlexBox>
      </FlexBox>
      <ContactFormTelBackGround>
        <ContactFormTelTitle>お問合せ先</ContactFormTelTitle>
        <ContactFormTelContent>
          <div style={{ marginBottom: '10px' }}>
            こちらからご相談内容の入力をお願いいたします。
          </div>
          <a href="/executives/fund_contacts/new">
            <img src="/images/contact_form.png" />
          </a>
        </ContactFormTelContent>
      </ContactFormTelBackGround>
    </ContactFormWrapper>
  )
}

function ExecutivesIndex() {
  const user = React.useContext(UserContext)

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.EXECUTIVES.INDEX ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>経営者メニュー</h1>
      <FlexBox flexWrap="wrap">
        <RecruitConsultationForm />
        {(user.isExecutive || user.isSystemAdmin) && <FundConsultationForm />}
      </FlexBox>
    </PageWrapper>
  )
}

export default ExecutivesIndex
