import * as React from 'react'
import { Controller } from 'react-hook-form'
import { VALIDATION_MESSAGES, COMPANIES } from 'commons/constants'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomInput,
  MidiumInput,
  ErrorMessage,
  HeadingText,
  CustomForm,
  CustomSelect,
  CustomSelectShort,
  CustomCheckBoxForm,
  WithRequiredBadge,
  CustomRadioButton,
  CustomRadioGroup,
  CustomFileInput,
  YearMounthDatePicker,
} from 'components/atoms/FormComponents'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { FlexBox } from 'components/atoms/BoxComponents'
import { FEATURES } from 'commons/features'
import { styled } from '@mui/styles'
import { SubscriptionFormKeys } from 'types/driverForm'
import FormSubmitSelectPageModal from 'components/atoms/FormSubmitSelectPageModal'
import ProcessingModal from 'components/atoms/ProcessingModal'
import { Box, Typography } from '@mui/material'
import { UserContext } from 'providers/UserProvider'
import { ENUMS } from 'commons/enums'
import SignatureImage from 'components/atoms/SignatureImage'
import InvoiceRegistrationNumberField from 'components/atoms/InvoiceRegistrationNumberField'
import { monthList } from 'commons/monthList'
import NumberFormat from 'components/organisms/NumberFormat'
import { validateNullableEmailInput } from 'commons/validation'

import { useCompanyForm } from './hooks/useCompanyForm'

interface Props {
  type: 'create' | 'update'
  data?: Company
  companies?: Company[]
  groupParentCompanies?: Company[]
}

export default function CompaniesForm(props: Props) {
  const user = React.useContext(UserContext)
  const {
    signatureImage,
    isModalShow,
    setModalShow,
    isProcessingModalShow,
    methods: {
      register,
      control,
      getValues,
      setValue,
      watch,
      formState: { errors },
    },
    handleKeyDown,
    onInputChange,
    submitHandlers,
  } = useCompanyForm(props.type, props.data)

  const [isDisabledCompany, setIsDisabledCompany] = React.useState(false)
  const [isDisabledLoginId, setIsDisabledLoginId] = React.useState(false)
  const [isDisabledGroupParentCompany, setIsDisabledGroupParentCompany] =
    React.useState(false)
  const [isDisabledAnnualStartMonth, setIsDisabledAnnualStartMonth] =
    React.useState(false)

  const companyList = Object.values(props.companies).map((company: Company) => {
    return {
      label: company.name,
      value: company.id,
    }
  })
  const groupParentCompanyList = Object.values(props.groupParentCompanies).map(
    (groupParentCompany: Company) => {
      return {
        label: groupParentCompany.name,
        value: groupParentCompany.id,
      }
    }
  )

  const CustomCheckBoxFormContain = styled('div')({
    marginTop: '10px',
  })

  React.useEffect(() => {
    const isGroupParentCompany = getValues('isGroupParentCompany') || false
    const groupParentCompanyId = getValues('groupParentCompanyId') || null
    const companyId = getValues('companyId') || null
    setIsDisabledCompany(isGroupParentCompany || groupParentCompanyId !== null)
    if (isGroupParentCompany || groupParentCompanyId !== null) {
      setValue('companyId', null)
    }
    // グループ親会社や事業所として登録する場合はグループ親会社の情報は空にする
    setIsDisabledGroupParentCompany(isGroupParentCompany || companyId !== null)
    if (isGroupParentCompany || companyId !== null) {
      setValue('groupParentCompanyId', null)
    }
    // 事業所として登録する場合は年度開始月の情報は空にする
    setIsDisabledAnnualStartMonth(companyId !== null)
    if (companyId !== null) {
      setValue('annualStartMonth', null)
    }
    setIsDisabledLoginId(
      companyId !== null ||
        (props.type === 'update' && getValues('loginId')?.length > 0)
    )
    if (companyId !== null) {
      setValue('loginId', '')
    }
  }, [
    watch('isGroupParentCompany'),
    watch('companyId'),
    watch('groupParentCompanyId'),
    watch('annualStartMonth'),
  ])

  return (
    <>
      <BackGroundWhite>
        <CustomForm onKeyDown={handleKeyDown}>
          <FlexBox flexWrap="wrap">
            {user.isXmileAdmin ? (
              <>
                <CustomLabel>
                  <WithRequiredBadge>{COMPANIES.LABELS.NAME}</WithRequiredBadge>
                  <FlexBox flexWrap="wrap">
                    <Box mr={1}>
                      <CustomInput {...register('name', { required: true })} />
                    </Box>
                    <Box mt={1}>
                      <Controller
                        name={'isGroupParentCompany'}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <label>
                            <input
                              type="checkbox"
                              checked={value}
                              onChange={() => onChange(!value)}
                            />
                            グループ親会社として扱う
                          </label>
                        )}
                      />
                    </Box>
                  </FlexBox>
                  {errors.name && (
                    <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
                  )}
                </CustomLabel>
                <CustomLabel>
                  {COMPANIES.LABELS.PARENT_COMPANY_NAME}
                  <Controller
                    name="companyId"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CustomSelect
                        options={companyList}
                        value={companyList.find((c) => c.value === value)}
                        onChange={(option: ReactSelectOptionProps) =>
                          onChange(option.value)
                        }
                        isDisabled={isDisabledCompany}
                        placeholder="未選択"
                      />
                    )}
                  />
                </CustomLabel>
                <CustomLabel>
                  {COMPANIES.LABELS.GROUP_PARENT_COMPANY_NAME}
                  <Controller
                    name="groupParentCompanyId"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CustomSelect
                        options={groupParentCompanyList}
                        value={groupParentCompanyList.find(
                          (c) => c.value === value
                        )}
                        onChange={(option: ReactSelectOptionProps) =>
                          onChange(option.value)
                        }
                        isDisabled={isDisabledGroupParentCompany}
                        placeholder="未選択"
                      />
                    )}
                  />
                </CustomLabel>
              </>
            ) : (
              <>
                <CustomLabel>
                  {COMPANIES.LABELS.NAME}: {props.data.name}
                </CustomLabel>
              </>
            )}
            <CustomLabel>
              <WithRequiredBadge>
                {COMPANIES.LABELS.PHONE_NUMBER}
              </WithRequiredBadge>
              <CustomInput {...register('phoneNumber', { required: true })} />
              {errors.phoneNumber && (
                <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
              )}
            </CustomLabel>

            <CustomLabel>
              <WithRequiredBadge>{COMPANIES.LABELS.ZIP_CODE}</WithRequiredBadge>
              <CustomInput {...register('zipCode', { required: true })} />
              {errors.zipCode && (
                <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
              )}
            </CustomLabel>
            <CustomLabel>
              <WithRequiredBadge>{COMPANIES.LABELS.ADDRESS}</WithRequiredBadge>
              <CustomInput {...register('address', { required: true })} />
              {errors.address && (
                <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
              )}
            </CustomLabel>
            <CustomLabel>
              {COMPANIES.LABELS.FAX_NUMBER}
              <CustomInput {...register('faxNumber')} />
            </CustomLabel>
            <CustomLabel>
              {COMPANIES.LABELS.EMAIL}
              <CustomInput
                {...register('email', {
                  validate: { format: validateNullableEmailInput },
                })}
              />
              {errors?.email?.type === 'format' && (
                <ErrorMessage>{VALIDATION_MESSAGES.emailFormat}</ErrorMessage>
              )}
            </CustomLabel>
            <CustomLabel>
              {COMPANIES.LABELS.BANK_ACCOUNT_NUMBER}
              <CustomInput {...register('bankAccountNumber')} />
            </CustomLabel>
            {(props.data.companyId === null || props.type === 'create') && (
              <>
                <CustomLabel>
                  {COMPANIES.LABELS.PAYMENT_STATEMENT_REFERENCE_DATE_TYPE}
                  <Controller
                    name="paymentStatementReferenceDateType"
                    control={control}
                    render={({ field }) => (
                      <CustomRadioGroup>
                        {Object.entries(
                          ENUMS.PARTNER_COMPANIES.BILL_REFERENCE_DATE_TYPE
                        ).map(([key, obj], index) => (
                          <React.Fragment key={key}>
                            <CustomRadioButton
                              {...field}
                              id={key}
                              value={obj.value}
                              key={index}
                              type="radio"
                              defaultChecked={field.value === obj.value}
                            />
                            <label htmlFor={key}>{obj.label}</label>
                          </React.Fragment>
                        ))}
                      </CustomRadioGroup>
                    )}
                  />
                </CustomLabel>

                <CustomLabel>
                  {COMPANIES.LABELS.ANNUAL_START_MONTH}
                  <Controller
                    name="annualStartMonth"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CustomSelectShort
                        options={monthList}
                        value={monthList.find((c) => c.value === value)}
                        onChange={(option: ReactSelectOptionProps) =>
                          onChange(option.value)
                        }
                        isDisabled={isDisabledAnnualStartMonth}
                        placeholder="未選択"
                      />
                    )}
                  />
                </CustomLabel>
              </>
            )}
            <CustomLabel>
              {COMPANIES.LABELS.INVOICE_REGISTRATION_NUMBER}
              <InvoiceRegistrationNumberField control={control} />
            </CustomLabel>
            <CustomLabel>
              {COMPANIES.LABELS.G_MARK}
              <CustomRadioGroup>
                {Object.entries(ENUMS.COMPANIES.G_MARK).map(
                  ([key, obj], index) => (
                    <React.Fragment key={key}>
                      <CustomRadioButton
                        {...register('hasGMark', { required: true })}
                        id={key}
                        value={obj.value}
                        key={index}
                        type="radio"
                      />
                      <label htmlFor={key}>{obj.label}</label>
                    </React.Fragment>
                  )
                )}
              </CustomRadioGroup>
            </CustomLabel>
            <CustomLabel>
              {COMPANIES.LABELS.SIGNATURE_IMAGE}
              <Box mr={2}>
                <SignatureImage image={signatureImage} />
                <Controller
                  name="attachedSignatureImage"
                  render={() => <CustomFileInput onChange={onInputChange} />}
                  control={control}
                />
              </Box>
            </CustomLabel>
          </FlexBox>

          {user.isXmileAdmin && (
            <>
              {props.type === 'update' && (
                <CustomLabel>
                  {COMPANIES.LABELS.COMPANY_LOGIN_ID}(半角数字6桁)
                  <Controller
                    rules={{
                      validate: (data) => {
                        if (
                          data === undefined ||
                          data === null ||
                          data === ''
                        ) {
                          return undefined // 空白の場合はバリデーション通過
                        }
                        return /^\d{6}$/.test(data)
                          ? undefined
                          : '半角数字6桁で入力してください'
                      },
                    }}
                    render={({ field }) => (
                      <NumberFormat
                        {...field}
                        customInput={MidiumInput}
                        disabled={isDisabledLoginId}
                      />
                    )}
                    name="loginId"
                    control={control}
                  />
                  {errors.loginId?.message && (
                    <ErrorMessage>{errors.loginId.message}</ErrorMessage>
                  )}
                </CustomLabel>
              )}

              <CustomLabel>
                {COMPANIES.LABELS.SUBSCRIPTION}
                <FlexBox flexWrap="wrap">
                  {FEATURES.map((feature, index) => (
                    <Controller
                      key={index}
                      name={
                        `subscriptions.${feature.value}` as SubscriptionFormKeys
                      }
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <CustomCheckBoxFormContain>
                          <CustomCheckBoxForm>
                            <label>
                              <input
                                type="checkbox"
                                checked={value}
                                onChange={() => onChange(!value)}
                              />
                              {feature.label}
                            </label>
                          </CustomCheckBoxForm>
                        </CustomCheckBoxFormContain>
                      )}
                    />
                  ))}
                </FlexBox>
              </CustomLabel>
            </>
          )}
        </CustomForm>
      </BackGroundWhite>
      <FlexBox justifyContent={'flex-end'} sx={{ marginTop: '8px' }}>
        <ApplyButton
          pattern="block"
          onClick={(e) => {
            e.preventDefault()
            setModalShow(true)
          }}
        >
          保存する
        </ApplyButton>
      </FlexBox>
      <FormSubmitSelectPageModal
        isOpen={isModalShow}
        resourceName="会社"
        handlers={submitHandlers}
      />
      <ProcessingModal isOpen={isProcessingModalShow} />
    </>
  )
}
