import * as React from 'react'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { styled } from '@mui/material'
import { palette, theme } from 'components/theme'
import { VEHICLES } from 'commons/constants'

type LicenseProps = {
  data: {
    standard: string
    limitedSemiMidsize: string
    semiMidsize: string
    limitedMidsize: string
    midSize: string
    large: string
    largeSpecial: string
    smallSpecial: string
    towing: string
  }
}

export default function License(props: LicenseProps) {
  const Title = styled('h2')({
    fontSize: '20px',
    margin: 0,
  })
  const Text = styled('p')({
    fontSize: '16px',
    margin: 0,
  })
  const TextWrapper = styled('div')({
    width: '300px',
    marginTop: '48px',
    paddingBottom: '8px',
    paddingLeft: '4px',
    borderBottom: `1px solid ${palette.border.pale}`,
    overflowWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  })

  const map = {
    standard: '普通',
    limitedSemiMidsize: '準中型(5t限定)',
    semiMidsize: '準中型',
    limitedMidsize: '中型(8t限定)',
    midSize: '中型',
    large: '大型',
    largeSpecial: '大型特殊',
    smallSpecial: '小型特殊',
    towing: '牽引',
  }

  const licenseText = Object.entries(props.data).reduce((prev, cur) => {
    if (cur[1]) {
      if (prev == '') return map[cur[0]]
      return prev + ` / ${map[cur[0]]}`
    }
    return prev
  }, '')

  return (
    <BackGroundWhite>
      <Title>{VEHICLES.LABELS.LICENSE}</Title>
      <TextWrapper>
        <Text>{licenseText}</Text>
      </TextWrapper>
    </BackGroundWhite>
  )
}
