import { FieldError } from 'react-hook-form'

// react-hook-formのError Objectを受け取ってエラーメッセージを返す
export const formatCustomDateErrorMessage = (errors: {
  year: FieldError
  month: FieldError
  day: FieldError
}) => {
  let msg = ''
  if (!errors.year && !errors.month && !errors.day) return msg
  if (errors.year) msg += '「年」'
  if (errors.month) msg += '「月」'
  if (errors.day) msg += '「日」'
  return (msg += 'は入力必須です')
}

export const formatPhoneNumberErrorMessage = (errors: {
  first: FieldError
  second: FieldError
  third: FieldError
}) => {
  // エラーがなければ空文字列を返す
  if (!errors.first && !errors.second && !errors.third) return ''
  // フォーマットエラーがあった場合
  if (
    errors.first?.type == 'format' ||
    errors.second?.type == 'format' ||
    errors.third?.type == 'format'
  )
    return '電話番号は正しいフォーマットで入力してください'
  // 一つでもエラーがあった場合
  if (errors.first || errors.second || errors.third)
    return '電話番号は入力必須です'
}
