import * as React from 'react'
import { Box } from '@mui/material'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { ADMIN_MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import AdminVideoList from 'components/pages/admin/education_video_logs/videoList'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'

function AdminEducationVideoLogsIndex() {
  return (
    <PageWrapper>
      <Helmet>
        <title>
          {PAGE_TITLES.EDUCATIONS.ATTENDANCES.INDEX ?? APPLICATION_NAME}
        </title>
      </Helmet>
      <h1>{ADMIN_MENUS.educations.children.education_video_logs.title}</h1>
      <Box sx={{ marginTop: '30px' }}>
        <BackGroundWhite>
          <AdminVideoList />
        </BackGroundWhite>
      </Box>
    </PageWrapper>
  )
}

export default AdminEducationVideoLogsIndex
