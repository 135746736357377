// 署名あり有効期限ありの画像を取得する
export const fetchS3ImagesV2 = async (urls: AttachedFileType[] | undefined) => {
  return await Promise.all(
    Object.values(urls || []).map((url) => {
      return fetchS3ImageV2(url)
    })
  )
}

// 署名あり有効期限ありの画像を取得する
export const fetchS3ImageV2 = async (image: AttachedFileType) => {
  if (!image) return null
  const { filename, url, type } = image

  const metadata = {
    type: type,
  }
  const data = await (await fetch(url)).blob()
  const file = new File([data], filename, metadata)
  return file
}

// 署名なし有効期限なしの画像を取得する
export const fetchS3Images = async (urls: string[] | undefined) => {
  return await Promise.all(
    Object.values(urls || []).map((url) => {
      return fetchS3Image(url)
    })
  )
}

// 署名なし有効期限なしの画像を取得する
export const fetchS3Image = async (url: string) => {
  if (!url) return null
  const path = url.split('/')
  const filename = path[path.length - 1]

  const data = await (await fetch(url)).blob()
  const metadata = {
    type: data.type || 'application/octet-stream',
  }
  const file = new File([data], filename, metadata)
  return file
}
