import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useParams } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
import { APPLICATION_NAME, HEAD_TEXTS, PAGE_TITLES } from 'commons/constants'
import { getEducationTestHistories } from 'components/apis/education_tests'
import EducationTestsForm from 'components/organisms/education_tests/Form'
import PageWrapper from 'components/organisms/layouts/PageWrapper'

const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function AdminDriversEducationTestsHistory() {
  const query = useQuery()
  const { id: userId, educationId } = useParams()
  const [educationTestHistories, setEducationTestHistories] =
    React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setIsLoading(true)
    getEducationTestHistories(userId, educationId, query.get('year'))
      .then((res) => {
        res.data.score !== null &&
          res.data.selectedAnswers.length &&
          setEducationTestHistories(res.data)
      })
      .finally(() => setIsLoading(false))
  }, [userId, educationId])

  return (
    <PageWrapper>
      <Helmet>
        <title>
          {PAGE_TITLES.EDUCATIONS.TESTS[educationId] ?? APPLICATION_NAME}
        </title>
      </Helmet>
      <h1>{HEAD_TEXTS.WEB_TEST}</h1>
      <Box sx={{ marginTop: '30px' }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {educationTestHistories ? (
              <EducationTestsForm
                showAnswer={true}
                correctAnswerSum={educationTestHistories.score}
                selectedAnswers={educationTestHistories.selectedAnswers}
                isPass={educationTestHistories.isPass}
                releaseVersion={educationTestHistories.releaseVersion}
              />
            ) : (
              <p>指定された年度の受講履歴が存在しません。</p>
            )}
          </>
        )}
      </Box>
    </PageWrapper>
  )
}
