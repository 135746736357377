import * as React from 'react'
import { Controller } from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomInput,
  CustomInputShort,
  PhoneNumberInput,
  HeadingText,
  BetweenPhoneNumbers,
  CustomSelectShort,
  ErrorMessage,
  FORM_VALIDATIONS,
  WithRequiredBadge,
  CustomDropZone,
  CustomSelect,
  CustomCheckBoxFormShort,
  CustomDateSelect,
  FormKeys,
  CustomCheckBoxForm,
  WithDescriptionTooltipIcon,
  CustomRadioButton,
  CustomRadioGroup,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { PREFECTURES } from 'commons/prefectures'
import { DRIVERS, VALIDATION_MESSAGES, TOOLTIP_TEXTS } from 'commons/constants'
import { QUALIFICATION_TYPE } from 'commons/qualificationType'
import { toastOnError } from 'commons/toaster'
import { isLoginIdDuplicate } from 'components/apis/drivers'
import romajiConv from '@koozaki/romaji-conv'
import { useMutation } from '@tanstack/react-query'
import CompanyAndOfficeSelect from 'components/atoms/ReactHookFormPartials/InputForm/CompanyAndOfficeSelect'
import PasswordLabel from './PasswordLabel'
import { slackNotification } from 'commons/slackNotification'
import { AxiosError } from 'axios'
import { UserContext } from 'providers/UserProvider'
import { validateNullableEmailInput } from 'commons/validation'
import { ENUMS } from 'commons/enums'
import { styled } from '@mui/styles'

export default function DriversProfile(props: {
  register
  control
  errors
  getValues
  setValue
  watch
  ledgerFiles: File[]
  setLedgerFiles
  type: ProfileEvent
  companies?: Company[]
  offices?: Office[]
  roles?: Role[]
  drivers?: DriverSelectOption[]
  isXmileSystemAdminUser?: boolean
  isAdminUser?: boolean
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>
}) {
  const user = React.useContext(UserContext)
  const {
    register,
    control,
    errors,
    ledgerFiles,
    setLedgerFiles,
    type,
    roles,
  } = props

  const bloodTypeList = ['a', 'b', 'ab', 'o', 'unknown'].map((value) => ({
    label: value === 'unknown' ? '不明' : value.toUpperCase(),
    value: value,
  }))
  const genderList = [
    { label: '男性', value: 'male' },
    { label: '女性', value: 'female' },
  ]
  const LongCustomRadioGroup = styled(CustomRadioGroup)({
    width: '600px',
  })

  const roleList = Object.values(roles).map((role: Role) => {
    return { label: role.label, value: role.value }
  })

  const qualificationArray = [
    QUALIFICATION_TYPE.slice(0, 4),
    QUALIFICATION_TYPE.slice(4, 8),
  ]

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setLedgerFiles([...ledgerFiles, ...acceptedFiles])
    },
    [ledgerFiles]
  )

  const isLoginIdDuplicateMutation = useMutation({
    mutationFn: isLoginIdDuplicate,
    onSuccess: (response) => {
      response.data.duplicate &&
        toastOnError(String(VALIDATION_MESSAGES.loginIdDuplicate))
    },
    onError: (e: AxiosError<{ errors: string }>) => {
      // 422エラー時にslack通知する
      slackNotification(e, user)
    },
  })

  function checkLoginIdDuplicate() {
    const params = {
      companyId: props.getValues('companyId'),
      loginId: props.getValues('loginId'),
      modifiedUserId: props.getValues('id'),
    }
    if (props.getValues('loginId')) {
      isLoginIdDuplicateMutation.mutate(params)
    }
  }

  const isEducationTarget =
    props.getValues('isEducationTarget') === false ? false : true
  props.setValue('isEducationTarget', isEducationTarget)

  /**
   * - 入力中のnameの値
   * - カナ変換の為に持っておく
   * */
  const enteringNameValue = {}

  /**
   * 渡した文字がアルファベット(a-z/A-Z)の1文字だけかどうかを判定する関数
   * */
  const isAlphabetOneCharacter = (str: string) => {
    return str.length === 1 && str.match(/^[a-zA-Z]*$/) !== null
  }
  /**
   * 渡した文字中にカタカナが含まれているかどうかを判定する関数
   * */
  const isKatakana = (str) => {
    return str.search(/[ァ-ヶ]/) >= 0
  }
  const isBackSpace = (key) => {
    return key === 'Backspace'
  }

  /**
   * ふりがなのinputを更新する
   * @param formKey
   * @param value
   */
  const onFuriganaCompleted = (formKey: FormKeys, value: string) => {
    const formKeyKana = `${formKey}Kana`
    props.setValue(formKeyKana, value)
    // 入力中の値をリセットする
    enteringNameValue[formKeyKana] = ''
  }

  const handleNameInputKeyDown = (
    formKey: FormKeys,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    // 入力済みの値
    const enteredValue = props.getValues(formKey)
    // inputの値が空になった場合はふりがなも空にする
    if (isBackSpace(e.key) && enteredValue.length === 1) {
      onFuriganaCompleted(formKey, '')
      return
    }

    // 入力した値がアルファベット一文字の場合
    if (isAlphabetOneCharacter(e.key)) {
      const formKeyKana = `${formKey}Kana`
      // 入力中の値を更新
      if (enteringNameValue[formKeyKana]) {
        enteringNameValue[formKeyKana] += e.key
      } else {
        enteringNameValue[formKeyKana] = e.key
      }

      // 'n'1文字の場合に'ん'になってしまうのでスキップする
      if (enteringNameValue[formKeyKana] === 'n') return
      const kana = romajiConv(enteringNameValue[formKeyKana]).katakana

      // カタカナに変換できた場合はinputを更新する
      if (isKatakana(kana)) {
        const enteredValueKana = props.getValues(formKeyKana)
        onFuriganaCompleted(formKey, enteredValueKana + kana)
      }
    }
  }

  return (
    <BackGroundWhite>
      <HeadingText>{DRIVERS.LABELS.PROFILE}</HeadingText>
      <FlexBox flexWrap="wrap">
        <CustomLabel>
          <WithRequiredBadge>{DRIVERS.LABELS.LOGIN_ID}</WithRequiredBadge>
          <CustomInput
            {...register('loginId', {
              required: true,
              onBlur: () => checkLoginIdDuplicate(),
            })}
            onKeyDown={props.onInputKeyDown}
          />
          {errors.loginId && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
        <CustomLabel>
          <PasswordLabel type={type} />
          <CustomInput
            {...register('password', {
              required: type == 'create',
            })}
            onKeyDown={props.onInputKeyDown}
          />
          {errors.password && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
          {type == 'update' && (
            <>
              ※変更したい場合のみ入力してください。
              <br />
              空欄の場合、登録済みのパスワードが変更されることはありません。
            </>
          )}
        </CustomLabel>
      </FlexBox>
      {props.isAdminUser && (
        <FlexBox flexWrap="wrap">
          <CustomLabel>
            {DRIVERS.LABELS.ROLE}
            <Controller
              name="role"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  options={roleList}
                  value={roleList.find((c) => c.value === value)}
                  onChange={(option: ReactSelectOptionProps) =>
                    onChange(option.value)
                  }
                  placeholder="未選択"
                />
              )}
            />
          </CustomLabel>
        </FlexBox>
      )}
      {props.isXmileSystemAdminUser && (
        <CustomLabel>
          <Controller
            name="isExecutive"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomCheckBoxForm>
                <label>
                  <input
                    type="checkbox"
                    checked={value as boolean}
                    onChange={() => onChange(!value)}
                  />
                  経営者として登録
                </label>
              </CustomCheckBoxForm>
            )}
          />
        </CustomLabel>
      )}
      <CompanyAndOfficeSelect
        companyKeyName={'companyId'}
        officeKeyName={'officeId'}
        control={control}
        getValues={props.getValues}
        setValue={props.setValue}
        watch={props.watch}
      />
      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {DRIVERS.LABELS.EMPLOYEE_NUMBER}
          <div style={{ fontSize: '13px' }}>
            こちらの欄に記入されている番号が従業員台帳の作成番号として表示されます。
            <br />
            社員番号の割り振りがない場合でも作成番号として表示をするために記入をお願いいたします。
          </div>
          <CustomInputShort
            {...register(`profile.employeeNumber`)}
            onKeyDown={props.onInputKeyDown}
          />
        </CustomLabel>

        <CustomLabel>
          {DRIVERS.LABELS.EMPLOYMENT_STATUS}
          <LongCustomRadioGroup>
            {Object.entries(ENUMS.DRIVERS.EMPLOYMENT_STATUS).map(
              ([key, obj]) => (
                <React.Fragment key={key}>
                  <CustomRadioButton
                    {...register('profile.employmentStatus')}
                    id={`employmentStatus_${obj.value}`}
                    value={obj.value}
                    type="radio"
                  />
                  <label htmlFor={`employmentStatus_${obj.value}`}>
                    {obj.label}
                  </label>
                </React.Fragment>
              )
            )}
          </LongCustomRadioGroup>
        </CustomLabel>

        <CustomLabel>
          {DRIVERS.LABELS.LEDGER_CREATED_AT}
          <CustomDateSelect
            formKeys={{
              year: 'profile.ledgerCreatedYear',
              month: 'profile.ledgerCreatedMonth',
              day: 'profile.ledgerCreatedDay',
            }}
            control={props.control}
          />
        </CustomLabel>
      </FlexBox>
      <FlexBox flexWrap="wrap">
        <CustomLabel>
          <WithRequiredBadge>{DRIVERS.LABELS.LAST_NAME}</WithRequiredBadge>
          <CustomInput
            {...props.register('profile.lastName', {
              required: true,
            })}
            onKeyDown={(e) => {
              handleNameInputKeyDown('profile.lastName', e)
              props.onInputKeyDown(e)
            }}
          />
          {props.errors.profile?.lastName && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>

        <CustomLabel>
          <WithRequiredBadge>{DRIVERS.LABELS.FIRST_NAME}</WithRequiredBadge>
          <CustomInput
            {...props.register('profile.firstName', {
              required: true,
            })}
            onKeyDown={(e) => {
              handleNameInputKeyDown('profile.firstName', e)
              props.onInputKeyDown(e)
            }}
          />
          {props.errors.profile?.firstName && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
      </FlexBox>

      <FlexBox flexWrap="wrap">
        <CustomLabel>
          <WithRequiredBadge>{DRIVERS.LABELS.LAST_NAME_KANA}</WithRequiredBadge>
          <CustomInput
            {...register('profile.lastNameKana', {
              required: true,
              pattern: FORM_VALIDATIONS.KATAKANA,
            })}
            onKeyDown={props.onInputKeyDown}
          />
          {errors.profile?.lastNameKana &&
            errors.profile.lastNameKana.type == 'required' && (
              <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
            )}
          {errors.profile?.lastNameKana &&
            errors.profile.lastNameKana.type == 'pattern' && (
              <ErrorMessage>{VALIDATION_MESSAGES.patternKatakana}</ErrorMessage>
            )}
        </CustomLabel>

        <CustomLabel>
          <WithRequiredBadge>
            {DRIVERS.LABELS.FIRST_NAME_KANA}
          </WithRequiredBadge>
          <CustomInput
            {...register('profile.firstNameKana', {
              required: true,
              pattern: { value: FORM_VALIDATIONS.KATAKANA },
            })}
            onKeyDown={props.onInputKeyDown}
          />
          {errors.profile?.firstNameKana &&
            errors.profile.firstNameKana.type == 'required' && (
              <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
            )}
          {errors.profile?.firstNameKana &&
            errors.profile.firstNameKana.type == 'pattern' && (
              <ErrorMessage>{VALIDATION_MESSAGES.patternKatakana}</ErrorMessage>
            )}
        </CustomLabel>
      </FlexBox>

      <CustomLabel>
        {DRIVERS.LABELS.GENDER}
        <Controller
          name="profile.gender"
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomSelectShort
              options={genderList}
              value={genderList.find((c) => c.value === value)}
              onChange={(option: ReactSelectOptionProps) =>
                onChange(option.value)
              }
              placeholder="未選択"
            />
          )}
        />
      </CustomLabel>

      <CustomLabel>
        {DRIVERS.LABELS.BIRTHDAY}
        <CustomDateSelect
          formKeys={{
            year: 'profile.birthedYear',
            month: 'profile.birthedMonth',
            day: 'profile.birthedDay',
          }}
          control={props.control}
          defaultToday={false}
          defaultValue={{ year: 1980 }}
        />
      </CustomLabel>

      <CustomLabel>
        {DRIVERS.LABELS.BLOOD_TYPE}
        <Controller
          name="profile.bloodType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomSelectShort
              options={bloodTypeList}
              value={bloodTypeList.find((c) => c.value === value)}
              onChange={(option: ReactSelectOptionProps) =>
                onChange(option.value)
              }
              placeholder="未選択"
            />
          )}
        />
      </CustomLabel>

      <CustomLabel>
        {DRIVERS.LABELS.EMAIL}
        <CustomInput
          {...register('email', {
            validate: { format: validateNullableEmailInput },
          })}
          onKeyDown={props.onInputKeyDown}
        />
        {errors?.email?.type === 'format' && (
          <ErrorMessage>{VALIDATION_MESSAGES.emailFormat}</ErrorMessage>
        )}
      </CustomLabel>

      <CustomLabel>
        {DRIVERS.LABELS.PHONE_NUMBER}
        <FlexBox flexWrap="wrap">
          <PhoneNumberInput
            {...register(`profile.phoneNumberFirst`)}
            onKeyDown={props.onInputKeyDown}
          />
          <BetweenPhoneNumbers>-</BetweenPhoneNumbers>
          <PhoneNumberInput
            {...register(`profile.phoneNumberSecond`)}
            onKeyDown={props.onInputKeyDown}
          />
          <BetweenPhoneNumbers>-</BetweenPhoneNumbers>
          <PhoneNumberInput
            {...register(`profile.phoneNumberThird`)}
            onKeyDown={props.onInputKeyDown}
          />
        </FlexBox>
      </CustomLabel>

      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {DRIVERS.LABELS.PERSONAL_PHONE_NUMBER1}
          <FlexBox flexWrap="wrap">
            <PhoneNumberInput
              {...register(`profile.personalPhoneNumber1First`)}
              onKeyDown={props.onInputKeyDown}
            />
            <BetweenPhoneNumbers>-</BetweenPhoneNumbers>
            <PhoneNumberInput
              {...register(`profile.personalPhoneNumber1Second`)}
              onKeyDown={props.onInputKeyDown}
            />
            <BetweenPhoneNumbers>-</BetweenPhoneNumbers>
            <PhoneNumberInput
              {...register(`profile.personalPhoneNumber1Third`)}
              onKeyDown={props.onInputKeyDown}
            />
          </FlexBox>
        </CustomLabel>

        <CustomLabel>
          {DRIVERS.LABELS.PERSONAL_PHONE_NUMBER2}
          <FlexBox flexWrap="wrap">
            <PhoneNumberInput
              {...register(`profile.personalPhoneNumber2First`)}
              onKeyDown={props.onInputKeyDown}
            />
            <BetweenPhoneNumbers>-</BetweenPhoneNumbers>
            <PhoneNumberInput
              {...register(`profile.personalPhoneNumber2Second`)}
              onKeyDown={props.onInputKeyDown}
            />
            <BetweenPhoneNumbers>-</BetweenPhoneNumbers>
            <PhoneNumberInput
              {...register(`profile.personalPhoneNumber2Third`)}
              onKeyDown={props.onInputKeyDown}
            />
          </FlexBox>
        </CustomLabel>
      </FlexBox>

      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {DRIVERS.LABELS.ZIP_CODE}
          <CustomInputShort
            {...register(`profile.zipCode`)}
            onKeyDown={props.onInputKeyDown}
          />
        </CustomLabel>

        <CustomLabel>
          {DRIVERS.LABELS.PREFECTURE}
          <Controller
            name="profile.prefecture"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelectShort
                options={PREFECTURES}
                value={PREFECTURES.find((c) => c.value === value)}
                onChange={(option: ReactSelectOptionProps) =>
                  onChange(option.value)
                }
                placeholder="未選択"
              />
            )}
          />
        </CustomLabel>

        <CustomLabel>
          {DRIVERS.LABELS.ADDRESS}
          <CustomInput
            {...register(`profile.address`)}
            onKeyDown={props.onInputKeyDown}
          />
        </CustomLabel>
      </FlexBox>

      <CustomLabel>
        資格の種類
        {qualificationArray.map((qualificationTypes, arrayIndex) => (
          <React.Fragment key={arrayIndex}>
            <FlexBox flexWrap="wrap">
              {qualificationTypes.map((qualificationType, index) => (
                <React.Fragment key={index}>
                  <Controller
                    name={`qualification.${qualificationType.value}`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CustomCheckBoxFormShort>
                        <label>
                          <input
                            type="checkbox"
                            checked={value}
                            onChange={() => onChange(!value)}
                          />
                          {qualificationType.label}
                        </label>
                      </CustomCheckBoxFormShort>
                    )}
                  />
                </React.Fragment>
              ))}
            </FlexBox>
          </React.Fragment>
        ))}
      </CustomLabel>
      <CustomLabel>
        安全教育講習
        <FlexBox alignItems="center" justifyContent="flex-start">
          <Controller
            name="isEducationTarget"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomCheckBoxForm>
                <label>
                  <input
                    type="checkbox"
                    checked={value as boolean}
                    onChange={() => onChange(!value)}
                  />
                  対象
                </label>
              </CustomCheckBoxForm>
            )}
          />
          <WithDescriptionTooltipIcon
            text={`${TOOLTIP_TEXTS.NOTIFICATION_IS_EDUCATION_TARGET}`}
          ></WithDescriptionTooltipIcon>
        </FlexBox>
      </CustomLabel>
      <CustomLabel>
        {DRIVERS.LABELS.ENGAGED_WORK_TYPE}
        <CustomInput {...register(`profile.engagedWorkType`)} />
      </CustomLabel>
      <CustomLabel>
        添付ファイルのアップロード
        <Controller
          render={() => (
            <CustomDropZone
              multiple={true}
              onDrop={onDrop}
              accept={
                'image/*,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              }
            />
          )}
          name="profile.ledgerFiles"
          control={control}
        />
        {Object.values(ledgerFiles).map((file, index) => (
          <FlexBox flexDirection={'row'} key={index}>
            <p>
              <a
                href={URL.createObjectURL(file)}
                download={decodeURI(file.name)}
              >
                {decodeURI(file.name)}
              </a>
            </p>
            <IconButton
              component="span"
              onClick={() =>
                setLedgerFiles(ledgerFiles.filter((_, i) => i !== index))
              }
            >
              <CancelIcon />
            </IconButton>
          </FlexBox>
        ))}
      </CustomLabel>
    </BackGroundWhite>
  )
}
