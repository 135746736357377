import * as React from 'react'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { Box } from '@mui/material'
import { FlexBox } from 'components/atoms/BoxComponents'
import { AddButton } from 'components/atoms/ButtonComponenets'
import {
  ADMIN_ROUTES,
  APPLICATION_NAME,
  LOCAL_STORAGE_PAGE_KEY,
  PAGE_TITLES,
  TABLE_DEFAULT_ROWS,
} from 'commons/constants'
import { XMILE_ADMIN_MENUS } from 'commons/menus'
import CompaniesTable from 'components/organisms/companies/Table'
import { Helmet } from 'react-helmet-async'
import { UserContext } from 'providers/UserProvider'
import { useLocation } from 'react-router-dom'
import useCompanies from './hooks/useCompanies'
import { getLocalStorageSearchParams } from 'commons/table'

function AdminCompaniesIndex() {
  const title = '会社の新規作成'
  const user = React.useContext(UserContext)

  const localStorageSearchParams = getLocalStorageSearchParams(
    LOCAL_STORAGE_PAGE_KEY.COMPANIES
  )
  const defaultSearchParams = {}

  const [searchParams, setSearchParams] = React.useState<SearchCompany>(
    localStorageSearchParams ?? defaultSearchParams
  )
  const [paginateParams, setPaginateParams] = React.useState<PaginateParams>({
    pageNumber: 1,
    displayLimit: TABLE_DEFAULT_ROWS,
  })
  const queryParams = useLocation().search
  const focusId = new URLSearchParams(queryParams).get('focus_id')

  const { companyData, isLoadingCompanyData } = useCompanies(
    searchParams,
    paginateParams
  )

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.COMPANIES.INDEX ?? APPLICATION_NAME}</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
        }}
      >
        <h1>{XMILE_ADMIN_MENUS.masters.children.companies.title}</h1>
        {user.isXmileAdmin && (
          <FlexBox alignItems="center">
            <AddButton href={ADMIN_ROUTES.COMPANIES_NEW}>{title}</AddButton>
          </FlexBox>
        )}
      </Box>
      <CompaniesTable
        data={companyData?.companies}
        totalCount={companyData?.totalCount}
        searchParams={searchParams}
        defaultSearchParams={defaultSearchParams}
        setSearchParams={setSearchParams}
        paginateParams={paginateParams}
        setPaginateParams={setPaginateParams}
        focusId={parseInt(focusId)}
        isLoading={isLoadingCompanyData}
      />
    </PageWrapper>
  )
}

export default AdminCompaniesIndex
