import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { addParamPrefix, camelCaseKeysToUnderscore } from 'commons/functions'

export function fetchEducationAttendances(
  baseParams: UserEducation,
  searchParams: SearchUserEducation,
  paginateParams: PaginateParams
) {
  const customSearchParams = addParamPrefix(searchParams, 'search')
  const customPaginateParams = addParamPrefix(paginateParams, 'pagination')
  const params = camelCaseKeysToUnderscore({
    ...baseParams,
    ...customSearchParams,
    ...customPaginateParams,
  })

  return axios.get(API_ENDPOINTS.EDUCATION_ATTENDANCES, {
    params: params,
  })
}

export function fetchAdminEducationAttendances(
  baseParams: UserEducation,
  searchParams: SearchUserEducation,
  paginateParams: PaginateParams
) {
  const customSearchParams = addParamPrefix(searchParams, 'search')
  const customPaginateParams = addParamPrefix(paginateParams, 'pagination')
  const params = camelCaseKeysToUnderscore({
    ...baseParams,
    ...customSearchParams,
    ...customPaginateParams,
  })

  return axios.get(
    [API_ENDPOINTS.EDUCATION_ATTENDANCES, 'admin_index'].join(''),
    {
      params: params,
    }
  )
}

export function fetchEducationAttendancesWithinAYear() {
  return axios.get(API_ENDPOINTS.EDUCATION_ATTENDANCES, {
    params: { is_within_a_year: true },
  })
}

export function createEducationAttendances(params: UserEducation) {
  return axios.post(
    API_ENDPOINTS.EDUCATION_ATTENDANCES,
    camelCaseKeysToUnderscore(params)
  )
}

export function getEducationAttendance(id: number) {
  return axios.get([API_ENDPOINTS.EDUCATION_ATTENDANCES, id].join(''))
}

export function updateEducationAttendance(params: UserEducation) {
  return axios.patch(
    [API_ENDPOINTS.EDUCATION_ATTENDANCES, params.id].join(''),
    camelCaseKeysToUnderscore(params)
  )
}

export function deleteEducationAttendance(id: number) {
  return axios.delete([API_ENDPOINTS.EDUCATION_ATTENDANCES, id].join(''))
}
