import * as React from 'react'
import {
  LOCAL_STORAGE_USER_NAME,
  LOCAL_STORAGE_USER_ID,
} from 'commons/constants'
import SelectDriverComponent from './SelectDriverComponent'

type Props = {
  onComplete: ({ id }: { id: string }) => void
}

export const CommonAccountContext = React.createContext(null)
export const SelectAccountContext = React.createContext(null)

function TmpAccountSelectContainer({ onComplete }: Props) {
  const commonUser = {
    id: localStorage.getItem(LOCAL_STORAGE_USER_ID),
    name: localStorage.getItem(LOCAL_STORAGE_USER_NAME),
  }
  const [selectUser, setSelectUser] = React.useState(null)

  function setCommonUser({ id, name }: { id: string; name: string }) {
    localStorage.setItem(LOCAL_STORAGE_USER_ID, id)
    localStorage.setItem(LOCAL_STORAGE_USER_NAME, name)
    onComplete({ id: id })
  }

  return (
    <CommonAccountContext.Provider value={{ commonUser, setCommonUser }}>
      <SelectAccountContext.Provider value={{ selectUser, setSelectUser }}>
        <div>
          <SelectDriverComponent />
        </div>
      </SelectAccountContext.Provider>
    </CommonAccountContext.Provider>
  )
}

export default TmpAccountSelectContainer
