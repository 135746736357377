import * as React from 'react'
import { MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import EducationAttendanceDetail from 'components/organisms/education_attendances/Detail'
import { getEducationAttendance } from 'components/apis/educationAttendances'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import { FlexBox } from 'components/atoms/BoxComponents'
import { EditButton } from 'components/atoms/ButtonComponenets'
import { useTheme } from '@mui/material'

function AdminEducationAttendancesShow() {
  const { user_education_id } = useParams()
  const theme = useTheme()
  const navigate = useNavigate()
  const { data: userEducation, isLoading } = useQuery(
    [
      `admin/education_attendances/${user_education_id}`,
      'education_attendances',
    ],
    () =>
      getEducationAttendance(parseInt(user_education_id)).then(
        (res) => res.data
      )
  )

  return (
    <PageWrapper>
      <Helmet>
        <title>
          {PAGE_TITLES.EDUCATIONS.ATTENDANCES.SHOW[user_education_id] ??
            APPLICATION_NAME}
        </title>
      </Helmet>
      <FlexBox
        justifyContent={'space-between'}
        sx={{
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
          alignItems: 'center',
        }}
      >
        <h1>{MENUS.educations.children.education_attendances.title}</h1>
        <EditButton
          onClick={() => {
            navigate('edit/')
          }}
        >
          編集する
        </EditButton>
      </FlexBox>
      {isLoading ? (
        <CircularProgressBox />
      ) : (
        <EducationAttendanceDetail data={userEducation} />
      )}
    </PageWrapper>
  )
}

export default AdminEducationAttendancesShow
