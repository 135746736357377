import * as React from 'react'
import {
  ARTICLES,
  ADMIN_ROUTES,
  LOCAL_STORAGE_PAGE_KEY,
  ROUTES,
} from 'commons/constants'
import EnhancedTable, { HeadCell } from 'components/atoms/TableComponents'
import SearchForm from '../articles/SearchForm'
import {
  getLocalStorageDisplayLimit,
  getLocalStoragePageNumber,
  getLocalStorageOrder,
  isOrder,
} from 'commons/table'
import { UserContext } from 'providers/UserProvider'
import { Order } from 'commons/array'

const headCells: readonly HeadCell<Article>[] = [
  {
    id: 'title',
    label: ARTICLES.LABELS.TITLE,
    type: 'string',
  },
  {
    id: 'creatorDetail',
    label: ARTICLES.LABELS.USER_NAME,
    type: 'string',
  },
  {
    id: 'updatedAt',
    label: ARTICLES.LABELS.UPDATED_AT,
    type: 'string',
  },
]

export default function ArticlesTable(props: {
  data: Article[]
  totalCount: number
  paginateParams: PaginateParams
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setSearchParams: React.Dispatch<React.SetStateAction<SearchArticle>>
  defaultSearchParams: SearchArticle
  isLoading?: boolean
}) {
  const [order, setOrder] = React.useState<Order>('desc')
  const [orderBy, setOrderBy] = React.useState<keyof Article>('updatedAt')
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(
    props.paginateParams.displayLimit
  )
  const rows = props.data
  const totalCount = props.totalCount
  const user = React.useContext(UserContext)
  const isOrderBy = (arg): arg is keyof Article => {
    const arr = headCells.map((h) => {
      return h.id
    })
    return arr.includes(arg)
  }
  React.useEffect(() => {
    // ソート条件をlocalStorageから取得してset
    const { order, orderBy } = getLocalStorageOrder(
      LOCAL_STORAGE_PAGE_KEY.ARTICLES
    )
    if (isOrder(order) && isOrderBy(orderBy)) {
      setOrder(order)
      setOrderBy(orderBy)
    }
    //「ページあたりの行数」をloalStorageから取得してセット
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = getLocalStoragePageNumber(
      LOCAL_STORAGE_PAGE_KEY.ARTICLES,
      true
    )
    const newPaginateParams = {
      pageNumber: pageNumber,
      displayLimit: displayLimit,
    }
    props.setPaginateParams(newPaginateParams)
    setRowsPerPage(displayLimit)
    setPage(pageNumber)
  }, [])

  return (
    <EnhancedTable<Article>
      {...props}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      page={page}
      setPage={setPage}
      rows={rows}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      headCells={headCells}
      localStoragePageKey={LOCAL_STORAGE_PAGE_KEY.ARTICLES}
      totalCount={totalCount}
      basePath={
        user.admin ? ADMIN_ROUTES.ARTICLES_INDEX : ROUTES.ARTICLES_INDEX
      }
      isNewVersion
      searchForm={
        <SearchForm
          setSearchParams={props.setSearchParams}
          setPaginateParams={props.setPaginateParams}
          setPage={setPage}
          defaultSearchParams={props.defaultSearchParams}
          isLoading={props.isLoading}
        />
      }
    />
  )
}
