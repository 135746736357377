import * as React from 'react'
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormGetValues,
} from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  ErrorMessage,
  ConvertibleCustomDateSelect,
  ConvertibleCustomInput,
  ConvertibleCustomPhoneNumberSelect,
  ConvertibleCustomSelect,
  ConvertibleCustomCheckBox,
  ConvertibleCustomDropZone,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { DRIVERS, VALIDATION_MESSAGES } from 'commons/constants'
import { Box, styled, useTheme } from '@mui/material'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { useNavigate } from 'react-router-dom'
import { PREFECTURES } from 'commons/prefectures'
import { QUALIFICATION_TYPE } from 'commons/qualificationType'
import { palette } from 'components/theme'
import { UserContext } from 'providers/UserProvider'
import { ValueText } from 'components/atoms/TextComponents'

type ProfileProps = {
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
  handleModeChange: (mapKey: DriverFormModeMapKey, mode: FormMode) => void
  ledgerFiles: File[]
  setLedgerFiles: React.Dispatch<React.SetStateAction<File[]>>
}

export default function Profile(props: ProfileProps) {
  const navigate = useNavigate()
  const user = React.useContext(UserContext)

  const reload = () => {
    if (
      window.confirm('保存していないデータは削除されます。よろしいですか？')
    ) {
      navigate(0)
    }
  }

  const genderList = [
    { label: '男性', value: 'male' },
    { label: '女性', value: 'female' },
  ]

  const qualifications = Object.entries(props.getValues('qualification'))
    .filter((arr) => arr[1] == true)
    .map((arr) => arr[0])

  const qualificationsShowText = Object.values(qualifications)
    .map((value) => {
      return QUALIFICATION_TYPE.find((e) => e.value == value).label
    })
    .join(' / ')

  const bloodType = props.getValues('profile.bloodType')
  const bloodTypeText =
    bloodType === 'unknown' ? '不明' : bloodType?.toUpperCase()

  const onDrop = (acceptedFiles: File[]) => {
    props.setLedgerFiles([...props.ledgerFiles, ...acceptedFiles])
  }
  const removeLedgerFile = (index: number) => {
    props.setLedgerFiles(props.ledgerFiles.filter((_, i) => i !== index))
  }

  const isEducationTarget = props.getValues('isEducationTarget')
  const isEducationTargetText = isEducationTarget === true ? '対象' : '対象外'

  const Text = styled('p')({
    color: palette.text.black.main,
    margin: '4px 0',
  })

  const theme = useTheme()

  return (
    <BackGroundWhite>
      <FlexBox justifyContent="space-between" marginBottom="16px">
        <HeadingText>{DRIVERS.LABELS.PROFILE}</HeadingText>
        <Box>
          {/* 一時的にコメントアウト */}
          {/* {props.mode == 'show' && (
            <EditButtonOutlined
              onClick={() => {
                props.handleModeChange('profile', 'edit')
              }}
            >
              編集
            </EditButtonOutlined>
          )} */}
          {props.mode == 'edit' && (
            <FlexBox>
              <ApplyButton pattern="marginRight8" type="submit">
                保存する
              </ApplyButton>
              <CancelButtonOutlined onClick={reload}>
                キャンセル
              </CancelButtonOutlined>
            </FlexBox>
          )}
        </Box>
      </FlexBox>
      <FlexBox
        alignItems="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel
          sx={{
            marginRight: '32px',
            [theme.breakpoints.down('md')]: {
              marginRight: '0',
            },
          }}
        >
          {DRIVERS.LABELS.EMPLOYEE_NUMBER}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey="profile.employeeNumber"
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.profile?.employeeNumber && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
        <CustomLabel
          sx={{
            [theme.breakpoints.down('md')]: {
              marginRight: '0',
            },
          }}
        >
          {DRIVERS.LABELS.EMPLOYMENT_STATUS}
          <ValueText value={props.getValues('profile.employmentStatusText')} />
        </CustomLabel>
      </FlexBox>

      <CustomLabel
        sx={{
          [theme.breakpoints.down('md')]: {
            marginRight: '0',
          },
        }}
      >
        {DRIVERS.LABELS.LEDGER_CREATED_AT}
        <ConvertibleCustomDateSelect
          mode={props.mode}
          formKeys={{
            year: 'profile.ledgerCreatedYear',
            month: 'profile.ledgerCreatedMonth',
            day: 'profile.ledgerCreatedDay',
          }}
          control={props.control}
          getValues={props.getValues}
        />
      </CustomLabel>
      <FlexBox
        alignItems="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel
          sx={{
            marginRight: '32px',
            [theme.breakpoints.down('md')]: {
              marginRight: 0,
            },
          }}
        >
          {DRIVERS.LABELS.LAST_NAME}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey="profile.lastName"
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.profile?.lastName && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
        <CustomLabel
          sx={{
            marginRight: '32px',
            [theme.breakpoints.down('md')]: {
              marginRight: 0,
            },
          }}
        >
          {DRIVERS.LABELS.FIRST_NAME}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey="profile.firstName"
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.profile?.firstName && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
      </FlexBox>
      <FlexBox
        alignItems="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel
          sx={{
            marginRight: '32px',
            [theme.breakpoints.down('md')]: {
              marginRight: 0,
            },
          }}
        >
          {DRIVERS.LABELS.LAST_NAME_KANA}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey="profile.lastNameKana"
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.profile?.lastNameKana && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
        <CustomLabel
          sx={{
            marginRight: '32px',
            [theme.breakpoints.down('md')]: {
              marginRight: 0,
            },
          }}
        >
          {DRIVERS.LABELS.FIRST_NAME_KANA}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey="profile.firstNameKana"
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.profile?.firstNameKana && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
      </FlexBox>
      <FlexBox
        alignItems="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel
          sx={{
            marginRight: '32px',
            [theme.breakpoints.down('md')]: {
              marginRight: 0,
            },
          }}
        >
          {DRIVERS.LABELS.GENDER}
          <ConvertibleCustomSelect
            mode={props.mode}
            formKey="profile.gender"
            control={props.control}
            getValues={props.getValues}
            options={genderList}
          ></ConvertibleCustomSelect>
          {props.errors.profile?.gender && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
        <CustomLabel
          sx={{
            [theme.breakpoints.down('md')]: {
              marginRight: 0,
            },
          }}
        >
          {DRIVERS.LABELS.BIRTHDAY}
          <ConvertibleCustomDateSelect
            mode={props.mode}
            formKeys={{
              year: 'profile.birthedYear',
              month: 'profile.birthedMonth',
              day: 'profile.birthedDay',
            }}
            control={props.control}
            getValues={props.getValues}
          />
        </CustomLabel>
      </FlexBox>
      <FlexBox
        alignItems="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel
          sx={{
            marginRight: '32px',
            [theme.breakpoints.down('md')]: {
              marginRight: 0,
            },
          }}
        >
          {DRIVERS.LABELS.BLOOD_TYPE}
          <Text>{bloodTypeText}</Text>
        </CustomLabel>
      </FlexBox>
      <FlexBox
        alignItems="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel
          sx={{
            marginRight: '32px',
            [theme.breakpoints.down('md')]: {
              marginRight: 0,
            },
          }}
        >
          {DRIVERS.LABELS.EMAIL}
          <ValueText value={props.getValues('email')} />
        </CustomLabel>
      </FlexBox>
      <FlexBox
        alignItems="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel
          sx={{
            [theme.breakpoints.down('md')]: {
              marginRight: 0,
            },
          }}
        >
          {DRIVERS.LABELS.PHONE_NUMBER}
          <ConvertibleCustomPhoneNumberSelect
            mode={props.mode}
            formKeys={{
              first: 'profile.phoneNumberFirst',
              second: 'profile.phoneNumberSecond',
              third: 'profile.phoneNumberThird',
            }}
            register={props.register}
            getValues={props.getValues}
          />
        </CustomLabel>
        <CustomLabel
          sx={{
            [theme.breakpoints.down('md')]: {
              marginRight: 0,
            },
          }}
        >
          {DRIVERS.LABELS.PERSONAL_PHONE_NUMBER1}
          <ConvertibleCustomPhoneNumberSelect
            mode={props.mode}
            formKeys={{
              first: 'profile.personalPhoneNumber1First',
              second: 'profile.personalPhoneNumber1Second',
              third: 'profile.personalPhoneNumber1Third',
            }}
            register={props.register}
            getValues={props.getValues}
          />
        </CustomLabel>
      </FlexBox>
      <FlexBox
        alignItems="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel
          sx={{
            [theme.breakpoints.down('md')]: {
              marginRight: 0,
            },
          }}
        >
          {DRIVERS.LABELS.PERSONAL_PHONE_NUMBER2}
          <ConvertibleCustomPhoneNumberSelect
            mode={props.mode}
            formKeys={{
              first: 'profile.personalPhoneNumber2First',
              second: 'profile.personalPhoneNumber2Second',
              third: 'profile.personalPhoneNumber2Third',
            }}
            register={props.register}
            getValues={props.getValues}
          />
        </CustomLabel>
      </FlexBox>
      <FlexBox
        alignItems="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel
          sx={{
            marginRight: '32px',
            [theme.breakpoints.down('md')]: {
              marginRight: 0,
            },
          }}
        >
          {DRIVERS.LABELS.ZIP_CODE}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey="profile.zipCode"
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.profile?.zipCode && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
        <CustomLabel
          sx={{
            [theme.breakpoints.down('md')]: {
              marginRight: 0,
            },
          }}
        >
          {DRIVERS.LABELS.PREFECTURE}
          <ConvertibleCustomSelect
            mode={props.mode}
            formKey="profile.prefecture"
            control={props.control}
            getValues={props.getValues}
            options={PREFECTURES}
          />
        </CustomLabel>
      </FlexBox>
      <FlexBox>
        <CustomLabel
          sx={{
            marginRight: '32px',
            [theme.breakpoints.down('md')]: {
              marginRight: 0,
            },
          }}
        >
          {DRIVERS.LABELS.ADDRESS}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey="profile.address"
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
          {props.errors.profile?.address && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
      </FlexBox>
      <FlexBox>
        <CustomLabel
          sx={{
            marginRight: '32px',
            [theme.breakpoints.down('md')]: {
              marginRight: 0,
            },
          }}
        >
          {DRIVERS.LABELS.QUALIFICATIONS_TYPE}
          <ConvertibleCustomCheckBox
            mode={props.mode}
            baseKey="qualification"
            control={props.control}
            items={QUALIFICATION_TYPE}
            showText={qualificationsShowText || '未入力'}
          ></ConvertibleCustomCheckBox>
        </CustomLabel>
      </FlexBox>
      <FlexBox>
        <CustomLabel sx={{ marginRight: '32px' }}>
          {DRIVERS.LABELS.ENGAGED_WORK_TYPE}
          <ConvertibleCustomInput
            mode={props.mode}
            formKey="profile.engagedWorkType"
            register={props.register}
            getValues={props.getValues}
          ></ConvertibleCustomInput>
        </CustomLabel>
      </FlexBox>
      <FlexBox>
        <CustomLabel sx={{ marginRight: '32px' }}>
          {DRIVERS.LABELS.IS_EDUCATION_TARGET}
          <Text>{isEducationTargetText}</Text>
        </CustomLabel>
      </FlexBox>
      <FlexBox>
        <CustomLabel sx={{ width: '100%' }}>
          {DRIVERS.LABELS.LEDGER_FILES}
          <ConvertibleCustomDropZone
            mode={props.mode}
            formKey={`profile.ledgerFiles`}
            getValues={props.getValues}
            control={props.control}
            files={props.ledgerFiles}
            onDrop={onDrop}
            onCancelIconClick={removeLedgerFile}
          />
        </CustomLabel>
      </FlexBox>
    </BackGroundWhite>
  )
}
