export const monthList = [
  {
    label: '　',
    value: null,
  },
  ...Array.from({ length: 12 }, (_, i) => ({
    label: `${i + 1}月`,
    value: i + 1,
  })),
]
