import * as React from 'react'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { Box, styled } from '@mui/material'
import { palette, theme } from 'components/theme'
import { VEHICLES } from 'commons/constants'
import { FlexBox } from 'components/atoms/BoxComponents'
import { ValueText } from 'components/atoms/TextComponents'

type GreenManagementProps = {
  data: Vehicle
}

export default function GreenManagement(props: GreenManagementProps) {
  const Inner = styled('div')({
    padding: '32px',
  })
  const Title = styled('h2')({
    fontSize: '20px',
    margin: 0,
    marginBottom: '16px',
  })
  const Text = styled('p')({
    fontSize: '16px',
    margin: 0,
  })
  const Label = styled('p')({
    fontSize: '14px',
    margin: 0,
    color: palette.text.gray.pale,
    marginTop: '32px',
    marginBottom: '8px',
  })
  const TextWrapper = styled('div')({
    width: '300px',
    paddingBottom: '8px',
    paddingLeft: '4px',
    borderBottom: `1px solid ${palette.border.pale}`,
    overflowWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  })

  return (
    <BackGroundWhite withoutPadding={true}>
      <Inner>
        <Title>{VEHICLES.LABELS.GREEN_MANAGEMENT}</Title>
        <FlexBox
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Box mr={3}>
            <Label>{VEHICLES.LABELS.GREEN_MANAGEMENT_VEHICLE_TYPE}</Label>
            <TextWrapper>
              <ValueText value={props.data.greenManagementVehicleTypeText} />
            </TextWrapper>
          </Box>
        </FlexBox>
      </Inner>
    </BackGroundWhite>
  )
}
