import * as React from 'react'
import {
  BackGroundPalePink,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import { Box, styled } from '@mui/material'
import { palette, theme } from 'components/theme'
import { VEHICLES } from 'commons/constants'
import { FlexBox } from 'components/atoms/BoxComponents'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

type TaxWeightProps = {
  data: Vehicle
  taxWeightImages: File[]
  taxWeightChildrenFiles: File[][]
}

export default function TaxWeight(props: TaxWeightProps) {
  const Title = styled('h2')({
    fontSize: '20px',
    margin: 0,
    marginBottom: '16px',
  })
  const SubTitle = styled('p')({
    fontSize: '16px',
    margin: 0,
    color: palette.text.gray.deep,
    fontWeight: 'bold',
  })

  const Text = styled('p')({
    fontSize: '16px',
    margin: 0,
  })
  const Label = styled('p')({
    fontSize: '14px',
    margin: 0,
    color: palette.text.gray.pale,
    marginTop: '32px',
    marginBottom: '8px',
  })
  const TextWrapper = styled('div')({
    width: '300px',
    paddingBottom: '8px',
    paddingLeft: '4px',
    borderBottom: `1px solid ${palette.border.pale}`,
    overflowWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  })
  const TextWrapperFull = styled(TextWrapper)({
    width: '628px',
  })
  const FileTextWrapper = styled(TextWrapper)({
    width: '100%',
    marginTop: '16px',
  })

  const formatDate = (year: string, month: string, day: string) => {
    if (year && month && day) return `${year}年${month}月${day}日`
    else return '未入力'
  }

  return (
    <BackGroundWhite>
      <Title>{VEHICLES.LABELS.TAX_WEIGHT}</Title>
      {props.data.taxWeight.length == 0 ? (
        <Text>データがありません</Text>
      ) : (
        props.data.taxWeight.map((t, i) => {
          return (
            <Box mb={3} key={`tax-weight-${i}`}>
              <BackGroundPalePink>
                <SubTitle>
                  {VEHICLES.LABELS.TAX_WEIGHT}
                  {i + 1}
                </SubTitle>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.TAX_WEIGHT_TOTAL_FEE}</Label>
                    <TextWrapper>
                      <Text>{t.totalFee ? `${t.totalFee}円` : '未入力'}</Text>
                    </TextWrapper>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.TAX_WEIGHT_START_DATE}</Label>
                    <TextWrapper>
                      <Text>
                        {formatDate(t.startYear, t.startMonth, t.startDay)}
                      </Text>
                    </TextWrapper>
                  </Box>
                  <Box>
                    <Label>{VEHICLES.LABELS.TAX_WEIGHT_END_DATE}</Label>
                    <TextWrapper>
                      <Text>{formatDate(t.endYear, t.endMonth, t.endDay)}</Text>
                    </TextWrapper>
                  </Box>
                </FlexBox>
                <FlexBox
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box mr={3}>
                    <Label>{VEHICLES.LABELS.TAX_WEIGHT_MEMO}</Label>
                    <TextWrapperFull>
                      <Text>{t.memo || '未入力'}</Text>
                    </TextWrapperFull>
                  </Box>
                </FlexBox>
                <Label>
                  {VEHICLES.LABELS.FILE}(
                  {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
                </Label>
                <FileTextWrapper>
                  {props.taxWeightChildrenFiles[i]?.length > 0 ? (
                    props.taxWeightChildrenFiles[i].map((file, i) => {
                      return (
                        <FlexBox key={`${file.name}-${i}`} mb={1}>
                          <InsertDriveFileOutlinedIcon fontSize="small" />
                          <Text sx={{ marginLeft: '8px' }}>
                            <a
                              href={URL.createObjectURL(file)}
                              download={decodeURI(file.name)}
                            >
                              {decodeURI(file.name)}
                            </a>
                          </Text>
                        </FlexBox>
                      )
                    })
                  ) : (
                    <Text>ファイルなし</Text>
                  )}
                </FileTextWrapper>
              </BackGroundPalePink>
            </Box>
          )
        })
      )}
      <Label>
        {VEHICLES.LABELS.FILE}({VEHICLES.LABELS.TAX_WEIGHT}の
        {VEHICLES.LABELS.FILE_UPLOAD_DESCRIPTION_PARENT})
      </Label>
      <FileTextWrapper>
        {props.taxWeightImages.length > 0 ? (
          props.taxWeightImages.map((file, i) => {
            return (
              <FlexBox key={`${file.name}-${i}`} mb={1}>
                <InsertDriveFileOutlinedIcon fontSize="small" />
                <Text sx={{ marginLeft: '8px' }}>
                  <a
                    href={URL.createObjectURL(file)}
                    download={decodeURI(file.name)}
                  >
                    {decodeURI(file.name)}
                  </a>
                </Text>
              </FlexBox>
            )
          })
        ) : (
          <Text>ファイルなし</Text>
        )}
      </FileTextWrapper>
    </BackGroundWhite>
  )
}
