import * as React from 'react'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomCheckBoxForm,
  HeadingText,
} from 'components/atoms/FormComponents'
import { FlexBoxRow } from 'components/atoms/BoxComponents'
import { styled } from '@mui/styles'
import { Controller } from 'react-hook-form'

export default function Equipments(props: { control; vehicleOptions }) {
  const { control, vehicleOptions } = props

  const CustomCheckBoxFormContain = styled('div')({
    marginTop: '10px',
  })

  const handleCheck = (ids, checkedId) => {
    const newIds = ids?.includes(checkedId)
      ? ids?.filter((id) => id !== checkedId)
      : [...(ids ?? []), checkedId]
    return newIds
  }

  return (
    <BackGroundWhite>
      <HeadingText>装備品</HeadingText>
      <CustomLabel>
        <FlexBoxRow flexWrap="wrap">
          {Object.values(vehicleOptions?.equipment || []).map(
            (obj: ReactSelectOptionProps, index: number) => (
              <CustomCheckBoxFormContain key={index}>
                <Controller
                  name={`equipments`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomCheckBoxForm>
                      <label>
                        <input
                          type="checkbox"
                          checked={value?.includes(obj.value)}
                          onChange={() =>
                            onChange(handleCheck(value, obj.value))
                          }
                        />
                        {obj.label}
                      </label>
                    </CustomCheckBoxForm>
                  )}
                />
              </CustomCheckBoxFormContain>
            )
          )}
        </FlexBoxRow>
      </CustomLabel>
    </BackGroundWhite>
  )
}
