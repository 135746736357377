import * as React from 'react'
import { useForm } from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { FlexBox } from 'components/atoms/BoxComponents'
import {
  CustomLabel,
  CustomInput,
  WithRequiredBadge,
  CustomForm,
  WithDescriptionTooltipIcon,
} from 'components/atoms/FormComponents'
import ProcessingModal from 'components/atoms/ProcessingModal'
import { toastOnError, toastOnSuccess } from 'commons/toaster'
import { ShouldSubmitOnKeyPress } from 'commons/formUtils'
import { updateCsvHeaderAssociation } from 'components/apis/csvHeaderAssociations'
import { UserContext } from 'providers/UserProvider'
import { slackNotification } from 'commons/slackNotification'
import { AxiosError } from 'axios'
import { useErrorHandler } from 'react-error-boundary'
import { useMutation } from '@tanstack/react-query'
import { CSV_HEADER_ASSOCIATIONS } from 'commons/constants'

interface Props {
  data?: DriverImport
}

function IsDuplication(data: DriverImport) {
  delete data['category']
  const values = Object.values(data).filter(Boolean)
  const uniqueValues = new Set(values)
  return values.length !== uniqueValues.size
}

function defaultHeader(str: string, template: string) {
  if (str === undefined) {
    return template
  }

  return str
}

function DriverImportForm(props: Props) {
  const user = React.useContext(UserContext)
  const handleError = useErrorHandler()
  const [isProcessingModalShow, setIsProcessingModalShow] =
    React.useState(false)
  const defaultValues = {
    ...props.data,
    category: 'driver',
    loginId: defaultHeader(
      props.data?.loginId,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LOGIN_ID
    ),
    password: defaultHeader(
      props.data?.password,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.PASSWORD
    ),
    officeName: defaultHeader(
      props.data?.officeName,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.OFFICE_NAME
    ),
    role: defaultHeader(
      props.data?.role,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.ROLE
    ),
    employeeNumber: defaultHeader(
      props.data?.employeeNumber,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.EMPLOYEE_NUMBER
    ),
    ledgerCreatedDate: defaultHeader(
      props.data?.ledgerCreatedDate,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LEDGER_CREATED_DATE
    ),
    name: defaultHeader(
      props.data?.name,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.NAME
    ),
    nameKana: defaultHeader(
      props.data?.nameKana,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.NAME_KANA
    ),
    gender: defaultHeader(
      props.data?.gender,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.GENDER
    ),
    birthday: defaultHeader(
      props.data?.birthday,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.BIRTHDAY
    ),
    bloodType: defaultHeader(
      props.data?.bloodType,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.BLOOD_TYPE
    ),
    email: defaultHeader(
      props.data?.email,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.EMAIL
    ),
    phoneNumber: defaultHeader(
      props.data?.phoneNumber,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.PHONE_NUMBER
    ),
    personalPhoneNumber1: defaultHeader(
      props.data?.personalPhoneNumber1,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.PERSONAL_PHONE_NUMBER1
    ),
    personalPhoneNumber2: defaultHeader(
      props.data?.personalPhoneNumber2,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.PERSONAL_PHONE_NUMBER2
    ),
    zipCode: defaultHeader(
      props.data?.zipCode,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.ZIP_CODE
    ),
    prefecture: defaultHeader(
      props.data?.prefecture,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.PREFECTURE
    ),
    address: defaultHeader(
      props.data?.address,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.ADDRESS
    ),
    qualificationOperationAssistant: defaultHeader(
      props.data?.qualificationOperationAssistant,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
        .QUALIFICATION_OPERATION_ASSISTANT
    ),
    qualificationOperationManager: defaultHeader(
      props.data?.qualificationOperationManager,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
        .QUALIFICATION_OPERATION_MANAGER
    ),
    qualificationMaintenanceAssistant: defaultHeader(
      props.data?.qualificationMaintenanceAssistant,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
        .QUALIFICATION_MAINTENANCE_ASSISTANT
    ),
    qualificationMaintenanceManager: defaultHeader(
      props.data?.qualificationMaintenanceManager,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
        .QUALIFICATION_MAINTENANCE_MANAGER
    ),
    qualificationForklift: defaultHeader(
      props.data?.qualificationForklift,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.QUALIFICATION_FORKLIFT
    ),
    qualificationSlinging: defaultHeader(
      props.data?.qualificationSlinging,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.QUALIFICATION_SLINGING
    ),
    qualificationCrane: defaultHeader(
      props.data?.qualificationCrane,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.QUALIFICATION_CRANE
    ),
    engagedWorkType: defaultHeader(
      props.data?.engagedWorkType,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.ENGAGED_WORK_TYPE
    ),
    licenseNumber: defaultHeader(
      props.data?.licenseNumber,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_NUMBER
    ),
    licenseIssuancedDate: defaultHeader(
      props.data?.licenseIssuancedDate,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_ISSUANCED_DATE
    ),
    licenseExpiredDate: defaultHeader(
      props.data?.licenseExpiredDate,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_EXPIRED_DATE
    ),
    licenseStandard: defaultHeader(
      props.data?.licenseStandard,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_STANDARD
    ),
    licenseLimitedSemiMidsize: defaultHeader(
      props.data?.licenseLimitedSemiMidsize,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_LIMITED_SEMI_MIDSIZE
    ),
    licenseSemiMidsize: defaultHeader(
      props.data?.licenseSemiMidsize,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_SEMI_MIDSIZE
    ),
    licenseLimitedMidsize: defaultHeader(
      props.data?.licenseLimitedMidsize,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_LIMITED_MIDSIZE
    ),
    licenseMidsize: defaultHeader(
      props.data?.licenseMidsize,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_MIDSIZE
    ),
    licenseLarge: defaultHeader(
      props.data?.licenseLarge,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_LARGE
    ),
    licenseLargeSpecial: defaultHeader(
      props.data?.licenseLargeSpecial,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_LARGE_SPECIAL
    ),
    licenseSmallSpecial: defaultHeader(
      props.data?.licenseSmallSpecial,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_SMALL_SPECIAL
    ),
    licenseTowing: defaultHeader(
      props.data?.licenseTowing,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_TOWING
    ),
    licenseMemo: defaultHeader(
      props.data?.licenseMemo,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_MEMO
    ),
    healthInsuranceSubscribedDate: defaultHeader(
      props.data?.healthInsuranceSubscribedDate,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
        .HEALTH_INSURANCE_SUBSCRIBED_DATE
    ),
    healthInsuranceNumber: defaultHeader(
      props.data?.healthInsuranceNumber,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.HEALTH_INSURANCE_NUMBER
    ),
    pensionInsuranceSubscribedDate: defaultHeader(
      props.data?.pensionInsuranceSubscribedDate,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
        .PENISION_INSURANCE_SUBSCRIBED_DATE
    ),
    pensionInsuranceNumber: defaultHeader(
      props.data?.pensionInsuranceNumber,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.PENISION_INSURANCE_NUMBER
    ),
    employmentInsuranceSubscribedDate: defaultHeader(
      props.data?.employmentInsuranceSubscribedDate,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
        .EMPLOYMENT_INSURANCE_SUBSCRIBED_DATE
    ),
    employmentInsuranceNumber: defaultHeader(
      props.data?.employmentInsuranceNumber,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.EMPLOYMENT_INSURANCE_NUMBER
    ),
    injuryInsuranceSubscribedDate: defaultHeader(
      props.data?.injuryInsuranceSubscribedDate,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
        .INJURY_INSURANCE_SUBSCRIBED_DATE
    ),
    injuryInsuranceNumber: defaultHeader(
      props.data?.injuryInsuranceNumber,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.INJURY_INSURANCE_NUMBER
    ),
    careerJoinedDate: defaultHeader(
      props.data?.careerJoinedDate,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.CAREER_JOINED_DATE
    ),
    careerRetiredDate: defaultHeader(
      props.data?.careerRetiredDate,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.CAREER_RETIRED_DATE
    ),
    careerRetiredReason: defaultHeader(
      props.data?.careerRetiredReason,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.CAREER_RETIRED_REASON
    ),
    careerSelectedDriverDate: defaultHeader(
      props.data?.careerSelectedDriverDate,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.CAREER_SELECTED_DRIVER_DATE
    ),
    careerDiedDate: defaultHeader(
      props.data?.careerDiedDate,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.CAREER_DIED_DATE
    ),
    careerDiedReason: defaultHeader(
      props.data?.careerDiedReason,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.CAREER_DIED_REASON
    ),
    profileMemo: defaultHeader(
      props.data?.profileMemo,
      CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.PROFILE_MEMO
    ),
  }
  const { register, getValues, trigger } = useForm<DriverImport>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: defaultValues,
  })

  const mutation = useMutation({
    mutationFn: updateCsvHeaderAssociation,
    onError: (e: AxiosError<{ errors: string[] }>) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors.join(','))
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
  })

  const mutationWithoutNavigation = useMutation({
    mutationFn: updateCsvHeaderAssociation,
    onSuccess: () => {
      toastOnSuccess('保存しました')
    },
    onError: (e: AxiosError<{ errors: string[] }>) => {
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors.join(','))
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
    onSettled: () => {
      setIsProcessingModalShow(false)
    },
  })

  const submit = async (callback: () => void) => {
    const hasNotError = await trigger(undefined, { shouldFocus: true })
    const isDuplication = IsDuplication(getValues())
    if (isDuplication) {
      toastOnError(
        'ヘッダーの項目名に重複があります。重複がなくなるように修正してください。'
      )
    } else if (hasNotError) {
      callback()
    } else {
      toastOnError('入力内容に不備があります')
    }
  }

  const submitCallback = async () => {
    setIsProcessingModalShow(true)
    const data = structuredClone(getValues())
    mutation.mutate(data, {
      onSuccess: () => {
        scrollTo({ top: 0 })
        toastOnSuccess('保存しました')
      },
      onSettled: () => {
        setIsProcessingModalShow(false)
      },
    })
  }

  const handleKeyDown = async (e) => {
    if (ShouldSubmitOnKeyPress(e)) {
      const hasNotError = await trigger(undefined, { shouldFocus: true })
      const isDuplication = IsDuplication(getValues())
      if (isDuplication) {
        toastOnError(
          'ヘッダーの項目名に重複があります。重複がなくなるように修正してください。'
        )
      } else if (hasNotError) {
        setIsProcessingModalShow(true)
        e.preventDefault()
        const params = structuredClone(getValues())
        mutationWithoutNavigation.mutate(params)
        ;(document.activeElement as HTMLElement)?.blur()
      } else {
        toastOnError('入力内容に不備があります')
      }
    }
  }

  return (
    <>
      <BackGroundWhite>
        <CustomForm onKeyDown={handleKeyDown}>
          <CustomLabel>
            従業員管理の画面項目とインポートするCSVのヘッダー項目を紐づけるための画面です。
            <br />
            各画面項目に紐づけたいCSVヘッダーの項目名を入力してください。
          </CustomLabel>
          <CustomLabel>
            <WithRequiredBadge>
              {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.NAME}
            </WithRequiredBadge>
            <CustomInput {...register('name', { required: true })} />
          </CustomLabel>
          <CustomLabel>
            <WithRequiredBadge>
              {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.NAME_KANA}
            </WithRequiredBadge>
            <CustomInput {...register('nameKana', { required: true })} />
          </CustomLabel>
          <CustomLabel>
            <FlexBox>
              {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LOGIN_ID}
              <WithDescriptionTooltipIcon text="CSVインポートでアカウントを新規作成した際に未指定の場合、ログインIDは自動で生成されます。" />
            </FlexBox>
            <CustomInput {...register('loginId')} />
          </CustomLabel>
          <CustomLabel>
            <FlexBox>
              {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.PASSWORD}
              <WithDescriptionTooltipIcon text="CSVインポートでアカウントを新規作成した際に未指定の場合、登録した日の「年月日」が登録されます。" />
            </FlexBox>
            <CustomInput {...register('password')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.OFFICE_NAME}
            <CustomInput {...register('officeName')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.ROLE}
            <CustomInput {...register('role')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.EMPLOYEE_NUMBER}
            <CustomInput {...register('employeeNumber')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LEDGER_CREATED_DATE}
            <CustomInput {...register('ledgerCreatedDate')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.GENDER}
            <CustomInput {...register('gender')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.BIRTHDAY}
            <CustomInput {...register('birthday')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.BLOOD_TYPE}
            <CustomInput {...register('bloodType')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.EMAIL}
            <CustomInput {...register('email')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.PHONE_NUMBER}
            <CustomInput {...register('phoneNumber')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .PERSONAL_PHONE_NUMBER1
            }
            <CustomInput {...register('personalPhoneNumber1')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .PERSONAL_PHONE_NUMBER2
            }
            <CustomInput {...register('personalPhoneNumber2')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.ZIP_CODE}
            <CustomInput {...register('zipCode')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.PREFECTURE}
            <CustomInput {...register('prefecture')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.ADDRESS}
            <CustomInput {...register('address')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .QUALIFICATION_OPERATION_ASSISTANT
            }
            <CustomInput {...register('qualificationOperationAssistant')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .QUALIFICATION_OPERATION_MANAGER
            }
            <CustomInput {...register('qualificationOperationManager')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .QUALIFICATION_MAINTENANCE_ASSISTANT
            }
            <CustomInput {...register('qualificationMaintenanceAssistant')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .QUALIFICATION_MAINTENANCE_MANAGER
            }
            <CustomInput {...register('qualificationMaintenanceManager')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .QUALIFICATION_FORKLIFT
            }
            <CustomInput {...register('qualificationForklift')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .QUALIFICATION_SLINGING
            }
            <CustomInput {...register('qualificationSlinging')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.QUALIFICATION_CRANE}
            <CustomInput {...register('qualificationCrane')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.ENGAGED_WORK_TYPE}
            <CustomInput {...register('engagedWorkType')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .HEALTH_INSURANCE_SUBSCRIBED_DATE
            }
            <CustomInput {...register('healthInsuranceSubscribedDate')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .HEALTH_INSURANCE_NUMBER
            }
            <CustomInput {...register('healthInsuranceNumber')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .PENISION_INSURANCE_SUBSCRIBED_DATE
            }
            <CustomInput {...register('pensionInsuranceSubscribedDate')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .PENISION_INSURANCE_NUMBER
            }
            <CustomInput {...register('pensionInsuranceNumber')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .EMPLOYMENT_INSURANCE_SUBSCRIBED_DATE
            }
            <CustomInput {...register('employmentInsuranceSubscribedDate')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .EMPLOYMENT_INSURANCE_NUMBER
            }
            <CustomInput {...register('employmentInsuranceNumber')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .INJURY_INSURANCE_SUBSCRIBED_DATE
            }
            <CustomInput {...register('injuryInsuranceSubscribedDate')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .INJURY_INSURANCE_NUMBER
            }
            <CustomInput {...register('injuryInsuranceNumber')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.CAREER_JOINED_DATE}
            <CustomInput {...register('careerJoinedDate')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.CAREER_RETIRED_DATE}
            <CustomInput {...register('careerRetiredDate')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.CAREER_RETIRED_REASON}
            <CustomInput {...register('careerRetiredReason')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .CAREER_SELECTED_DRIVER_DATE
            }
            <CustomInput {...register('careerSelectedDriverDate')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.CAREER_DIED_DATE}
            <CustomInput {...register('careerDiedDate')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.CAREER_DIED_REASON}
            <CustomInput {...register('careerDiedReason')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_NUMBER}
            <CustomInput {...register('licenseNumber')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .LICENSE_ISSUANCED_DATE
            }
            <CustomInput {...register('licenseIssuancedDate')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_EXPIRED_DATE}
            <CustomInput {...register('licenseExpiredDate')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_STANDARD}
            <CustomInput {...register('licenseStandard')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .LICENSE_LIMITED_SEMI_MIDSIZE
            }
            <CustomInput {...register('licenseLimitedSemiMidsize')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_SEMI_MIDSIZE}
            <CustomInput {...register('licenseSemiMidsize')} />
          </CustomLabel>
          <CustomLabel>
            {
              CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT
                .LICENSE_LIMITED_MIDSIZE
            }
            <CustomInput {...register('licenseLimitedMidsize')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_MIDSIZE}
            <CustomInput {...register('licenseMidsize')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_LARGE}
            <CustomInput {...register('licenseLarge')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_LARGE_SPECIAL}
            <CustomInput {...register('licenseLargeSpecial')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_SMALL_SPECIAL}
            <CustomInput {...register('licenseSmallSpecial')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_TOWING}
            <CustomInput {...register('licenseTowing')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.LICENSE_MEMO}
            <CustomInput {...register('licenseMemo')} />
          </CustomLabel>
          <CustomLabel>
            {CSV_HEADER_ASSOCIATIONS.LABELS.DRIVER_IMPORT.PROFILE_MEMO}
            <CustomInput {...register('profileMemo')} />
          </CustomLabel>
        </CustomForm>
      </BackGroundWhite>
      <FlexBox justifyContent={'flex-end'} sx={{ marginTop: '8px' }}>
        <ApplyButton
          pattern="block"
          onClick={() => submit(() => submitCallback())}
        >
          保存する
        </ApplyButton>
      </FlexBox>
      <ProcessingModal isOpen={isProcessingModalShow} />
    </>
  )
}

export default DriverImportForm
