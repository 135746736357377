export type Order = 'asc' | 'desc'
export type CellType =
  | 'string'
  | 'money'
  | 'status'
  | 'date'
  | 'alcoholCheck'
  | 'statefulText'
export type StatefulText = {
  state: 'error' | 'none'
  text: string
}

export const sortBy = <T>(
  arr: Array<T>,
  order: 'asc' | 'desc',
  orderBy: keyof T
) => {
  return stableSort(arr, getComparator<T>(order, orderBy))
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator<T>(
  order: Order,
  orderBy: keyof T
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator<T>(a, b, orderBy)
    : (a, b) => -descendingComparator<T>(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
