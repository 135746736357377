import { range } from 'commons/functions'

export const hourRange = range(0, 24).map((value) => ({
  label: value,
  value: value,
}))

export const minutesRange = range(0, 60).map((value) => ({
  label: value,
  value: value,
}))

export const secondsRange = range(0, 60).map((value) => ({
  label: value,
  value: value,
}))

export const hhmm2Hours = (hhmm: string) => {
  const hhmmArr = hhmm?.split(':') ?? [0]
  const hh = Number(hhmmArr[0])
  const mm = hhmmArr.length < 2 ? 0 : Number(hhmmArr[1])
  const hours = Number(hh) + (Number(mm) ? Number(mm) / 60 : 0)

  return hours
}

export const hhmmFormat = (hhmm: string) => {
  const hhmmArr = hhmm?.split(':') ?? [0]
  const hh = Number(hhmmArr[0])
  const mm = hhmmArr.length < 2 ? 0 : Number(hhmmArr[1])

  return { hh, mm }
}

export const timeToMinutes = (time: string): number => {
  if (!time) return 0

  const [hours, minutes] = time.split(':').map(Number)
  return hours * 60 + minutes
}

export const combineDateAndTime = (date: Date, time: Date) => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds()
  )
}

/*
 * 時間（秒単位）をHH:MM形式の文字列に変換する
 *
 * @param seconds: number 秒数
 *
 * @return string
 *
 * @example secondsToHHMM(3600)
 * @description '01時間00分'
 */
export const secondsToHHMM = (seconds: number): string => {
  if (seconds === 0) {
    return '00時間00分'
  }

  const hours: number = Math.floor(seconds / 3600)
  const minutes: number = Math.floor((seconds % 3600) / 60)

  const hoursString: string = String(hours).padStart(2, '0')
  const minutesString: string = String(minutes).padStart(2, '0')

  return `${hoursString}時間${minutesString}分`
}

/**
 * timestampをHH:MM形式の文字列に変換する
 *
 * @param date: Date
 *
 * @returns string
 *
 * @example timestampToHHMM(new Date())
 * @description '12時34分'
 */
export const timestampToHHMM = (date: Date): string => {
  const hours = date.getHours()
  const minutes = date.getMinutes()

  return `${hours}時${minutes}分`
}

/**
 * 経過時間（秒単位）を計算する
 *
 * @param startTime
 * @param endTime
 * @return number
 *
 * @example calcElapsedTime(new Date('2021-01-01T00:00:00'), new Date('2021-01-01T01:00:00'))
 * @description 3600
 */
export const calcElapsedTime = (startTime: Date, endTime: Date): number => {
  const elapsedTimeInMilliseconds = endTime.getTime() - startTime.getTime()
  const elapsedTimeInSeconds = elapsedTimeInMilliseconds / 1000

  return elapsedTimeInSeconds
}

/*
 * 指定した分数間隔で時刻の配列を[{label: `HH:MM`, value: `HH:MM`}]フォーマットで、生成する
 *
 * @param interval: number - 分数間隔
 *
 * @return { label: string, value: string }[]
 *
 * @example generateTimeArrayWithInterval(15)
 * @description { label: '00:00', value: '00:00' }〜{ label: '23:45', value: '23:45' }
 */
export const generateTimeArrayWithInterval = (
  interval: number
): {
  label: string
  value: string
}[] => {
  interval = interval || 15

  const hours = Array.from({ length: 24 }, (_, i) =>
    i < 10 ? '0' + i : '' + i
  )
  const minutes = Array.from({ length: 60 / interval }, (_, i) =>
    i * interval < 10 ? '0' + i * interval : '' + i * interval
  )

  const timeRange = hours
    .map((hour) =>
      minutes.map((minute) => ({
        label: `${hour}:${minute}`,
        value: `${hour}:${minute}`,
      }))
    )
    .flat()

  return timeRange
}
