import * as React from 'react'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { UserContext } from 'providers/UserProvider'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { fetchNotifications } from 'components/apis/notifications'
import { useErrorHandler } from 'react-error-boundary'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import { Helmet } from 'react-helmet-async'
import EducationsIndex from './educations'

function Index() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const user = React.useContext(UserContext)
  const [notifications, setNotifications] = React.useState<Notification[]>(null)
  const [totalCount, setTotalCount] = React.useState(0)
  const [loading, setLoading] = React.useState(true)
  const NOTIFICATION_TABLE_ROWS_PER_PAGE = 5
  const [paginateParams, setPaginateParams] = React.useState<PaginateParams>({
    pageNumber: 1,
    displayLimit: NOTIFICATION_TABLE_ROWS_PER_PAGE,
  })
  const handleError = useErrorHandler()
  const defaultSearchParams = {
    notificationType: '',
    leftDays: undefined,
    includePastNotifications: false,
    officeId: user.officeId,
  }
  const [searchParams, setSearchParams] =
    React.useState<SearchNotification>(defaultSearchParams)

  React.useEffect(() => {
    fetchNotifications(searchParams, paginateParams)
      .then((response) => {
        setNotifications(response.data.notifications)
        setTotalCount(response.data.totalCount)
      })
      .catch((e) => {
        handleError(e.response.status)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [searchParams, paginateParams])

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.INDEX ?? APPLICATION_NAME}</title>
      </Helmet>
      {loading ? <CircularProgressBox /> : <EducationsIndex />}
    </PageWrapper>
  )
}

export default Index
