import * as React from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import { styled, useTheme } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import { ADMIN_MENUS } from 'commons/menus'
import { toastOnError, toastOnSuccess } from 'commons/toaster'
import { getDriver, unlockDriver } from 'components/apis/drivers'
import { FlexBox } from 'components/atoms/BoxComponents'
import { EditButton } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import AttendanceHistoryModal from 'components/organisms/admin/drivers/AttendanceHistoryModal'
import DriverDetail from 'components/organisms/admin/drivers/show/Detail'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import SidebarAnchorList from 'components/organisms/layouts/SidebarAnchorList'
import { UserContext } from 'providers/UserProvider'

function AdminDriversShow() {
  const { id } = useParams()
  const navigate = useNavigate()
  const user = React.useContext(UserContext)
  const handleError = useErrorHandler()
  const [showAttendanceHistoryModal, setShowAttendanceHistoryModal] =
    React.useState(false)

  const {
    data: driver,
    isLoading,
    refetch,
  } = useQuery(
    [`admin/drivers/${id}`, 'driver'],
    () => getDriver(parseInt(id)).then((res) => res.data),
    {
      onError: (e: AxiosError) => handleError(e.response.status),
      refetchOnWindowFocus: false,
    }
  )

  const theme = useTheme()

  const Title = styled('h1')({
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '16px',
    },
  })

  const InvisibleForMobile = styled('div')({
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  })

  const onClickUnlock = (id: number) => {
    unlockDriver(id)
      .then(() => {
        refetch()
        toastOnSuccess('ロックを解除しました。')
      })
      .catch(() => {
        toastOnError('ロックの解除に失敗しました。')
      })
  }

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.DRIVERS.SHOW ?? APPLICATION_NAME}</title>
      </Helmet>
      <FlexBox
        justifyContent={'space-between'}
        sx={{
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
          alignItems: 'center',
        }}
      >
        <Title>{ADMIN_MENUS.masters.children.drivers.title}</Title>
        <FlexBox
          gap={2}
          sx={{
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
            [theme.breakpoints.up('sm')]: {
              marginRight: '16px',
            },
            alignItems: 'center',
          }}
        >
          {!isLoading && user.admin && driver.isLocked && (
            <ApplyButton onClick={() => onClickUnlock(driver.id)}>
              ロック解除
            </ApplyButton>
          )}
          {!isLoading && user.admin && (
            <InvisibleForMobile>
              <ApplyButton onClick={() => setShowAttendanceHistoryModal(true)}>
                受講履歴出力
              </ApplyButton>
            </InvisibleForMobile>
          )}
          {!isLoading && user.admin && (
            <InvisibleForMobile>
              <ApplyButton
                onClick={() =>
                  window.open(
                    `/api/v1/drivers/ledgers/export?&ids[]=${driver.id}`,
                    '_blank'
                  )
                }
              >
                台帳出力
              </ApplyButton>
            </InvisibleForMobile>
          )}
          <EditButton
            onClick={() => {
              navigate('edit/')
            }}
          >
            {/* TODO: 項目ごとに編集するボタンを設置するタイミングで、挙動を区別するために「まとめて編集する」などに文言を変更 */}
            編集する
          </EditButton>
        </FlexBox>
      </FlexBox>
      {isLoading ? (
        <CircularProgressBox />
      ) : (
        <FlexBox>
          <SidebarAnchorList page="drivers" />
          <DriverDetail data={driver} />
        </FlexBox>
      )}
      <AttendanceHistoryModal
        educationId={id}
        showModal={showAttendanceHistoryModal}
        setShowModal={setShowAttendanceHistoryModal}
      />
    </PageWrapper>
  )
}

export default AdminDriversShow
