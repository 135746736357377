import { useQuery } from '@tanstack/react-query'

export const useEducation = (id) => {
  const { data, error } = useQuery(['educations', id], async () => {
    const res = await fetch(`/api/v1/educations/${id}`)
    return res.json()
  })

  return {
    education: data,
    isLoading: !error && !data,
    isError: error,
  }
}
