import * as React from 'react'
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  Controller,
} from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { AddButtonOutlined } from 'components/atoms/ButtonComponenets'
import { Box } from '@mui/material'
import AptitudeTestRecordCard from 'components/organisms/admin/drivers/form_sections/aptitude_test_records/AptitudeTestRecordCard'
import { APTITUDE_TEST_RECORDS, DRIVERS } from 'commons/constants'
import {
  CustomDropZone,
  CustomLabel,
  HeadingText,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

type AptitudeTestRecordsProps = {
  register: UseFormRegister<Driver>
  setValue: UseFormSetValue<Driver>
  watch: UseFormWatch<Driver>
  control: Control<Driver>
  errors: FieldErrors<Driver>
  aptitudeTestRecordsFiles: File[]
  setAptitudeTestRecordsFiles: React.Dispatch<React.SetStateAction<File[]>>
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>
}

const AptitudeTestRecords: React.FC<AptitudeTestRecordsProps> = (props) => {
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: 'aptitudeTestRecords',
  })

  // 画像設定時のコールバック
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      props.setAptitudeTestRecordsFiles([
        ...props.aptitudeTestRecordsFiles,
        ...acceptedFiles,
      ])
    },
    [props.aptitudeTestRecordsFiles]
  )

  const appendEmptyItem = () => {
    const emptyItem = {
      aptitudeType: '',
      consultationYear: undefined,
      consultationMonth: undefined,
      consultationDay: undefined,
      institutionName: '',
      result: '',
      files: [],
    }
    append(emptyItem, { shouldFocus: false })
  }

  const deleteItem = (targetIndex: number) => {
    remove(targetIndex)
  }

  return (
    <BackGroundWhite>
      <HeadingText>{DRIVERS.LABELS.APTITUDE_TEST}</HeadingText>
      {fields.map((_, i) => {
        return (
          <Box mb={2} key={`aptitude-test-card-${i}`}>
            <AptitudeTestRecordCard
              register={props.register}
              errors={props.errors}
              control={props.control}
              appendEmptyItem={appendEmptyItem}
              deleteItem={deleteItem}
              watch={props.watch}
              setValue={props.setValue}
              index={i}
              onInputKeyDown={props.onInputKeyDown}
            ></AptitudeTestRecordCard>
          </Box>
        )
      })}
      <AddButtonOutlined onClick={appendEmptyItem}>
        {DRIVERS.LABELS.APTITUDE_TEST}を追加
      </AddButtonOutlined>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {DRIVERS.LABELS.FILE_UPLOAD}(適性診断の
        {DRIVERS.LABELS.FILE_UPLOAD_DESCRIPTION_PARENT})
        <Controller
          render={() => (
            <CustomDropZone
              multiple={true}
              onDrop={onDrop}
              accept={'image/*, application/pdf'}
            />
          )}
          name={`aptitudeTestRecordsFiles`}
          control={props.control}
        />
        {Object.values(props?.aptitudeTestRecordsFiles)?.map((image, index) => (
          <FlexBox flexDirection={'row'} key={index}>
            <p>
              <a
                href={URL.createObjectURL(image)}
                download={decodeURI(image.name)}
              >
                {decodeURI(image.name)}
              </a>
            </p>
            <IconButton
              component="span"
              onClick={() =>
                props.setAptitudeTestRecordsFiles(
                  props.aptitudeTestRecordsFiles.filter((_, i) => i !== index)
                )
              }
            >
              <CancelIcon />
            </IconButton>
          </FlexBox>
        ))}
      </CustomLabel>
    </BackGroundWhite>
  )
}

export default AptitudeTestRecords
