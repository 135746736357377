import * as React from 'react'
import { MENUS } from 'commons/menus'
import EducationAttendanceForm from 'components/organisms/education_attendances/Form'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { getEducations } from 'components/apis/educations'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'

function AdminEducationAttendancesNew() {
  const { id } = useParams()

  const { data: education, isLoading } = useQuery(
    ['admin/education_attendances/new', 'educations'],
    () => getEducations(parseInt(id)).then((res) => res.data)
  )

  return (
    <PageWrapper>
      <Helmet>
        <title>
          {PAGE_TITLES.EDUCATIONS.ATTENDANCES.NEW ?? APPLICATION_NAME}
        </title>
      </Helmet>
      <h1>{MENUS.educations.children.education_attendances.title}</h1>
      {isLoading ? (
        <CircularProgressBox />
      ) : (
        <EducationAttendanceForm
          type="create"
          questionCount={education.questionCount}
        />
      )}
    </PageWrapper>
  )
}

export default AdminEducationAttendancesNew
