export const VEHICLE_BODY_TYPE = [
  { label: '平ボディ', value: 'flat' },
  { label: '箱車', value: 'box' },
  { label: '幌車', value: 'hood' },
  { label: 'キャブオーバ', value: 'cab_over' },
  { label: 'トラクタ(トレーラーヘッド)', value: 'tractor' },
  { label: 'ダンプ', value: 'dump' },
  { label: 'バン', value: 'van' },
  { label: 'セミトレーラー', value: 'semi_trailer' },
  { label: 'フルトレーラー', value: 'full_trailer' },
  { label: '冷蔵冷凍車', value: 'refrigerator' },
  { label: 'クレーン車', value: 'crane' },
  { label: 'レッカー車', value: 'towing' },
  { label: 'ミキサー車', value: 'mixer' },
  { label: 'タンク車', value: 'tank' },
  { label: 'パッカー車', value: 'packer' },
  { label: '指定なし', value: 'other' },
]
