import * as React from 'react'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { Box } from '@mui/material'
import { ADMIN_ROUTES, TABLE_DEFAULT_ROWS } from 'commons/constants'
import { ADMIN_MENUS } from 'commons/menus'
import { AddButton } from 'components/atoms/ButtonComponenets'
import { UserContext } from 'providers/UserProvider'
import { useErrorHandler } from 'react-error-boundary'
import ArticleMaintenancesTable from 'components/organisms/articles/maintenances/Table'
import { useLocation } from 'react-router-dom'
import useMaintenanceArticles from './hooks/useMaintenanceArticles'

function ArticleMaintenancesIndex() {
  const title = 'お知らせの新規作成'
  const user = React.useContext(UserContext)
  const defaultSearchParams = {}
  const [searchParams, setSearchParams] =
    React.useState<SearchMaintenanceArticle>(defaultSearchParams)
  const [paginateParams, setPaginateParams] = React.useState<PaginateParams>({
    pageNumber: 1,
    displayLimit: TABLE_DEFAULT_ROWS,
  })
  const queryParams = useLocation().search
  const focusId = new URLSearchParams(queryParams).get('focus_id')

  const { maintenanceArticlesData, isLoadingMaintenanceArticlesData } =
    useMaintenanceArticles(searchParams, paginateParams)

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
        }}
      >
        <h1>{ADMIN_MENUS.articles.children.article_maintenances.title}</h1>
        {user.admin && (
          <AddButton href={ADMIN_ROUTES.ARTICLES_MAINTENANCES_NEW}>
            {title}
          </AddButton>
        )}
      </Box>
      <ArticleMaintenancesTable
        data={maintenanceArticlesData?.articles}
        totalCount={maintenanceArticlesData?.totalCount}
        setSearchParams={setSearchParams}
        paginateParams={paginateParams}
        setPaginateParams={setPaginateParams}
        defaultSearchParams={defaultSearchParams}
        focusId={parseInt(focusId)}
        isLoading={isLoadingMaintenanceArticlesData}
      />
    </PageWrapper>
  )
}

export default ArticleMaintenancesIndex
