import * as React from 'react'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomCheckBoxForm,
  HeadingText,
} from 'components/atoms/FormComponents'
import { FlexBoxRow } from 'components/atoms/BoxComponents'
import { styled } from '@mui/styles'
import { Controller } from 'react-hook-form'
import { LICENSE_TYPE } from 'commons/licenseType'
import { VEHICLES } from 'commons/constants'

export default function License(props: { register; control; errors; type }) {
  const { control } = props

  const CustomCheckBoxFormContain = styled('div')({
    marginTop: '10px',
  })

  return (
    <BackGroundWhite>
      <HeadingText>{VEHICLES.LABELS.LICENSE}（複数選択可能）</HeadingText>
      <CustomLabel>
        <FlexBoxRow flexWrap="wrap">
          {LICENSE_TYPE.map((licenseType, index) => (
            <Controller
              key={index}
              name={`license${
                licenseType.value.charAt(0).toUpperCase() +
                licenseType.value.slice(1)
              }`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomCheckBoxFormContain>
                  <CustomCheckBoxForm>
                    <label>
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={() => onChange(!value)}
                      />
                      {licenseType.label}
                    </label>
                  </CustomCheckBoxForm>
                </CustomCheckBoxFormContain>
              )}
            />
          ))}
        </FlexBoxRow>
      </CustomLabel>
    </BackGroundWhite>
  )
}
