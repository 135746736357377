import * as React from 'react'
import {
  CustomDateSelect,
  CustomInput,
  CustomLabel,
  CustomSelect,
  ErrorMessage,
  CustomDropZone,
  WithRequiredBadge,
} from 'components/atoms/FormComponents'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import {
  DIRECTION_RECORDS,
  DRIVERS,
  VALIDATION_MESSAGES,
} from 'commons/constants'
import { BackGroundPalePink } from 'components/atoms/BackGroundComponents'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { styled } from '@mui/styles'
import { formatCustomDateErrorMessage } from 'commons/errorMessage'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { FlexBox } from 'components/atoms/BoxComponents'

type DirectionRecordCardProps = {
  register: UseFormRegister<Driver>
  setValue: UseFormSetValue<Driver>
  watch: UseFormWatch<Driver>
  control: Control<Driver>
  errors: FieldErrors<Driver>
  appendEmptyItem: () => void
  deleteItem: (index: number) => void
  index: number
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>
}

const DirectionRecordCard: React.FC<DirectionRecordCardProps> = (props) => {
  const { control, setValue, watch, index, errors } = props
  const customDateErrorMessage = React.useCallback(() => {
    return formatCustomDateErrorMessage({
      year: errors?.directionRecords?.[index]?.consultationYear,
      month: errors?.directionRecords?.[index]?.consultationMonth,
      day: errors?.directionRecords?.[index]?.consultationDay,
    })
  }, [errors?.directionRecords?.[index]])

  const CardTitle = styled('p')({
    fontWeight: 'bold',
  })

  const driverTypeOptions = [
    { value: 'initial', label: '初任' },
    { value: 'older', label: '高齢' },
    { value: 'specific', label: '特別' },
  ]

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const oldFiles = watch(`directionRecords.${index}.files`)
        ? watch(`directionRecords.${index}.files`)
        : []
      setValue(`directionRecords.${index}.files`, [
        ...oldFiles,
        ...acceptedFiles,
      ])
    },
    [watch(`directionRecords.${index}.files`)]
  )

  return (
    <BackGroundPalePink sx={{ marginBottom: '30px' }}>
      <CardTitle>
        {DRIVERS.LABELS.DIRECTION_RECORDS}
        {index + 1}
      </CardTitle>
      <CustomLabel
        sx={{
          marginBottom: '30px',
        }}
      >
        <WithRequiredBadge>
          {DIRECTION_RECORDS.LABELS.DRIVER_TYPE}
        </WithRequiredBadge>
        <Controller
          name={`directionRecords.${index}.driverType`}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <CustomSelect
              options={driverTypeOptions}
              value={driverTypeOptions?.find(
                (option) => option.value === value
              )}
              onChange={(option: ReactSelectOptionProps) =>
                onChange(option.value)
              }
              placeholder="未選択"
            />
          )}
        />
        {errors?.directionRecords?.[index]?.driverType && (
          <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
        )}
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {DIRECTION_RECORDS.LABELS.CONSULTATION_DATE}
        <CustomDateSelect
          formKeys={{
            year: `directionRecords.${index}.consultationYear`,
            month: `directionRecords.${index}.consultationMonth`,
            day: `directionRecords.${index}.consultationDay`,
          }}
          required
          control={control}
        />
        <ErrorMessage>{customDateErrorMessage()}</ErrorMessage>
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {DIRECTION_RECORDS.LABELS.DESCRIPTION}
        <CustomInput
          sx={{ marginTop: '4px', height: '38px' }}
          onKeyDown={props.onInputKeyDown}
          {...props.register(`directionRecords.${props.index}.description`)}
        />
      </CustomLabel>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {DRIVERS.LABELS.FILE_UPLOAD}(特別指導の
        {DRIVERS.LABELS.FILE_UPLOAD_DESCRIPTION_CHILD})
        <Controller
          render={() => (
            <CustomDropZone
              multiple={true}
              onDrop={onDrop}
              accept={'image/*, application/pdf'}
            />
          )}
          name={`directionRecords.${index}.fileUrls`}
          control={control}
        />
        {watch(`directionRecords.${index}.files`) &&
          Object.values(watch(`directionRecords.${index}.files`))?.map(
            (image: File, fileIndex: number) => (
              <FlexBox flexDirection={'row'} key={fileIndex}>
                <p>
                  <a
                    href={URL.createObjectURL(image)}
                    download={decodeURI(image.name)}
                  >
                    {decodeURI(image.name)}
                  </a>
                </p>
                <IconButton
                  component="span"
                  onClick={() =>
                    setValue(
                      `directionRecords.${index}.files`,
                      watch(`directionRecords.${index}.files`).filter(
                        (_, i) => i !== fileIndex
                      )
                    )
                  }
                >
                  <CancelIcon />
                </IconButton>
              </FlexBox>
            )
          )}
      </CustomLabel>
      <DeleteButton onClick={() => props.deleteItem(props.index)}>
        削除
      </DeleteButton>
    </BackGroundPalePink>
  )
}

export default DirectionRecordCard
