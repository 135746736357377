import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const SalesIcon = () => {
  return (
    <SvgIcon>
      <path
        d="M20 4H4V20H20V4ZM9.33333 16.4444H7.55556V10.2222H9.33333V16.4444ZM12.8889 16.4444H11.1111V7.55556H12.8889V16.4444ZM16.4444 16.4444H14.6667V12.8889H16.4444V16.4444Z"
        fill="#003333"
      />
    </SvgIcon>
  )
}
