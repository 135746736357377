import * as React from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material'
import { Controller, useFieldArray } from 'react-hook-form'
import {
  BackGroundWhite,
  BackGroundGray,
} from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomInput,
  CustomInputShort,
  HeadingText,
  UnitText,
  CustomSelectShort,
} from 'components/atoms/FormComponents'
import { AddButton, DeleteButton } from 'components/atoms/ButtonComponenets'
import { FlexBox } from 'components/atoms/BoxComponents'

const ExperienceInput = styled(CustomInput)({
  width: '80px',
})

function DrivingExperienceForm(props: {
  register
  control
  DrivingExperienceIndex: number
}) {
  const { register, control, DrivingExperienceIndex } = props
  const vehicleTypes = [
    { value: 'cargo', label: '貨物' },
    { value: 'passenger', label: '乗用' },
    { value: 'bus', label: 'バス' },
  ]

  const theme = useTheme()

  return (
    <React.Fragment>
      <CustomLabel>
        自動車の種類
        <Controller
          name={`drivingExperiences[${DrivingExperienceIndex}].vehicleType`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomSelectShort
              options={vehicleTypes}
              value={vehicleTypes.find((c) => c.value === value)}
              onChange={(option: ReactSelectOptionProps) =>
                onChange(option.value)
              }
              placeholder="未選択"
            />
          )}
        />
      </CustomLabel>

      <FlexBox
        flexWrap="wrap"
        sx={{
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        <CustomLabel>
          定員
          <FlexBox flexWrap="wrap">
            <CustomInputShort
              {...register(
                `drivingExperiences[${DrivingExperienceIndex}].capacity`
              )}
            />
            <UnitText>人</UnitText>
          </FlexBox>
        </CustomLabel>

        <CustomLabel>
          積載量
          <FlexBox flexWrap="wrap">
            <CustomInputShort
              {...register(
                `drivingExperiences[${DrivingExperienceIndex}].loadCapacity`
              )}
            />
            <UnitText>t</UnitText>
          </FlexBox>
        </CustomLabel>
      </FlexBox>

      <CustomLabel>
        経験年数
        <FlexBox
          flexWrap="wrap"
          sx={{
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <FlexBox>
            <ExperienceInput
              {...register(
                `drivingExperiences[${DrivingExperienceIndex}].experienceYear`
              )}
            />
            <UnitText>年</UnitText>
          </FlexBox>
          <FlexBox>
            <ExperienceInput
              {...register(
                `drivingExperiences[${DrivingExperienceIndex}].experienceMonth`
              )}
            />
            <UnitText>ヶ月</UnitText>
          </FlexBox>
        </FlexBox>
      </CustomLabel>

      <CustomLabel>
        経験した事業所の名称
        <CustomInput
          {...register(
            `drivingExperiences[${DrivingExperienceIndex}].officeName`
          )}
        />
      </CustomLabel>
    </React.Fragment>
  )
}

export default function DriversDrivingExperience(props: { register; control }) {
  const { register, control } = props
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: 'drivingExperiences',
  })
  const initDrivingExperience = {
    vehicleType: null,
    capacity: null,
    loadCapacity: null,
    experienceYear: null,
    experienceMonth: null,
    officeName: null,
  }

  const deleteDrivingExperience = (index) => {
    remove(index)
  }

  const addDrivingExperience = () => {
    append(initDrivingExperience)
  }

  return (
    <BackGroundWhite>
      <HeadingText>運転経験</HeadingText>
      {fields.map((_, index) => (
        <React.Fragment key={index}>
          <BackGroundGray>
            運転経験{index + 1}
            <DrivingExperienceForm
              register={register}
              control={control}
              DrivingExperienceIndex={index}
            />
            <DeleteButton onClick={() => deleteDrivingExperience(index)}>
              削除
            </DeleteButton>
          </BackGroundGray>
        </React.Fragment>
      ))}
      <AddButton
        variant="text"
        onClick={() => addDrivingExperience()}
        sx={{
          background: 'none',
          '&:hover': { background: 'none' },
          color: '#003333',
          border: '1px solid #003333',
        }}
      >
        運転経験を追加
      </AddButton>
    </BackGroundWhite>
  )
}
