import {
  LOCAL_STORAGE_DISPLAY_LIMIT_KEY,
  LOCAL_STORAGE_PAGE_NUMBER_KEY,
  LOCAL_STORAGE_ORDER_BY_KEY,
  LOCAL_STORAGE_ORDER_KEY,
  LOCAL_STORAGE_SEARCH_PARAMS_KEY,
  LOCAL_STORAGE_VISIBLE_COLUMNS_KEY,
  TABLE_DEFAULT_ROWS,
} from './constants'
import { Order, sortBy } from './array'

/**
 *  localStorageからdisplayLimitを取得
 */
export const getLocalStorageDisplayLimit = () => {
  const localStorageData = localStorage.getItem(LOCAL_STORAGE_DISPLAY_LIMIT_KEY)
  return localStorageData ? parseInt(localStorageData) : TABLE_DEFAULT_ROWS
}

/**
 *  localStorage上のdisplayLimitを更新
 */
export const setLocalStorageDisplayLimit = (count) => {
  localStorage.setItem(LOCAL_STORAGE_DISPLAY_LIMIT_KEY, String(count))
}

/**
 *  localStorageからpageNumberを取得
 */
export const getLocalStoragePageNumber = (
  localStoragePageKey: string,
  isNewVersion?: boolean
) => {
  const pageNumberKey = [
    localStoragePageKey,
    LOCAL_STORAGE_PAGE_NUMBER_KEY,
  ].join('_')
  const localStorageData = localStorage.getItem(pageNumberKey)
  // muiのTablePaginationとPaginationのスタートページ番号が異なるため、新バージョンの場合はページ番号を1から始める
  const defaultPageNumber = isNewVersion ? 1 : 0

  return localStorageData ? parseInt(localStorageData) : defaultPageNumber
}

/**
 *  localStorage上のpageNumberを更新
 */
export const setLocalStoragePageNumber = (
  localStoragePageKey: string,
  count
) => {
  const pageNumberKey = [
    localStoragePageKey,
    LOCAL_STORAGE_PAGE_NUMBER_KEY,
  ].join('_')
  localStorage.setItem(pageNumberKey, String(count))
}

export const removeLocalStoragePageNumber = (localStoragePageKey: string) => {
  const pageNumberKey = [
    localStoragePageKey,
    LOCAL_STORAGE_PAGE_NUMBER_KEY,
  ].join('_')
  localStorage.removeItem(pageNumberKey)
}

/**
 *  localStorageからpageKeyに基づくsearchParamsを取得
 */
export const getLocalStorageSearchParams = (localStoragePageKey: string) => {
  try {
    const localStorageData = JSON.parse(
      localStorage.getItem(localStoragePageKey)
    )
    return localStorageData[LOCAL_STORAGE_SEARCH_PARAMS_KEY]
  } catch {
    return null
  }
}

/**
 * localStorageのsearchParamsを更新
 * 既存の値があればそれを上書きする
 */
export const setLocalStorageSearchParams = (
  localStoragePageKey: string,
  data
) => {
  if (!localStoragePageKey) return
  const localStorageData = localStorage.getItem(localStoragePageKey)
  const obj = localStorageData ? JSON.parse(localStorageData) : {}
  obj[LOCAL_STORAGE_SEARCH_PARAMS_KEY] = data
  localStorage.setItem(localStoragePageKey, JSON.stringify(obj))
}

/**
 *  localStorageからpageKeyに基づくorder/orderByを取得
 */
export const getLocalStorageOrder = (localStoragePageKey: string) => {
  try {
    const localStorageData = JSON.parse(
      localStorage.getItem(localStoragePageKey)
    )
    return {
      [LOCAL_STORAGE_ORDER_KEY]: localStorageData[LOCAL_STORAGE_ORDER_KEY],
      [LOCAL_STORAGE_ORDER_BY_KEY]:
        localStorageData[LOCAL_STORAGE_ORDER_BY_KEY],
    }
  } catch {
    return {
      [LOCAL_STORAGE_ORDER_KEY]: null,
      [LOCAL_STORAGE_ORDER_BY_KEY]: null,
    }
  }
}

/**
 * localStorageのorderを更新
 * 既存の値があればそれを上書きする
 */
export const setLocalStorageOrder = (
  localStoragePageKey: string,
  order: string,
  orderBy
) => {
  if (!localStoragePageKey) return
  const localStorageData = localStorage.getItem(localStoragePageKey)
  const obj = localStorageData ? JSON.parse(localStorageData) : {}
  obj[LOCAL_STORAGE_ORDER_KEY] = order
  obj[LOCAL_STORAGE_ORDER_BY_KEY] = orderBy
  localStorage.setItem(localStoragePageKey, JSON.stringify(obj))
}

export const isOrder = (arg): arg is Order => {
  return arg === 'asc' || arg === 'desc'
}

/**
 * localStorageの検索/ソート条件からデータを並び替えた場合に、指定したidを持つデータの次のデータのidを返す
 */
export const getNextResourceId = <T>(
  localStoragePageKey: string,
  id: number,
  resourcesPerDisplayLimit: any[][]
): number | null => {
  const { order, orderBy } = getLocalStorageOrder(localStoragePageKey)

  let nextId: number | null = null
  // sort条件に従って並び替え、次のデータのidを取得
  for (const resources of resourcesPerDisplayLimit) {
    const current = resources.find((d) => d.id === id)
    if (current) {
      const sorted = sortBy<T>(resources, order, orderBy)
      const currentIndex = sorted.indexOf(current)
      const next = sorted[currentIndex + 1]
      if (!next) {
        return null
      }
      nextId = next['id']
      break
    }
  }
  return nextId
}

/**
 *  localStorageからpageKeyに基づくVisibleColumnsを取得
 */
export const getLocalStorageVisibleColumns = (localStoragePageKey: string) => {
  try {
    const localStorageData = JSON.parse(
      localStorage.getItem(localStoragePageKey)
    )
    return localStorageData[LOCAL_STORAGE_VISIBLE_COLUMNS_KEY]
  } catch {
    return null
  }
}

/**
 * localStorageのVisibleColumnsを更新
 * 既存の値があればそれを上書きする
 */
export const setLocalStorageVisibleColumns = (
  localStoragePageKey: string,
  data
) => {
  if (!localStoragePageKey) return
  const localStorageData = localStorage.getItem(localStoragePageKey)
  const obj = localStorageData ? JSON.parse(localStorageData) : {}
  obj[LOCAL_STORAGE_VISIBLE_COLUMNS_KEY] = data
  localStorage.setItem(localStoragePageKey, JSON.stringify(obj))
}
