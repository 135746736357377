import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BackGroundGray } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomForm,
  ResetButton,
  CustomSelect,
  WithEmptyOption,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Box, Typography } from '@mui/material'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { LOCAL_STORAGE_PAGE_KEY } from 'commons/constants'
import { yearRangeFrom2000 } from 'commons/date'
import SearchCompanyAndOffice from 'components/atoms/ReactHookFormPartials/SearchForm/SearchCompanyAndOffice'
import {
  getLocalStorageSearchParams,
  setLocalStorageSearchParams,
  getLocalStorageDisplayLimit,
  removeLocalStoragePageNumber,
} from 'commons/table'

interface Props {
  setSearchParams: React.Dispatch<React.SetStateAction<SearchEducationVideoLog>>
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  defaultSearchParams: SearchEducationVideoLog
  isLoading?: boolean
}

function SearchForm(props: Props) {
  const {
    setSearchParams,
    setPaginateParams,
    setPage,
    defaultSearchParams,
    isLoading = false,
  } = props
  React.useEffect(() => {
    const searchParams = getLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_VIDEO_LOGS
    )
    if (searchParams) {
      // リクエストで送るparamsのstate
      setSearchParams(searchParams)
      // formのvalue
      Object.keys(searchParams).forEach(
        (key: keyof SearchEducationVideoLog) => {
          setValue(key, searchParams[key])
        }
      )
    }
  }, [LOCAL_STORAGE_PAGE_KEY.EDUCATION_VIDEO_LOGS])

  // 視聴状況の選択肢
  const watchStatusSelectOptions: SelectOption[] = [
    { value: 'completed', label: '完了' },
    { value: 'not_completed', label: '未完了' },
  ]

  // フォームオブジェクト
  const { handleSubmit, control, reset, getValues, setValue, watch } =
    useForm<SearchEducationVideoLog>({
      mode: 'onSubmit',
      defaultValues: defaultSearchParams,
    })

  // フォームsubmit時のコールバック
  const onSubmit = (data: SearchEducationVideoLog) => {
    setSearchParams(data)

    setLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_VIDEO_LOGS,
      data
    )
  }

  const resetParams = () => {
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = 1

    setPaginateParams({ pageNumber, displayLimit: displayLimit })
    setPage(pageNumber)
    removeLocalStoragePageNumber(LOCAL_STORAGE_PAGE_KEY.EDUCATION_VIDEO_LOGS)
    setSearchParams(defaultSearchParams)
    setLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_VIDEO_LOGS,
      defaultSearchParams
    )
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '90%' }}>
        <BackGroundGray>
          <Typography
            sx={{ marginBottom: '20px', fontWeight: 'bold' }}
            variant={'body1'}
          >
            条件で絞り込み
          </Typography>
          <CustomForm onSubmit={handleSubmit(onSubmit)}>
            <FlexBox>
              <SearchCompanyAndOffice
                companyKeyName={'companyId'}
                officeKeyName={'officeId'}
                control={control}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
              />
            </FlexBox>
            <FlexBox flexWrap="wrap">
              <CustomLabel>
                <FlexBox>受講年度</FlexBox>
                <Controller
                  name="attendedYear"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      options={yearRangeFrom2000}
                      value={yearRangeFrom2000?.find((c) => c.value === value)}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option.value)
                      }
                      placeholder=""
                    />
                  )}
                />
              </CustomLabel>

              <CustomLabel>
                視聴状況
                <Controller
                  name="watchStatus"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      options={WithEmptyOption(watchStatusSelectOptions)}
                      value={watchStatusSelectOptions?.find(
                        (c) => c.value === value
                      )}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option.value)
                      }
                      placeholder="全て"
                    />
                  )}
                />
              </CustomLabel>
            </FlexBox>

            <FlexBox flexWrap="wrap">
              <ApplyButton pattern="inRow" type="submit" disabled={isLoading}>
                絞り込む
              </ApplyButton>
              <ResetButton
                sx={{ marginRight: '1rem', marginBottom: '8px' }}
                onClick={() => {
                  reset()
                  resetParams()
                }}
                disabled={isLoading}
              >
                <Typography>リセット</Typography>
              </ResetButton>
            </FlexBox>
          </CustomForm>
        </BackGroundGray>
      </Box>
    </Box>
  )
}

export default SearchForm
