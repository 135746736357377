export const slackNotification = (error: any, user: User) => {
  if (error?.response?.status == 422) {
    // チャネルのslack_url
    const url = `https://hooks.slack.com/services/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`

    let postData = []

    // FormDataだった場合、jsonにパースする。
    if (error?.response?.config?.data instanceof FormData) {
      error.response.config.data.forEach((value, key) => {
        postData.push({ [key]: value })
      })
    } else {
      postData = error?.response?.config?.data
        ? JSON.parse(error.response.config.data)
        : []
    }

    const errorInfo = {
      errorCode: error?.response?.status ?? `undefined`,
      errorDetail: error?.response?.data?.error ?? `undefined`,
      methodName: error?.response.config?.method ?? `undefined`,
      endpoint: error?.response?.config?.url ?? `undefined`,
      userName: user?.fullName ?? `undefined`,
      companyName: user?.company?.name ?? `undefined`,
      postData: postData,
    }

    const payload = {
      text: `\`エラーコード\`:${errorInfo.errorCode}\n\`エラー内容\`:${
        errorInfo.errorDetail
      }
            \n\`メソッド名\`:${errorInfo.methodName}\n\`エンドポイント\`:${
        errorInfo.endpoint
      }
            \n\`ユーザー名\`:${errorInfo.userName}\n\`会社名\`:${
        errorInfo.companyName
      }
            \n\`postデータ\`:${JSON.stringify(errorInfo.postData, null, 2)}`,
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(payload),
    })
  }
}
