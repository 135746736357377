import * as React from 'react'
import Modal from 'react-modal'

type Props = {
  children?: React.ReactNode
  isModalOpen: boolean
  customClose?: () => boolean
  customOpen?: () => void
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  style?: React.CSSProperties
  shouldCloseOnOverlayClick?: boolean
}

export default function CustomCenteringModal({
  children,
  isModalOpen,
  setIsModalOpen,
  customClose = () => true,
  customOpen,
  style,
  shouldCloseOnOverlayClick = true,
}: Props) {
  function closeModal() {
    if (customClose()) {
      setIsModalOpen(false)
    }
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onAfterOpen={customOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={{
        overlay: {
          zIndex: 1000,
        },
        content: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          maxHeight: '88vh',
          ...style,
        },
      }}
    >
      {children}
    </Modal>
  )
}
