import * as React from 'react'
import { useWatch, Control } from 'react-hook-form'
import { textLengthWithoutNewline } from 'commons/functions'

export function TextLength(props: { control: Control<any>; name: string }) {
  const text = useWatch({
    control: props.control,
    name: props.name,
  })
  return (
    <>
      <span>文字数: {textLengthWithoutNewline(text)}文字</span>
    </>
  )
}
