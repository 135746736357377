import * as React from 'react'
import { APPLICATION_NAME, PAGE_TITLES, API_ENDPOINTS } from 'commons/constants'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { Helmet } from 'react-helmet-async'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'

function ExecutivesFundContactsIndex() {
  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.FUND_CONTACTS.INDEX ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>問い合わせ履歴</h1>
      <BackGroundWhite>
        <ApplyButton
          onClick={() =>
            window.open(API_ENDPOINTS.FUND_CONTACTS_CSV_EXPORT, '_blank')
          }
        >
          CSV出力
        </ApplyButton>
      </BackGroundWhite>
    </PageWrapper>
  )
}

export default ExecutivesFundContactsIndex
