import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BackGroundGray } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomForm,
  DatePicker,
  ResetButton,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Box, Typography } from '@mui/material'
import { toISOStringWithTimezone } from 'commons/functions'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { styles } from 'components/theme'
import SearchCompany from 'components/atoms/ReactHookFormPartials/SearchForm/SearchCompany'
import {
  getLocalStorageSearchParams,
  setLocalStorageSearchParams,
  getLocalStorageDisplayLimit,
  removeLocalStoragePageNumber,
} from 'commons/table'
import { LOCAL_STORAGE_PAGE_KEY } from 'commons/constants'

interface Props {
  setSearchParams: React.Dispatch<React.SetStateAction<SearchAlcoholCheck>>
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  defaultSearchParams: SearchAlcoholCheck
}

function SearchForm(props: Props) {
  const { setSearchParams, setPaginateParams, setPage, defaultSearchParams } =
    props
  React.useEffect(() => {
    const searchParams = getLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.ALCOHOL_CHECKS
    )
    if (searchParams) {
      // リクエストで送るparamsのstate
      setSearchParams(searchParams)
      // formのvalue
      Object.keys(searchParams).forEach((key: keyof SearchAlcoholCheck) => {
        setValue(key, searchParams[key])
      })
    }
  }, [])

  // フォームオブジェクト
  const { handleSubmit, control, reset, setValue } =
    useForm<SearchAlcoholCheck>({
      mode: 'onSubmit',
    })

  // フォームsubmit時のコールバック
  const onSubmit = (data: SearchAlcoholCheck) => {
    const params = {
      ...data,
      createdAtFrom: data.createdAtFrom
        ? toISOStringWithTimezone(data.createdAtFrom as Date).split('T')[0]
        : undefined,
      createdAtTo: data.createdAtTo
        ? toISOStringWithTimezone(data.createdAtTo as Date).split('T')[0]
        : undefined,
    }
    setSearchParams(params)
    setLocalStorageSearchParams(LOCAL_STORAGE_PAGE_KEY.ALCOHOL_CHECKS, data)
  }

  const resetParams = () => {
    const displayLimit = getLocalStorageDisplayLimit()
    setPaginateParams({ pageNumber: 1, displayLimit: displayLimit })
    setPage(0)
    removeLocalStoragePageNumber(LOCAL_STORAGE_PAGE_KEY.ALCOHOL_CHECKS)
    setSearchParams(defaultSearchParams)
    setLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.ALCOHOL_CHECKS,
      defaultSearchParams
    )
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '90%' }}>
        <BackGroundGray>
          <Typography
            sx={{ marginBottom: '20px', fontWeight: 'bold' }}
            variant={'body1'}
          >
            条件で絞り込み
          </Typography>
          <CustomForm onSubmit={handleSubmit(onSubmit)}>
            <FlexBox>
              <SearchCompany control={control} />
            </FlexBox>
            <FlexBox>
              <CustomLabel>
                期間
                <FlexBox>
                  <Controller
                    control={control}
                    name="createdAtFrom"
                    render={({ field: { onChange, value } }) => {
                      const date = value ? new Date(value) : null
                      return (
                        <DatePicker
                          onChange={onChange}
                          selected={date}
                          sx={{ width: `${styles.shortFormWidth}px` }}
                        />
                      )
                    }}
                  />
                  <Typography sx={{ margin: '0 1rem' }}>〜</Typography>
                  <Controller
                    control={control}
                    name="createdAtTo"
                    render={({ field: { onChange, value } }) => {
                      const date = value ? new Date(value) : null
                      return (
                        <DatePicker
                          onChange={onChange}
                          selected={date}
                          sx={{ width: `${styles.shortFormWidth}px` }}
                        />
                      )
                    }}
                  />
                </FlexBox>
              </CustomLabel>
            </FlexBox>

            <ApplyButton pattern="inRow" type="submit">
              絞り込む
            </ApplyButton>
            <ResetButton
              onClick={() => {
                reset()
                resetParams()
              }}
            >
              <Typography>リセット</Typography>
            </ResetButton>
          </CustomForm>
        </BackGroundGray>
      </Box>
    </Box>
  )
}

export default SearchForm
