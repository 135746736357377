import * as React from 'react'
import { UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { CustomTextArea } from 'components/atoms/FormComponents'
import { SxProps, Theme } from '@mui/material/styles'
import { toastOnError } from 'commons/toaster'

const defaultRows = 4
const maxCols = 25
const maxRows = 10

type EducationCommentTextAreaProps = {
  register: UseFormRegister<EducationComment>
  setValue: UseFormSetValue<EducationComment>
  name: 'driverComment' | 'adminComment'
  required?: boolean
  validate?: (value: string) => boolean
  disabled?: boolean
  rows?: number
  sx?: SxProps<Theme>
  placeholder?: string
}

export default function EducationCommentTextArea(
  props: EducationCommentTextAreaProps
) {
  const handleTextChange = (event) => {
    const originalText = event.target.value
    const lines = originalText.split('\n')

    if (lines.length > maxRows) {
      toastOnError('改行は10行までです。')
    }
    const formatedText = lines.slice(0, maxRows).join('\n')
    props.setValue(props.name, formatedText)
  }

  return (
    <CustomTextArea
      rows={props.rows || defaultRows}
      cols={maxCols}
      sx={{
        maxWidth: '368px',
        resize: 'none',
        fontSize: '14px',
        fontFamily: 'ui-monospace',
        ...props.sx,
      }}
      {...props.register(props.name, {
        required: props.required,
        validate: props.validate,
      })}
      disabled={props.disabled}
      onChange={handleTextChange}
      placeholder={props.placeholder}
    />
  )
}
