import * as React from 'react'
import { MENUS } from 'commons/menus'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { getNotification } from 'components/apis/notifications'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import NotificationDetail from 'components/organisms/notifications/Detail'
import { useParams, useNavigate } from 'react-router-dom'
import { useErrorHandler } from 'react-error-boundary'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import { Helmet } from 'react-helmet-async'
import { FlexBox } from 'components/atoms/BoxComponents'
import { EditButton } from 'components/atoms/ButtonComponenets'
import { useTheme } from '@mui/material'

function AdminNotificationShow() {
  const [notification, setNotification] = React.useState<Notification>(null)
  const [loading, setLoading] = React.useState(true)
  const { id } = useParams()
  const handleError = useErrorHandler()
  const theme = useTheme()
  const navigate = useNavigate()

  React.useEffect(() => {
    getNotification(parseInt(id))
      .then((response) => {
        setNotification(response.data)
      })
      .catch((e) => {
        handleError(e.response.status)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.NOTIFICATIONS.SHOW ?? APPLICATION_NAME}</title>
      </Helmet>
      <FlexBox
        justifyContent={'space-between'}
        sx={{
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
          alignItems: 'center',
        }}
      >
        <h1>{MENUS.notifications.title}</h1>
      </FlexBox>
      {loading ? (
        <CircularProgressBox />
      ) : (
        <NotificationDetail data={notification} />
      )}
    </PageWrapper>
  )
}

export default AdminNotificationShow
