import { styled } from '@mui/styles'
import { palette, theme } from 'components/theme'
import * as React from 'react'
import Modal from 'react-modal'

type FormSubmitModalProps = {
  isOpen: boolean
  resourceName: string
  text?: {
    main: string
    toShow: string
    toIndex: string
  }
  handlers: {
    cancel: () => void | Promise<void>
    toShow: () => void | Promise<void>
    toIndex: () => void | Promise<void>
  }
}

const StyledModal = styled(Modal)({
  marginLeft: 0,
  display: 'flex',
  flexFlow: 'wrap',
  flexDirection: 'column',
  justifyContents: 'center',
  alignItems: 'center',
  width: '400px',
  height: '320px',
  background: 'white',
  border: '1px solid rgb(204, 204, 204)',
  [theme.breakpoints.down('sm')]: { width: '300px', padding: '0 16px' },
})
const Inner = styled('div')({
  height: '100%',
  maxWidth: '300px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})
const Text = styled('p')({
  margin: '0 0 40px',
})
const Button = styled('button')({
  padding: '8px',
  width: '100px',
  cursor: 'pointer',
  borderRadius: '4px',
  marginBottom: '16px',
})
const CancelButton = styled(Button)({
  background: 'white',
  color: `${palette.primary.deep}`,
  border: `1px solid ${palette.primary.deep}`,
})
const ConfirmButton = styled(Button)({
  background: `${palette.primary.deep}`,
  color: 'white',
  border: `1px solid ${palette.primary.deep}`,
  width: '100%',
  maxWidth: '240px',
})

const FormSubmitSelectPageModal: React.FC<FormSubmitModalProps> = (props) => {
  const { isOpen, text, handlers } = props

  return (
    <StyledModal
      isOpen={isOpen}
      style={{
        overlay: {
          minHeight: '100%',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {},
      }}
    >
      <Inner>
        <Text>{text?.main ? text.main : '保存しますか?'}</Text>
        <ConfirmButton onClick={handlers.toIndex}>
          {text?.toIndex ? text.toIndex : '保存して一覧へ'}
        </ConfirmButton>
        <ConfirmButton onClick={handlers.toShow}>
          {text?.toShow ? text.toShow : '保存してデータを確認'}
        </ConfirmButton>
        <CancelButton onClick={handlers.cancel}>キャンセル</CancelButton>
      </Inner>
    </StyledModal>
  )
}

export default FormSubmitSelectPageModal
