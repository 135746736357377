import * as React from 'react'
import { useForm } from 'react-hook-form'
import { BackGroundGray } from 'components/atoms/BackGroundComponents'
import { CustomForm, ResetButton } from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Box, Typography } from '@mui/material'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import SearchCompanyAndOffice from 'components/atoms/ReactHookFormPartials/SearchForm/SearchCompanyAndOffice'
import {
  getLocalStorageSearchParams,
  setLocalStorageSearchParams,
  getLocalStorageDisplayLimit,
  removeLocalStoragePageNumber,
} from 'commons/table'
import { LOCAL_STORAGE_PAGE_KEY } from 'commons/constants'

interface Props {
  setSearchParams: React.Dispatch<
    React.SetStateAction<SearchEducationAnnualPlan>
  >
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  defaultSearchParams: SearchEducationAnnualPlan
  isLoading?: boolean
}

function SearchForm(props: Props) {
  const {
    setSearchParams,
    setPaginateParams,
    setPage,
    defaultSearchParams,
    isLoading = false,
  } = props

  React.useEffect(() => {
    const searchParams = getLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_ANNUAL_PLAN
    )
    if (searchParams) {
      // リクエストで送るparamsのstate
      setSearchParams(searchParams)
      // formのvalue
      Object.keys(searchParams).forEach(
        (key: keyof SearchEducationAnnualPlan) => {
          setValue(key, searchParams[key])
        }
      )
    }
  }, [])

  // フォームオブジェクト
  const { handleSubmit, reset, control, getValues, setValue, watch } =
    useForm<SearchEducationAnnualPlan>({
      mode: 'onSubmit',
    })

  // フォームsubmit時のコールバック
  const onSubmit = (data: SearchEducationAnnualPlan) => {
    setSearchParams(data)
    setLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_ANNUAL_PLAN,
      data
    )
  }

  const resetParams = () => {
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = 1

    setPaginateParams({ pageNumber, displayLimit: displayLimit })
    setPage(pageNumber)
    removeLocalStoragePageNumber(LOCAL_STORAGE_PAGE_KEY.EDUCATION_ANNUAL_PLAN)
    setSearchParams(defaultSearchParams)
    setLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_ANNUAL_PLAN,
      defaultSearchParams
    )
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '90%' }}>
        <BackGroundGray>
          <Typography
            sx={{ marginBottom: '20px', fontWeight: 'bold' }}
            variant={'body1'}
          >
            条件で絞り込み
          </Typography>
          <CustomForm onSubmit={handleSubmit(onSubmit)}>
            <FlexBox>
              <SearchCompanyAndOffice
                companyKeyName={'companyId'}
                officeKeyName={'officeId'}
                control={control}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
              />
            </FlexBox>
            <ApplyButton pattern="inRow" type="submit" disabled={isLoading}>
              絞り込む
            </ApplyButton>
            <ResetButton
              onClick={() => {
                reset()
                resetParams()
              }}
              disabled={isLoading}
            >
              <Typography>リセット</Typography>
            </ResetButton>
          </CustomForm>
        </BackGroundGray>
      </Box>
    </Box>
  )
}

export default SearchForm
