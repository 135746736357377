import axios from 'commons/axiosClient'
import { API_ENDPOINTS } from 'commons/constants'
import { camelCaseKeysToUnderscore } from 'commons/functions'

export function fetchEducationSetting() {
  return axios.get(API_ENDPOINTS.EDUCATION_SETTINGS)
}

export function updateEducationSetting(params) {
  return axios.post(
    API_ENDPOINTS.EDUCATION_SETTINGS_UPDATE_ATTRIBUTES,
    camelCaseKeysToUnderscore(params)
  )
}
