import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const TimecardIcon = () => {
  return (
    <SvgIcon viewBox="0 0 32 32">
      <path
        d="M26.668 7.99992H16.0013L13.3346 5.33325H5.33464C3.86797 5.33325 2.6813 6.53325 2.6813 7.99992L2.66797 23.9999C2.66797 25.4666 3.86797 26.6666 5.33464 26.6666H26.668C28.1346 26.6666 29.3346 25.4666 29.3346 23.9999V10.6666C29.3346 9.19992 28.1346 7.99992 26.668 7.99992ZM20.0013 11.9999C21.468 11.9999 22.668 13.1999 22.668 14.6666C22.668 16.1333 21.468 17.3333 20.0013 17.3333C18.5346 17.3333 17.3346 16.1333 17.3346 14.6666C17.3346 13.1999 18.5346 11.9999 20.0013 11.9999ZM25.3346 22.6666H14.668V21.3333C14.668 19.5599 18.228 18.6666 20.0013 18.6666C21.7746 18.6666 25.3346 19.5599 25.3346 21.3333V22.6666Z"
        fill="#003333"
      />
    </SvgIcon>
  )
}
