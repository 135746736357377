import * as React from 'react'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { Helmet } from 'react-helmet-async'
import FundContactsForm from 'components/organisms/executives/fund_contacts/Form'

function ExecutivesFundContactsNew() {
  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.FUND_CONTACTS.NEW ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>経営・資金相談問い合わせ</h1>
      <FundContactsForm />
    </PageWrapper>
  )
}

export default ExecutivesFundContactsNew
