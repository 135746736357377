import * as React from 'react'
import {
  ADMIN_ROUTES,
  ALERT_TEXT,
  APPLICATION_NAME,
  LOCAL_STORAGE_PAGE_KEY,
  PAGE_TITLES,
  TABLE_DEFAULT_ROWS,
} from 'commons/constants'
import { ADMIN_MENUS } from 'commons/menus'
import DriversTable from 'components/organisms/admin/drivers/Table'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { AddButton, ExportButton } from 'components/atoms/ButtonComponenets'
import { CustomCsvImport } from 'components/atoms/FormComponents'
import { Box } from '@mui/material'
import { FlexBox } from 'components/atoms/BoxComponents'
import {
  importDriver,
  fetchLockedDriversList,
  exportDriversCsv,
} from 'components/apis/drivers'
import { useErrorHandler } from 'react-error-boundary'
import { toastOnSuccess, toastOnErrorUseComponent } from 'commons/toaster'
import { UserContext } from 'providers/UserProvider'
import { styled } from '@mui/system'
import { Helmet } from 'react-helmet-async'
import { palette } from 'components/theme'
import { Link } from 'react-router-dom'
import ProcessingModal from 'components/atoms/ProcessingModal'
import { useLocation } from 'react-router-dom'
import useDrivers from './hooks/useDrivers'
import { useMemo } from 'react'
import { getLocalStorageSearchParams } from 'commons/table'

function AdminDriversIndex() {
  const title = '従業員の新規作成'
  const user = React.useContext(UserContext)

  const localStorageSearchParams = getLocalStorageSearchParams(
    LOCAL_STORAGE_PAGE_KEY.DRIVERS
  )
  const defaultSearchParams = { officeId: user.officeId }

  const [searchParams, setSearchParams] = React.useState<SearchDriver>(
    localStorageSearchParams ?? defaultSearchParams
  )
  const [paginateParams, setPaginateParams] = React.useState<PaginateParams>({
    pageNumber: 1,
    displayLimit: TABLE_DEFAULT_ROWS,
  })
  const [lockedDrivers, setLockedDrivers] = React.useState([])
  const handleError = useErrorHandler()
  const [isProcessingModalShow, setIsProcessingModalShow] =
    React.useState(false)
  const queryParams = useLocation().search
  const focusId = new URLSearchParams(queryParams).get('focus_id')

  const InvisibleForMobile = styled('div')({
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  })

  const { driversData, isLoadingDriversData } = useDrivers(
    searchParams,
    paginateParams
  )

  const resultDriverIds = useMemo(
    () => driversData?.resultDriverIds,
    [driversData?.resultDriverIds]
  )

  const driverLedgersExportUrl = () => {
    let url = `/api/v1/drivers/ledgers/export?`
    resultDriverIds?.map((value) => {
      url += `&ids[]=${value}`
    })
    return url
  }

  const ExportEmployeesUrl = () => {
    let url = `/api/v1/drivers/csv/export_employees?`
    resultDriverIds?.map((value) => {
      url += `&ids[]=${value}`
    })
    return url
  }

  const isSearchParamsEmpty = (searchParams: SearchDriver) => {
    // XMileシステム管理者、グループ親会社システム管理者以外のユーザーは検索条件に"会社"が表示されないため、会社以外の検索条件が未指定かどうかを判定する
    const searchParamsCopy =
      user.isXmileAdmin || user.isGroupParentCompanyAdmin
        ? searchParams
        : searchParamsRemovedCompanyId(searchParams)
    const selectedParams = Object.values(searchParamsCopy).filter(function (x) {
      return x !== '' && x !== undefined && x !== null
    })
    if (selectedParams.length == 0) {
      return true
    }
    return false
  }

  const searchParamsRemovedCompanyId = (searchParams: SearchDriver) => {
    const { companyId, ...searchParamsRemovedCompanyId } = searchParams
    return searchParamsRemovedCompanyId
  }

  const ToCsvSettingImport = () => (
    <div>
      CSVの紐付けが登録されていません。
      <br />
      <Link to="/admin/csv_settings/driver_import">
        マスタ管理→CSV設定→ヘッダーの設定
      </Link>
      から登録してください。
    </div>
  )

  const ShowCsvError = (error_data) => {
    return (
      <div>
        CSVのインポートに失敗しました。
        <br />
        {error_data.line && (
          <>
            {`CSVの${error_data.line}行目：`}
            <br />
          </>
        )}
        <ul>{errorList(error_data.errors)}</ul>
      </div>
    )
  }
  const errorList = (errors) => {
    return errors.map((error, index) => <li key={index}>{error}</li>)
  }

  const ToCsvSettingCautions = () => (
    <div>
      CSVのインポートに失敗しました。
      <br />
      お手数ですが
      <Link to="/admin/csv_settings/cautions/driver_import">
        インポートの注意事項
      </Link>
      をご確認した上で、再度実行してください。
    </div>
  )

  const onInputChange = (e) => {
    setIsProcessingModalShow(true)
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)

    importDriver(formData)
      .then(() => {
        toastOnSuccess('CSVインポート完了')
      })
      .catch((e) => {
        switch (e.response.status) {
          case 400:
            toastOnErrorUseComponent(ToCsvSettingCautions())
            break
          case 404:
            toastOnErrorUseComponent(ToCsvSettingImport())
            break
          case 422:
            toastOnErrorUseComponent(ShowCsvError(e.response.data))
            break
          default:
            handleError(e.response.status)
        }
      })
      .finally(() => {
        setIsProcessingModalShow(false)
      })
  }

  const exportCsv = () => {
    exportDriversCsv(searchParams)
      .then((response) => {
        const filename = 'drivers.csv'
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
      })
      .catch((e) => {
        switch (e.response.status) {
          case 404:
            toastOnErrorUseComponent(ToCsvSettingImport())
            break
          default:
            handleError(e.response.status)
        }
      })
  }

  React.useEffect(() => {
    fetchLockedDriversList()
      .then((response) => {
        setLockedDrivers(response.data.drivers)
      })
      .catch((e) => {
        handleError(e.response.status)
      })
  }, [])

  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>{PAGE_TITLES.DRIVERS.INDEX ?? APPLICATION_NAME}</title>
        </Helmet>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
          }}
        >
          <h1>{ADMIN_MENUS.masters.children.drivers.title}</h1>
          <FlexBox alignItems="center">
            <InvisibleForMobile>
              {user.admin && (
                <ExportButton
                  onClick={() =>
                    window.open(driverLedgersExportUrl(), '_blank')
                  }
                  disabled={resultDriverIds?.length == 0 ? true : false}
                  modalText={
                    isSearchParamsEmpty(searchParams)
                      ? `${ALERT_TEXT.LONG_TIME_EXPORT}${ALERT_TEXT.ALL_DATA_EXPORT}`
                      : ALERT_TEXT.LONG_TIME_EXPORT
                  }
                >
                  従業員台帳一括出力
                </ExportButton>
              )}

              {user.admin && (
                <Box ml={2}>
                  <ExportButton
                    onClick={() => window.open(ExportEmployeesUrl(), '_blank')}
                    disabled={driversData?.totalCount === 0}
                    modalText={
                      isSearchParamsEmpty(searchParams)
                        ? `${ALERT_TEXT.LONG_TIME_EXPORT}${ALERT_TEXT.ALL_DATA_EXPORT}`
                        : ALERT_TEXT.LONG_TIME_EXPORT
                    }
                  >
                    労働者名簿CSV リスト出力
                  </ExportButton>
                </Box>
              )}

              {user.subscribedCsvSettings && (
                <Box ml={2}>
                  <ExportButton
                    onClick={exportCsv}
                    disabled={driversData?.totalCount === 0}
                    modalText={
                      isSearchParamsEmpty(searchParams)
                        ? `${ALERT_TEXT.LONG_TIME_EXPORT}${ALERT_TEXT.ALL_DATA_EXPORT}`
                        : ALERT_TEXT.LONG_TIME_EXPORT
                    }
                  >
                    CSV出力
                  </ExportButton>
                </Box>
              )}

              {user.isSystemAdmin && user.subscribedCsvSettings && (
                <Box ml={2}>
                  <CustomCsvImport onChange={onInputChange} />
                </Box>
              )}
              {user.admin && (
                <Box ml={2}>
                  <AddButton href={ADMIN_ROUTES.DRIVERS_NEW}>{title}</AddButton>
                </Box>
              )}
            </InvisibleForMobile>
          </FlexBox>
        </Box>
        {user.admin && lockedDrivers.length > 0 && (
          <div>
            <span style={{ color: palette.error.main }}>
              以下のアカウントがロックされています。
            </span>
            <ul style={{ paddingLeft: '0px' }}>
              {lockedDrivers.map((driver) => {
                return (
                  <li key={driver.id} style={{ listStyle: 'none' }}>
                    <Link
                      to={`/admin/drivers/${driver.id}`}
                      style={{ textDecoration: 'none' }}
                    >
                      {driver.fullName}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
        <DriversTable
          data={driversData?.drivers}
          totalCount={driversData?.totalCount}
          setSearchParams={setSearchParams}
          paginateParams={paginateParams}
          setPaginateParams={setPaginateParams}
          searchParams={searchParams}
          defaultSearchParams={defaultSearchParams}
          isXmileSystemAdminUser={user.isXmileAdmin}
          focusId={parseInt(focusId)}
          isLoading={isLoadingDriversData}
        />
      </PageWrapper>
      <ProcessingModal isOpen={isProcessingModalShow} />
    </>
  )
}

export default AdminDriversIndex
