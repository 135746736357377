import * as React from 'react'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  BoxBorderBottom,
  FlexBox,
  MarginBox,
} from 'components/atoms/BoxComponents'
import { Tab, Tabs, Typography } from '@mui/material'
import { TextBold } from 'components/atoms/TextComponents'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { useNavigate } from 'react-router-dom'
import { deleteAlcoholCheck } from 'components/apis/alcohol_checks'
import { toastOnError } from 'commons/toaster'
import { ALCOHOL_CHECKS } from 'commons/constants'
import { useMutation } from '@tanstack/react-query'
import { UserContext } from 'providers/UserProvider'
import { slackNotification } from 'commons/slackNotification'
import TabView from './TabView'
import { TabName } from './Form'

export default function AlcoholCheckDetail(props: { data: AlcoholCheck }) {
  const { data } = props
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0)
  const navigate = useNavigate()
  const mutation = useMutation({
    mutationFn: deleteAlcoholCheck,
    onSuccess: () => {
      navigate(-1)
    },
    onError: (e: { response: { errors: string } }) => {
      toastOnError(e.response.errors)
      // 422エラー時にslack通知する
      slackNotification(e, user)
    },
  })
  const onClickDelete = () => {
    mutation.mutate(data.id)
  }

  const user = React.useContext(UserContext)

  return (
    <BackGroundWhite>
      <>
        {user.isXmileAdmin ||
          (user.isGroupParentCompanyAdmin && (
            <MarginBox sx={{ margin: '1rem' }}>
              <TextBold>{ALCOHOL_CHECKS.LABELS.COMPANY}</TextBold>
              <BoxBorderBottom>
                <Typography>{data.companyName}</Typography>
              </BoxBorderBottom>
            </MarginBox>
          ))}

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ALCOHOL_CHECKS.LABELS.OFFICE}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.officeName}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <FlexBox flexDirection={'column'}>
          <MarginBox sx={{ margin: '1rem' }}>
            <TextBold>{ALCOHOL_CHECKS.LABELS.DRIVER}</TextBold>
            <BoxBorderBottom>
              <Typography>{data.userName}</Typography>
            </BoxBorderBottom>
          </MarginBox>

          <Tabs
            value={selectedTabIndex}
            onChange={(_: React.SyntheticEvent, tabIndex: number) => {
              setSelectedTabIndex(tabIndex)
            }}
            variant="fullWidth"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#0050B2',
              },
            }}
            sx={{
              borderBottom: '1px solid #0050B2',
              marginBottom: '1.5rem',
              marginTop: '1rem',
            }}
          >
            <Tab label="業前" sx={{ fontWeight: 'bold' }} />
            <Tab
              label="業後"
              disabled={data.resultAfterWork ? false : true}
              sx={{ fontWeight: 'bold' }}
            />
          </Tabs>
          {selectedTabIndex === 0 && (
            <TabView data={data} tabName={TabName.BEFORE} />
          )}
          {data.resultAfterWork && selectedTabIndex === 1 && (
            <TabView data={data} tabName={TabName.AFTER} />
          )}
        </FlexBox>
        <DeleteButton sx={{ margin: 'none' }} onClick={() => onClickDelete()}>
          このデータを削除する
        </DeleteButton>
      </>
    </BackGroundWhite>
  )
}
