import * as React from 'react'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { useErrorHandler } from 'react-error-boundary'
import { Helmet } from 'react-helmet-async'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import DriverImportForm from 'components/organisms/csv_settings/driver_import/Form'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { getCsvHeaderAssociation } from 'components/apis/csvHeaderAssociations'

function AdminCsvSettingsDriverImportEdit() {
  const [driverImport, setDriverImport] = React.useState<DriverImport>(null)
  const [loading, setLoading] = React.useState(true)
  const handleError = useErrorHandler()

  React.useEffect(() => {
    getCsvHeaderAssociation('driver')
      .then((response) => {
        setDriverImport(response.data)
      })
      .catch((e) => {
        handleError(e.response.status)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <PageWrapper>
      <Helmet>
        <title>
          {PAGE_TITLES.CSV_SETTINGS.DRIVER_IMPORT ?? APPLICATION_NAME}
        </title>
      </Helmet>
      <h1>従業員CSVインポート設定</h1>
      {loading ? (
        <CircularProgressBox />
      ) : (
        <DriverImportForm data={driverImport} />
      )}
    </PageWrapper>
  )
}

export default AdminCsvSettingsDriverImportEdit
