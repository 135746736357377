export const LICENSE_TYPE = [
  { label: '普通', value: 'standard' },
  { label: '準中型(5t限定)', value: 'limitedSemiMidsize' },
  { label: '準中型', value: 'semiMidsize' },
  { label: '中型(8t限定)', value: 'limitedMidsize' },
  { label: '中型', value: 'midsize' },
  { label: '大型', value: 'large' },
  { label: '大型特殊', value: 'largeSpecial' },
  { label: '小型特殊', value: 'smallSpecial' },
  { label: '牽引', value: 'towing' },
]
