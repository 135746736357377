import * as React from 'react'
import { ERRORS_TEXTS } from 'commons/constants'
import { styled } from '@mui/styles'

const DescriptionText = styled('h3')({
  whiteSpace: 'pre-wrap',
})

function Unknown() {
  return (
    <>
      <DescriptionText>{ERRORS_TEXTS.UNKNOWN}</DescriptionText>
    </>
  )
}

export default Unknown
