import * as React from 'react'
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  Controller,
} from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { AddButtonOutlined } from 'components/atoms/ButtonComponenets'
import { Box } from '@mui/material'
import MedicalRecordCard from 'components/organisms/admin/drivers/form_sections/medical_records/MedicalRecordCard'
import { DRIVERS, MEDICAL_RECORDS } from 'commons/constants'
import {
  CustomDropZone,
  CustomLabel,
  HeadingText,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

type MedicalRecordsProps = {
  register: UseFormRegister<Driver>
  setValue: UseFormSetValue<Driver>
  watch: UseFormWatch<Driver>
  control: Control<Driver>
  errors: FieldErrors<Driver>
  medicalRecordsFiles: File[]
  setMedicalRecordsFiles: React.Dispatch<React.SetStateAction<File[]>>
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>
}

const MedicalRecords: React.FC<MedicalRecordsProps> = (props) => {
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: 'medicalRecords',
  })

  // 画像設定時のコールバック
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      props.setMedicalRecordsFiles([
        ...props.medicalRecordsFiles,
        ...acceptedFiles,
      ])
    },
    [props.medicalRecordsFiles]
  )

  const appendEmptyItem = () => {
    const emptyItem = {
      driverType: '',
      consultationYear: null,
      consultationMonth: null,
      consultationDay: null,
      description: '',
      files: [],
    }
    append(emptyItem, { shouldFocus: false })
  }

  const deleteItem = (targetIndex: number) => {
    remove(targetIndex)
  }

  return (
    <BackGroundWhite>
      <HeadingText>{DRIVERS.LABELS.MEDICAL_RECORDS}</HeadingText>
      {fields.map((_, i) => {
        return (
          <Box mb={2} key={`medical-record-card-${i}`}>
            <MedicalRecordCard
              register={props.register}
              errors={props.errors}
              control={props.control}
              appendEmptyItem={appendEmptyItem}
              deleteItem={deleteItem}
              watch={props.watch}
              setValue={props.setValue}
              index={i}
              onInputKeyDown={props.onInputKeyDown}
            ></MedicalRecordCard>
          </Box>
        )
      })}
      <AddButtonOutlined onClick={appendEmptyItem}>
        {DRIVERS.LABELS.MEDICAL_RECORDS}を追加
      </AddButtonOutlined>
      <CustomLabel sx={{ marginBottom: '30px' }}>
        {DRIVERS.LABELS.FILE_UPLOAD}(健康診断の
        {DRIVERS.LABELS.FILE_UPLOAD_DESCRIPTION_PARENT})
        <Controller
          render={() => (
            <CustomDropZone
              multiple={true}
              onDrop={onDrop}
              accept={'image/*, application/pdf'}
            />
          )}
          name={`medicalRecordsFileUrls`}
          control={props.control}
        />
        {Object.values(props?.medicalRecordsFiles)?.map((image, index) => (
          <FlexBox flexDirection={'row'} key={index}>
            <p>
              <a
                href={URL.createObjectURL(image)}
                download={decodeURI(image.name)}
              >
                {decodeURI(image.name)}
              </a>
            </p>
            <IconButton
              component="span"
              onClick={() =>
                props.setMedicalRecordsFiles(
                  props.medicalRecordsFiles.filter((_, i) => i !== index)
                )
              }
            >
              <CancelIcon />
            </IconButton>
          </FlexBox>
        ))}
      </CustomLabel>
    </BackGroundWhite>
  )
}

export default MedicalRecords
