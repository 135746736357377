import * as React from 'react'
import { Control, Controller } from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  CustomFileInput,
  Avatar,
  CustomDateSelect,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { DRIVERS } from 'commons/constants'
import { Box, useTheme } from '@mui/material'

type ProfileImageProps = {
  control: Control<Driver>
  setProfileImage: React.Dispatch<React.SetStateAction<File>>
  profileImage: File
}

export default function ProfileImage(props: ProfileImageProps) {
  const theme = useTheme()
  const { control, setProfileImage, profileImage } = props
  const imageURL = profileImage ? URL.createObjectURL(profileImage) : ''

  // 画像設定時のコールバック
  const onInputChange = (e) => {
    const file = e.target.files[0]
    setProfileImage(file)
  }

  return (
    <BackGroundWhite>
      <Box mb={3}>
        <HeadingText>{DRIVERS.LABELS.PROFILE_IMAGE}</HeadingText>
      </Box>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <CustomLabel>
            {DRIVERS.LABELS.PROFILE_IMAGE}
            <FlexBox alignItems="center" mt={1}>
              <Box mr={2}>
                <Avatar src={imageURL} />
              </Box>
              <Controller
                name="profile.imageUrl"
                render={() => <CustomFileInput onChange={onInputChange} />}
                control={control}
              />
            </FlexBox>
          </CustomLabel>
        </Box>
        <CustomLabel>
          <Box mb={1}> {DRIVERS.LABELS.PROFILE_IMAGE_DATE}</Box>
          <CustomDateSelect
            formKeys={{
              year: 'profile.imageCreatedYear',
              month: 'profile.imageCreatedMonth',
              day: 'profile.imageCreatedDay',
            }}
            control={control}
          />
        </CustomLabel>
      </FlexBox>
    </BackGroundWhite>
  )
}
