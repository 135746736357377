import { toast } from 'react-toastify'

export const toastOnSuccess = (message: string) => {
  toast.success(message, {
    position: 'bottom-center',
    theme: 'colored',
  })
}

export const toastOnError = (
  message: string,
  onOpen?: () => void,
  onClose?: () => void
) => {
  if (typeof message !== 'string') message = 'エラーが発生しました'
  else message = message || 'エラーが発生しました'
  toast.error(message, {
    position: 'bottom-center',
    theme: 'colored',
    onOpen: onOpen,
    onClose: onClose,
  })
}

export const toastOnErrorUseComponent = (
  component: JSX.Element,
  onOpen?: () => void,
  onClose?: () => void
) => {
  toast.error(component, {
    position: 'bottom-center',
    theme: 'colored',
    onOpen: onOpen,
    onClose: onClose,
  })
}
