import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BackGroundGray } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomInputShort,
  CustomForm,
  ResetButton,
  CustomSelect,
  WithEmptyOption,
  CustomCheckBoxForm,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { styled } from '@mui/styles'
import { Box, BoxProps, Typography } from '@mui/material'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { fetchVehicleOptions } from 'components/apis/vehicles'
import { LOCAL_STORAGE_PAGE_KEY, VEHICLES } from 'commons/constants'
import SearchCompanyAndOffice from 'components/atoms/ReactHookFormPartials/SearchForm/SearchCompanyAndOffice'
import { LICENSE_TYPE } from 'commons/licenseType'
import { ENUMS } from 'commons/enums'
import { range } from 'commons/functions'
import { useQuery } from '@tanstack/react-query'
import {
  setLocalStorageSearchParams,
  getLocalStorageDisplayLimit,
  removeLocalStoragePageNumber,
} from 'commons/table'

interface Props {
  setSearchParams: React.Dispatch<React.SetStateAction<SearchVehicle>>
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  searchParams: SearchVehicle
  defaultSearchParams: SearchVehicle
  isLoading?: boolean
}

export function FlexBoxRow(props: BoxProps) {
  const FlexBoxRowElement = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  })
  return <FlexBoxRowElement {...props}>{props.children}</FlexBoxRowElement>
}

const CustomCheckBoxFormContain = styled('div')({
  marginTop: '10px',
})

function SearchForm(props: Props) {
  const {
    setSearchParams,
    setPaginateParams,
    setPage,
    searchParams,
    defaultSearchParams,
    isLoading = false,
  } = props

  // フォームオブジェクト
  const { register, handleSubmit, control, reset, getValues, setValue, watch } =
    useForm<SearchVehicle>({
      mode: 'onSubmit',
      defaultValues: searchParams,
    })

  // フォームsubmit時のコールバック
  const onSubmit = (data: SearchVehicle) => {
    setSearchParams(data)
    setLocalStorageSearchParams(LOCAL_STORAGE_PAGE_KEY.VEHICLES, data)
  }

  const { data: vehicleOptions } = useQuery(
    [`vehicles/SearchForm`, 'vehicle_options'],
    () =>
      fetchVehicleOptions().then((res) => {
        const obj: SelectOptions = Object.entries(res.data).reduce(
          (acc, [key, values]) => {
            acc[key] = Object.values(values).map((value) => {
              return { label: value?.text, value: value?.value }
            })
            return acc
          },
          {}
        )
        return obj
      })
  )

  const tonnageRange = range(1, 31).map((value) => ({
    label: `${value}t`,
    value: value,
  }))

  const resetParams = () => {
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = 1
    setPaginateParams({ pageNumber, displayLimit: displayLimit })
    setPage(pageNumber)
    removeLocalStoragePageNumber(LOCAL_STORAGE_PAGE_KEY.VEHICLES)
    setSearchParams(defaultSearchParams)
    setLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.VEHICLES,
      defaultSearchParams
    )
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '90%' }}>
        <BackGroundGray>
          <Typography
            sx={{ marginBottom: '20px', fontWeight: 'bold' }}
            variant={'body1'}
          >
            条件で絞り込み
          </Typography>
          <CustomForm onSubmit={handleSubmit(onSubmit)}>
            <SearchCompanyAndOffice
              companyKeyName={'companyId'}
              officeKeyName={'officeId'}
              control={control}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
            />
            <FlexBox>
              <CustomLabel>
                {VEHICLES.LABELS.NAME}
                <CustomInputShort {...register('name')} />
              </CustomLabel>

              <CustomLabel>
                {VEHICLES.LABELS.NUMBER}
                <CustomInputShort {...register('number')} />
              </CustomLabel>
            </FlexBox>

            <FlexBox>
              <CustomLabel>
                {VEHICLES.LABELS.TEMPERATURE}
                <Controller
                  name="temperatureZone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      options={WithEmptyOption(vehicleOptions?.temperatureZone)}
                      value={vehicleOptions?.temperatureZone?.find(
                        (c) => c.value === value
                      )}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option.value)
                      }
                      placeholder="全て"
                    />
                  )}
                />
              </CustomLabel>
            </FlexBox>

            <FlexBox>
              <CustomLabel>
                {VEHICLES.LABELS.SHAPE}
                <Controller
                  name="shape"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      options={WithEmptyOption(vehicleOptions?.shape)}
                      value={vehicleOptions?.shape?.find(
                        (c) => c.value === value
                      )}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option.value)
                      }
                      placeholder="全て"
                    />
                  )}
                />
              </CustomLabel>

              <CustomLabel>
                {VEHICLES.LABELS.SIZE}
                <Controller
                  name="size"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      options={WithEmptyOption(vehicleOptions?.size)}
                      value={vehicleOptions?.size?.find(
                        (c) => c.value === value
                      )}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option.value)
                      }
                      placeholder="全て"
                    />
                  )}
                />
              </CustomLabel>
            </FlexBox>

            <FlexBox>
              <CustomLabel>
                {VEHICLES.LABELS.OPERATION_STATUS}
                <FlexBoxRow flexWrap="wrap">
                  {Object.entries(ENUMS.VEHICLES.OPERATION_STATUS_TYPE).map(
                    ([index, operationStatusType]) => (
                      <Controller
                        key={index}
                        name={
                          `operationStatusType${
                            operationStatusType.value.charAt(0).toUpperCase() +
                            operationStatusType.value.slice(1)
                          }` as keyof SearchVehicle
                        }
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <CustomCheckBoxFormContain>
                            <CustomCheckBoxForm>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={value as boolean}
                                  onChange={() => onChange(!value)}
                                />
                                {operationStatusType.label}
                              </label>
                            </CustomCheckBoxForm>
                          </CustomCheckBoxFormContain>
                        )}
                      />
                    )
                  )}
                </FlexBoxRow>
              </CustomLabel>
            </FlexBox>

            <FlexBox>
              <CustomLabel>
                {VEHICLES.LABELS.TONNAGE}
                <FlexBox>
                  <Controller
                    name="minTonnage"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CustomSelect
                        options={tonnageRange}
                        value={tonnageRange?.find((c) => c.value === value)}
                        onChange={(option: ReactSelectOptionProps) =>
                          onChange(option.value)
                        }
                        placeholder="トン数を選択する(最小)"
                      />
                    )}
                  />
                  <Typography sx={{ margin: '0 1rem' }}>〜</Typography>
                  <Controller
                    name="maxTonnage"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CustomSelect
                        options={tonnageRange}
                        value={tonnageRange?.find((c) => c.value === value)}
                        onChange={(option: ReactSelectOptionProps) =>
                          onChange(option.value)
                        }
                        placeholder="トン数を選択する(最大)"
                      />
                    )}
                  />
                </FlexBox>
              </CustomLabel>
            </FlexBox>

            <FlexBox>
              <CustomLabel>
                {VEHICLES.LABELS.LICENSE}
                <Controller
                  name="license"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      options={WithEmptyOption(LICENSE_TYPE)}
                      value={LICENSE_TYPE?.find((c) => c.value === value)}
                      onChange={(option: ReactSelectOptionProps) =>
                        onChange(option?.value)
                      }
                      placeholder="全て"
                    />
                  )}
                />
              </CustomLabel>
            </FlexBox>

            <ApplyButton pattern="inRow" type="submit" disabled={isLoading}>
              絞り込む
            </ApplyButton>
            <ResetButton
              onClick={() => {
                reset(defaultSearchParams)
                resetParams()
              }}
              disabled={isLoading}
            >
              <Typography>リセット</Typography>
            </ResetButton>
          </CustomForm>
        </BackGroundGray>
      </Box>
    </Box>
  )
}

export default SearchForm
