import * as React from 'react'
import { Controller } from 'react-hook-form'
import { CustomLabel, CustomSelect } from 'components/atoms/FormComponents'
import { fetchAllCompanies } from 'components/apis/companies'
import { UserContext } from 'providers/UserProvider'
import { useQuery } from '@tanstack/react-query'

export default function SearchCompany({ control }: { control: any }) {
  const user = React.useContext(UserContext)

  const { data: companies, isLoading } = useQuery(
    ['search-company', 'companies'],
    () => fetchAllCompanies().then((res) => res.data.companies)
  )

  if (isLoading) {
    return null
  }

  const companyList = companies.map((company: Company) => {
    // NOTE: react-hook-formのreset()は全ての項目をundefinedにするので、valueがnullでは意図した挙動にならない為、未選択時のvalueはundefinedにしておく
    return {
      label: company.name,
      value: company.name === '　' ? undefined : company.id,
      selected: company.selected,
    }
  })

  return user.isXmileAdmin ? (
    <CustomLabel>
      会社名
      <Controller
        name="companyId"
        control={control}
        render={({ field: { onChange, value } }) => (
          <CustomSelect
            options={companyList}
            value={companyList.find((c) => c.value === value)}
            onChange={(option: ReactSelectOptionProps) =>
              onChange(option.value)
            }
            placeholder="未選択"
          />
        )}
      />
    </CustomLabel>
  ) : null
}
