import * as React from 'react'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { Box } from '@mui/material'
import { TABLE_DEFAULT_ROWS } from 'commons/constants'
import { MENUS } from 'commons/menus'
import { UserContext } from 'providers/UserProvider'
import ArticlesTable from 'components/organisms/articles/Table'
import useArticles from './hooks/useArticles'

function ArticlesIndex() {
  const title = 'お知らせの新規作成'
  const user = React.useContext(UserContext)
  const defaultSearchParams = {}
  const [searchParams, setSearchParams] =
    React.useState<SearchArticle>(defaultSearchParams)
  const [paginateParams, setPaginateParams] = React.useState<PaginateParams>({
    pageNumber: 1,
    displayLimit: TABLE_DEFAULT_ROWS,
  })

  const { articlesData, isLoadingArticlesData } = useArticles(
    searchParams,
    paginateParams
  )

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
        }}
      >
        <h1>{MENUS.articles.title}</h1>
      </Box>
      <ArticlesTable
        data={articlesData?.articles}
        totalCount={articlesData?.totalCount}
        setSearchParams={setSearchParams}
        paginateParams={paginateParams}
        setPaginateParams={setPaginateParams}
        defaultSearchParams={defaultSearchParams}
        isLoading={isLoadingArticlesData}
      />
    </PageWrapper>
  )
}

export default ArticlesIndex
