import * as React from 'react'
import {
  APPLICATION_NAME,
  PAGE_TITLES,
  TABLE_DEFAULT_ROWS,
} from 'commons/constants'
import { MENUS } from 'commons/menus'
import NotificationsTable from 'components/organisms/notifications/Table'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { Box } from '@mui/material'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { fetchNotifications } from 'components/apis/notifications'
import { useErrorHandler } from 'react-error-boundary'
import { Helmet } from 'react-helmet-async'

function NotificationIndex() {
  const [notifications, setNotifications] = React.useState<Notification[]>(null)
  const [totalCount, setTotalCount] = React.useState(0)
  const [loading, setLoading] = React.useState(true)
  const defaultSearchParams = {
    notificationType: '',
    leftDays: undefined,
    includePastNotifications: false,
  }
  const [searchParams, setSearchParams] =
    React.useState<SearchNotification>(defaultSearchParams)
  const [paginateParams, setPaginateParams] = React.useState<PaginateParams>({
    pageNumber: 1,
    displayLimit: TABLE_DEFAULT_ROWS,
  })
  const handleError = useErrorHandler()

  React.useEffect(() => {
    fetchNotifications(searchParams, paginateParams)
      .then((response) => {
        setNotifications(response.data.notifications)
        setTotalCount(response.data.totalCount)
      })
      .catch((e) => {
        handleError(e.response.status)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [searchParams, paginateParams])

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.NOTIFICATIONS.INDEX ?? APPLICATION_NAME}</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
        }}
      >
        <h1>{MENUS.notifications.title}</h1>
      </Box>
      {loading ? (
        <CircularProgressBox />
      ) : (
        <NotificationsTable
          data={notifications}
          totalCount={totalCount}
          setSearchParams={setSearchParams}
          defaultSearchParams={defaultSearchParams}
          paginateParams={paginateParams}
          setPaginateParams={setPaginateParams}
          disabledClick={true}
        />
      )}
    </PageWrapper>
  )
}

export default NotificationIndex
