import * as React from 'react'
import { XMILE_ADMIN_MENUS } from 'commons/menus'
import CompaniesForm from 'components/organisms/companies/Form'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { getCompany } from 'components/apis/companies'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { useParams } from 'react-router-dom'
import {
  fetchAllCompanies,
  fetchGroupParentCompanies,
} from 'components/apis/companies'
import { useQuery } from '@tanstack/react-query'
import { APPLICATION_NAME, PAGE_TITLES } from 'commons/constants'
import { Helmet } from 'react-helmet-async'

function AdminCompaniesEdit() {
  const { id } = useParams()

  const { data: company, isLoading: isCompanyLoading } = useQuery(
    [`admin/companies/${id}`, 'company'],
    () => getCompany(parseInt(id)).then((res) => res.data)
  )

  const { data: companies, isLoading: isCompanyListLoading } = useQuery(
    [`admin/companies/${id}/edit`, 'companies'],
    () => fetchAllCompanies().then((res) => res.data.companies)
  )

  const {
    data: groupParentCompanies,
    isLoading: isGoupParentCompanyListLoading,
  } = useQuery([`admin/companies/${id}/edit`, 'groupParentCompanies'], () =>
    fetchGroupParentCompanies().then((res) => res.data.groupParentCompanies)
  )

  if (
    isCompanyLoading ||
    isCompanyListLoading ||
    isGoupParentCompanyListLoading
  ) {
    return <CircularProgressBox />
  }

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.COMPANIES.EDIT ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>{XMILE_ADMIN_MENUS.masters.children.companies.title}</h1>
      <CompaniesForm
        type="update"
        data={company}
        companies={companies}
        groupParentCompanies={groupParentCompanies}
      />
    </PageWrapper>
  )
}

export default AdminCompaniesEdit
