import * as React from 'react'
import {
  BackGroundPalePink,
  BackGroundWhite,
} from 'components/atoms/BackGroundComponents'
import { Box, styled } from '@mui/material'
import { palette, theme } from 'components/theme'
import { VEHICLES } from 'commons/constants'
import { FlexBox } from 'components/atoms/BoxComponents'

type BasicInfoProps = {
  data: Vehicle
}

export default function BasicInfo(props: BasicInfoProps) {
  const Title = styled('h2')({
    fontSize: '20px',
    margin: 0,
    marginBottom: '16px',
  })
  const Text = styled('p')({
    fontSize: '16px',
    margin: 0,
  })
  const Label = styled('p')({
    fontSize: '14px',
    margin: 0,
    color: palette.text.gray.pale,
    marginTop: '32px',
    marginBottom: '8px',
  })
  const TextWrapper = styled('div')({
    width: '300px',
    paddingBottom: '8px',
    paddingLeft: '4px',
    borderBottom: `1px solid ${palette.border.pale}`,
    overflowWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  })
  const TextWrapperShort = styled(TextWrapper)({
    width: '140px',
  })
  const TextWrapperLong = styled(TextWrapper)({
    width: '420px',
  })

  const registeredDate =
    props.data.registeredYear &&
    props.data.registeredMonth &&
    props.data.registeredDay
      ? `${props.data.registeredYear}年${props.data.registeredMonth}月${props.data.registeredDay}日`
      : '未入力'

  const modelName =
    props.data.model1 && props.data.model2
      ? `${props.data.model1}-${props.data.model2}`
      : null

  const tonnage = props.data.tonnage ? props.data.tonnage + 't' : ''
  const firstRegisteredDate = props.data.firstRegisteredDate
    ? String(props.data.firstRegisteredDate).slice(0, -3)
    : ''

  const size = {
    normal: '標準',
    short: 'ショート',
    long: 'ロング',
    wide: 'ワイド',
  }
  const cabinType = {
    normal: '標準',
    wide: 'ワイド',
    full: 'フル',
    high: 'ハイ',
    double: 'ダブル',
  }
  const scrType = { '0': 'なし', '1': 'あり' }
  const transMissionType = {
    mt: 'MT',
    at: 'AT',
    special: '特殊',
    'semi-at': 'セミオートマ',
  }
  const bedType = {
    '0': 'なし',
    '1': 'あり',
  }
  const coldType = {
    '0': 'なし',
    '1': 'あり',
  }
  const category = {
    light: '軽',
    small: '小型',
    normal: '普通',
    small_special: '小型特殊',
    large_special: '大型特殊',
  }
  const usageType = {
    passenger: '乗用',
    freight: '貨物',
    special: '特別',
  }
  const numberPlateType = {
    business: '業務用',
    private: '自家用',
  }

  return (
    <BackGroundWhite>
      <Title>{VEHICLES.LABELS.BASIC_INFO}</Title>
      <BackGroundPalePink>
        <Label sx={{ marginTop: '8px' }}>
          {VEHICLES.LABELS.NUMBER_WITH_DESCRIPTION}
        </Label>
        <TextWrapper>
          <Text>{props.data.number || '未入力'}</Text>
        </TextWrapper>
        <Label sx={{ marginTop: '16px' }}>
          {VEHICLES.LABELS.UNIQUE_NUMBER}
        </Label>
        <TextWrapper sx={{ marginBottom: '8px' }}>
          <Text>{props.data.uniqueNumber || '未入力'}</Text>
        </TextWrapper>
      </BackGroundPalePink>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.REGISTERED_DATE}</Label>
          <TextWrapper>
            <Text>{registeredDate || '未入力'}</Text>
          </TextWrapper>
        </Box>
        <Box>
          <Label>{VEHICLES.LABELS.FIRST_REGISTERED_DATE}</Label>
          <TextWrapper>
            <Text>{firstRegisteredDate || '未入力'}</Text>
          </TextWrapper>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.CATEGORY}</Label>
          <TextWrapperShort>
            <Text>{category[props.data.category] || '未入力'}</Text>
          </TextWrapperShort>
        </Box>
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.USAGE}</Label>
          <TextWrapperShort>
            <Text>{usageType[props.data.usage] || '未入力'}</Text>
          </TextWrapperShort>
        </Box>
        <Box>
          <Label>{VEHICLES.LABELS.NUMBER_PLATE_TYPE}</Label>
          <TextWrapperShort>
            <Text>
              {numberPlateType[props.data.numberPlateType] || '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.SHAPE}</Label>
          <TextWrapper>
            <Text>{props.data.shapeText || '未入力'}</Text>
          </TextWrapper>
        </Box>
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.CONNECTED_TRAILER}</Label>
          <TextWrapper>
            <Text>{props.data.trailerNumber || '未入力'}</Text>
          </TextWrapper>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.MANUFACTURE}</Label>
          <TextWrapper>
            <Text>{props.data.manufacture || '未入力'}</Text>
          </TextWrapper>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.PERSON_CAPACITY}</Label>
          <TextWrapperShort>
            <Text>
              {props.data.personCapacity
                ? `${props.data.personCapacity}人`
                : '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.WEIGHT_CAPACITY}</Label>
          <TextWrapperShort>
            <Text>
              {props.data.weightCapacity1
                ? `${props.data.weightCapacity1}kg`
                : '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.WEIGHT_CAPACITY}2</Label>
          <TextWrapperShort>
            <Text>
              {props.data.weightCapacity2
                ? `${props.data.weightCapacity2}kg`
                : '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.WEIGHT_CAPACITY}3</Label>
          <TextWrapperShort>
            <Text>
              {props.data.weightCapacity3
                ? `${props.data.weightCapacity3}kg`
                : '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.WEIGHT}</Label>
          <TextWrapperShort>
            <Text>
              {props.data.weight ? `${props.data.weight}kg` : '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.TOTAL_WEIGHT}</Label>
          <TextWrapperShort>
            <Text>
              {props.data.totalWeight1
                ? `${props.data.totalWeight1}kg`
                : '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.TOTAL_WEIGHT}2</Label>
          <TextWrapperShort>
            <Text>
              {props.data.totalWeight2
                ? `${props.data.totalWeight2}kg`
                : '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.TOTAL_WEIGHT}3</Label>
          <TextWrapperShort>
            <Text>
              {props.data.totalWeight3
                ? `${props.data.totalWeight3}kg`
                : '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.SIZE}</Label>
          <TextWrapperShort>
            <Text>
              {props.data.size
                ? [size[props.data.size], tonnage].join('/')
                : '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.OUTER}</Label>
          <TextWrapperLong>
            <Text>
              長さ:
              {props.data.outerLength
                ? `${props.data.outerLength}cm `
                : '未入力 '}
              幅:
              {props.data.outerWidth
                ? `${props.data.outerWidth}cm `
                : '未入力 '}
              高さ:
              {props.data.outerHeight
                ? `${props.data.outerHeight}cm `
                : '未入力 '}
            </Text>
          </TextWrapperLong>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.CABIN_TYPE}</Label>
          <TextWrapper>
            <Text>{cabinType[props.data.cabinType] || '未入力'}</Text>
          </TextWrapper>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.AXILE_WEIGHT_FRONT_FRONT}</Label>
          <TextWrapperShort>
            <Text>
              {props.data.axileWeightFrontFront
                ? `${props.data.axileWeightFrontFront}kg`
                : '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.AXILE_WEIGHT_FRONT_REAR}</Label>
          <TextWrapperShort>
            <Text>
              {props.data.axileWeightFrontRear
                ? `${props.data.axileWeightFrontRear}kg`
                : '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.AXILE_WEIGHT_REAR_FRONT}</Label>
          <TextWrapperShort>
            <Text>
              {props.data.axileWeightRearFront
                ? `${props.data.axileWeightRearFront}kg`
                : '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.AXILE_WEIGHT_FRONT_REAR_REAR}</Label>
          <TextWrapperShort>
            <Text>
              {props.data.axileWeightRearRear
                ? `${props.data.axileWeightRearRear}kg`
                : '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.DRIVE_SYSTEM}</Label>
          <TextWrapper>
            <Text>{props.data.driveSystem || '未入力'}</Text>
          </TextWrapper>
        </Box>
        <Box>
          <Label>{VEHICLES.LABELS.MODEL}</Label>
          <TextWrapper>
            <Text>
              {modelName
                ? `${modelName}${props.data.modelModified ? '(改)' : ''}`
                : '未入力'}
            </Text>
          </TextWrapper>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.ENGINE_MODEL}</Label>
          <TextWrapper>
            <Text>{props.data.engineModel || '未入力'}</Text>
          </TextWrapper>
        </Box>
        <Box>
          <Label>{VEHICLES.LABELS.ENGINE_DISPLACEMENT}</Label>
          <TextWrapper>
            <Text>
              {props.data.engineDisplacement
                ? `${props.data.engineDisplacement}kw/l`
                : '未入力'}
            </Text>
          </TextWrapper>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.FUEL_TYPE}</Label>
          <TextWrapper>
            <Text>{props.data.fuelType || '未入力'}</Text>
          </TextWrapper>
        </Box>
        <Box>
          <Label>{VEHICLES.LABELS.FUEL_TANK_NUMBER}</Label>
          <TextWrapper>
            <Text>{props.data.fuelTankNumber || '未入力'}</Text>
          </TextWrapper>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.FUEL_TANK_CAPACITY}1</Label>
          <TextWrapper>
            <Text>
              {props.data.fuelTankCapacity1
                ? `${props.data.fuelTankCapacity1}ℓ`
                : '未入力'}
            </Text>
          </TextWrapper>
        </Box>
        <Box>
          <Label>{VEHICLES.LABELS.FUEL_TANK_CAPACITY}2</Label>
          <TextWrapper>
            <Text>
              {props.data.fuelTankCapacity2
                ? `${props.data.fuelTankCapacity2}ℓ`
                : '未入力'}
            </Text>
          </TextWrapper>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.FUEL_TANK_CAPACITY}3</Label>
          <TextWrapper>
            <Text>
              {props.data.fuelTankCapacity3
                ? `${props.data.fuelTankCapacity3}ℓ`
                : '未入力'}
            </Text>
          </TextWrapper>
        </Box>
        <Box>
          <Label>{VEHICLES.LABELS.FUEL_TANK_CAPACITY}4</Label>
          <TextWrapper>
            <Text>
              {props.data.fuelTankCapacity4
                ? `${props.data.fuelTankCapacity4}ℓ`
                : '未入力'}
            </Text>
          </TextWrapper>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.HAS_SCR}</Label>
          <TextWrapperShort>
            <Text>{scrType[props.data.hasScr] || '未入力'}</Text>
          </TextWrapperShort>
        </Box>
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.TRANSMISSION_TYPE}</Label>
          <TextWrapperShort>
            <Text>
              {transMissionType[props.data.transmissionType] || '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
        <Box>
          <Label>{VEHICLES.LABELS.HAS_BED}</Label>
          <TextWrapperShort>
            <Text>{bedType[props.data.hasBed] || '未入力'}</Text>
          </TextWrapperShort>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.TIRE_COUNT}</Label>
          <TextWrapperShort>
            <Text>
              {props.data.tireCount ? `${props.data.tireCount}本` : '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.TIRE_SIZE_FRONT}</Label>
          <TextWrapperShort>
            <Text>{props.data.tireSizeFront || '未入力'}</Text>
          </TextWrapperShort>
        </Box>
        <Box>
          <Label>{VEHICLES.LABELS.TIRE_SIZE_REAR}</Label>
          <TextWrapperShort>
            <Text>{props.data.tireSizeRear || '未入力'}</Text>
          </TextWrapperShort>
        </Box>
      </FlexBox>
      <FlexBox
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box mr={3}>
          <Label>{VEHICLES.LABELS.IS_SPECIFIED_COLD}</Label>
          <TextWrapperShort>
            <Text>{coldType[props.data.isSpecifiedCold] || '未入力'}</Text>
          </TextWrapperShort>
        </Box>
        <Box>
          <Label>{VEHICLES.LABELS.RECYCLE_PRICE}</Label>
          <TextWrapperShort>
            <Text>
              {props.data.recyclePrice
                ? `${props.data.recyclePrice}円`
                : '未入力'}
            </Text>
          </TextWrapperShort>
        </Box>
      </FlexBox>
    </BackGroundWhite>
  )
}
