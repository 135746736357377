import * as React from 'react'
import { Control, useFieldArray } from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { AddButtonOutlined } from 'components/atoms/ButtonComponenets'
import { Box } from '@mui/material'
import VideoCard from './VideoCard'

type VideosProps = {
  subjectId: number
  videoOptions: SelectOption[]
  control: Control<EducationAnnualPlan>
}

export const Videos: React.FC<VideosProps> = (props) => {
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: `subjects.${props.subjectId}.videos`,
  })

  const appendEmptyItem = () => {
    const emptyItem = {
      id: null,
    }
    append(emptyItem, { shouldFocus: false })
  }

  const deleteItem = (targetIndex: number) => {
    remove(targetIndex)
  }

  return (
    <BackGroundWhite>
      {fields.map((_, i) => {
        return (
          <Box mb={2} key={`subjet${props.subjectId}-video-card-${i}`}>
            <VideoCard
              subjectId={props.subjectId}
              videoOptions={props.videoOptions}
              control={props.control}
              deleteItem={deleteItem}
              index={i}
            />
          </Box>
        )
      })}
      <AddButtonOutlined onClick={appendEmptyItem}>
        動画を追加
      </AddButtonOutlined>
    </BackGroundWhite>
  )
}
