import * as React from 'react'
import { useFieldArray, UseFormGetValues } from 'react-hook-form'
import {
  BackGroundWhite,
  BackGroundPalePink,
} from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomInputShort,
  HeadingText,
  CustomRadioButton,
  CustomRadioGroup,
  CustomDateSelect,
} from 'components/atoms/FormComponents'
import {
  AddButtonOutlined,
  DeleteButton,
} from 'components/atoms/ButtonComponenets'
import { FlexBox } from 'components/atoms/BoxComponents'
import { DRIVERS } from 'commons/constants'
import { ENUMS } from 'commons/enums'
import { styled } from '@mui/styles'
import { Box } from '@mui/material'
import { DriverKeys } from 'types/driverForm'

function FamilyProfileForm(props: {
  register
  control
  familyProfileIndex: number
  getValues: UseFormGetValues<Driver>
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>
}) {
  const { register, control, familyProfileIndex, getValues } = props
  const SmallCustomRadioGroup = styled(CustomRadioGroup)({
    width: '180px',
  })

  return (
    <React.Fragment>
      <FlexBox flexWrap="wrap">
        <CustomLabel>
          {DRIVERS.LABELS.LAST_NAME}
          <CustomInputShort
            onKeyDown={props.onInputKeyDown}
            {...register(`familyProfiles.${familyProfileIndex}.lastName`)}
          />
        </CustomLabel>

        <CustomLabel>
          {DRIVERS.LABELS.FIRST_NAME}
          <CustomInputShort
            onKeyDown={props.onInputKeyDown}
            {...register(`familyProfiles.${familyProfileIndex}.firstName`)}
          />
        </CustomLabel>
      </FlexBox>

      <CustomLabel>
        {DRIVERS.LABELS.BIRTHDAY}
        <CustomDateSelect
          formKeys={{
            year: `familyProfiles.${familyProfileIndex}.birthedYear`,
            month: `familyProfiles.${familyProfileIndex}.birthedMonth`,
            day: `familyProfiles.${familyProfileIndex}.birthedDay`,
          }}
          control={control}
          defaultToday={false}
          defaultValue={{ year: 1980 }}
        ></CustomDateSelect>
      </CustomLabel>

      <CustomLabel>
        {DRIVERS.LABELS.RELATIONSHIP}
        <CustomInputShort
          onKeyDown={props.onInputKeyDown}
          {...register(`familyProfiles.${familyProfileIndex}.relationship`)}
        />
      </CustomLabel>

      <CustomLabel>
        {DRIVERS.LABELS.IS_DEPENDENT}
        <SmallCustomRadioGroup>
          {Object.entries(ENUMS.DRIVERS.IS_DEPENDENT).map(([key, obj]) => (
            <React.Fragment key={key}>
              <CustomRadioButton
                {...register(
                  `familyProfiles.${familyProfileIndex}.isDependent`
                )}
                id={`isDependent_${obj.value}_${familyProfileIndex}`}
                value={obj.value}
                defaultChecked={
                  getValues(
                    `familyProfiles.${familyProfileIndex}.isDependent` as unknown as DriverKeys
                  ) == undefined
                    ? obj.value == ENUMS.DRIVERS.IS_DEPENDENT.YES.value
                    : getValues(
                        `familyProfiles.${familyProfileIndex}.isDependent` as unknown as DriverKeys
                      ) == obj.value
                }
                type="radio"
              />
              <label htmlFor={`isDependent_${obj.value}_${familyProfileIndex}`}>
                {obj.label}
              </label>
            </React.Fragment>
          ))}
        </SmallCustomRadioGroup>
      </CustomLabel>
    </React.Fragment>
  )
}

export default function DriversFamilyProfile(props: {
  register
  control
  getValues
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>
}) {
  const { register, control } = props
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: 'familyProfiles',
  })
  const initFamily = {
    firstName: null,
    lastName: null,
    birthedYear: undefined,
    birthedMonth: undefined,
    birthedDay: undefined,
    relationship: null,
  }

  const deleteFamilyProfile = (index) => {
    remove(index)
  }

  const addFamilyProfile = () => {
    append(initFamily)
  }

  return (
    <BackGroundWhite>
      <HeadingText>{DRIVERS.LABELS.FAMILY_PROFILE}</HeadingText>
      {fields.map((_, index) => (
        <Box mb={2} key={index}>
          <BackGroundPalePink>
            {DRIVERS.LABELS.FAMILY_PROFILE}
            {index + 1}
            <FamilyProfileForm
              register={register}
              control={control}
              familyProfileIndex={index}
              getValues={props.getValues}
              onInputKeyDown={props.onInputKeyDown}
            />
            <DeleteButton onClick={() => deleteFamilyProfile(index)}>
              削除
            </DeleteButton>
          </BackGroundPalePink>
        </Box>
      ))}
      <AddButtonOutlined onClick={() => addFamilyProfile()}>
        {DRIVERS.LABELS.FAMILY_PROFILE}を追加
      </AddButtonOutlined>
    </BackGroundWhite>
  )
}
