import * as React from 'react'
import { EditButton } from 'components/atoms/ButtonComponenets'
import { useQuery } from '@tanstack/react-query'
import { fetchDriverList } from 'components/apis/drivers'
import { CustomSelect } from 'components/atoms/FormComponents'
import {
  CommonAccountContext,
  SelectAccountContext,
} from './TmpAccountSelectContainer'

function SelectDriverComponent() {
  const { commonUser, setCommonUser } = React.useContext(CommonAccountContext)
  const { selectUser, setSelectUser } = React.useContext(SelectAccountContext)
  const defaultValue = {}

  const { data: drivers } = useQuery([`education`, `${commonUser.id}`], () =>
    fetchDriverList().then((res) =>
      Object.values(res.data.drivers).map((value: DriverSelectOption) => ({
        value: value.id,
        label: value.fullName,
      }))
    )
  )

  function onClick() {
    setCommonUser(selectUser)
  }

  return (
    <>
      <div>ドライバーを選択してください。</div>
      <div>
        <CustomSelect
          options={drivers}
          defaultValue={defaultValue}
          onChange={(option: ReactSelectOptionProps) =>
            setSelectUser({ id: `${option.value}`, name: option.label })
          }
          placeholder="選択してください"
        />
      </div>
      <div style={{ textAlign: 'center', padding: '10px' }}>
        <EditButton onClick={onClick} disabled={!selectUser}>
          変更する
        </EditButton>
      </div>
    </>
  )
}

export default SelectDriverComponent
