import * as React from 'react'
import { useForm } from 'react-hook-form'
import { BackGroundGray } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  CustomInputShort,
  CustomForm,
  ResetButton,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Box, Typography } from '@mui/material'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { COMPANIES } from 'commons/constants'
import { LOCAL_STORAGE_PAGE_KEY } from 'commons/constants'
import {
  getLocalStorageDisplayLimit,
  removeLocalStoragePageNumber,
  setLocalStorageSearchParams,
} from 'commons/table'

interface Props {
  searchParams: SearchCompany
  defaultSearchParams: SearchCompany
  setSearchParams: React.Dispatch<React.SetStateAction<SearchCompany>>
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  isLoading?: boolean
}

function SearchForm(props: Props) {
  const {
    searchParams,
    defaultSearchParams,
    setSearchParams,
    setPaginateParams,
    setPage,
    isLoading = false,
  } = props

  // フォームオブジェクト
  const { register, handleSubmit, reset } = useForm<SearchCompany>({
    mode: 'onSubmit',
    defaultValues: searchParams,
  })

  // フォームsubmit時のコールバック
  const onSubmit = (data: SearchCompany) => {
    setSearchParams(data)
    setLocalStorageSearchParams(LOCAL_STORAGE_PAGE_KEY.COMPANIES, data)
  }

  const resetParams = () => {
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = 1

    setPaginateParams({ pageNumber, displayLimit: displayLimit })
    setPage(pageNumber)
    removeLocalStoragePageNumber(LOCAL_STORAGE_PAGE_KEY.COMPANIES)

    setSearchParams(defaultSearchParams)
    setLocalStorageSearchParams(
      LOCAL_STORAGE_PAGE_KEY.COMPANIES,
      defaultSearchParams
    )
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '90%' }}>
        <BackGroundGray>
          <Typography
            sx={{ marginBottom: '20px', fontWeight: 'bold' }}
            variant={'body1'}
          >
            条件で絞り込み
          </Typography>
          <CustomForm onSubmit={handleSubmit(onSubmit)}>
            <FlexBox>
              <CustomLabel>
                {COMPANIES.LABELS.NAME}
                <CustomInputShort {...register('name')} />
              </CustomLabel>
            </FlexBox>
            <ApplyButton pattern="inRow" type="submit" disabled={isLoading}>
              絞り込む
            </ApplyButton>
            <ResetButton
              onClick={() => {
                reset(defaultSearchParams)
                resetParams()
              }}
              disabled={isLoading}
            >
              <Typography>リセット</Typography>
            </ResetButton>
          </CustomForm>
        </BackGroundGray>
      </Box>
    </Box>
  )
}

export default SearchForm
