import * as React from 'react'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { ARTICLES } from 'commons/constants'
import { getVideoConversionStatuses } from 'components/apis/articles'
import { useQuery } from '@tanstack/react-query'
import {
  FlexBox,
  MarginBox,
  BoxBorderBottom,
} from 'components/atoms/BoxComponents'
import { Typography } from '@mui/material'
import { TextBold } from 'components/atoms/TextComponents'
import { fetchS3Images } from 'commons/fetchS3Images'
import { styled } from '@mui/material'
import VideoPlayerModal from 'components/atoms/videos/VideoPlayerModal'
import {
  VideoViewButton,
  ConversionStatusButton,
  ConversionStatusTooltip,
} from 'components/atoms/videos/VideoViewButton'
import {
  articleVideoUrlStyle,
  articleVideoFlexBoxStyle,
} from 'components/theme'
import {
  isVideoFile,
  isUnPlayableFile,
  checkVideoConversionsStatuses,
} from 'commons/functions'

export default function ArticleDetail(props: { data: Article }) {
  const { data } = props
  const [files, setFiles] = React.useState<File[]>([])
  // 添付動画のModalの状態管理
  const [isVideoModalShow, setIsVideoModalShow] = React.useState(false)
  // 動画ファイルのURLの状態管理
  const [videoUrl, setVideoUrl] = React.useState('')

  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3Images(data.fileUrls)
      setFiles(files)
    })()
  }, [data.fileUrls])

  const Section = styled('div')({
    margin: '0 1rem 1rem 1rem',
  })

  const showVideoModal = (video) => {
    setIsVideoModalShow(true)
    setVideoUrl(URL.createObjectURL(video))
  }

  const { data: VideoConversionStatuses } = useQuery(
    [`articles`, `${data.id}`, `video_conversion_statuses`],
    () =>
      getVideoConversionStatuses(data.id).then((res) => {
        return res.data
      })
  )

  return (
    <>
      <Section>
        <BackGroundWhite>
          <FlexBox flexDirection={'column'}>
            <MarginBox sx={{ margin: '1rem' }}>
              <h2>{data.title}</h2>
              <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                {data.description}
              </Typography>
            </MarginBox>

            <MarginBox sx={{ margin: '1rem' }}>
              <TextBold>{ARTICLES.LABELS.USER_NAME}</TextBold>
              <BoxBorderBottom>
                <Typography>{data.creatorDetail}</Typography>
              </BoxBorderBottom>
            </MarginBox>

            <MarginBox sx={{ margin: '1rem' }}>
              <TextBold>{ARTICLES.LABELS.UPDATED_AT}</TextBold>
              <BoxBorderBottom>
                <Typography>{data.updatedAt}</Typography>
              </BoxBorderBottom>
            </MarginBox>
          </FlexBox>
          {Object.values(files)?.map((file, index) => (
            <FlexBox
              flexDirection={'row'}
              key={index}
              style={articleVideoFlexBoxStyle}
            >
              <VideoPlayerModal
                isModalOpen={isVideoModalShow}
                setIsModalOpen={setIsVideoModalShow}
                videoUrl={videoUrl}
              />
              {isVideoFile(file) &&
                (isUnPlayableFile(file) ? (
                  <ConversionStatusButton
                    status={checkVideoConversionsStatuses(
                      file.name,
                      VideoConversionStatuses
                    )}
                  />
                ) : (
                  <VideoViewButton
                    onClick={() => showVideoModal(file)}
                    disabled={isUnPlayableFile(file)}
                  />
                ))}
              <p style={articleVideoUrlStyle}>
                <a
                  href={URL.createObjectURL(file)}
                  download={decodeURI(file.name)}
                >
                  {decodeURI(file.name)}
                </a>
              </p>
              {isUnPlayableFile(file) && (
                <ConversionStatusTooltip
                  status={checkVideoConversionsStatuses(
                    file.name,
                    VideoConversionStatuses
                  )}
                />
              )}
            </FlexBox>
          ))}
        </BackGroundWhite>
      </Section>
    </>
  )
}
