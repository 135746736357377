import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const AlcoholIcon = () => {
  return (
    <SvgIcon>
      <rect x="1" y="12.4604" width="1" height="1" rx="0.5" fill="#003333" />
      <rect x="3" y="12.4604" width="1.5" height="1" rx="0.5" fill="#003333" />
      <rect x="5.5" y="12.4604" width="2" height="1" rx="0.5" fill="#003333" />
      <rect
        x="1.50391"
        y="16.4697"
        width="1"
        height="1"
        rx="0.5"
        transform="rotate(-18 1.50391 16.4697)"
        fill="#003333"
      />
      <rect
        x="3.40602"
        y="15.8516"
        width="1.5"
        height="1"
        rx="0.5"
        transform="rotate(-18 3.40602 15.8516)"
        fill="#003333"
      />
      <rect
        x="5.78516"
        y="15.0791"
        width="2"
        height="1"
        rx="0.5"
        transform="rotate(-18 5.78516 15.0791)"
        fill="#003333"
      />
      <rect
        x="1.81626"
        y="8.50049"
        width="1"
        height="1"
        rx="0.5"
        transform="rotate(18 1.81626 8.50049)"
        fill="#003333"
      />
      <rect
        x="3.71875"
        y="9.11865"
        width="1.5"
        height="1"
        rx="0.5"
        transform="rotate(18 3.71875 9.11865)"
        fill="#003333"
      />
      <rect
        x="6.09751"
        y="9.89111"
        width="2"
        height="1"
        rx="0.5"
        transform="rotate(18 6.09751 9.89111)"
        fill="#003333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1185 12.3893C23.1185 16.4704 19.8102 19.7787 15.7292 19.7787C12.9337 19.7787 10.5007 18.2263 9.24537 15.9366L15.7292 12.6947L9.00072 9.33044C10.1639 6.77589 12.7392 5 15.7292 5C19.8102 5 23.1185 8.30832 23.1185 12.3893ZM17.1172 11.2C17.6695 11.2 18.1172 10.7523 18.1172 10.2C18.1172 9.64771 17.6695 9.2 17.1172 9.2C16.5649 9.2 16.1172 9.64771 16.1172 10.2C16.1172 10.7523 16.5649 11.2 17.1172 11.2Z"
        fill="#003333"
      />{' '}
    </SvgIcon>
  )
}
